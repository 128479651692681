import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
  selector: 'app-greeninnovationhub-footer-text',
  templateUrl: './greeninnovationhub-footer-text.component.html',
  styleUrls: ['./greeninnovationhub-footer-text.component.scss']
})
export class GreenInnovationHubFooterTextComponent implements OnInit, ConfigInterface {

  constructor() { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }
}
