import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, APP_INITIALIZER } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from '@shared/shared.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import localeNl from '@angular/common/locales/nl';
import localeSV from '@angular/common/locales/sv';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { Papa } from 'ngx-papaparse';
import { MarkdownModule } from 'ngx-markdown';
import { AuthGuardService } from '@shared/services/auth-guard-service.service';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { ClipboardModule } from 'ngx-clipboard';
import { HttpClientModule } from '@angular/common/http';
import { MatomoModule } from 'ngx-matomo';
import { A11yModule} from '@angular/cdk/a11y';
import { PermissionDeniedComponent } from '@shared/permission-denied/permission-denied.component';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { environment } from 'environments/common/environment.default';
import { NavigationService } from '@shared/services/navigation.service';
import { KeycloakService } from 'keycloak-angular';


registerLocaleData(localeNl);
registerLocaleData(localeSV);
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: '' // or 'your.domain.com' // it is mandatory to set a domain, for cookies to work properly (see https://goo.gl/S2Hy2A)
  },
  palette: {
    popup: {
      background: '#000'
    },
    button: {
      background: '#f1d600'
    }
  },
  theme: 'classic',
  type: 'opt-out'
};

@NgModule({
  entryComponents:[
    AppComponent //Gets rid of the 'AppComponent cannot be used as an entry component.' error, but why?
  ],
  declarations: [
    AppComponent,
    PermissionDeniedComponent,
  ],
  imports: [
    MatomoModule,
    HttpClientModule,
    BrowserModule,
    CoreModule,
    MarkdownModule.forRoot(),
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }), //was linked to production {enabled : environment.production}
    NgcCookieConsentModule.forRoot(cookieConfig),
    ClipboardModule,
    A11yModule
  ],
  providers: [
    AuthGuardService,
    DynamicInjectorService,
    NavigationService,
    KeycloakService,
    Papa,
    {
      provide: LOCALE_ID,
      useValue: localStorage.getItem('selectedLanguage'),
    },
    {
      // LOADS ENVIRONMENT URLS FOR DOCKER/DYNAMIC SERVE; CONTENT ACQUIRED AT RUN
      provide: APP_INITIALIZER,
      useFactory: (dynamicInjectorService: DynamicInjectorService) => () => {
        return dynamicInjectorService.initEnvironment(window.location.href)
      },
      deps: [DynamicInjectorService],
      multi: true,
    },
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}
