import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
  selector: 'app-stichtsevecht-home-text',
  templateUrl: './stichtsevecht-home-text.component.html',
  styleUrls: ['./stichtsevecht-home-text.component.scss']
})

export class StichtsevechtHomeTextComponent implements OnInit, ConfigInterface {

  constructor() { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }

}
