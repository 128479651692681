<!-- <div *ngIf="!isAuthorized()" class="not-authorized">
  <button mat-raised-button color="warn" class="not-authorized-button" (click)="redirectToBlank('https://www.docs.civity.nl/dataplatform/portal_access')">
    <mat-icon>help</mat-icon>
    <span>&nbsp;&nbsp;{{'AUTH.NOT_AUTHORIZED_GIS_VIEWER' | translate}}</span>
  </button>
</div> -->

<div fxLayoutAlign="center">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <h2></h2>
    <p style="text-align: justify"></p>
    <div *ngIf="showLoading" fxLayoutAlign="center center">
      <mat-spinner *ngIf="showLoading"></mat-spinner>
    </div>
    <div fxLayoutAlign="center center">
      <iframe frameborder="0"></iframe>
    </div>
    <br>
  </div>
</div>