import { Subject } from 'rxjs';

export interface NotificationConfig {
  message: string;
  action: string;
  duration: number;
}

export class NotificationService {

  private notificationSource = new Subject<NotificationConfig>();
  notificationAvailable$ = this.notificationSource.asObservable();


  sendNotification(messsage: string, action: string, duration?: number) {
    this.notificationSource.next({ message: messsage, action: action, duration });
  }

}
