      <div fxLayout="column" fxFlex=60>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Lelystad Open Data</h1>
        <p style="text-align: justify;">
          Welkom op het open data platform van de gemeente Lelystad! Op deze website vindt u een overzicht van de
          beschikbare
          open data van de gemeente Lelystad. <br>
          <br>
          Voor de datasets klik boven op tabblad <a href="./#data">Data</a>
        </p>
        <br>
        <p>
          <a class="url-redirect-item" href="./#data">Data Lelystad</a>
        </p>
        <br>
        <p style="text-align: justify;">
          Als u ideeën heeft, verbeterpunten ziet of vragen wilt stellen, stuur dan een mail naar <a
            href="mailto:gemeente@lelystad.nl">gemeente@lelystad.nl</a>
          ovv OpenData Lelystad. In de toekomst gaat Gemeente Lelystad meer datasets publiceren. De gemeente Lelystad
          werkt samen
          met andere overheden om meer open data op een makkelijke manier beschikbaar te stellen. Samen werken we ook
          aan standaardisatie van datasets.
        </p>
        <br>
        <h2>Waardevolle informatie</h2>
        <p style="text-align: justify;">
          Om haar werk zo goed mogelijk te kunnen doen, verzamelt de gemeente Lelystad veel verschillende soorten
          gegevens (data).
          Wanneer verschillende gegevens gecombineerd worden ontstaat er Informatie die waardevol en interessant kan
          zijn voor bedrijven,
          organisaties, journalisten, onderzoekers en bewoners.<br>
          <br>
          Bijvoorbeeld: Plattegronden met informatie over afvalbakken, visplaatsen en openbare werken, geografische
          data, resultaten van
          bewonersonderzoeken of andere gegevens over de stad.<br>
          <br>
          Uit onderzoek blijkt dat open data grote economische waarde hebben. Veel mensen en organisaties kunnen
          profiteren.<br>
          <br>
          Om deze ontwikkelingen mogelijk te maken, stelt de gemeente Lelystad deze data zo veel en zo gemakkelijk
          mogelijk beschikbaar.
          De data zijn bekostigd uit publieke middelen en daarom gratis toegankelijk.<br>
          <br>
          Open data is:<br>
        </p>
        <ul class="text-color">
          <li>openbare data,</li>
          <li>zonder auteursrechten of rechten van derden,</li>
          <li>bekostigd uit publieke middelen,</li>
          <li>verzameld voor werkzaamheden overheidsinstantie,</li>
          <li>liefst ‘open standaard’; dat wil zeggen zonder barrières voor gebruik ICT-toepassingen,</li>
          <li>liefst leesbaar door computers zodat zoekmachines de informatie kunnen vinden.</li>
        </ul>
        <br>
        <h2>Gebruiksvoorwaarden open data Gemeente Lelystad</h2>
        <p style="text-align: justify;">
          Iedereen – particulier en bedrijf – mag de als zodanig aangemerkte open data van de gemeente Lelystad,
          zoals geplaatst op lelystad.dataplatform.nl hergebruiken in eigen toepassingen.<br>
          <br>
          De gemeente Lelystad stelt de inhoud van de datasets met de grootst mogelijke zorg samen. Dit is echter
          geen garantie dat deze datasets volledig, accuraat en juist zijn. De gemeente Lelystad sluit iedere
          aansprakelijkheid
          uit voor welke schade dan ook, direct en/of indirect, op enige wijze ontstaan door en/of voortvloeiend uit elk
          gebruik
          van deze datasets, waaronder ook – maar niet alleen – handelingen van een gebruiker van de datasets die op
          welke manier
          dan ook zijn ingegeven door de hier geplaatste informatie.
        </p>
        <p></p>
        <p></p>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <h2 class="mat-headline ">Gegevensformaten</h2>
          <p style="text-align: justify;">
            De open data wordt aangeboden in een aantal verschillende gegevensformaten. Hieronder vindt u per
            gegevensformaat een korte uitleg.
          </p>
          <p style="text-align: justify;"><strong>CSV</strong><br>
            Een CSV (comma separated value) kan worden geopend in een tekst-editor (zoals Notepad) of een spreadsheet
            (zoals OpenOffice Calc of Microsoft Excel). Ook is het formaat zeer geschikt om in te lezen in een database.
          </p>
          <p style="text-align: justify;"><strong>JSON</strong><br>
            Het JSON-formaat (javascript object notation) wordt vooral gebruikt voor uitwisseling tussen servers en
            webapplicaties.
            Het formaat beschrijft in een voor mensen leesbare vorm objecten, attributen en de waarden van deze
            attributen.
            Het kunnen ook ruimtelijke objecten zijn. Men spreekt in dat geval wel van een geo-json.
          </p>
          <p style="text-align: justify;"><strong>KML</strong><br>
            Een KML-bestand kan worden geopend met Google Earth. Indien Google Earth geïnstalleerd is, wordt bij klikken
            op
            het bestand automatisch ingezoomd op de dataset.
          </p>
          <p style="text-align: justify;"><strong>Shapefile</strong><br>
            Shapefiles worden gebruikt door de geografische software van de firma ESRI. Daarnaast is het een gangbaar
            formaat bij de uitwisseling
            van geografische gegevens. Een shapefile bestaat minimaal uit een shp-, shx- en dbf-bestand. Deze bestanden
            worden hier gezamenlijk
            aangeboden binnen een ZIP-bestand (d.w.z. gecomprimeerd).
          </p>
        </div>
      </div>