import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-gebuiksvoorwaarden',
  templateUrl: './gebuiksvoorwaarden.component.html',
  styleUrls: ['./gebuiksvoorwaarden.component.scss']
})
export class GebuiksvoorwaardenComponent implements OnInit {

  language: string;

  constructor(
    private translateService: TranslateService,
    private navigationService: NavigationService
    ) { }

  ngOnInit() {
    this.language = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(res => {
      this.language = this.translateService.currentLang;
    });
  }

  public getContactRoute():string{
    return this.navigationService.getContactRoute();
  }

}
