      <div fxLayout="column">
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Den Haag Open Data</h1>
        <p style="text-align: justify;">
          Welkom op het open data platform van de gemeente Den Haag! Op deze website vindt u een overzicht van de
          beschikbare
          open data van de gemeente Den Haag. Als u ideeën heeft, verbeterpunten ziet of vragen wilt stellen,
          eem dan <a href="mailto:datashop@denhaag.nl">contact&nbsp;</a> met ons op.
          In de toekomst gaat Gemeente Den Haag meer datasets publiceren. De gemeente Den Haag werkt samen met andere
          overheden om meer
          open data op een makkelijke manier beschikbaar te stellen. Samen werken we ook aan standaardisatie van
          datasets.
        </p>
        <h2>Open data is:</h2>

        <ul class="text-color">
          <li>openbare data,</li>
          <li>zonder auteursrechten of rechten van derden,</li>
          <li>bekostigd uit publieke middelen,</li>
          <li>verzameld voor werkzaamheden overheidsinstantie,</li>
          <li>liefst ‘open standaard’; dat wil zeggen zonder barrières voor gebruik ICT-toepassingen,</li>
          <li>liefst leesbaar door computers zodat zoekmachines de informatie kunnen vinden.</li>
        </ul>
        <br>
        <h2>Waardevolle informatie</h2>
        <p style="text-align: justify;">
          Om haar werk zo goed mogelijk te kunnen doen, verzamelt de gemeente Den Haag veel verschillende soorten
          gegevens.
          Informatie die waardevol en interessant kan zijn voor bedrijven, organisaties, journalisten, onderzoekers en
          bewoners.
          Zoals plattegronden met informatie, geografische data, resultaten van bewonersonderzoeken of allerlei gegevens
          over de stad.
          Volgens onderzoek kan open data grote economische waarde hebben. Veel mensen en organisaties kunnen ervan
          profiteren.
          Om deze ontwikkelingen mogelijk te maken, stelt de gemeente Den Haag deze data zo veel en zo gemakkelijk
          mogelijk beschikbaar.
          De data zijn bekostigd uit publieke middelen en daarom gratis toegankelijk. Voorbeelden van kaarten en
          grafieken gemaakt
          met open data zijn op <a href="http://geoportaal-ddh.opendata.arcgis.com/"
            target="_blank">www.kaartendenhaag.nl</a> te vinden.
        </p>
        <h2>Beheerunit Open data</h2>
        <p style="text-align: justify;">
          De beheerunit open data adviseert en begeleidt verschillende open datatrajecten binnen en buiten de gemeente.
          De unit wil deze informatie voor burgers, non-profit organisaties en commerciële partijen zo goed mogelijk
          beschikbaar te stellen.
          Kijk <a href="https://www.denhaag.nl/nl/algemeen/beheerunit-open-data.htm" target="_blank">hier</a> voor meer
          informatie.
          Vanuit de beheerunit participeren wij in diverse projecten en platforms en werken samen met andere
          gemeenten en organisaties om samen de stad vorm te geven aansluitend op
          <a href="https://denhaag.raadsinformatie.nl/modules/13/overige_bestuurlijke_stukken/152213"
            target="_blank">agenda ruimte</a> voor de stad.
          Heb je ideeën of vragen hierover
          neem contact met ons op: <a href="mailto:datashop@denhaag.nl">datashop@denhaag.nl&nbsp;</a>
        </p>
        <h2>Gebruiksvoorwaarden open data Gemeente Den Haag</h2>
        <p style="text-align: justify;">
          Iedereen – particulier en bedrijf – mag de als zodanig aangemerkte open data van de gemeente Den Haag, zoals
          geplaatst op
          denhaag.dataplatform.nl hergebruiken in eigen toepassingen. De gemeente Den Haag stelt de inhoud van de
          datasets met de grootst
          mogelijke zorg samen. Dit is echter geen garantie dat deze datasets volledig, accuraat en juist zijn. De
          gemeente Den Haag sluit
          iedere aansprakelijkheid uit voor welke schade dan ook, direct en/of indirect, op enige wijze ontstaan door
          en/of voortvloeiend uit
          elk gebruik van deze datasets, waaronder ook – maar niet alleen – handelingen van een gebruiker van de
          datasets die op welke manier dan
          ook zijn ingegeven door de hier geplaatste informatie.
        </p>
      </div>