import { ExposomeAuth, EnvironmentDependantString, EnvironmentDependantNumber, TST, ACC, PRD } from "./environment.model";
import { Extent } from 'ol/extent';

/**
 * This is a helper to be used when constructing optional configuration objects
 * @param {Object} function construct<T>(
 * @param {T} type - An optional param (Google Closure syntax)
 * @returns {T} This is the result
 */
function construct<T>(value: Object, type: { new(json: Object): T ;}): T {
    if (value != undefined) {
        return new type(value);
    }
    return undefined;
}


/**
 * Used to collect all the organisation specific data. Turning an otherwise agnostic app into the app for an organisation.
 * @param {Object} json inserts a json object to strip the variables for the model from.
 */
export class OrganizationConfig {
    /**
     * Name to recognize this organisation in the application for organisation specific settings rooted in the app.
     * @todo organisation now have this name, but also an id model and the name of the organisation folder as their id. This could be merge into one.
     */
    name: string;

    /**
     * The name of the organisation as it is going to be displayed in the app.
     */
    title: string;

    /**
     * The language the app will start with, if there isn't a prefered language noted in the browser already.
     * @example 'nl' for dutch, 'en' for english or 'sv' for swedish.
     */
    startLanguage: string;

    /**
     * Dict of all customer assets like favicons, banners, logos etc.
     */
    assets: Assets;

    /**
     * Dict of  properties for Data tab and the displayed dataset list.
     */
    datasetListConfig: DatasetListConfig;
    // TODO find correct config
    definitionListConfig: DatasetListConfig;    

    /**
     * Dict of properties of the dataset-card: Enable organization text.
     */
    datasetCardConfig: DatasetCardConfig;

    /**
     * Dict of properties of tabs of a dataset-details: information, table, map, download, contact.
     */
    datasetDetailsConfig: DatasetDetailsConfig;

    /**
     * The url from which all dataset related api call are made.
     * Should have a **TST**, **ACC**, **PRD** version which the app can automatically use to change environments.
     */
    apiUrl: EnvironmentDependantString;

    /**
     * The id for the current organization.
     * Used to filter datasets based on organization.
     * Organization id's change per environment so a **TST**, **ACC** and **PRD** id should be provided.
     */
    organizationId: EnvironmentDependantString;

    /**
     * A list of languages supported.
     * The list provided here are the short version of a country, so **nl** for **dutch** and **en** for english.
     * The current supported languages can be found inside the setting.json inside the config folder.
     */
    supportedLanguages: string[];

    /**
     * Every municipality has their own matomo id to be found inside matomo.
     */
    matomoSiteId: EnvironmentDependantNumber;

    /**
     * Marks if this client is part of oneCkan
     */
    oneCkanClient: boolean;

    /**
     * Used to build the header.
     * In here the buttons are defined, as well as the style or images.
     */
    header: HeaderModel;

    /**
     * Used to configure the homepage elements.
     */
    homepage: HomepageModel;

    /**
     * By default the portal starts on the home page.
     * If a municipality wants to user to start on a different page it can be defined here.
     */
    alternativeStartRoute: string;


    /**
     * Enables keycloak for the municipality. This will restrict some if not all pages.
     * @todo: needs confirmation
     */
    keycloakEnabled?:boolean;

    /**
     * The authentication in order to use keycloak in the portal
     */
    auth?:AuthConfig;

    /**
     * @todo: customer specific. Needs to be a bit more generic. The name for sure.
     */
    exposomeAuth?:ExposomeAuth;

    datasetRelations?: DatasetRelationshipConfigModel

    // optional properties
    customerScript?: CustomerScriptModel;


    constructor(json : Object){
        const apiUrl:Object = json['apiUrl'];
        const orgId:Object = json['organizationId'];
        const matomoSiteId:Object = json['matomoSiteId'];
        this.alternativeStartRoute = json['alternativeStartRoute'];
        this.apiUrl = new EnvironmentDependantString(apiUrl[TST], apiUrl[ACC], apiUrl[PRD]);
        this.assets = new Assets(json['assets']);
        this.datasetListConfig = new DatasetListConfig(json['datasetListConfig']);
        this.definitionListConfig = new DatasetListConfig(json['definitionListConfig']);
        this.datasetCardConfig = new DatasetCardConfig(json['datasetCardConfig']);
        this.datasetDetailsConfig = new DatasetDetailsConfig(json['datasetDetailsConfig']);
        this.auth = json['auth'] != undefined ? new AuthConfig(json['auth']) : undefined;
        this.customerScript = json['customerScript'] != undefined ? new CustomerScriptModel(json['customerScript']) : undefined;
        this.datasetRelations = json['datasetRelations'];
        this.exposomeAuth = json['exposomeAuth'] != undefined ? new ExposomeAuth(json['exposomeAuth']) : undefined;
        this.header = new HeaderModel(json['header']);
        this.homepage = new HomepageModel(json['homepage']);
        this.keycloakEnabled = json['keycloakEnabled'];
        this.matomoSiteId = new EnvironmentDependantNumber(matomoSiteId[TST], matomoSiteId[ACC], matomoSiteId[PRD]);
        this.name = json['name'];
        this.oneCkanClient = json['oneCkanClient'];
        this.organizationId = new EnvironmentDependantString(orgId[TST], orgId[ACC], orgId[PRD]);
        this.startLanguage = json['startLanguage'];
        this.supportedLanguages = json['supportedLanguages'];
        this.title = json['title'];
    }
}

export class AuthConfig {
    userMenu: UserMenuConfig;
    url: EnvironmentDependantString;
    realm: string;
    clientId: string;
    role: string;

    constructor(json:Object){
        this.userMenu = new UserMenuConfig(json['userMenu']);
        const urlObject:Object = json['url'];
        this.url = new EnvironmentDependantString(urlObject[TST], urlObject[ACC], urlObject[PRD]);
        this.realm = json['realm'];
        this.clientId = json['clientId'];
        this.role = json['role'];
    }
}

export class UserMenuConfig {
    dialogName:string;

    constructor(json: Object){
        this.dialogName = json['dialogName'];
    }
}

/**
 * The header model is used to create the header for the portal.
 * It should encompass all flexibilities related to the header.
 * @param {Object} json inserts a json object to strip the variables for the header from.
 */
export class HeaderModel{

    /**
     * Shows the amount of datasets underneath the header
     */
    datasetCounter: DatasetCounterConfig;

    /**
     * Shows the amount of datasets underneath the header
     */
    definitionCounter: DatasetCounterConfig;    

    /**
     * Displays default icon or language code on language button dropbown
     */
    languageSelector: LanguageSelectorConfig;

    /**
     * A list of buttons for the header.
     * Every button can be adjusted in style and route.
     */
    buttons:HeaderButtonModel[];

    /**
     * The style for the common header.
     * Every button can be styled within the buttons models.
     * The style needs to be an object where you can provide the standard CSS variables such as `height` or `margin`
     */
    style?:{[key: string]: any};

    /**
     * The style for the search bar underneath the header.
     * The style needs to be an object where you can provide the standard CSS variables such as `height` or `margin`
     */
    searchbarStyle?:{[key: string]: any};


    showShoppingCart: boolean

    constructor(json: Object){
        this.datasetCounter = construct(json['datasetCounter'], DatasetCounterConfig);
        this.definitionCounter = construct(json['definitionCounter'], DatasetCounterConfig);
        this.languageSelector = new LanguageSelectorConfig(json['languageSelector']);
        this.style = json['style'];
        this.searchbarStyle = json['searchbarStyle'];
        this.showShoppingCart = json["showShoppingCart"];
        const buttons = json['buttons'];
        const returnButtons: HeaderButtonModel[] = [];
        if(buttons){
            buttons.forEach(button => {
                if(button.icon){
                    returnButtons.push(new HeaderIconButtonModel(button, button.icon));
                } else if (button.imagePath){
                    returnButtons.push(new HeaderImageButtonModel(button, button.imagePath, button.altText));
                } else if (button.name){
                    returnButtons.push(new HeaderNameButtonModel(button, button.name));
                }
            })
            this.buttons = returnButtons;
        } else {
            console.warn('no buttons were found', json);
        }
    }
}

export class HomepageModel {
    displaySearchBar: boolean;
    markdownSupport: boolean;

    //optional
    displayNews?: boolean
    displayThemes?: boolean;
    carouselConfig?: CarouselConfig;

    constructor(json: Object){
        this.displaySearchBar = json['displaySearchBar'];
        this.markdownSupport = json['markdownSupport'];

        this.displayNews = json['displayNews'];
        this.displayThemes = json['displayThemes'];
        this.carouselConfig = json['carouselConfig'] != undefined ? new CarouselConfig(json['carouselConfig']) : undefined;
    }
}

export interface HeaderButtonModel{
    type: "icon" | "image" | "name";
    redirect: "route" | "link";
    url: string;
    style?: {[key: string]: any};
    altText?: string;
    name:string;
    routerLinkActive?:string;
    displayName?:boolean;
}

export class HeaderIconButtonModel implements HeaderButtonModel{
    type: "icon" | "image" | "name" = "icon";
    redirect: "route" | "link";
    url: string;
    style?: {[key: string]: any};
    icon: string;
    name: string;
    routerLinkActive?:string;
    displayName?:boolean;

    constructor(headerButton: HeaderButtonModel, icon:string){
        this.redirect = headerButton.redirect;
        this.url = headerButton.url;
        this.style = headerButton.style;
        this.icon = icon;
        this.name = headerButton.name;
        this.routerLinkActive = headerButton.routerLinkActive;
        this.displayName = headerButton.displayName;
    }
}

export class HeaderNameButtonModel implements HeaderButtonModel{
    type: "icon" | "image" | "name" = "name";
    redirect: "route" | "link";
    url: string;
    style?: {[key: string]: any};
    name: string;
    routerLinkActive?:string;
    displayName?:boolean;

    constructor(headerButton: HeaderButtonModel, name:string){
        this.redirect = headerButton.redirect;
        this.url = headerButton.url;
        this.style = headerButton.style;
        this.name = name;
        this.routerLinkActive = headerButton.routerLinkActive;
        this.displayName = headerButton.displayName;
    }
}

export class HeaderImageButtonModel implements HeaderButtonModel{
    type: "icon" | "image" | "name" = "image";
    redirect: "route" | "link";
    url: string;
    style?: {[key: string]: any};
    imagePath: string;
    alt?: string;
    name:string;
    routerLinkActive?:string;
    displayName?:boolean;

    constructor(headerButton: HeaderButtonModel, imagePath:string, altText?:string){
        this.redirect = headerButton.redirect;
        this.url = headerButton.url;
        this.style = headerButton.style;
        this.imagePath = imagePath;
        this.name = headerButton.name;
        this.alt = altText;
        this.routerLinkActive = headerButton.routerLinkActive;
        this.displayName = headerButton.displayName;
    }
}

export class DatasetCounterConfig {
    enabled: boolean;
    style?: {[key: string]: any};;

    constructor(json: Object){
        this.enabled = json['enabled'];
        this.style = json['style'];
    }
}

export class LanguageSelectorConfig {
    type: string;
    material_icon?: string;

    constructor(json: Object){
        this.type = json['type'];
        this.material_icon = json['material_icon'];
    }
}

export class DatasetRelationshipConfigModel {
    relationIcons: {[relationName: string]: string};
}

export class Assets {
    favicon?: AssetIcon;
    banner?: AssetImage;
    logo?: AssetImage;

    constructor(json: Object){
        this.favicon = json['favicon'] != undefined ? new AssetIcon(json['favicon']) : undefined;
        this.banner = json['banner'] != undefined ? new AssetImage(json['banner']) : undefined;
        this.logo = json['logo'] != undefined ? new AssetImage(json['logo']) : undefined;
    }
}

export class AssetIcon {
    path:string;

    constructor(json: Object){
        this.path = json['path'];
    }
}

export class AssetImage {
    url?:string;
    path?:string;
    altText?:string;
    style?:{[key: string]: any};

    constructor(json: Object){
        this.url = json['url'];
        this.path = json['path'];
        this.style = json['style'];
        this.altText = json['altText'];
    }
}
export class DatasetListConfig {
    datasetNotesMaxLength: number; //character limit on previewing dataset description inside a dataset mat-card in the dataset list overview
    // optional
    expandedFilterMenu?: boolean; // set the default state of the filter menu
    excludedFilters?: string[]; // list of filters to be excluded from the filter menu
    enableWishlist?: boolean;
    constructor(json: Object){
        this.datasetNotesMaxLength = json['datasetNotesMaxLength'];
        this.expandedFilterMenu = json['expandedFilterMenu'];
        this.excludedFilters = json['excludeFilter'];
        this.enableWishlist = json['enableWishlist'];
    }
}

export class DatasetCardConfig {
    showOrganization: boolean;

    constructor(json: Object) {
        this.showOrganization = json['showOrganization'];
    }
}

export class DatasetDetailsConfig {
    information: InformationConfig;
    table: TableConfig;
    map: MapConfig;
    download: DownloadConfig;
    contact: ContactConfig;
    //optional
    disableGoBackToDataButton?: boolean;

    constructor(json: Object){
        this.information = new InformationConfig(json['information']);
        this.table = new TableConfig(json['table']);
        this.map = new MapConfig(json['map']);
        this.download = new DownloadConfig(json['download']);
        this.contact = new ContactConfig(json['contact']);
        this.disableGoBackToDataButton = json['disableGoBackToDataButton'];
    }
}

export class InformationConfig {
    property?:string;

    constructor(json: Object){
        this.property = json['property'];
    }
}

export class TableConfig {
    datastoreDownloadButton: boolean; //get a download button in side the table for a dataset

    constructor(json: Object){
        this.datastoreDownloadButton = json['datastoreDownloadButton'];
    }
}

export class MapConfig {
    drawFilter: boolean; //display draw icon on top right corner of the map, draw an area to filter data before downloading
    ogrDownload: boolean; //display extra column inside OWS table (if enabled), where user can download full or part (draw filter) of the data in multiple OGR-supported formats
    owsEndpoints: boolean; //display table of available WMS and WFS endpoints
    legendConfig: LegendConfig; //in-map legend properties
    baseMapConfig: BaseMapConfig; //display basemap layer on different projection system than openlayers default EPSG:3857
    clickRadius: number = 1; // set number to increase/decrease search radius, defaults to 1.
    //Optional
    defaultLayerExtent?: number[];

    constructor(json: Object){
        this.drawFilter = json['drawFilter'];
        this.ogrDownload = json['ogrDownload'];
        this.owsEndpoints = json['owsEndpoints'];
        this.legendConfig = new LegendConfig(json['legendConfig']);
        this.baseMapConfig = new BaseMapConfig(json['baseMapConfig']);
        this.clickRadius = json['clickRadius'];
        this.defaultLayerExtent = json['defaultLayerExtent'];
    }
}

export class DownloadConfig {
    tabEnabled: boolean; //display the download tab
    apiSupport: ApiSupport; //optional
    // ogr
    ogrDownload:boolean; //display extra column inside download table named "Download (other formats)" where user can download data in multiple OGR-supported formats
    ogrAvailableSRID?: { [id: string]: string; }; //show additional column to select a target SRID when downloading
    ogrDefaultSRID?: string; //define default SRID, otherwise first option of ogrAvailableSRID will be picked

    constructor(json: Object) {
        this.tabEnabled = json['tabEnabled'];
        this.apiSupport = json['apiSupport'];
        // ogr
        this.ogrDownload = json['ogrDownload'];
        this.ogrAvailableSRID = json['ogrAvailableSRID'];
        this.ogrDefaultSRID = json['ogrDefaultSRID'] == undefined && this.ogrAvailableSRID != undefined ? Object.keys(this.ogrAvailableSRID)[0] : json['ogrDefaultSRID'];
    }
}

export class ContactConfig {
    tabEnabled: boolean; //display the contact tab
    emailSender: string;
    defaultEmailRecipient: string; //default portal email address
    getEmailFromDataset: boolean;

    constructor(json: Object){
        this.tabEnabled = json['tabEnabled'];
        this.emailSender = json['emailSender'];
        this.defaultEmailRecipient = json['defaultEmailRecipient'];
        this.getEmailFromDataset = json['getEmailFromDataset'];
    }
}

export class ApiSupport {
    //optional
    excludedEndpoints?: string[]; //display the contact tab

    constructor(json: Object){
        this.excludedEndpoints = json['excludedEndpoints'];
    }
}

export class BaseMapConfig{
    attributions:string;
    endpoint:{[key: string]: any};
    extent?:Extent;
    format?:string;
    layer?:string;
    projection:number;
    standard: string;


    constructor(json: Object){
        this.attributions = json['attributions'];
        this.endpoint = json['endpoint'];
        this.extent = json['extent'];
        this.format = json['format'];
        this.layer = json['layer'];
        this.projection = json['projection'];
        this.standard = json['standard'];
    }
}

export class LegendConfig{
    visibleOnInit: boolean;

    constructor(json: Object){
        this.visibleOnInit = json['visibleOnInit'];
    }
}

export class CustomerScriptModel{
    name:string;
    source:string;
    constructor(json: Object){
        this.name = json['name'];
        this.source = json['source'];
    }
}
export class CarouselConfig {
    autoplayDelay?: number;
    height?: string;
    filters?: string;
    sort?: string;

    constructor(json: Object){
        this.autoplayDelay = json['autoplayDelay'];
        this.height = json['height'];
        this.filters = json['filters'];
        this.sort = json['sort'];
    }
}

export class CarouselCustomSlideModel{
    image: string;
    header: string;
    content: string;
    link?: string;

    constructor(json: Object){
        this.image = json['image'];
        this.header = json['header'];
        this.content = json['content'];
        this.link = json['link'];
    }
}
