import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";

//Customer components
import { AlmereFooterTextComponent } from "./organizations/almere/footer/almere-footer-text.component";
import { AmersfoortFooterTextComponent } from "./organizations/amersfoort/footer/amersfoort-footer-text.component";
import { CatalogusLelystadFooterTextComponent } from "./organizations/catalogus-lelystad/footer/catalogus-lelystad-footer-text.component";
import { DataplatformFooterTextComponent } from "./organizations/dataplatform/footer/dataplatform-footer-text.component";
import { DenhaagFooterTextComponent } from "./organizations/denhaag/footer/denhaag-footer-text.component";
import { ExposomeFooterTextComponent } from "./organizations/exposome/footer/exposome-footer-text.component";
import { FrieslandFooterTextComponent } from "./organizations/friesland/footer/friesland-footer-text.component";
import { GreenInnovationHubFooterTextComponent } from "./organizations/greeninnovationhub/footer/greeninnovationhub-footer-text.component";
import { HealthRiFooterTextComponent } from './organizations/health-ri/footer/health-ri-footer-text.component';
import { IotStadslabFooterTextComponent } from "./organizations/iot-stadslab-s-hertogenbosch/footer/iot-stadslab-footer-text.component";
import { LeeuwardenFooterTextComponent } from "./organizations/leeuwarden/footer/leeuwarden-footer-text.component";
import { LelystadFooterTextComponent } from "./organizations/lelystad/footer/lelystad-footer-text.component";
import { LidingoFooterTextComponent } from "./organizations/lidingo/footer/lidingo-footer-text.component";
import { MalmoFooterTextComponent } from "./organizations/malmo/footer/malmo-footer-text.component";
import { NieuwegeinFooterTextComponent } from "./organizations/nieuwegein/footer/nieuwegein-footer-text.component";
import { ProvincieNoordHollandFooterTextComponent } from "./organizations/provincie-noord-holland/footer/provincie-noord-holland-footer-text.component";
import { RotterdamFooterTextComponent } from "./organizations/rotterdam/footer/rotterdam-footer-text.component";
import { SandboxFooterTextComponent } from "./organizations/sandbox/footer/sandbox-footer-text.component";
import { SchiedamFooterTextComponent } from "./organizations/schiedam/footer/schiedam-footer-text.component";
import { SHertogenboschFooterTextComponent } from "./organizations/s-hertogenbosch/footer/s-hertogenbosch-footer-text.component";
import { SnuffelfietsFooterTextComponent } from "./organizations/snuffelfiets/footer/snuffelfiets-footer-text.component";
import { ScorewaterFooterTextComponent } from "./organizations/scorewater/footer/scorewater-footer-text.component";
import { StichtsevechtFooterTextComponent } from "./organizations/stichtsevecht/footer/stichtsevecht-footer-text.component";
import { UnbrandedFooterTextComponent } from "./organizations/unbranded/footer/unbranded-footer-text.component";
import { ZaanstadFooterTextComponent } from "./organizations/zaanstad/footer/zaanstad-footer-text.component";

//A list, or multiple lists of components used to flexibly load components inside your app.


//How to use it:

//Add the following code to insert all components. Or create a filter for every component if you only want a specific component to load.
// (component list).forEach(component => {
//    this.viewContainerRef.createComponent<Object>(component);
// });

export const footerComponents = [
  AlmereFooterTextComponent,
  AmersfoortFooterTextComponent,
  CatalogusLelystadFooterTextComponent,
  DataplatformFooterTextComponent,
  DenhaagFooterTextComponent,
  ExposomeFooterTextComponent,
  FrieslandFooterTextComponent,
  GreenInnovationHubFooterTextComponent,
  HealthRiFooterTextComponent,
  IotStadslabFooterTextComponent,
  LeeuwardenFooterTextComponent,
  LelystadFooterTextComponent,
  LidingoFooterTextComponent,
  MalmoFooterTextComponent,
  NieuwegeinFooterTextComponent,
  ProvincieNoordHollandFooterTextComponent,
  RotterdamFooterTextComponent,
  SandboxFooterTextComponent,
  SchiedamFooterTextComponent,
  ScorewaterFooterTextComponent,
  SHertogenboschFooterTextComponent,
  StichtsevechtFooterTextComponent,
  SnuffelfietsFooterTextComponent,
  UnbrandedFooterTextComponent,
  ZaanstadFooterTextComponent
]

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    footerComponents
  ],
  exports: [
    footerComponents
  ],
  providers: [
  ]
})
export class FooterModule { }
