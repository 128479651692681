
import { ExtrasModel } from '@shared/models/extras.model';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { DatasetModel } from '@shared/models/dataset.model';
import { DatasetInformationComponent } from '../dataset-information.component';
import { MatTable } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from '@shared/services/api.service';


@Component({
  selector: 'app-metadata-table',
  templateUrl: './metadata-table.component.html',
  styleUrls: ['./metadata-table.component.scss']
})
export class MetadataTableComponent implements OnInit {

  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  @Input() datasetDetails: DatasetModel;

  dataSource: ExtrasModel[];
  displayedColumns = ['value_label', 'value'];
  field: any;
  language: string;
  allLabels: Array<any>;

  constructor(
    private apiService: ApiService,
    private datasetDetailsComponent: DatasetInformationComponent,
    private translationService: TranslateService) { }

  ngOnInit() {
    this.dataSource = [];
    this.allLabels = [];
    this.language = this.translationService.currentLang;
    this.translationService.onLangChange.subscribe(res => {
      this.language = this.translationService.currentLang;
      // trigger dataSource sorting and rendering on language change
      // this.sortDataSourceOnActiveLanguage()
      // this.table.renderRows();
    });
    this.populateDataSource();
  };

  /**
   * Load datasource metadata using appropriate CKAN endpoints
   */
  populateDataSource(){
    let metadata: ExtrasModel;
    this.apiService.getSchemeDataset().then(
      (data) => {
        data.dataset_fields.forEach(
          field => {
            if (this.language == 'nl' && field.label.nl) {
              const label = field.label.nl;
              this.allLabels.push(label);
            } else if (this.language == 'sv' && field.label.sv) {
              const label = field.label.sv;
              this.allLabels.push(label);
            } else {
              const label = field.label;
              this.allLabels.push(label);
            }
          }
        );
      });

    this.apiService.getSchemePackageDetails(this.datasetDetailsComponent.datasetDetails.id).then(
      (response) => {
        Object.entries(response).forEach(([key, field]) => {
          const value = (key === 'organization') ? field['value']['title'] : field['value'];
          const visible = field['visible'];
          if (visible == true && value && value != "") {
            metadata = new ExtrasModel();
            metadata.value = value;
            metadata.visible = visible;
            metadata.field_label = field['field_label'];
            metadata.value_label = field['value_label'];
            metadata.value_type = field['value_type'];
            this.dataSource.push(metadata);
          }
        });
        // this.sortDataSourceOnActiveLanguage()
        this.table.renderRows();
      });
  }

  /**
   * Sort metadata field label based on active language and available field values
   */
  sortDataSourceOnActiveLanguage(){
    this.dataSource.sort((a, b) => {
      const keyA = a.field_label[this.language] || a.field_label.en || a.field_label;
      const keyB = b.field_label[this.language] || b.field_label.en || b.field_label;
      if (keyA < keyB) {
        return -1;
      }
      if (keyA > keyB) {
        return 1;
      }
      return 0;
    });
  }

  /**
   * Get metadata field's 'value_type' value
   * @param {string} metadata_field metadata field name
   * @returns {string} value type. can be: text, url, date or email
   */
  getValueType(metadata_field): string {
    return metadata_field.value_type
  }

  /**
   * Get metadata field's 'field_label' available value
   * @param {string} metadata_field metadata field name
   * @param {string} locale language locale the portal is currently displayed
   * @returns {string}
   */
  getFieldLabel(metadata_field, locale): string {
    //Look for active language value, then default language value, then non-dict string value
    let fieldLabel = metadata_field.field_label[locale] || metadata_field.field_label.en || metadata_field.field_label
    return fieldLabel
  }

  /**
   * Get metadata field's 'value_label' available value
   * @param {string} metadata_field metadata field name
   * @param {string} locale language locale the portal is currently displayed
   * @returns {string}
   */
  getValueLabel(metadata_field, locale):string {
    //Look for active language value, then default language value, then non-dict string value then 'value' field value
    let value_label = metadata_field.value_label
    let valueLabel = value_label ? value_label[locale] || value_label.en || value_label : metadata_field.value
    return valueLabel
  }

  /**
   * Check that the value string is a valid URL with regex pattern
   * @param {string} url
   * @returns {boolean}
   */
  validateUrl(url: string): boolean {
    const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/i;
    return urlPattern.test(url);
  }

  /**
   * Check that the value string is a valid email address with regex pattern
   * @param {string} email
   * @returns {boolean}
   */
  validateEmail(email: string) {
    // Simple REGEX
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailPattern.test(email);
  }
}
