import { Injectable } from '@angular/core';
import { DatasetModel } from '@shared/models/dataset.model';
import { ResourceModel } from '@shared/models/resource.model';
import { DatasetResourceButtons } from '../models/dataset-resources-buttons.model';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import * as _ from 'lodash';

@Injectable()
export class DatasetResourcesService {

  constructor(
    private dynamicInjectorService: DynamicInjectorService,
  ) {
  }

  getDatasetResourcesButtons(dataset: DatasetModel) {
    let resources: ResourceModel[];
    let datasetResourceButtons = new DatasetResourceButtons();
    let downloadTabEnabled = this.dynamicInjectorService.getOrganizationConfig().datasetDetailsConfig.download.tabEnabled
    let wishlistTabEnabled = this.dynamicInjectorService.getOrganizationConfig().datasetListConfig.enableWishlist
    resources = dataset.resources;
    datasetResourceButtons = new DatasetResourceButtons();
    datasetResourceButtons.map = false;
    datasetResourceButtons.table = false;
    datasetResourceButtons.download = downloadTabEnabled && this.countResources(dataset) > 0 && !wishlistTabEnabled
    datasetResourceButtons.api = false;
    datasetResourceButtons.wishlist = wishlistTabEnabled;
    resources.forEach((res) => {
      if (res.datastore_active) { // TODO add suppport for more formats when switching to Ogr logic
        datasetResourceButtons.table = true;
      }
      if (this.isSpatialEnabled(res)) {
          datasetResourceButtons.map = true;
        }

    });
    return datasetResourceButtons;
  }

  countDatasetsResourcesByType(datasetDetails: DatasetModel) {
    const resourcesType: string[] = [];
    for (const res of datasetDetails.resources) {
      resourcesType.push(res.format);
    }
    return _.countBy(resourcesType);
  }

  isSpatialEnabled(res: ResourceModel) {
    let spatialEnabled = false;
      if (res.wms_url && res.layer_srid) {
        spatialEnabled = true;
      }
    return spatialEnabled;
  }

  /**
   * get count of dataset's resources
   * @returns number
   */
  countResources(dataset: DatasetModel): number {
    return dataset.resources.length
  }

  getResourceFromResourceName(dataset: DatasetModel, resourceName:string): ResourceModel{
    const resource: ResourceModel | undefined = dataset.resources.find(res => res.name === resourceName);
    return resource
  }

}
