import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
    selector: 'app-exposome-home-text',
    templateUrl: './exposome-home-text.component.html',
    styleUrls: ['./exposome-home-text.component.scss']
})
export class ExposomeHomeTextComponent implements OnInit, ConfigInterface {

    constructor() { }

    getPortal(): string {
        return ID.id;
    }

    ngOnInit() {
    }

}
