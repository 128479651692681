import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { DatasetModel } from '@shared/models/dataset.model';
import { DatasetsService } from '@shared/services/datasets.service';

@Injectable()
export class DatasetDetailsResolver implements Resolve<DatasetModel> {

  constructor(private datasetsService: DatasetsService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DatasetModel> | Promise<DatasetModel> | DatasetModel {
    return this.datasetsService.getDatasetDetails(route.paramMap.get('id'));
  }
}
