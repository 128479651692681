import { Component, OnInit, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-regional-historical-center',
  templateUrl: './regional-historical-center.component.html',
  styleUrls: ['./regional-historical-center.component.scss']
})
export class RegionalHistoricalCenterComponent implements OnInit {
  url;
  showLoading: boolean;

  constructor(private elementRef: ElementRef) {
    this.showLoading = true;
  }

  ngOnInit() {

    this.url = 'https://www.rhcvechtenvenen.nl/collectie?miadt=386&mizig=349';
    const iframe = this.elementRef.nativeElement.querySelector('iframe');
    if (!_.isNull(iframe)) {
      iframe.src = this.url;
      iframe.width = '100%';
      iframe.height = '900px';
    }

    iframe.onload = (() => {
      this.showLoading = false;
    });
  }

}
