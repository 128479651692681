import { Component, OnInit, ElementRef } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import * as _ from 'lodash';
import { KeycloakUserService } from '@shared/services/keycloak-user.service';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { ACC, PRD } from '@shared/models/environment.model';
import { openDialog } from '../../../../custom/custom-functions/custom.dialog.functions';

@Component({
  selector: 'app-gisviewer',
  templateUrl: './gisviewer.component.html',
  styleUrls: ['./gisviewer.component.scss']
})
export class GISViewerComponent implements OnInit {

  private oneMapUrl;
  customIframe;
  showLoading: boolean;
  result_public_key: string;
  cohort_public_key: string;

  //TODO: maybe make a common config to get this information
  private static readonly TST_ONE_MAP_URL:string = 'https://civity.webgis.nl/nl/workspace/civity/map/tst-research-exposures';
  private static readonly ACC_ONE_MAP_URL:string = 'https://civity.webgis.nl/nl/workspace/civity/map/acc-research-exposures';
  private static readonly PRD_ONE_MAP_URL:string = 'https://civity.webgis.nl/nl/workspace/civity/map/prd-research-exposures';  

  constructor(
    private elementRef: ElementRef, 
    public dialog: MatDialog, 
    private keycloakUserService: KeycloakUserService,
    private dynamicInjector: DynamicInjectorService
    ) {
    this.showLoading = true;
  }

  public openDialog(): void {
    openDialog(this.dynamicInjector.getOrganizationConfig().name, this.dialog);
  }

  ngOnInit() {
    const env:string = this.dynamicInjector.getEnvironment().environment;
    
    if(env == PRD){
      this.oneMapUrl = GISViewerComponent.PRD_ONE_MAP_URL;
    } else if (env == ACC){
      this.oneMapUrl = GISViewerComponent.ACC_ONE_MAP_URL;
    } else {
      this.oneMapUrl = GISViewerComponent.TST_ONE_MAP_URL;
    }

    const iframe = this.elementRef.nativeElement.querySelector('iframe');
    if (!_.isNull(iframe)) {
      iframe.src = this.oneMapUrl;
      iframe.width = '100%';
      iframe.height = '900px';
    }

    iframe.onload = (() => {
      this.showLoading = false;
    });
  }

  redirectToBlank(url): void {
    window.open(url, '_blank');
  }

}
