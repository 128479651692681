import { Component, Input, OnInit } from '@angular/core';
import { HeaderButtonModel, HeaderIconButtonModel, HeaderImageButtonModel } from '@shared/models/organization.config.model';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
@Component({
  selector: 'app-header-button',
  templateUrl: './header-button.component.html',
  styleUrls: ['./header-button.component.scss']
})
export class HeaderButtonComponent implements OnInit {

  @Input() public headerButtonModel: HeaderButtonModel;

  constructor(
    private dynamicInjectorService: DynamicInjectorService,
  ) { }

  ngOnInit(): void {
  }


  public isHeaderButtonAnImage():boolean{
    if(this.headerButtonModel && this.headerButtonModel.type){
      return this.headerButtonModel.type == "image";
    } else {
      return false;
    }
  }

  public isHeaderButtonAnIcon():boolean{
    if(this.headerButtonModel && this.headerButtonModel.type){
      return this.headerButtonModel.type == "icon";
    } else {
      return false;
    }
  }

  public isHeaderButtonAName():boolean{
    if(this.headerButtonModel && this.headerButtonModel.type){
      return this.headerButtonModel.type == "name" && !(this.isHeaderButtonAnIcon() || this.isHeaderButtonAnImage());
    } else {
      return false;
    }
  }

  public displayName():boolean{
    if(this.headerButtonModel){
      return this.headerButtonModel.displayName;
    } else {
      return false;
    }
  }

  public getHeaderButtonImageUrl():string{
    const imageModel:HeaderImageButtonModel = this.headerButtonModel as HeaderImageButtonModel;
    const imagePath = `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${imageModel.imagePath}`
    return imagePath;
  }

  public getHeaderButtonIcon():string{
    const imageModel:HeaderIconButtonModel = this.headerButtonModel as HeaderIconButtonModel;
    return imageModel.icon;
  }

  public getHeaderButtonStyle():{[key: string]: any}{
    return this.headerButtonModel.style;
  }

  public getHeaderButtonName():string{
    return this.headerButtonModel.name;
  }

  public getRouterLinkActive():string{
    return this.headerButtonModel.routerLinkActive ? this.headerButtonModel.routerLinkActive : 'active-button';
  }

  public getHeaderButtonAltText():string{
    return this.headerButtonModel.altText;
  }

  public getHeaderButtonUrl():string{
    return this.headerButtonModel.url;
  }

  public redirectToLink():boolean{
    return this.headerButtonModel.redirect == 'link';
  }
}
