import 'hammerjs';
import { OverlayContainer } from '@angular/cdk/overlay';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationConfig, NotificationService } from '@shared/services/notification.service';
import { ActivatedRoute } from '@angular/router';
import { Title, Meta} from '@angular/platform-browser';
import { Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NgcCookieConsentService, NgcStatusChangeEvent, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { MatomoInjector, MatomoTracker } from 'ngx-matomo';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { NavigationService } from '@shared/services/navigation.service';
import { CustomerScriptModel } from '@shared/models/organization.config.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  public fullScreen = false;
  private statusChangeSubscription: Subscription;
  showContent = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dynamicInjectorService: DynamicInjectorService,
    public overlayContainer: OverlayContainer, 
    private translateService: TranslateService, 
    private snackBar: MatSnackBar,
    private notificationService: NotificationService, 
    private activatedRoute: ActivatedRoute, 
    private metaService: Meta,
    private titleService: Title, 
    private cookieService: NgcCookieConsentService,
    private matomoInjector: MatomoInjector, 
    private matomoTracker: MatomoTracker,
    private navigationService: NavigationService,
    
    ) {
    }

  ngOnInit() {
    this.titleService.setTitle(`Dataplatform ${this.dynamicInjectorService.getOrganizationConfig().title}`);
    this.document.documentElement.lang = this.dynamicInjectorService.getOrganizationConfig().startLanguage;
    this.dynamicInjectorService.setFavIcon();
    this.metaService.addTags([
      {name: 'description', content: `${this.titleService.getTitle()}`},
    ]);
    const matomoSiteId = this.dynamicInjectorService.getEnvironment().matomoSiteId
    const matomoUrl = this.dynamicInjectorService.getEnvironment().matomoUrl
    this.matomoInjector.init(matomoUrl, matomoSiteId);
    if (this.dynamicInjectorService.getOrganizationConfig().customerScript){
      const customerScript: CustomerScriptModel = this.dynamicInjectorService.getOrganizationConfig().customerScript;
      this.loadCustomerScript(customerScript.name, customerScript.source)
    }
    this.notificationService.notificationAvailable$.subscribe((notification: NotificationConfig) => {
      this.openSnackBar(notification.message, notification.action, notification.duration);
    });

    this.initTranslate();
    this.activatedRoute.queryParams.subscribe(params => {
      this.fullScreen = params['fullScreen'];
    });

    this.statusChangeSubscription = this.cookieService.statusChange$.subscribe(
      (event: NgcStatusChangeEvent) => {
        if (event.status = 'deny') {
          this.matomoTracker.deleteCookies();
          this.matomoTracker.disableCookies();
        }
      });

    this.translateService.onLangChange.subscribe(x => {
      this.translateService.get(['COOKIE.HEADER', 'COOKIE.MESSAGE', 'COOKIE.DISMISS', 'COOKIE.ALLOW', 'COOKIE.DENY', 'COOKIE.LINK', 'COOKIE.POLICY'])
        .subscribe(data => {

          this.cookieService.getConfig().content = this.cookieService.getConfig().content || {};
          this.cookieService.getConfig().elements = this.cookieService.getConfig().elements || {};
          // Override default messages with the translated ones
          this.cookieService.getConfig().content.header = data['COOKIE.HEADER'];
          this.cookieService.getConfig().content.message = data['COOKIE.MESSAGE'];
          this.cookieService.getConfig().content.dismiss = data['COOKIE.DISMISS'];
          this.cookieService.getConfig().content.allow = data['COOKIE.ALLOW'];
          this.cookieService.getConfig().content.deny = data['COOKIE.DENY'];
          this.cookieService.getConfig().content.link = data['COOKIE.LINK'];
          this.cookieService.getConfig().content.policy = data['COOKIE.POLICY'];

          const cookieConfig: NgcCookieConsentConfig = this.cookieService.getConfig();
          cookieConfig.theme = 'block';
          cookieConfig.position = 'bottom-right';
          cookieConfig.palette.popup = {'background': '#eeeeee', 'text': '#000000', 'link': '#000000' };
          cookieConfig.elements.messagelink = `
                  <span id="cookieconsent:header" class="cc-custom-header"><h2>{{header}}</h2></span>
                  <br />
                  <span id="cookieconsent:desc" class="cc-message">{{message}}
                    <a id="cookieconsent:link" aria-label="learn more about cookies" tabindex="0" class="cc-link">{{link}}</a>
                  </span>
             `;
          this.cookieService.destroy(); // remove previous cookie bar (with default messages)
          this.cookieService.init(cookieConfig); // update config with translated messages
          const elem = document.getElementById('cookieconsent:link');
          if (elem) {
            elem.addEventListener('click', (e) => {
              this.matomoTracker.trackEvent('link', 'click', 'cookie notice: more information');
              this.navigationService.toCookiePrivacyPolicy();
            });

          }
        });
    });

   setTimeout(() => this.showContent = true, 500);
  }

  /**
   * Tries to find a starting language.
   * Either because it was selected before and that selection was saved in the storage.
   * or by finding the language inside of your browser language
   * or by finding the default starting language from the organisation in the config
   * else it will pick english 'en' by default.
   */
  initTranslate() {

    /**
     * get default starting language from the organisations config
     */
    const startingLanguage:string = this.dynamicInjectorService.getOrganizationConfig().startLanguage;
    if(startingLanguage){
      this.translateService.setDefaultLang(startingLanguage);
    } else {
      console.debug(`Did not find a default starting language in the config so it will pick english 'en' instead.`);
      this.translateService.setDefaultLang('en');
    }
    
    if (localStorage.getItem('selectedLanguage')) {
      this.setTranslationLanguage(this.translateService.defaultLang);
    } else if (this.translateService.getBrowserLang() !== undefined && this.getSupportedLanguages().indexOf(this.translateService.getBrowserLang()) !== -1) {
      this.setTranslationLanguage(this.translateService.getBrowserLang());
    } else if (startingLanguage) {
      this.setTranslationLanguage(startingLanguage);
    } else {
      console.debug(`Did not find a default language in the storage, browser or config so will start with english 'en' instead.`);
      this.setTranslationLanguage('en');
    }
    this.translateService.currentLang
  }

    /**
   * Changes the page language for translable text fields as well as for accessibility
   * @param lang the language you want to use
   * @todo the same function exists in app.component.ts, we might want to share this in a service
   */
  private setTranslationLanguage(lang:string) : void{
    this.translateService.use(lang);
    if(document.getElementsByTagName('html') && document.getElementsByTagName('html')[0] && document.getElementsByTagName('html')[0].getAttribute('lang')){
      document.getElementsByTagName('html')[0].setAttribute('lang',lang);
      console.debug(`Changed page language to: ${document.getElementsByTagName('html')[0].getAttribute('lang')}.`);
    }
  }

  private openSnackBar(message: string, action: string, duration?: number):void {
    if (!duration) {
      this.snackBar.open(message, action, {
        duration: 2000,
        horizontalPosition: 'left'
      });
    } else {
      this.snackBar.open(message, action, {
        duration: duration,
        horizontalPosition: 'left'
      });
    }
  }
  
  ngOnDestroy() {
    this.statusChangeSubscription.unsubscribe();
  }

  public getCustomerName():string{
    return this.dynamicInjectorService.getEnvironment().name;
  }

  public getChosenTheme():string{
    return this.dynamicInjectorService.getTheme();
  }

  public isFullscreen():boolean{
    return this.fullScreen;
  }

  public getSupportedLanguages():string[]{
    return this.dynamicInjectorService.getOrganizationConfig().supportedLanguages;
  }

  public loadCustomerScript(scriptName: string, scriptSource: string):void {
    console.debug(`Loading script ${scriptName}`)
    let node = document.createElement('script');
    node.src = scriptSource;
    node.type = 'text/javascript';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }
}
  