import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
  selector: 'app-scorewater-footer-text',
  templateUrl: './scorewater-footer-text.component.html',
  styleUrls: ['./scorewater-footer-text.component.scss']
})
export class ScorewaterFooterTextComponent implements OnInit, ConfigInterface {

  constructor() { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }

  public getEuropeanImage():string{
    return './assets/images/eu_flag.png';
  }

}
