import { Component, OnInit, ElementRef } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-voorbeelden',
  templateUrl: './voorbeelden.component.html',
  styleUrls: ['./voorbeelden.component.scss']
})
export class VoorbeeldenDenhaagComponent implements OnInit {

  url;
  customIframe;
  showLoading: boolean;

  constructor(private elementRef: ElementRef) {
    this.showLoading = true;
  }

  ngOnInit() {

    this.url = 'https://ddh.maps.arcgis.com/apps/MapAndAppGallery/index.html?appid=af34c323a6aa4e33a2a18463acf2a270';
    const iframe = this.elementRef.nativeElement.querySelector('iframe');
    if (!_.isNull(iframe)) {
      iframe.src = this.url;
      iframe.width = '100%';
      iframe.height = '900px';

    }

    iframe.onload = (() => {
      this.showLoading = false;
    });
  }

}
