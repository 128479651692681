<!-- Dataset Counter -->
<h2 [style]="getDatasetCounterStyle()" *ngIf="isDatasetCounterEnabled()" class="dataset-counter">{{'HEADER.TOTAL_DATASETS' |
  translate}}: {{getTotalNumberOfDatasets()}} 
</h2>
<!-- Search Bar -->
<div class="search-bar center-flex pt2 app-background" [ngStyle]="getSearchBarStyle()">
  <mat-form-field appearance="outline" fxFlex="33" fxFlex.sm="90" fxFlex.xs="90" class="searchIconFix">
    <mat-label>{{'HEADER.SEARCH_PLACEHOLDER' | translate}}</mat-label>
    <input (keyup.enter)="displayDatasetSearchResults()" matInput placeholder="" [matAutocomplete]="auto" [formControl]="datasetSearchInput">
    <div matSuffix class="searchIcon">
      <mat-icon class="searchIconPadding" (click)="displayDatasetSearchResults()">search</mat-icon>
    </div>
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option disabled *ngIf="(filteredDatasets | async)?.length==0 && datasetSearchInput.value">{{'HEADER.SEARCH_NO_RESULTS' | translate}}</mat-option>
      <mat-option *ngFor="let dataset of filteredDatasets | async" (onSelectionChange)="getDatasetDetail(dataset.id)">
        <span class="datasetTitle">{{ dataset.title }} </span>
        <br>
        <span class="datasetDesc">{{dataset.notes}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>
