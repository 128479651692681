<div [class]="getChosenTheme()">
    <app-header *ngIf="!fullScreen"></app-header>
  <div class="app-background">
    <main>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
<footer>
  <app-footer *ngIf="showContent"></app-footer>
</footer>