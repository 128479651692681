      <br />
      <div fxLayout="column"
        style="margin-left: 15% ; width: 70% ; margin-bottom: 16px ; align-items: center ; margin-top: 30px ">
        <h1 class="mat-h1" role="heading">Välkommen till Malmös öppna dataplattform</h1>
        <p style="text-align: justify; margin-bottom: 16px">
          Alla - såväl privatpersoner som privata och offentliga organisationer - får fritt använda de öppna data som
          finns
          på denna portal. Datan är fri att återanvändas, modifieras och delas.
        </p>
        <button mat-button [routerLink]="getDataRoute()" routerLinkActive="active-button"
          style="background-color: #4a7c96; color: white ; width: 200px">{{'HOMEPAGE.GO_TO_DATASETS' |
          translate}}</button>

        <h3 class="mat-headline" role="heading" style="margin-top: 40px; margin-bottom: -20px">Kategorier</h3>
      </div>
