<mat-toolbar class="footer">
  <mat-toolbar-row class="footer-row">
      <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
      <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61" fxLayoutAlign="space-between">

        <div fxFlex="20">
          <p class="mat-subheading-1">Bezoekadres</p>
          <p class="mat-body-1">
            Parallelweg 30<br>
            5225AL ‘s-Hertogenbosch<br>
          </p>

        </div>

        <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
            <p class="mat-subheading-1">Contact</p>
            <p class="mat-body-1">
              <a href="mailto:iotstadslab@s-hertogenbosch.nl">iotstadslab@s-hertogenbosch.nl&nbsp;</a><br>
            </p>
          </div>
        </div>

        <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61" fxLayoutAlign="space-between">
          <p class="powered-by">Powered by <a href="https://www.dataplatform.nl/" target="_blank">dataplatform.nl</a><br></p> <br> <br>
        </div>
      </div>
    </div>    
  </mat-toolbar-row>
</mat-toolbar>
