import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { footerComponents } from '../../../../portal-config/footer.module';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {

  /**
   * Needed to break a for loop when the conditions have been met.
   */
  private static readonly BREAK:string = 'Break';

  @ViewChild('dynamic_footer', {
    read: ViewContainerRef
  }) viewContainerRef: ViewContainerRef

  constructor(private dynamicInjectorService: DynamicInjectorService, private container:ViewContainerRef) {
    this.viewContainerRef = this.container;
  }

  ngOnInit() {
    this.initOrganisationFooter();
  }

  /**
   * Finds the footer component based on the organization found in the dynamic injector
   */
  public initOrganisationFooter(): void {
    try{
      footerComponents.forEach(component => {
        if (component.prototype.getPortal() == this.dynamicInjectorService.getEnvironment().organization) {
            this.viewContainerRef.createComponent<Object>(component);
            throw AppFooterComponent.BREAK;
          };
        });
    } catch (e) {
      if (e !== AppFooterComponent.BREAK) throw e;
    }
  }

  public getCustomerName():string{
    return this.dynamicInjectorService.getEnvironment().name;
  }

}
