import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-map3d',
  templateUrl: './map3d.component.html',
  styleUrls: ['./map3d.component.scss']
})
export class Map3dComponent implements AfterViewInit  {

  @ViewChild('map3dIframe') iframe: ElementRef;
  iframeUrl: SafeResourceUrl;
  isFullscreen: boolean = false;

  private static readonly MAP_URL = {
    'tst':'https://example.com',
    'acc':'https://example.com',
    'prd':'https://example.com'
  }

  constructor(
    private dynamicInjector: DynamicInjectorService,
    private sanitizer: DomSanitizer
    ) { }

  ngAfterViewInit() {
    const env:string = this.dynamicInjector.getEnvironment().environment;
    this.setIframeUrl(Map3dComponent.MAP_URL[env])
    this.setIframeSize('100%', '630px'); // Set initial width and height
    this.setIframeZoom(1.0); // Set initial zoom level (1.0 = 100%)
  }

  setIframeUrl(url) {
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  setIframeSize(width: string, height: string) {
    this.iframe.nativeElement.style.width = width;
    this.iframe.nativeElement.style.height = height;
  }

  setIframeZoom(zoomLevel: number) {
    this.iframe.nativeElement.style.transform = `scale(${zoomLevel})`;
  }

  toggleFullscreen() {
    const iframeElement = this.iframe.nativeElement;

    if (!this.isFullscreen) {
      if (iframeElement.requestFullscreen) {
        iframeElement.requestFullscreen();
      } else if (iframeElement.mozRequestFullScreen) { // Firefox
        iframeElement.mozRequestFullScreen();
      } else if (iframeElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
        iframeElement.webkitRequestFullscreen();
      } else if (iframeElement.msRequestFullscreen) { // IE/Edge
        iframeElement.msRequestFullscreen();
      }
    } else {
      if (iframeElement.exitFullscreen) {
        iframeElement.exitFullscreen();
      } else if (iframeElement.mozCancelFullScreen) {
        iframeElement.mozCancelFullScreen();
      } else if (iframeElement.webkitExitFullscreen) {
        iframeElement.webkitExitFullscreen();
      } else if (iframeElement.msExitFullscreen) {
        iframeElement.msExitFullscreen();
      }
    }

    this.isFullscreen = !this.isFullscreen;
  }

  getAsset(path) {
    return `${this.dynamicInjector.getCustomerConfigPath()}/assets/${path}`
  }
}
