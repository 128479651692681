import { Component, OnInit, ElementRef } from '@angular/core';
import * as _ from 'lodash';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { ACC, PRD } from '@shared/models/environment.model';

@Component({
  selector: 'app-klimaatmonitor',
  templateUrl: './klimaatmonitor.component.html',
  styleUrls: ['./klimaatmonitor.component.scss']
})
export class KlimaatMonitorComponent implements OnInit {

  private klimaatmonitorUrl;
  customIframe;
  showLoading: boolean;

  //TODO: maybe make a common config to get this information
  private static readonly TST_KLIMAATMONITOR_URL:string = 'https://app.powerbi.com/view?r=eyJrIjoiMzcwNzZkYTEtNTJjYy00ZmNkLWFiODQtYTcyMjk5YzgzM2IwIiwidCI6IjFhMjljMGY3LThiNzctNGJhNS1hZDg5LWY3OGZjM2RiNzdlNyIsImMiOjh9&pageName=ReportSection489547fe6a453e6a3b34';
  private static readonly ACC_KLIMAATMONITOR_URL:string = 'https://app.powerbi.com/view?r=eyJrIjoiMzcwNzZkYTEtNTJjYy00ZmNkLWFiODQtYTcyMjk5YzgzM2IwIiwidCI6IjFhMjljMGY3LThiNzctNGJhNS1hZDg5LWY3OGZjM2RiNzdlNyIsImMiOjh9&pageName=ReportSection489547fe6a453e6a3b34';
  private static readonly PRD_KLIMAATMONITOR_URL:string = 'https://app.powerbi.com/view?r=eyJrIjoiMzcwNzZkYTEtNTJjYy00ZmNkLWFiODQtYTcyMjk5YzgzM2IwIiwidCI6IjFhMjljMGY3LThiNzctNGJhNS1hZDg5LWY3OGZjM2RiNzdlNyIsImMiOjh9&pageName=ReportSection489547fe6a453e6a3b34';  

  constructor(
    private elementRef: ElementRef, 
    private dynamicInjector: DynamicInjectorService
    ) {
    this.showLoading = true;
  }

  ngOnInit() {
    const env:string = this.dynamicInjector.getEnvironment().environment;
    
    if(env == PRD){
      this.klimaatmonitorUrl = KlimaatMonitorComponent.PRD_KLIMAATMONITOR_URL;
    } else if (env == ACC){
      this.klimaatmonitorUrl = KlimaatMonitorComponent.ACC_KLIMAATMONITOR_URL;
    } else {
      this.klimaatmonitorUrl = KlimaatMonitorComponent.TST_KLIMAATMONITOR_URL;
    }

    const iframe = this.elementRef.nativeElement.querySelector('iframe');
    if (!_.isNull(iframe)) {
      iframe.src = this.klimaatmonitorUrl;
      iframe.width = '100%';
      iframe.height = '900px';
    }

    iframe.onload = (() => {
      this.showLoading = false;
    });
  }

  redirectToBlank(url): void {
    window.open(url, '_blank');
  }

}
