import { Component, OnInit, Input } from '@angular/core';
import { OrganizationModel } from '@shared/models/organization.model';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-organizations-card',
  templateUrl: './organizations-card.component.html',
  styleUrls: ['./organizations-card.component.scss'],
})

export class OrganizationsCardComponent implements OnInit {

  @Input() organization: OrganizationModel;
  @Input() index: number;
  mouseOver: boolean;

  constructor(private navigationService:NavigationService) {
  }

  ngOnInit() {
  }

  addQueryParam(organizationName) {
    this.navigationService.toData({queryParams: {['organization.name']: organizationName}, queryParamsHandling: 'merge'});
  }
}
