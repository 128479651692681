import { Injectable } from "@angular/core";
import { KeycloakEvent, KeycloakEventType, KeycloakService } from "keycloak-angular";
import { DynamicInjectorService } from "./dynamic.injector.service";

@Injectable()
export class KeycloakUserService {
    constructor(private keycloakService: KeycloakService, private dynamicInjectorService: DynamicInjectorService){
        if(this.dynamicInjectorService.getOrganizationConfig().keycloakEnabled){
            this.keycloakService.keycloakEvents$.subscribe((event:KeycloakEvent) => {
                if(event.type == KeycloakEventType.OnReady){
                    this.initAuthorization();
                }
            });
        }
    }

    private authorizedAccess: boolean;
    private accessToken: string;
    private userName:string = '';
    private fullName: string = '';
    
    initializeService(): Promise<boolean> {
        return this.keycloakService.isLoggedIn()
      }

    getToken(): string{
        return this.accessToken
    }

    initAuthorization():void{
        this.keycloakService.isLoggedIn().then(response => this.authorizedAccess = response);
        this.keycloakService.getToken().then(response => this.accessToken = response);
        this.keycloakService.loadUserProfile().then(profile => {
            this.userName = profile.username;
            this.fullName = `${profile.firstName} ${profile.lastName}`
        });
    }

    isAuthorized():boolean{
        return this.authorizedAccess;
    }

    logout(redirectUri?: string):Promise<void>{
        return this.keycloakService.logout(redirectUri);
    }

    login():Promise<void>{
        return this.keycloakService.login();
    }

    clearToken(): void{
        return this.keycloakService.clearToken();
    }

    getUsername():string{
        return this.userName;
    }
    
    getFullName(): string{
        return this.fullName;
    }
}
