import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
    selector: 'app-lidingo-footer-text',
    templateUrl: './lidingo-footer-text.component.html',
    styleUrls: ['./lidingo-footer-text.component.scss']
})
export class LidingoFooterTextComponent implements OnInit, ConfigInterface {

    constructor() { }

    getPortal(): string {
        return ID.id;
    }

    ngOnInit() {
    }

}
