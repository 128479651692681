import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';

@Component({
  selector: 'app-lidingo-home-text',
  templateUrl: './lidingo-home-text.component.html',
  styleUrls: ['./lidingo-home-text.component.scss']
})
export class LidingoHomeTextComponent implements OnInit, ConfigInterface {


  constructor() {
  }
  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {

  }

}
