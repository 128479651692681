<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
      <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
        <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61"
          fxLayoutAlign="space-between">
  
          <div fxFlex="20">
            <p>Besök Oss</p>
            <p class="mat-body-1">
              Lejonvägen 15, 181 82 Lidingö<br>
              <br>
            </p>
          </div>
          
          <div fxFlex="20">
              <p>Postadress </p>
              <p class="mat-body-1">
                Lidingö Stad, 181 82 Lidingö<br>
               <br>
              </p>
          </div>
  
          <p>Powered by <a href="https://www.dataplatform.nl/" target="_blank">dataplatform.nl</a><br></p> <br> <br>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>