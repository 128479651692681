<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
        <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
            <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="60"
                fxLayoutAlign="space-between">

                <div fxLayoutAlign="center center" fxFlex="50">
                    <a href="https://snuffelfiets.nl/" target="_blank"><button mat-button>© 2022 Snuffelfiets</button></a>
                    <a href="https://snuffelfiets.nl/privacy/" target="_blank"><button mat-button>Privacy</button></a>
                    <a href="https://snuffelfiets.nl/disclaimer/" target="_blank"><button mat-button>Disclaimer</button></a>
                </div>
                <!-- <div fxLayoutAlign="center center" fxFlex="33"></div> -->

                <div fxFlex="50" fxLayoutAlign="center center">
                    <a href="https://www.facebook.com/snuffelfiets/" target="_blank">
                        <button mat-fab color="primary" class="logo-button">
                            <i class="fa fa-facebook-square logo-icon"></i>
                        </button>
                    </a>

                    <a href="https://www.linkedin.com/showcase/snuffelfiets/" target="_blank">
                        <button mat-fab color="primary" class="logo-button">
                            <i class="fa fa-linkedin-square logo-icon"></i>
                        </button>
                    </a>

                    <a href="https://twitter.com/snuffeldata" target="_blank">
                        <button mat-fab color="primary" class="logo-button">
                            <i class="fa fa-twitter-square logo-icon"></i>
                        </button>
                    </a>
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>