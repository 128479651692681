<mat-toolbar class="footer">
  <mat-toolbar-row class="footer-row">
    <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
      <div class="content"
           fxLayout="row wrap"
           fxLayout.lt-md="column"
           fxFlexFill fxFlex="61" fxLayoutAlign="space-between">

        <div fxLayoutAlign="start" fxFlex="26" fxLayoutAlign.lt-md="start">
          <div>
            <h2>Bezoekadres</h2>
              <p class="mat-body-1">
                Gemeente Stichtse Vecht<br>
                Endelhovenlaan 1<br>
                3601 GR Maarssen<br>
            </p>
          </div>
        </div>
        <div fxLayoutAlign="start" fxFlex="21" fxLayoutAlign.lt-md="start">
          <div>
            <h2>Postadres</h2>
            <p class="mat-body-1">
              Postbus 1212<br>
              3600 BE Maarssen<br>
            </p>
          </div>
        </div>
        <div fxLayoutAlign="start" fxFlex="30" fxLayoutAlign.lt-md="start">
          <div>
            <h2>Contact</h2>
            <p class="mat-body-1">
              Telefoon: 14 0346<br>
              Whatsapp:  06 13 53 45 89<br>
              <a href="https://stichtsevecht.nl/contact/" target="_blank">https://stichtsevecht.nl/contact/</a><br>
            </p>
          </div>
        </div>
        <div fxLayoutAlign="start" fxFlex="23" fxLayoutAlign.lt-md="start">
          <div>
            <h2>Service</h2>
            <p class="mat-body-1">
              <a href="https://stichtsevecht.nl/privacy-proclaimer-en-cookies/" target="_blank">Privacy, proclaimer en cookies</a><br>
              <a href="https://stichtsevecht.nl/toegankelijkheid/" target="_blank">Toegankelijkheid</a><br>
            </p>
          </div>
          
        </div>
        <div fxFlex="100" fxLayoutAlign.lt-md="center">
          <div>
            <p>Powered by <a href="https://www.dataplatform.nl/" target="_blank">dataplatform.nl</a></p> 
          </div>
        </div>
      </div>
      
    </div>
  </mat-toolbar-row>
</mat-toolbar>
