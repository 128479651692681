<div fxLayoutAlign="center">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <h1>
      {{ 'DATAPLATFORM.FAQ' | translate }}
      <hr>
    </h1>
    <br>
    <mat-accordion>
      <mat-expansion-panel *ngFor="let question of questions">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <a>{{ question.QUESTION }}</a>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p [innerHTML]="question.ANSWER"></p>   
      </mat-expansion-panel>
    </mat-accordion>    
    <br><br>
  </div>
</div>
