import { Component, OnInit } from '@angular/core';
import { GroupModel } from '@shared/models/group.model';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { NavigationService } from '@shared/services/navigation.service';
import { ApiService } from '@shared/services/api.service';

@Component({
  selector: 'app-dataplatform-preview-groups',
  templateUrl: './dataplatform-preview-groups.component.html',
  styleUrls: ['./dataplatform-preview-groups.component.scss']
})
export class DataplatformPreviewGroupsComponent implements OnInit {

  groups: GroupModel[] = [];
  customerName: string;
  showLoading: boolean;

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private dynamicInjectorService: DynamicInjectorService) {
    this.customerName = this.dynamicInjectorService.getEnvironment().name;
    this.showLoading = true;
  }

  ngOnInit() {
    this.apiService.getThemes().subscribe((groups: GroupModel[]) => {
      groups.forEach(group => {
        group.package_count = null;
        this.groups.push(group);
      });
      let i = 0;
      // tslint:disable-next-line: prefer-const
      for (let groupElement of this.groups) {
        this.apiService.getGroupPackageCount(groupElement.name).then((res) => {
          groupElement.package_count = res.count;
          ++i;
          if (i === this.groups.length) {
            this.showLoading = false;
          }
        });
      }
    });
  }

  addQueryParam(groupName) {
    this.navigationService.toData({queryParams: {['groups.name']: groupName}, queryParamsHandling: 'merge'});
  }

}
