<div fxLayoutAlign="center" class="ieCompat">
  <div fxLayout="column" fxFlex=100 fxFlex.md=100 fxFlex.sm=100 fxFlex.xs=100>
        <br />
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Green Innovation Hub</h1>
        <p>
          De Green Innovation Hub focust op duurzame en inclusieve gebiedsontwikkeling in Almere, de provincie Flevoland 
          en zelfs de rest van Nederland. Het betreft een samenwerking tussen Gemeente Almere, VodafoneZiggo en Provincie 
          Flevoland. De Green Innovation Hub is gehuisvest in het centrum van Almere (noord-campus). De satelliet locatie 
          The Natural Pavilion bevindt zich op het voormalig Floriade terrein (zuid-campus). Het Floriade Kennisprogramma,  
          de ervaring van VodafoneZiggo met andere innovatie-ecosystemen en de casustieken van de gemeente Almere en de 
          provincie Flevoland bieden de Green Innovation Hub en de eraan deelnemende organisaties een relevant netwerk 
          aan partners, data en kennis.
        </p>
        <p>
        </p>
        <br>
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Use Cases</h1>
        <div class="flex-wrap" fxLayout="row">
          <div class="data-keten-container">
            <img class="data-keten-integratie" src="/portal-config/organizations/greeninnovationhub/assets/images/data_keten_integratie.png"/>
            <a href="https://caeli.nl/en/home/" target="_blank"><button mat-button class="round-button" id="caeli"></button></a>
            <a href="https://www.leaffactory.nl/" target="_blank"><button mat-button class="round-button" id="leaf-factory"></button></a>
            <a href="https://www.babylondaken.nl/" target="_blank"><button mat-button class="round-button" id="babylon-daken"></button></a>
            <a href="https://cobraclimate.com/" target="_blank"><button mat-button class="round-button" id="cobra-climate"></button></a>
            <a href="https://test.moestuinplanner.coopoosterwold.wonderbit.com/" target="_blank"><button mat-button class="round-button" id="wonderbit"></button></a>
            <a href="https://thegreeninnovationhub.nl/" target="_blank"><button mat-button class="round-button" id="green-innovation-hub"></button></a>
            <a href="https://collaborall.net/" target="_blank"><button mat-button class="round-button" id="colaborall"></button></a>
            <a href="https://gih.digitwin.app/" target="_blank"><button mat-button class="round-button" id="argaleo"></button></a>
            <a href="https://civity.nl/" target="_blank"><button mat-button class="round-button" id="civity"></button></a>
            <a href="https://www.wecity.nl/nl/sensorregister" target="_blank"><button mat-button class="round-button" id="wecity"></button></a>
            <a href="https://www.vodafoneziggo.nl/" target="_blank"><button mat-button class="round-button" id="vodafone-ziggo"></button></a>
          </div>
        </div>
        <br>
        <h1 class="mat-headline" style="border-bottom: 1px solid #b3b2b2;font-size: 39px;">Partners</h1>
        <div class="flex-wrap" fxLayout="row">
            <app-dataset-card class="partnerTile" altText="" style="margin: 0.7em;" *ngFor="let partner of partners" [style.background-image]="setBackgroundUrl(partner.backgroundURL)">
            <a [href]="partner.redirectionURL" target="_blank"><p class="partnerDescription">{{partner.notes}}</p></a>
            </app-dataset-card>
        </div>
        <br><br>
  </div>
</div>

<!-- Component to display case description on click -->
<div class="dialog-overlay" [ngClass]="{'open': showCaseDialog}"></div>
<div class="case-dialog" [ngClass]="{'open': showCaseDialog}">
  <div class="case-dialog-content">
    <h2>{{ caseTitle }} </h2>
    <p class="markdown-content" [innerHTML]="caseDescription"></p>
    <button mat-raised-button (click)="closeCaseDialog()">Sluiten</button>
  </div>
</div>
