<div fxLayoutAlign="center" class="ieCompat">
    <div fxLayout="column" fxFlex=100>
        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="30px">
            <div fxLayout="column" fxFlex=65>
                <br/>
                <h1 class="mat-headline" style="border-bottom: 1px solid #0571b3;">Welkom bij het Urban Data Platform
                    van het Innovatielab Den Bosch</h1>
                <p style="text-align: justify;">
                    Het Innovatie Kwartier Den Bosch (iKDB) is dé plek waar innovatie en samenwerking centraal staan.
                    Gevestigd in het historische Grenco-gebouw, biedt het iKDB een inspirerende omgeving voor het
                    ontwikkelen van slimme oplossingen en het delen van kennis.
                </p>
                <p class="mat-h2">Innovatielab en IoT Stadslab</p>
                <p style="text-align: justify;">
                    Het Innovatielab, onderdeel van het iKDB, is een broedplaats voor innovatie en creativiteit. Hier
                    werkt het IoT Stadslab namens de gemeente 's-Hertogenbosch samen met diverse partners zoals Avans
                    Hogeschool, HAS Hogeschool, Jheronimus Academy of Data Science (JADS), BIM en SPARK Campus. Deze
                    samenwerking zorgt voor een dynamische omgeving waar onderwijs, bedrijfsleven en overheid samenkomen
                    om maatschappelijke uitdagingen aan te pakken.
                </p>
                <p class="mat-h2">Den Bosch: Toonaangevende Datastad</p>
                <p style="text-align: justify;">
                    's-Hertogenbosch heeft zich gepositioneerd als een toonaangevende datastad in Nederland. Het Urban
                    Data Platform (UDP) speelt hierin een cruciale rol. Gebaseerd op de FIWARE-technologie en gehost
                    door Civity, biedt het UDP een robuuste en flexibele infrastructuur voor data-gedreven innovaties.
                    Verschillende onderwijsinstellingen en bedrijven maken gebruik van dit platform om hun projecten en
                    onderzoek te ondersteunen.
                </p>
                <br>
                <p class="mat-h2">Samenwerking en Innovatie</p>
                <p style="text-align: justify;">
                    Het iKDB en het Urban Data Platform stimuleren samenwerking tussen verschillende sectoren. Door
                    hybride leeromgevingen te creëren, kunnen studenten en professionals van elkaar leren en samen
                    werken aan innovatieve oplossingen. Dit maakt het iKDB een unieke plek waar theorie en praktijk
                    samenkomen, en waar de toekomst van 's-Hertogenbosch als slimme en duurzame stad wordt vormgegeven.
                </p>
                <br>
                <p class="mat-h2">Presentatie van de Data in het Innovatie Kwartier Den Bosch</p>
                <p style="text-align: justify;">
                    De basis van het Urban Data Platform (UDP) in het Innovatie Kwartier Den Bosch zijn de datasets die
                    u kunt gebruiken voor eigen analyses of toepassingen. Wij bieden deze data op verschillende manieren
                    aan: als downloadbaar bestand, maar ook via een API. Ons dataplatform biedt ook de mogelijkheid om
                    data op verschillende manieren te visualiseren. Dit is een extra manier om op een makkelijke manier
                    een indruk te krijgen van de inhoud van datasets.
                </p>
                <p style="text-align: justify;">
                    In de toekomst zullen deze mogelijkheden verder worden uitgebreid zodat u deze visualisaties ook
                    kunt hergebruiken. In de documentatie leest u daar meer over. De komende periode zullen wij
                    hieronder al een aantal voorbeelden plaatsen hoe datasets op verschillende manieren gepresenteerd
                    kunnen worden.
                </p>
                <p style="text-align: justify;">
                    Het Innovatie Kwartier Den Bosch gebruikt onze eigen open data om het gebruik van open data
                    eenvoudiger te maken. Met de Omgevingswet in het vooruitzicht bieden wij een platform waar kaarten
                    en grafieken gemaakt met open data aangeboden worden aan burgers, bedrijven, onderzoekers en
                    journalisten. Door het combineren van data en techniek richten we ons ook op de verslimming van de
                    buitenruimte, waardoor we bijdragen aan een slimme en duurzame stad.
                </p>
                <br>
            </div>
        </div>
    </div>
</div>
