<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
      <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
        <div class="content"
             fxLayout="row wrap"
             fxLayout.lt-md="column"
             fxFlexFill fxFlex="61" fxLayoutAlign="space-between">

          <div fxFlex="20">
            <p class="mat-subheading-1">Bezoekadres</p>
            <p class="mat-body-1">
              Stadskantoor<br>
              Leyweg 813<br>
              2541 HA Den Haag<br>
            </p>

          </div>
          <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
            <div>
            </div>
          </div>
          <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
            <div>
              <p class="mat-subheading-1">Contact</p>
              <p class="mat-body-1">
                  <a href="mailto:datashop@denhaag.nl">datashop@denhaag.nl&nbsp;</a><br>
              </p>
            </div>
          </div>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
