<!-- Map -->
<div #mapElement class="map" id="mapCanvas">
</div>

<!-- Click Feature Popup-->
<div id="popup" class="ol-popup app-background">
  <a href="#" id="popup-closer" class="ol-popup-closer row"><mat-icon>cancel</mat-icon></a>
  <div class="popup-content row">
    <div *ngFor="let feature of mapService.getSelectedFeaturesProperties() | paginate : {
                        itemsPerPage: 1,
                        currentPage: mapService.featurePopupDefaultPage,
                        totalItems: mapService.getSelectedFeaturesCount()
                      };
                let i = index">
      <div class="featureLayerName" value="{{ feature.id }}"><i>{{ feature.title }}</i>
        <hr>
      </div>
      <div *ngFor="let obj of feature.content">
        <div *ngIf="obj.key != 'layer_id'">
          <b>{{obj.key}}</b>:
          <ng-container *ngIf="isURL(obj.value) else STRING">
            <u style="color:blue;"><a [href]="obj.value" target="_blank">link</a></u>
          </ng-container>
          <ng-template #STRING>{{obj.value}}</ng-template>
        </div>
      </div>
      <hr *ngIf="mapService.getSelectedFeaturesCount() > 1">
    </div>
    <div class="featurePopupPagination" *ngIf="mapService.getSelectedFeaturesCount() > 1">
      <pagination-controls previousLabel="" nextLabel="" (pageChange)="mapService.featurePopupPageChange($event)">
      </pagination-controls>
    </div>
  </div>
</div>

<!-- Legend Control -->
<div id="toggleLegend" class="ol-legend ol-unselectable ol-control ol-collapsed">
  <button type="button" id="toggleLegendButton" title="{{'MAP_TAB.TABLE_OF_CONTENTS' | translate}}"
    (click)="toggleLegendBox()">
    <mat-icon id="legendIcon">legend_toggle</mat-icon>
  </button>
</div>
<div id="legendBox">
  <div id="legendLayerList" *ngFor="let legendContent of mapService.getAllLayerLegendContent()">
    <div>
      <div class="layerNameInput" id={{legendContent.layerName}}>
        <mat-icon (click)="toggleLegendGraph(legendContent.position)" class="toggleLegendGraph"
          id="toggleLegendGraph{{legendContent.position}}">arrow_right</mat-icon>
        <label>
          <input type="checkbox" checked (change)="toggleLayerVisibility(legendContent.layerName)">
          <b>{{ legendContent.title }}</b>&nbsp;
        </label>
        <mat-icon id="legendTimestamp" *ngIf="legendContent.timestamp">date_range</mat-icon>
      </div>
      <div class="legendGraph" id="legendGraph{{legendContent.position}}" *ngIf="legendContent.graphicURL">
        <img class="graphicURLImg" src="{{legendContent.graphicURL}}" alt="{{legendContent.title}}">
      </div>
    </div>
  </div>
</div>


<!-- TimeStamp Control -->
<div id="toggleTimestamp" class="ol-timestamp ol-unselectable ol-control ol-collapsed"
  [ngStyle]="setTimeStampControlStyles()">
  <button type="button" id="toggleTimestampButton" title="Toggle Date Picker" (click)="toggleTimestampBox()">
    <mat-icon id="timestampIcon">date_range</mat-icon>
  </button>
</div>
<div class="row" id="timestampBox" [ngStyle]="setTimeStampBoxStyles()">
  <div *ngIf="hasTimestampLayers()">
    <mat-grid-list [formGroup]="timestampBox" cols="6" rowHeight="1.55em">
      <mat-grid-tile colspan="4">
        <mat-form-field id="startDate">
          <input matInput [matDatepicker]="startDate" formControlName="startDate"
            (dateInput)="getStartDateValue($event)">
          <mat-datepicker-toggle matSuffix [for]="startDate" title="Select start date"></mat-datepicker-toggle>
          <mat-datepicker #startDate></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <button type="button" id="toggleLockDateButton" title="Lock/unlock date range" (click)="toggleLockDate()">
          <mat-icon id="toggleLockDateButtonIcon">lock</mat-icon>
        </button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
      </mat-grid-tile>
      <mat-grid-tile colspan="4" [ngStyle]="!lockStatus ? {'pointer-events': 'none', 'color': 'grey'}:{}">
        <mat-form-field id="endDate">
          <input matInput [matDatepicker]="endDate" formControlName="endDate" (dateInput)="getEndDateValue($event)">
          <mat-datepicker-toggle matSuffix [for]="endDate" title="Select end date"></mat-datepicker-toggle>
          <mat-datepicker #endDate></mat-datepicker>
        </mat-form-field>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" [ngStyle]="!lockStatus ? {'display': 'none'}:{'display': 'inline'}">
        <button type="button" id="refreshDateRange" title="Refresh date range" (click)="refreshDateRange()">
          <mat-icon id="refreshDateRangeButton">refresh</mat-icon>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<!-- Draw Control -->
<div id="toggleDraw" class="ol-draw ol-unselectable ol-control ol-collapsed" [ngStyle]="setDrawControlStyles()">
  <button type="button" id="toggleDrawButton" title="Toggle Drawing" (click)="toggleDrawBox()">
    <mat-icon id="toggleDrawIcon">edit</mat-icon>
  </button>
</div>
<div class="row" id="drawBox">
  <div *ngIf="displayDrawControl()">
    <mat-grid-list cols="1" rowHeight="1.55em">
      <mat-grid-tile colspan="1">
        <button type="button" id="drawRectangle" title="Draw a rectangle bounding box"
          (click)="selectDrawType('Rectangle')"
          [ngStyle]="drawSelected() == 'Rectangle' ? {'background-color': 'rgba(220,170,0,0.5'}:{'background-color': 'white'}">
          <mat-icon class="drawIcon">filter_center_focus</mat-icon>
        </button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <button type="button" id="drawCircle" title="Draw a circle bounding box" (click)="selectDrawType('Circle')"
          [ngStyle]="drawSelected() == 'Circle' ? {'background-color': 'rgba(220,170,0,0.5'}:{'background-color': 'white'}">
          <mat-icon class="drawIcon">filter_tilt_shift</mat-icon>
        </button>
      </mat-grid-tile>
      <mat-grid-tile colspan="1">
        <button type="button" id="drawPolygon" title="Draw a polygon bounding box" (click)="selectDrawType('Polygon')"
          [ngStyle]="drawSelected() == 'Polygon' ? {'background-color': 'rgba(220,170,0,0.5'}:{'background-color': 'white'}">
          <mat-icon class="drawIcon">timeline</mat-icon>
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>