import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy, ViewEncapsulation} from '@angular/core';
import { MapService } from './map.service';
import * as _ from 'lodash';
import { MatDatepickerInput } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DatasetsService } from '@shared/services/datasets.service';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup,FormControl} from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';

@Component({
  selector: 'app-map-preview',
  templateUrl: './map-preview.component.html',
  styleUrls: ['./map-preview.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapPreviewComponent implements OnInit, OnDestroy {

  @Input() format: any;
  @Input() wms_url: string;
  @Input() wfs_url: string;
  @Input() layer_extent: string;
  @Input() layer_srid: string;
  @Input() resourceUrl: string;
  @Input() geoResources: Array<any> = [];
  @ViewChild('mapElement') mapElement: ElementRef;
  @ViewChild('popup') popup: ElementRef;
  @ViewChild('popupContent') popupContent: ElementRef;
  @ViewChild('popupCloser') popupCloser: ElementRef;

  public timestampBox : FormGroup;
  public legendBox: FormGroup;
  date;
  lockStatus: boolean = false;
  legendBoxControl: boolean = false;
  legendGraphVisibility: boolean = false;
  toggleLegendControl: boolean = true;
  timestampBoxControl: boolean = false;
  toggleTimestampControl: boolean = true;
  drawBoxControl: boolean = false;
  toggleDrawControl: boolean = true;
  
  constructor(public mapService: MapService,
              public dialog: MatDialog,
              private datasetsService: DatasetsService,
              private dynamicInjector: DynamicInjectorService,
              private translateService: TranslateService,
              private matSnackBar: MatSnackBar,
              private dateAdapter: DateAdapter<Date>
              ) {
                this.dateAdapter.setLocale('en-NL'); //dd/MM/yyyy
                this.timestampBox = new FormGroup({
                  startDate:new FormControl(this.date),
                  endDate:new FormControl(this.date),
                  setDate:new FormControl(),
                })
  }

  translateTooltips() {
    const buttons = this.mapElement.nativeElement.querySelectorAll('button[title^="MAP_TAB"], button[translate]');
    const translations = [];
    for (const button of buttons) {
      if (!button.hasAttribute('translate')) {
        button.setAttribute('translate', button.getAttribute('title'));
      }
      translations.push(button.getAttribute('translate'));
    }
    this.translateService.get(translations).subscribe((res: string) => {
      for (const button of buttons) {
        button.setAttribute('title', res[button.getAttribute('translate')]);
      }
    });
  }

  renderMap() {
    // init map service
    this.mapService.initMap()
    // add geometry to initialize map
    let startDate = new Date()
    let endDate =  this.getNextDate(startDate)
    this.timestampBox.controls['startDate'].setValue(startDate);
    this.timestampBox.controls['endDate'].setValue(endDate);
    // init available layers
    this.mapService.renderLayers(this.geoResources);
  }

  toggleLayerVisibility(layerName) {
    this.mapService.updateLayerVisibility(layerName);
  }

  toggleLegendBox(){
    let toggleLegendButton : HTMLElement = document.getElementById(`toggleLegendButton`);
    let legendBox : HTMLElement = document.getElementById(`legendBox`);
    this.legendBoxControl = !this.legendBoxControl;
    this.toggleLegendControl = !this.toggleLegendControl;

    if (this.legendBoxControl){
      toggleLegendButton.style.backgroundColor='rgba(0,128,0,0.5';
      legendBox.style.display='inline';
    }else{
      toggleLegendButton.style.backgroundColor='white';
      legendBox.style.display='none';
    }
  }

  toggleLegendGraph(pos){
    this.legendGraphVisibility = !this.legendGraphVisibility;
    let buttonIcon : HTMLElement = document.getElementById(`toggleLegendGraph${pos}`);
    let graphElement : HTMLElement = document.getElementById(`legendGraph${pos}`);
    if (this.legendGraphVisibility) {
      buttonIcon.textContent = 'arrow_right'
      graphElement.style.display = "none";
    } 
    else {
      buttonIcon.textContent = 'arrow_drop_down'
      graphElement.style.display = "block";
    }
  }
  
  displayDrawControl(): boolean{
    return this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.drawFilter;
  }

  hasTimestampLayers(): boolean {
    for (const resource of this.geoResources) {
      if (resource.timestamp_identifier){
        return true
      }
    }
    return false
  }

  setDrawControlStyles(){
    let styles = {};
    styles['display'] = 'none'
    if (this.displayDrawControl()){
      styles['display'] = 'inline'
    }
    return styles
  }

  setDrawShapeSelectedStyles(){
    let styles = {};

  }
  setTimeStampControlStyles(){
    let styles = {};
    styles['display'] = 'none'
    styles['right'] = '2.2em'
    if (this.hasTimestampLayers()){
      styles['display'] = 'inline'
      if (this.displayDrawControl()){
        styles['right'] = '3.9em'
      }
    }
    return styles
  }

  setTimeStampBoxStyles(){
    let styles = {};
    styles['right'] = '2.2em'
    if (this.displayDrawControl()){
      styles['right'] = '3.9em'
    }
    return styles
  }

  toggleDrawBox(){
    let toggleDrawButton : HTMLElement = document.getElementById(`toggleDrawButton`);
    let drawBox : HTMLElement = document.getElementById(`drawBox`);
    this.drawBoxControl = !this.drawBoxControl
    this.toggleDrawControl = !this.toggleDrawControl
    if (this.drawBoxControl){
      toggleDrawButton.style.backgroundColor='rgba(220,170,0,0.5)';
      drawBox.style.display='inline';
    }else{
      toggleDrawButton.style.backgroundColor='white';
      drawBox.style.display='none';
      this.mapService.disableMapDrawing()
    }
  }

  selectDrawType(type:string):void{
    this.mapService.enableMapDrawing(type);
  }

  drawSelected(): string {
    return this.mapService.drawTypeSelected
  }
  toggleTimestampBox(){
    let toggleTimestampButton : HTMLElement = document.getElementById(`toggleTimestampButton`);
    let timestampBox : HTMLElement = document.getElementById(`timestampBox`);
    
    this.timestampBoxControl = !this.timestampBoxControl
    this.toggleTimestampControl = !this.toggleTimestampControl
    // timestampBox toggle for fullscreen proper style.display
    let toggleOverlayDisplay = Array.from(document.getElementsByClassName('cdk-overlay-container')as HTMLCollectionOf<HTMLElement>);
    if (this.timestampBoxControl){
      toggleTimestampButton.style.backgroundColor='rgba(0,128,0,0.5)';
      timestampBox.style.display='inline';
      toggleOverlayDisplay.forEach((element) => {
        element.style.display = 'inline';
      });
      this.refreshDateRange()
    }
    else {
      toggleTimestampButton.style.backgroundColor='white'
      timestampBox.style.display='none';
      toggleOverlayDisplay.forEach((element) => {
        element.style.display = 'none';
      });
      this.refreshWithoutDateRange()
    }
  }

  toggleLockDate(){
    // When lock again, replace with 1-day interval again
    let buttonIcon : HTMLElement = document.getElementById("toggleLockDateButtonIcon");
    if (this.lockStatus) {
      let currentDate = this.timestampBox.controls['startDate'].value
      let nextDate = this.getNextDate(currentDate)
      this.timestampBox.controls['endDate'].setValue(nextDate)
      let startDateString = currentDate.toISOString()
      let endDateString = nextDate.toISOString()
      this.mapService.refreshMap(startDateString, endDateString);
      buttonIcon.textContent = 'lock'
    } 
    else {
      buttonIcon.textContent = 'lock_open'
    }
    this.lockStatus = !this.lockStatus
  }

  refreshDateRange(){
    let startDate = this.timestampBox.controls['startDate'].value
    let endDate = this.timestampBox.controls['endDate'].value
    let startDateString = startDate.toISOString()
    let endDateString = endDate.toISOString()
    this.mapService.refreshMap(startDateString, endDateString);
  }

  refreshWithoutDateRange(){
    this.mapService.refreshMap(null, null);
  }
  
  getNextDate(input: Date){
    let nextDate = new Date(input)
    nextDate.setDate(nextDate.getDate()+1)
    return nextDate
  }
  getStartDateValue(input: MatDatepickerInput<Date>){
    let startDate = input.value
    let endDate = this.getNextDate(startDate)
    let startDateString = startDate.toISOString()
    
    if (!this.lockStatus) {
      this.timestampBox.controls['endDate'].setValue(endDate);
      let endDateString = endDate.toISOString()
      this.mapService.refreshMap(startDateString, endDateString);
    }
    else {
      let tempEndDate = this.timestampBox.controls['endDate'].value
      if (tempEndDate < startDate){
        this.timestampBox.controls['endDate'].setValue(endDate);
      }
    }
  }
  
  isURL(string){
    let isURL = new RegExp('^https?://(?:www\\.)?[\\w.-]+(?:\\.[a-zA-Z]{2,})+(?:[\\w\\W]*)$');
    if (isURL.test(string)) {
      return true;
    } else {
      return false
    }
  }
  displayFeatureContentValue(value){
    let isURL = new RegExp('^https?://(?:www\\.)?[\\w.-]+(?:\\.[a-zA-Z]{2,})+(?:[\\w\\W]*)$');

    // Check if the input string matches URL pattern
    if (isURL.test(value)) {
        // If it's a URL, create a link within a div
        return this.constructLink(value);
    } else {
        // If it's not a URL, just wrap it in a div
        return value
    }
  }

  constructLink(value) {
    var link = document.createElement('a'); // Create <a> element
    link.href = value; // Set href attribute
    link.target = '_blank'; // Open link in new tab
    link.textContent = 'link'; // Set link text
    return link.outerHTML; // Return the HTML string representation of the <a> element
}

  isTimestampEnabled(geoResources):boolean{
    return true
  }

  getEndDateValue(input: MatDatepickerInput<Date>){
    let endDate = input.value
    let tempStartDate = this.timestampBox.controls['startDate'].value
    if (endDate < tempStartDate){
      this.timestampBox.controls['endDate'].setValue(this.getNextDate(tempStartDate));
    }
  }

  ngOnInit(): void {
    this.translateService.onLangChange.subscribe(res => {
       this.translateTooltips();
    });
    this.renderMap();
  }

  ngOnDestroy(): void {
    this.matSnackBar.dismiss();
  }
}
