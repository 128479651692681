<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
      <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
        <div class="content"
             fxLayout="row wrap"
             fxLayout.lt-md="column"
             fxFlexFill fxFlex="61" fxLayoutAlign="space-between">

          <div fxFlex="20">
            <p class="mat-subheading-1">Postadres</p>
            <p class="mat-body-1">
              Postbus 1501<br>
              3100 EA Schiedam<br>
            </p>

          </div>
          <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
              <div fxFlex="20">
                  <p class="mat-subheading-1">Bezoekadres</p>
                  <p class="mat-body-1">
                    Stadskantoor<br>
                    Stadserf 1<br>
                    3112 DZ Schiedam<br>
                  </p>

                </div>
          </div>
          <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
            <div>
              <p class="mat-subheading-1">Contact</p>
              <p class="mat-body-1">
                  Tel: 14 010<br>
                  (werkdagen van 8.30 tot 17.00)<br>
                  <br>
                  <a href="https://www.schiedam.nl/a-tot-z/contact-opnemen" target="_blank">Maak direct een afspraak</a>
              </p>
            </div>
          </div>

          <p> <img alt="" height="15" src="/assets/images/dataplatformIco.png" style="line-height:20.8px" width="15"> Powered by <a href="https://www.dataplatform.nl/" target="_blank">dataplatform.nl</a><br></p>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
