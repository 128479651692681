import { Component, OnInit, AfterViewInit, Inject, ElementRef } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';
@Component({
  selector: 'app-nieuwegein-home-text',
  templateUrl: './nieuwegein-home-text.component.html',
  styleUrls: ['./nieuwegein-home-text.component.scss']
})
export class NieuwegeinHomeTextComponent implements OnInit, AfterViewInit, ConfigInterface {

  constructor(@Inject(DOCUMENT) private document, private elementRef: ElementRef) {
  }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    const script = this.document.createElement('script');
    script.src = 'https://platform.twitter.com/widgets.js';
    this.elementRef.nativeElement.appendChild(script);
  }
}
