import * as _ from 'lodash';
import { ApiService } from '@shared/services/api.service';
import { BaseMapConfig } from '@shared/models/organization.config.model';
import { bbox, intersects, and, during } from 'ol/format/filter';
import { circular } from 'ol/geom/Polygon';
import { createBox } from 'ol/interaction/Draw';
import { defaults } from 'ol/control';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { EPSG_DEFINITIONS } from 'assets/map/epsg.definitions'
import { extend } from 'ol/extent.js';
import { Extent, getCenter } from 'ol/extent';
import { getDistance} from 'ol/sphere';
import { getTopLeft, getWidth } from 'ol/extent';
import { Injectable } from '@angular/core';
import { register } from 'ol/proj/proj4';
import { ResourceModel } from '@shared/models/resource.model';
import { transformExtent, transform } from 'ol/proj';
import Circle from 'ol/geom/Circle';
import Control from 'ol/control/Control';
import Draw from 'ol/interaction/Draw';
import Filter from 'ol/format/filter/Filter';
import FullScreen from 'ol/control/FullScreen';
import GML3 from 'ol/format/GML3';
import MapBrowserEvent from 'ol/MapBrowserEvent';
import OlMap from 'ol/Map';
import Overlay from 'ol/Overlay';
import Point from 'ol/geom/Point.js';
import Polygon from 'ol/geom/Polygon';
import proj4 from 'proj4';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import WFS from 'ol/format/WFS';
import WKB from 'ol/format/WKB';
import WKT from 'ol/format/WKT';
import WMTS from 'ol/source/WMTS';
import WMTSTileGrid from 'ol/tilegrid/WMTS';
import XYZ from 'ol/source/XYZ';

// Populated from National Information Exchange Model (NIEM) XML vocabulary information (http://www.datypic.com/sc/niem21/ss.html)
const DEFAULT_CLICK_RADIUS : number = 1;
const baseMapDefaultEpsg:string = 'EPSG:3857';
const baseMapDefaultExtent:Extent = [-20026376.39, -20048966.10, 20026376.39, 20048966.10];
const filterBboxLimit:number = 0.1 // 0.1 of kilometer
const WMS_VERSION:string = '1.1.0'
const WFS_VERSION:string = '2.0.0'
const MAP_TILES_URL:string = 'https://stamen-tiles-b.a.ssl.fastly.net/terrain/{z}/{x}/{y}.png';
const OL_CONTROL_CLASS:string = 'ol-control';
const POPUP_ID:string = 'popup';
const WFS_STATUS_ID:string = 'service_status';
const TOGGLE_LEGEND_ID:string = 'toggleLegend';
const DEFAULT_ZOOM_LEVEL: Extent = [5,45,15,60];
const gmlGeometryComplexTypes:string[] = [
  "gml:AbstractCurveType", // geometryBasic (http://www.datypic.com/sc/niem21/s-geometryBasic0d1d.xsd.html)
  "gml:AbstractGeometricPrimitiveType",
  "gml:AbstractGeometryType",
  "gml:CurveArrayPropertyType",
  "gml:CurvePropertyType",
  "gml:DirectPositionListType",
  "gml:DirectPositionType",
  "gml:EnvelopeType",
  "gml:GeometricPrimitivePropertyType",
  "gml:GeometryArrayPropertyType",
  "gml:GeometryPropertyType",
  "gml:LineStringType",
  "gml:PointArrayPropertyType",
  "gml:PointPropertyType",
  "gml:PointType",
  "gml:VectorType",
  "gml:AbstractGeometricAggregateType", // geometryAggregates (http://www.datypic.com/sc/niem21/s-geometryAggregates.xsd.html)
  "gml:MultiCurvePropertyType",
  "gml:MultiCurveType",
  "gml:MultiGeometryPropertyType",
  "gml:MultiGeometryType",
  "gml:MultiPointPropertyType",
  "gml:MultiPointType",
  "gml:MultiSolidPropertyType",
  "gml:MultiSolidType",
  "gml:MultiSurfacePropertyType",
  "gml:MultiSurfaceType"
]


export class SelectedObjectProperty {
  key: string;
  value: string;
}

@Injectable()
export class MapService {

  private map: OlMap;
  private wmsLayer: TileLayer<any>;
  private wmsServiceURL: string;
  private owsServiceParams: URLSearchParams;
  private layerExtent: Extent;
  private legendContent: Array<any> = [];
  private epsg: string;
  private transformedExtent: Extent;
  private timestampFilterEnabled: boolean = false;
  private timestampFilterStartDate: string;
  private timestampFilterEndDate: string;
  selectedObjectProperties: SelectedObjectProperty[] = [];
  private baseMapEpsg: string;
  selectedFeaturesProperties: Array<any>;
  vectorLayerDict: { [name: string]: string } = {};
  featurePopupDefaultPage: number = 1;
  pageSize = 1;
  count = 2;
  baseMapConfig: BaseMapConfig = this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.baseMapConfig 
  drawingEnabled = false;
  drawBorderStroke = new Style({
    stroke: new Stroke({
      color: 'rgba(255, 255, 255, 0.8)',
      width: 4
    }),
  })
  drawMainStroke = new Style({
    stroke: new Stroke({
      color: 'rgba(0, 153, 255, 0.8)',
      width: 3,
      lineDash: [4,8],
      lineDashOffset: 6
    }),
  })
  drawSource = new VectorSource({wrapX: false});
  drawLayer = new VectorLayer({
    source: this.drawSource, 
    style: [this.drawBorderStroke, this.drawMainStroke]
  });
  drawTool: Draw; // Draw object to enable/disable interaction with map.
  drawTypeSelected; // Store shape selected for drawing.
  drawFeatureGeometry; // Store WKB geometry of drawn bounding box.
  
  constructor(
    private apiService: ApiService,
    private dynamicInjector: DynamicInjectorService
    ) {
      this.populatePopup = this.populatePopup.bind(this)
  }
  
  private getBaseMapEpsg(): string{
    const projectionEpsg = `EPSG:${this.baseMapConfig.projection}`;
    // add new projection to proj4 definitions
    proj4.defs(projectionEpsg, this.getEpsgConfig(this.baseMapConfig.projection));
    // update proj4 definitions
    register(proj4)
    return projectionEpsg
  }

  public initMap(): void {
    this.baseMapEpsg = this.getBaseMapEpsg();
    const container: HTMLElement = document.getElementById(POPUP_ID)
    const closer: HTMLElement = document.getElementById('popup-closer');
    
    closer.onclick = function () {
      overlay.setPosition(undefined);
      closer.blur();
      return false;
    };

    const overlay: Overlay = new Overlay(({
      element: container,
      stopEvent: true,
      autoPan: true
    }));

    this.map = new OlMap({
      layers: [this.constructBaseMapLayer()],
      target: 'mapCanvas',
      controls: defaults({
        zoom: true,
        zoomOptions: {zoomInTipLabel: 'MAP_TAB.ZOOM_IN', zoomOutTipLabel: 'MAP_TAB.ZOOM_OUT'},
        attribution: true,
        attributionOptions: {tipLabel: 'MAP_TAB.ATTRIBUTION'},
        rotate: false,
      }),
      overlays: [overlay],
      view: new View({
        projection: this.baseMapEpsg,
        maxZoom :20})
    });

    this.map.getControls().extend([new FullScreen({})])
    this.setMapEvents(overlay);

    // Control Legend
    const toggleLegend : HTMLElement = document.getElementById(TOGGLE_LEGEND_ID);
    const toggleLegendControl : Control = new Control({
      element: toggleLegend
    });
    this.map.addControl(toggleLegendControl);
    const legendBox : HTMLElement = document.getElementById('legendBox');
    legendBox.className = OL_CONTROL_CLASS;
    const legendBoxControl: Control = new Control({
        element: legendBox
    });
    this.map.addControl(legendBoxControl);
  }

  enableMapDrawing(shape){
    this.drawTypeSelected = shape
    if (this.drawingEnabled){
      this.resetMapDrawing()
    }
    else{
      this.drawingEnabled = true;
    }
    let drawType;
    let drawGeometryFunction;
    switch(shape) { 
      case 'Rectangle': { 
        drawType = 'Circle';
        drawGeometryFunction = createBox();
        break; 
      } 
      case 'Circle': { 
        drawType = 'Circle';
        break; 
      } 
      case 'Polygon': { 
        drawType = 'Polygon';
        break; 
      }
      default: { 
        break; 
      }
    } 
    this.drawTool = new Draw({
      source: this.drawSource,
      type: drawType,
      geometryFunction: drawGeometryFunction
    });
    this.onClickDrawActions()
    this.map.addInteraction(this.drawTool);
  }
  
  disableMapDrawing(){
    this.drawTypeSelected = null;
    this.drawingEnabled = false;
    this.resetMapDrawing()
  }

  resetMapDrawing(){
    /*
    Prepare new drawing action by cleaning draw hooks, cleaning the layer's source.
    */
    this.map.removeInteraction(this.drawTool);
    this.drawSource.clear()
  }

  private constructBaseMapLayer():TileLayer<any>{
    let baseMapLayer: TileLayer<any>;
    if (this.baseMapConfig.standard.toLowerCase() === 'xyz'){
      // Load XYZ tileset
      baseMapLayer = new TileLayer({
        source: new XYZ({
          url: this.baseMapConfig.endpoint[this.dynamicInjector.getEnvironment().environment],
          attributions: this.baseMapConfig.attributions,
          crossOrigin: null,
        }),
      });
    }
    else if (this.baseMapConfig.standard.toLowerCase() === 'wmts'){
      // load WMTS tile grid 
      const baseMapExtent = this.baseMapConfig.extent;
      const tileSizePixels = 256;
      const tileSizeMtrs = getWidth(baseMapExtent) / tileSizePixels;
      const matrixIds = [];
      const resolutions = [];
      for (let i = 0; i <= 15; i++) {
        matrixIds[i] = `${this.baseMapEpsg}:${i}`;
        resolutions[i] = tileSizeMtrs / Math.pow(2, i);
      }
      // console.debug(resolutions) // This is helpful to calculate resolutions for an unknown crs gridset configuration on geoserver side)
      const tileGrid = new WMTSTileGrid({
        origin: getTopLeft(baseMapExtent),
        resolutions: resolutions,
        matrixIds: matrixIds,
    })
      baseMapLayer = new TileLayer({
        opacity: 0.7,
        source: new WMTS({
          attributions: this.baseMapConfig.attributions,
          url: this.baseMapConfig.endpoint[this.dynamicInjector.getEnvironment().environment],
          layer: this.baseMapConfig.layer,
          format: this.baseMapConfig.format,
          matrixSet: this.baseMapEpsg,
          projection: this.baseMapEpsg,
          tileGrid: tileGrid,
          style: 'default',
          wrapX: false
        }),
      })
    }
    return baseMapLayer
  }

  private setMapEvents(overlay: Overlay):void {

    this.map.on('singleclick', (evt: MapBrowserEvent<MouseEvent>) => {
      if (!this.drawingEnabled){
        this.onClickWFSActions(evt, overlay)
      }
    })
  };

  private onClickDrawActions():void{
    /* 
    When drawing is enabled, keep only one drawing(feature) at a time.
    Overwrite previous drawing(feature) on new drawing click.
    */
    this.drawTool.on('drawstart', () => {
      // If feature exists already, erase it.
      if (this.drawSource.getFeatures().length > 0){
        this.drawSource.clear()
      }
    });
    this.drawTool.on('drawend', function (e) {
      this.drawFeatureGeometry = null;
      if (this.drawTypeSelected != 'Circle'){
        const polygonFeature: Polygon = e.feature.getGeometry()
        this.drawFeatureGeometry = polygonFeature
      }
      else{
        const circleFeature: Circle = e.feature.getGeometry()
        this.drawFeatureGeometry = this.convertCircleFeatureToPolygonFeature(circleFeature)
      }
    }.bind(this))
  }

  private async onClickWFSActions(evt: MapBrowserEvent<MouseEvent>, overlay: Overlay):Promise<void> {
    /*
    - Get click event coordinates.
    - Return single feature closest to location clicked.
    - Highlight feature on map.
    - Display popup with feature information.
    */

    let vectorLayerList: Array<any> = [];
    this.map.getLayers().forEach(function (layer: any) {
        if (layer.getProperties().service === 'wfs' && layer.getVisible()){
          vectorLayerList.push(layer);
      }
    })

    if (vectorLayerList.length === 0){
      return
    }
    this.hideDetailsPopup();
    let featuresFoundArray: Array<object> = [];
    this.vectorLayerDict = {};
    // get closest feature for every layer activated
    for ( let featureLayer of vectorLayerList){
      
      let featureReturned = this.fetchSelectedFeatures(featureLayer, evt, overlay)
      featuresFoundArray.push(featureReturned);
      this.vectorLayerDict[featureLayer.getClassName] = featureLayer.getProperties().attributes.layerName
    }
    try {
      const result = await Promise.all(featuresFoundArray)
      this.populatePopup(result,overlay, evt.coordinate);
    }
    catch(error) {
      console.warn(error);
    }
  }

  private fetchSelectedFeatures(featureLayer: any, evt: MapBrowserEvent<MouseEvent>, overlay: Overlay): Promise<object>{
    let featuresFound: {[key: string]: any} = {};
    let featureLayerAttributes = featureLayer.getProperties().attributes;

    // generate a GetFeature request body
    const serviceWFS:WFS = new WFS({
      gmlFormat: new GML3()
    });

    // generate spatial filter to retrieve feature closest to evt coordinates
    let spatialFilter:Filter = this.constructSpatialFilter(evt, featureLayerAttributes)
    
    const featureRequest:any = serviceWFS.writeGetFeature({
      srsName: this.baseMapEpsg,
      featureNS: 'http://www.opengis.net/wfs',
      featurePrefix: featureLayerAttributes.featurePrefix,
      featureTypes: [featureLayerAttributes.featureType],
      outputFormat: 'gml3',
      maxFeatures: 10,
      filter: spatialFilter
    });
    // then post the request and populate the empty WFS layer with a single feature
    return fetch(featureLayerAttributes.serviceURL, {
      method: 'POST',
      body: new XMLSerializer().serializeToString(featureRequest),
    })
      .then(function (response : Response) : Promise<string> {
        return response.text();
      })
      .then(function (xmlText : string) : any {
        const xml:Document = new DOMParser().parseFromString(xmlText, "text/xml")
        const gmlFeatures : Array<any> = serviceWFS.readFeatures(xml, {
          featureProjection: featureLayerAttributes.layerSrid,
          dataProjection: this.baseMapEpsg
        });
        var closestFeature: any;
        
        featureLayer.getSource().clear();
        // this.hideDetailsPopup(); 
        if (gmlFeatures.length == 0 ){
          console.warn(`No feature found for layer: ${featureLayerAttributes.featureType}`, true);
          return featuresFound
        }
        if(gmlFeatures.length > 0){
          if(gmlFeatures.length == 1){
            closestFeature = gmlFeatures[0];
          };
          if(gmlFeatures.length > 1){
            featureLayer.getSource().addFeatures(gmlFeatures);
            closestFeature = featureLayer.getSource().getClosestFeatureToCoordinate(
              proj4(this.baseMapEpsg, featureLayerAttributes.layerSrid, [evt.coordinate[0], evt.coordinate[1]])
            );
            // cleanup all features after retrieving closest one
            featureLayer.getSource().clear();
          };
          featureLayer.getSource().addFeature(closestFeature);
          var popupPointerPosition:Extent = evt.coordinate;
          if (closestFeature.getGeometry() && closestFeature.getGeometry().getType() == 'Point'){
            popupPointerPosition = getCenter(featureLayer.getSource().getExtent());
          }
          closestFeature.set('layer_id', featureLayer.getClassName())
          closestFeature.set('layer_name', featureLayerAttributes.layerName)
          
          featuresFound = closestFeature.getProperties();
          // do not display the geometry attribute
          delete featuresFound[featureLayerAttributes.geometryName]
        };
        return featuresFound
      }.bind(this));
  }

  private constructSpatialFilter(evt, featureLayerAttributes): Filter{
    let spatialFilter: Filter;
    const geometryType = featureLayerAttributes.geometryType
    const polygonGeometryTypes = ['Polygon','MultiPolygon']

    if (polygonGeometryTypes.includes(geometryType)){
      // Generate new Point from click event and transform to basemap projection
      let transformedFilterPoint = new Point(proj4(this.baseMapEpsg, featureLayerAttributes.layerSrid, [evt.coordinate[0], evt.coordinate[1]]))
      // Fix weird filter (buggy?) behavior for EPSG:4326 where the lat/long must be defined reversed
      if (featureLayerAttributes.layerSrid == "EPSG:4326"){
        const coordinates4326 = transformedFilterPoint.getFlatCoordinates()
        transformedFilterPoint = new Point([coordinates4326[1], coordinates4326[0]])
      }
      spatialFilter = (
        // Filter with point intersection
        intersects(featureLayerAttributes.geometryName, transformedFilterPoint, featureLayerAttributes.layerSrid)
      )
      if (this.timestampFilterEnabled && featureLayerAttributes.timestamp_identifier){
        spatialFilter =and(
          intersects(featureLayerAttributes.geometryName, transformedFilterPoint, featureLayerAttributes.layerSrid),
          during(featureLayerAttributes.timestamp_identifier, this.timestampFilterStartDate, this.timestampFilterEndDate)
        )
      }
    }
    else {
      // Generate new Bounding Box from click event (dynamic size based on zoom level) and transform to basemap projection
      const currentZoom: number= this.map.getView().getZoom()
      // set bbox limits according to zoom level.
      const userSpecificClickRadius = this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.clickRadius || DEFAULT_CLICK_RADIUS;
      const bboxToZoomLevel: number = filterBboxLimit*userSpecificClickRadius*(4000000/2**currentZoom)
      // generate BBOX from click coordinates
      const xmin:number = evt.coordinate[0] - bboxToZoomLevel;
      const ymin:number = evt.coordinate[1] - bboxToZoomLevel;
      const xmax:number = evt.coordinate[0] + bboxToZoomLevel;
      const ymax:number = evt.coordinate[1] + bboxToZoomLevel;
      const onClickBBOX:Extent = [xmin, ymin, xmax, ymax];
      // Transform to basemap projection
      const transformedOnClickBBOX = transformExtent(onClickBBOX, this.baseMapEpsg, featureLayerAttributes.layerSrid);
      spatialFilter = (
        // Apply only bounding box area filter
        bbox(featureLayerAttributes.geometryName, transformedOnClickBBOX, featureLayerAttributes.layerSrid)
      )
      if (this.timestampFilterEnabled && featureLayerAttributes.timestamp_identifier){
        spatialFilter =and(
          bbox(featureLayerAttributes.geometryName, transformedOnClickBBOX, featureLayerAttributes.layerSrid),
          during(featureLayerAttributes.timestamp_identifier, this.timestampFilterStartDate, this.timestampFilterEndDate)
          )
      }  
    }
    return spatialFilter
  }

  private populatePopup(result: any, overlay: Overlay, featureCenter: Extent){
    this.selectedFeaturesProperties = []
    result.forEach((feature) => {
      if (Object.keys(feature).length !== 0){
        let selectedObjectProperties = [];
        let layerID: string
        let layerName: string
        for (const key in feature) {
          if (key === 'layer_id'){
            layerID = feature[key]
          }
          if (key === 'layer_name'){
            layerName = feature[key]
          }
          else{
              const selectedObject = new SelectedObjectProperty();
              selectedObject.key = key;
              selectedObject.value = feature[key];
              selectedObjectProperties.push(selectedObject);
            }
        }
        
        this.selectedFeaturesProperties.push({'content': selectedObjectProperties, 'id':layerID, 'title':layerName})
      }
      if (this.selectedFeaturesProperties.length !== 0){
        this.showDetailsPopup(overlay, featureCenter);
      }
    });
  }

  private hideDetailsPopup(): void {
    document.getElementById(POPUP_ID).hidden = true;
  }

  private showDetailsPopup(overlay: Overlay, coordinate:Extent): void {
    this.featurePopupDefaultPage = 1
    document.getElementById(POPUP_ID).hidden = false;
    overlay.setPosition(coordinate);
  }

  public getAllLayerLegendContent(): any {
    return this.legendContent;
  }

  public getLayerName(layer_id: string): any {
    return this.vectorLayerDict[layer_id];
  }
  public onPageChange($event) {
    this.selectedFeaturesProperties =  this.selectedFeaturesProperties.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
  }
  
  public getSelectedFeaturesProperties(): SelectedObjectProperty[] {
    return this.selectedFeaturesProperties;
  }

  public featurePopupPageChange(event) {
    this.featurePopupDefaultPage = event;
  }

  public getSelectedFeaturesCount(): number {
    let counter: number = 1;
    if (this.selectedFeaturesProperties){
      counter = this.selectedFeaturesProperties.length
    }
    return counter;
  }

  private flashMessage(message: string, hide: boolean): void {
    document.getElementById(WFS_STATUS_ID).innerHTML = message
    document.getElementById(WFS_STATUS_ID).style.display = 'block';
    if (hide){setTimeout(function() {
      document.getElementById(WFS_STATUS_ID).style.display = 'none';
      }, 1000);
    };
  }; 

  private getEpsgConfig(srid): string {
    if (EPSG_DEFINITIONS.has(srid)){
      return EPSG_DEFINITIONS.get(srid)
    }
    else{
      console.warn(`Projection system ${srid} not found.`)
    }
  };

  private getLegendgraphicUrl(wmsSource: TileWMS): string {
    let graphicUrl : string;
    // const img : HTMLImageElement = document.getElementById('legend') as HTMLImageElement;
    // const toggleLegend : HTMLElement = document.getElementById(MapService.TOGGLE_LEGEND_ID);
    this.checkLegendImage(graphicUrl, 
      function(){ 
        graphicUrl = wmsSource.getLegendUrl()
      },
      function(){
        graphicUrl = ''
      } 
    );
    return graphicUrl
  }

  private checkLegendImage(imageSrc: string, good: any, bad: any): void {
    const img:HTMLImageElement = new Image();
    img.onload = good;
    img.onerror = bad;
    img.src = imageSrc;
  }

  public async renderLayers(
    geoResources: Array<any> = []): Promise<void> {
  /*
  Display the WMS and WFS layers. Steps are:
  1. Strip incoming URLs from their parameters, after acquiring the typeName and layer name for WFS, WMS respectively
  2. Incoming layer_extent and layer_srid are transformed to match the openlayers projection system.
  3. Load Tile WMS layer and add to map.
  4. Load empty WFS layer and add to map. The features will be populated on click event.
  *. If WFS url is invalid, no WFS layer will be added to the map, no click event will trigger WFS logic.
  */
    let hasTimestampLayers: boolean = false;
    this.legendContent.length = 0;
    for (const resource of geoResources) {
      // construct layers
      this.addWMSLayer(resource);
      await this.addWFSLayer(resource);
      if (resource.timestamp_identifier){
        hasTimestampLayers = true
      }
    }
    // Control Draw
    if (this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.drawFilter){
      this.enableDrawControl()
    }
    // Control TimeSeries
    if (hasTimestampLayers){
      this.enableTimeSeriesControl()      
    }
    //Calculate extent to include all layers
    this.zoomToFullExtent()
  }

  private zoomToFullExtent(){
    let combinedExtent;
    this.map.getLayers().forEach(function(layer) {
      if (layer.getProperties().service === 'wms' && layer.getVisible()){
        if (!combinedExtent){
          combinedExtent = layer.getExtent()
        }
        extend(combinedExtent, layer.getExtent());
      }
    });
    this.map.getView().fit(combinedExtent,{
        padding: [50,50,50,50]
    });
  }

  public async getFeatureContent(url: string): Promise<any> {
    /*
    Convert observable request into firstValueFrom promise
    */
    let response = await (this.requestGetFeature(url))
    return response;

  }
  
  public requestGetFeature(url): Promise<JSON>  {
    /*
    Request getFeature from OWS service endpoint
    Properly handle exceptions
    */
    return this.apiService.getWFSFeatures(url).then(response => {
      return response;
    })
    
  }
    

  public async addWFSLayer(resource){
      if (resource.wfs_url){
        let featureAttributes: { [name: string]: string } = {};
        featureAttributes.layerSrid = `EPSG:${resource.layer_srid}`;
        const vectorSourceUrl: URL = new URL(resource.wfs_url);
        let search_params = vectorSourceUrl.searchParams;
        // acquire layer name from provided WFS endpoint param typeName
        const typeName: string = search_params.get('typeName')

        featureAttributes.serviceURL = this.harmonizeOwsURL(resource.wfs_url) //harmonize incoming WFS parameters
        if (!typeName){
          console.warn('WFS param "typeName" is missing. No features can be acquired.', false)
          return
        }
        else{
          if (!typeName.includes(":")){
            console.warn('WFS param "typeName" has invalid structure. No features can be acquired.', false)
            return
          };
          let featureJson: any
          if (typeName.includes(":")){
            featureAttributes.featurePrefix = typeName.split(":")[0]
            featureAttributes.featureType = typeName.split(":")[1]
            // read single feature from layer to store geometry attributes. Update params in wfs url
            search_params.set('outputFormat', 'application/json');
            search_params.set('count', '1');
            search_params.set('maxFeatures', '1');
            search_params.set('version', WFS_VERSION);
            vectorSourceUrl.search = search_params.toString();
            let featureContent = await this.getFeatureContent(vectorSourceUrl.toString())
            let featureList = featureContent?.features ?? [];
            if (featureList.length > 0){
              featureAttributes.geometryType = featureList[0].geometry.type;
              featureAttributes.geometryName = featureList[0].geometry_name;
              featureAttributes.layerName = resource.name;
              featureAttributes.timestamp_identifier = resource.timestamp_identifier;
              // Load empty WFS source but storing geometry attributes
              const wfsLayer = new VectorLayer({
                className: resource.id,
                properties: {service: 'wfs', attributes: featureAttributes},
                source: new VectorSource(),
                style: new Style({
                  stroke: new Stroke({
                    color: 'rgba(0, 0, 255, 1.0)',
                    width: 2,
                  }),
                }),
              });
              this.map.addLayer(wfsLayer);
            }
        };
        }
    }
  }
    
  public addWMSLayer(resource){
    const tileWMSSourceUrl:URL = new URL(resource.wms_url);
    const tileWMSSourceLayer = tileWMSSourceUrl.searchParams.get("layers")
    
    //strip incoming WMS url from params
    this.wmsServiceURL = this.stripURL(resource.wms_url) 
    this.owsServiceParams = resource.ows_url ? new URL(resource.ows_url).searchParams : undefined;
    // Set default extent If wms_extent is empty, zoom to Europe level
    this.layerExtent = DEFAULT_ZOOM_LEVEL; 
    
    if(resource.layer_extent) {
      if (typeof resource.layer_extent === 'string'){
        try { 
          this.layerExtent = (JSON.parse(resource.layer_extent));
        } catch(e) {
          console.warn(e, false)
        }
      } else {
        this.layerExtent = resource.layer_extent;
      }
    }
    else {
      console.warn('Layer extent not found.', true)
    }

    this.epsg = `EPSG:${resource.layer_srid}`;
    // add new projection to proj4 definitions
    proj4.defs(this.epsg, this.getEpsgConfig(resource.layer_srid));
    // update proj4 definitions
    register(proj4)

    try{
      // Apply default layer extent if provided, otherwise use the original layer's extent
      const defaultLayerExtent = this.dynamicInjector.getOrganizationConfig().datasetDetailsConfig.map.defaultLayerExtent
      this.transformedExtent = defaultLayerExtent ? defaultLayerExtent : transformExtent(this.layerExtent, this.epsg, this.baseMapEpsg);
    }   
    catch (Error){ 
      if ( Error.message === `Cannot read properties of null (reading 'getCode')` ) {
        console.warn(`Error trasforming bounding box. Global defaults applied.`, true)
      }
      else {
        console.warn(Error.message, false)
      }
    };
    if (!this.transformedExtent){
      this.transformedExtent = baseMapDefaultExtent;
    }
    let wmsParams: { [name: string]: any } = {
      'LAYERS': tileWMSSourceLayer,
      'TILED': true, 
      'VERSION': WMS_VERSION
    }
    let legendParams: { [name: string]: any } = {
      'LAYER': tileWMSSourceLayer,
      'REQUEST': 'GetLegendGraphic',
      'SERVICE': 'WMS'
    }
    // Retrieve additional params from ows_url, when exists, and include them in the service calls
    if (this.owsServiceParams){
      this.owsServiceParams.forEach((value, key) => {
        wmsParams[key] = value
        legendParams[key] = value
      });
    }

    let wmsSource: TileWMS = new TileWMS({
      url: this.wmsServiceURL,
      params: wmsParams,
      transition: 0,
    });
    this.wmsLayer = new TileLayer({
      // Transform geoserver 4326 (expected default value) extent to openlayers default projection 3857
      className: resource.id,
      extent: this.transformedExtent,
      source: wmsSource,
      opacity: 1,
      properties: {'timestamp_identifier': resource.timestamp_identifier, service: 'wms'}
    });
    this.map.addLayer(this.wmsLayer);
    
    this.legendContent.push({'position': this.legendContent.length, 'title': resource.name, 'layerName': resource.id, 'graphicURL': this.getLegendURL(wmsSource, legendParams), 'timestamp':resource.timestamp_identifier});
  }

  /**
   * Return the GetLegendGraphic URL including passed specific parameters
  **/
  getLegendURL(wmsSource: TileWMS, legendParams): string{
    return wmsSource.getLegendUrl(undefined, legendParams)
  }

  public updateLayerVisibility(layerName:string){
    this.hideDetailsPopup()
    this.map.getLayers().forEach(function (layer) {
      if (layerName === layer.getClassName()) {
        layer.setVisible(!layer.getVisible());
        // when layer is switched off, selected features must be reset.
        // layer.getSource().clear();   
      } 
      });
  }

 
  public refreshMap(startDateString: string, endDateString: string): void{
    this.timestampFilterStartDate = startDateString;
    this.timestampFilterEndDate = endDateString;
    if (startDateString && endDateString){
      this.timestampFilterEnabled = true;
    }
    else{
      this.timestampFilterEnabled = false;
    }
    this.map.getLayers().forEach(function (layer: any) {
      if (layer.getProperties().service === 'wms' && layer.getProperties().timestamp_identifier){
        const wmsSource = layer.getSource();
        const timestampColName = layer.getProperties().timestamp_identifier
        let wmsParams = wmsSource.getParams()
        if (this.timestampFilterEnabled){
          const cql_filter_date_query:string = `${timestampColName} >= ${startDateString} AND ${timestampColName} < ${endDateString}`
          wmsParams["cql_filter"] = cql_filter_date_query;
        }
        else{
          delete wmsParams["cql_filter"]
        }
        wmsSource.updateParams(wmsParams)
      }
    }.bind(this))
  }

  /**
  * Make use of the OL circular method to convert a circle feature to polygon feature.
  * Returns a default 32 vertices polygon out of a drawn circle.
  * @param {Circle} circleFeature circle on basemap coordinates
  * @param {number=} vertices number of circular polygon points to be generated
  * @returns {Polygon} circular polygon on basemap coordinates
  */
  convertCircleFeatureToPolygonFeature(circleFeature: Circle, vertices:number = 32): Polygon{
    // Prerequisite for the geometry calculations to be correct is that all coordinates are transformed from basemap projection to EPSG:4326 projection
    // When the new polygon is constructed, coordinates are then transformed back to original basemap projection.
    
    // Get center point of circle
    const centerPoint = circleFeature.getCenter()
    let transformedCenterPoint = transform(centerPoint, this.baseMapEpsg, 'EPSG:4326');
    
    // Calculate radius out of circle's extent height /2
    const topLeftPoint = [circleFeature.getExtent()[0], circleFeature.getExtent()[1]]
    const bottomLeftPoint = [circleFeature.getExtent()[2], circleFeature.getExtent()[1]]
    let transformedTopLeftPoint = transform(topLeftPoint, this.baseMapEpsg, 'EPSG:4326');
    let transformedBottomLeftPoint = transform(bottomLeftPoint, this.baseMapEpsg, 'EPSG:4326');
    let transformedRadius = getDistance(transformedTopLeftPoint, transformedBottomLeftPoint) / 2;
    
    // Construct new circular polygon with n vertices
    let polygonFeatureFromCircleFeature: Polygon = circular(transformedCenterPoint, transformedRadius, vertices)
    polygonFeatureFromCircleFeature.transform("EPSG:4326", this.baseMapEpsg)
    
    return polygonFeatureFromCircleFeature
  }

  public getDrawFeatureGeometry(resource: ResourceModel): string{
    // transform drawn shape from basemap projection to resource's original projection
    const originalProjectionGeometry = this.drawFeatureGeometry.clone().transform(this.baseMapEpsg,`EPSG:${resource.layer_srid}`)
    // const drawFeatureGeometryWKT = new WKT().writeGeometry(originalProjectionGeometry) as string;
    const drawFeatureGeometryWKB = new WKB().writeGeometry(originalProjectionGeometry) as string;

    return drawFeatureGeometryWKB
  }

  private enableDrawControl():void {
    const toggleDraw : HTMLElement = document.getElementById('toggleDraw');
    const toggleDrawControl : Control = new Control({
      element: toggleDraw
    });
    this.map.addControl(toggleDrawControl);
    const drawBox : HTMLElement = document.getElementById('drawBox');
    drawBox.className = OL_CONTROL_CLASS;
    const drawBoxControl : Control = new Control({
        element: drawBox
    });
    this.map.addControl(drawBoxControl);
    this.map.addLayer(this.drawLayer)
  }

  private enableTimeSeriesControl():void{
    const toggleTimestamp : HTMLElement = document.getElementById('toggleTimestamp');
      const toggleTimestampControl : Control = new Control({
        element: toggleTimestamp
      });
      this.map.addControl(toggleTimestampControl);
      const timestampBox : HTMLElement = document.getElementById('timestampBox');
      timestampBox.className = OL_CONTROL_CLASS;
      const timestampBoxControl : Control = new Control({
          element: timestampBox
      });
      this.map.addControl(timestampBoxControl);
  }

  public harmonizeOwsURL(url):string {
    if (!url){
      return '-'
    }
    let strippedURL: URL = new URL(this.stripURL(url))
    if (this.owsServiceParams){
      this.owsServiceParams.forEach((value, key) => {
        strippedURL.searchParams.set(key, value)
      });
    }
    return strippedURL.toString()
  }

  stripURL(url: string): string {
    const urlObj = new URL(url);
    urlObj.search = '';
    return urlObj.toString();
  }

}
