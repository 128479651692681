import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';

export class PortalConfigComponent{
    constructor(
        protected dynamicInjectorService: DynamicInjectorService
    ) {
     }

    getSrcPath(path:string){
        return `${this.dynamicInjectorService.getCustomerConfigPath()}/${path}`
      }

  }