      <div fxLayout="column" fxFlex=60>
        <br />
        <p class="mat-headline" style="border-bottom: 1px solid #b3b2b2;">Amersfoort Open Data</p>
        <hr>
        <p style="text-align: justify;">
          Welkom op het open data platform van de gemeente Amersfoort! Op deze website vindt u een overzicht van de
          beschikbare
          open data van de gemeente Amersfoort. Als u ideeën heeft, verbeterpunten ziet of vragen wilt stellen, neem dan
          <a href="mailto:opendata@amersfoort.nl?subject=Reactie%20open%20data%20portaal%20Amersfoort">contact&nbsp;</a>
          met ons op. In de toekomst gaat Gemeente Amersfoort meer datasets publiceren. De gemeente Amersfoort werkt
          samen met andere
          overheden om meer open data op een makkelijke manier beschikbaar te stellen. Samen werken we ook aan
          standaardisatie van datasets.
        </p>
        <br>
        <p class="mat-subheading-1">Open data is:</p>
        <ul>
          <li>
            <p>openbare data,</p>
          </li>
          <li>
            <p>zonder auteursrechten of rechten van derden,</p>
          </li>
          <li>
            <p>bekostigd uit publieke middelen,</p>
          </li>
          <li>
            <p>verzameld voor werkzaamheden overheidsinstantie,</p>
          </li>
          <li>
            <p>liefst ‘open standaard’; dat wil zeggen zonder barrières voor gebruik ICT-toepassingen,</p>
          </li>
          <li>
            <p>liefst leesbaar door computers zodat zoekmachines de informatie kunnen vinden.</p>
          </li>
        </ul>
        <br>
        <p class="mat-subheading-1">Waardevolle informatie</p>
        <p style="text-align: justify;">Om haar werk zo goed mogelijk te kunnen doen, verzamelt de gemeente Amersfoort
          veel verschillende
          soorten gegevens. Informatie die waardevol en interessant kan zijn voor bedrijven, organisaties, journalisten,
          onderzoekers en
          bewoners. Zoals plattegronden met informatie, geografische data, resultaten van bewonersonderzoeken of
          allerlei gegevens over de stad.
          Volgens onderzoek kan open data grote economische waarde hebben. Veel mensen en organisaties kunnen ervan
          profiteren.
          Om deze ontwikkelingen mogelijk te maken, stelt de gemeente Amersfoort deze data zo veel en zo gemakkelijk
          mogelijk beschikbaar.
          De data zijn bekostigd uit publieke middelen en daarom gratis toegankelijk.
        </p>

        <br>

        <p class="mat-subheading-1">Gebruiksvoorwaarden open data Gemeente Amersfoort</p>
        <p style="text-align: justify;">Iedereen – particulier en bedrijf – mag de als zodanig aangemerkte open data van
          de gemeente Amersfoort,
          zoals geplaatst op amersfoort.dataplatform.nl hergebruiken in eigen toepassingen.<br><br>

          De gemeente Amersfoort stelt de inhoud van de datasets met de grootst mogelijke zorg samen. Dit is echter geen
          garantie dat deze datasets
          volledig, accuraat en juist zijn. De gemeente Amersfoort sluit iedere aansprakelijkheid uit voor welke schade
          dan ook, direct en/of indirect,
          op enige wijze ontstaan door en/of voortvloeiend uit elk gebruik van deze datasets, waaronder ook – maar niet
          alleen – handelingen van een
          gebruiker van de datasets die op welke manier dan ook zijn ingegeven door de hier geplaatste informatie. </p>
        <br>
        <p><b>Proclaimer</b></p>
        <a href="http://www.amersfoort.nl/proclaimer" target="_blank">http://www.amersfoort.nl/proclaimer</a>
        <p style="text-align: justify;">
          Voor meer informatie over open data en datasets van de gemeente Amersfoort kunt u contact opnemen met <br>
          <a
            href="mailto:opendata@amersfoort.nl?subject=Reactie%20open%20data%20portaal%20Amersfoort">opendata@amersfoort.nl&nbsp;</a>
        </p>
        <p></p>
        <p></p>
      </div>
      <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
      <div fxLayout="column" fxFlex=35 class="ieCustomFlex">
        <br />
        <div class="policy-custom">
          <p class="mat-subheading-1">Open data en privacy</p>
          <p style="text-align: justify;">De gemeente Amersfoort gaat zorgvuldig om met de gegevens van haar inwoners en
            houdt
            zich aan de Wet bescherming persoonsgegevens.
          </p>
          <br>
          <p style="text-align: justify;">Bij open data gaat het alleen om informatie die openbaar gemaakt mág worden,
            dus geen persoonlijke,
            vertrouwelijke of bedrijfsgevoelige gegevens. Dus geen gegevens over personen.<br> Informatie die schade kan
            veroorzaken of de
            veiligheid in gevaar kan brengen wordt ook niet via open data verspreid.
          </p>
        </div>
      </div>