import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { DatasetsService } from '../../../../src/app/shared/services/datasets.service';
import { ID } from '../id';

@Component({
  selector: 'app-unbranded-home-text',
  templateUrl: './unbranded-home-text.component.html',
  styleUrls: ['./unbranded-home-text.component.scss']
})
export class UnbrandedHomeTextComponent implements OnInit, ConfigInterface {

  //TODO: are these values used?
  url;
  customIframe;
  
  public currentDate: Date;
  public totalNoOfDatasets: number;

  constructor(private datasetsService: DatasetsService) {
    this.currentDate = new Date();
  }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {

    this.datasetsService.getTotalNumberOfDatasets().then((result: number) => {
      this.totalNoOfDatasets = result;
    });

  }

}
