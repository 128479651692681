import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { ThemesComponent } from '@shared/themes/themes.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HomeComponent } from 'app/home/home.component';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { KeycloakUserServiceResolver } from '@shared/resolvers/authorization.resolver';
import { PermissionDeniedComponent } from '@shared/permission-denied/permission-denied.component';

const appRoutes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [DynamicInjectorService], //makes it possible to redirect to other pages on start up.
  },
  {
    path: 'data',
    loadChildren: () => import('../datasets/datasets.module').then(m => m.DatasetsModule)
  },
  {
    path: 'themes',
    component: ThemesComponent,
    // canActivate: [AuthGuardService]
  },
  {
    path: 'custom',
    loadChildren: () => import('../../../portal-config/custom.module').then(m => m.CustomModule),
    resolve: { isAuthorized: KeycloakUserServiceResolver }
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'home'
  },
  {
    path: 'permission-denied',
    component: PermissionDeniedComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { 
      useHash: true
     })
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {

}
