<div fxLayoutAlign="center">
  <div fxLayout="column" fxFlex=60 fxFlex.md=60 fxFlex.sm=85 fxFlex.xs=90>
    <h2></h2>
    <p style="text-align: justify"></p>
    <div class="iframe-component" fxLayoutAlign="center center">
      <!-- <iframe #map3dIframe [src]="iframeUrl"></iframe> -->
      <img class="coming-soon" [src]="getAsset('images/coming_soon_3d.png')">
    </div>
    <button color="primary" mat-button (click)="toggleFullscreen()">Toggle Fullscreen</button>
    <br>
  </div>
</div>