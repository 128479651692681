<div fxLayoutAlign="center">
  <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
    <h1>
      {{ 'DATAPLATFORM.PRIVACY' | translate }}
      <hr>
    </h1>
    <br>
    <div>
      <div class="field-items" [ngSwitch]="language">
        <div *ngSwitchCase="'nl'"><ng-container *ngTemplateOutlet="dutch"></ng-container></div>
        <div *ngSwitchCase="'en'"><ng-container *ngTemplateOutlet="english"></ng-container></div>
      </div>
    </div>
    <br><br><br>
  </div>
</div>

<ng-template #dutch>
  <h2 class="mat-h2">Privacyverklaring Civity b.v.</h2>
  <p>Wanneer jij er voor kiest om informatie over jezelf met ons te delen, vertrouw je erop dat wij zorgvuldig met jouw gegevens omgaan. Civity legt in deze privacy verklaring graag uit hoe we verstrekte of verzamelde persoonsgegevens op <a href="http://www.civity.nl/">www.civity.nl</a>, <a href="http://www.slimmelden.nl/">www.slimmelden.nl</a> en <a href="http://www.dataplatform.nl/">www.dataplatform.nl</a> verwerken en gebruiken.</p>
   
  <h3 class="mat-h3">Wie zijn we</h3>
  <p>Wij zijn Civity b.v.</p>
  <p>Civity b.v. is gevestigd aan Handelsweg 6-1, 3707 NH  Zeist.</p>
  
  <h3 class="mat-h3">Persoonsgegevens die wij verwerken</h3>
  <p>Civity verwerkt jouw persoonsgegevens doordat je gebruik maakt van onze diensten en/of omdat je deze gegevens zelf aan ons verstrekt. Dat kan wanneer je ons vrijwillig mailt, een formulier invult of je opgeeft voor onze nieuwsbrief. Dit kunnen de volgende gegevens zijn:</p>
  <ul>
    <li>Voor- en achternaam</li>
    <li>Telefoonnummer</li>
    <li>E-mailadres</li>
    <li>Locatiegegevens</li>
  </ul>
  
  <h3 class="mat-h3">Waarom wij jouw persoonsgegevens bewaren</h3>
  <p>Als je gebruik maakt van onze diensten, zoals Slim Melden verwerkt Civity jouw persoonsgegevens uitsluitend om deze door te kunnen zetten naar de organisatie die deze dienst van Civity afneemt als klant. Deze organisatie kan deze gegevens gebruiken om je te bellen of te e-mailen indien dit nodig is om hun dienstverlening uit te kunnen voeren. Civity neemt nooit contact met jou op.</p>
  <p>Indien je vrijwillig gegevens hebt verstrekt op <a href="http://www.civity.nl/">www.civity.nl</a>, <a href="http://www.slimmelden.nl/">www.slimmelden.nl</a> en <a href="http://www.dataplatform.nl/">www.dataplatform.nl</a> gebruikt Civity jouw persoonsgegevens om te reageren op specifieke informatieverzoeken en het versturen van promotie materiaal.</p>
  
  <h3 class="mat-h3">Hoe lang we jouw persoonsgegevens bewaren</h3>
  <p>Civity bewaart jouw persoonsgegevens niet langer dan strikt nodig is om het doel te realiseren waarvoor je gegevens worden verzameld. Indien je gebruik maakt van onze dienst Slim Melden, worden persoonsgegevens verwijderd conform de afspraken met de klant.</p>
    
  <h3 class="mat-h3">Met wie kunnen wij jouw persoonsgegevens delen</h3>
  <p>Indien je gebruik maakt van Slim Melden stuurt Civity de gegevens die je zelf hebt ingevuld uitsluitend door aan de organisatie die de melding uit Slim Melden afhandelt. Civity deelt deze gegevens niet met andere partijen.</p>
  
  <h3 class="mat-h3">Hoe wij jouw persoonsgegevens beveiligen</h3>
  <p>Civity neemt de bescherming van jouw gegevens serieus en neemt passende maatregelen om misbruik, verlies, onbevoegde toegang, ongewenste openbaarmaking en ongeoorloofde wijziging tegen te gaan. Als jij het idee hebt dat jouw gegevens toch niet goed beveiligd zijn of er aanwijzingen zijn van misbruik, neem dan contact op via <a href="mailto:info@civity.nl">info@civity.nl</a>.</p>
    
  <h3 class="mat-h3">Over het gebruik van cookies of vergelijkbare technieken</h3>
  <p>Civity gebruikt alleen technische en functionele cookies. En analytische cookies die geen inbreuk maken op je privacy. Een cookie is een klein tekstbestand dat bij het eerste bezoek aan deze website wordt opgeslagen op jouw computer, tablet of smartphone. De cookies die wij gebruiken zijn noodzakelijk voor de technische werking van de website en jouw gebruiksgemak. Ze zorgen ervoor dat de website naar behoren werkt en onthouden bijvoorbeeld jouw voorkeursinstellingen. Ook kunnen wij hiermee onze website optimaliseren. Je kunt je afmelden voor cookies door je internetbrowser zo in te stellen dat deze geen cookies meer opslaat. Daarnaast kun je ook alle informatie die eerder is opgeslagen via de instellingen van je browser verwijderen.</p>
</ng-template>

<ng-template #english>
  <h2 class="mat-h2">Privacy Statement Civity b.v.</h2>
  <p>When you choose to share information about yourself with us, you trust that we will handle your information with care. In this privacy statement, Civity is happy to explain how we process and use personal data provided or collected on <a href="http://www.civity.nl/">www.civity.nl</a>, <a href="http://www.slimmelden.nl/">www.slimmelden.nl</a> and <a href="http://www.dataplatform.nl/">www.dataplatform.nl</a>.</p>
   
  <h3 class="mat-h3">Who we are</h3>
  <p>We are Civity b.v.</p>
  <p>Civity b.v. is located at Handelsweg 6-1, 3707 NH Zeist.</p>
  
  <h3 class="mat-h3">Personal data we process</h3>
  <p>Civity processes your personal data because you use our services and/or because you provide these data to us yourself. This is possible when you voluntarily e-mail us, fill in a form or sign up for our newsletter. This could be the following information:</p>
  <ul>
    <li>First and last name</li>
    <li>Telephone number</li>
    <li>E-mail address</li>
    <li>Location information</li>
  </ul>
  
  <h3 class="mat-h3">Why we store your personal data</h3>
  <p>If you use our services, such as Slim Melden, Civity will only process your personal data for the purpose of forwarding them to the organisation that purchases this service from Civity as a customer. This organisation can use this information to call or e-mail you if this is necessary in order to be able to carry out their services. Civity will never contact you.</p>
  <p>If you have voluntarily provided information on <a href="http://www.civity.nl/">www.civity.nl</a>, <a href="http://www.slimmelden.nl/">www.slimmelden.nl</a> and <a href="http://www.dataplatform.nl/">www.dataplatform.nl</a>, Civity will use your personal data to respond to specific requests for information and to send promotional material.</p>
  
  <h3 class="mat-h3">How long we keep your personal data</h3>
  <p>Civity does not retain your personal data for longer than is strictly necessary to achieve the purpose for which your data are collected. If you use our Smart Reporting service, personal data will be deleted in accordance with the agreements made with the customer.</p>
    
  <h3 class="mat-h3">Who can we share your personal data with?</h3>
  <p>If you use Slim Melden, Civity will only send the data that you have entered to the organisation that handles the report from Slim Melden. Civity does not share this information with other parties.</p>
  
  <h3 class="mat-h3">How we protect your personal data</h3>
  <p>Civity takes the protection of your data seriously and takes appropriate measures to prevent misuse, loss, unauthorised access, unwanted disclosure and unauthorised alteration. If you feel that your data is not properly secured or there are indications of misuse, please contact us at <a href="mailto:info@civity.nl">info@civity.nl</a>.</p>
    
  <h3 class="mat-h3">About the use of cookies or similar techniques</h3>
  <p>Civity only uses technical and functional cookies. And analytical cookies that do not infringe your privacy. A cookie is a small text file that is stored on your computer, tablet or smartphone the first time you visit this website. The cookies we use are necessary for the technical operation of the website and your ease of use. They ensure that the website works properly and remember, for example, your preferences. It also enables us to optimise our website. You can unsubscribe from cookies by setting your internet browser so that it no longer stores cookies. In addition, you can also delete any information previously stored in your browser's settings.</p>
</ng-template>
