            <div fxLayout="column" fxFlex="100">
                <br />
                <h1 class="mat-headline" style="color: #19486a; border-bottom: 1px solid #b3b2b2">
                    <strong>Exposome Dataplatform</strong>
                </h1>
                <h2 class="mat-headline" style="color: #19486a; font-family: merriweather serif">
                    Welkom op het dataplatform van Exposome NL
                </h2>
                <p style="text-align: justify; font-family: open sans">
                    Welcome to the Exposome data platform. Here you'll be able to view and
                    interact with a wealth of exposome data that has been generated by the
                    Exposome-NL (link to exposome-nl page), EXPANSE (link to expanse page)
                    projects among many other sources.
                    <br>
                    <br>
                    On this data platform you'll be able to:
                    <br>
                    <br>
                    1) Visualize exposome data for your European region of interest
                    <br>
                    2) Generate estimates for one or more exposome layers for an address of
                    interest.
                    <br>
                    3) Link exposome information to your cohort.
                    <br>
                    <br>The Exposome Data Platform uses the FAIR principles. This means that the data is findable
                    (Findable), accessible (Accessible), exchangeable (Interoperable) and
                    reusable (Reusable). Over time, the Data Platform will expand into a Fair
                    Data point.
                </p>
            </div>