export class GroupModel {
    id: string;
    name: string;
    translatedName: string;
    display_name: string;
    package_count: number;
    image_display_url: string;
    result: any;
    title: any;
  }
