<mat-toolbar class="footer">
  <mat-toolbar-row class="footer-row">
    <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
      <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61"
        fxLayoutAlign="space-between">

        <div fxFlex="20">
          <h2 class="mat-headline" style="color:#d5a95c"><strong>Contact</strong></h2>
          <p class="footer-text-color" style="color:#d5a95c; font-family: open sans;">
            E: <a style="color:#d5a95c; font-family: open sans;" href="mailto:iotstadslab@s-hertogenbosch.nl">
              iotstadslab@s-hertogenbosch.nl</a><br>
          </p>

        </div>
        <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div fxFlex="20">
            <h2 class="mat-headline" style="color:#d5a95c"><strong>Postadres</strong></h2>
            <p class="footer-text-color" style="color:#d5a95c; font-family: open sans;">
              Parallelweg 30<br>
              5223 AL<br>
              ’s-Hertogenbosch
            </p>

          </div>
        </div>
        <div fxLayoutAlign="end" fxFlex="33" fxLayoutAlign.lt-md="start">
          <div>
          </div>
        </div>
      </div>
    </div>
  </mat-toolbar-row>
</mat-toolbar>