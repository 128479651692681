import { DomSanitizer } from '@angular/platform-browser';
import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'safeUrl'
})
export class SafePipeUrl implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) { }

    transform(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

}

@Pipe({
  name: 'safeHtml'
})
export class SafePipeHtml implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(html: string) {
      return this.sanitizer.bypassSecurityTrustHtml(html);
  }

}

