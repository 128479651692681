import { Component, OnInit } from '@angular/core';
import SwiperCore, { Autoplay, Pagination, Navigation } from 'swiper';
import { DatasetModel } from '@shared/models/dataset.model';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { DatasetsService } from '@shared/services/datasets.service';
import { NavigationService } from '@shared/services/navigation.service';
import { Assets } from '@shared/models/organization.config.model';

SwiperCore.use([Autoplay, Navigation, Pagination]); //https://codesandbox.io/s/xpsmiw?file=/src/app/app.component.ts:82-97 https://swiperjs.com/demos
const DEFAULT_CAROUSEL_HEIGHT:string = '250px';
const DEFAULT_CAROUSEL_AUTOPLAY_VALUE: number = 4000;
const CAROUSEL_TEXT_FIELDNAME: string = 'carousel_text'
@Component({
    selector: 'app-carousel',
    templateUrl: './carousel.component.html',
    styleUrls: ['./carousel.component.scss']
})

export class CarouselComponent implements OnInit{
    private carouselDatasets: DatasetModel[] = [];
    private customerAssets: Assets;
    constructor(
        private dynamicInjectorService: DynamicInjectorService,
        private navigationService: NavigationService,
        private datasetsService: DatasetsService,
    ){}

    ngOnInit() {
        this.getCustomerAssets();
        this.initCarousel();
      }
    
    private initCarousel(): void {
        // CKAN call to retrieve carousel items and populate a carousel banner, if items available
        if (this.hasCarouselConfig()) {
          const filters = `owner_org:${this.dynamicInjectorService.getEnvironment().organizationId}`;
          const sort = this.dynamicInjectorService.getOrganizationConfig().homepage.carouselConfig.sort;
          this.datasetsService.getCarouselDatasets(filters, sort).then(response => {
            response.results.forEach(result => this.datasetsService.getDatasetDetails(result.id).then(dataset => {
              //on-the-fly replace null carousel_text with package title
              if (!dataset[CAROUSEL_TEXT_FIELDNAME]) {
                dataset[CAROUSEL_TEXT_FIELDNAME] = dataset['title']
              }
              this.carouselDatasets.push(dataset);
            }));
          });
        }
    }

    public hasCarouselConfig(): boolean {
        return this.dynamicInjectorService.getOrganizationConfig().homepage.carouselConfig != undefined;
    }

    public showCarousel(): boolean {
        return this.carouselDatasets.length > 0;
    }

    public getCarouselDatasets(): DatasetModel[] {
        return this.carouselDatasets;
    }
    
    public getCarouselHeight(): Object {
        let carouselHeight: string = DEFAULT_CAROUSEL_HEIGHT;
        if (this.dynamicInjectorService.getOrganizationConfig().homepage.carouselConfig.height) {
        carouselHeight = this.dynamicInjectorService.getOrganizationConfig().homepage.carouselConfig.height
        }
        let carouselStyle = { height: carouselHeight}
        return carouselStyle;
    }

    public getCarouselAutoplay(): Object {
        let autoplayDelay: number = DEFAULT_CAROUSEL_AUTOPLAY_VALUE;
        if (this.dynamicInjectorService.getOrganizationConfig().homepage.carouselConfig.autoplayDelay) {
        autoplayDelay = this.dynamicInjectorService.getOrganizationConfig().homepage.carouselConfig.autoplayDelay
        }
        let autoplayConfig = { delay: autoplayDelay, disableOnInteraction: false }
        return autoplayConfig;
    }

    public goToDatasetDetail(id: string): void {
      this.navigationService.toDatasetDetail(id);
    }

    public getCustomerAssets(): void {
      this.customerAssets = this.dynamicInjectorService.getOrganizationConfig().assets;
    }

    public hasBannerImage(): boolean {
      return this.customerAssets.banner !== undefined
    }

    public hasLogoImage(): boolean {
      return this.customerAssets.logo !== undefined
    }

    public getLogoImageSrc(){
      if (this.customerAssets.logo.url !== undefined){
        return this.customerAssets.logo.url
      }
      if (this.customerAssets.logo.path !== undefined){
        return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${this.customerAssets.logo.path}`
      }
    }

    public getLogoImageAltText(){
      if (this.customerAssets.logo.altText !== undefined){
        return this.customerAssets.logo.altText
      }
    }

    public getBannerImageSrc(){
      if (this.customerAssets.banner.url !== undefined){
        return this.customerAssets.banner.url
      }
      if (this.customerAssets.banner.path !== undefined){
        return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${this.customerAssets.banner.path}`
      }
    }

    public getBannerImageAltText(){
      if (this.customerAssets.banner.altText !== undefined){
        return this.customerAssets.banner.altText
      }
    }

}
