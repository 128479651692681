<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
        <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
            <div class="content" fxLayout="row wrap" fxLayout.lt-md="column" fxFlexFill fxFlex="61"
                fxLayoutAlign="space-between">

                <div fxFlex="20">
                    <h2 class="mat-headline" style="color:#19486A"><strong>Contact</strong></h2>
                    <p class="footer-text-color" style="color:#19486A; font-family: open sans;">
                        E: <a style="color:#FF3A21; font-family: open sans;"
                            href="mailto:exposome.office@uu.nl">exposome.office@uu.nl </a><br>
                    </p>
                </div>
                <div fxLayoutAlign="center start" fxFlex="33" fxLayoutAlign.lt-md="start">
                    <div fxFlex="20">
                        <h2 class="mat-headline" style="color:#19486A"><strong>Postadres</strong></h2>
                        <p class="footer-text-color" style="color:#FF3A21; font-family: open sans;">
                            Exposome NL
                            p/a Universiteit Utrecht<br>
                            Yalelaan 2<br>
                            3584 CM Utrecht
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </mat-toolbar-row>

    <mat-toolbar-row class="footer-row">
        <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
            <div fxFlex="61">
                <div fxFlex="66" fxLayoutAlign="start" style="white-space:normal">
                    <p class="mat-body european-union-horizon" style="margin-top:auto; margin-bottom:auto;">{{'FOOTER.EXPOSOME_EU_FUNDING' | translate}}</p>
                </div>
                <div fxFlex="33" fxLayoutAlign="start">
                    <img [src]="getEuropeanImage()" class="european-union-horizon-image">
                </div>
            </div>
        </div>
    </mat-toolbar-row>

</mat-toolbar>