import { CommonModule } from "@angular/common";
import { MarkdownModule } from "ngx-markdown";
import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";

//Customer components
import { AlmereHomeTextComponent } from "./organizations/almere/home/almere-home-text.component";
import { AmersfoortHomeTextComponent } from "./organizations/amersfoort/home/amersfoort-home-text.component";
import { CatalogusLelystadHomeTextComponent } from "./organizations/catalogus-lelystad/home/catalogus-lelystad-home-text.component";
import { DataplatformHomeTextComponent } from "./organizations/dataplatform/home/dataplatform-home-text.component";
import { DenhaagHomeTextComponent } from "./organizations/denhaag/home/denhaag-home-text.component";
import { ExposomeHomeTextComponent } from "./organizations/exposome/home/exposome-home-text.component";
import { FrieslandHomeTextComponent } from "./organizations/friesland/home/friesland-home-text.component";
import { GreenInnovationHubHomeTextComponent } from "./organizations/greeninnovationhub/home/greeninnovationhub-home-text.component";
import { HealthRiHomeTextComponent } from './organizations/health-ri/home/health-ri-home-text.component';
import { IotStadsLabHomeTextComponent } from "./organizations/iot-stadslab-s-hertogenbosch/home/iot-stadslab-home-text.component";
import { LeeuwardenHomeTextComponent } from "./organizations/leeuwarden/home/leeuwarden-home-text.component";
import { LelystadHomeTextComponent } from "./organizations/lelystad/home/lelystad-home-text.component";
import { LidingoHomeTextComponent } from "./organizations/lidingo/home/lidingo-home-text.component";
import { MalmoHomeTextComponent } from "./organizations/malmo/home/malmo-home-text.component";
import { NieuwegeinHomeTextComponent } from "./organizations/nieuwegein/home/nieuwegein-home-text.component";
import { ProvincieNoordHollandHomeTextComponent } from "./organizations/provincie-noord-holland/home/provincie-noord-holland-home-text.component";
import { RotterdamHomeTextComponent } from "./organizations/rotterdam/home/rotterdam-home-text.component";
import { SandboxHomeTextComponent } from "./organizations/sandbox/home/sandbox-home-text.component";
import { SchiedamHomeTextComponent } from "./organizations/schiedam/home/schiedam-home-text.component";
import { ScorewaterHomeTextComponent } from "./organizations/scorewater/home/scorewater-home-text.component";
import { SHertogenboschHomeTextComponent } from "./organizations/s-hertogenbosch/home/s-hertogenbosch-home-text.component";
import { SnuffelfietsHomeTextComponent } from "./organizations/snuffelfiets/home/snuffelfiets-home-text.component";
import { StichtsevechtHomeTextComponent } from "./organizations/stichtsevecht/home/stichtsevecht-home-text.component";
import { UnbrandedHomeTextComponent } from "./organizations/unbranded/home/unbranded-home-text.component";
import { ZaanstadHomeTextComponent } from "./organizations/zaanstad/home/zaanstad-home-text.component";

export const homeComponents = [
    AlmereHomeTextComponent,
    AmersfoortHomeTextComponent,
    CatalogusLelystadHomeTextComponent,
    DataplatformHomeTextComponent,
    DenhaagHomeTextComponent,
    ExposomeHomeTextComponent,
    FrieslandHomeTextComponent,
    GreenInnovationHubHomeTextComponent,
    HealthRiHomeTextComponent,
    IotStadsLabHomeTextComponent,
    LeeuwardenHomeTextComponent,
    LelystadHomeTextComponent,
    LidingoHomeTextComponent,
    MalmoHomeTextComponent,
    NieuwegeinHomeTextComponent,
    ProvincieNoordHollandHomeTextComponent,
    RotterdamHomeTextComponent,
    SandboxHomeTextComponent,
    SchiedamHomeTextComponent,
    ScorewaterHomeTextComponent,
    SHertogenboschHomeTextComponent,
    StichtsevechtHomeTextComponent,
    SnuffelfietsHomeTextComponent,
    UnbrandedHomeTextComponent,
    ZaanstadHomeTextComponent    
]

@NgModule({
    imports: [
      CommonModule,
      SharedModule,
      MarkdownModule.forRoot()
    ],
    declarations: [
      homeComponents
    ],
    exports: [
      homeComponents
    ],
    providers: [
    ]
  })
  export class HomeModule { }
