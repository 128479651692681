import { Component, OnInit } from '@angular/core';
import { ID } from '../id';

@Component({
  selector: 'app-catalogus-lelystad-home-text',
  templateUrl: './catalogus-lelystad-home-text.component.html',
  styleUrls: ['./catalogus-lelystad-home-text.component.scss']
})
export class CatalogusLelystadHomeTextComponent implements OnInit {

  constructor() { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
  }

}
