
<div fxLayoutAlign="center">
    <div fxLayout="column" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90>
      <div fxLayout="row">
        <div fxLayout="row" fxLayout.lt-md="column">
          <div  fxLayout="column" fxFlex=60 fxFlex.sm=85  fxFlex.xs="85">
              <br/>
              <p class="mat-headline" style="border-bottom: 1px solid #b3b2b2;">Kom maar op! Win jij de Friese Open Data Award?</p>
              <p style="text-align: justify;">
                We zijn benieuwd wat jij kunt doen met de open data die we op het Fries Open Data Platform publiceren,
                dus kom maar op! Vanaf 6 december 2017 kunnen studenten en andere geïnteresseerden meedoen aan de eerste Friese Open
                Data Award. Er zijn 3 challenges waar je aan kunt deelnemen:<br/>
              </p>
              <p class="mat-subheading-1">1 Connect: een speurtocht naar het juiste antwoord</p>
              <p style="text-align: justify;">
              Deze challenge is al gesloten, dus gebruik je creativiteit voor de beide andere challenges! De uitdaging was het met
              data uit dit platform beantwoorden van de volgende vraag:
              </p>
               <p style="text-align: justify;">
                <strong>
                  Wat is het plantjaar van de waardevolle boom,die het dichtst staat bij de provinciale lichtmast met het ID 696502?
                </strong>
              </p>
              <p style="text-align: justify;">
                De inzenders van goede antwoorden hebben inmiddels bericht ontvangen.
                </p>
              <p class="mat-subheading-1">2 Een concept dat iedereen zou willen gebruiken</p>
              <p style="text-align: justify;">
                Wat bedenk jij waar het komend jaar heel Nederland graag gebruik van maakt? Beschrijf een concept van een geschikte toepassing
                op één of meerdere open data sets. Data sets zijn die te vinden zijn op het Fries Open Data Platform, maar ook andere open
                data sets mogen gebruikt worden. Schroom ook niet om nieuwe datasets te beschrijven die nu nog niet openbaar zijn.
                Stuur je concept met je contactgegevens naar <a href="mailto:fodp@leeuwarden.nl">fodp@leeuwarden.nl</a>. Je kunt hiermee een Friese Open Data Award winnen en een
                bezoek aan een innovatieve ICT-organisatie.
              </p>
              <p class="mat-subheading-1">3 Creëer een functionerend prototype.</p>
              <p style="text-align: justify;">
                Bouw een werkend prototype van je toepassing en maak daarbij gebruik van ten minste één van de bronnen van het Fries open data platform.
                Het staat je vrij om ook andere open databronnen te gebruiken. Stuur een beschrijving van je toepassing met je contactgegevens
                naar <a href="mailto:fodp@leeuwarden.nl">fodp@leeuwarden.nl</a> en we maken afspraak met je voor een demonstratie. Je kunt ook met dit onderdeel een Friese
                Open Data Award winnen en een bezoek aan een innovatieve ICT-organisatie.
              </p>
              <p class="mat-subheading-1">Combineren kan!</p>
              <p style="text-align: justify;">
                Je mag uiteraard aan meerdere wedstrijdonderdelen meedoen en conceptueel en creatief combineren! De challenge loopt vanaf 6 december 2017.
                De uitreiking van de Friese Open Data Award(s) zal plaatsvinden tijdens een evenement dat dan in Leeuwarden plaatsvindt. Daarover hoor je snel meer. Heel veel succes!
              </p>
        </div>
        <div fxLayout="column" fxFlex=5 class="ieCustomMinWidth"></div>
        <div fxLayout="column" fxFlex=35 class="ieCustomFlex" fxFlex.sm=15  fxFlex.xs="15">
            <div>
              <p class="mat-subheading-1">Alles op een rij</p>
              <ul class="text-color">
                <li>Startdatum: 6 december 2017</li>
                <li>Je kunt nog meedoen aan de Challenges Concept en Creatief</li>
                <li>Voor studenten en andere geïnteresseerden</li>
                <li>3 Challenges, dus kom maar op!:
                  <ul class="text-color">
                    <li><strong>Connect:</strong> los een vraag op met data (inzending gesloten)</li>
                    <li><strong>Concept:</strong> bedenk een open data toepassing</li>
                    <li><strong>Creatief:</strong> maak een prototype</li>
                  </ul>
                </li>
                <li>Stuur je inzending naar <a href="mailto:fodp@leeuwarden.nl">fodp@leeuwarden.nl</a></li>
                <li>Stuur je vragen naar <a href="mailto:fodp@leeuwarden.nl">fodp@leeuwarden.nl</a></li>
                <li>Prijs: de 1e Friese Open Data Award en een bezoek aan een innovatief ICT bedrijf</li>
              </ul>
            </div>
         </div>
       </div>
      </div>
    </div>
  </div>
