import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DatasetModel } from '@shared/models/dataset.model';
import { DatasetsService } from '@shared/services/datasets.service';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { NavigationService } from '@shared/services/navigation.service';
import * as _ from 'lodash';
import { MatomoTracker } from 'ngx-matomo';

interface Expand {
    description: boolean;
    metadata: boolean;
}

@Component({
    selector: 'app-dataset-information',
    templateUrl: './dataset-information.component.html',
    styleUrls: ['./dataset-information.component.scss']
})
export class DatasetInformationComponent implements OnInit {

    customerName: string;

    @Input() datasetDetails: DatasetModel;
    @Input() totalViews;
    @Input() metadataIsEmpty: boolean;

    expand: Expand = { description: true, metadata: false };
    displayedColumns = ['key', 'value'];
    constructor(
        private datasetsService: DatasetsService,
        private navigationService: NavigationService,
        private matomoTracker: MatomoTracker,
        private dynamicInjectorService: DynamicInjectorService,
        private activatedRoute: ActivatedRoute
    ) {
        this.customerName = this.dynamicInjectorService.getEnvironment().name;
    }

    ngOnInit() {
        const matomoCategory = `Access dataset: ${this.datasetDetails.id}`;
        const matomoAction = 'Information tab'
        this.matomoTracker.trackEvent(matomoCategory, matomoAction);
    }

    addQueryParam(tagName) {
        this.navigationService.toData({ queryParams: { ['tags.name']: tagName }, queryParamsHandling: '' }, this.getParamKey(), this.getParamValue());
    }

    private getParamKey(): string {
        const queryKey: string = this.activatedRoute.snapshot.queryParamMap.get('key');
        const key: string = this.activatedRoute.snapshot.paramMap.get('key');
        if (!_.isNil(key)) {
            return key;
        } else if (!_.isNil(queryKey)) {
            return queryKey;
        } else {
            return undefined
        }
    }

    private getParamValue(): string {
        const queryValue: string = this.activatedRoute.snapshot.queryParamMap.get('value');
        const value: string = this.activatedRoute.snapshot.paramMap.get('value');
        if (!_.isNil(value)) {
            return value;
        } else if (!_.isNil(queryValue)) {
            return queryValue;
        } else {
            return undefined
        }
    }

    isWishlistEnabled(): boolean{
        return this.dynamicInjectorService.getOrganizationConfig().datasetListConfig.enableWishlist
    }

    isItemInWishlist(id): boolean{
        return this.datasetsService.isItemInWishlist(id)
    }

    removeFromWishlist(id){
        this.datasetsService.removeItemFromWishList(id)

    }

    addToWishlist(dataset){
        this.datasetsService.addItemToWishList(dataset)
    }
}
