export class OrganizationModel {
  display_name?: string;
  description?: string;
  image_display_url?: string;
  title?: string;
  id: string;
  image_url?:string;
  created?:Date;
  is_organization?:boolean;
  name?:string;
  revision_id?:string;
  state?:string;
  type?:string;
  approval_status?:string;
  package_count?: number;
  cache_last_updated?: null;
  package_id?: string;
  standard_complience?: string;
  size?: number;
  datastore_active?: boolean;
  standard_version?: string;
  license_id?: string;
  open_data?: string;
  status?: string;
  hash?: string;
  format?: string;
  mimetype_inner?: null;
  url_type?: string;
  geo_enabled?: string;
  mimetype?: string;
  cache_url?: null;
  url?: string;
  documentation?: string;
  last_modified?: string;
  position?: number;
  resource_type?: null;
  vocabulary_id?: null;
  portal_intro_text?: string;
}
