import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import {KeycloakService} from 'keycloak-angular';

export function initializer(keycloak: KeycloakService, dynamicInjectorService: DynamicInjectorService): () => Promise<any> {
  dynamicInjectorService.initEnvironment(window.location.href);
  return (): Promise<void> => {
    return new Promise(async (resolve, reject) => {
      dynamicInjectorService.initEnvironment(window.location.href).then(() => {
        if(dynamicInjectorService.getOrganizationConfig().auth != undefined){
          try {
            keycloak.init({
              config: {
                url: dynamicInjectorService.getOrganizationConfig().auth.url[dynamicInjectorService.getEnvironment().environment],
                realm: dynamicInjectorService.getOrganizationConfig().auth.realm,
                clientId: dynamicInjectorService.getOrganizationConfig().auth.clientId
              },
              initOptions: {
                onLoad: 'check-sso',
                checkLoginIframe: false
              },
              enableBearerInterceptor: true,
              bearerExcludedUrls: [],
              loadUserProfileAtStartUp: true
            });
            resolve();
          } catch (error) {
            reject(error);
          }
        } else {
          console.debug('This organisation has no keycloak configs.');
          resolve();
        }
      })
    });
  };
}
