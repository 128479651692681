import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { KeycloakUserService } from "@shared/services/keycloak-user.service";
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { DialogData } from "@shared/interfaces/dialog.data.interface";
import { ApiService } from "@shared/services/api.service";
import { HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'gis-viewer-authentication-dialog',
  templateUrl: 'gis-viewer-authentication-dialog.component.html',
  styleUrls: ['gis-viewer-authentication-dialog.component.scss'],
})
export class GISViewerAuthenticationDialog {

  publicKeyFileName: string;
  cohortFileName: string;
  bearerToken: string;
  public publicKeyFile: File;
  public cohortFile: File;

  private uploadPublicKeyFileEndpoint;
  private uploadCohortFileEndpoint;

  constructor(
    private apiService: ApiService,
    public dialogRef: MatDialogRef<GISViewerAuthenticationDialog>,
    private dynamicInjectorService: DynamicInjectorService,
    private keycloak: KeycloakUserService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit() {
    if (this.dynamicInjectorService.getOrganizationConfig().exposomeAuth != undefined) {
      const exposome_endpoint = this.dynamicInjectorService.getOrganizationConfig().exposomeAuth.url[this.dynamicInjectorService.getEnvironment().environment];
      this.uploadPublicKeyFileEndpoint = `${exposome_endpoint}/upload-public-key-file`;
      this.uploadCohortFileEndpoint = `${exposome_endpoint}/upload-coordinates-file`;
    } else {
      console.debug('No exposome auth configuration found.');
    }
  }

  onSubmitClick(): void {
    // Acquire token from authorized user currently logged in:
    const uploadHeaders: HttpHeaders = new HttpHeaders()
    uploadHeaders.set('Authorization', `Bearer ${this.keycloak.getToken()}`);

    //First post public key file
    const publicKeyFileFormData: FormData = new FormData();
    publicKeyFileFormData.append("user-public-key", this.publicKeyFile);
    this.apiService.postRequest<void>(this.uploadPublicKeyFileEndpoint, publicKeyFileFormData, uploadHeaders);

    //Second post cohert file
    const cohertFileFormData: FormData = new FormData();
    cohertFileFormData.append("coordinates-data-file", this.cohortFile);
    this.apiService.postRequest<void>(this.uploadCohortFileEndpoint, cohertFileFormData, uploadHeaders);
  }

  onCancelClick(): void {
    this.dialogRef.close();
  }

  onPublicKeyFileSelected(event) {

    const file: File = event.target.files[0];

    if (file) {
      this.publicKeyFileName = file.name;
      this.publicKeyFile = file;
    }
  }
  onCohortFileSelected(event) {

    const file: File = event.target.files[0];

    if (file) {
      this.cohortFileName = file.name;
      this.cohortFile = file;
    }
  }
}