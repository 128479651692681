import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { DatasetsService } from '@shared/services/datasets.service';

@Component({
  selector: 'app-health-ri-home-text',
  templateUrl: './health-ri-home-text.component.html',
  styleUrls: ['./health-ri-home-text-component.scss']
})
export class HealthRiHomeTextComponent implements OnInit, ConfigInterface {
  
  public totalNoOfDatasets: number = 0;
  constructor(
    private dynamicInjectorService: DynamicInjectorService,
    private datasetsService: DatasetsService
  ) { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
    this.datasetsService.getTotalNumberOfDatasets().then((result: number) => {
      if (result){
        this.totalNoOfDatasets = result;
      }
    });
  }

  getAsset(path) {
    return `${this.dynamicInjectorService.getCustomerConfigPath()}/assets/${path}`
  }
}
