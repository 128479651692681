<mat-toolbar color="primary" class="ieCompat app-header-toolbar" [ngSwitch]="getCustomerName()">

  <mat-toolbar-row class="mat-elevation-z5 app-header-toolbar-row" class="ieCompat" fxLayout="row"
    fxLayoutAlign="center">
    <div fxLayoutAlign="space-between center" fxLayout="row" fxFlex=60 fxFlex.md=80 fxFlex.sm=85 fxFlex.xs=90
      [ngStyle]="getToolbarStyle()">

        <!-- BUTTONS -->
        <div fxLayoutAlign="center" fxLayout="row">
          <!-- WHEN SCREEN WIDTH IS NOT XS OR SM -->
          <div *ngFor="let header of getHeaderButtons()" fxHide.xs fxHide.sm>
            <!-- internal route -->
            <div [routerLink]="header.url" *ngIf="isHeaderButtonLink(header)">
              <app-header-button [headerButtonModel]="header"></app-header-button>
            </div>
            <div [routerLink]="header.url" *ngIf="isHeaderButtonRoute(header)">
              <app-header-button [headerButtonModel]="header"></app-header-button>
            </div>
          </div>
        </div>

        <!-- LANGUAGE AND LOGIN OPTIONS -->
        <div class="action-group-right">
          <button mat-icon-button [matMenuTriggerFor]="languageMenu" *ngIf="hasMoreLanguages() else NO_LANGUAGE_MENU" fxHide.xs fxHide.sm>
            <ng-container *ngIf="displayLanguageIcon() else LANGUAGE_CODE">
              <mat-icon>language</mat-icon>
            </ng-container>
            <ng-template #LANGUAGE_CODE>
              <p class="language-code">{{ activeLanguageCode }}</p>
            </ng-template>
          </button>
          <ng-template #NO_LANGUAGE_MENU>
            <!-- <p class="no-menu-version" fxHide.xs fxHide.sm [title]="getBuildVersionFull()">{{ getBuildVersion() }}</p> -->
          </ng-template>
          <button mat-icon-button [routerLink]="'/custom/shoppingCart'" *ngIf="showShoppingCart()" fxHide.xs fxHide.sm>
            <mat-icon>list_alt
              <mat-icon *ngIf="!isWishListEmpty()" class="wishlist-not-empty">favorite</mat-icon></mat-icon>
          </button>

          <!-- LOGIN MENU -->
          <button mat-button *ngIf="!isAuthorized() && isKeycloakEnabled()" class="user"
            (click)="login()">{{'HEADER.LOGIN' | translate}}</button>
          <button mat-button *ngIf="isAuthorized() && isKeycloakEnabled()" class="user"
            [matMenuTriggerFor]="logoutMenu">
            <span>{{getFullName()}}</span>
            <mat-icon>keyboard_arrow_down</mat-icon>
          </button>

          <mat-menu #logoutMenu="matMenu">
            <button *ngIf="getDialogName()" (click)="openDialog()" mat-menu-item>{{ getDialogName() }}</button>
            <button (click)="logout()" mat-menu-item>{{'HEADER.LOGOUT' | translate}}</button>
          </mat-menu>

          <mat-menu #languageMenu="matMenu" yPosition="below">
            <div *ngFor="let lang of getLanguagesKeys()">
              <button (click)="changeTranslation(lang)" mat-menu-item>{{getLanguageName(lang)}}</button>
            </div>
            <p class="in-menu-version" fxHide.xs fxHide.sm [title]="getBuildVersionFull()">{{ getBuildVersion() }}</p>
          </mat-menu>

          <!-- WHEN SCREEN WIDTH IS XS OR SM, PAGE BUTTONS ARE ADDED AS WELL-->
          <button mat-icon-button [matMenuTriggerFor]="menu" fxHide fxShow.xs fxShow.sm>
            <mat-icon>menu</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <div *ngFor="let header of getHeaderButtons()">
              <button mat-menu-item [routerLink]="header.url" routerLinkActive="active-button"
                *ngIf="isHeaderButtonRoute(header)">
                {{header.name | translate}}
              </button>
              <a [href]="header.url" *ngIf="isHeaderButtonLink(header)" style="color:unset">
                <button mat-menu-item>
                  {{header.name | translate}}
                </button>
              </a>
            </div>
            <button mat-menu-item [matMenuTriggerFor]="languageMenu" *ngIf="hasMoreLanguages()">
              {{'HEADER.LANGUAGE' | translate}}
            </button>
            <p class="in-menu-version" [title]="getBuildVersionFull()">{{ getBuildVersion() }}</p>
          </mat-menu>
        </div>
      </div>
  </mat-toolbar-row>
</mat-toolbar>
<br />