import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { NavigationService } from '@shared/services/navigation.service';
import { ID } from '../id';

@Component({
  selector: 'app-malmo-home-text',
  templateUrl: './malmo-home-text.component.html',
  styleUrls: ['./malmo-home-text.component.scss']
})
export class MalmoHomeTextComponent implements OnInit, ConfigInterface {

  language: string;

  constructor(
    private translateService: TranslateService,
    private navigationService: NavigationService
  ) { }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
    this.language = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(x => {
      this.language = this.translateService.currentLang;
    });
  }

  public getDataRoute():string{
    return this.navigationService.getDataRoute();
  }
}
