import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit } from '@angular/core';
import { DatasetsService } from '../services/datasets.service';
import { DatasetModel } from '../models/dataset.model';
import { NotificationService } from '../services/notification.service';
import * as _ from 'lodash';
import { ApiService } from '@shared/services/api.service';
import { CkanResponseModel } from '@shared/models/scheming-package.model';

@Component({
  selector: 'app-dataset-rating',
  templateUrl: './dataset-rating.component.html',
  styleUrls: ['./dataset-rating.component.scss']
})
export class DatasetRatingComponent implements OnInit {

  @Input() datasetDetails: DatasetModel;

  private rating: number = 5;
  private yourRating: number = 0;
  private isSelected: boolean = false;

  public static readonly MAX_RATING: number = 5;
  public static readonly LOCALSTORAGE_DATASET_RATING = "votedDatasets";
  public static readonly CLASS_STAR_RATING_NONE_SELECTED = "star-rating-none-selected";
  public static readonly CLASS_STAR_RATING_LOW_SCORE = "star-rating-low-score";
  public static readonly CLASS_STAR_RATING_MID_SCORE = "star-rating-mid-score";
  public static readonly CLASS_STAR_RATING_HIGH_SCORE = "star-rating-high-score";
  public static readonly CLASS_STAR_RATING_SELECTED = "star-rating-selected";

  constructor(
    private datasetsService: DatasetsService, 
    private apiService: ApiService,
    private notificationService: NotificationService,
    private translateService: TranslateService
    ) {
  }

  ngOnInit() {
    this.rating = this.getRating();
  }

  public mouseOut(): void {
    this.rating = this.getRating();
  }

  public mouseOver(index: number): void {
    this.rating = index + 1;
  }

  public isStarSelected(index: number): boolean {
    return index < this.rating;
  }

  public getMaxRating(): number {
    return DatasetRatingComponent.MAX_RATING;
  }

  public getRating(): number {
    if (this.isSelected) {
      return this.yourRating;
    } else if (this.datasetDetails && this.datasetDetails.rating) {
      return this.datasetDetails.rating;
    } else {
      return this.rating;
    }
  }

  public getRatingsCount(): number {
    if (this.datasetDetails && this.datasetDetails.ratings_count) {
      return this.datasetDetails.ratings_count;
    } else {
      return 0;
    }
  }

  public getClass(index?: number): string {
    index = index + 1;
    if (index > this.getRating()) {
      return DatasetRatingComponent.CLASS_STAR_RATING_NONE_SELECTED;
    } else if (this.isSelected) {
      return DatasetRatingComponent.CLASS_STAR_RATING_SELECTED;
    } else if (this.datasetDetails && this.datasetDetails.ratings_count > 0) {
      if (this.getRating() <= 1) {
        return DatasetRatingComponent.CLASS_STAR_RATING_LOW_SCORE;
      } else if (this.getRating() <= 4) {
        return DatasetRatingComponent.CLASS_STAR_RATING_MID_SCORE;
      } else {
        return DatasetRatingComponent.CLASS_STAR_RATING_HIGH_SCORE;
      }
    } else {
      return DatasetRatingComponent.CLASS_STAR_RATING_NONE_SELECTED;
    }
  }

  public submitRating(index: number): void {
    index = index + 1;

    this.isSelected = true;
    this.yourRating = index;
    const storedRawDatasetRatings = JSON.parse(localStorage.getItem(DatasetRatingComponent.LOCALSTORAGE_DATASET_RATING));
    if (!_.isNil(storedRawDatasetRatings)) {
      const storedDatasetRatings: Map<string, number> = new Map<string,number>(storedRawDatasetRatings);
      if (storedDatasetRatings.size && storedDatasetRatings.has(this.datasetDetails.id)) {
        this.editRating(index, this.datasetDetails);
      } else {
        this.addRating(index, this.datasetDetails);
      }
    } else {
      this.addRating(index, this.datasetDetails)
    }
  }

  private addRating(vote: number, dataset: DatasetModel): void {
    /**
     * @todo: any :(
     */
    this.apiService.addRating(dataset.id, vote, dataset.ratings_count).then((data: CkanResponseModel<any>) => {
      if (data.success) {

        this.saveVoteLocally(dataset.id, vote)

        dataset.ratings_count = (dataset.ratings_count + 1);
        dataset.rating = ((dataset.rating + data.result.rating) / dataset.ratings_count);

        this.showTranslatedNotification('RATING.THANK_YOU_MESSAGE');
      }
    });
  }

  private editRating(vote: number, dataset: DatasetModel): void {
    /**
     * @todo: any :(
     */
    this.apiService.editRating(dataset.id, vote).then((data: CkanResponseModel<any>) => {
      if (data.success) {

        this.saveVoteLocally(dataset.id, vote);

        this.datasetDetails.rating = ((dataset.rating + vote) / dataset.ratings_count);

        this.showTranslatedNotification('RATING.EDIT_MESSAGE');
      }
    });
  }

  private saveVoteLocally(id: string, vote: number): void {
    var storedDatasetRatings: Map<string, number> = new Map<string,number>(JSON.parse(localStorage.getItem(DatasetRatingComponent.LOCALSTORAGE_DATASET_RATING)));
    if (_.isNil(storedDatasetRatings) || !storedDatasetRatings.size) {
      storedDatasetRatings = new Map<string, number>();
    }
    storedDatasetRatings.set(id, vote);
    
    localStorage.setItem(DatasetRatingComponent.LOCALSTORAGE_DATASET_RATING, JSON.stringify(Array.from(storedDatasetRatings.entries())));
  }

  private showTranslatedNotification(translationReference: string): void {
    this.translateService.get(translationReference).subscribe(message => {
      this.notificationService.sendNotification(message, '');
    });
  }
}
