export const EPSG_DEFINITIONS: Map<number,string> = new Map<number,string>(
    [
    [2000, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995 +x_0=400000 +y_0=0 +ellps=clrk80 +units=m +no_defs'],
    [2001, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=-255,-15,71,0,0,0,0 +units=m +no_defs '],
    [2002, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=725,685,536,0,0,0,0 +units=m +no_defs '],
    [2003, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=72,213.7,93,0,0,0,0 +units=m +no_defs '],
    [2004, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=174,359,365,0,0,0,0 +units=m +no_defs '],
    [2005, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=9,183,236,0,0,0,0 +units=m +no_defs '],
    [2006, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=-149,128,296,0,0,0,0 +units=m +no_defs '],
    [2007, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=195.671,332.517,274.607,0,0,0,0 +units=m +no_defs '],
    [2008, '+proj=tmerc +lat_0=0 +lon_0=-55.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2009, '+proj=tmerc +lat_0=0 +lon_0=-58.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2010, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2011, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2012, '+proj=tmerc +lat_0=0 +lon_0=-67.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2013, '+proj=tmerc +lat_0=0 +lon_0=-70.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2014, '+proj=tmerc +lat_0=0 +lon_0=-73.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2015, '+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2016, '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2017, '+proj=tmerc +lat_0=0 +lon_0=-73.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2018, '+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2019, '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2020, '+proj=tmerc +lat_0=0 +lon_0=-82.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2021, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2022, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2023, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2024, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2025, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2026, '+proj=tmerc +lat_0=0 +lon_0=-96 +k=0.9999 +x_0=304800 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2027, '+proj=utm +zone=15 +ellps=clrk66 +units=m +no_defs'],
    [2028, '+proj=utm +zone=16 +ellps=clrk66 +units=m +no_defs'],
    [2029, '+proj=utm +zone=17 +ellps=clrk66 +units=m +no_defs'],
    [2030, '+proj=utm +zone=18 +ellps=clrk66 +units=m +no_defs'],
    [2031, '+proj=utm +zone=17 +ellps=clrk66 +units=m +no_defs'],
    [2032, '+proj=utm +zone=18 +ellps=clrk66 +units=m +no_defs'],
    [2033, '+proj=utm +zone=19 +ellps=clrk66 +units=m +no_defs'],
    [2034, '+proj=utm +zone=20 +ellps=clrk66 +units=m +no_defs'],
    [2035, '+proj=utm +zone=21 +ellps=clrk66 +units=m +no_defs'],
    [2036, '+proj=sterea +lat_0=46.5 +lon_0=-66.5 +k=0.999912 +x_0=2500000 +y_0=7500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2037, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2038, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2039, '+proj=tmerc +lat_0=31.73439361111111 +lon_0=35.20451694444445 +k=1.0000067 +x_0=219529.584 +y_0=626907.39 +ellps=GRS80 +towgs84=-48,55,52,0,0,0,0 +units=m +no_defs '],
    [2040, '+proj=utm +zone=30 +ellps=clrk80 +towgs84=-125,53,467,0,0,0,0 +units=m +no_defs '],
    [2041, '+proj=utm +zone=30 +ellps=clrk80 +towgs84=-124.76,53,466.79,0,0,0,0 +units=m +no_defs '],
    [2042, '+proj=utm +zone=29 +ellps=clrk80 +towgs84=-125,53,467,0,0,0,0 +units=m +no_defs '],
    [2043, '+proj=utm +zone=29 +ellps=clrk80 +towgs84=-124.76,53,466.79,0,0,0,0 +units=m +no_defs '],
    [2044, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +towgs84=-17.51,-108.32,-62.39,0,0,0,0 +units=m +no_defs '],
    [2045, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +towgs84=-17.51,-108.32,-62.39,0,0,0,0 +units=m +no_defs '],
    [2046, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2047, '+proj=tmerc +lat_0=0 +lon_0=17 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2048, '+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2049, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2050, '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2051, '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2052, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2053, '+proj=tmerc +lat_0=0 +lon_0=29 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2054, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2055, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2056, '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs '],
    [2057, '+proj=omerc +lat_0=27.51882880555555 +lonc=52.60353916666667 +alpha=0.5716611944444444 +k=0.999895934 +x_0=658377.437 +y_0=3044969.194 +gamma=0.5716611944444444 +ellps=intl +towgs84=-133.63,-157.5,-158.62,0,0,0,0 +units=m +no_defs '],
    [2058, '+proj=utm +zone=38 +ellps=intl +towgs84=-117,-132,-164,0,0,0,0 +units=m +no_defs '],
    [2059, '+proj=utm +zone=39 +ellps=intl +towgs84=-117,-132,-164,0,0,0,0 +units=m +no_defs '],
    [2060, '+proj=utm +zone=40 +ellps=intl +towgs84=-117,-132,-164,0,0,0,0 +units=m +no_defs '],
    [2061, '+proj=utm +zone=41 +ellps=intl +towgs84=-117,-132,-164,0,0,0,0 +units=m +no_defs '],
    [2062, '+proj=lcc +lat_1=40 +lat_0=40 +lon_0=0 +k_0=0.9988085293 +x_0=600000 +y_0=600000 +a=6378298.3 +b=6356657.14266956 +pm=madrid +units=m +no_defs'],
    [2063, '+proj=utm +zone=28 +a=6378249.2 +b=6356515 +towgs84=-23,259,-9,0,0,0,0 +units=m +no_defs '],
    [2064, '+proj=utm +zone=29 +a=6378249.2 +b=6356515 +towgs84=-23,259,-9,0,0,0,0 +units=m +no_defs '],
    [2065, '+proj=krovak +lat_0=49.5 +lon_0=42.5 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=570.8,85.7,462.8,4.998,1.587,5.261,3.56 +pm=ferro +units=m +no_defs '],
    [2066, '+proj=cass +lat_0=11.2521786111111 +lon_0=-60.6860088888888 +x_0=37718.6615932499 +y_0=36209.9151295199 +a=6378293.64520875 +b=6356617.98767983 +to_meter=0.201166195164 +no_defs'],
    [2067, '+proj=utm +zone=20 +ellps=intl +towgs84=-0.465,372.095,171.736,0,0,0,0 +units=m +no_defs '],
    [2068, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2069, '+proj=tmerc +lat_0=0 +lon_0=11 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2070, '+proj=tmerc +lat_0=0 +lon_0=13 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2071, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2072, '+proj=tmerc +lat_0=0 +lon_0=17 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2073, '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2074, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2075, '+proj=tmerc +lat_0=0 +lon_0=23 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2076, '+proj=tmerc +lat_0=0 +lon_0=25 +k=0.9999 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2077, '+proj=utm +zone=32 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2078, '+proj=utm +zone=33 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2079, '+proj=utm +zone=34 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2080, '+proj=utm +zone=35 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2081, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2082, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=intl +towgs84=27.5,14,186.4,0,0,0,0 +units=m +no_defs '],
    [2083, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=intl +towgs84=16,196,93,0,0,0,0 +units=m +no_defs '],
    [2084, '+proj=utm +zone=19 +south +ellps=intl +towgs84=16,196,93,0,0,0,0 +units=m +no_defs '],
    [2085, '+proj=lcc +lat_1=22.35 +lat_0=22.35 +lon_0=-81 +k_0=0.99993602 +x_0=500000 +y_0=280296.016 +datum=NAD27 +units=m +no_defs'],
    [2086, '+proj=lcc +lat_1=20.7166666666666 +lat_0=20.7166666666666 +lon_0=-76.8333333333333 +k_0=0.99994848 +x_0=500000 +y_0=229126.939 +datum=NAD27 +units=m +no_defs'],
    [2087, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +units=m +no_defs '],
    [2088, '+proj=tmerc +lat_0=0 +lon_0=11 +k=0.9996 +x_0=500000 +y_0=0 +datum=carthage +units=m +no_defs'],
    [2089, '+proj=utm +zone=38 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2090, '+proj=utm +zone=39 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2091, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=-76,-138,67,0,0,0,0 +units=m +no_defs '],
    [2092, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=-76,-138,67,0,0,0,0 +units=m +no_defs '],
    [2093, '+proj=tmerc +lat_0=0 +lon_0=106 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=-17.51,-108.32,-62.39,0,0,0,0 +units=m +no_defs '],
    [2094, '+proj=tmerc +lat_0=0 +lon_0=106 +k=0.9996 +x_0=500000 +y_0=0 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [2095, '+proj=utm +zone=28 +ellps=intl +towgs84=-173,253,27,0,0,0,0 +units=m +no_defs '],
    [2096, '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [2097, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [2098, '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [2099, '+proj=cass +lat_0=25.3823611111111 +lon_0=50.7613888888888 +x_0=100000 +y_0=100000 +ellps=helmert +units=m +no_defs'],
    [2100, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +datum=GGRS87 +units=m +no_defs'],
    [2101, '+proj=lcc +lat_1=10.1666666666666 +lat_0=10.1666666666666 +lon_0=-71.6056177777777 +k_0=1 +x_0=0 +y_0=-52684.972 +ellps=intl +units=m +no_defs'],
    [2102, '+proj=lcc +lat_1=10.1666666666666 +lat_0=10.1666666666666 +lon_0=-71.6056177777777 +k_0=1 +x_0=200000 +y_0=147315.028 +ellps=intl +units=m +no_defs'],
    [2103, '+proj=lcc +lat_1=10.1666666666666 +lat_0=10.1666666666666 +lon_0=-71.6056177777777 +k_0=1 +x_0=500000 +y_0=447315.028 +ellps=intl +units=m +no_defs'],
    [2104, '+proj=lcc +lat_1=10.1666666666666 +lat_0=10.1666666666666 +lon_0=-71.6056177777777 +k_0=1 +x_0=-17044 +y_0=-23139.97 +ellps=intl +units=m +no_defs'],
    [2105, '+proj=tmerc +lat_0=-36.87972222222222 +lon_0=174.7641666666667 +k=0.9999 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2106, '+proj=tmerc +lat_0=-37.76111111111111 +lon_0=176.4661111111111 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2107, '+proj=tmerc +lat_0=-38.62444444444444 +lon_0=177.8855555555556 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2108, '+proj=tmerc +lat_0=-39.65083333333333 +lon_0=176.6736111111111 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2109, '+proj=tmerc +lat_0=-39.13555555555556 +lon_0=174.2277777777778 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2110, '+proj=tmerc +lat_0=-39.51222222222222 +lon_0=175.64 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2111, '+proj=tmerc +lat_0=-40.24194444444444 +lon_0=175.4880555555555 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2112, '+proj=tmerc +lat_0=-40.92527777777777 +lon_0=175.6472222222222 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2113, '+proj=tmerc +lat_0=-41.3011111111111 +lon_0=174.7763888888889 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2114, '+proj=tmerc +lat_0=-40.71472222222223 +lon_0=172.6719444444444 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2115, '+proj=tmerc +lat_0=-41.27444444444444 +lon_0=173.2991666666667 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2116, '+proj=tmerc +lat_0=-41.28972222222222 +lon_0=172.1088888888889 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2117, '+proj=tmerc +lat_0=-41.81055555555555 +lon_0=171.5811111111111 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2118, '+proj=tmerc +lat_0=-42.33361111111111 +lon_0=171.5497222222222 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2119, '+proj=tmerc +lat_0=-42.68888888888888 +lon_0=173.01 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2120, '+proj=tmerc +lat_0=-41.54444444444444 +lon_0=173.8019444444444 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2121, '+proj=tmerc +lat_0=-42.88611111111111 +lon_0=170.9797222222222 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2122, '+proj=tmerc +lat_0=-43.11 +lon_0=170.2608333333333 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2123, '+proj=tmerc +lat_0=-43.97777777777778 +lon_0=168.6061111111111 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2124, '+proj=tmerc +lat_0=-43.59055555555556 +lon_0=172.7269444444445 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2125, '+proj=tmerc +lat_0=-43.74861111111111 +lon_0=171.3605555555555 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2126, '+proj=tmerc +lat_0=-44.40194444444445 +lon_0=171.0572222222222 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2127, '+proj=tmerc +lat_0=-44.735 +lon_0=169.4675 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2128, '+proj=tmerc +lat_0=-45.13277777777778 +lon_0=168.3986111111111 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2129, '+proj=tmerc +lat_0=-45.56361111111111 +lon_0=167.7386111111111 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2130, '+proj=tmerc +lat_0=-45.81611111111111 +lon_0=170.6283333333333 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2131, '+proj=tmerc +lat_0=-45.86138888888889 +lon_0=170.2825 +k=0.99996 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2132, '+proj=tmerc +lat_0=-46.6 +lon_0=168.3427777777778 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2133, '+proj=utm +zone=58 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2134, '+proj=utm +zone=59 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2135, '+proj=utm +zone=60 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2136, '+proj=tmerc +lat_0=4.666666666666667 +lon_0=-1 +k=0.99975 +x_0=274319.7391633579 +y_0=0 +a=6378300 +b=6356751.689189189 +towgs84=-199,32,322,0,0,0,0 +to_meter=0.3047997101815088 +no_defs '],
    [2137, '+proj=tmerc +lat_0=0 +lon_0=-1 +k=0.9996 +x_0=500000 +y_0=0 +a=6378300 +b=6356751.689189189 +towgs84=-199,32,322,0,0,0,0 +units=m +no_defs '],
    [2138, '+proj=lcc +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +ellps=clrk66 +units=m +no_defs'],
    [2139, '+proj=tmerc +lat_0=0 +lon_0=-55.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2140, '+proj=tmerc +lat_0=0 +lon_0=-58.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2141, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2142, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2143, '+proj=tmerc +lat_0=0 +lon_0=-67.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2144, '+proj=tmerc +lat_0=0 +lon_0=-70.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2145, '+proj=tmerc +lat_0=0 +lon_0=-73.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2146, '+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2147, '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2148, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2149, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2150, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2151, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2152, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2153, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2154, '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2155, '+proj=lcc +lat_1=-14.26666666666667 +lat_0=-14.26666666666667 +lon_0=170 +k_0=1 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=-115,118,426,0,0,0,0 +units=us-ft +no_defs '],
    [2156, '+proj=utm +zone=59 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2157, '+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=0.99982 +x_0=600000 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2158, '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2159, '+proj=tmerc +lat_0=6.66666666666666 +lon_0=-12 +k=1 +x_0=152399.855090754 +y_0=0 +a=6378300 +b=6356751.68918918 +to_meter=0.304799710181508 +no_defs'],
    [2160, '+proj=tmerc +lat_0=6.66666666666666 +lon_0=-12 +k=1 +x_0=243839.768145207 +y_0=182879.826108905 +a=6378300 +b=6356751.68918918 +to_meter=0.304799710181508 +no_defs'],
    [2161, '+proj=utm +zone=28 +ellps=clrk80 +towgs84=-88,4,101,0,0,0,0 +units=m +no_defs '],
    [2162, '+proj=utm +zone=29 +ellps=clrk80 +towgs84=-88,4,101,0,0,0,0 +units=m +no_defs '],
    [2163, '+proj=laea +lat_0=45 +lon_0=-100 +x_0=0 +y_0=0 +a=6370997 +b=6370997 +units=m +no_defs'],
    [2164, '+proj=tmerc +lat_0=0 +lon_0=-5 +k=0.9996 +x_0=500000 +y_0=0 +ellps=clrk80 +towgs84=-125,53,467,0,0,0,0 +units=m +no_defs '],
    [2165, '+proj=tmerc +lat_0=0 +lon_0=-5 +k=0.9996 +x_0=500000 +y_0=0 +ellps=clrk80 +towgs84=-124.76,53,466.79,0,0,0,0 +units=m +no_defs '],
    [2166, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [2167, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [2168, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [2169, '+proj=tmerc +lat_0=49.83333333333334 +lon_0=6.166666666666667 +k=1 +x_0=80000 +y_0=100000 +ellps=intl +towgs84=-189.6806,18.3463,-42.7695,-0.33746,-3.09264,2.53861,0.4598 +units=m +no_defs '],
    [2170, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [2171, '+proj=sterea +lat_0=50.625 +lon_0=21.08333333333333 +k=0.9998 +x_0=4637000 +y_0=5647000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [2172, '+proj=sterea +lat_0=53.00194444444445 +lon_0=21.50277777777778 +k=0.9998 +x_0=4603000 +y_0=5806000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [2173, '+proj=sterea +lat_0=53.58333333333334 +lon_0=17.00833333333333 +k=0.9998 +x_0=3501000 +y_0=5999000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [2174, '+proj=sterea +lat_0=51.67083333333333 +lon_0=16.67222222222222 +k=0.9998 +x_0=3703000 +y_0=5627000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [2175, '+proj=tmerc +lat_0=0 +lon_0=18.95833333333333 +k=0.999983 +x_0=237000 +y_0=-4700000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [2176, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2177, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2178, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2179, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2180, '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2181, '+proj=tmerc +lat_0=0 +lon_0=27 +k=0.9996 +x_0=9500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2182, '+proj=tmerc +lat_0=0 +lon_0=30 +k=0.9996 +x_0=10500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2183, '+proj=tmerc +lat_0=0 +lon_0=33 +k=0.9996 +x_0=11500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2184, '+proj=tmerc +lat_0=0 +lon_0=36 +k=0.9996 +x_0=12500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2185, '+proj=tmerc +lat_0=0 +lon_0=39 +k=0.9996 +x_0=13500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2186, '+proj=tmerc +lat_0=0 +lon_0=42 +k=0.9996 +x_0=14500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2187, '+proj=tmerc +lat_0=0 +lon_0=45 +k=0.9996 +x_0=15500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [2188, '+proj=utm +zone=25 +ellps=intl +towgs84=-425,-169,81,0,0,0,0 +units=m +no_defs '],
    [2189, '+proj=utm +zone=26 +ellps=intl +towgs84=-104,167,-38,0,0,0,0 +units=m +no_defs '],
    [2190, '+proj=utm +zone=26 +ellps=intl +towgs84=-203,141,53,0,0,0,0 +units=m +no_defs '],
    [2191, '+proj=utm +zone=28 +ellps=intl +units=m +no_defs'],
    [2192, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=2.337229166666667 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2193, '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2194, '+proj=lcc +lat_1=-14.26666666666667 +lat_0=-14.26666666666667 +lon_0=-170 +k_0=1 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=-115,118,426,0,0,0,0 +units=us-ft +no_defs '],
    [2195, '+proj=utm +zone=2 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2196, '+proj=tmerc +lat_0=0 +lon_0=9.5 +k=0.99995 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2197, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.99995 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2198, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2199, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=-44.183,-0.58,-38.489,2.3867,2.7072,-3.5196,-8.2703 +units=m +no_defs '],
    [2200, '+proj=sterea +lat_0=46.5 +lon_0=-66.5 +k=0.999912 +x_0=300000 +y_0=800000 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2201, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2202, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2203, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2204, '+proj=lcc +lat_1=35.25 +lat_2=36.4166666666666 +lat_0=34.6666666666666 +lon_0=-86 +x_0=609601.219202438 +y_0=30480.0609601219 +datum=NAD27 +units=us-ft +no_defs'],
    [2205, '+proj=lcc +lat_1=37.9666666666666 +lat_2=38.9666666666666 +lat_0=37.5 +lon_0=-84.25 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [2206, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=9500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2207, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=10500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2208, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=11500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2209, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=12500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2210, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=13500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2211, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=14500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2212, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=15500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2213, '+proj=tmerc +lat_0=0 +lon_0=30 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2214, '+proj=tmerc +lat_0=0 +lon_0=10.5 +k=0.999 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=-206.1,-174.7,-87.7,0,0,0,0 +units=m +no_defs '],
    [2215, '+proj=utm +zone=32 +a=6378249.2 +b=6356515 +towgs84=-70.9,-151.8,-41.4,0,0,0,0 +units=m +no_defs '],
    [2216, '+proj=utm +zone=22 +ellps=intl +towgs84=164,138,-189,0,0,0,0 +units=m +no_defs '],
    [2217, '+proj=utm +zone=23 +ellps=intl +towgs84=164,138,-189,0,0,0,0 +units=m +no_defs '],
    [2219, '+proj=utm +zone=19 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2220, '+proj=utm +zone=20 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2222, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666666 +k=0.9999 +x_0=213360 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2223, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666666 +k=0.9999 +x_0=213360 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2224, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2225, '+proj=lcc +lat_1=41.6666666666666 +lat_2=40 +lat_0=39.3333333333333 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2226, '+proj=lcc +lat_1=39.8333333333333 +lat_2=38.3333333333333 +lat_0=37.6666666666666 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2227, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.0666666666666 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2228, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.3333333333333 +lon_0=-119 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2229, '+proj=lcc +lat_1=35.4666666666666 +lat_2=34.0333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2230, '+proj=lcc +lat_1=33.8833333333333 +lat_2=32.7833333333333 +lat_0=32.1666666666666 +lon_0=-116.25 +x_0=2000000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2231, '+proj=lcc +lat_1=40.7833333333333 +lat_2=39.7166666666666 +lat_0=39.3333333333333 +lon_0=-105.5 +x_0=914401.828803657 +y_0=304800.609601219 +datum=NAD83 +units=us-ft +no_defs'],
    [2232, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.8333333333333 +lon_0=-105.5 +x_0=914401.828803657 +y_0=304800.609601219 +datum=NAD83 +units=us-ft +no_defs'],
    [2233, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.2333333333333 +lat_0=36.6666666666666 +lon_0=-105.5 +x_0=914401.828803657 +y_0=304800.609601219 +datum=NAD83 +units=us-ft +no_defs'],
    [2234, '+proj=lcc +lat_1=41.8666666666666 +lat_2=41.2 +lat_0=40.8333333333333 +lon_0=-72.75 +x_0=304800.609601219 +y_0=152400.304800609 +datum=NAD83 +units=us-ft +no_defs'],
    [2235, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666666 +k=0.999995 +x_0=200000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2236, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2237, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2238, '+proj=lcc +lat_1=30.75 +lat_2=29.5833333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2239, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666666 +k=0.9999 +x_0=200000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2240, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666666 +k=0.9999 +x_0=699999.999898399 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2241, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-112.166666666666 +k=0.999947367999999 +x_0=200000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2242, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-114 +k=0.999947367999999 +x_0=500000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2243, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2244, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666666 +k=0.999966667 +x_0=99999.9998983997 +y_0=249364.998729997 +datum=NAD83 +units=us-ft +no_defs'],
    [2245, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=900000 +y_0=249364.998729997 +datum=NAD83 +units=us-ft +no_defs'],
    [2246, '+proj=lcc +lat_1=37.9666666666666 +lat_2=38.9666666666666 +lat_0=37.5 +lon_0=-84.25 +x_0=500000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2247, '+proj=lcc +lat_1=37.9333333333333 +lat_2=36.7333333333333 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=500000.0001016 +y_0=500000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2248, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.6666666666666 +lon_0=-77 +x_0=399999.999898399 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2249, '+proj=lcc +lat_1=42.6833333333333 +lat_2=41.7166666666666 +lat_0=41 +lon_0=-71.5 +x_0=200000.0001016 +y_0=750000 +datum=NAD83 +units=us-ft +no_defs'],
    [2250, '+proj=lcc +lat_1=41.4833333333333 +lat_2=41.2833333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2251, '+proj=lcc +lat_1=47.0833333333333 +lat_2=45.4833333333333 +lat_0=44.7833333333333 +lon_0=-87 +x_0=7999999.999968 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2252, '+proj=lcc +lat_1=45.7 +lat_2=44.1833333333333 +lat_0=43.3166666666666 +lon_0=-84.3666666666666 +x_0=5999999.999976 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2253, '+proj=lcc +lat_1=43.6666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.3666666666666 +x_0=3999999.999984 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2254, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2255, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=699999.999898399 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2256, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=599999.9999976 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2257, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2258, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2259, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916667 +x_0=830000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2260, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2261, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=249999.999898399 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2262, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2263, '+proj=lcc +lat_1=41.0333333333333 +lat_2=40.6666666666666 +lat_0=40.1666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2264, '+proj=lcc +lat_1=36.1666666666666 +lat_2=34.3333333333333 +lat_0=33.75 +lon_0=-79 +x_0=609601.219202438 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2265, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.4333333333333 +lat_0=47 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2266, '+proj=lcc +lat_1=47.4833333333333 +lat_2=46.1833333333333 +lat_0=45.6666666666666 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2267, '+proj=lcc +lat_1=36.7666666666666 +lat_2=35.5666666666666 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2268, '+proj=lcc +lat_1=35.2333333333333 +lat_2=33.9333333333333 +lat_0=33.3333333333333 +lon_0=-98 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2269, '+proj=lcc +lat_1=46 +lat_2=44.3333333333333 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=2500000.0001424 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2270, '+proj=lcc +lat_1=44 +lat_2=42.3333333333333 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=1500000.0001464 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2271, '+proj=lcc +lat_1=41.95 +lat_2=40.8833333333333 +lat_0=40.1666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2272, '+proj=lcc +lat_1=40.9666666666666 +lat_2=39.9333333333333 +lat_0=39.3333333333333 +lon_0=-77.75 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2273, '+proj=lcc +lat_1=34.8333333333333 +lat_2=32.5 +lat_0=31.8333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2274, '+proj=lcc +lat_1=36.4166666666666 +lat_2=35.25 +lat_0=34.3333333333333 +lon_0=-86 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2275, '+proj=lcc +lat_1=36.1833333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000.0001016 +y_0=999999.999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [2276, '+proj=lcc +lat_1=33.9666666666666 +lat_2=32.1333333333333 +lat_0=31.6666666666666 +lon_0=-98.5 +x_0=600000 +y_0=2000000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2277, '+proj=lcc +lat_1=31.8833333333333 +lat_2=30.1166666666666 +lat_0=29.6666666666666 +lon_0=-100.333333333333 +x_0=699999.999898399 +y_0=3000000 +datum=NAD83 +units=us-ft +no_defs'],
    [2278, '+proj=lcc +lat_1=30.2833333333333 +lat_2=28.3833333333333 +lat_0=27.8333333333333 +lon_0=-99 +x_0=600000 +y_0=3999999.9998984 +datum=NAD83 +units=us-ft +no_defs'],
    [2279, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.6666666666666 +lon_0=-98.5 +x_0=300000 +y_0=5000000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2280, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.7166666666666 +lat_0=40.3333333333333 +lon_0=-111.5 +x_0=500000.0001504 +y_0=999999.999996 +datum=NAD83 +units=ft +no_defs'],
    [2281, '+proj=lcc +lat_1=40.65 +lat_2=39.0166666666666 +lat_0=38.3333333333333 +lon_0=-111.5 +x_0=500000.0001504 +y_0=1999999.999992 +datum=NAD83 +units=ft +no_defs'],
    [2282, '+proj=lcc +lat_1=38.35 +lat_2=37.2166666666666 +lat_0=36.6666666666666 +lon_0=-111.5 +x_0=500000.0001504 +y_0=2999999.999988 +datum=NAD83 +units=ft +no_defs'],
    [2283, '+proj=lcc +lat_1=39.2 +lat_2=38.0333333333333 +lat_0=37.6666666666666 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=2000000.0001016 +datum=NAD83 +units=us-ft +no_defs'],
    [2284, '+proj=lcc +lat_1=37.9666666666666 +lat_2=36.7666666666666 +lat_0=36.3333333333333 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=999999.999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [2285, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.833333333333 +x_0=500000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2286, '+proj=lcc +lat_1=47.3333333333333 +lat_2=45.8333333333333 +lat_0=45.3333333333333 +lon_0=-120.5 +x_0=500000.0001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2287, '+proj=lcc +lat_1=46.7666666666666 +lat_2=45.5666666666666 +lat_0=45.1666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2288, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.8333333333333 +lon_0=-90 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2289, '+proj=lcc +lat_1=44.0666666666666 +lat_2=42.7333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [2290, '+proj=sterea +lat_0=47.25 +lon_0=-63 +k=0.999912 +x_0=700000 +y_0=400000 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2291, '+proj=sterea +lat_0=47.25 +lon_0=-63 +k=0.999912 +x_0=400000 +y_0=800000 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2292, '+proj=sterea +lat_0=47.25 +lon_0=-63 +k=0.999912 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2294, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=4500000 +y_0=0 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2295, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=5500000 +y_0=0 +a=6378135 +b=6356750.30492159 +units=m +no_defs'],
    [2308, '+proj=tmerc +lat_0=0 +lon_0=109 +k=0.9996 +x_0=500000 +y_0=10000000 +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +units=m +no_defs '],
    [2309, '+proj=tmerc +lat_0=0 +lon_0=116 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs'],
    [2310, '+proj=tmerc +lat_0=0 +lon_0=132 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs'],
    [2311, '+proj=tmerc +lat_0=0 +lon_0=6 +k=0.9996 +x_0=500000 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [2312, '+proj=utm +zone=33 +ellps=clrk80 +units=m +no_defs'],
    [2313, '+proj=utm +zone=33 +ellps=clrk80 +units=m +no_defs'],
    [2314, '+proj=cass +lat_0=10.44166666666667 +lon_0=-61.33333333333334 +x_0=86501.46392052001 +y_0=65379.0134283 +a=6378293.645208759 +b=6356617.987679838 +towgs84=-61.702,284.488,472.052,0,0,0,0 +to_meter=0.3047972654 +no_defs '],
    [2315, '+proj=utm +zone=19 +south +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [2316, '+proj=utm +zone=20 +south +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [2317, '+proj=lcc +lat_1=9 +lat_2=3 +lat_0=6 +lon_0=-66 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [2318, '+proj=lcc +lat_1=17 +lat_2=33 +lat_0=25.08951 +lon_0=48 +x_0=0 +y_0=0 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [2319, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2320, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2321, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2322, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2323, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2324, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2325, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [2326, '+proj=tmerc +lat_0=22.31213333333334 +lon_0=114.1785555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.243649,-1.158827,-1.094246 +units=m +no_defs '],
    [2327, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=13500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2328, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=14500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2329, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=15500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2330, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=16500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2331, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=17500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2332, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2333, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2334, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=20500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2335, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=21500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2336, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=22500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2337, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=23500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2338, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2339, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2340, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2341, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2342, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2343, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2344, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2345, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2346, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2347, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2348, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2349, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=25500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2350, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=26500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2351, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=27500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2352, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=28500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2353, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=29500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2354, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=30500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2355, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=31500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2356, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=32500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2357, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=33500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2358, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=34500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2359, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=35500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2360, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=36500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2361, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=37500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2362, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2363, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2364, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2365, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2366, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=42500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2367, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=43500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2368, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=44500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2369, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=45500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2370, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2371, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2372, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2373, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2374, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2375, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2376, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2377, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2378, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2379, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2380, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2381, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2382, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2383, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2384, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2385, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2386, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2387, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2388, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2389, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2390, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +a=6378140 +b=6356755.28815752 +units=m +no_defs'],
    [2391, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +no_defs '],
    [2392, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=2500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +no_defs '],
    [2393, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=3500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +no_defs '],
    [2394, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=4500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +no_defs '],
    [2395, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=-76,-138,67,0,0,0,0 +units=m +no_defs '],
    [2396, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=-76,-138,67,0,0,0,0 +units=m +no_defs '],
    [2397, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=24.9,-126.4,-93.2,-0.063,-0.247,-0.041,1.01 +units=m +no_defs '],
    [2398, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=24.9,-126.4,-93.2,-0.063,-0.247,-0.041,1.01 +units=m +no_defs '],
    [2399, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=24.9,-126.4,-93.2,-0.063,-0.247,-0.041,1.01 +units=m +no_defs '],
    [2400, '+proj=tmerc +lat_0=0 +lon_0=15.80827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [2401, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=25500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2402, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=26500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2403, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=27500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2404, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=28500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2405, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=29500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2406, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=30500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2407, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=31500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2408, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=32500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2409, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=33500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2410, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=34500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2411, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=35500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2412, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=36500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2413, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=37500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2414, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2415, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2416, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2417, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2418, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=42500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2419, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=43500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2420, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=44500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2421, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=45500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2422, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2423, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2424, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2425, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2426, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2427, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2428, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2429, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2430, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2431, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2432, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2433, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2434, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2435, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2436, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2437, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2438, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2439, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2440, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2441, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2442, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [2443, '+proj=tmerc +lat_0=33 +lon_0=129.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2444, '+proj=tmerc +lat_0=33 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2445, '+proj=tmerc +lat_0=36 +lon_0=132.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2446, '+proj=tmerc +lat_0=33 +lon_0=133.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2447, '+proj=tmerc +lat_0=36 +lon_0=134.3333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2448, '+proj=tmerc +lat_0=36 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2449, '+proj=tmerc +lat_0=36 +lon_0=137.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2450, '+proj=tmerc +lat_0=36 +lon_0=138.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2451, '+proj=tmerc +lat_0=36 +lon_0=139.8333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2452, '+proj=tmerc +lat_0=40 +lon_0=140.8333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2453, '+proj=tmerc +lat_0=44 +lon_0=140.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2454, '+proj=tmerc +lat_0=44 +lon_0=142.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2455, '+proj=tmerc +lat_0=44 +lon_0=144.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2456, '+proj=tmerc +lat_0=26 +lon_0=142 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2457, '+proj=tmerc +lat_0=26 +lon_0=127.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2458, '+proj=tmerc +lat_0=26 +lon_0=124 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2459, '+proj=tmerc +lat_0=26 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2460, '+proj=tmerc +lat_0=20 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2461, '+proj=tmerc +lat_0=26 +lon_0=154 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2462, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=-44.183,-0.58,-38.489,2.3867,2.7072,-3.5196,-8.2703 +units=m +no_defs '],
    [2463, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2464, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2465, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2466, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2467, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2468, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2469, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2470, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2471, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2472, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2473, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2474, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2475, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2476, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2477, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2478, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2479, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2480, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2481, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2482, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2483, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2484, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2485, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2486, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2487, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2488, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2489, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2490, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2491, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2492, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2493, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2494, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2495, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2496, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2497, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2498, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2499, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2500, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2501, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2502, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2503, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2504, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2505, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2506, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2507, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2508, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2509, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2510, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2511, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2512, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2513, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2514, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2515, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2516, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2517, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2518, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2519, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2520, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2521, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2522, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2523, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2524, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2525, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2526, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=10500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2527, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=11500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2528, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=12500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2529, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2530, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=14500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2531, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=15500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2532, '+proj=tmerc +lat_0=0 +lon_0=48 +k=1 +x_0=16500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2533, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=17500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2534, '+proj=tmerc +lat_0=0 +lon_0=54 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2535, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2536, '+proj=tmerc +lat_0=0 +lon_0=60 +k=1 +x_0=20500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2537, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=21500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2538, '+proj=tmerc +lat_0=0 +lon_0=66 +k=1 +x_0=22500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2539, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=23500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2540, '+proj=tmerc +lat_0=0 +lon_0=72 +k=1 +x_0=24500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2541, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=25500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2542, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=26500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2543, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=27500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2544, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=28500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2545, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=29500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2546, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=30500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2547, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=31500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2548, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=32500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2549, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=33500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2550, '+proj=utm +zone=50 +south +ellps=bessel +towgs84=-404.78,685.68,45.47,0,0,0,0 +units=m +no_defs '],
    [2551, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=34500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2552, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=35500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2553, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=36500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2554, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=37500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2555, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2556, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2557, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2558, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2559, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=42500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2560, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=43500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2561, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=44500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2562, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=45500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2563, '+proj=tmerc +lat_0=0 +lon_0=138 +k=1 +x_0=46500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2564, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=47500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2565, '+proj=tmerc +lat_0=0 +lon_0=144 +k=1 +x_0=48500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2566, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=49500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2567, '+proj=tmerc +lat_0=0 +lon_0=150 +k=1 +x_0=50500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2568, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=51500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2569, '+proj=tmerc +lat_0=0 +lon_0=156 +k=1 +x_0=52500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2570, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=53500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2571, '+proj=tmerc +lat_0=0 +lon_0=162 +k=1 +x_0=54500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2572, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=55500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2573, '+proj=tmerc +lat_0=0 +lon_0=168 +k=1 +x_0=56500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2574, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=57500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2575, '+proj=tmerc +lat_0=0 +lon_0=174 +k=1 +x_0=58500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2576, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=59500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2577, '+proj=tmerc +lat_0=0 +lon_0=180 +k=1 +x_0=60000000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2578, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=61500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2579, '+proj=tmerc +lat_0=0 +lon_0=-174 +k=1 +x_0=62500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2580, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=63500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2581, '+proj=tmerc +lat_0=0 +lon_0=-168 +k=1 +x_0=64500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2582, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2583, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2584, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2585, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2586, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2587, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2588, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2589, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2590, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2591, '+proj=tmerc +lat_0=0 +lon_0=48 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2592, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2593, '+proj=tmerc +lat_0=0 +lon_0=54 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2594, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2595, '+proj=tmerc +lat_0=0 +lon_0=60 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2596, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2597, '+proj=tmerc +lat_0=0 +lon_0=66 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2598, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2599, '+proj=tmerc +lat_0=0 +lon_0=72 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2600, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9998 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2601, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2602, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2603, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2604, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2605, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2606, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2607, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2608, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2609, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2610, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2611, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2612, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2613, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2614, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2615, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2616, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2617, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2618, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2619, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2620, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2621, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2622, '+proj=tmerc +lat_0=0 +lon_0=138 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2623, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2624, '+proj=tmerc +lat_0=0 +lon_0=144 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2625, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2626, '+proj=tmerc +lat_0=0 +lon_0=150 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2627, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2628, '+proj=tmerc +lat_0=0 +lon_0=156 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2629, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2630, '+proj=tmerc +lat_0=0 +lon_0=162 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2631, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2632, '+proj=tmerc +lat_0=0 +lon_0=168 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2633, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2634, '+proj=tmerc +lat_0=0 +lon_0=174 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2635, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2636, '+proj=tmerc +lat_0=0 +lon_0=180 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2637, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2638, '+proj=tmerc +lat_0=0 +lon_0=-174 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2639, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2640, '+proj=tmerc +lat_0=0 +lon_0=-168 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2641, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2642, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2643, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2644, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=10500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2645, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=11500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2646, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=12500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2647, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2648, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=14500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2649, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=15500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2650, '+proj=tmerc +lat_0=0 +lon_0=48 +k=1 +x_0=16500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2651, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=17500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2652, '+proj=tmerc +lat_0=0 +lon_0=54 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2653, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2654, '+proj=tmerc +lat_0=0 +lon_0=60 +k=1 +x_0=20500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2655, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=21500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2656, '+proj=tmerc +lat_0=0 +lon_0=66 +k=1 +x_0=22500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2657, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=23500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2658, '+proj=tmerc +lat_0=0 +lon_0=72 +k=1 +x_0=24500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2659, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=25500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2660, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=26500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2661, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=27500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2662, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=28500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2663, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=29500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2664, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=30500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2665, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=31500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2666, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=32500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2667, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=33500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2668, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=34500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2669, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=35500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2670, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=36500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2671, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=37500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2672, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2673, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2674, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2675, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2676, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=42500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2677, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=43500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2678, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=44500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2679, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=45500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2680, '+proj=tmerc +lat_0=0 +lon_0=138 +k=1 +x_0=46500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2681, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=47500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2682, '+proj=tmerc +lat_0=0 +lon_0=144 +k=1 +x_0=48500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2683, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=49500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2684, '+proj=tmerc +lat_0=0 +lon_0=150 +k=1 +x_0=50500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2685, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=51500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2686, '+proj=tmerc +lat_0=0 +lon_0=156 +k=1 +x_0=52500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2687, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=53500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2688, '+proj=tmerc +lat_0=0 +lon_0=162 +k=1 +x_0=54500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2689, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=55500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2690, '+proj=tmerc +lat_0=0 +lon_0=168 +k=1 +x_0=56500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2691, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=57500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2692, '+proj=tmerc +lat_0=0 +lon_0=174 +k=1 +x_0=58500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2693, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=59500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2694, '+proj=tmerc +lat_0=0 +lon_0=180 +k=1 +x_0=60000000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2695, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=61500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2696, '+proj=tmerc +lat_0=0 +lon_0=-174 +k=1 +x_0=62500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2697, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=63500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2698, '+proj=tmerc +lat_0=0 +lon_0=-168 +k=1 +x_0=64500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2699, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2700, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2701, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2702, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2703, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2704, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2705, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2706, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2707, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2708, '+proj=tmerc +lat_0=0 +lon_0=48 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2709, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2710, '+proj=tmerc +lat_0=0 +lon_0=54 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2711, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2712, '+proj=tmerc +lat_0=0 +lon_0=60 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2713, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2714, '+proj=tmerc +lat_0=0 +lon_0=66 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2715, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2716, '+proj=tmerc +lat_0=0 +lon_0=72 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2717, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2718, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2719, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2720, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2721, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2722, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2723, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2724, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2725, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2726, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2727, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2728, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2729, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2730, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2731, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2732, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2733, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2734, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2735, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2736, '+proj=utm +zone=36 +south +ellps=clrk66 +towgs84=-80,-100,-228,0,0,0,0 +units=m +no_defs '],
    [2737, '+proj=utm +zone=37 +south +ellps=clrk66 +towgs84=-80,-100,-228,0,0,0,0 +units=m +no_defs '],
    [2738, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2739, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2740, '+proj=tmerc +lat_0=0 +lon_0=138 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2741, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2742, '+proj=tmerc +lat_0=0 +lon_0=144 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2743, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2744, '+proj=tmerc +lat_0=0 +lon_0=150 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2745, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2746, '+proj=tmerc +lat_0=0 +lon_0=156 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2747, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2748, '+proj=tmerc +lat_0=0 +lon_0=162 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2749, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2750, '+proj=tmerc +lat_0=0 +lon_0=168 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2751, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2752, '+proj=tmerc +lat_0=0 +lon_0=174 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2753, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2754, '+proj=tmerc +lat_0=0 +lon_0=180 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2755, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2756, '+proj=tmerc +lat_0=0 +lon_0=-174 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2757, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2758, '+proj=tmerc +lat_0=0 +lon_0=-168 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [2759, '+proj=tmerc +lat_0=30.5 +lon_0=-85.83333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2760, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2761, '+proj=tmerc +lat_0=31 +lon_0=-110.1666666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2762, '+proj=tmerc +lat_0=31 +lon_0=-111.9166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2763, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2764, '+proj=lcc +lat_1=36.23333333333333 +lat_2=34.93333333333333 +lat_0=34.33333333333334 +lon_0=-92 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2765, '+proj=lcc +lat_1=34.76666666666667 +lat_2=33.3 +lat_0=32.66666666666666 +lon_0=-92 +x_0=400000 +y_0=400000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2766, '+proj=lcc +lat_1=41.66666666666666 +lat_2=40 +lat_0=39.33333333333334 +lon_0=-122 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2767, '+proj=lcc +lat_1=39.83333333333334 +lat_2=38.33333333333334 +lat_0=37.66666666666666 +lon_0=-122 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2768, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.06666666666667 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2769, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.33333333333334 +lon_0=-119 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2770, '+proj=lcc +lat_1=35.46666666666667 +lat_2=34.03333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2771, '+proj=lcc +lat_1=33.88333333333333 +lat_2=32.78333333333333 +lat_0=32.16666666666666 +lon_0=-116.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2772, '+proj=lcc +lat_1=40.78333333333333 +lat_2=39.71666666666667 +lat_0=39.33333333333334 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2773, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.83333333333334 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2774, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.23333333333333 +lat_0=36.66666666666666 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2775, '+proj=lcc +lat_1=41.86666666666667 +lat_2=41.2 +lat_0=40.83333333333334 +lon_0=-72.75 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2776, '+proj=tmerc +lat_0=38 +lon_0=-75.41666666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2777, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2778, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2779, '+proj=lcc +lat_1=30.75 +lat_2=29.58333333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2780, '+proj=tmerc +lat_0=30 +lon_0=-82.16666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2781, '+proj=tmerc +lat_0=30 +lon_0=-84.16666666666667 +k=0.9999 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2782, '+proj=tmerc +lat_0=18.83333333333333 +lon_0=-155.5 +k=0.999966667 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2783, '+proj=tmerc +lat_0=20.33333333333333 +lon_0=-156.6666666666667 +k=0.999966667 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2784, '+proj=tmerc +lat_0=21.16666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2785, '+proj=tmerc +lat_0=21.83333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2786, '+proj=tmerc +lat_0=21.66666666666667 +lon_0=-160.1666666666667 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2787, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-112.1666666666667 +k=0.9999473679999999 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2788, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-114 +k=0.9999473679999999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2789, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2790, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-88.33333333333333 +k=0.9999749999999999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2791, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-90.16666666666667 +k=0.999941177 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2792, '+proj=tmerc +lat_0=37.5 +lon_0=-85.66666666666667 +k=0.999966667 +x_0=100000 +y_0=250000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2793, '+proj=tmerc +lat_0=37.5 +lon_0=-87.08333333333333 +k=0.999966667 +x_0=900000 +y_0=250000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2794, '+proj=lcc +lat_1=43.26666666666667 +lat_2=42.06666666666667 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2795, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.61666666666667 +lat_0=40 +lon_0=-93.5 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2796, '+proj=lcc +lat_1=39.78333333333333 +lat_2=38.71666666666667 +lat_0=38.33333333333334 +lon_0=-98 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2797, '+proj=lcc +lat_1=38.56666666666667 +lat_2=37.26666666666667 +lat_0=36.66666666666666 +lon_0=-98.5 +x_0=400000 +y_0=400000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2798, '+proj=lcc +lat_1=37.96666666666667 +lat_2=38.96666666666667 +lat_0=37.5 +lon_0=-84.25 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2799, '+proj=lcc +lat_1=37.93333333333333 +lat_2=36.73333333333333 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=500000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2800, '+proj=lcc +lat_1=32.66666666666666 +lat_2=31.16666666666667 +lat_0=30.5 +lon_0=-92.5 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2801, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.33333333333333 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2802, '+proj=tmerc +lat_0=43.66666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2803, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.16666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2804, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.66666666666666 +lon_0=-77 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2805, '+proj=lcc +lat_1=42.68333333333333 +lat_2=41.71666666666667 +lat_0=41 +lon_0=-71.5 +x_0=200000 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2806, '+proj=lcc +lat_1=41.48333333333333 +lat_2=41.28333333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2807, '+proj=lcc +lat_1=47.08333333333334 +lat_2=45.48333333333333 +lat_0=44.78333333333333 +lon_0=-87 +x_0=8000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2808, '+proj=lcc +lat_1=45.7 +lat_2=44.18333333333333 +lat_0=43.31666666666667 +lon_0=-84.36666666666666 +x_0=6000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2809, '+proj=lcc +lat_1=43.66666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.36666666666666 +x_0=4000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2810, '+proj=lcc +lat_1=48.63333333333333 +lat_2=47.03333333333333 +lat_0=46.5 +lon_0=-93.09999999999999 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2811, '+proj=lcc +lat_1=47.05 +lat_2=45.61666666666667 +lat_0=45 +lon_0=-94.25 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2812, '+proj=lcc +lat_1=45.21666666666667 +lat_2=43.78333333333333 +lat_0=43 +lon_0=-94 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2813, '+proj=tmerc +lat_0=29.5 +lon_0=-88.83333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2814, '+proj=tmerc +lat_0=29.5 +lon_0=-90.33333333333333 +k=0.99995 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2815, '+proj=tmerc +lat_0=35.83333333333334 +lon_0=-90.5 +k=0.999933333 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2816, '+proj=tmerc +lat_0=35.83333333333334 +lon_0=-92.5 +k=0.999933333 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2817, '+proj=tmerc +lat_0=36.16666666666666 +lon_0=-94.5 +k=0.999941177 +x_0=850000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2818, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2819, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2820, '+proj=tmerc +lat_0=34.75 +lon_0=-115.5833333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2821, '+proj=tmerc +lat_0=34.75 +lon_0=-116.6666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2822, '+proj=tmerc +lat_0=34.75 +lon_0=-118.5833333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2823, '+proj=tmerc +lat_0=42.5 +lon_0=-71.66666666666667 +k=0.999966667 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2824, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2825, '+proj=tmerc +lat_0=31 +lon_0=-104.3333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2826, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2827, '+proj=tmerc +lat_0=31 +lon_0=-107.8333333333333 +k=0.999916667 +x_0=830000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2828, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2829, '+proj=tmerc +lat_0=40 +lon_0=-76.58333333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2830, '+proj=tmerc +lat_0=40 +lon_0=-78.58333333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2831, '+proj=lcc +lat_1=41.03333333333333 +lat_2=40.66666666666666 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2832, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.43333333333333 +lat_0=47 +lon_0=-100.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2833, '+proj=lcc +lat_1=47.48333333333333 +lat_2=46.18333333333333 +lat_0=45.66666666666666 +lon_0=-100.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2834, '+proj=lcc +lat_1=41.7 +lat_2=40.43333333333333 +lat_0=39.66666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2835, '+proj=lcc +lat_1=40.03333333333333 +lat_2=38.73333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2836, '+proj=lcc +lat_1=36.76666666666667 +lat_2=35.56666666666667 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2837, '+proj=lcc +lat_1=35.23333333333333 +lat_2=33.93333333333333 +lat_0=33.33333333333334 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2838, '+proj=lcc +lat_1=46 +lat_2=44.33333333333334 +lat_0=43.66666666666666 +lon_0=-120.5 +x_0=2500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2839, '+proj=lcc +lat_1=44 +lat_2=42.33333333333334 +lat_0=41.66666666666666 +lon_0=-120.5 +x_0=1500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2840, '+proj=tmerc +lat_0=41.08333333333334 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2841, '+proj=lcc +lat_1=45.68333333333333 +lat_2=44.41666666666666 +lat_0=43.83333333333334 +lon_0=-100 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2842, '+proj=lcc +lat_1=44.4 +lat_2=42.83333333333334 +lat_0=42.33333333333334 +lon_0=-100.3333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2843, '+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2844, '+proj=lcc +lat_1=36.18333333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2845, '+proj=lcc +lat_1=33.96666666666667 +lat_2=32.13333333333333 +lat_0=31.66666666666667 +lon_0=-98.5 +x_0=600000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2846, '+proj=lcc +lat_1=31.88333333333333 +lat_2=30.11666666666667 +lat_0=29.66666666666667 +lon_0=-100.3333333333333 +x_0=700000 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2847, '+proj=lcc +lat_1=30.28333333333333 +lat_2=28.38333333333333 +lat_0=27.83333333333333 +lon_0=-99 +x_0=600000 +y_0=4000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2848, '+proj=lcc +lat_1=27.83333333333333 +lat_2=26.16666666666667 +lat_0=25.66666666666667 +lon_0=-98.5 +x_0=300000 +y_0=5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2849, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.71666666666667 +lat_0=40.33333333333334 +lon_0=-111.5 +x_0=500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2850, '+proj=lcc +lat_1=40.65 +lat_2=39.01666666666667 +lat_0=38.33333333333334 +lon_0=-111.5 +x_0=500000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2851, '+proj=lcc +lat_1=38.35 +lat_2=37.21666666666667 +lat_0=36.66666666666666 +lon_0=-111.5 +x_0=500000 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2852, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2853, '+proj=lcc +lat_1=39.2 +lat_2=38.03333333333333 +lat_0=37.66666666666666 +lon_0=-78.5 +x_0=3500000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2854, '+proj=lcc +lat_1=37.96666666666667 +lat_2=36.76666666666667 +lat_0=36.33333333333334 +lon_0=-78.5 +x_0=3500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2855, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.8333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2856, '+proj=lcc +lat_1=47.33333333333334 +lat_2=45.83333333333334 +lat_0=45.33333333333334 +lon_0=-120.5 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2857, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2858, '+proj=lcc +lat_1=38.88333333333333 +lat_2=37.48333333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2859, '+proj=lcc +lat_1=46.76666666666667 +lat_2=45.56666666666667 +lat_0=45.16666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2860, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.83333333333334 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2861, '+proj=lcc +lat_1=44.06666666666667 +lat_2=42.73333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2862, '+proj=tmerc +lat_0=40.5 +lon_0=-105.1666666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2863, '+proj=tmerc +lat_0=40.5 +lon_0=-107.3333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2864, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2865, '+proj=tmerc +lat_0=40.5 +lon_0=-110.0833333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2866, '+proj=lcc +lat_1=18.43333333333333 +lat_2=18.03333333333333 +lat_0=17.83333333333333 +lon_0=-66.43333333333334 +x_0=200000 +y_0=200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2867, '+proj=tmerc +lat_0=31 +lon_0=-110.1666666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2868, '+proj=tmerc +lat_0=31 +lon_0=-111.9166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2869, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2870, '+proj=lcc +lat_1=41.66666666666666 +lat_2=40 +lat_0=39.33333333333334 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2871, '+proj=lcc +lat_1=39.83333333333334 +lat_2=38.33333333333334 +lat_0=37.66666666666666 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2872, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.06666666666667 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2873, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.33333333333334 +lon_0=-119 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2874, '+proj=lcc +lat_1=35.46666666666667 +lat_2=34.03333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2875, '+proj=lcc +lat_1=33.88333333333333 +lat_2=32.78333333333333 +lat_0=32.16666666666666 +lon_0=-116.25 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2876, '+proj=lcc +lat_1=40.78333333333333 +lat_2=39.71666666666667 +lat_0=39.33333333333334 +lon_0=-105.5 +x_0=914401.8288036576 +y_0=304800.6096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2877, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.83333333333334 +lon_0=-105.5 +x_0=914401.8288036576 +y_0=304800.6096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2878, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.23333333333333 +lat_0=36.66666666666666 +lon_0=-105.5 +x_0=914401.8288036576 +y_0=304800.6096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2879, '+proj=lcc +lat_1=41.86666666666667 +lat_2=41.2 +lat_0=40.83333333333334 +lon_0=-72.75 +x_0=304800.6096012192 +y_0=152400.3048006096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2880, '+proj=tmerc +lat_0=38 +lon_0=-75.41666666666667 +k=0.999995 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2881, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2882, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2883, '+proj=lcc +lat_1=30.75 +lat_2=29.58333333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2884, '+proj=tmerc +lat_0=30 +lon_0=-82.16666666666667 +k=0.9999 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2885, '+proj=tmerc +lat_0=30 +lon_0=-84.16666666666667 +k=0.9999 +x_0=699999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2886, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-112.1666666666667 +k=0.9999473679999999 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2887, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-114 +k=0.9999473679999999 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2888, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2889, '+proj=tmerc +lat_0=37.5 +lon_0=-85.66666666666667 +k=0.999966667 +x_0=99999.99989839978 +y_0=249364.9987299975 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2890, '+proj=tmerc +lat_0=37.5 +lon_0=-87.08333333333333 +k=0.999966667 +x_0=900000 +y_0=249364.9987299975 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2891, '+proj=lcc +lat_1=37.96666666666667 +lat_2=38.96666666666667 +lat_0=37.5 +lon_0=-84.25 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2892, '+proj=lcc +lat_1=37.93333333333333 +lat_2=36.73333333333333 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=500000.0001016001 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2893, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.66666666666666 +lon_0=-77 +x_0=399999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2894, '+proj=lcc +lat_1=42.68333333333333 +lat_2=41.71666666666667 +lat_0=41 +lon_0=-71.5 +x_0=200000.0001016002 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2895, '+proj=lcc +lat_1=41.48333333333333 +lat_2=41.28333333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2896, '+proj=lcc +lat_1=47.08333333333334 +lat_2=45.48333333333333 +lat_0=44.78333333333333 +lon_0=-87 +x_0=7999999.999968001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2897, '+proj=lcc +lat_1=45.7 +lat_2=44.18333333333333 +lat_0=43.31666666666667 +lon_0=-84.36666666666666 +x_0=5999999.999976001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2898, '+proj=lcc +lat_1=43.66666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.36666666666666 +x_0=3999999.999984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2899, '+proj=tmerc +lat_0=29.5 +lon_0=-88.83333333333333 +k=0.99995 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2900, '+proj=tmerc +lat_0=29.5 +lon_0=-90.33333333333333 +k=0.99995 +x_0=699999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2901, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2902, '+proj=tmerc +lat_0=31 +lon_0=-104.3333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2903, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2904, '+proj=tmerc +lat_0=31 +lon_0=-107.8333333333333 +k=0.999916667 +x_0=830000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2905, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2906, '+proj=tmerc +lat_0=40 +lon_0=-76.58333333333333 +k=0.9999375 +x_0=249999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2907, '+proj=tmerc +lat_0=40 +lon_0=-78.58333333333333 +k=0.9999375 +x_0=350000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2908, '+proj=lcc +lat_1=41.03333333333333 +lat_2=40.66666666666666 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2909, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.43333333333333 +lat_0=47 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2910, '+proj=lcc +lat_1=47.48333333333333 +lat_2=46.18333333333333 +lat_0=45.66666666666666 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2911, '+proj=lcc +lat_1=36.76666666666667 +lat_2=35.56666666666667 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2912, '+proj=lcc +lat_1=35.23333333333333 +lat_2=33.93333333333333 +lat_0=33.33333333333334 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2913, '+proj=lcc +lat_1=46 +lat_2=44.33333333333334 +lat_0=43.66666666666666 +lon_0=-120.5 +x_0=2500000.0001424 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2914, '+proj=lcc +lat_1=44 +lat_2=42.33333333333334 +lat_0=41.66666666666666 +lon_0=-120.5 +x_0=1500000.0001464 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2915, '+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2916, '+proj=lcc +lat_1=36.18333333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000.0001016002 +y_0=999999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2917, '+proj=lcc +lat_1=33.96666666666667 +lat_2=32.13333333333333 +lat_0=31.66666666666667 +lon_0=-98.5 +x_0=600000 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2918, '+proj=lcc +lat_1=31.88333333333333 +lat_2=30.11666666666667 +lat_0=29.66666666666667 +lon_0=-100.3333333333333 +x_0=699999.9998983998 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2919, '+proj=lcc +lat_1=30.28333333333333 +lat_2=28.38333333333333 +lat_0=27.83333333333333 +lon_0=-99 +x_0=600000 +y_0=3999999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2920, '+proj=lcc +lat_1=27.83333333333333 +lat_2=26.16666666666667 +lat_0=25.66666666666667 +lon_0=-98.5 +x_0=300000.0000000001 +y_0=5000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2921, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.71666666666667 +lat_0=40.33333333333334 +lon_0=-111.5 +x_0=500000.0001504 +y_0=999999.9999960001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2922, '+proj=lcc +lat_1=40.65 +lat_2=39.01666666666667 +lat_0=38.33333333333334 +lon_0=-111.5 +x_0=500000.0001504 +y_0=1999999.999992 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2923, '+proj=lcc +lat_1=38.35 +lat_2=37.21666666666667 +lat_0=36.66666666666666 +lon_0=-111.5 +x_0=500000.0001504 +y_0=2999999.999988 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2924, '+proj=lcc +lat_1=39.2 +lat_2=38.03333333333333 +lat_0=37.66666666666666 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2925, '+proj=lcc +lat_1=37.96666666666667 +lat_2=36.76666666666667 +lat_0=36.33333333333334 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=999999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2926, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.8333333333333 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2927, '+proj=lcc +lat_1=47.33333333333334 +lat_2=45.83333333333334 +lat_0=45.33333333333334 +lon_0=-120.5 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2928, '+proj=lcc +lat_1=46.76666666666667 +lat_2=45.56666666666667 +lat_0=45.16666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2929, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.83333333333334 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2930, '+proj=lcc +lat_1=44.06666666666667 +lat_2=42.73333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2931, '+proj=tmerc +lat_0=0 +lon_0=13 +k=0.9996 +x_0=500000 +y_0=0 +a=6378249.2 +b=6356515 +towgs84=-106,-87,188,0,0,0,0 +units=m +no_defs '],
    [2932, '+proj=tmerc +lat_0=24.45 +lon_0=51.21666666666667 +k=0.99999 +x_0=200000 +y_0=300000 +ellps=intl +towgs84=-119.4248,-303.65872,-11.00061,1.164298,0.174458,1.096259,3.657065 +units=m +no_defs '],
    [2933, '+proj=utm +zone=50 +south +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +units=m +no_defs '],
    [2934, '+proj=merc +lon_0=110 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +pm=jakarta +units=m +no_defs '],
    [2935, '+proj=tmerc +lat_0=0.1166666666666667 +lon_0=41.53333333333333 +k=1 +x_0=1300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2936, '+proj=tmerc +lat_0=0.1166666666666667 +lon_0=44.53333333333333 +k=1 +x_0=2300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2937, '+proj=tmerc +lat_0=0.1166666666666667 +lon_0=47.53333333333333 +k=1 +x_0=3300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2938, '+proj=tmerc +lat_0=0.1166666666666667 +lon_0=50.53333333333333 +k=1 +x_0=4300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2939, '+proj=tmerc +lat_0=0.1333333333333333 +lon_0=50.76666666666667 +k=1 +x_0=2300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2940, '+proj=tmerc +lat_0=0.1333333333333333 +lon_0=53.76666666666667 +k=1 +x_0=3300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2941, '+proj=tmerc +lat_0=0.1333333333333333 +lon_0=56.76666666666667 +k=1 +x_0=4300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [2942, '+proj=utm +zone=28 +ellps=intl +towgs84=-499,-249,314,0,0,0,0 +units=m +no_defs '],
    [2943, '+proj=utm +zone=28 +ellps=intl +towgs84=-289,-124,60,0,0,0,0 +units=m +no_defs '],
    [2944, '+proj=tmerc +lat_0=0 +lon_0=-55.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2945, '+proj=tmerc +lat_0=0 +lon_0=-58.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2946, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2947, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2948, '+proj=tmerc +lat_0=0 +lon_0=-67.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2949, '+proj=tmerc +lat_0=0 +lon_0=-70.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2950, '+proj=tmerc +lat_0=0 +lon_0=-73.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2951, '+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2952, '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2953, '+proj=sterea +lat_0=46.5 +lon_0=-66.5 +k=0.999912 +x_0=2500000 +y_0=7500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2954, '+proj=sterea +lat_0=47.25 +lon_0=-63 +k=0.999912 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2955, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2956, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2957, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2958, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2959, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2960, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2961, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2962, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2964, '+proj=aea +lat_1=55 +lat_2=65 +lat_0=50 +lon_0=-154 +x_0=0 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [2965, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666666 +k=0.999966667 +x_0=99999.9998983997 +y_0=249999.999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [2966, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=900000 +y_0=249999.999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [2967, '+proj=tmerc +lat_0=37.5 +lon_0=-85.66666666666667 +k=0.999966667 +x_0=99999.99989839978 +y_0=249999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2968, '+proj=tmerc +lat_0=37.5 +lon_0=-87.08333333333333 +k=0.999966667 +x_0=900000 +y_0=249999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [2969, '+proj=utm +zone=20 +ellps=intl +towgs84=137,248,-430,0,0,0,0 +units=m +no_defs '],
    [2970, '+proj=utm +zone=20 +ellps=intl +towgs84=-467,-16,-300,0,0,0,0 +units=m +no_defs '],
    [2971, '+proj=utm +zone=22 +ellps=intl +towgs84=-186,230,110,0,0,0,0 +units=m +no_defs '],
    [2972, '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2973, '+proj=utm +zone=20 +ellps=intl +towgs84=186,482,151,0,0,0,0 +units=m +no_defs '],
    [2975, '+proj=utm +zone=40 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2976, '+proj=utm +zone=6 +south +ellps=intl +towgs84=162,117,154,0,0,0,0 +units=m +no_defs '],
    [2977, '+proj=utm +zone=5 +south +ellps=intl +towgs84=72.438,345.918,79.486,1.6045,0.8823,0.5565,1.3746 +units=m +no_defs '],
    [2978, '+proj=utm +zone=7 +south +ellps=intl +towgs84=84,274,65,0,0,0,0 +units=m +no_defs '],
    [2979, '+proj=utm +zone=42 +south +ellps=intl +towgs84=145,-187,103,0,0,0,0 +units=m +no_defs '],
    [2980, '+proj=utm +zone=38 +south +ellps=intl +towgs84=-382,-59,-262,0,0,0,0 +units=m +no_defs '],
    [2981, '+proj=utm +zone=58 +south +ellps=intl +towgs84=335.47,222.58,-230.94,0,0,0,0 +units=m +no_defs '],
    [2982, '+proj=utm +zone=58 +south +ellps=intl +towgs84=-13,-348,292,0,0,0,0 +units=m +no_defs '],
    [2983, '+proj=utm +zone=58 +south +ellps=intl +towgs84=-122.383,-188.696,103.344,3.5107,-4.9668,-5.7047,4.4798 +units=m +no_defs '],
    [2984, '+proj=lcc +lat_1=-20.66666666666667 +lat_2=-22.33333333333333 +lat_0=-21.5 +lon_0=166 +x_0=400000 +y_0=300000 +ellps=intl +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2987, '+proj=utm +zone=21 +ellps=clrk66 +towgs84=11.363,424.148,373.13,0,0,0,0 +units=m +no_defs '],
    [2988, '+proj=utm +zone=1 +south +ellps=intl +towgs84=253,-132,-127,0,0,0,0 +units=m +no_defs '],
    [2989, '+proj=utm +zone=20 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2990, '+proj=tmerc +lat_0=-21.11666666666667 +lon_0=55.53333333333333 +k=1 +x_0=50000 +y_0=160000 +ellps=intl +towgs84=94,-948,-1262,0,0,0,0 +units=m +no_defs '],
    [2991, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=400000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [2992, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=399999.9999984 +y_0=0 +datum=NAD83 +units=ft +no_defs'],
    [2993, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [2994, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=399999.9999984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [2995, '+proj=utm +zone=58 +south +ellps=intl +towgs84=287.58,177.78,-135.41,0,0,0,0 +units=m +no_defs '],
    [2996, '+proj=utm +zone=58 +south +ellps=intl +towgs84=-13,-348,292,0,0,0,0 +units=m +no_defs '],
    [2997, '+proj=utm +zone=58 +south +ellps=intl +towgs84=-480.26,-438.32,-643.429,16.3119,20.1721,-4.0349,-111.7002 +units=m +no_defs '],
    [2998, '+proj=utm +zone=58 +south +ellps=intl +towgs84=-10.18,-350.43,291.37,0,0,0,0 +units=m +no_defs '],
    [2999, '+proj=utm +zone=38 +south +ellps=intl +towgs84=-963,510,-359,0,0,0,0 +units=m +no_defs '],
    [3000, '+proj=merc +lon_0=110 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +units=m +no_defs '],
    [3001, '+proj=merc +lon_0=110 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +units=m +no_defs '],
    [3002, '+proj=merc +lon_0=110 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-587.8,519.75,145.76,0,0,0,0 +units=m +no_defs '],
    [3003, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +units=m +no_defs '],
    [3004, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +units=m +no_defs '],
    [3005, '+proj=aea +lat_1=50 +lat_2=58.5 +lat_0=45 +lon_0=-126 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3006, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3007, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3008, '+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3009, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3010, '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3011, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3012, '+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3013, '+proj=tmerc +lat_0=0 +lon_0=15.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3014, '+proj=tmerc +lat_0=0 +lon_0=17.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3015, '+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3016, '+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3017, '+proj=tmerc +lat_0=0 +lon_0=21.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3018, '+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3019, '+proj=tmerc +lat_0=0 +lon_0=11.30827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [3020, '+proj=tmerc +lat_0=0 +lon_0=13.55827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [3021, '+proj=tmerc +lat_0=0 +lon_0=15.80827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [3022, '+proj=tmerc +lat_0=0 +lon_0=18.05827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [3023, '+proj=tmerc +lat_0=0 +lon_0=20.30827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [3024, '+proj=tmerc +lat_0=0 +lon_0=22.55827777777778 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +units=m +no_defs '],
    [3025, '+proj=tmerc +lat_0=0 +lon_0=11.3082777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3026, '+proj=tmerc +lat_0=0 +lon_0=13.5582777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3027, '+proj=tmerc +lat_0=0 +lon_0=15.8082777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3028, '+proj=tmerc +lat_0=0 +lon_0=18.0582777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3029, '+proj=tmerc +lat_0=0 +lon_0=20.3082777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3030, '+proj=tmerc +lat_0=0 +lon_0=22.5582777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3031, '+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3032, '+proj=stere +lat_0=-90 +lat_ts=-71 +lon_0=70 +k=1 +x_0=6000000 +y_0=6000000 +datum=WGS84 +units=m +no_defs'],
    [3033, '+proj=lcc +lat_1=-68.5 +lat_2=-74.5 +lat_0=-50 +lon_0=70 +x_0=6000000 +y_0=6000000 +datum=WGS84 +units=m +no_defs'],
    [3034, '+proj=lcc +lat_1=35 +lat_2=65 +lat_0=52 +lon_0=10 +x_0=4000000 +y_0=2800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3035, '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3036, '+proj=utm +zone=36 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3037, '+proj=utm +zone=37 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3038, '+proj=utm +zone=26 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3039, '+proj=utm +zone=27 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3040, '+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3041, '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3042, '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3043, '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3044, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3045, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3046, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3047, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3048, '+proj=utm +zone=36 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3049, '+proj=utm +zone=37 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3050, '+proj=utm +zone=38 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3051, '+proj=utm +zone=39 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3054, '+proj=utm +zone=26 +ellps=intl +towgs84=-73,47,-83,0,0,0,0 +units=m +no_defs '],
    [3055, '+proj=utm +zone=27 +ellps=intl +towgs84=-73,47,-83,0,0,0,0 +units=m +no_defs '],
    [3056, '+proj=utm +zone=28 +ellps=intl +towgs84=-73,47,-83,0,0,0,0 +units=m +no_defs '],
    [3057, '+proj=lcc +lat_1=64.25 +lat_2=65.75 +lat_0=65 +lon_0=-19 +x_0=500000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3058, '+proj=tmerc +lat_0=0 +lon_0=-8.5 +k=1 +x_0=50000 +y_0=-7800000 +ellps=intl +towgs84=982.6087,552.753,-540.873,6.6816266,-31.6114924,-19.84816,16.805 +units=m +no_defs '],
    [3059, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3060, '+proj=utm +zone=58 +south +ellps=intl +towgs84=-11.64,-348.6,291.98,0,0,0,0 +units=m +no_defs '],
    [3061, '+proj=utm +zone=28 +ellps=intl +towgs84=-502.862,-247.438,312.724,0,0,0,0 +units=m +no_defs '],
    [3062, '+proj=utm +zone=26 +ellps=intl +towgs84=-204.619,140.176,55.226,0,0,0,0 +units=m +no_defs '],
    [3063, '+proj=utm +zone=26 +ellps=intl +towgs84=-106.226,166.366,-37.893,0,0,0,0 +units=m +no_defs '],
    [3064, '+proj=utm +zone=32 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3065, '+proj=utm +zone=33 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3066, '+proj=tmerc +lat_0=0 +lon_0=37 +k=0.9998 +x_0=500000 +y_0=-3000000 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [3067, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3068, '+proj=cass +lat_0=52.4186482777777 +lon_0=13.6272036666666 +x_0=40000 +y_0=10000 +datum=potsdam +units=m +no_defs'],
    [3069, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=500000 +y_0=-4500000 +datum=NAD27 +units=m +no_defs'],
    [3070, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=520000 +y_0=-4480000 +datum=NAD83 +units=m +no_defs'],
    [3071, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=520000 +y_0=-4480000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3072, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3073, '+proj=tmerc +lat_0=43 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3074, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3075, '+proj=tmerc +lat_0=43.83333333333334 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3076, '+proj=tmerc +lat_0=43 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3077, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3078, '+proj=omerc +lat_0=45.3091666666666 +lonc=-86 +alpha=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +no_uoff +gamma=337.25556 +datum=NAD83 +units=m +no_defs'],
    [3079, '+proj=omerc +lat_0=45.30916666666666 +lonc=-86 +alpha=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +no_uoff +gamma=337.25556 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3080, '+proj=lcc +lat_1=27.4166666666666 +lat_2=34.9166666666666 +lat_0=31.1666666666666 +lon_0=-100 +x_0=914400 +y_0=914400 +datum=NAD27 +units=ft +no_defs'],
    [3081, '+proj=lcc +lat_1=27.4166666666666 +lat_2=34.9166666666666 +lat_0=31.1666666666666 +lon_0=-100 +x_0=1000000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [3082, '+proj=lcc +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=5000000 +datum=NAD83 +units=m +no_defs'],
    [3083, '+proj=aea +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=6000000 +datum=NAD83 +units=m +no_defs'],
    [3084, '+proj=lcc +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3085, '+proj=aea +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3086, '+proj=aea +lat_1=24 +lat_2=31.5 +lat_0=24 +lon_0=-84 +x_0=400000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3087, '+proj=aea +lat_1=24 +lat_2=31.5 +lat_0=24 +lon_0=-84 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3088, '+proj=lcc +lat_1=37.0833333333333 +lat_2=38.6666666666666 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=1500000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [3089, '+proj=lcc +lat_1=37.0833333333333 +lat_2=38.6666666666666 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=1500000 +y_0=999999.999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [3090, '+proj=lcc +lat_1=37.08333333333334 +lat_2=38.66666666666666 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3091, '+proj=lcc +lat_1=37.08333333333334 +lat_2=38.66666666666666 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=1500000 +y_0=999999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3092, '+proj=utm +zone=51 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [3093, '+proj=utm +zone=52 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [3094, '+proj=utm +zone=53 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [3095, '+proj=utm +zone=54 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [3096, '+proj=utm +zone=55 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [3097, '+proj=utm +zone=51 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3098, '+proj=utm +zone=52 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3099, '+proj=utm +zone=53 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3100, '+proj=utm +zone=54 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3101, '+proj=utm +zone=55 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3102, '+proj=lcc +lat_1=-14.26666666666667 +lat_0=-14.26666666666667 +lon_0=-170 +k_0=1 +x_0=152400.3048006096 +y_0=95169.31165862332 +ellps=clrk66 +towgs84=-115,118,426,0,0,0,0 +units=us-ft +no_defs '],
    [3103, '+proj=utm +zone=28 +ellps=clrk80 +units=m +no_defs'],
    [3104, '+proj=utm +zone=29 +ellps=clrk80 +units=m +no_defs'],
    [3105, '+proj=utm +zone=30 +ellps=clrk80 +units=m +no_defs'],
    [3106, '+proj=tmerc +lat_0=0 +lon_0=90 +k=0.9996 +x_0=500000 +y_0=0 +a=6377276.345 +b=6356075.41314024 +towgs84=283.7,735.9,261.1,0,0,0,0 +units=m +no_defs '],
    [3107, '+proj=lcc +lat_1=-28 +lat_2=-36 +lat_0=-32 +lon_0=135 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3108, '+proj=tmerc +lat_0=49.5 +lon_0=-2.416666666666667 +k=0.999997 +x_0=47000 +y_0=50000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3109, '+proj=tmerc +lat_0=49.225 +lon_0=-2.135 +k=0.9999999000000001 +x_0=40000 +y_0=70000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3110, '+proj=lcc +lat_1=-36 +lat_2=-38 +lat_0=-37 +lon_0=145 +x_0=2500000 +y_0=4500000 +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [3111, '+proj=lcc +lat_1=-36 +lat_2=-38 +lat_0=-37 +lon_0=145 +x_0=2500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3112, '+proj=lcc +lat_1=-18 +lat_2=-36 +lat_0=0 +lon_0=134 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3113, '+proj=tmerc +lat_0=-28 +lon_0=153 +k=0.99999 +x_0=50000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3114, '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-80.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3115, '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-77.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3116, '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-74.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3117, '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-71.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3118, '+proj=tmerc +lat_0=4.596200416666666 +lon_0=-68.07750791666666 +k=1 +x_0=1000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3119, '+proj=tmerc +lat_0=0 +lon_0=10.5 +k=0.999 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=-206.1,-174.7,-87.7,0,0,0,0 +units=m +no_defs '],
    [3120, '+proj=sterea +lat_0=50.625 +lon_0=21.08333333333333 +k=0.9998 +x_0=4637000 +y_0=5467000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3121, '+proj=tmerc +lat_0=0 +lon_0=117 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +units=m +no_defs '],
    [3122, '+proj=tmerc +lat_0=0 +lon_0=119 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +units=m +no_defs '],
    [3123, '+proj=tmerc +lat_0=0 +lon_0=121 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +units=m +no_defs '],
    [3124, '+proj=tmerc +lat_0=0 +lon_0=123 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +units=m +no_defs '],
    [3125, '+proj=tmerc +lat_0=0 +lon_0=125 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +units=m +no_defs '],
    [3126, '+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3127, '+proj=tmerc +lat_0=0 +lon_0=20 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3128, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3129, '+proj=tmerc +lat_0=0 +lon_0=22 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3130, '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3131, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3132, '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3133, '+proj=tmerc +lat_0=0 +lon_0=26 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3134, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3135, '+proj=tmerc +lat_0=0 +lon_0=28 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3136, '+proj=tmerc +lat_0=0 +lon_0=29 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3137, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3138, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3140, '+proj=cass +lat_0=-18 +lon_0=178 +x_0=109435.392 +y_0=141622.272 +a=6378306.3696 +b=6356571.996 +towgs84=98,390,-22,0,0,0,0 +units=link +no_defs '],
    [3141, '+proj=utm +zone=60 +south +ellps=intl +towgs84=265.025,384.929,-194.046,0,0,0,0 +units=m +no_defs '],
    [3142, '+proj=utm +zone=1 +south +ellps=intl +towgs84=265.025,384.929,-194.046,0,0,0,0 +units=m +no_defs '],
    [3143, '+proj=tmerc +lat_0=-17 +lon_0=178.75 +k=0.99985 +x_0=2000000 +y_0=4000000 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [3146, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3147, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3148, '+proj=utm +zone=48 +a=6377276.345 +b=6356075.41314024 +towgs84=198,881,317,0,0,0,0 +units=m +no_defs '],
    [3149, '+proj=utm +zone=49 +a=6377276.345 +b=6356075.41314024 +towgs84=198,881,317,0,0,0,0 +units=m +no_defs '],
    [3150, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [3151, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [3152, '+proj=tmerc +lat_0=0 +lon_0=18.05779 +k=0.99999425 +x_0=100178.1808 +y_0=-6500614.7836 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3153, '+proj=aea +lat_1=50 +lat_2=58.5 +lat_0=45 +lon_0=-126 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3154, '+proj=utm +zone=7 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3155, '+proj=utm +zone=8 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3156, '+proj=utm +zone=9 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3157, '+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3158, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3159, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3160, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3161, '+proj=lcc +lat_1=44.5 +lat_2=53.5 +lat_0=0 +lon_0=-85 +x_0=930000 +y_0=6430000 +datum=NAD83 +units=m +no_defs'],
    [3162, '+proj=lcc +lat_1=44.5 +lat_2=53.5 +lat_0=0 +lon_0=-85 +x_0=930000 +y_0=6430000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3163, '+proj=lcc +lat_1=-20.66666666666667 +lat_2=-22.33333333333333 +lat_0=-21.5 +lon_0=166 +x_0=400000 +y_0=300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3164, '+proj=utm +zone=58 +south +ellps=WGS84 +towgs84=-56.263,16.136,-22.856,0,0,0,0 +units=m +no_defs '],
    [3165, '+proj=lcc +lat_1=-22.24469175 +lat_2=-22.29469175 +lat_0=-22.26969175 +lon_0=166.44242575 +x_0=0.66 +y_0=1.02 +ellps=intl +towgs84=-10.18,-350.43,291.37,0,0,0,0 +units=m +no_defs '],
    [3166, '+proj=lcc +lat_1=-22.24472222222222 +lat_2=-22.29472222222222 +lat_0=-22.26972222222222 +lon_0=166.4425 +x_0=8.313000000000001 +y_0=-2.354 +ellps=intl +towgs84=-10.18,-350.43,291.37,0,0,0,0 +units=m +no_defs '],
    [3167, '+proj=omerc +lat_0=4 +lonc=102.25 +alpha=323.0257905 +k=0.99984 +x_0=40000 +y_0=0 +no_uoff +gamma=323.130102361111 +a=6377295.664 +b=6356094.6679152 +to_meter=20.116756 +no_defs'],
    [3168, '+proj=omerc +lat_0=4 +lonc=102.25 +alpha=323.0257905 +k=0.99984 +x_0=804670.24 +y_0=0 +no_uoff +gamma=323.130102361111 +a=6377295.664 +b=6356094.6679152 +units=m +no_defs'],
    [3169, '+proj=utm +zone=57 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3170, '+proj=utm +zone=58 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3171, '+proj=utm +zone=59 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3172, '+proj=utm +zone=59 +south +ellps=intl +towgs84=287.58,177.78,-135.41,0,0,0,0 +units=m +no_defs '],
    [3174, '+proj=aea +lat_1=42.122774 +lat_2=49.01518 +lat_0=45.568977 +lon_0=-84.455955 +x_0=1000000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [3175, '+proj=aea +lat_1=42.122774 +lat_2=49.01518 +lat_0=45.568977 +lon_0=-83.248627 +x_0=1000000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [3176, '+proj=tmerc +lat_0=0 +lon_0=106 +k=0.9996 +x_0=500000 +y_0=0 +a=6377276.345 +b=6356075.41314024 +towgs84=198,881,317,0,0,0,0 +units=m +no_defs '],
    [3177, '+proj=tmerc +lat_0=0 +lon_0=17 +k=0.9965000000000001 +x_0=1000000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3178, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3179, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3180, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3181, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3182, '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3183, '+proj=utm +zone=23 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3184, '+proj=utm +zone=24 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3185, '+proj=utm +zone=25 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3186, '+proj=utm +zone=26 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3187, '+proj=utm +zone=27 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3188, '+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3189, '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3190, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3191, '+proj=tmerc +lat_0=0 +lon_0=11 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3192, '+proj=tmerc +lat_0=0 +lon_0=13 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3193, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3194, '+proj=tmerc +lat_0=0 +lon_0=17 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3195, '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3196, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3197, '+proj=tmerc +lat_0=0 +lon_0=23 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3198, '+proj=tmerc +lat_0=0 +lon_0=25 +k=0.99995 +x_0=200000 +y_0=0 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3199, '+proj=utm +zone=32 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3200, '+proj=lcc +lat_1=32.5 +lat_0=32.5 +lon_0=45 +k_0=0.9987864078000001 +x_0=1500000 +y_0=1166200 +ellps=clrk80 +towgs84=-241.54,-163.64,396.06,0,0,0,0 +units=m +no_defs '],
    [3201, '+proj=utm +zone=33 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3202, '+proj=utm +zone=34 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3203, '+proj=utm +zone=35 +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +units=m +no_defs '],
    [3204, '+proj=lcc +lat_1=-60.6666666666666 +lat_2=-63.3333333333333 +lat_0=-90 +lon_0=-66 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3205, '+proj=lcc +lat_1=-60.6666666666666 +lat_2=-63.3333333333333 +lat_0=-90 +lon_0=-54 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3206, '+proj=lcc +lat_1=-60.6666666666666 +lat_2=-63.3333333333333 +lat_0=-90 +lon_0=-42 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3207, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=-174 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3208, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=-66 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3209, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=-54 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3210, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=42 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3211, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=54 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3212, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=66 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3213, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=78 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3214, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3215, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=102 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3216, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=114 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3217, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=126 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3218, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=138 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3219, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=150 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3220, '+proj=lcc +lat_1=-64.6666666666666 +lat_2=-67.3333333333333 +lat_0=-90 +lon_0=162 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3221, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=-102 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3222, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=-90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3223, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=-78 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3224, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=-66 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3225, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=-18 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3226, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=-6 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3227, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=6 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3228, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=18 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3229, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=30 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3230, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=42 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3231, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=54 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3232, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=66 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3233, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=78 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3234, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3235, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=102 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3236, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=114 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3237, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=126 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3238, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=138 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3239, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=150 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3240, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=162 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3241, '+proj=lcc +lat_1=-68.6666666666666 +lat_2=-71.3333333333333 +lat_0=-90 +lon_0=174 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3242, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-153 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3243, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-135 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3244, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-117 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3245, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-99 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3246, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-81 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3247, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-63 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3248, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-27 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3249, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=-9 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3250, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=9 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3251, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=27 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3252, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=45 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3253, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=63 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3254, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=81 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3255, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=99 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3256, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=117 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3257, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=135 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3258, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=153 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3259, '+proj=lcc +lat_1=-72.6666666666666 +lat_2=-75.3333333333333 +lat_0=-90 +lon_0=171 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3260, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-168 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3261, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-144 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3262, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-120 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3263, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-96 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3264, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-72 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3265, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-48 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3266, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=-24 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3267, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3268, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=24 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3269, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=48 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3270, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=72 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3271, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=96 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3272, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=120 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3273, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=144 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3274, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-90 +lon_0=168 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3275, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-165 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3276, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-135 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3277, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-105 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3278, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-75 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3279, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3280, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-15 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3281, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=15 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3282, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3283, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=75 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3284, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=105 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3285, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=135 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3286, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=165 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3287, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-150 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3288, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-90 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3289, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=-30 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3290, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=30 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3291, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=90 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3292, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=150 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3293, '+proj=stere +lat_0=-90 +lat_ts=-80.2386111111111 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3294, '+proj=lcc +lat_1=-76.6666666666666 +lat_2=-79.3333333333333 +lat_0=-78 +lon_0=162 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3295, '+proj=aeqd +lat_0=9.54670833333333 +lon_0=138.168744444444 +x_0=40000 +y_0=60000 +ellps=clrk66 +units=m +no_defs'],
    [3296, '+proj=utm +zone=5 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs '],
    [3297, '+proj=utm +zone=6 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs '],
    [3298, '+proj=utm +zone=7 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs '],
    [3299, '+proj=utm +zone=8 +south +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +units=m +no_defs '],
    [3300, '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0.055,-0.541,-0.185,0.0183,-0.0003,-0.007,-0.014 +units=m +no_defs '],
    [3301, '+proj=lcc +lat_1=59.33333333333334 +lat_2=58 +lat_0=57.51755393055556 +lon_0=24 +x_0=500000 +y_0=6375000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3302, '+proj=utm +zone=7 +south +ellps=intl +towgs84=410.721,55.049,80.746,2.5779,2.3514,0.6664,17.3311 +units=m +no_defs '],
    [3303, '+proj=utm +zone=7 +south +ellps=intl +towgs84=347.103,1078.125,2623.922,-33.8875,70.6773,-9.3943,186.074 +units=m +no_defs '],
    [3304, '+proj=utm +zone=6 +south +ellps=intl +towgs84=221.525,152.948,176.768,-2.3847,-1.3896,-0.877,11.4741 +units=m +no_defs '],
    [3305, '+proj=utm +zone=6 +south +ellps=intl +towgs84=215.525,149.593,176.229,-3.2624,-1.692,-1.1571,10.4773 +units=m +no_defs '],
    [3306, '+proj=utm +zone=5 +south +ellps=intl +towgs84=217.037,86.959,23.956,0,0,0,0 +units=m +no_defs '],
    [3307, '+proj=utm +zone=39 +ellps=WGS84 +towgs84=0,-0.15,0.68,0,0,0,0 +units=m +no_defs '],
    [3308, '+proj=lcc +lat_1=-30.75 +lat_2=-35.75 +lat_0=-33.25 +lon_0=147 +x_0=9300000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3309, '+proj=aea +lat_1=34 +lat_2=40.5 +lat_0=0 +lon_0=-120 +x_0=0 +y_0=-4000000 +datum=NAD27 +units=m +no_defs'],
    [3310, '+proj=aea +lat_1=34 +lat_2=40.5 +lat_0=0 +lon_0=-120 +x_0=0 +y_0=-4000000 +datum=NAD83 +units=m +no_defs'],
    [3311, '+proj=aea +lat_1=34 +lat_2=40.5 +lat_0=0 +lon_0=-120 +x_0=0 +y_0=-4000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3312, '+proj=utm +zone=21 +ellps=intl +towgs84=-186,230,110,0,0,0,0 +units=m +no_defs '],
    [3313, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3314, '+proj=lcc +lat_1=-6.5 +lat_2=-11.5 +lat_0=0 +lon_0=26 +x_0=0 +y_0=0 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3315, '+proj=tmerc +lat_0=-9 +lon_0=26 +k=0.9998 +x_0=0 +y_0=0 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3316, '+proj=tmerc +lat_0=0 +lon_0=22 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3317, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3318, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3319, '+proj=tmerc +lat_0=0 +lon_0=14 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3320, '+proj=tmerc +lat_0=0 +lon_0=16 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3321, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3322, '+proj=tmerc +lat_0=0 +lon_0=20 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3323, '+proj=tmerc +lat_0=0 +lon_0=22 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3324, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3325, '+proj=tmerc +lat_0=0 +lon_0=26 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3326, '+proj=tmerc +lat_0=0 +lon_0=28 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3327, '+proj=tmerc +lat_0=0 +lon_0=30 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +units=m +no_defs'],
    [3328, '+proj=sterea +lat_0=52.16666666666666 +lon_0=19.16666666666667 +k=0.999714 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3329, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3330, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3331, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3332, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3333, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3334, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3335, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3336, '+proj=utm +zone=42 +south +ellps=intl +towgs84=145,-187,103,0,0,0,0 +units=m +no_defs '],
    [3337, '+proj=lcc +lat_1=-20.19506944444445 +lat_0=-20.19506944444445 +lon_0=57.52182777777778 +k_0=1 +x_0=1000000 +y_0=1000000 +ellps=clrk80 +towgs84=-770.1,158.4,-498.2,0,0,0,0 +units=m +no_defs '],
    [3338, '+proj=aea +lat_1=55 +lat_2=65 +lat_0=50 +lon_0=-154 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3339, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +towgs84=-79.9,-158,-168.9,0,0,0,0 +units=m +no_defs '],
    [3340, '+proj=tmerc +lat_0=0 +lon_0=14 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +towgs84=-79.9,-158,-168.9,0,0,0,0 +units=m +no_defs '],
    [3341, '+proj=tmerc +lat_0=0 +lon_0=16 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=clrk80 +towgs84=-79.9,-158,-168.9,0,0,0,0 +units=m +no_defs '],
    [3342, '+proj=utm +zone=33 +south +ellps=clrk80 +towgs84=-79.9,-158,-168.9,0,0,0,0 +units=m +no_defs '],
    [3343, '+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3344, '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3345, '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3346, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9998 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3347, '+proj=lcc +lat_1=49 +lat_2=77 +lat_0=63.390675 +lon_0=-91.8666666666666 +x_0=6200000 +y_0=3000000 +datum=NAD83 +units=m +no_defs'],
    [3348, '+proj=lcc +lat_1=49 +lat_2=77 +lat_0=63.390675 +lon_0=-91.86666666666666 +x_0=6200000 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3349, '+proj=merc +lon_0=-150 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3350, '+proj=tmerc +lat_0=0.1 +lon_0=21.95 +k=1 +x_0=250000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3351, '+proj=tmerc +lat_0=0.1 +lon_0=24.95 +k=1 +x_0=1250000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3352, '+proj=tmerc +lat_0=0.1 +lon_0=27.95 +k=1 +x_0=2250000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3353, '+proj=utm +zone=32 +south +ellps=intl +units=m +no_defs'],
    [3354, '+proj=utm +zone=32 +south +ellps=intl +units=m +no_defs'],
    [3355, '+proj=tmerc +lat_0=30 +lon_0=31 +k=1 +x_0=615000 +y_0=810000 +ellps=helmert +towgs84=-146.21,112.63,4.05,0,0,0,0 +units=m +no_defs '],
    [3356, '+proj=utm +zone=17 +ellps=clrk66 +towgs84=-179.483,-69.379,-27.584,-7.862,8.163,6.042,-13.925 +units=m +no_defs '],
    [3357, '+proj=utm +zone=17 +ellps=clrk66 +towgs84=8.853,-52.644,180.304,-0.393,-2.323,2.96,-24.081 +units=m +no_defs '],
    [3358, '+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.22 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3359, '+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.2192024385 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3360, '+proj=lcc +lat_1=34.83333333333334 +lat_2=32.5 +lat_0=31.83333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3361, '+proj=lcc +lat_1=34.83333333333334 +lat_2=32.5 +lat_0=31.83333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3362, '+proj=lcc +lat_1=41.95 +lat_2=40.88333333333333 +lat_0=40.16666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3363, '+proj=lcc +lat_1=41.95 +lat_2=40.88333333333333 +lat_0=40.16666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3364, '+proj=lcc +lat_1=40.96666666666667 +lat_2=39.93333333333333 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3365, '+proj=lcc +lat_1=40.96666666666667 +lat_2=39.93333333333333 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3366, '+proj=cass +lat_0=22.3121333333333 +lon_0=114.178555555555 +x_0=40243.5777560423 +y_0=19069.9335151257 +a=6378293.64520875 +b=6356617.98767983 +units=m +no_defs'],
    [3367, '+proj=utm +zone=28 +ellps=clrk80 +units=m +no_defs'],
    [3368, '+proj=utm +zone=29 +ellps=clrk80 +units=m +no_defs'],
    [3369, '+proj=utm +zone=30 +ellps=clrk80 +units=m +no_defs'],
    [3370, '+proj=utm +zone=59 +datum=NAD27 +units=m +no_defs'],
    [3371, '+proj=utm +zone=60 +datum=NAD27 +units=m +no_defs'],
    [3372, '+proj=utm +zone=59 +datum=NAD83 +units=m +no_defs'],
    [3373, '+proj=utm +zone=60 +datum=NAD83 +units=m +no_defs'],
    [3374, '+proj=utm +zone=29 +ellps=intl +units=m +no_defs'],
    [3375, '+proj=omerc +lat_0=4 +lonc=102.25 +alpha=323.025796466666 +k=0.99984 +x_0=804671 +y_0=0 +no_uoff +gamma=323.130102361111 +ellps=GRS80 +units=m +no_defs'],
    [3376, '+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31580995 +k=0.99984 +x_0=0 +y_0=0 +no_uoff +gamma=53.1301023611111 +ellps=GRS80 +units=m +no_defs'],
    [3377, '+proj=cass +lat_0=2.12167974444444 +lon_0=103.427936236111 +x_0=-14810.562 +y_0=8758.32 +ellps=GRS80 +units=m +no_defs'],
    [3378, '+proj=cass +lat_0=2.68234763611111 +lon_0=101.974905041666 +x_0=3673.785 +y_0=-4240.573 +ellps=GRS80 +units=m +no_defs'],
    [3379, '+proj=cass +lat_0=3.76938808888888 +lon_0=102.368298983333 +x_0=-7368.228 +y_0=6485.858 +ellps=GRS80 +units=m +no_defs'],
    [3380, '+proj=cass +lat_0=3.68464905 +lon_0=101.389107913888 +x_0=-34836.161 +y_0=56464.049 +ellps=GRS80 +units=m +no_defs'],
    [3381, '+proj=cass +lat_0=4.9762852 +lon_0=103.070275625 +x_0=19594.245 +y_0=3371.895 +ellps=GRS80 +units=m +no_defs'],
    [3382, '+proj=cass +lat_0=5.42151754166666 +lon_0=100.344376963888 +x_0=-23.414 +y_0=62.283 +ellps=GRS80 +units=m +no_defs'],
    [3383, '+proj=cass +lat_0=5.96467271388888 +lon_0=100.636371111111 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [3384, '+proj=cass +lat_0=4.85906302222222 +lon_0=100.815410586111 +x_0=-1.769 +y_0=133454.779 +ellps=GRS80 +units=m +no_defs'],
    [3385, '+proj=cass +lat_0=5.97254365833333 +lon_0=102.295241669444 +x_0=13227.851 +y_0=8739.894 +ellps=GRS80 +units=m +no_defs'],
    [3386, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +no_defs '],
    [3387, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=5500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +no_defs '],
    [3388, '+proj=merc +lon_0=51 +lat_ts=42 +x_0=0 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3389, '+proj=tmerc +lat_0=0 +lon_0=180 +k=1 +x_0=60500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [3390, '+proj=tmerc +lat_0=0 +lon_0=180 +k=1 +x_0=60500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [3391, '+proj=utm +zone=37 +ellps=clrk80 +towgs84=70.995,-335.916,262.898,0,0,0,0 +units=m +no_defs '],
    [3392, '+proj=utm +zone=38 +ellps=clrk80 +towgs84=70.995,-335.916,262.898,0,0,0,0 +units=m +no_defs '],
    [3393, '+proj=utm +zone=39 +ellps=clrk80 +towgs84=70.995,-335.916,262.898,0,0,0,0 +units=m +no_defs '],
    [3394, '+proj=lcc +lat_1=32.5 +lat_0=32.5 +lon_0=45 +k_0=0.9987864078000001 +x_0=1500000 +y_0=1166200 +ellps=clrk80 +towgs84=-242.2,-144.9,370.3,0,0,0,0 +units=m +no_defs '],
    [3395, '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3396, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3397, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3398, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3399, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [3400, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3401, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3402, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3403, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3404, '+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.2192024384 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3405, '+proj=utm +zone=48 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs '],
    [3406, '+proj=utm +zone=49 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs '],
    [3407, '+proj=cass +lat_0=22.3121333333333 +lon_0=114.178555555555 +x_0=40243.5777560423 +y_0=19069.9335151257 +a=6378293.64520875 +b=6356617.98767983 +to_meter=0.3047972654 +no_defs'],
    [3408, '+proj=laea +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +a=6371228 +b=6371228 +units=m +no_defs'],
    [3409, '+proj=laea +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +a=6371228 +b=6371228 +units=m +no_defs'],
    [3410, '+proj=cea +lon_0=0 +lat_ts=30 +x_0=0 +y_0=0 +a=6371228 +b=6371228 +units=m +no_defs'],
    [3411, '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +a=6378273 +b=6356889.449 +units=m +no_defs'],
    [3412, '+proj=stere +lat_0=-90 +lat_ts=-70 +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=6378273 +b=6356889.449 +units=m +no_defs'],
    [3413, '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=-45 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3414, '+proj=tmerc +lat_0=1.36666666666666 +lon_0=103.833333333333 +k=1 +x_0=28001.642 +y_0=38744.572 +ellps=WGS84 +units=m +no_defs'],
    [3415, '+proj=lcc +lat_1=18 +lat_2=24 +lat_0=21 +lon_0=114 +x_0=500000 +y_0=500000 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [3416, '+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.33333333333333 +x_0=400000 +y_0=400000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3417, '+proj=lcc +lat_1=43.2666666666666 +lat_2=42.0666666666666 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=999999.99998984 +datum=NAD83 +units=us-ft +no_defs'],
    [3418, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.6166666666666 +lat_0=40 +lon_0=-93.5 +x_0=500000.00001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3419, '+proj=lcc +lat_1=39.7833333333333 +lat_2=38.7166666666666 +lat_0=38.3333333333333 +lon_0=-98 +x_0=399999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3420, '+proj=lcc +lat_1=38.5666666666666 +lat_2=37.2666666666666 +lat_0=36.6666666666666 +lon_0=-98.5 +x_0=399999.99998984 +y_0=399999.99998984 +datum=NAD83 +units=us-ft +no_defs'],
    [3421, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000.00001016 +y_0=8000000.00001016 +datum=NAD83 +units=us-ft +no_defs'],
    [3422, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666666 +k=0.9999 +x_0=500000.00001016 +y_0=6000000 +datum=NAD83 +units=us-ft +no_defs'],
    [3423, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000.000010159 +y_0=3999999.99998984 +datum=NAD83 +units=us-ft +no_defs'],
    [3424, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3425, '+proj=lcc +lat_1=43.26666666666667 +lat_2=42.06666666666667 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=999999.9999898402 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3426, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.61666666666667 +lat_0=40 +lon_0=-93.5 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3427, '+proj=lcc +lat_1=39.78333333333333 +lat_2=38.71666666666667 +lat_0=38.33333333333334 +lon_0=-98 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3428, '+proj=lcc +lat_1=38.56666666666667 +lat_2=37.26666666666667 +lat_0=36.66666666666666 +lon_0=-98.5 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3429, '+proj=tmerc +lat_0=34.75 +lon_0=-115.5833333333333 +k=0.9999 +x_0=200000.00001016 +y_0=8000000.000010163 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3430, '+proj=tmerc +lat_0=34.75 +lon_0=-116.6666666666667 +k=0.9999 +x_0=500000.00001016 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3431, '+proj=tmerc +lat_0=34.75 +lon_0=-118.5833333333333 +k=0.9999 +x_0=800000.0000101599 +y_0=3999999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3432, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3433, '+proj=lcc +lat_1=36.2333333333333 +lat_2=34.9333333333333 +lat_0=34.3333333333333 +lon_0=-92 +x_0=399999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3434, '+proj=lcc +lat_1=34.7666666666666 +lat_2=33.3 +lat_0=32.6666666666666 +lon_0=-92 +x_0=399999.99998984 +y_0=399999.99998984 +datum=NAD83 +units=us-ft +no_defs'],
    [3435, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-88.3333333333333 +k=0.999974999999999 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3436, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-90.1666666666666 +k=0.999941177 +x_0=699999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3437, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666666 +k=0.999966667 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3438, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=99999.9999898399 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3439, '+proj=utm +zone=39 +ellps=clrk80 +towgs84=-180.624,-225.516,173.919,-0.81,-1.898,8.336,16.71006 +units=m +no_defs '],
    [3440, '+proj=utm +zone=40 +ellps=clrk80 +towgs84=-180.624,-225.516,173.919,-0.81,-1.898,8.336,16.71006 +units=m +no_defs '],
    [3441, '+proj=lcc +lat_1=36.23333333333333 +lat_2=34.93333333333333 +lat_0=34.33333333333334 +lon_0=-92 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3442, '+proj=lcc +lat_1=34.76666666666667 +lat_2=33.3 +lat_0=32.66666666666666 +lon_0=-92 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3443, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-88.33333333333333 +k=0.9999749999999999 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3444, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-90.16666666666667 +k=0.999941177 +x_0=699999.9999898402 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3445, '+proj=tmerc +lat_0=42.5 +lon_0=-71.66666666666667 +k=0.999966667 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3446, '+proj=tmerc +lat_0=41.08333333333334 +lon_0=-71.5 +k=0.99999375 +x_0=99999.99998983997 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3447, '+proj=lcc +lat_1=49.83333333333334 +lat_2=51.16666666666666 +lat_0=50.797815 +lon_0=4.359215833333333 +x_0=150328 +y_0=166262 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3448, '+proj=lcc +lat_1=18 +lat_0=18 +lon_0=-77 +k_0=1 +x_0=750000 +y_0=650000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3449, '+proj=utm +zone=17 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3450, '+proj=utm +zone=18 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3451, '+proj=lcc +lat_1=32.6666666666666 +lat_2=31.1666666666666 +lat_0=30.5 +lon_0=-92.5 +x_0=999999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3452, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.3333333333333 +x_0=999999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3453, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.5 +lon_0=-91.3333333333333 +x_0=999999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3454, '+proj=lcc +lat_1=44.4 +lat_2=42.8333333333333 +lat_0=42.3333333333333 +lon_0=-100.333333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3455, '+proj=lcc +lat_1=44.4 +lat_2=42.8333333333333 +lat_0=42.3333333333333 +lon_0=-100.333333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3456, '+proj=lcc +lat_1=32.66666666666666 +lat_2=31.16666666666667 +lat_0=30.5 +lon_0=-92.5 +x_0=999999.9999898402 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3457, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.33333333333333 +x_0=999999.9999898402 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3458, '+proj=lcc +lat_1=45.68333333333333 +lat_2=44.41666666666666 +lat_0=43.83333333333334 +lon_0=-100 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3459, '+proj=lcc +lat_1=44.4 +lat_2=42.83333333333334 +lat_0=42.33333333333334 +lon_0=-100.3333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3460, '+proj=tmerc +lat_0=-17 +lon_0=178.75 +k=0.99985 +x_0=2000000 +y_0=4000000 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [3461, '+proj=utm +zone=28 +a=6378249.2 +b=6356515 +towgs84=-83,37,124,0,0,0,0 +units=m +no_defs '],
    [3462, '+proj=utm +zone=29 +a=6378249.2 +b=6356515 +towgs84=-83,37,124,0,0,0,0 +units=m +no_defs '],
    [3463, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3464, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3465, '+proj=tmerc +lat_0=30.5 +lon_0=-85.83333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3466, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3467, '+proj=aea +lat_1=55 +lat_2=65 +lat_0=50 +lon_0=-154 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3468, '+proj=omerc +lat_0=57 +lonc=-133.6666666666667 +alpha=323.1301023611111 +k=0.9999 +x_0=5000000 +y_0=-5000000 +no_uoff +gamma=323.1301023611111 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3469, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3470, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3471, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3472, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3473, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3474, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3475, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3476, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3477, '+proj=lcc +lat_1=53.83333333333334 +lat_2=51.83333333333334 +lat_0=51 +lon_0=-176 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3478, '+proj=tmerc +lat_0=31 +lon_0=-111.9166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3479, '+proj=tmerc +lat_0=31 +lon_0=-111.9166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3480, '+proj=tmerc +lat_0=31 +lon_0=-110.1666666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3481, '+proj=tmerc +lat_0=31 +lon_0=-110.1666666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3482, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3483, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3484, '+proj=lcc +lat_1=36.23333333333333 +lat_2=34.93333333333333 +lat_0=34.33333333333334 +lon_0=-92 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3485, '+proj=lcc +lat_1=36.23333333333333 +lat_2=34.93333333333333 +lat_0=34.33333333333334 +lon_0=-92 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3486, '+proj=lcc +lat_1=34.76666666666667 +lat_2=33.3 +lat_0=32.66666666666666 +lon_0=-92 +x_0=400000 +y_0=400000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3487, '+proj=lcc +lat_1=34.76666666666667 +lat_2=33.3 +lat_0=32.66666666666666 +lon_0=-92 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3488, '+proj=aea +lat_1=34 +lat_2=40.5 +lat_0=0 +lon_0=-120 +x_0=0 +y_0=-4000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3489, '+proj=lcc +lat_1=41.66666666666666 +lat_2=40 +lat_0=39.33333333333334 +lon_0=-122 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3490, '+proj=lcc +lat_1=41.66666666666666 +lat_2=40 +lat_0=39.33333333333334 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3491, '+proj=lcc +lat_1=39.83333333333334 +lat_2=38.33333333333334 +lat_0=37.66666666666666 +lon_0=-122 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3492, '+proj=lcc +lat_1=39.83333333333334 +lat_2=38.33333333333334 +lat_0=37.66666666666666 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3493, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.06666666666667 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3494, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.06666666666667 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3495, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.33333333333334 +lon_0=-119 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3496, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.33333333333334 +lon_0=-119 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3497, '+proj=lcc +lat_1=35.46666666666667 +lat_2=34.03333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3498, '+proj=lcc +lat_1=35.46666666666667 +lat_2=34.03333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3499, '+proj=lcc +lat_1=33.88333333333333 +lat_2=32.78333333333333 +lat_0=32.16666666666666 +lon_0=-116.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3500, '+proj=lcc +lat_1=33.88333333333333 +lat_2=32.78333333333333 +lat_0=32.16666666666666 +lon_0=-116.25 +x_0=2000000.0001016 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3501, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.83333333333334 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3502, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.83333333333334 +lon_0=-105.5 +x_0=914401.8288036576 +y_0=304800.6096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3503, '+proj=lcc +lat_1=40.78333333333333 +lat_2=39.71666666666667 +lat_0=39.33333333333334 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3504, '+proj=lcc +lat_1=40.78333333333333 +lat_2=39.71666666666667 +lat_0=39.33333333333334 +lon_0=-105.5 +x_0=914401.8288036576 +y_0=304800.6096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3505, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.23333333333333 +lat_0=36.66666666666666 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3506, '+proj=lcc +lat_1=38.43333333333333 +lat_2=37.23333333333333 +lat_0=36.66666666666666 +lon_0=-105.5 +x_0=914401.8288036576 +y_0=304800.6096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3507, '+proj=lcc +lat_1=41.86666666666667 +lat_2=41.2 +lat_0=40.83333333333334 +lon_0=-72.75 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3508, '+proj=lcc +lat_1=41.86666666666667 +lat_2=41.2 +lat_0=40.83333333333334 +lon_0=-72.75 +x_0=304800.6096012192 +y_0=152400.3048006096 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3509, '+proj=tmerc +lat_0=38 +lon_0=-75.41666666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3510, '+proj=tmerc +lat_0=38 +lon_0=-75.41666666666667 +k=0.999995 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3511, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3512, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3513, '+proj=aea +lat_1=24 +lat_2=31.5 +lat_0=24 +lon_0=-84 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3514, '+proj=lcc +lat_1=30.75 +lat_2=29.58333333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3515, '+proj=lcc +lat_1=30.75 +lat_2=29.58333333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3516, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3517, '+proj=tmerc +lat_0=24.33333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3518, '+proj=tmerc +lat_0=30 +lon_0=-82.16666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3519, '+proj=tmerc +lat_0=30 +lon_0=-82.16666666666667 +k=0.9999 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3520, '+proj=tmerc +lat_0=30 +lon_0=-84.16666666666667 +k=0.9999 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3521, '+proj=tmerc +lat_0=30 +lon_0=-84.16666666666667 +k=0.9999 +x_0=699999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3522, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-114 +k=0.9999473679999999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3523, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-114 +k=0.9999473679999999 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3524, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-112.1666666666667 +k=0.9999473679999999 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3525, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-112.1666666666667 +k=0.9999473679999999 +x_0=200000.0001016002 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3526, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3527, '+proj=tmerc +lat_0=41.66666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3528, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-88.33333333333333 +k=0.9999749999999999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3529, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-88.33333333333333 +k=0.9999749999999999 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3530, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-90.16666666666667 +k=0.999941177 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3531, '+proj=tmerc +lat_0=36.66666666666666 +lon_0=-90.16666666666667 +k=0.999941177 +x_0=699999.9999898402 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3532, '+proj=tmerc +lat_0=37.5 +lon_0=-85.66666666666667 +k=0.999966667 +x_0=100000 +y_0=250000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3533, '+proj=tmerc +lat_0=37.5 +lon_0=-85.66666666666667 +k=0.999966667 +x_0=99999.99989839978 +y_0=249999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3534, '+proj=tmerc +lat_0=37.5 +lon_0=-87.08333333333333 +k=0.999966667 +x_0=900000 +y_0=250000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3535, '+proj=tmerc +lat_0=37.5 +lon_0=-87.08333333333333 +k=0.999966667 +x_0=900000 +y_0=249999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3536, '+proj=lcc +lat_1=43.26666666666667 +lat_2=42.06666666666667 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3537, '+proj=lcc +lat_1=43.26666666666667 +lat_2=42.06666666666667 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=999999.9999898402 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3538, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.61666666666667 +lat_0=40 +lon_0=-93.5 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3539, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.61666666666667 +lat_0=40 +lon_0=-93.5 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3540, '+proj=lcc +lat_1=39.78333333333333 +lat_2=38.71666666666667 +lat_0=38.33333333333334 +lon_0=-98 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3541, '+proj=lcc +lat_1=39.78333333333333 +lat_2=38.71666666666667 +lat_0=38.33333333333334 +lon_0=-98 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3542, '+proj=lcc +lat_1=38.56666666666667 +lat_2=37.26666666666667 +lat_0=36.66666666666666 +lon_0=-98.5 +x_0=400000 +y_0=400000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3543, '+proj=lcc +lat_1=38.56666666666667 +lat_2=37.26666666666667 +lat_0=36.66666666666666 +lon_0=-98.5 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3544, '+proj=lcc +lat_1=37.96666666666667 +lat_2=38.96666666666667 +lat_0=37.5 +lon_0=-84.25 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3545, '+proj=lcc +lat_1=37.96666666666667 +lat_2=38.96666666666667 +lat_0=37.5 +lon_0=-84.25 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3546, '+proj=lcc +lat_1=37.08333333333334 +lat_2=38.66666666666666 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3547, '+proj=lcc +lat_1=37.08333333333334 +lat_2=38.66666666666666 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=1500000 +y_0=999999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3548, '+proj=lcc +lat_1=37.93333333333333 +lat_2=36.73333333333333 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=500000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3549, '+proj=lcc +lat_1=37.93333333333333 +lat_2=36.73333333333333 +lat_0=36.33333333333334 +lon_0=-85.75 +x_0=500000.0001016001 +y_0=500000.0001016001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3550, '+proj=lcc +lat_1=32.66666666666666 +lat_2=31.16666666666667 +lat_0=30.5 +lon_0=-92.5 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3551, '+proj=lcc +lat_1=32.66666666666666 +lat_2=31.16666666666667 +lat_0=30.5 +lon_0=-92.5 +x_0=999999.9999898402 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3552, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.33333333333333 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3553, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.33333333333333 +x_0=999999.9999898402 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3554, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3555, '+proj=tmerc +lat_0=43.83333333333334 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3556, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3557, '+proj=tmerc +lat_0=43.66666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3558, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.16666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3559, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.66666666666666 +lon_0=-77 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3560, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.7166666666666 +lat_0=40.3333333333333 +lon_0=-111.5 +x_0=500000.00001016 +y_0=999999.99998984 +datum=NAD83 +units=us-ft +no_defs'],
    [3561, '+proj=tmerc +lat_0=18.83333333333333 +lon_0=-155.5 +k=0.999966667 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=61,-285,-181,0,0,0,0 +units=us-ft +no_defs '],
    [3562, '+proj=tmerc +lat_0=20.33333333333333 +lon_0=-156.6666666666667 +k=0.999966667 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=61,-285,-181,0,0,0,0 +units=us-ft +no_defs '],
    [3563, '+proj=tmerc +lat_0=21.16666666666667 +lon_0=-158 +k=0.99999 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=61,-285,-181,0,0,0,0 +units=us-ft +no_defs '],
    [3564, '+proj=tmerc +lat_0=21.83333333333333 +lon_0=-159.5 +k=0.99999 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=61,-285,-181,0,0,0,0 +units=us-ft +no_defs '],
    [3565, '+proj=tmerc +lat_0=21.66666666666667 +lon_0=-160.1666666666667 +k=1 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=61,-285,-181,0,0,0,0 +units=us-ft +no_defs '],
    [3566, '+proj=lcc +lat_1=40.65 +lat_2=39.0166666666666 +lat_0=38.3333333333333 +lon_0=-111.5 +x_0=500000.00001016 +y_0=2000000.00001016 +datum=NAD83 +units=us-ft +no_defs'],
    [3567, '+proj=lcc +lat_1=38.35 +lat_2=37.2166666666666 +lat_0=36.6666666666666 +lon_0=-111.5 +x_0=500000.00001016 +y_0=3000000 +datum=NAD83 +units=us-ft +no_defs'],
    [3568, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.71666666666667 +lat_0=40.33333333333334 +lon_0=-111.5 +x_0=500000.00001016 +y_0=999999.9999898402 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3569, '+proj=lcc +lat_1=40.65 +lat_2=39.01666666666667 +lat_0=38.33333333333334 +lon_0=-111.5 +x_0=500000.00001016 +y_0=2000000.00001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3570, '+proj=lcc +lat_1=38.35 +lat_2=37.21666666666667 +lat_0=36.66666666666666 +lon_0=-111.5 +x_0=500000.00001016 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3571, '+proj=laea +lat_0=90 +lon_0=180 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3572, '+proj=laea +lat_0=90 +lon_0=-150 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3573, '+proj=laea +lat_0=90 +lon_0=-100 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3574, '+proj=laea +lat_0=90 +lon_0=-40 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3575, '+proj=laea +lat_0=90 +lon_0=10 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3576, '+proj=laea +lat_0=90 +lon_0=90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3577, '+proj=aea +lat_1=-18 +lat_2=-36 +lat_0=0 +lon_0=132 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3578, '+proj=aea +lat_1=61.6666666666666 +lat_2=68 +lat_0=59 +lon_0=-132.5 +x_0=500000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [3579, '+proj=aea +lat_1=61.66666666666666 +lat_2=68 +lat_0=59 +lon_0=-132.5 +x_0=500000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3580, '+proj=lcc +lat_1=62 +lat_2=70 +lat_0=0 +lon_0=-112 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3581, '+proj=lcc +lat_1=62 +lat_2=70 +lat_0=0 +lon_0=-112 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3582, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.66666666666666 +lon_0=-77 +x_0=399999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3583, '+proj=lcc +lat_1=41.48333333333333 +lat_2=41.28333333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3584, '+proj=lcc +lat_1=41.48333333333333 +lat_2=41.28333333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3585, '+proj=lcc +lat_1=42.68333333333333 +lat_2=41.71666666666667 +lat_0=41 +lon_0=-71.5 +x_0=200000 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3586, '+proj=lcc +lat_1=42.68333333333333 +lat_2=41.71666666666667 +lat_0=41 +lon_0=-71.5 +x_0=200000.0001016002 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3587, '+proj=lcc +lat_1=45.7 +lat_2=44.18333333333333 +lat_0=43.31666666666667 +lon_0=-84.36666666666666 +x_0=6000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3588, '+proj=lcc +lat_1=45.7 +lat_2=44.18333333333333 +lat_0=43.31666666666667 +lon_0=-84.36666666666666 +x_0=5999999.999976001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3589, '+proj=lcc +lat_1=47.08333333333334 +lat_2=45.48333333333333 +lat_0=44.78333333333333 +lon_0=-87 +x_0=8000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3590, '+proj=lcc +lat_1=47.08333333333334 +lat_2=45.48333333333333 +lat_0=44.78333333333333 +lon_0=-87 +x_0=7999999.999968001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3591, '+proj=omerc +lat_0=45.30916666666666 +lonc=-86 +alpha=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +no_uoff +gamma=337.25556 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3592, '+proj=lcc +lat_1=43.66666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.36666666666666 +x_0=4000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3593, '+proj=lcc +lat_1=43.66666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.36666666666666 +x_0=3999999.999984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3594, '+proj=lcc +lat_1=47.05 +lat_2=45.61666666666667 +lat_0=45 +lon_0=-94.25 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3595, '+proj=lcc +lat_1=48.63333333333333 +lat_2=47.03333333333333 +lat_0=46.5 +lon_0=-93.09999999999999 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3596, '+proj=lcc +lat_1=45.21666666666667 +lat_2=43.78333333333333 +lat_0=43 +lon_0=-94 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3597, '+proj=tmerc +lat_0=29.5 +lon_0=-88.83333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3598, '+proj=tmerc +lat_0=29.5 +lon_0=-88.83333333333333 +k=0.99995 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3599, '+proj=tmerc +lat_0=29.5 +lon_0=-90.33333333333333 +k=0.99995 +x_0=700000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3600, '+proj=tmerc +lat_0=29.5 +lon_0=-90.33333333333333 +k=0.99995 +x_0=699999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3601, '+proj=tmerc +lat_0=35.83333333333334 +lon_0=-92.5 +k=0.999933333 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3602, '+proj=tmerc +lat_0=35.83333333333334 +lon_0=-90.5 +k=0.999933333 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3603, '+proj=tmerc +lat_0=36.16666666666666 +lon_0=-94.5 +k=0.999941177 +x_0=850000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3604, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3605, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3606, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3607, '+proj=tmerc +lat_0=34.75 +lon_0=-116.6666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3608, '+proj=tmerc +lat_0=34.75 +lon_0=-116.6666666666667 +k=0.9999 +x_0=500000.00001016 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3609, '+proj=tmerc +lat_0=34.75 +lon_0=-115.5833333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3610, '+proj=tmerc +lat_0=34.75 +lon_0=-115.5833333333333 +k=0.9999 +x_0=200000.00001016 +y_0=8000000.000010163 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3611, '+proj=tmerc +lat_0=34.75 +lon_0=-118.5833333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3612, '+proj=tmerc +lat_0=34.75 +lon_0=-118.5833333333333 +k=0.9999 +x_0=800000.0000101599 +y_0=3999999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3613, '+proj=tmerc +lat_0=42.5 +lon_0=-71.66666666666667 +k=0.999966667 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3614, '+proj=tmerc +lat_0=42.5 +lon_0=-71.66666666666667 +k=0.999966667 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3615, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3616, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3617, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3618, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3619, '+proj=tmerc +lat_0=31 +lon_0=-104.3333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3620, '+proj=tmerc +lat_0=31 +lon_0=-104.3333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3621, '+proj=tmerc +lat_0=31 +lon_0=-107.8333333333333 +k=0.999916667 +x_0=830000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3622, '+proj=tmerc +lat_0=31 +lon_0=-107.8333333333333 +k=0.999916667 +x_0=830000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3623, '+proj=tmerc +lat_0=40 +lon_0=-76.58333333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3624, '+proj=tmerc +lat_0=40 +lon_0=-76.58333333333333 +k=0.9999375 +x_0=249999.9998983998 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3625, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3626, '+proj=tmerc +lat_0=38.83333333333334 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3627, '+proj=lcc +lat_1=41.03333333333333 +lat_2=40.66666666666666 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3628, '+proj=lcc +lat_1=41.03333333333333 +lat_2=40.66666666666666 +lat_0=40.16666666666666 +lon_0=-74 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3629, '+proj=tmerc +lat_0=40 +lon_0=-78.58333333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3630, '+proj=tmerc +lat_0=40 +lon_0=-78.58333333333333 +k=0.9999375 +x_0=350000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3631, '+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.22 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3632, '+proj=lcc +lat_1=36.16666666666666 +lat_2=34.33333333333334 +lat_0=33.75 +lon_0=-79 +x_0=609601.2192024384 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3633, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.43333333333333 +lat_0=47 +lon_0=-100.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3634, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.43333333333333 +lat_0=47 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3635, '+proj=lcc +lat_1=47.48333333333333 +lat_2=46.18333333333333 +lat_0=45.66666666666666 +lon_0=-100.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3636, '+proj=lcc +lat_1=47.48333333333333 +lat_2=46.18333333333333 +lat_0=45.66666666666666 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3637, '+proj=lcc +lat_1=41.7 +lat_2=40.43333333333333 +lat_0=39.66666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3638, '+proj=lcc +lat_1=40.03333333333333 +lat_2=38.73333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3639, '+proj=lcc +lat_1=36.76666666666667 +lat_2=35.56666666666667 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3640, '+proj=lcc +lat_1=36.76666666666667 +lat_2=35.56666666666667 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3641, '+proj=lcc +lat_1=35.23333333333333 +lat_2=33.93333333333333 +lat_0=33.33333333333334 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3642, '+proj=lcc +lat_1=35.23333333333333 +lat_2=33.93333333333333 +lat_0=33.33333333333334 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3643, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=400000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3644, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=399999.9999984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3645, '+proj=lcc +lat_1=46 +lat_2=44.33333333333334 +lat_0=43.66666666666666 +lon_0=-120.5 +x_0=2500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3646, '+proj=lcc +lat_1=46 +lat_2=44.33333333333334 +lat_0=43.66666666666666 +lon_0=-120.5 +x_0=2500000.0001424 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3647, '+proj=lcc +lat_1=44 +lat_2=42.33333333333334 +lat_0=41.66666666666666 +lon_0=-120.5 +x_0=1500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3648, '+proj=lcc +lat_1=44 +lat_2=42.33333333333334 +lat_0=41.66666666666666 +lon_0=-120.5 +x_0=1500000.0001464 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3649, '+proj=lcc +lat_1=41.95 +lat_2=40.88333333333333 +lat_0=40.16666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3650, '+proj=lcc +lat_1=41.95 +lat_2=40.88333333333333 +lat_0=40.16666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3651, '+proj=lcc +lat_1=40.96666666666667 +lat_2=39.93333333333333 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3652, '+proj=lcc +lat_1=40.96666666666667 +lat_2=39.93333333333333 +lat_0=39.33333333333334 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3653, '+proj=tmerc +lat_0=41.08333333333334 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3654, '+proj=tmerc +lat_0=41.08333333333334 +lon_0=-71.5 +k=0.99999375 +x_0=99999.99998983997 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3655, '+proj=lcc +lat_1=34.83333333333334 +lat_2=32.5 +lat_0=31.83333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3656, '+proj=lcc +lat_1=34.83333333333334 +lat_2=32.5 +lat_0=31.83333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3657, '+proj=lcc +lat_1=45.68333333333333 +lat_2=44.41666666666666 +lat_0=43.83333333333334 +lon_0=-100 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3658, '+proj=lcc +lat_1=45.68333333333333 +lat_2=44.41666666666666 +lat_0=43.83333333333334 +lon_0=-100 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3659, '+proj=lcc +lat_1=44.4 +lat_2=42.83333333333334 +lat_0=42.33333333333334 +lon_0=-100.3333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3660, '+proj=lcc +lat_1=44.4 +lat_2=42.83333333333334 +lat_0=42.33333333333334 +lon_0=-100.3333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3661, '+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3662, '+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3663, '+proj=lcc +lat_1=31.88333333333333 +lat_2=30.11666666666667 +lat_0=29.66666666666667 +lon_0=-100.3333333333333 +x_0=700000 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3664, '+proj=lcc +lat_1=31.88333333333333 +lat_2=30.11666666666667 +lat_0=29.66666666666667 +lon_0=-100.3333333333333 +x_0=699999.9998983998 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3665, '+proj=aea +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3666, '+proj=lcc +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3667, '+proj=lcc +lat_1=36.18333333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3668, '+proj=lcc +lat_1=36.18333333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000.0001016002 +y_0=999999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3669, '+proj=lcc +lat_1=33.96666666666667 +lat_2=32.13333333333333 +lat_0=31.66666666666667 +lon_0=-98.5 +x_0=600000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3670, '+proj=lcc +lat_1=33.96666666666667 +lat_2=32.13333333333333 +lat_0=31.66666666666667 +lon_0=-98.5 +x_0=600000 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3671, '+proj=lcc +lat_1=27.83333333333333 +lat_2=26.16666666666667 +lat_0=25.66666666666667 +lon_0=-98.5 +x_0=300000 +y_0=5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3672, '+proj=lcc +lat_1=27.83333333333333 +lat_2=26.16666666666667 +lat_0=25.66666666666667 +lon_0=-98.5 +x_0=300000.0000000001 +y_0=5000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3673, '+proj=lcc +lat_1=30.28333333333333 +lat_2=28.38333333333333 +lat_0=27.83333333333333 +lon_0=-99 +x_0=600000 +y_0=4000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3674, '+proj=lcc +lat_1=30.28333333333333 +lat_2=28.38333333333333 +lat_0=27.83333333333333 +lon_0=-99 +x_0=600000 +y_0=3999999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3675, '+proj=lcc +lat_1=40.65 +lat_2=39.01666666666667 +lat_0=38.33333333333334 +lon_0=-111.5 +x_0=500000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3676, '+proj=lcc +lat_1=40.65 +lat_2=39.01666666666667 +lat_0=38.33333333333334 +lon_0=-111.5 +x_0=500000.0001504 +y_0=1999999.999992 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3677, '+proj=lcc +lat_1=40.65 +lat_2=39.01666666666667 +lat_0=38.33333333333334 +lon_0=-111.5 +x_0=500000.00001016 +y_0=2000000.00001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3678, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.71666666666667 +lat_0=40.33333333333334 +lon_0=-111.5 +x_0=500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3679, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.71666666666667 +lat_0=40.33333333333334 +lon_0=-111.5 +x_0=500000.0001504 +y_0=999999.9999960001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3680, '+proj=lcc +lat_1=41.78333333333333 +lat_2=40.71666666666667 +lat_0=40.33333333333334 +lon_0=-111.5 +x_0=500000.00001016 +y_0=999999.9999898402 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3681, '+proj=lcc +lat_1=38.35 +lat_2=37.21666666666667 +lat_0=36.66666666666666 +lon_0=-111.5 +x_0=500000 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3682, '+proj=lcc +lat_1=38.35 +lat_2=37.21666666666667 +lat_0=36.66666666666666 +lon_0=-111.5 +x_0=500000.0001504 +y_0=2999999.999988 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [3683, '+proj=lcc +lat_1=38.35 +lat_2=37.21666666666667 +lat_0=36.66666666666666 +lon_0=-111.5 +x_0=500000.00001016 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3684, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3685, '+proj=lcc +lat_1=39.2 +lat_2=38.03333333333333 +lat_0=37.66666666666666 +lon_0=-78.5 +x_0=3500000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3686, '+proj=lcc +lat_1=39.2 +lat_2=38.03333333333333 +lat_0=37.66666666666666 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3687, '+proj=lcc +lat_1=37.96666666666667 +lat_2=36.76666666666667 +lat_0=36.33333333333334 +lon_0=-78.5 +x_0=3500000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3688, '+proj=lcc +lat_1=37.96666666666667 +lat_2=36.76666666666667 +lat_0=36.33333333333334 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=999999.9998983998 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3689, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.8333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3690, '+proj=lcc +lat_1=48.73333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.8333333333333 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3691, '+proj=lcc +lat_1=47.33333333333334 +lat_2=45.83333333333334 +lat_0=45.33333333333334 +lon_0=-120.5 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3692, '+proj=lcc +lat_1=47.33333333333334 +lat_2=45.83333333333334 +lat_0=45.33333333333334 +lon_0=-120.5 +x_0=500000.0001016001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3693, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3694, '+proj=lcc +lat_1=38.88333333333333 +lat_2=37.48333333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3695, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.83333333333334 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3696, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.83333333333334 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3697, '+proj=lcc +lat_1=46.76666666666667 +lat_2=45.56666666666667 +lat_0=45.16666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3698, '+proj=lcc +lat_1=46.76666666666667 +lat_2=45.56666666666667 +lat_0=45.16666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3699, '+proj=lcc +lat_1=44.06666666666667 +lat_2=42.73333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3700, '+proj=lcc +lat_1=44.06666666666667 +lat_2=42.73333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3701, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=520000 +y_0=-4480000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3702, '+proj=tmerc +lat_0=40.5 +lon_0=-105.1666666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3703, '+proj=tmerc +lat_0=40.5 +lon_0=-107.3333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3704, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3705, '+proj=tmerc +lat_0=40.5 +lon_0=-110.0833333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3706, '+proj=utm +zone=59 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3707, '+proj=utm +zone=60 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3708, '+proj=utm +zone=1 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3709, '+proj=utm +zone=2 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3710, '+proj=utm +zone=3 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3711, '+proj=utm +zone=4 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3712, '+proj=utm +zone=5 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3713, '+proj=utm +zone=6 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3714, '+proj=utm +zone=7 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3715, '+proj=utm +zone=8 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3716, '+proj=utm +zone=9 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3717, '+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3718, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3719, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3720, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3721, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3722, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3723, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3724, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3725, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3726, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3727, '+proj=tmerc +lat_0=-21.11666666666667 +lon_0=55.53333333333333 +k=1 +x_0=160000 +y_0=50000 +ellps=intl +towgs84=94,-948,-1262,0,0,0,0 +units=m +no_defs '],
    [3728, '+proj=lcc +lat_1=41.7 +lat_2=40.43333333333333 +lat_0=39.66666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3729, '+proj=lcc +lat_1=40.03333333333333 +lat_2=38.73333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3730, '+proj=tmerc +lat_0=40.5 +lon_0=-105.1666666666667 +k=0.9999375 +x_0=200000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3731, '+proj=tmerc +lat_0=40.5 +lon_0=-107.3333333333333 +k=0.9999375 +x_0=399999.99998984 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3732, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3733, '+proj=tmerc +lat_0=40.5 +lon_0=-110.0833333333333 +k=0.9999375 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3734, '+proj=lcc +lat_1=41.7 +lat_2=40.4333333333333 +lat_0=39.6666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3735, '+proj=lcc +lat_1=40.0333333333333 +lat_2=38.7333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3736, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666666 +k=0.9999375 +x_0=200000.00001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3737, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=399999.99998984 +y_0=99999.9999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [3738, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3739, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000.000010159 +y_0=99999.9999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [3740, '+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3741, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3742, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3743, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3744, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3745, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3746, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3747, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3748, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3749, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3750, '+proj=utm +zone=4 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3751, '+proj=utm +zone=5 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3752, '+proj=merc +lon_0=100 +lat_ts=-41 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3753, '+proj=lcc +lat_1=41.7 +lat_2=40.43333333333333 +lat_0=39.66666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3754, '+proj=lcc +lat_1=40.03333333333333 +lat_2=38.73333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3755, '+proj=tmerc +lat_0=40.5 +lon_0=-105.1666666666667 +k=0.9999375 +x_0=200000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3756, '+proj=tmerc +lat_0=40.5 +lon_0=-107.3333333333333 +k=0.9999375 +x_0=399999.99998984 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3757, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3758, '+proj=tmerc +lat_0=40.5 +lon_0=-110.0833333333333 +k=0.9999375 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3759, '+proj=tmerc +lat_0=21.1666666666666 +lon_0=-158 +k=0.99999 +x_0=500000.00001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [3760, '+proj=tmerc +lat_0=21.16666666666667 +lon_0=-158 +k=0.99999 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [3761, '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3762, '+proj=lcc +lat_1=-54 +lat_2=-54.75 +lat_0=-55 +lon_0=-37 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3763, '+proj=tmerc +lat_0=39.66825833333333 +lon_0=-8.133108333333334 +k=1 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3764, '+proj=tmerc +lat_0=-44 +lon_0=-176.5 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3765, '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3766, '+proj=lcc +lat_1=45.91666666666666 +lat_2=43.08333333333334 +lat_0=0 +lon_0=16.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3767, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3768, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3769, '+proj=utm +zone=20 +ellps=clrk66 +towgs84=-73,213,296,0,0,0,0 +units=m +no_defs '],
    [3770, '+proj=tmerc +lat_0=32 +lon_0=-64.75 +k=1 +x_0=550000 +y_0=100000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3771, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [3772, '+proj=tmerc +lat_0=0 +lon_0=-114 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [3773, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [3774, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [3775, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3776, '+proj=tmerc +lat_0=0 +lon_0=-114 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3777, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3778, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3779, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3780, '+proj=tmerc +lat_0=0 +lon_0=-114 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3781, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3782, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3783, '+proj=tmerc +lat_0=-25.06855261111111 +lon_0=-130.1129671111111 +k=1 +x_0=14200 +y_0=15500 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3784, '+proj=utm +zone=9 +south +ellps=intl +towgs84=185,165,42,0,0,0,0 +units=m +no_defs '],
    [3785, '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs'],
    [3786, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +a=6371007 +b=6371007 +units=m +no_defs'],
    [3787, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [3788, '+proj=tmerc +lat_0=0 +lon_0=166 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3789, '+proj=tmerc +lat_0=0 +lon_0=169 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3790, '+proj=tmerc +lat_0=0 +lon_0=179 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3791, '+proj=tmerc +lat_0=0 +lon_0=-178 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3793, '+proj=tmerc +lat_0=0 +lon_0=-176.5 +k=1 +x_0=3500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3794, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3795, '+proj=lcc +lat_1=23 +lat_2=21.7 +lat_0=22.35 +lon_0=-81 +x_0=500000 +y_0=280296.016 +datum=NAD27 +units=m +no_defs'],
    [3796, '+proj=lcc +lat_1=21.3 +lat_2=20.1333333333333 +lat_0=20.7166666666666 +lon_0=-76.8333333333333 +x_0=500000 +y_0=229126.939 +datum=NAD27 +units=m +no_defs'],
    [3797, '+proj=lcc +lat_1=50 +lat_2=46 +lat_0=44 +lon_0=-70 +x_0=800000 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [3798, '+proj=lcc +lat_1=50 +lat_2=46 +lat_0=44 +lon_0=-70 +x_0=800000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3799, '+proj=lcc +lat_1=50 +lat_2=46 +lat_0=44 +lon_0=-70 +x_0=800000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3800, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [3801, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3802, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3812, '+proj=lcc +lat_1=49.83333333333334 +lat_2=51.16666666666666 +lat_0=50.797815 +lon_0=4.359215833333333 +x_0=649328 +y_0=665262 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3814, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +datum=NAD83 +units=m +no_defs'],
    [3815, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3816, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3819, '+proj=longlat +ellps=bessel +towgs84=595.48,121.69,515.35,4.115,-2.9383,0.853,-3.408 +no_defs '],
    [3821, '+proj=longlat +ellps=aust_SA +no_defs'],
    [3822, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [3823, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [3824, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [3825, '+proj=tmerc +lat_0=0 +lon_0=119 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3826, '+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3827, '+proj=tmerc +lat_0=0 +lon_0=119 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [3828, '+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [3829, '+proj=utm +zone=51 +ellps=intl +towgs84=-637,-549,-203,0,0,0,0 +units=m +no_defs '],
    [3832, '+proj=merc +lon_0=150 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3833, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=2500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3834, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=2500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [3835, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [3836, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [3837, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3838, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3839, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3840, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=10500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [3841, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [3842, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [3843, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [3844, '+proj=sterea +lat_0=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=2.329,-147.042,-92.08,0.309,-0.325,-0.497,5.69 +units=m +no_defs '],
    [3845, '+proj=tmerc +lat_0=0 +lon_0=11.30625 +k=1.000006 +x_0=1500025.141 +y_0=-667.282 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3846, '+proj=tmerc +lat_0=0 +lon_0=13.55626666666667 +k=1.0000058 +x_0=1500044.695 +y_0=-667.13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3847, '+proj=tmerc +lat_0=0 +lon_0=15.80628452944445 +k=1.00000561024 +x_0=1500064.274 +y_0=-667.711 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3848, '+proj=tmerc +lat_0=0 +lon_0=18.0563 +k=1.0000054 +x_0=1500083.521 +y_0=-668.8440000000001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3849, '+proj=tmerc +lat_0=0 +lon_0=20.30631666666667 +k=1.0000052 +x_0=1500102.765 +y_0=-670.706 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3850, '+proj=tmerc +lat_0=0 +lon_0=22.55633333333333 +k=1.0000049 +x_0=1500121.846 +y_0=-672.557 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3851, '+proj=lcc +lat_1=-37.5 +lat_2=-44.5 +lat_0=-41 +lon_0=173 +x_0=3000000 +y_0=7000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3852, '+proj=lcc +lat_1=-76.66666666666667 +lat_2=-79.33333333333333 +lat_0=-90 +lon_0=157 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3854, '+proj=tmerc +lat_0=0 +lon_0=18.05787 +k=0.99999506 +x_0=100182.7406 +y_0=-6500620.1207 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3857, '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs'],
    [3873, '+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=19500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3874, '+proj=tmerc +lat_0=0 +lon_0=20 +k=1 +x_0=20500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3875, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=21500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3876, '+proj=tmerc +lat_0=0 +lon_0=22 +k=1 +x_0=22500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3877, '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=23500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3878, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=24500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3879, '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3880, '+proj=tmerc +lat_0=0 +lon_0=26 +k=1 +x_0=26500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3881, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=27500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3882, '+proj=tmerc +lat_0=0 +lon_0=28 +k=1 +x_0=28500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3883, '+proj=tmerc +lat_0=0 +lon_0=29 +k=1 +x_0=29500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3884, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=30500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3885, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=31500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3887, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [3888, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [3889, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [3890, '+proj=utm +zone=37 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3891, '+proj=utm +zone=38 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3892, '+proj=utm +zone=39 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3893, '+proj=tmerc +lat_0=29.02626833333333 +lon_0=46.5 +k=0.9994 +x_0=800000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [3901, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=3500000 +y_0=0 +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +units=m +vunits=m +no_defs '],
    [3902, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [3903, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [3906, '+proj=longlat +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +no_defs '],
    [3907, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=5500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [3908, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [3909, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=7500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [3910, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=8500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [3911, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [3912, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [3920, '+proj=utm +zone=20 +ellps=clrk66 +towgs84=11,72,-101,0,0,0,0 +units=m +no_defs '],
    [3942, '+proj=lcc +lat_1=41.25 +lat_2=42.75 +lat_0=42 +lon_0=3 +x_0=1700000 +y_0=1200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3943, '+proj=lcc +lat_1=42.25 +lat_2=43.75 +lat_0=43 +lon_0=3 +x_0=1700000 +y_0=2200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3944, '+proj=lcc +lat_1=43.25 +lat_2=44.75 +lat_0=44 +lon_0=3 +x_0=1700000 +y_0=3200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3945, '+proj=lcc +lat_1=44.25 +lat_2=45.75 +lat_0=45 +lon_0=3 +x_0=1700000 +y_0=4200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3946, '+proj=lcc +lat_1=45.25 +lat_2=46.75 +lat_0=46 +lon_0=3 +x_0=1700000 +y_0=5200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3947, '+proj=lcc +lat_1=46.25 +lat_2=47.75 +lat_0=47 +lon_0=3 +x_0=1700000 +y_0=6200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3948, '+proj=lcc +lat_1=47.25 +lat_2=48.75 +lat_0=48 +lon_0=3 +x_0=1700000 +y_0=7200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3949, '+proj=lcc +lat_1=48.25 +lat_2=49.75 +lat_0=49 +lon_0=3 +x_0=1700000 +y_0=8200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3950, '+proj=lcc +lat_1=49.25 +lat_2=50.75 +lat_0=50 +lon_0=3 +x_0=1700000 +y_0=9200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3968, '+proj=lcc +lat_1=37 +lat_2=39.5 +lat_0=36 +lon_0=-79.5 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3969, '+proj=lcc +lat_1=37 +lat_2=39.5 +lat_0=36 +lon_0=-79.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3970, '+proj=lcc +lat_1=37 +lat_2=39.5 +lat_0=36 +lon_0=-79.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3973, '+proj=laea +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3974, '+proj=laea +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3975, '+proj=cea +lon_0=0 +lat_ts=30 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3976, '+proj=stere +lat_0=-90 +lat_ts=-70 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3978, '+proj=lcc +lat_1=49 +lat_2=77 +lat_0=49 +lon_0=-95 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [3979, '+proj=lcc +lat_1=49 +lat_2=77 +lat_0=49 +lon_0=-95 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [3985, '+proj=lcc +lat_1=-6.5 +lat_2=-11.5 +lat_0=9 +lon_0=26 +x_0=500000 +y_0=500000 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3986, '+proj=tmerc +lat_0=-9 +lon_0=30 +k=1 +x_0=200000 +y_0=500000 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3987, '+proj=tmerc +lat_0=-9 +lon_0=28 +k=1 +x_0=200000 +y_0=500000 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3988, '+proj=tmerc +lat_0=-9 +lon_0=26 +k=1 +x_0=200000 +y_0=500000 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3989, '+proj=tmerc +lat_0=-9 +lon_0=24 +k=1 +x_0=200000 +y_0=500000 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [3991, '+proj=lcc +lat_1=18.43333333333333 +lat_2=18.03333333333333 +lat_0=17.83333333333333 +lon_0=-66.43333333333334 +x_0=152400.3048006096 +y_0=0 +ellps=clrk66 +towgs84=11,72,-101,0,0,0,0 +units=us-ft +no_defs '],
    [3992, '+proj=lcc +lat_1=18.43333333333333 +lat_2=18.03333333333333 +lat_0=17.83333333333333 +lon_0=-66.43333333333334 +x_0=152400.3048006096 +y_0=30480.06096012192 +ellps=clrk66 +towgs84=11,72,-101,0,0,0,0 +units=us-ft +no_defs '],
    [3993, '+proj=aeqd +guam +lat_0=13.4724663527778 +lon_0=144.748750705556 +x_0=50000 +y_0=50000 +ellps=clrk66 +towgs84=-100,-248,259,0,0,0,0 +units=m +no_defs'],
    [3994, '+proj=merc +lon_0=100 +lat_ts=-41 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3995, '+proj=stere +lat_0=90 +lat_ts=71 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3996, '+proj=stere +lat_0=90 +lat_ts=75 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [3997, '+proj=tmerc +lat_0=0 +lon_0=55.3333333333333 +k=1 +x_0=500000 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [4000, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4001, '+proj=longlat +ellps=airy +no_defs'],
    [4002, '+proj=longlat +ellps=mod_airy +no_defs'],
    [4003, '+proj=longlat +ellps=aust_SA +no_defs'],
    [4004, '+proj=longlat +ellps=bessel +no_defs'],
    [4005, '+proj=longlat +a=6377492.018 +b=6356173.50871269 +no_defs'],
    [4006, '+proj=longlat +ellps=bess_nam +no_defs'],
    [4007, '+proj=longlat +a=6378293.64520875 +b=6356617.98767983 +no_defs'],
    [4008, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4009, '+proj=longlat +a=6378450.04754889 +b=6356826.62148844 +no_defs'],
    [4010, '+proj=longlat +a=6378300.789 +b=6356566.435 +no_defs'],
    [4011, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4012, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4013, '+proj=longlat +a=6378249.145 +b=6356514.96639875 +no_defs'],
    [4014, '+proj=longlat +a=6378249.2 +b=6356514.99694177 +no_defs'],
    [4015, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +no_defs'],
    [4016, '+proj=longlat +ellps=evrstSS +no_defs'],
    [4017, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4018, '+proj=longlat +a=6377304.063 +b=6356103.03899315 +no_defs'],
    [4019, '+proj=longlat +ellps=GRS80 +no_defs'],
    [4020, '+proj=longlat +ellps=helmert +no_defs'],
    [4021, '+proj=longlat +a=6378160 +b=6356774.50408554 +no_defs'],
    [4022, '+proj=longlat +ellps=intl +no_defs'],
    [4023, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4024, '+proj=longlat +ellps=krass +no_defs'],
    [4025, '+proj=longlat +ellps=WGS66 +no_defs'],
    [4026, '+proj=tmerc +lat_0=0 +lon_0=28.4 +k=0.9999400000000001 +x_0=200000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4027, '+proj=longlat +a=6376523 +b=6355862.93325557 +no_defs'],
    [4028, '+proj=longlat +a=6378298.3 +b=6356657.14266956 +no_defs'],
    [4029, '+proj=longlat +a=6378300 +b=6356751.68918918 +no_defs'],
    [4030, '+proj=longlat +ellps=WGS84 +no_defs'],
    [4031, '+proj=longlat +ellps=WGS84 +no_defs'],
    [4032, '+proj=longlat +a=6378136.2 +b=6356751.51692742 +no_defs'],
    [4033, '+proj=longlat +a=6378136.3 +b=6356751.61659214 +no_defs'],
    [4034, '+proj=longlat +a=6378249.14480801 +b=6356514.96620413 +no_defs'],
    [4035, '+proj=longlat +a=6371000 +b=6371000 +no_defs'],
    [4036, '+proj=longlat +ellps=GRS67 +no_defs'],
    [4037, '+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs'],
    [4038, '+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs'],
    [4039, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4040, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4041, '+proj=longlat +a=6378135 +b=6356750.30492159 +no_defs'],
    [4042, '+proj=longlat +a=6377299.36559538 +b=6356098.35900515 +no_defs'],
    [4043, '+proj=longlat +ellps=WGS72 +no_defs'],
    [4044, '+proj=longlat +a=6377301.243 +b=6356100.23016538 +no_defs'],
    [4045, '+proj=longlat +a=6377299.151 +b=6356098.14512013 +no_defs'],
    [4046, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4047, '+proj=longlat +a=6371007 +b=6371007 +no_defs'],
    [4048, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4049, '+proj=tmerc +lat_0=0 +lon_0=14 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4050, '+proj=tmerc +lat_0=0 +lon_0=16 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4051, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4052, '+proj=longlat +a=6370997 +b=6370997 +no_defs'],
    [4053, '+proj=longlat +a=6371228 +b=6371228 +no_defs'],
    [4054, '+proj=longlat +a=6378273 +b=6356889.449 +no_defs'],
    [4055, '+proj=longlat +a=6378137 +b=6378137 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4056, '+proj=tmerc +lat_0=0 +lon_0=20 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4057, '+proj=tmerc +lat_0=0 +lon_0=22 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4058, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4059, '+proj=tmerc +lat_0=0 +lon_0=26 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4060, '+proj=tmerc +lat_0=0 +lon_0=28 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4061, '+proj=utm +zone=33 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4062, '+proj=utm +zone=34 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4063, '+proj=utm +zone=35 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4071, '+proj=utm +zone=23 +south +ellps=intl +towgs84=-143.87,243.37,-33.52,0,0,0,0 +units=m +no_defs '],
    [4073, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4074, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4075, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4079, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4080, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4081, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4082, '+proj=utm +zone=27 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4083, '+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4087, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [4088, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +a=6371007 +b=6371007 +units=m +no_defs'],
    [4093, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.99998 +x_0=200000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4094, '+proj=tmerc +lat_0=0 +lon_0=10 +k=0.99998 +x_0=400000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4095, '+proj=tmerc +lat_0=0 +lon_0=11.75 +k=0.99998 +x_0=600000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4096, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=800000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4097, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.99998 +x_0=200000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [4098, '+proj=tmerc +lat_0=0 +lon_0=10 +k=0.99998 +x_0=400000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [4099, '+proj=tmerc +lat_0=0 +lon_0=11.75 +k=0.99998 +x_0=600000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [4100, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=800000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [4120, '+proj=longlat +ellps=bessel +no_defs'],
    [4121, '+proj=longlat +datum=GGRS87 +no_defs'],
    [4122, '+proj=longlat +a=6378135 +b=6356750.30492159 +no_defs'],
    [4123, '+proj=longlat +ellps=intl +towgs84=-96.062,-82.428,-121.753,4.801,0.345,-1.376,1.496 +no_defs '],
    [4124, '+proj=longlat +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +no_defs '],
    [4125, '+proj=longlat +ellps=bessel +towgs84=-404.78,685.68,45.47,0,0,0,0 +no_defs '],
    [4126, '+proj=longlat +ellps=GRS80 +no_defs'],
    [4127, '+proj=longlat +ellps=clrk66 +towgs84=-80,-100,-228,0,0,0,0 +no_defs '],
    [4128, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4129, '+proj=longlat +ellps=clrk66 +towgs84=-132,-110,-335,0,0,0,0 +no_defs '],
    [4130, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4131, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=198,881,317,0,0,0,0 +no_defs '],
    [4132, '+proj=longlat +ellps=clrk80 +towgs84=-241.54,-163.64,396.06,0,0,0,0 +no_defs '],
    [4133, '+proj=longlat +ellps=GRS80 +towgs84=0.055,-0.541,-0.185,0.0183,-0.0003,-0.007,-0.014 +no_defs '],
    [4134, '+proj=longlat +ellps=clrk80 +towgs84=-180.624,-225.516,173.919,-0.81,-1.898,8.336,16.71006 +no_defs '],
    [4135, '+proj=longlat +ellps=clrk66 +towgs84=61,-285,-181,0,0,0,0 +no_defs '],
    [4136, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4137, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4138, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4139, '+proj=longlat +ellps=clrk66 +towgs84=11,72,-101,0,0,0,0 +no_defs '],
    [4140, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4141, '+proj=longlat +ellps=GRS80 +towgs84=-48,55,52,0,0,0,0 +no_defs '],
    [4142, '+proj=longlat +ellps=clrk80 +towgs84=-125,53,467,0,0,0,0 +no_defs '],
    [4143, '+proj=longlat +ellps=clrk80 +towgs84=-124.76,53,466.79,0,0,0,0 +no_defs '],
    [4144, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=214,804,268,0,0,0,0 +no_defs '],
    [4145, '+proj=longlat +a=6377301.243 +b=6356100.230165384 +towgs84=283,682,231,0,0,0,0 +no_defs '],
    [4146, '+proj=longlat +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +no_defs '],
    [4147, '+proj=longlat +ellps=krass +towgs84=-17.51,-108.32,-62.39,0,0,0,0 +no_defs '],
    [4148, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4149, '+proj=longlat +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +no_defs '],
    [4150, '+proj=longlat +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +no_defs '],
    [4151, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4152, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4153, '+proj=longlat +ellps=intl +towgs84=-133.63,-157.5,-158.62,0,0,0,0 +no_defs '],
    [4154, '+proj=longlat +ellps=intl +towgs84=-117,-132,-164,0,0,0,0 +no_defs '],
    [4155, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-83,37,124,0,0,0,0 +no_defs '],
    [4156, '+proj=longlat +ellps=bessel +towgs84=589,76,480,0,0,0,0 +no_defs '],
    [4157, '+proj=longlat +a=6378293.64520875 +b=6356617.98767983 +no_defs'],
    [4158, '+proj=longlat +ellps=intl +towgs84=-0.465,372.095,171.736,0,0,0,0 +no_defs '],
    [4159, '+proj=longlat +ellps=intl +towgs84=-115.8543,-99.0583,-152.4616,0,0,0,0 +no_defs '],
    [4160, '+proj=longlat +ellps=intl +no_defs'],
    [4161, '+proj=longlat +ellps=intl +towgs84=27.5,14,186.4,0,0,0,0 +no_defs '],
    [4162, '+proj=longlat +ellps=bessel +no_defs'],
    [4163, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4164, '+proj=longlat +ellps=krass +towgs84=-76,-138,67,0,0,0,0 +no_defs '],
    [4165, '+proj=longlat +ellps=intl +towgs84=-173,253,27,0,0,0,0 +no_defs '],
    [4166, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4167, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4168, '+proj=longlat +a=6378300 +b=6356751.689189189 +towgs84=-199,32,322,0,0,0,0 +no_defs '],
    [4169, '+proj=longlat +ellps=clrk66 +towgs84=-115,118,426,0,0,0,0 +no_defs '],
    [4170, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4171, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4172, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4173, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4174, '+proj=longlat +a=6378300 +b=6356751.68918918 +no_defs'],
    [4175, '+proj=longlat +ellps=clrk80 +towgs84=-88,4,101,0,0,0,0 +no_defs '],
    [4176, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4178, '+proj=longlat +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +no_defs '],
    [4179, '+proj=longlat +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +no_defs '],
    [4180, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4181, '+proj=longlat +ellps=intl +towgs84=-189.6806,18.3463,-42.7695,-0.33746,-3.09264,2.53861,0.4598 +no_defs '],
    [4182, '+proj=longlat +ellps=intl +towgs84=-425,-169,81,0,0,0,0 +no_defs '],
    [4183, '+proj=longlat +ellps=intl +towgs84=-104,167,-38,0,0,0,0 +no_defs '],
    [4184, '+proj=longlat +ellps=intl +towgs84=-203,141,53,0,0,0,0 +no_defs '],
    [4185, '+proj=longlat +ellps=intl +no_defs'],
    [4188, '+proj=longlat +ellps=airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +no_defs '],
    [4189, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4190, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4191, '+proj=longlat +ellps=krass +towgs84=-44.183,-0.58,-38.489,2.3867,2.7072,-3.5196,-8.2703 +no_defs '],
    [4192, '+proj=longlat +ellps=intl +towgs84=-206.1,-174.7,-87.7,0,0,0,0 +no_defs '],
    [4193, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-70.9,-151.8,-41.4,0,0,0,0 +no_defs '],
    [4194, '+proj=longlat +ellps=intl +towgs84=164,138,-189,0,0,0,0 +no_defs '],
    [4195, '+proj=longlat +ellps=intl +towgs84=105,326,-102.5,0,0,0.814,-0.6 +no_defs '],
    [4196, '+proj=longlat +ellps=intl +towgs84=-45,417,-3.5,0,0,0.814,-0.6 +no_defs '],
    [4197, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4198, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4199, '+proj=longlat +ellps=intl +no_defs'],
    [4200, '+proj=longlat +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +no_defs '],
    [4201, '+proj=longlat +ellps=clrk80 +towgs84=-166,-15,204,0,0,0,0 +no_defs '],
    [4202, '+proj=longlat +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +no_defs '],
    [4203, '+proj=longlat +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +no_defs '],
    [4204, '+proj=longlat +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +no_defs '],
    [4205, '+proj=longlat +ellps=krass +towgs84=-43,-163,45,0,0,0,0 +no_defs '],
    [4206, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4207, '+proj=longlat +ellps=intl +towgs84=-304.046,-60.576,103.64,0,0,0,0 +no_defs '],
    [4208, '+proj=longlat +ellps=intl +towgs84=-151.99,287.04,-147.45,0,0,0,0 +no_defs '],
    [4209, '+proj=longlat +a=6378249.145 +b=6356514.966398753 +towgs84=-143,-90,-294,0,0,0,0 +no_defs '],
    [4210, '+proj=longlat +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +no_defs '],
    [4211, '+proj=longlat +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +no_defs '],
    [4212, '+proj=longlat +ellps=clrk80 +towgs84=31.95,300.99,419.19,0,0,0,0 +no_defs '],
    [4213, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-106,-87,188,0,0,0,0 +no_defs '],
    [4214, '+proj=longlat +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +no_defs '],
    [4215, '+proj=longlat +ellps=intl +no_defs'],
    [4216, '+proj=longlat +ellps=clrk66 +towgs84=-73,213,296,0,0,0,0 +no_defs '],
    [4217, '+proj=tmerc +lat_0=0 +lon_0=171 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4218, '+proj=longlat +ellps=intl +towgs84=307,304,-318,0,0,0,0 +no_defs '],
    [4219, '+proj=longlat +ellps=bessel +towgs84=-384,664,-48,0,0,0,0 +no_defs '],
    [4220, '+proj=longlat +ellps=clrk80 +towgs84=-50.9,-347.6,-231,0,0,0,0 +no_defs '],
    [4221, '+proj=longlat +ellps=intl +towgs84=-148,136,90,0,0,0,0 +no_defs '],
    [4222, '+proj=longlat +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +no_defs '],
    [4223, '+proj=longlat +datum=carthage +no_defs'],
    [4224, '+proj=longlat +ellps=intl +towgs84=-143.87,243.37,-33.52,0,0,0,0 +no_defs '],
    [4225, '+proj=longlat +ellps=intl +towgs84=-205.57,168.77,-4.12,0,0,0,0 +no_defs '],
    [4226, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4227, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-190.421,8.532,238.69,0,0,0,0 +no_defs '],
    [4228, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4229, '+proj=longlat +ellps=helmert +towgs84=-130,110,-13,0,0,0,0 +no_defs '],
    [4230, '+proj=longlat +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +no_defs '],
    [4231, '+proj=longlat +ellps=intl +towgs84=-83.11,-97.38,-117.22,0.005693,-0.044698,0.044285,0.1218 +no_defs '],
    [4232, '+proj=longlat +ellps=clrk80 +towgs84=-333.102,-11.02,230.69,0,0,0.554,0.219 +no_defs '],
    [4233, '+proj=longlat +ellps=intl +towgs84=-133,-321,50,0,0,0,0 +no_defs '],
    [4234, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4235, '+proj=longlat +ellps=intl +no_defs'],
    [4236, '+proj=longlat +ellps=intl +towgs84=-637,-549,-203,0,0,0,0 +no_defs '],
    [4237, '+proj=longlat +ellps=GRS67 +towgs84=52.17,-71.82,-14.9,0,0,0,0 +no_defs '],
    [4238, '+proj=longlat +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +no_defs '],
    [4239, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=217,823,299,0,0,0,0 +no_defs '],
    [4240, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=210,814,289,0,0,0,0 +no_defs '],
    [4241, '+proj=longlat +a=6378249.14480801 +b=6356514.96620413 +no_defs'],
    [4242, '+proj=longlat +ellps=clrk66 +towgs84=70,207,389.5,0,0,0,0 +no_defs '],
    [4243, '+proj=longlat +a=6377299.36559538 +b=6356098.35900515 +no_defs'],
    [4244, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=-97,787,86,0,0,0,0 +no_defs '],
    [4245, '+proj=longlat +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +no_defs '],
    [4246, '+proj=longlat +ellps=clrk80 +towgs84=-294.7,-200.1,525.5,0,0,0,0 +no_defs '],
    [4247, '+proj=longlat +ellps=intl +towgs84=-273.5,110.6,-357.9,0,0,0,0 +no_defs '],
    [4248, '+proj=longlat +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +no_defs '],
    [4249, '+proj=longlat +ellps=intl +no_defs'],
    [4250, '+proj=longlat +ellps=clrk80 +towgs84=-130,29,364,0,0,0,0 +no_defs '],
    [4251, '+proj=longlat +ellps=clrk80 +towgs84=-90,40,88,0,0,0,0 +no_defs '],
    [4252, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4253, '+proj=longlat +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +no_defs '],
    [4254, '+proj=longlat +ellps=intl +towgs84=16,196,93,0,0,0,0 +no_defs '],
    [4255, '+proj=longlat +ellps=intl +towgs84=-333,-222,114,0,0,0,0 +no_defs '],
    [4256, '+proj=longlat +ellps=clrk80 +towgs84=41,-220,-134,0,0,0,0 +no_defs '],
    [4257, '+proj=longlat +ellps=bessel +towgs84=-587.8,519.75,145.76,0,0,0,0 +no_defs '],
    [4258, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4259, '+proj=longlat +ellps=intl +towgs84=-254.1,-5.36,-100.29,0,0,0,0 +no_defs '],
    [4260, '+proj=longlat +ellps=clrk80 +towgs84=-70.9,-151.8,-41.4,0,0,0,0 +no_defs '],
    [4261, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +no_defs '],
    [4262, '+proj=longlat +ellps=bessel +towgs84=639,405,60,0,0,0,0 +no_defs '],
    [4263, '+proj=longlat +ellps=clrk80 +towgs84=-92,-93,122,0,0,0,0 +no_defs '],
    [4264, '+proj=longlat +ellps=intl +towgs84=-252.95,-4.11,-96.38,0,0,0,0 +no_defs '],
    [4265, '+proj=longlat +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +no_defs '],
    [4266, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-74,-130,42,0,0,0,0 +no_defs '],
    [4267, '+proj=longlat +datum=NAD27 +no_defs'],
    [4268, '+proj=longlat +a=6378450.04754889 +b=6356826.62148844 +no_defs'],
    [4269, '+proj=longlat +datum=NAD83 +no_defs'],
    [4270, '+proj=longlat +ellps=clrk80 +towgs84=-249,-156,381,0,0,0,0 +no_defs '],
    [4271, '+proj=longlat +ellps=intl +towgs84=-10,375,165,0,0,0,0 +no_defs '],
    [4272, '+proj=longlat +datum=nzgd49 +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [4273, '+proj=longlat +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +no_defs '],
    [4274, '+proj=longlat +ellps=intl +towgs84=-223.237,110.193,36.649,0,0,0,0 +no_defs '],
    [4275, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +no_defs '],
    [4276, '+proj=longlat +ellps=WGS66 +no_defs'],
    [4277, '+proj=longlat +datum=OSGB36 +no_defs'],
    [4278, '+proj=longlat +ellps=airy +no_defs'],
    [4279, '+proj=longlat +ellps=airy +no_defs'],
    [4280, '+proj=longlat +ellps=bessel +no_defs'],
    [4281, '+proj=longlat +a=6378300.789 +b=6356566.435 +towgs84=-275.7224,94.7824,340.8944,-8.001,-4.42,-11.821,1 +no_defs '],
    [4282, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-148,51,-291,0,0,0,0 +no_defs '],
    [4283, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4284, '+proj=longlat +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +no_defs '],
    [4285, '+proj=longlat +ellps=intl +towgs84=-128.16,-282.42,21.93,0,0,0,0 +no_defs '],
    [4286, '+proj=longlat +ellps=helmert +no_defs'],
    [4287, '+proj=longlat +ellps=intl +towgs84=164,138,-189,0,0,0,0 +no_defs '],
    [4288, '+proj=longlat +ellps=intl +no_defs'],
    [4289, '+proj=longlat +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857,0.350733,-1.87035,4.0812 +no_defs '],
    [4291, '+proj=longlat +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +no_defs '],
    [4292, '+proj=longlat +ellps=intl +towgs84=-355,21,72,0,0,0,0 +no_defs '],
    [4293, '+proj=longlat +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +no_defs '],
    [4294, '+proj=longlat +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +no_defs '],
    [4295, '+proj=longlat +ellps=bessel +no_defs'],
    [4296, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4297, '+proj=longlat +ellps=intl +towgs84=-189,-242,-91,0,0,0,0 +no_defs '],
    [4298, '+proj=longlat +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +no_defs '],
    [4299, '+proj=longlat +datum=IRE65 +no_defs'],
    [4300, '+proj=longlat +ellps=mod_airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +no_defs '],
    [4301, '+proj=longlat +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +no_defs '],
    [4302, '+proj=longlat +a=6378293.645208759 +b=6356617.987679838 +towgs84=-61.702,284.488,472.052,0,0,0,0 +no_defs '],
    [4303, '+proj=longlat +ellps=helmert +no_defs'],
    [4304, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-73,-247,227,0,0,0,0 +no_defs '],
    [4305, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [4306, '+proj=longlat +ellps=bessel +no_defs'],
    [4307, '+proj=longlat +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +no_defs '],
    [4308, '+proj=longlat +ellps=bessel +no_defs'],
    [4309, '+proj=longlat +ellps=intl +towgs84=-155,171,37,0,0,0,0 +no_defs '],
    [4310, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-30,190,89,0,0,0,0 +no_defs '],
    [4311, '+proj=longlat +ellps=intl +towgs84=-265,120,-358,0,0,0,0 +no_defs '],
    [4312, '+proj=longlat +datum=hermannskogel +no_defs'],
    [4313, '+proj=longlat +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +no_defs '],
    [4314, '+proj=longlat +datum=potsdam +no_defs'],
    [4315, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-23,259,-9,0,0,0,0 +no_defs '],
    [4316, '+proj=longlat +ellps=intl +towgs84=103.25,-100.4,-307.19,0,0,0,0 +no_defs '],
    [4317, '+proj=longlat +ellps=krass +towgs84=28,-121,-77,0,0,0,0 +no_defs '],
    [4318, '+proj=longlat +ellps=WGS84 +towgs84=-3.2,-5.7,2.8,0,0,0,0 +no_defs '],
    [4319, '+proj=longlat +ellps=GRS80 +towgs84=-20.8,11.3,2.4,0,0,0,0 +no_defs '],
    [4322, '+proj=longlat +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +no_defs '],
    [4324, '+proj=longlat +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +no_defs '],
    [4326, '+proj=longlat +datum=WGS84 +no_defs'],
    [4327, '+proj=longlat +datum=WGS84 +no_defs'],
    [4328, '+proj=geocent +datum=WGS84 +units=m +no_defs'],
    [4329, '+proj=longlat +datum=WGS84 +no_defs'],
    [4330, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4331, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4332, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4333, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4334, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4335, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4336, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4337, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4338, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4339, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4340, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4341, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4342, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4343, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4344, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4345, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4346, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4347, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4348, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4349, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4350, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4351, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4352, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4353, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4354, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4355, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4356, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4357, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4358, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4359, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4360, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4361, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4362, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4363, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4364, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4365, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4366, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4367, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4368, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4369, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4370, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4371, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4372, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4373, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4374, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4375, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4376, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4377, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4378, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4379, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4380, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4381, '+proj=longlat +ellps=intl +no_defs'],
    [4382, '+proj=geocent +ellps=intl +units=m +no_defs'],
    [4383, '+proj=longlat +ellps=WGS84 +no_defs'],
    [4384, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4385, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4386, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4387, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4388, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4389, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4390, '+proj=cass +lat_0=2.042583333333333 +lon_0=103.5627583333333 +x_0=0 +y_0=0 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4391, '+proj=cass +lat_0=2.712283333333334 +lon_0=101.9411666666667 +x_0=-242.005 +y_0=-948.547 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4392, '+proj=cass +lat_0=3.710972222222222 +lon_0=102.4361777777778 +x_0=0 +y_0=0 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4393, '+proj=cass +lat_0=3.680344444444444 +lon_0=101.5082444444444 +x_0=-21759.438 +y_0=55960.906 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4394, '+proj=cass +lat_0=4.946141666666667 +lon_0=102.8952083333333 +x_0=0 +y_0=0 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4395, '+proj=cass +lat_0=5.421325 +lon_0=100.3458694444444 +x_0=0 +y_0=0 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4396, '+proj=cass +lat_0=5.965147222222223 +lon_0=100.6375944444444 +x_0=0 +y_0=0 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4397, '+proj=cass +lat_0=4.859380555555555 +lon_0=100.8167666666667 +x_0=0 +y_0=133453.669 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4398, '+proj=cass +lat_0=5.893922222222222 +lon_0=102.1772916666667 +x_0=0 +y_0=0 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [4399, '+proj=tmerc +lat_0=0 +lon_0=171 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4400, '+proj=tmerc +lat_0=0 +lon_0=177 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4401, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4402, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4403, '+proj=tmerc +lat_0=0 +lon_0=-165 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4404, '+proj=tmerc +lat_0=0 +lon_0=-159 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4405, '+proj=tmerc +lat_0=0 +lon_0=-153 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4406, '+proj=tmerc +lat_0=0 +lon_0=-147 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4407, '+proj=tmerc +lat_0=0 +lon_0=-141 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4408, '+proj=tmerc +lat_0=0 +lon_0=-135 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4409, '+proj=tmerc +lat_0=0 +lon_0=-129 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4410, '+proj=tmerc +lat_0=0 +lon_0=-123 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4411, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4412, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4413, '+proj=tmerc +lat_0=0 +lon_0=-105 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4414, '+proj=tmerc +lat_0=13.5 +lon_0=144.75 +k=1 +x_0=100000 +y_0=200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4415, '+proj=lcc +lat_1=-6.5 +lat_2=-11.5 +lat_0=-9 +lon_0=26 +x_0=500000 +y_0=500000 +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +units=m +no_defs '],
    [4417, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [4418, '+proj=tmerc +lat_0=0 +lon_0=-75 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4419, '+proj=tmerc +lat_0=0 +lon_0=-69 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4420, '+proj=tmerc +lat_0=0 +lon_0=177 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4421, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4422, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4423, '+proj=tmerc +lat_0=0 +lon_0=-165 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4424, '+proj=tmerc +lat_0=0 +lon_0=-159 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4425, '+proj=tmerc +lat_0=0 +lon_0=-153 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4426, '+proj=tmerc +lat_0=0 +lon_0=-147 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4427, '+proj=tmerc +lat_0=0 +lon_0=-141 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4428, '+proj=tmerc +lat_0=0 +lon_0=-135 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4429, '+proj=tmerc +lat_0=0 +lon_0=-129 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4430, '+proj=tmerc +lat_0=0 +lon_0=-123 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4431, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4432, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4433, '+proj=tmerc +lat_0=0 +lon_0=-105 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4434, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [4437, '+proj=lcc +lat_1=18.43333333333333 +lat_2=18.03333333333333 +lat_0=17.83333333333333 +lon_0=-66.43333333333334 +x_0=200000 +y_0=200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4438, '+proj=tmerc +lat_0=0 +lon_0=-75 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4439, '+proj=tmerc +lat_0=0 +lon_0=-69 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4455, '+proj=lcc +lat_1=40.9666666666666 +lat_2=39.9333333333333 +lat_0=39.3333333333333 +lon_0=-77.75 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [4456, '+proj=lcc +lat_1=41.0333333333333 +lat_2=40.6666666666666 +lat_0=40.5 +lon_0=-74 +x_0=609601.219202438 +y_0=30480.0609601219 +datum=NAD27 +units=us-ft +no_defs'],
    [4457, '+proj=lcc +lat_1=45.6833333333333 +lat_2=44.4166666666666 +lat_0=43.8333333333333 +lon_0=-100 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [4462, '+proj=lcc +lat_1=-18 +lat_2=-36 +lat_0=-27 +lon_0=132 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [4463, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4465, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4466, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4467, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4468, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4469, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4470, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4471, '+proj=utm +zone=38 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4472, '+proj=longlat +ellps=intl +towgs84=-381.788,-57.501,-256.673,0,0,0,0 +no_defs'],
    [4473, '+proj=geocent +ellps=intl +units=m +no_defs'],
    [4474, '+proj=utm +zone=38 +south +ellps=intl +towgs84=-382,-59,-262,0,0,0,0 +units=m +no_defs '],
    [4475, '+proj=longlat +ellps=intl +towgs84=-381.788,-57.501,-256.673,0,0,0,0 +no_defs '],
    [4479, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4480, '+proj=longlat +ellps=GRS80 +no_defs'],
    [4481, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4482, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4483, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4484, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4485, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4486, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4487, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4488, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4489, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4490, '+proj=longlat +ellps=GRS80 +no_defs'],
    [4491, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=13500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4492, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=14500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4493, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=15500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4494, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=16500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4495, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=17500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4496, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4497, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4498, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=20500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4499, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=21500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4500, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=22500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4501, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=23500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4502, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4503, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4504, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4505, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4506, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4507, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4508, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4509, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4510, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4511, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4512, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4513, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=25500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4514, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=26500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4515, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=27500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4516, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=28500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4517, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=29500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4518, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=30500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4519, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=31500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4520, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=32500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4521, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=33500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4522, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=34500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4523, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=35500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4524, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=36500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4525, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=37500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4526, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4527, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4528, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4529, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4530, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=42500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4531, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=43500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4532, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=44500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4533, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=45500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4534, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4535, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4536, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4537, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4538, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4539, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4540, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4541, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4542, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4543, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4544, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4545, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4546, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4547, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4548, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4549, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4550, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4551, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4552, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4553, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4554, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [4555, '+proj=longlat +ellps=krass +no_defs'],
    [4556, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4557, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4558, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4559, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4568, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4569, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=14500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4570, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=15500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4571, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=16500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4572, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=17500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4573, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4574, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4575, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=20500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4576, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=21500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4577, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=22500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4578, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=23500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4579, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4580, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4581, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4582, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4583, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4584, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4585, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4586, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4587, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4588, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4589, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4600, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4601, '+proj=longlat +ellps=clrk80 +towgs84=-255,-15,71,0,0,0,0 +no_defs '],
    [4602, '+proj=longlat +ellps=clrk80 +towgs84=725,685,536,0,0,0,0 +no_defs '],
    [4603, '+proj=longlat +ellps=clrk80 +towgs84=72,213.7,93,0,0,0,0 +no_defs '],
    [4604, '+proj=longlat +ellps=clrk80 +towgs84=174,359,365,0,0,0,0 +no_defs '],
    [4605, '+proj=longlat +ellps=clrk80 +towgs84=9,183,236,0,0,0,0 +no_defs '],
    [4606, '+proj=longlat +ellps=clrk80 +towgs84=-149,128,296,0,0,0,0 +no_defs '],
    [4607, '+proj=longlat +ellps=clrk80 +towgs84=195.671,332.517,274.607,0,0,0,0 +no_defs '],
    [4608, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4609, '+proj=longlat +ellps=clrk66 +no_defs'],
    [4610, '+proj=longlat +a=6378140 +b=6356755.28815752 +no_defs'],
    [4611, '+proj=longlat +ellps=intl +towgs84=-162.619,-276.959,-161.764,0.067753,-2.243649,-1.158827,-1.094246 +no_defs '],
    [4612, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4613, '+proj=longlat +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +no_defs '],
    [4614, '+proj=longlat +ellps=intl +towgs84=-119.4248,-303.65872,-11.00061,1.164298,0.174458,1.096259,3.657065 +no_defs '],
    [4615, '+proj=longlat +ellps=intl +towgs84=-499,-249,314,0,0,0,0 +no_defs '],
    [4616, '+proj=longlat +ellps=intl +towgs84=-289,-124,60,0,0,0,0 +no_defs '],
    [4617, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4618, '+proj=longlat +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +no_defs '],
    [4619, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4620, '+proj=longlat +ellps=clrk80 +towgs84=-106,-129,165,0,0,0,0 +no_defs '],
    [4621, '+proj=longlat +ellps=intl +towgs84=137,248,-430,0,0,0,0 +no_defs '],
    [4622, '+proj=longlat +ellps=intl +towgs84=-467,-16,-300,0,0,0,0 +no_defs '],
    [4623, '+proj=longlat +ellps=intl +towgs84=-186,230,110,0,0,0,0 +no_defs '],
    [4624, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4625, '+proj=longlat +ellps=intl +towgs84=186,482,151,0,0,0,0 +no_defs '],
    [4626, '+proj=longlat +ellps=intl +towgs84=94,-948,-1262,0,0,0,0 +no_defs '],
    [4627, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4628, '+proj=longlat +ellps=intl +towgs84=162,117,154,0,0,0,0 +no_defs '],
    [4629, '+proj=longlat +ellps=intl +towgs84=72.438,345.918,79.486,1.6045,0.8823,0.5565,1.3746 +no_defs '],
    [4630, '+proj=longlat +ellps=intl +towgs84=84,274,65,0,0,0,0 +no_defs '],
    [4631, '+proj=longlat +ellps=intl +towgs84=145,-187,103,0,0,0,0 +no_defs '],
    [4632, '+proj=longlat +ellps=intl +towgs84=-382,-59,-262,0,0,0,0 +no_defs '],
    [4633, '+proj=longlat +ellps=intl +towgs84=335.47,222.58,-230.94,0,0,0,0 +no_defs '],
    [4634, '+proj=longlat +ellps=intl +towgs84=-13,-348,292,0,0,0,0 +no_defs '],
    [4635, '+proj=longlat +ellps=intl +towgs84=-122.383,-188.696,103.344,3.5107,-4.9668,-5.7047,4.4798 +no_defs '],
    [4636, '+proj=longlat +ellps=intl +towgs84=365,194,166,0,0,0,0 +no_defs '],
    [4637, '+proj=longlat +ellps=intl +towgs84=325,154,172,0,0,0,0 +no_defs '],
    [4638, '+proj=longlat +ellps=clrk66 +towgs84=11.363,424.148,373.13,0,0,0,0 +no_defs '],
    [4639, '+proj=longlat +ellps=intl +towgs84=253,-132,-127,0,0,0,0 +no_defs '],
    [4640, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4641, '+proj=longlat +ellps=intl +towgs84=287.58,177.78,-135.41,0,0,0,0 +no_defs '],
    [4642, '+proj=longlat +ellps=intl +towgs84=-13,-348,292,0,0,0,0 +no_defs '],
    [4643, '+proj=longlat +ellps=intl +towgs84=-480.26,-438.32,-643.429,16.3119,20.1721,-4.0349,-111.7002 +no_defs '],
    [4644, '+proj=longlat +ellps=intl +towgs84=-10.18,-350.43,291.37,0,0,0,0 +no_defs '],
    [4645, '+proj=longlat +ellps=intl +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4646, '+proj=longlat +ellps=intl +towgs84=-963,510,-359,0,0,0,0 +no_defs '],
    [4647, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=32500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4652, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=25500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4653, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=26500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4654, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=27500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4655, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=28500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4656, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=29500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4657, '+proj=longlat +a=6377019.27 +b=6355762.5391 +towgs84=-28,199,5,0,0,0,0 +no_defs '],
    [4658, '+proj=longlat +ellps=intl +towgs84=-73,47,-83,0,0,0,0 +no_defs '],
    [4659, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4660, '+proj=longlat +ellps=intl +towgs84=982.6087,552.753,-540.873,6.6816266,-31.6114924,-19.84816,16.805 +no_defs '],
    [4661, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4662, '+proj=longlat +ellps=intl +towgs84=-11.64,-348.6,291.98,0,0,0,0 +no_defs '],
    [4663, '+proj=longlat +ellps=intl +towgs84=-502.862,-247.438,312.724,0,0,0,0 +no_defs '],
    [4664, '+proj=longlat +ellps=intl +towgs84=-204.619,140.176,55.226,0,0,0,0 +no_defs '],
    [4665, '+proj=longlat +ellps=intl +towgs84=-106.226,166.366,-37.893,0,0,0,0 +no_defs '],
    [4666, '+proj=longlat +ellps=bessel +towgs84=508.088,-191.042,565.223,0,0,0,0 +no_defs '],
    [4667, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4668, '+proj=longlat +ellps=intl +towgs84=-86,-98,-119,0,0,0,0 +no_defs '],
    [4669, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4670, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4671, '+proj=longlat +a=6378249.2 +b=6356515 +no_defs'],
    [4672, '+proj=longlat +ellps=intl +towgs84=175,-38,113,0,0,0,0 +no_defs '],
    [4673, '+proj=longlat +ellps=intl +towgs84=174.05,-25.49,112.57,0,0,0.554,0.2263 +no_defs '],
    [4674, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4675, '+proj=longlat +ellps=clrk66 +towgs84=-100,-248,259,0,0,0,0 +no_defs '],
    [4676, '+proj=longlat +ellps=krass +no_defs'],
    [4677, '+proj=longlat +ellps=krass +no_defs'],
    [4678, '+proj=longlat +ellps=krass +towgs84=44.585,-131.212,-39.544,0,0,0,0 +no_defs '],
    [4679, '+proj=longlat +ellps=clrk80 +towgs84=-80.01,253.26,291.19,0,0,0,0 +no_defs '],
    [4680, '+proj=longlat +ellps=clrk80 +towgs84=124.5,-63.5,-281,0,0,0,0 +no_defs '],
    [4681, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4682, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=283.7,735.9,261.1,0,0,0,0 +no_defs '],
    [4683, '+proj=longlat +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +no_defs '],
    [4684, '+proj=longlat +ellps=intl +towgs84=-133,-321,50,0,0,0,0 +no_defs '],
    [4685, '+proj=longlat +ellps=intl +no_defs'],
    [4686, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4687, '+proj=longlat +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,-0.0183,0.0003,-0.007,-0.0093 +no_defs '],
    [4688, '+proj=longlat +ellps=intl +towgs84=347.103,1078.125,2623.922,-33.8875,70.6773,-9.3943,186.074 +no_defs '],
    [4689, '+proj=longlat +ellps=intl +towgs84=410.721,55.049,80.746,2.5779,2.3514,0.6664,17.3311 +no_defs '],
    [4690, '+proj=longlat +ellps=intl +towgs84=221.525,152.948,176.768,-2.3847,-1.3896,-0.877,11.4741 +no_defs '],
    [4691, '+proj=longlat +ellps=intl +towgs84=215.525,149.593,176.229,-3.2624,-1.692,-1.1571,10.4773 +no_defs '],
    [4692, '+proj=longlat +ellps=intl +towgs84=217.037,86.959,23.956,0,0,0,0 +no_defs '],
    [4693, '+proj=longlat +ellps=WGS84 +towgs84=0,-0.15,0.68,0,0,0,0 +no_defs '],
    [4694, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4695, '+proj=longlat +ellps=clrk66 +towgs84=-103.746,-9.614,-255.95,0,0,0,0 +no_defs '],
    [4696, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4697, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4698, '+proj=longlat +ellps=intl +towgs84=145,-187,103,0,0,0,0 +no_defs '],
    [4699, '+proj=longlat +ellps=clrk80 +towgs84=-770.1,158.4,-498.2,0,0,0,0 +no_defs '],
    [4700, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4701, '+proj=longlat +ellps=clrk80 +towgs84=-79.9,-158,-168.9,0,0,0,0 +no_defs '],
    [4702, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4703, '+proj=longlat +ellps=clrk80 +no_defs'],
    [4704, '+proj=longlat +ellps=intl +no_defs'],
    [4705, '+proj=longlat +ellps=intl +no_defs'],
    [4706, '+proj=longlat +ellps=helmert +towgs84=-146.21,112.63,4.05,0,0,0,0 +no_defs '],
    [4707, '+proj=longlat +ellps=intl +towgs84=114,-116,-333,0,0,0,0 +no_defs '],
    [4708, '+proj=longlat +ellps=aust_SA +towgs84=-491,-22,435,0,0,0,0 +no_defs '],
    [4709, '+proj=longlat +ellps=intl +towgs84=145,75,-272,0,0,0,0 +no_defs '],
    [4710, '+proj=longlat +ellps=intl +towgs84=-320,550,-494,0,0,0,0 +no_defs '],
    [4711, '+proj=longlat +ellps=intl +towgs84=124,-234,-25,0,0,0,0 +no_defs '],
    [4712, '+proj=longlat +ellps=intl +towgs84=-205,107,53,0,0,0,0 +no_defs '],
    [4713, '+proj=longlat +ellps=clrk80 +towgs84=-77,-128,142,0,0,0,0 +no_defs '],
    [4714, '+proj=longlat +ellps=intl +towgs84=-127,-769,472,0,0,0,0 +no_defs '],
    [4715, '+proj=longlat +ellps=intl +towgs84=-104,-129,239,0,0,0,0 +no_defs '],
    [4716, '+proj=longlat +ellps=intl +towgs84=298,-304,-375,0,0,0,0 +no_defs '],
    [4717, '+proj=longlat +ellps=clrk66 +towgs84=-2,151,181,0,0,0,0 +no_defs '],
    [4718, '+proj=longlat +ellps=intl +towgs84=230,-199,-752,0,0,0,0 +no_defs '],
    [4719, '+proj=longlat +ellps=intl +towgs84=211,147,111,0,0,0,0 +no_defs '],
    [4720, '+proj=longlat +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +no_defs '],
    [4721, '+proj=longlat +ellps=intl +towgs84=265.025,384.929,-194.046,0,0,0,0 +no_defs '],
    [4722, '+proj=longlat +ellps=intl +towgs84=-794,119,-298,0,0,0,0 +no_defs '],
    [4723, '+proj=longlat +ellps=clrk66 +towgs84=-179.483,-69.379,-27.584,-7.862,8.163,6.042,-13.925 +no_defs '],
    [4724, '+proj=longlat +ellps=intl +towgs84=208,-435,-229,0,0,0,0 +no_defs '],
    [4725, '+proj=longlat +ellps=intl +towgs84=189,-79,-202,0,0,0,0 +no_defs '],
    [4726, '+proj=longlat +ellps=clrk66 +towgs84=8.853,-52.644,180.304,-0.393,-2.323,2.96,-24.081 +no_defs '],
    [4727, '+proj=longlat +ellps=intl +towgs84=403,-81,277,0,0,0,0 +no_defs '],
    [4728, '+proj=longlat +ellps=intl +towgs84=-307,-92,127,0,0,0,0 +no_defs '],
    [4729, '+proj=longlat +ellps=intl +towgs84=185,165,42,0,0,0,0 +no_defs '],
    [4730, '+proj=longlat +ellps=intl +towgs84=170,42,84,0,0,0,0 +no_defs '],
    [4731, '+proj=longlat +ellps=clrk80 +towgs84=51,391,-36,0,0,0,0 +no_defs '],
    [4732, '+proj=longlat +a=6378270 +b=6356794.343434343 +towgs84=102,52,-38,0,0,0,0 +no_defs '],
    [4733, '+proj=longlat +ellps=intl +towgs84=276,-57,149,0,0,0,0 +no_defs '],
    [4734, '+proj=longlat +ellps=intl +towgs84=-632,438,-609,0,0,0,0 +no_defs '],
    [4735, '+proj=longlat +ellps=intl +towgs84=647,1777,-1124,0,0,0,0 +no_defs '],
    [4736, '+proj=longlat +ellps=clrk80 +towgs84=260,12,-147,0,0,0,0 +no_defs '],
    [4737, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4738, '+proj=longlat +a=6378293.64520875 +b=6356617.98767983 +no_defs'],
    [4739, '+proj=longlat +ellps=intl +towgs84=-156,-271,-189,0,0,0,0 +no_defs '],
    [4740, '+proj=longlat +a=6378136 +b=6356751.361745712 +towgs84=0,0,1.5,0,0,0.076,0 +no_defs '],
    [4741, '+proj=longlat +ellps=intl +no_defs'],
    [4742, '+proj=longlat +ellps=GRS80 +no_defs'],
    [4743, '+proj=longlat +ellps=clrk80 +towgs84=70.995,-335.916,262.898,0,0,0,0 +no_defs '],
    [4744, '+proj=longlat +ellps=clrk80 +towgs84=-242.2,-144.9,370.3,0,0,0,0 +no_defs '],
    [4745, '+proj=longlat +ellps=bessel +no_defs'],
    [4746, '+proj=longlat +ellps=bessel +no_defs'],
    [4747, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4748, '+proj=longlat +a=6378306.3696 +b=6356571.996 +towgs84=51,391,-36,0,0,0,0 +no_defs '],
    [4749, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4750, '+proj=longlat +ellps=WGS84 +towgs84=-56.263,16.136,-22.856,0,0,0,0 +no_defs '],
    [4751, '+proj=longlat +a=6377295.664 +b=6356094.6679152 +no_defs'],
    [4752, '+proj=longlat +a=6378306.3696 +b=6356571.996 +towgs84=98,390,-22,0,0,0,0 +no_defs '],
    [4753, '+proj=longlat +ellps=intl +no_defs'],
    [4754, '+proj=longlat +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +no_defs '],
    [4755, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4756, '+proj=longlat +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +no_defs '],
    [4757, '+proj=longlat +ellps=WGS84 +no_defs'],
    [4758, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4759, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4760, '+proj=longlat +ellps=WGS66 +no_defs'],
    [4761, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4762, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4763, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4764, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4765, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [4766, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=30500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4767, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=31500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4768, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=32500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4769, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=33500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4770, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=34500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4771, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=35500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4772, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=36500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4773, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=37500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4774, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=38500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4775, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=39500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4776, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=40500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4777, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=41500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4778, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=42500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4779, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=43500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4780, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=44500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4781, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=45500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4782, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4783, '+proj=tmerc +lat_0=0 +lon_0=78 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4784, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4785, '+proj=tmerc +lat_0=0 +lon_0=84 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4786, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4787, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4788, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4789, '+proj=tmerc +lat_0=0 +lon_0=96 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4790, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4791, '+proj=tmerc +lat_0=0 +lon_0=102 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4792, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4793, '+proj=tmerc +lat_0=0 +lon_0=108 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4794, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4795, '+proj=tmerc +lat_0=0 +lon_0=114 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4796, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4797, '+proj=tmerc +lat_0=0 +lon_0=120 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4798, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4799, '+proj=tmerc +lat_0=0 +lon_0=126 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4800, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4801, '+proj=longlat +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +pm=bern +no_defs '],
    [4802, '+proj=longlat +ellps=intl +towgs84=307,304,-318,0,0,0,0 +pm=bogota +no_defs '],
    [4803, '+proj=longlat +ellps=intl +towgs84=-304.046,-60.576,103.64,0,0,0,0 +pm=lisbon +no_defs '],
    [4804, '+proj=longlat +ellps=bessel +towgs84=-587.8,519.75,145.76,0,0,0,0 +pm=jakarta +no_defs '],
    [4805, '+proj=longlat +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +no_defs '],
    [4806, '+proj=longlat +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +pm=rome +no_defs '],
    [4807, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +no_defs '],
    [4808, '+proj=longlat +ellps=bessel +pm=jakarta +no_defs'],
    [4809, '+proj=longlat +ellps=intl +pm=brussels +no_defs'],
    [4810, '+proj=longlat +ellps=intl +towgs84=-189,-242,-91,0,0,0,0 +pm=paris +no_defs '],
    [4811, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-73,-247,227,0,0,0,0 +pm=paris +no_defs '],
    [4812, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4813, '+proj=longlat +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +pm=jakarta +no_defs '],
    [4814, '+proj=longlat +ellps=bessel +pm=stockholm +no_defs'],
    [4815, '+proj=longlat +ellps=bessel +pm=athens +no_defs'],
    [4816, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-263,6,431,0,0,0,0 +pm=paris +no_defs '],
    [4817, '+proj=longlat +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +no_defs '],
    [4818, '+proj=longlat +ellps=bessel +towgs84=589,76,480,0,0,0,0 +pm=ferro +no_defs '],
    [4819, '+proj=longlat +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +pm=paris +no_defs '],
    [4820, '+proj=longlat +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +pm=jakarta +no_defs '],
    [4821, '+proj=longlat +a=6378249.2 +b=6356515 +pm=paris +no_defs'],
    [4822, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [4823, '+proj=longlat +ellps=intl +no_defs'],
    [4824, '+proj=longlat +ellps=intl +no_defs'],
    [4826, '+proj=lcc +lat_1=15 +lat_2=16.6666666666666 +lat_0=15.8333333333333 +lon_0=-24 +x_0=161587.83 +y_0=128511.202 +datum=WGS84 +units=m +no_defs'],
    [4839, '+proj=lcc +lat_1=48.66666666666666 +lat_2=53.66666666666666 +lat_0=51 +lon_0=10.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4855, '+proj=tmerc +lat_0=0 +lon_0=5.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4856, '+proj=tmerc +lat_0=0 +lon_0=6.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4857, '+proj=tmerc +lat_0=0 +lon_0=7.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4858, '+proj=tmerc +lat_0=0 +lon_0=8.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4859, '+proj=tmerc +lat_0=0 +lon_0=9.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4860, '+proj=tmerc +lat_0=0 +lon_0=10.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4861, '+proj=tmerc +lat_0=0 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4862, '+proj=tmerc +lat_0=0 +lon_0=12.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4863, '+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4864, '+proj=tmerc +lat_0=0 +lon_0=14.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4865, '+proj=tmerc +lat_0=0 +lon_0=15.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4866, '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4867, '+proj=tmerc +lat_0=0 +lon_0=17.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4868, '+proj=tmerc +lat_0=0 +lon_0=18.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4869, '+proj=tmerc +lat_0=0 +lon_0=19.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4870, '+proj=tmerc +lat_0=0 +lon_0=20.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4871, '+proj=tmerc +lat_0=0 +lon_0=21.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4872, '+proj=tmerc +lat_0=0 +lon_0=22.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4873, '+proj=tmerc +lat_0=0 +lon_0=23.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4874, '+proj=tmerc +lat_0=0 +lon_0=24.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4875, '+proj=tmerc +lat_0=0 +lon_0=25.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4876, '+proj=tmerc +lat_0=0 +lon_0=26.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4877, '+proj=tmerc +lat_0=0 +lon_0=27.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4878, '+proj=tmerc +lat_0=0 +lon_0=28.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4879, '+proj=tmerc +lat_0=0 +lon_0=29.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4880, '+proj=tmerc +lat_0=0 +lon_0=30.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [4882, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4883, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4884, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4885, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4886, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4887, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4888, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4889, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4890, '+proj=geocent +ellps=WGS66 +units=m +no_defs'],
    [4891, '+proj=longlat +ellps=NWL9D +no_defs'],
    [4892, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4893, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4894, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4895, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4896, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4897, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4898, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4899, '+proj=geocent +ellps=intl +units=m +no_defs'],
    [4900, '+proj=longlat +ellps=intl +towgs84=-208.4058,-109.8777,-2.5764,0,0,0,0 +no_defs'],
    [4901, '+proj=longlat +a=6376523 +b=6355862.93325557 +pm=2.33720833333333 +no_defs'],
    [4902, '+proj=longlat +a=6376523 +b=6355862.93325557 +pm=paris +no_defs'],
    [4903, '+proj=longlat +a=6378298.3 +b=6356657.14266956 +pm=madrid +no_defs'],
    [4904, '+proj=longlat +ellps=bessel +towgs84=508.088,-191.042,565.223,0,0,0,0 +pm=lisbon +no_defs '],
    [4906, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4907, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4908, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4909, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4910, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4911, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4912, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4913, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4914, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4915, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4916, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4917, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4918, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4919, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4920, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4921, '+proj=longlat +ellps=GRS80 +no_defs'],
    [4922, '+proj=geocent +a=6378136 +b=6356751.36174571 +units=m +no_defs'],
    [4923, '+proj=longlat +a=6378136 +rf=298.257839303 +towgs84=0,0,1.5,0,0,0.076,0 +no_defs'],
    [4924, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4925, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4926, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4927, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4928, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4929, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4930, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4931, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4932, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4933, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4934, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4935, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4936, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4937, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4938, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4939, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4940, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4941, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4942, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4943, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4944, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4945, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4946, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4947, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4948, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4949, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4950, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4951, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4952, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4953, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4954, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4955, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4956, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4957, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4958, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4959, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4960, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4961, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4962, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4963, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4964, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4965, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4966, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4967, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4968, '+proj=geocent +ellps=intl +units=m +no_defs'],
    [4969, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4970, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4971, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4972, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4973, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4974, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4975, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4976, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4977, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4978, '+proj=geocent +datum=WGS84 +units=m +no_defs'],
    [4979, '+proj=longlat +datum=WGS84 +no_defs'],
    [4980, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4981, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4982, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [4983, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4984, '+proj=geocent +ellps=WGS72 +units=m +no_defs'],
    [4985, '+proj=longlat +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +no_defs'],
    [4986, '+proj=geocent +ellps=WGS72 +units=m +no_defs'],
    [4987, '+proj=longlat +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +no_defs'],
    [4988, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4989, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4990, '+proj=geocent +ellps=krass +units=m +no_defs'],
    [4991, '+proj=longlat +ellps=krass +no_defs'],
    [4992, '+proj=geocent +ellps=krass +units=m +no_defs'],
    [4993, '+proj=longlat +ellps=krass +towgs84=44.585,-131.212,-39.544,0,0,0,0 +no_defs'],
    [4994, '+proj=geocent +ellps=clrk66 +units=m +no_defs'],
    [4995, '+proj=longlat +ellps=clrk66 +towgs84=-127.62,-67.24,-47.04,-3.068,4.903,1.578,-1.06 +no_defs'],
    [4996, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4997, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [4998, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [4999, '+proj=longlat +ellps=GRS80 +towgs84=0.072,-0.507,-0.245,0.0183,-0.0003,0.007,-0.0093 +no_defs'],
    [5011, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5012, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5013, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5014, '+proj=utm +zone=25 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5015, '+proj=utm +zone=26 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5016, '+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5018, '+proj=tmerc +lat_0=39.66666666666666 +lon_0=-8.131906111111112 +k=1 +x_0=0 +y_0=0 +ellps=intl +towgs84=-304.046,-60.576,103.64,0,0,0,0 +units=m +no_defs '],
    [5041, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=0 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5042, '+proj=stere +lat_0=-90 +lat_ts=-90 +lon_0=0 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5048, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5069, '+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [5070, '+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [5071, '+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5072, '+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5105, '+proj=tmerc +lat_0=58 +lon_0=5.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5106, '+proj=tmerc +lat_0=58 +lon_0=6.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5107, '+proj=tmerc +lat_0=58 +lon_0=7.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5108, '+proj=tmerc +lat_0=58 +lon_0=8.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5109, '+proj=tmerc +lat_0=58 +lon_0=9.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5110, '+proj=tmerc +lat_0=58 +lon_0=10.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5111, '+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5112, '+proj=tmerc +lat_0=58 +lon_0=12.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5113, '+proj=tmerc +lat_0=58 +lon_0=13.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5114, '+proj=tmerc +lat_0=58 +lon_0=14.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5115, '+proj=tmerc +lat_0=58 +lon_0=15.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5116, '+proj=tmerc +lat_0=58 +lon_0=16.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5117, '+proj=tmerc +lat_0=58 +lon_0=17.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5118, '+proj=tmerc +lat_0=58 +lon_0=18.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5119, '+proj=tmerc +lat_0=58 +lon_0=19.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5120, '+proj=tmerc +lat_0=58 +lon_0=20.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5121, '+proj=tmerc +lat_0=58 +lon_0=21.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5122, '+proj=tmerc +lat_0=58 +lon_0=22.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5123, '+proj=tmerc +lat_0=58 +lon_0=23.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5124, '+proj=tmerc +lat_0=58 +lon_0=24.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5125, '+proj=tmerc +lat_0=58 +lon_0=25.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5126, '+proj=tmerc +lat_0=58 +lon_0=26.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5127, '+proj=tmerc +lat_0=58 +lon_0=27.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5128, '+proj=tmerc +lat_0=58 +lon_0=28.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5129, '+proj=tmerc +lat_0=58 +lon_0=29.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5130, '+proj=tmerc +lat_0=58 +lon_0=30.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5132, '+proj=longlat +ellps=bessel +no_defs'],
    [5167, '+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5168, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=550000 +ellps=bessel +units=m +no_defs'],
    [5169, '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5170, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5171, '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5172, '+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5173, '+proj=tmerc +lat_0=38 +lon_0=125.002890277777 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5174, '+proj=tmerc +lat_0=38 +lon_0=127.002890277777 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5175, '+proj=tmerc +lat_0=38 +lon_0=127.002890277777 +k=1 +x_0=200000 +y_0=550000 +ellps=bessel +units=m +no_defs'],
    [5176, '+proj=tmerc +lat_0=38 +lon_0=129.002890277777 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5177, '+proj=tmerc +lat_0=38 +lon_0=131.002890277777 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [5178, '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=bessel +units=m +no_defs'],
    [5179, '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5180, '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5181, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5182, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=550000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5183, '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5184, '+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5185, '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5186, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5187, '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5188, '+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5221, '+proj=krovak +lat_0=49.5 +lon_0=42.5 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [5223, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=500000 +datum=WGS84 +units=m +no_defs'],
    [5228, '+proj=longlat +ellps=bessel +towgs84=572.213,85.334,461.94,4.9732,1.529,5.2484,3.5378 +no_defs '],
    [5229, '+proj=longlat +ellps=bessel +towgs84=572.213,85.334,461.94,4.9732,1.529,5.2484,3.5378 +pm=ferro +no_defs '],
    [5233, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=-0.293,766.95,87.713,0.195704,1.695068,3.473016,-0.039338 +no_defs '],
    [5234, '+proj=tmerc +lat_0=7.000480277777778 +lon_0=80.77171111111112 +k=0.9999238418 +x_0=200000 +y_0=200000 +a=6377276.345 +b=6356075.41314024 +towgs84=-97,787,86,0,0,0,0 +units=m +no_defs '],
    [5235, '+proj=tmerc +lat_0=7.000471527777778 +lon_0=80.77171308333334 +k=0.9999238418 +x_0=500000 +y_0=500000 +a=6377276.345 +b=6356075.41314024 +towgs84=-0.293,766.95,87.713,0.195704,1.695068,3.473016,-0.039338 +units=m +no_defs '],
    [5243, '+proj=lcc +lat_1=48.66666666666666 +lat_2=53.66666666666666 +lat_0=51 +lon_0=10.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5244, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5245, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5246, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5247, '+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31580995 +k=0.99984 +x_0=0 +y_0=0 +no_uoff +gamma=53.13010236111111 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5250, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5251, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5252, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5253, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5254, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5255, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5256, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5257, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5258, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5259, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5262, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5263, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5264, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5266, '+proj=tmerc +lat_0=0 +lon_0=90 +k=1 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5269, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=9500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5270, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=10500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5271, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=11500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5272, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=12500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5273, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=13500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5274, '+proj=tmerc +lat_0=0 +lon_0=42 +k=1 +x_0=14500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5275, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=15500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5292, '+proj=tmerc +lat_0=0 +lon_0=90.73333333333333 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5293, '+proj=tmerc +lat_0=0 +lon_0=89.55 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5294, '+proj=tmerc +lat_0=0 +lon_0=89.84999999999999 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5295, '+proj=tmerc +lat_0=0 +lon_0=90.03333333333333 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5296, '+proj=tmerc +lat_0=0 +lon_0=90.15000000000001 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5297, '+proj=tmerc +lat_0=0 +lon_0=91.13333333333334 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5298, '+proj=tmerc +lat_0=0 +lon_0=91.23333333333333 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5299, '+proj=tmerc +lat_0=0 +lon_0=89.34999999999999 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5300, '+proj=tmerc +lat_0=0 +lon_0=91.34999999999999 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5301, '+proj=tmerc +lat_0=0 +lon_0=89.84999999999999 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5302, '+proj=tmerc +lat_0=0 +lon_0=91.56666666666666 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5303, '+proj=tmerc +lat_0=0 +lon_0=89.06666666666666 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5304, '+proj=tmerc +lat_0=0 +lon_0=90.26666666666667 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5305, '+proj=tmerc +lat_0=0 +lon_0=89.55 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5306, '+proj=tmerc +lat_0=0 +lon_0=91.75 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5307, '+proj=tmerc +lat_0=0 +lon_0=90.5 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5308, '+proj=tmerc +lat_0=0 +lon_0=90.16666666666667 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5309, '+proj=tmerc +lat_0=0 +lon_0=90.11666666666666 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5310, '+proj=tmerc +lat_0=0 +lon_0=91.56666666666666 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5311, '+proj=tmerc +lat_0=0 +lon_0=90.86666666666666 +k=1 +x_0=250000 +y_0=-2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5316, '+proj=tmerc +lat_0=0 +lon_0=-7 +k=0.999997 +x_0=200000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5318, '+proj=tmerc +lat_0=0 +lon_0=-7 +k=0.999997 +x_0=200000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5320, '+proj=lcc +lat_1=44.5 +lat_2=54.5 +lat_0=0 +lon_0=-84 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [5321, '+proj=lcc +lat_1=44.5 +lat_2=54.5 +lat_0=0 +lon_0=-84 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5322, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5323, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5324, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5325, '+proj=lcc +lat_1=64.25 +lat_2=65.75 +lat_0=65 +lon_0=-19 +x_0=1700000 +y_0=300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5329, '+proj=merc +lon_0=3.192280555555556 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-403,684,41,0,0,0,0 +pm=jakarta +units=m +no_defs '],
    [5330, '+proj=merc +lon_0=3.192280555555556 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +pm=jakarta +units=m +no_defs '],
    [5331, '+proj=merc +lon_0=3.192280555555556 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-587.8,519.75,145.76,0,0,0,0 +pm=jakarta +units=m +no_defs '],
    [5332, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5337, '+proj=utm +zone=25 +south +ellps=intl +towgs84=-151.99,287.04,-147.45,0,0,0,0 +units=m +no_defs '],
    [5340, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5341, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5342, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5343, '+proj=tmerc +lat_0=-90 +lon_0=-72 +k=1 +x_0=1500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5344, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5345, '+proj=tmerc +lat_0=-90 +lon_0=-66 +k=1 +x_0=3500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5346, '+proj=tmerc +lat_0=-90 +lon_0=-63 +k=1 +x_0=4500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5347, '+proj=tmerc +lat_0=-90 +lon_0=-60 +k=1 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5348, '+proj=tmerc +lat_0=-90 +lon_0=-57 +k=1 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5349, '+proj=tmerc +lat_0=-90 +lon_0=-54 +k=1 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5352, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5353, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5354, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5355, '+proj=utm +zone=20 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5356, '+proj=utm +zone=19 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5357, '+proj=utm +zone=21 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5358, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5359, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5360, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5361, '+proj=utm +zone=19 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5362, '+proj=utm +zone=18 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5363, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [5364, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5365, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5367, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5368, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5369, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5370, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5371, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5372, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5373, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5379, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [5380, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5381, '+proj=longlat +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5382, '+proj=utm +zone=21 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5383, '+proj=utm +zone=22 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5387, '+proj=utm +zone=18 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5388, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5389, '+proj=utm +zone=19 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5391, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5392, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5393, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5396, '+proj=utm +zone=26 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5451, '+proj=longlat +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +no_defs '],
    [5456, '+proj=lcc +lat_1=10.46666666666667 +lat_0=10.46666666666667 +lon_0=-84.33333333333333 +k_0=0.99995696 +x_0=500000 +y_0=271820.522 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5457, '+proj=lcc +lat_1=9 +lat_0=9 +lon_0=-83.66666666666667 +k_0=0.99995696 +x_0=500000 +y_0=327987.436 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5458, '+proj=lcc +lat_1=16.8166666666666 +lat_0=16.8166666666666 +lon_0=-90.3333333333333 +k_0=0.99992226 +x_0=500000 +y_0=292209.579 +datum=NAD27 +units=m +no_defs'],
    [5459, '+proj=lcc +lat_1=14.9 +lat_0=14.9 +lon_0=-90.33333333333333 +k_0=0.99989906 +x_0=500000 +y_0=325992.681 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5460, '+proj=lcc +lat_1=13.78333333333333 +lat_0=13.78333333333333 +lon_0=-89 +k_0=0.99996704 +x_0=500000 +y_0=295809.184 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5461, '+proj=lcc +lat_1=13.86666666666667 +lat_0=13.86666666666667 +lon_0=-85.5 +k_0=0.99990314 +x_0=500000 +y_0=359891.816 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5462, '+proj=lcc +lat_1=11.73333333333333 +lat_0=11.73333333333333 +lon_0=-85.5 +k_0=0.9999222800000001 +x_0=500000 +y_0=288876.327 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5463, '+proj=utm +zone=17 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [5464, '+proj=longlat +a=6378293.64520875 +b=6356617.98767983 +no_defs'],
    [5466, '+proj=tmerc +lat_0=17.0612419444444 +lon_0=-88.6318575 +k=1 +x_0=66220.0283308276 +y_0=135779.509988529 +a=6378293.64520875 +b=6356617.98767983 +units=m +no_defs'],
    [5467, '+proj=longlat +ellps=clrk66 +no_defs'],
    [5469, '+proj=lcc +lat_1=8.41666666666666 +lat_0=8.41666666666666 +lon_0=-80 +k_0=0.99989909 +x_0=500000 +y_0=294865.303 +ellps=clrk66 +units=m +no_defs'],
    [5472, '+proj=poly +lat_0=8.25 +lon_0=-81 +x_0=914391.7962 +y_0=999404.721715486 +ellps=clrk66 +to_meter=0.9143917962 +no_defs'],
    [5479, '+proj=lcc +lat_1=-76.66666666666667 +lat_2=-79.33333333333333 +lat_0=-78 +lon_0=163 +x_0=7000000 +y_0=5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5480, '+proj=lcc +lat_1=-73.66666666666667 +lat_2=-75.33333333333333 +lat_0=-74.5 +lon_0=165 +x_0=5000000 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5481, '+proj=lcc +lat_1=-70.66666666666667 +lat_2=-72.33333333333333 +lat_0=-71.5 +lon_0=166 +x_0=3000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5482, '+proj=stere +lat_0=-90 +lat_ts=-90 +lon_0=180 +k=0.994 +x_0=5000000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5487, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5488, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5489, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5490, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5498, '+proj=longlat +datum=NAD83 +geoidgrids=g2012a_conus.gtx,g2012a_alaska.gtx,g2012a_guam.gtx,g2012a_hawaii.gtx,g2012a_puertorico.gtx,g2012a_samoa.gtx +vunits=m +no_defs'],
    [5499, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +geoidgrids=g2012a_conus.gtx,g2012a_alaska.gtx,g2012a_guam.gtx,g2012a_hawaii.gtx,g2012a_puertorico.gtx,g2012a_samoa.gtx +vunits=m +no_defs '],
    [5500, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +geoidgrids=g2012a_conus.gtx,g2012a_alaska.gtx,g2012a_guam.gtx,g2012a_hawaii.gtx,g2012a_puertorico.gtx,g2012a_samoa.gtx +vunits=m +no_defs '],
    [5513, '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs '],
    [5514, '+proj=krovak +lat_0=49.5 +lon_0=24.83333333333333 +alpha=30.28813972222222 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=589,76,480,0,0,0,0 +units=m +no_defs '],
    [5518, '+proj=tmerc +lat_0=-44 +lon_0=-176.5 +k=1 +x_0=350000 +y_0=650000 +ellps=intl +towgs84=175,-38,113,0,0,0,0 +units=m +no_defs '],
    [5519, '+proj=tmerc +lat_0=-44 +lon_0=-176.5 +k=1 +x_0=350000 +y_0=650000 +ellps=intl +towgs84=174.05,-25.49,112.57,0,0,0.554,0.2263 +units=m +no_defs '],
    [5520, '+proj=tmerc +lat_0=0 +lon_0=3 +k=1 +x_0=1500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [5523, '+proj=tmerc +lat_0=0 +lon_0=11.5 +k=0.9996 +x_0=1500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [5524, '+proj=longlat +ellps=intl +no_defs'],
    [5527, '+proj=longlat +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +no_defs '],
    [5530, '+proj=poly +lat_0=0 +lon_0=-54 +x_0=5000000 +y_0=10000000 +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5531, '+proj=utm +zone=21 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5532, '+proj=utm +zone=22 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [5533, '+proj=utm +zone=23 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5534, '+proj=utm +zone=24 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5535, '+proj=utm +zone=25 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5536, '+proj=utm +zone=21 +south +ellps=intl +units=m +no_defs'],
    [5537, '+proj=utm +zone=22 +south +ellps=intl +units=m +no_defs'],
    [5538, '+proj=utm +zone=23 +south +ellps=intl +units=m +no_defs'],
    [5539, '+proj=utm +zone=24 +south +ellps=intl +units=m +no_defs'],
    [5544, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5545, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5546, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5550, '+proj=utm +zone=54 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5551, '+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5552, '+proj=utm +zone=56 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5554, '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5555, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5556, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5558, '+proj=geocent +ellps=krass +units=m +no_defs'],
    [5559, '+proj=lcc +lat_1=16.81666666666667 +lat_0=16.81666666666667 +lon_0=-90.33333333333333 +k_0=0.99992226 +x_0=500000 +y_0=292209.579 +ellps=clrk66 +towgs84=205,96,-98,0,0,0,0 +units=m +no_defs '],
    [5560, '+proj=longlat +ellps=krass +towgs84=24,-121,-76,0,0,0,0 +no_defs'],
    [5561, '+proj=longlat +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +no_defs '],
    [5562, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5563, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5564, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5565, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5566, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5567, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5568, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5569, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5570, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5571, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5572, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5573, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=10500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5574, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=11500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5575, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=12500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5576, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5577, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5578, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5579, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5580, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5581, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5582, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5583, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [5588, '+proj=sterea +lat_0=46.5 +lon_0=-66.5 +k=0.999912 +x_0=304800 +y_0=304800 +datum=NAD27 +units=ft +no_defs'],
    [5589, '+proj=tmerc +lat_0=17.0612419444444 +lon_0=-88.6318575 +k=1 +x_0=66220.0283308276 +y_0=135779.509988529 +a=6378293.64520875 +b=6356617.98767983 +to_meter=0.3047972654 +no_defs'],
    [5591, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5592, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [5593, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [5596, '+proj=tmerc +lat_0=0 +lon_0=11.33333333333333 +k=1 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5598, '+proj=tmerc +lat_0=0 +lon_0=11.33333333333333 +k=1 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5623, '+proj=tmerc +lat_0=41.5 +lon_0=-83.6666666666666 +k=0.999942857 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [5624, '+proj=tmerc +lat_0=41.5 +lon_0=-85.75 +k=0.999909091 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [5625, '+proj=tmerc +lat_0=41.5 +lon_0=-88.75 +k=0.999909091 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [5627, '+proj=tmerc +lat_0=0 +lon_0=6 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [5628, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [5629, '+proj=utm +zone=38 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5631, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=2500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [5632, '+proj=lcc +lat_1=35 +lat_2=65 +lat_0=52 +lon_0=10 +x_0=4000000 +y_0=2800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5633, '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5634, '+proj=lcc +lat_1=35 +lat_2=65 +lat_0=52 +lon_0=10 +x_0=4000000 +y_0=2800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5635, '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5636, '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5637, '+proj=lcc +lat_1=35 +lat_2=65 +lat_0=52 +lon_0=10 +x_0=4000000 +y_0=2800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5638, '+proj=laea +lat_0=52 +lon_0=10 +x_0=4321000 +y_0=3210000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5639, '+proj=lcc +lat_1=35 +lat_2=65 +lat_0=52 +lon_0=10 +x_0=4000000 +y_0=2800000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5641, '+proj=merc +lon_0=-43 +lat_ts=-2 +x_0=5000000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5643, '+proj=lcc +lat_1=52.66666666666666 +lat_2=54.33333333333334 +lat_0=48 +lon_0=10 +x_0=815000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [5644, '+proj=utm +zone=39 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5646, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [5649, '+proj=tmerc +lat_0=0 +lon_0=3 +k=0.9996 +x_0=31500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5650, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=33500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5651, '+proj=tmerc +lat_0=0 +lon_0=3 +k=0.9996 +x_0=31500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5652, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=32500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5653, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=33500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5654, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [5655, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [5659, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=500053 +y_0=-3999820 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +units=m +no_defs '],
    [5663, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [5664, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=2500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [5665, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [5666, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5667, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5668, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5669, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5670, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [5671, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [5672, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=33.4,-146.6,-76.3,-0.359,-0.053,0.844,-0.84 +units=m +no_defs '],
    [5673, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [5674, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [5675, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=26,-121,-78,0,0,0,0 +units=m +no_defs '],
    [5676, '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [5677, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [5678, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [5679, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [5680, '+proj=tmerc +lat_0=0 +lon_0=3 +k=1 +x_0=1500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [5681, '+proj=longlat +ellps=bessel +no_defs'],
    [5682, '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5683, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5684, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5685, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [5698, '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5699, '+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5700, '+proj=utm +zone=1 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5707, '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=1200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [5708, '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=4185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [5825, '+proj=tmerc +lat_0=-35.31773627777778 +lon_0=149.0092948305555 +k=1.000086 +x_0=200000 +y_0=600000 +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [5828, '+proj=geocent +ellps=bessel +units=m +no_defs'],
    [5830, '+proj=longlat +ellps=bessel +no_defs'],
    [5832, '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +units=m +vunits=m +no_defs'],
    [5833, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +units=m +vunits=m +no_defs'],
    [5834, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +vunits=m +no_defs'],
    [5835, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +units=m +vunits=m +no_defs'],
    [5836, '+proj=utm +zone=37 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5837, '+proj=utm +zone=40 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5839, '+proj=utm +zone=17 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5842, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs'],
    [5844, '+proj=tmerc +lat_0=0 +lon_0=30 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5845, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5846, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5847, '+proj=tmerc +lat_0=0 +lon_0=13.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5848, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5849, '+proj=tmerc +lat_0=0 +lon_0=16.5 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5850, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5851, '+proj=tmerc +lat_0=0 +lon_0=14.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5852, '+proj=tmerc +lat_0=0 +lon_0=15.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5853, '+proj=tmerc +lat_0=0 +lon_0=17.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5854, '+proj=tmerc +lat_0=0 +lon_0=18.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5855, '+proj=tmerc +lat_0=0 +lon_0=20.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5856, '+proj=tmerc +lat_0=0 +lon_0=21.75 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5857, '+proj=tmerc +lat_0=0 +lon_0=23.25 +k=1 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5858, '+proj=utm +zone=22 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5875, '+proj=utm +zone=18 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5876, '+proj=utm +zone=19 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5877, '+proj=utm +zone=20 +south +ellps=aust_SA +towgs84=-67.35,3.88,-38.22,0,0,0,0 +units=m +no_defs '],
    [5879, '+proj=utm +zone=38 +south +ellps=intl +towgs84=-381.788,-57.501,-256.673,0,0,0,0 +units=m +no_defs '],
    [5880, '+proj=poly +lat_0=0 +lon_0=-54 +x_0=5000000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [5884, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [5885, '+proj=longlat +ellps=GRS80 +no_defs'],
    [5886, '+proj=longlat +ellps=GRS80 +no_defs'],
    [5887, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=0.9996 +x_0=1500000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [5890, '+proj=stere +lat_0=90 +lat_ts=70 +lon_0=90 +k=1 +x_0=0 +y_0=0 +a=6378273 +b=6356889.449 +units=m +no_defs'],
    [5896, '+proj=tmerc +lat_0=0 +lon_0=102 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs'],
    [5897, '+proj=tmerc +lat_0=0 +lon_0=105 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs'],
    [5898, '+proj=tmerc +lat_0=0 +lon_0=108 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs'],
    [5899, '+proj=tmerc +lat_0=0 +lon_0=107.75 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs'],
    [5921, '+proj=lcc +lat_1=85 +lat_2=77 +lat_0=81.317226 +lon_0=-111 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5922, '+proj=lcc +lat_1=85 +lat_2=77 +lat_0=81.317226 +lon_0=-39 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5923, '+proj=lcc +lat_1=85 +lat_2=77 +lat_0=81.317226 +lon_0=33 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5924, '+proj=lcc +lat_1=85 +lat_2=77 +lat_0=81.317226 +lon_0=105 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5925, '+proj=lcc +lat_1=85 +lat_2=77 +lat_0=81.317226 +lon_0=177 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5926, '+proj=lcc +lat_1=77 +lat_2=69 +lat_0=73.1557408611111 +lon_0=-111 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5927, '+proj=lcc +lat_1=77 +lat_2=69 +lat_0=73.1557408611111 +lon_0=-39 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5928, '+proj=lcc +lat_1=77 +lat_2=69 +lat_0=73.1557408611111 +lon_0=33 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5929, '+proj=lcc +lat_1=77 +lat_2=69 +lat_0=73.1557408611111 +lon_0=105 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5930, '+proj=lcc +lat_1=77 +lat_2=69 +lat_0=73.1557408611111 +lon_0=177 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5931, '+proj=lcc +lat_1=69 +lat_2=61 +lat_0=65.1012708888888 +lon_0=-111 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5932, '+proj=lcc +lat_1=69 +lat_2=61 +lat_0=65.1012708888888 +lon_0=-39 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5933, '+proj=lcc +lat_1=69 +lat_2=61 +lat_0=65.1012708888888 +lon_0=33 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5934, '+proj=lcc +lat_1=69 +lat_2=61 +lat_0=65.1012708888888 +lon_0=105 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5935, '+proj=lcc +lat_1=69 +lat_2=61 +lat_0=65.1012708888888 +lon_0=177 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [5936, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=-150 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5937, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=-100 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5938, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=-33 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5939, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=18 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5940, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=105 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [5942, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [5945, '+proj=tmerc +lat_0=58 +lon_0=5.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5946, '+proj=tmerc +lat_0=58 +lon_0=6.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5947, '+proj=tmerc +lat_0=58 +lon_0=7.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5948, '+proj=tmerc +lat_0=58 +lon_0=8.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5949, '+proj=tmerc +lat_0=58 +lon_0=9.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5950, '+proj=tmerc +lat_0=58 +lon_0=10.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5951, '+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5952, '+proj=tmerc +lat_0=58 +lon_0=12.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5953, '+proj=tmerc +lat_0=58 +lon_0=13.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5954, '+proj=tmerc +lat_0=58 +lon_0=14.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5955, '+proj=tmerc +lat_0=58 +lon_0=15.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5956, '+proj=tmerc +lat_0=58 +lon_0=16.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5957, '+proj=tmerc +lat_0=58 +lon_0=17.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5958, '+proj=tmerc +lat_0=58 +lon_0=18.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5959, '+proj=tmerc +lat_0=58 +lon_0=19.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5960, '+proj=tmerc +lat_0=58 +lon_0=20.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5961, '+proj=tmerc +lat_0=58 +lon_0=21.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5962, '+proj=tmerc +lat_0=58 +lon_0=22.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5963, '+proj=tmerc +lat_0=58 +lon_0=23.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5964, '+proj=tmerc +lat_0=58 +lon_0=24.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5965, '+proj=tmerc +lat_0=58 +lon_0=25.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5966, '+proj=tmerc +lat_0=58 +lon_0=26.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5967, '+proj=tmerc +lat_0=58 +lon_0=27.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5968, '+proj=tmerc +lat_0=58 +lon_0=28.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5969, '+proj=tmerc +lat_0=58 +lon_0=29.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5970, '+proj=tmerc +lat_0=58 +lon_0=30.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5971, '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5972, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5973, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5974, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5975, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [5976, '+proj=utm +zone=36 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6050, '+proj=lcc +lat_1=87 +lat_2=83.66666666666667 +lat_0=85.43711833333333 +lon_0=-30 +x_0=25500000 +y_0=1500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6051, '+proj=lcc +lat_1=83.66666666666667 +lat_2=80.33333333333333 +lat_0=82.05842488888888 +lon_0=-52 +x_0=18500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6052, '+proj=lcc +lat_1=83.66666666666667 +lat_2=80.33333333333333 +lat_0=82.05842488888888 +lon_0=-12 +x_0=20500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6053, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=-69 +x_0=29500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6054, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=-39 +x_0=31500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6055, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=-10 +x_0=33500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6056, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=-64 +x_0=20500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6057, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=-39 +x_0=22500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6058, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=-14 +x_0=24500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6059, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-62 +x_0=41500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6060, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-42 +x_0=43500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6061, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-22 +x_0=45500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6062, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-56 +x_0=26500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6063, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-38 +x_0=28500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6064, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-20 +x_0=30500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6065, '+proj=lcc +lat_1=67 +lat_2=63.66666666666666 +lat_0=65.35103930555555 +lon_0=-51 +x_0=11500000 +y_0=7500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6066, '+proj=lcc +lat_1=67 +lat_2=63.66666666666666 +lat_0=65.35103930555555 +lon_0=-34 +x_0=13500000 +y_0=7500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6067, '+proj=lcc +lat_1=63.66666666666666 +lat_2=60.33333333333334 +lat_0=62.01530688888889 +lon_0=-52 +x_0=20500000 +y_0=8500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6068, '+proj=lcc +lat_1=63.66666666666666 +lat_2=60.33333333333334 +lat_0=62.01530688888889 +lon_0=-37 +x_0=22500000 +y_0=8500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6069, '+proj=lcc +lat_1=83.66666666666667 +lat_2=80.33333333333333 +lat_0=82.05842488888888 +lon_0=16 +x_0=22500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6070, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=21 +x_0=11500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6071, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=10 +x_0=26500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6072, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=34 +x_0=28500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6073, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=14 +x_0=11500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6074, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=34 +x_0=13500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6075, '+proj=lcc +lat_1=83.6666666666666 +lat_2=80.3333333333333 +lat_0=82.0584248888888 +lon_0=53 +x_0=24500000 +y_0=2500000 +datum=WGS84 +units=m +no_defs'],
    [6076, '+proj=lcc +lat_1=83.6666666666666 +lat_2=80.3333333333333 +lat_0=82.0584248888888 +lon_0=93 +x_0=26500000 +y_0=2500000 +datum=WGS84 +units=m +no_defs'],
    [6077, '+proj=lcc +lat_1=80.3333333333333 +lat_2=77 +lat_0=78.7073375277777 +lon_0=52 +x_0=13500000 +y_0=3500000 +datum=WGS84 +units=m +no_defs'],
    [6078, '+proj=lcc +lat_1=80.3333333333333 +lat_2=77 +lat_0=78.7073375277777 +lon_0=83 +x_0=15500000 +y_0=3500000 +datum=WGS84 +units=m +no_defs'],
    [6079, '+proj=lcc +lat_1=80.3333333333333 +lat_2=77 +lat_0=78.7073375277777 +lon_0=114 +x_0=17500000 +y_0=3500000 +datum=WGS84 +units=m +no_defs'],
    [6080, '+proj=lcc +lat_1=80.3333333333333 +lat_2=77 +lat_0=78.7073375277777 +lon_0=145 +x_0=19500000 +y_0=3500000 +datum=WGS84 +units=m +no_defs'],
    [6081, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=58 +x_0=30500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6082, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=82 +x_0=32500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6083, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=106 +x_0=34500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6084, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=130 +x_0=36500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6085, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=154 +x_0=38500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6086, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=179 +x_0=40500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6087, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=54 +x_0=15500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6088, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=74 +x_0=17500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6089, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=95 +x_0=19500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6090, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=116 +x_0=21500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6091, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=137 +x_0=23500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6092, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=158 +x_0=25500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6093, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=179 +x_0=27500000 +y_0=5500000 +datum=WGS84 +units=m +no_defs'],
    [6094, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-163 +x_0=29500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6095, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-147 +x_0=31500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6096, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-165 +x_0=14500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6097, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-147 +x_0=16500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6098, '+proj=lcc +lat_1=87 +lat_2=83.66666666666667 +lat_0=85.43711833333333 +lon_0=-90 +x_0=23500000 +y_0=1500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6099, '+proj=lcc +lat_1=83.66666666666667 +lat_2=80.33333333333333 +lat_0=82.05842488888888 +lon_0=-115 +x_0=14500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6100, '+proj=lcc +lat_1=83.66666666666667 +lat_2=80.33333333333333 +lat_0=82.05842488888888 +lon_0=-75 +x_0=16500000 +y_0=2500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6101, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=-129 +x_0=25500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6102, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=-99 +x_0=27500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6103, '+proj=lcc +lat_1=80.33333333333333 +lat_2=77 +lat_0=78.70733752777778 +lon_0=-69 +x_0=29500000 +y_0=3500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6104, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=-129 +x_0=14500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6105, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=-104 +x_0=16500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6106, '+proj=lcc +lat_1=77 +lat_2=73.66666666666667 +lat_0=75.36440330555556 +lon_0=-79 +x_0=18500000 +y_0=4500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6107, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-131 +x_0=33500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6108, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-111 +x_0=35500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6109, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-91 +x_0=37500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6110, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-71 +x_0=39500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6111, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-132 +x_0=18500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6112, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-113 +x_0=20500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6113, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-94 +x_0=22500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6114, '+proj=lcc +lat_1=70.33333333333333 +lat_2=67 +lat_0=68.68747555555557 +lon_0=-75 +x_0=24500000 +y_0=6500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6115, '+proj=lcc +lat_1=87 +lat_2=83.6666666666666 +lat_0=85.4371183333333 +lon_0=30 +x_0=27500000 +y_0=1500000 +datum=WGS84 +units=m +no_defs'],
    [6116, '+proj=lcc +lat_1=87 +lat_2=83.6666666666666 +lat_0=85.4371183333333 +lon_0=90 +x_0=29500000 +y_0=1500000 +datum=WGS84 +units=m +no_defs'],
    [6117, '+proj=lcc +lat_1=87 +lat_2=83.6666666666666 +lat_0=85.4371183333333 +lon_0=150 +x_0=31500000 +y_0=1500000 +datum=WGS84 +units=m +no_defs'],
    [6118, '+proj=lcc +lat_1=87 +lat_2=83.6666666666666 +lat_0=85.4371183333333 +lon_0=-150 +x_0=21500000 +y_0=1500000 +datum=WGS84 +units=m +no_defs'],
    [6119, '+proj=lcc +lat_1=83.6666666666666 +lat_2=80.3333333333333 +lat_0=82.0584248888888 +lon_0=133 +x_0=28500000 +y_0=2500000 +datum=WGS84 +units=m +no_defs'],
    [6120, '+proj=lcc +lat_1=83.6666666666666 +lat_2=80.3333333333333 +lat_0=82.0584248888888 +lon_0=166 +x_0=10500000 +y_0=2500000 +datum=WGS84 +units=m +no_defs'],
    [6121, '+proj=lcc +lat_1=83.6666666666666 +lat_2=80.3333333333333 +lat_0=82.0584248888888 +lon_0=-154 +x_0=12500000 +y_0=2500000 +datum=WGS84 +units=m +no_defs'],
    [6122, '+proj=lcc +lat_1=80.3333333333333 +lat_2=77 +lat_0=78.7073375277777 +lon_0=176 +x_0=21500000 +y_0=3500000 +datum=WGS84 +units=m +no_defs'],
    [6123, '+proj=lcc +lat_1=80.3333333333333 +lat_2=77 +lat_0=78.7073375277777 +lon_0=-153 +x_0=23500000 +y_0=3500000 +datum=WGS84 +units=m +no_defs'],
    [6124, '+proj=lcc +lat_1=77 +lat_2=73.6666666666666 +lat_0=75.3644033055555 +lon_0=-155 +x_0=12500000 +y_0=4500000 +datum=WGS84 +units=m +no_defs'],
    [6125, '+proj=lcc +lat_1=73.66666666666667 +lat_2=70.33333333333333 +lat_0=72.02500919444445 +lon_0=-5 +x_0=47500000 +y_0=5500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6128, '+proj=utm +zone=17 +ellps=clrk66 +towgs84=-179.483,-69.379,-27.584,-7.862,8.163,6.042,-13.925 +units=ft +no_defs '],
    [6129, '+proj=utm +zone=17 +ellps=clrk66 +towgs84=8.853,-52.644,180.304,-0.393,-2.323,2.96,-24.081 +units=ft +no_defs '],
    [6133, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6134, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [6135, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [6141, '+proj=lcc +lat_1=19.33333333333333 +lat_2=19.7 +lat_0=19.33333333333333 +lon_0=80.56666666666666 +x_0=899160 +y_0=579120 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [6144, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [6145, '+proj=tmerc +lat_0=58 +lon_0=5.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6146, '+proj=tmerc +lat_0=58 +lon_0=6.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6147, '+proj=tmerc +lat_0=58 +lon_0=7.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6148, '+proj=tmerc +lat_0=58 +lon_0=8.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6149, '+proj=tmerc +lat_0=58 +lon_0=9.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6150, '+proj=tmerc +lat_0=58 +lon_0=10.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6151, '+proj=tmerc +lat_0=58 +lon_0=11.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6152, '+proj=tmerc +lat_0=58 +lon_0=12.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6153, '+proj=tmerc +lat_0=58 +lon_0=13.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6154, '+proj=tmerc +lat_0=58 +lon_0=14.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6155, '+proj=tmerc +lat_0=58 +lon_0=15.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6156, '+proj=tmerc +lat_0=58 +lon_0=16.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6157, '+proj=tmerc +lat_0=58 +lon_0=17.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6158, '+proj=tmerc +lat_0=58 +lon_0=18.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6159, '+proj=tmerc +lat_0=58 +lon_0=19.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6160, '+proj=tmerc +lat_0=58 +lon_0=20.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6161, '+proj=tmerc +lat_0=58 +lon_0=21.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6162, '+proj=tmerc +lat_0=58 +lon_0=22.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6163, '+proj=tmerc +lat_0=58 +lon_0=23.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6164, '+proj=tmerc +lat_0=58 +lon_0=24.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6165, '+proj=tmerc +lat_0=58 +lon_0=25.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6166, '+proj=tmerc +lat_0=58 +lon_0=26.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6167, '+proj=tmerc +lat_0=58 +lon_0=27.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6168, '+proj=tmerc +lat_0=58 +lon_0=28.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6169, '+proj=tmerc +lat_0=58 +lon_0=29.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6170, '+proj=tmerc +lat_0=58 +lon_0=30.5 +k=1 +x_0=100000 +y_0=1000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6171, '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6172, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6173, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6174, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6175, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6176, '+proj=utm +zone=36 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6190, '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +vunits=m +no_defs '],
    [6200, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=609601.219202438 +y_0=0 +k_0=1.0000382 +ellps=clrk66 +towgs84=-8,160,176,0,0,0,0 +units=us-ft +no_defs'],
    [6201, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3333333333333 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=609601.219202438 +y_0=0 +k_0=1.0000382 +ellps=clrk66 +towgs84=-8,160,176,0,0,0,0 +units=us-ft +no_defs'],
    [6202, '+proj=lcc +lat_0=41.5 +lon_0=-84.3333333333333 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=609601.219202438 +y_0=0 +k_0=1.0000382 +ellps=clrk66 +towgs84=-8,160,176,0,0,0,0 +units=us-ft +no_defs'],
    [6204, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [6207, '+proj=longlat +a=6377276.345 +b=6356075.41314024 +towgs84=293.17,726.18,245.36,0,0,0,0 +no_defs '],
    [6210, '+proj=utm +zone=23 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6211, '+proj=utm +zone=24 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6307, '+proj=lcc +lat_1=18.4333333333333 +lat_2=18.0333333333333 +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +x_0=200000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [6309, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [6310, '+proj=longlat +ellps=WGS84 +towgs84=8.846,-4.394,-1.122,-0.00237,-0.146528,0.130428,0.783926 +no_defs'],
    [6311, '+proj=longlat +ellps=WGS84 +towgs84=8.846,-4.394,-1.122,-0.00237,-0.146528,0.130428,0.783926 +no_defs '],
    [6312, '+proj=tmerc +lat_0=0 +lon_0=33 +k=0.99995 +x_0=200000 +y_0=-3500000 +ellps=WGS84 +towgs84=8.846,-4.394,-1.122,-0.00237,-0.146528,0.130428,0.783926 +units=m +no_defs '],
    [6316, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=7500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs '],
    [6317, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6318, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6319, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6320, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6321, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6322, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6323, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6324, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6325, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6328, '+proj=utm +zone=59 +ellps=GRS80 +units=m +no_defs'],
    [6329, '+proj=utm +zone=60 +ellps=GRS80 +units=m +no_defs'],
    [6330, '+proj=utm +zone=1 +ellps=GRS80 +units=m +no_defs'],
    [6331, '+proj=utm +zone=2 +ellps=GRS80 +units=m +no_defs'],
    [6332, '+proj=utm +zone=3 +ellps=GRS80 +units=m +no_defs'],
    [6333, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [6334, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [6335, '+proj=utm +zone=6 +ellps=GRS80 +units=m +no_defs'],
    [6336, '+proj=utm +zone=7 +ellps=GRS80 +units=m +no_defs'],
    [6337, '+proj=utm +zone=8 +ellps=GRS80 +units=m +no_defs'],
    [6338, '+proj=utm +zone=9 +ellps=GRS80 +units=m +no_defs'],
    [6339, '+proj=utm +zone=10 +ellps=GRS80 +units=m +no_defs'],
    [6340, '+proj=utm +zone=11 +ellps=GRS80 +units=m +no_defs'],
    [6341, '+proj=utm +zone=12 +ellps=GRS80 +units=m +no_defs'],
    [6342, '+proj=utm +zone=13 +ellps=GRS80 +units=m +no_defs'],
    [6343, '+proj=utm +zone=14 +ellps=GRS80 +units=m +no_defs'],
    [6344, '+proj=utm +zone=15 +ellps=GRS80 +units=m +no_defs'],
    [6345, '+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs'],
    [6346, '+proj=utm +zone=17 +ellps=GRS80 +units=m +no_defs'],
    [6347, '+proj=utm +zone=18 +ellps=GRS80 +units=m +no_defs'],
    [6348, '+proj=utm +zone=19 +ellps=GRS80 +units=m +no_defs'],
    [6349, '+proj=longlat +ellps=GRS80 +geoidgrids=g2012a_conus.gtx,g2012a_alaska.gtx,g2012a_guam.gtx,g2012a_hawaii.gtx,g2012a_puertorico.gtx,g2012a_samoa.gtx +vunits=m +no_defs'],
    [6350, '+proj=aea +lat_1=29.5 +lat_2=45.5 +lat_0=23 +lon_0=-96 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6351, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=-163 +x_0=29500000 +y_0=5500000 +ellps=GRS80 +units=m +no_defs'],
    [6352, '+proj=lcc +lat_1=73.6666666666666 +lat_2=70.3333333333333 +lat_0=72.0250091944444 +lon_0=-147 +x_0=31500000 +y_0=5500000 +ellps=GRS80 +units=m +no_defs'],
    [6353, '+proj=lcc +lat_1=70.3333333333333 +lat_2=67 +lat_0=68.6874755555555 +lon_0=-165 +x_0=14500000 +y_0=6500000 +ellps=GRS80 +units=m +no_defs'],
    [6354, '+proj=lcc +lat_1=70.3333333333333 +lat_2=67 +lat_0=68.6874755555555 +lon_0=-147 +x_0=16500000 +y_0=6500000 +ellps=GRS80 +units=m +no_defs'],
    [6355, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6356, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6362, '+proj=lcc +lat_1=17.5 +lat_2=29.5 +lat_0=12 +lon_0=-102 +x_0=2500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6363, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6364, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [6365, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [6366, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6367, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6368, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6369, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6370, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6371, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6372, '+proj=lcc +lat_1=17.5 +lat_2=29.5 +lat_0=12 +lon_0=-102 +x_0=2500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6381, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6382, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6383, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6384, '+proj=tmerc +lat_0=0 +lon_0=30 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6385, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6386, '+proj=tmerc +lat_0=0 +lon_0=36 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6387, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=300000 +y_0=0 +ellps=krass +towgs84=25,-141,-78.5,0,0.35,0.736,0 +units=m +no_defs '],
    [6391, '+proj=lcc +lat_1=19.33333333333333 +lat_2=19.7 +lat_0=19.33333333333333 +lon_0=-80.56666666666666 +x_0=899160 +y_0=579120 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +no_defs '],
    [6393, '+proj=aea +lat_1=55 +lat_2=65 +lat_0=50 +lon_0=-154 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6394, '+proj=omerc +lat_0=57 +lonc=-133.666666666666 +alpha=323.130102361111 +k=0.9999 +x_0=5000000 +y_0=-5000000 +no_uoff +gamma=323.130102361111 +ellps=GRS80 +units=m +no_defs'],
    [6395, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6396, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6397, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6398, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6399, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6400, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6401, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6402, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6403, '+proj=lcc +lat_1=53.8333333333333 +lat_2=51.8333333333333 +lat_0=51 +lon_0=-176 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6404, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666666 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6405, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666666 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6406, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666666 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6407, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666666 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6408, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6409, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6410, '+proj=lcc +lat_1=36.2333333333333 +lat_2=34.9333333333333 +lat_0=34.3333333333333 +lon_0=-92 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6411, '+proj=lcc +lat_1=36.2333333333333 +lat_2=34.9333333333333 +lat_0=34.3333333333333 +lon_0=-92 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6412, '+proj=lcc +lat_1=34.7666666666666 +lat_2=33.3 +lat_0=32.6666666666666 +lon_0=-92 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [6413, '+proj=lcc +lat_1=34.7666666666666 +lat_2=33.3 +lat_0=32.6666666666666 +lon_0=-92 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [6414, '+proj=aea +lat_1=34 +lat_2=40.5 +lat_0=0 +lon_0=-120 +x_0=0 +y_0=-4000000 +ellps=GRS80 +units=m +no_defs'],
    [6415, '+proj=lcc +lat_1=41.6666666666666 +lat_2=40 +lat_0=39.3333333333333 +lon_0=-122 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6416, '+proj=lcc +lat_1=41.6666666666666 +lat_2=40 +lat_0=39.3333333333333 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6417, '+proj=lcc +lat_1=39.8333333333333 +lat_2=38.3333333333333 +lat_0=37.6666666666666 +lon_0=-122 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6418, '+proj=lcc +lat_1=39.8333333333333 +lat_2=38.3333333333333 +lat_0=37.6666666666666 +lon_0=-122 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6419, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.0666666666666 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6420, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.0666666666666 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6421, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.3333333333333 +lon_0=-119 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6422, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.3333333333333 +lon_0=-119 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6423, '+proj=lcc +lat_1=35.4666666666666 +lat_2=34.0333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6424, '+proj=lcc +lat_1=35.4666666666666 +lat_2=34.0333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6425, '+proj=lcc +lat_1=33.8833333333333 +lat_2=32.7833333333333 +lat_0=32.1666666666666 +lon_0=-116.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6426, '+proj=lcc +lat_1=33.8833333333333 +lat_2=32.7833333333333 +lat_0=32.1666666666666 +lon_0=-116.25 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6427, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.8333333333333 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [6428, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.8333333333333 +lon_0=-105.5 +x_0=914401.828803657 +y_0=304800.609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [6429, '+proj=lcc +lat_1=40.7833333333333 +lat_2=39.7166666666666 +lat_0=39.3333333333333 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [6430, '+proj=lcc +lat_1=40.7833333333333 +lat_2=39.7166666666666 +lat_0=39.3333333333333 +lon_0=-105.5 +x_0=914401.828803657 +y_0=304800.609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [6431, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.2333333333333 +lat_0=36.6666666666666 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [6432, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.2333333333333 +lat_0=36.6666666666666 +lon_0=-105.5 +x_0=914401.828803657 +y_0=304800.609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [6433, '+proj=lcc +lat_1=41.8666666666666 +lat_2=41.2 +lat_0=40.8333333333333 +lon_0=-72.75 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +units=m +no_defs'],
    [6434, '+proj=lcc +lat_1=41.8666666666666 +lat_2=41.2 +lat_0=40.8333333333333 +lon_0=-72.75 +x_0=304800.609601219 +y_0=152400.304800609 +ellps=GRS80 +units=us-ft +no_defs'],
    [6435, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666666 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6436, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666666 +k=0.999995 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6437, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6438, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6439, '+proj=aea +lat_1=24 +lat_2=31.5 +lat_0=24 +lon_0=-84 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6440, '+proj=lcc +lat_1=30.75 +lat_2=29.5833333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6441, '+proj=lcc +lat_1=30.75 +lat_2=29.5833333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6442, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6443, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6444, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666666 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6445, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666666 +k=0.9999 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6446, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666666 +k=0.9999 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6447, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666666 +k=0.9999 +x_0=699999.999898399 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6448, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-114 +k=0.999947367999999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6449, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-114 +k=0.999947367999999 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6450, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-112.166666666666 +k=0.999947367999999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6451, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-112.166666666666 +k=0.999947367999999 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6452, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6453, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6454, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-88.3333333333333 +k=0.999974999999999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6455, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-88.3333333333333 +k=0.999974999999999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6456, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-90.1666666666666 +k=0.999941177 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6457, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-90.1666666666666 +k=0.999941177 +x_0=699999.99998984 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6458, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666666 +k=0.999966667 +x_0=100000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [6459, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666666 +k=0.999966667 +x_0=99999.9998983997 +y_0=249999.999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6460, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=900000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [6461, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=900000 +y_0=249999.999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6462, '+proj=lcc +lat_1=43.2666666666666 +lat_2=42.0666666666666 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [6463, '+proj=lcc +lat_1=43.2666666666666 +lat_2=42.0666666666666 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=999999.99998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [6464, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.6166666666666 +lat_0=40 +lon_0=-93.5 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6465, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.6166666666666 +lat_0=40 +lon_0=-93.5 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6466, '+proj=lcc +lat_1=39.7833333333333 +lat_2=38.7166666666666 +lat_0=38.3333333333333 +lon_0=-98 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6467, '+proj=lcc +lat_1=39.7833333333333 +lat_2=38.7166666666666 +lat_0=38.3333333333333 +lon_0=-98 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6468, '+proj=lcc +lat_1=38.5666666666666 +lat_2=37.2666666666666 +lat_0=36.6666666666666 +lon_0=-98.5 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [6469, '+proj=lcc +lat_1=38.5666666666666 +lat_2=37.2666666666666 +lat_0=36.6666666666666 +lon_0=-98.5 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [6470, '+proj=lcc +lat_1=37.9666666666666 +lat_2=38.9666666666666 +lat_0=37.5 +lon_0=-84.25 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6471, '+proj=lcc +lat_1=37.9666666666666 +lat_2=38.9666666666666 +lat_0=37.5 +lon_0=-84.25 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6472, '+proj=lcc +lat_1=37.0833333333333 +lat_2=38.6666666666666 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [6473, '+proj=lcc +lat_1=37.0833333333333 +lat_2=38.6666666666666 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=1500000 +y_0=999999.999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6474, '+proj=lcc +lat_1=37.9333333333333 +lat_2=36.7333333333333 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=500000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [6475, '+proj=lcc +lat_1=37.9333333333333 +lat_2=36.7333333333333 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=500000.0001016 +y_0=500000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6476, '+proj=lcc +lat_1=32.6666666666666 +lat_2=31.1666666666666 +lat_0=30.5 +lon_0=-92.5 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6477, '+proj=lcc +lat_1=32.6666666666666 +lat_2=31.1666666666666 +lat_0=30.5 +lon_0=-92.5 +x_0=999999.99998984 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6478, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.3333333333333 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6479, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.3333333333333 +x_0=999999.99998984 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6480, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6481, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6482, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6483, '+proj=tmerc +lat_0=43.6666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6484, '+proj=tmerc +lat_0=43.6666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6485, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666666 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6486, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666666 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6487, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.6666666666666 +lon_0=-77 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6488, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.6666666666666 +lon_0=-77 +x_0=399999.999898399 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6489, '+proj=lcc +lat_1=41.4833333333333 +lat_2=41.2833333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6490, '+proj=lcc +lat_1=41.4833333333333 +lat_2=41.2833333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6491, '+proj=lcc +lat_1=42.6833333333333 +lat_2=41.7166666666666 +lat_0=41 +lon_0=-71.5 +x_0=200000 +y_0=750000 +ellps=GRS80 +units=m +no_defs'],
    [6492, '+proj=lcc +lat_1=42.6833333333333 +lat_2=41.7166666666666 +lat_0=41 +lon_0=-71.5 +x_0=200000.0001016 +y_0=750000 +ellps=GRS80 +units=us-ft +no_defs'],
    [6493, '+proj=lcc +lat_1=45.7 +lat_2=44.1833333333333 +lat_0=43.3166666666666 +lon_0=-84.3666666666666 +x_0=6000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6494, '+proj=lcc +lat_1=45.7 +lat_2=44.1833333333333 +lat_0=43.3166666666666 +lon_0=-84.3666666666666 +x_0=5999999.999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6495, '+proj=lcc +lat_1=47.0833333333333 +lat_2=45.4833333333333 +lat_0=44.7833333333333 +lon_0=-87 +x_0=8000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6496, '+proj=lcc +lat_1=47.0833333333333 +lat_2=45.4833333333333 +lat_0=44.7833333333333 +lon_0=-87 +x_0=7999999.999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6497, '+proj=omerc +lat_0=45.3091666666666 +lonc=-86 +alpha=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +no_uoff +gamma=337.25556 +ellps=GRS80 +units=m +no_defs'],
    [6498, '+proj=lcc +lat_1=43.6666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.3666666666666 +x_0=4000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6499, '+proj=lcc +lat_1=43.6666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.3666666666666 +x_0=3999999.999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6500, '+proj=lcc +lat_1=47.05 +lat_2=45.6166666666666 +lat_0=45 +lon_0=-94.25 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [6501, '+proj=lcc +lat_1=47.05 +lat_2=45.6166666666666 +lat_0=45 +lon_0=-94.25 +x_0=800000.000010159 +y_0=99999.9999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6502, '+proj=lcc +lat_1=48.6333333333333 +lat_2=47.0333333333333 +lat_0=46.5 +lon_0=-93.0999999999999 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [6503, '+proj=lcc +lat_1=48.6333333333333 +lat_2=47.0333333333333 +lat_0=46.5 +lon_0=-93.0999999999999 +x_0=800000.000010159 +y_0=99999.9999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6504, '+proj=lcc +lat_1=45.2166666666666 +lat_2=43.7833333333333 +lat_0=43 +lon_0=-94 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [6505, '+proj=lcc +lat_1=45.2166666666666 +lat_2=43.7833333333333 +lat_0=43 +lon_0=-94 +x_0=800000.000010159 +y_0=99999.9999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6506, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6507, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6508, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +ellps=GRS80 +units=m +no_defs'],
    [6509, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6510, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=699999.999898399 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6511, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6512, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6513, '+proj=tmerc +lat_0=36.1666666666666 +lon_0=-94.5 +k=0.999941177 +x_0=850000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6514, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6515, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6516, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.8333333333333 +lon_0=-100 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6517, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [6518, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666666 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +units=m +no_defs'],
    [6519, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666666 +k=0.9999 +x_0=500000.00001016 +y_0=6000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [6520, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +ellps=GRS80 +units=m +no_defs'],
    [6521, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000.00001016 +y_0=8000000.00001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6522, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [6523, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000.000010159 +y_0=3999999.99998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [6524, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666666 +k=0.999966667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6525, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666666 +k=0.999966667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6526, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6527, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6528, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6529, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6530, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6531, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6532, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916667 +x_0=830000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6533, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916667 +x_0=830000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6534, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6535, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=249999.999898399 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6536, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6537, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6538, '+proj=lcc +lat_1=41.0333333333333 +lat_2=40.6666666666666 +lat_0=40.1666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6539, '+proj=lcc +lat_1=41.0333333333333 +lat_2=40.6666666666666 +lat_0=40.1666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6540, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6541, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6542, '+proj=lcc +lat_1=36.1666666666666 +lat_2=34.3333333333333 +lat_0=33.75 +lon_0=-79 +x_0=609601.22 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6543, '+proj=lcc +lat_1=36.1666666666666 +lat_2=34.3333333333333 +lat_0=33.75 +lon_0=-79 +x_0=609601.219202438 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6544, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.4333333333333 +lat_0=47 +lon_0=-100.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6545, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.4333333333333 +lat_0=47 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6546, '+proj=lcc +lat_1=47.4833333333333 +lat_2=46.1833333333333 +lat_0=45.6666666666666 +lon_0=-100.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6547, '+proj=lcc +lat_1=47.4833333333333 +lat_2=46.1833333333333 +lat_0=45.6666666666666 +lon_0=-100.5 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6548, '+proj=lcc +lat_1=41.7 +lat_2=40.4333333333333 +lat_0=39.6666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6549, '+proj=lcc +lat_1=41.7 +lat_2=40.4333333333333 +lat_0=39.6666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6550, '+proj=lcc +lat_1=40.0333333333333 +lat_2=38.7333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6551, '+proj=lcc +lat_1=40.0333333333333 +lat_2=38.7333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6552, '+proj=lcc +lat_1=36.7666666666666 +lat_2=35.5666666666666 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6553, '+proj=lcc +lat_1=36.7666666666666 +lat_2=35.5666666666666 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6554, '+proj=lcc +lat_1=35.2333333333333 +lat_2=33.9333333333333 +lat_0=33.3333333333333 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6555, '+proj=lcc +lat_1=35.2333333333333 +lat_2=33.9333333333333 +lat_0=33.3333333333333 +lon_0=-98 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6556, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6557, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=399999.9999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6558, '+proj=lcc +lat_1=46 +lat_2=44.3333333333333 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6559, '+proj=lcc +lat_1=46 +lat_2=44.3333333333333 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=2500000.0001424 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6560, '+proj=lcc +lat_1=44 +lat_2=42.3333333333333 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6561, '+proj=lcc +lat_1=44 +lat_2=42.3333333333333 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=1500000.0001464 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6562, '+proj=lcc +lat_1=41.95 +lat_2=40.8833333333333 +lat_0=40.1666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6563, '+proj=lcc +lat_1=41.95 +lat_2=40.8833333333333 +lat_0=40.1666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6564, '+proj=lcc +lat_1=40.9666666666666 +lat_2=39.9333333333333 +lat_0=39.3333333333333 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6565, '+proj=lcc +lat_1=40.9666666666666 +lat_2=39.9333333333333 +lat_0=39.3333333333333 +lon_0=-77.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6566, '+proj=lcc +lat_1=18.4333333333333 +lat_2=18.0333333333333 +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +x_0=200000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [6567, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6568, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=99999.9999898399 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6569, '+proj=lcc +lat_1=34.8333333333333 +lat_2=32.5 +lat_0=31.8333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6570, '+proj=lcc +lat_1=34.8333333333333 +lat_2=32.5 +lat_0=31.8333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6571, '+proj=lcc +lat_1=45.6833333333333 +lat_2=44.4166666666666 +lat_0=43.8333333333333 +lon_0=-100 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6572, '+proj=lcc +lat_1=45.6833333333333 +lat_2=44.4166666666666 +lat_0=43.8333333333333 +lon_0=-100 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6573, '+proj=lcc +lat_1=44.4 +lat_2=42.8333333333333 +lat_0=42.3333333333333 +lon_0=-100.333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6574, '+proj=lcc +lat_1=44.4 +lat_2=42.8333333333333 +lat_0=42.3333333333333 +lon_0=-100.333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6575, '+proj=lcc +lat_1=36.4166666666666 +lat_2=35.25 +lat_0=34.3333333333333 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6576, '+proj=lcc +lat_1=36.4166666666666 +lat_2=35.25 +lat_0=34.3333333333333 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6577, '+proj=lcc +lat_1=31.8833333333333 +lat_2=30.1166666666666 +lat_0=29.6666666666666 +lon_0=-100.333333333333 +x_0=700000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [6578, '+proj=lcc +lat_1=31.8833333333333 +lat_2=30.1166666666666 +lat_0=29.6666666666666 +lon_0=-100.333333333333 +x_0=699999.999898399 +y_0=3000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [6579, '+proj=aea +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=6000000 +ellps=GRS80 +units=m +no_defs'],
    [6580, '+proj=lcc +lat_1=27.5 +lat_2=35 +lat_0=18 +lon_0=-100 +x_0=1500000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [6581, '+proj=lcc +lat_1=36.1833333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [6582, '+proj=lcc +lat_1=36.1833333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000.0001016 +y_0=999999.999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6583, '+proj=lcc +lat_1=33.9666666666666 +lat_2=32.1333333333333 +lat_0=31.6666666666666 +lon_0=-98.5 +x_0=600000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [6584, '+proj=lcc +lat_1=33.9666666666666 +lat_2=32.1333333333333 +lat_0=31.6666666666666 +lon_0=-98.5 +x_0=600000 +y_0=2000000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6585, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.6666666666666 +lon_0=-98.5 +x_0=300000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [6586, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.6666666666666 +lon_0=-98.5 +x_0=300000 +y_0=5000000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6587, '+proj=lcc +lat_1=30.2833333333333 +lat_2=28.3833333333333 +lat_0=27.8333333333333 +lon_0=-99 +x_0=600000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [6588, '+proj=lcc +lat_1=30.2833333333333 +lat_2=28.3833333333333 +lat_0=27.8333333333333 +lon_0=-99 +x_0=600000 +y_0=3999999.9998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [6589, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6590, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6591, '+proj=lcc +lat_1=37 +lat_2=39.5 +lat_0=36 +lon_0=-79.5 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6592, '+proj=lcc +lat_1=39.2 +lat_2=38.0333333333333 +lat_0=37.6666666666666 +lon_0=-78.5 +x_0=3500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [6593, '+proj=lcc +lat_1=39.2 +lat_2=38.0333333333333 +lat_0=37.6666666666666 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=2000000.0001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6594, '+proj=lcc +lat_1=37.9666666666666 +lat_2=36.7666666666666 +lat_0=36.3333333333333 +lon_0=-78.5 +x_0=3500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [6595, '+proj=lcc +lat_1=37.9666666666666 +lat_2=36.7666666666666 +lat_0=36.3333333333333 +lon_0=-78.5 +x_0=3500000.0001016 +y_0=999999.999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6596, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6597, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.833333333333 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6598, '+proj=lcc +lat_1=47.3333333333333 +lat_2=45.8333333333333 +lat_0=45.3333333333333 +lon_0=-120.5 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6599, '+proj=lcc +lat_1=47.3333333333333 +lat_2=45.8333333333333 +lat_0=45.3333333333333 +lon_0=-120.5 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6600, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6601, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6602, '+proj=lcc +lat_1=38.8833333333333 +lat_2=37.4833333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6603, '+proj=lcc +lat_1=38.8833333333333 +lat_2=37.4833333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6604, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.83333333333334 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6605, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.8333333333333 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6606, '+proj=lcc +lat_1=46.7666666666666 +lat_2=45.5666666666666 +lat_0=45.1666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6607, '+proj=lcc +lat_1=46.7666666666666 +lat_2=45.5666666666666 +lat_0=45.1666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6608, '+proj=lcc +lat_1=44.0666666666666 +lat_2=42.7333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6609, '+proj=lcc +lat_1=44.0666666666666 +lat_2=42.7333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6610, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=520000 +y_0=-4480000 +ellps=GRS80 +units=m +no_defs'],
    [6611, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666666 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6612, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666666 +k=0.9999375 +x_0=200000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6613, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [6614, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=399999.99998984 +y_0=99999.9999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6615, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [6616, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000.000010159 +y_0=99999.9999898399 +ellps=GRS80 +units=us-ft +no_defs'],
    [6617, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6618, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6619, '+proj=lcc +lat_1=40.65 +lat_2=39.0166666666666 +lat_0=38.3333333333333 +lon_0=-111.5 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [6620, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.7166666666666 +lat_0=40.3333333333333 +lon_0=-111.5 +x_0=500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [6621, '+proj=lcc +lat_1=38.35 +lat_2=37.2166666666666 +lat_0=36.6666666666666 +lon_0=-111.5 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [6622, '+proj=lcc +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6623, '+proj=aea +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [6624, '+proj=aea +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6625, '+proj=lcc +lat_1=40.65 +lat_2=39.0166666666666 +lat_0=38.3333333333333 +lon_0=-111.5 +x_0=500000.00001016 +y_0=2000000.00001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [6626, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.7166666666666 +lat_0=40.3333333333333 +lon_0=-111.5 +x_0=500000.00001016 +y_0=999999.99998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [6627, '+proj=lcc +lat_1=38.35 +lat_2=37.2166666666666 +lat_0=36.6666666666666 +lon_0=-111.5 +x_0=500000.00001016 +y_0=3000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [6628, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6629, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666666 +k=0.999966667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6630, '+proj=tmerc +lat_0=21.1666666666666 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6631, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6632, '+proj=tmerc +lat_0=21.6666666666666 +lon_0=-160.166666666666 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6633, '+proj=tmerc +lat_0=21.1666666666666 +lon_0=-158 +k=0.99999 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6634, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [6635, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [6636, '+proj=utm +zone=2 +south +ellps=GRS80 +units=m +no_defs'],
    [6637, '+proj=tmerc +lat_0=13.5 +lon_0=144.75 +k=1 +x_0=100000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [6646, '+proj=tmerc +lat_0=29.02626833333333 +lon_0=46.5 +k=0.9994 +x_0=800000 +y_0=0 +ellps=clrk80 +towgs84=70.995,-335.916,262.898,0,0,0,0 +units=m +no_defs '],
    [6649, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [6650, '+proj=utm +zone=7 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6651, '+proj=utm +zone=8 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6652, '+proj=utm +zone=9 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6653, '+proj=utm +zone=10 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6654, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6655, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6656, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6657, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6658, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6659, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6660, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6661, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6662, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6663, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6664, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6665, '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [6666, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6667, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6668, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6669, '+proj=tmerc +lat_0=33 +lon_0=129.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6670, '+proj=tmerc +lat_0=33 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6671, '+proj=tmerc +lat_0=36 +lon_0=132.166666666666 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6672, '+proj=tmerc +lat_0=33 +lon_0=133.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6673, '+proj=tmerc +lat_0=36 +lon_0=134.333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6674, '+proj=tmerc +lat_0=36 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6675, '+proj=tmerc +lat_0=36 +lon_0=137.166666666666 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6676, '+proj=tmerc +lat_0=36 +lon_0=138.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6677, '+proj=tmerc +lat_0=36 +lon_0=139.833333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6678, '+proj=tmerc +lat_0=40 +lon_0=140.833333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6679, '+proj=tmerc +lat_0=44 +lon_0=140.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6680, '+proj=tmerc +lat_0=44 +lon_0=142.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6681, '+proj=tmerc +lat_0=44 +lon_0=144.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6682, '+proj=tmerc +lat_0=26 +lon_0=142 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6683, '+proj=tmerc +lat_0=26 +lon_0=127.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6684, '+proj=tmerc +lat_0=26 +lon_0=124 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6685, '+proj=tmerc +lat_0=26 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6686, '+proj=tmerc +lat_0=20 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6687, '+proj=tmerc +lat_0=26 +lon_0=154 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6688, '+proj=utm +zone=51 +ellps=GRS80 +units=m +no_defs'],
    [6689, '+proj=utm +zone=52 +ellps=GRS80 +units=m +no_defs'],
    [6690, '+proj=utm +zone=53 +ellps=GRS80 +units=m +no_defs'],
    [6691, '+proj=utm +zone=54 +ellps=GRS80 +units=m +no_defs'],
    [6692, '+proj=utm +zone=55 +ellps=GRS80 +units=m +no_defs'],
    [6696, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [6697, '+proj=longlat +ellps=GRS80 +vunits=m +no_defs'],
    [6700, '+proj=longlat +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +vunits=m +no_defs '],
    [6703, '+proj=tmerc +lat_0=0 +lon_0=-60 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs'],
    [6704, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6705, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [6706, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [6707, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6708, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6709, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6720, '+proj=tmerc +lat_0=0 +lon_0=105.625 +k=1.000024 +x_0=50000 +y_0=1300000 +datum=WGS84 +units=m +no_defs'],
    [6721, '+proj=tmerc +lat_0=0 +lon_0=105.625 +k=1.00002514 +x_0=50000 +y_0=1300000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6722, '+proj=tmerc +lat_0=0 +lon_0=96.875 +k=1 +x_0=50000 +y_0=1400000 +datum=WGS84 +units=m +no_defs'],
    [6723, '+proj=tmerc +lat_0=0 +lon_0=96.875 +k=0.99999387 +x_0=50000 +y_0=1500000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6732, '+proj=utm +zone=41 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6733, '+proj=utm +zone=42 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6734, '+proj=utm +zone=43 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6735, '+proj=utm +zone=44 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6736, '+proj=utm +zone=46 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6737, '+proj=utm +zone=47 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6738, '+proj=utm +zone=59 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6781, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6782, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6783, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6784, '+proj=tmerc +lat_0=44.5 +lon_0=-117.833333333333 +k=1.00016 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6785, '+proj=tmerc +lat_0=44.5 +lon_0=-117.833333333333 +k=1.00016 +x_0=39999.99999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6786, '+proj=tmerc +lat_0=44.5 +lon_0=-117.833333333333 +k=1.00016 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6787, '+proj=tmerc +lat_0=44.5 +lon_0=-117.833333333333 +k=1.00016 +x_0=39999.99999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6788, '+proj=tmerc +lat_0=41.75 +lon_0=-121.75 +k=1.0002 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6789, '+proj=tmerc +lat_0=41.75 +lon_0=-121.75 +k=1.0002 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6790, '+proj=tmerc +lat_0=41.75 +lon_0=-121.75 +k=1.0002 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6791, '+proj=tmerc +lat_0=41.75 +lon_0=-121.75 +k=1.0002 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6792, '+proj=lcc +lat_1=44.6666666666666 +lat_0=44.6666666666666 +lon_0=-121.25 +k_0=1.00012 +x_0=80000 +y_0=130000 +ellps=GRS80 +units=m +no_defs'],
    [6793, '+proj=lcc +lat_1=44.6666666666666 +lat_0=44.6666666666666 +lon_0=-121.25 +k_0=1.00012 +x_0=79999.99999968 +y_0=130000.00001472 +ellps=GRS80 +units=ft +no_defs'],
    [6794, '+proj=lcc +lat_1=44.6666666666666 +lat_0=44.6666666666666 +lon_0=-121.25 +k_0=1.00012 +x_0=80000 +y_0=130000 +ellps=GRS80 +units=m +no_defs'],
    [6795, '+proj=lcc +lat_1=44.6666666666666 +lat_0=44.6666666666666 +lon_0=-121.25 +k_0=1.00012 +x_0=79999.99999968 +y_0=130000.00001472 +ellps=GRS80 +units=ft +no_defs'],
    [6796, '+proj=lcc +lat_1=43.6666666666666 +lat_0=43.6666666666666 +lon_0=-119.75 +k_0=1.0002 +x_0=120000 +y_0=60000 +ellps=GRS80 +units=m +no_defs'],
    [6797, '+proj=lcc +lat_1=43.6666666666666 +lat_0=43.6666666666666 +lon_0=-119.75 +k_0=1.0002 +x_0=119999.99999952 +y_0=59999.99999976 +ellps=GRS80 +units=ft +no_defs'],
    [6798, '+proj=lcc +lat_1=43.6666666666666 +lat_0=43.6666666666666 +lon_0=-119.75 +k_0=1.0002 +x_0=120000 +y_0=60000 +ellps=GRS80 +units=m +no_defs'],
    [6799, '+proj=lcc +lat_1=43.6666666666666 +lat_0=43.6666666666666 +lon_0=-119.75 +k_0=1.0002 +x_0=119999.99999952 +y_0=59999.99999976 +ellps=GRS80 +units=ft +no_defs'],
    [6800, '+proj=tmerc +lat_0=42.5 +lon_0=-123.333333333333 +k=1.00007 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6801, '+proj=tmerc +lat_0=42.5 +lon_0=-123.333333333333 +k=1.00007 +x_0=39999.99999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6802, '+proj=tmerc +lat_0=42.5 +lon_0=-123.333333333333 +k=1.00007 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6803, '+proj=tmerc +lat_0=42.5 +lon_0=-123.333333333333 +k=1.00007 +x_0=39999.99999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6804, '+proj=lcc +lat_1=45.6666666666666 +lat_0=45.6666666666666 +lon_0=-120.5 +k_0=1.000008 +x_0=150000 +y_0=30000 +ellps=GRS80 +units=m +no_defs'],
    [6805, '+proj=lcc +lat_1=45.6666666666666 +lat_0=45.6666666666666 +lon_0=-120.5 +k_0=1.000008 +x_0=150000.00001464 +y_0=30000.00001512 +ellps=GRS80 +units=ft +no_defs'],
    [6806, '+proj=lcc +lat_1=45.6666666666666 +lat_0=45.6666666666666 +lon_0=-120.5 +k_0=1.000008 +x_0=150000 +y_0=30000 +ellps=GRS80 +units=m +no_defs'],
    [6807, '+proj=lcc +lat_1=45.6666666666666 +lat_0=45.6666666666666 +lon_0=-120.5 +k_0=1.000008 +x_0=150000.00001464 +y_0=30000.00001512 +ellps=GRS80 +units=ft +no_defs'],
    [6808, '+proj=omerc +lat_0=45.9166666666666 +lonc=-123 +alpha=295 +k=1 +x_0=7000000 +y_0=-3000000 +no_uoff +gamma=295 +ellps=GRS80 +units=m +no_defs'],
    [6809, '+proj=omerc +lat_0=45.9166666666666 +lonc=-123 +alpha=295 +k=1 +x_0=7000000.00000248 +y_0=-2999999.999988 +no_uoff +gamma=295 +ellps=GRS80 +units=ft +no_defs'],
    [6810, '+proj=omerc +lat_0=45.9166666666666 +lonc=-123 +alpha=295 +k=1 +x_0=7000000 +y_0=-3000000 +no_uoff +gamma=295 +ellps=GRS80 +units=m +no_defs'],
    [6811, '+proj=omerc +lat_0=45.9166666666666 +lonc=-123 +alpha=295 +k=1 +x_0=7000000.00000248 +y_0=-2999999.999988 +no_uoff +gamma=295 +ellps=GRS80 +units=ft +no_defs'],
    [6812, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-123.333333333333 +k=1.000023 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6813, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-123.333333333333 +k=1.000023 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6814, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-123.333333333333 +k=1.000023 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6815, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-123.333333333333 +k=1.000023 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6816, '+proj=tmerc +lat_0=44.5 +lon_0=-121 +k=1.00011 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6817, '+proj=tmerc +lat_0=44.5 +lon_0=-121 +k=1.00011 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6818, '+proj=tmerc +lat_0=44.5 +lon_0=-121 +k=1.00011 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6819, '+proj=tmerc +lat_0=44.5 +lon_0=-121 +k=1.00011 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6820, '+proj=tmerc +lat_0=43.75 +lon_0=-123.166666666666 +k=1.000015 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6821, '+proj=tmerc +lat_0=43.75 +lon_0=-123.166666666666 +k=1.000015 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6822, '+proj=tmerc +lat_0=43.75 +lon_0=-123.166666666666 +k=1.000015 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6823, '+proj=tmerc +lat_0=43.75 +lon_0=-123.166666666666 +k=1.000015 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6824, '+proj=tmerc +lat_0=41.75 +lon_0=-123.333333333333 +k=1.000043 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6825, '+proj=tmerc +lat_0=41.75 +lon_0=-123.333333333333 +k=1.000043 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6826, '+proj=tmerc +lat_0=41.75 +lon_0=-123.333333333333 +k=1.000043 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6827, '+proj=tmerc +lat_0=41.75 +lon_0=-123.333333333333 +k=1.000043 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6828, '+proj=tmerc +lat_0=45 +lon_0=-122.333333333333 +k=1.00005 +x_0=10000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6829, '+proj=tmerc +lat_0=45 +lon_0=-122.333333333333 +k=1.00005 +x_0=10000.0000152 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6830, '+proj=tmerc +lat_0=45 +lon_0=-122.333333333333 +k=1.00005 +x_0=10000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6831, '+proj=tmerc +lat_0=45 +lon_0=-122.333333333333 +k=1.00005 +x_0=10000.0000152 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6832, '+proj=tmerc +lat_0=45 +lon_0=-118 +k=1.00013 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6833, '+proj=tmerc +lat_0=45 +lon_0=-118 +k=1.00013 +x_0=39999.99999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6834, '+proj=tmerc +lat_0=45 +lon_0=-118 +k=1.00013 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6835, '+proj=tmerc +lat_0=45 +lon_0=-118 +k=1.00013 +x_0=39999.99999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6836, '+proj=tmerc +lat_0=43.25 +lon_0=-117 +k=1.0001 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6837, '+proj=tmerc +lat_0=43.25 +lon_0=-117 +k=1.0001 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6838, '+proj=tmerc +lat_0=43.25 +lon_0=-117 +k=1.0001 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6839, '+proj=tmerc +lat_0=43.25 +lon_0=-117 +k=1.0001 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6840, '+proj=omerc +lat_0=44.75 +lonc=-124.05 +alpha=5 +k=1 +x_0=-300000 +y_0=-4600000 +no_uoff +gamma=5 +ellps=GRS80 +units=m +no_defs'],
    [6841, '+proj=omerc +lat_0=44.75 +lonc=-124.05 +alpha=5 +k=1 +x_0=-299999.9999988 +y_0=-4600000.00001208 +no_uoff +gamma=5 +ellps=GRS80 +units=ft +no_defs'],
    [6842, '+proj=omerc +lat_0=44.75 +lonc=-124.05 +alpha=5 +k=1 +x_0=-300000 +y_0=-4600000 +no_uoff +gamma=5 +ellps=GRS80 +units=m +no_defs'],
    [6843, '+proj=omerc +lat_0=44.75 +lonc=-124.05 +alpha=5 +k=1 +x_0=-299999.9999988 +y_0=-4600000.00001208 +no_uoff +gamma=5 +ellps=GRS80 +units=ft +no_defs'],
    [6844, '+proj=tmerc +lat_0=45.25 +lon_0=-119.166666666666 +k=1.000045 +x_0=60000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6845, '+proj=tmerc +lat_0=45.25 +lon_0=-119.166666666666 +k=1.000045 +x_0=59999.99999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6846, '+proj=tmerc +lat_0=45.25 +lon_0=-119.166666666666 +k=1.000045 +x_0=60000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6847, '+proj=tmerc +lat_0=45.25 +lon_0=-119.166666666666 +k=1.000045 +x_0=59999.99999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6848, '+proj=tmerc +lat_0=45.0833333333333 +lon_0=-118.333333333333 +k=1.000175 +x_0=30000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6849, '+proj=tmerc +lat_0=45.0833333333333 +lon_0=-118.333333333333 +k=1.000175 +x_0=30000.00001512 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6850, '+proj=tmerc +lat_0=45.0833333333333 +lon_0=-118.333333333333 +k=1.000175 +x_0=30000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6851, '+proj=tmerc +lat_0=45.0833333333333 +lon_0=-118.333333333333 +k=1.000175 +x_0=30000.00001512 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6852, '+proj=lcc +lat_1=45.5 +lat_0=45.5 +lon_0=-122.75 +k_0=1.000002 +x_0=100000 +y_0=50000 +ellps=GRS80 +units=m +no_defs'],
    [6853, '+proj=lcc +lat_1=45.5 +lat_0=45.5 +lon_0=-122.75 +k_0=1.000002 +x_0=99999.9999996 +y_0=50000.00001504 +ellps=GRS80 +units=ft +no_defs'],
    [6854, '+proj=lcc +lat_1=45.5 +lat_0=45.5 +lon_0=-122.75 +k_0=1.000002 +x_0=100000 +y_0=50000 +ellps=GRS80 +units=m +no_defs'],
    [6855, '+proj=lcc +lat_1=45.5 +lat_0=45.5 +lon_0=-122.75 +k_0=1.000002 +x_0=99999.9999996 +y_0=50000.00001504 +ellps=GRS80 +units=ft +no_defs'],
    [6856, '+proj=tmerc +lat_0=44.3333333333333 +lon_0=-123.083333333333 +k=1.00001 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6857, '+proj=tmerc +lat_0=44.3333333333333 +lon_0=-123.083333333333 +k=1.00001 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6858, '+proj=tmerc +lat_0=44.3333333333333 +lon_0=-123.083333333333 +k=1.00001 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6859, '+proj=tmerc +lat_0=44.3333333333333 +lon_0=-123.083333333333 +k=1.00001 +x_0=50000.00001504 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6860, '+proj=tmerc +lat_0=44.0833333333333 +lon_0=-122.5 +k=1.000155 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6861, '+proj=tmerc +lat_0=44.0833333333333 +lon_0=-122.5 +k=1.000155 +x_0=0 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6862, '+proj=tmerc +lat_0=44.0833333333333 +lon_0=-122.5 +k=1.000155 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6863, '+proj=tmerc +lat_0=44.0833333333333 +lon_0=-122.5 +k=1.000155 +x_0=0 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6867, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6868, '+proj=lcc +lat_1=43 +lat_2=45.5 +lat_0=41.75 +lon_0=-120.5 +x_0=399999.9999984 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6870, '+proj=tmerc +lat_0=0 +lon_0=20 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6871, '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +geoidgrids=egm08_25.gtx +vunits=m +no_defs'],
    [6875, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9985000000000001 +x_0=7000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6876, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=3000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6879, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.8333333333333 +lon_0=-90 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6880, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.8333333333333 +lon_0=-100 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6881, '+proj=longlat +ellps=clrk80 +towgs84=-24,-203,268,0,0,0,0 +no_defs '],
    [6882, '+proj=longlat +ellps=clrk80 +towgs84=-183,-15,273,0,0,0,0 +no_defs '],
    [6883, '+proj=longlat +ellps=intl +towgs84=-235,-110,393,0,0,0,0 +no_defs '],
    [6884, '+proj=lcc +lat_1=46 +lat_2=44.3333333333333 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6885, '+proj=lcc +lat_1=46 +lat_2=44.3333333333333 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=2500000.0001424 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6886, '+proj=lcc +lat_1=44 +lat_2=42.3333333333333 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6887, '+proj=lcc +lat_1=44 +lat_2=42.3333333333333 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=1500000.0001464 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [6892, '+proj=longlat +ellps=clrk80 +towgs84=-43.685,-179.785,-267.721,0,0,0,0 +no_defs '],
    [6893, '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +geoidgrids=egm08_25.gtx +vunits=m +no_defs'],
    [6894, '+proj=longlat +ellps=clrk80 +towgs84=-63,176,185,0,0,0,0 +no_defs '],
    [6915, '+proj=utm +zone=40 +ellps=clrk80 +towgs84=-43.685,-179.785,-267.721,0,0,0,0 +units=m +no_defs '],
    [6917, '+proj=longlat +ellps=WGS84 +vunits=m +no_defs'],
    [6922, '+proj=lcc +lat_1=39.5 +lat_2=37.5 +lat_0=36 +lon_0=-98.25 +x_0=400000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [6923, '+proj=lcc +lat_1=39.5 +lat_2=37.5 +lat_0=36 +lon_0=-98.25 +x_0=399999.99998984 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [6924, '+proj=lcc +lat_1=39.5 +lat_2=37.5 +lat_0=36 +lon_0=-98.25 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [6925, '+proj=lcc +lat_1=39.5 +lat_2=37.5 +lat_0=36 +lon_0=-98.25 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [6927, '+proj=tmerc +lat_0=1.36666666666666 +lon_0=103.833333333333 +k=1 +x_0=28001.642 +y_0=38744.572 +ellps=WGS84 +units=m +vunits=m +no_defs'],
    [6931, '+proj=laea +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [6932, '+proj=laea +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [6933, '+proj=cea +lon_0=0 +lat_ts=30 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [6934, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6956, '+proj=tmerc +lat_0=0 +lon_0=102 +k=0.9999 +x_0=0 +y_0=500000 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs '],
    [6957, '+proj=tmerc +lat_0=0 +lon_0=105 +k=0.9999 +x_0=0 +y_0=500000 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs '],
    [6958, '+proj=tmerc +lat_0=0 +lon_0=108 +k=0.9999 +x_0=0 +y_0=500000 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs '],
    [6959, '+proj=tmerc +lat_0=0 +lon_0=107.75 +k=0.9999 +x_0=0 +y_0=500000 +ellps=WGS84 +towgs84=-191.90441429,-39.30318279,-111.45032835,0.00928836,-0.01975479,0.00427372,0.252906278 +units=m +no_defs '],
    [6962, '+proj=lcc +lat_1=39 +lat_2=43 +lat_0=41 +lon_0=20 +x_0=0 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [6966, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=609601.219202438 +y_0=0 +k_0=1.0000382 +ellps=clrk66 +towgs84=-8,160,176,0,0,0,0 +units=us-ft +no_defs'],
    [6978, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6979, '+proj=longlat +ellps=WGS84 +no_defs'],
    [6980, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6981, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6982, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6983, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6984, '+proj=tmerc +lat_0=31.7343936111111 +lon_0=35.2045169444444 +k=1.0000067 +x_0=219529.584 +y_0=626907.39 +ellps=GRS80 +units=m +no_defs'],
    [6985, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6986, '+proj=longlat +ellps=WGS84 +no_defs'],
    [6987, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6988, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [6989, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6990, '+proj=longlat +ellps=GRS80 +no_defs'],
    [6991, '+proj=tmerc +lat_0=31.7343936111111 +lon_0=35.2045169444444 +k=1.0000067 +x_0=219529.584 +y_0=626907.39 +ellps=GRS80 +units=m +no_defs'],
    [6996, '+proj=tmerc +lat_0=37.75 +lon_0=-122.45 +k=1.000007 +x_0=48000 +y_0=24000 +ellps=GRS80 +units=m +no_defs'],
    [6997, '+proj=tmerc +lat_0=37.75 +lon_0=-122.45 +k=1.000007 +x_0=48000 +y_0=24000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7005, '+proj=utm +zone=37 +ellps=clrk80 +towgs84=-242.2,-144.9,370.3,0,0,0,0 +units=m +no_defs '],
    [7006, '+proj=utm +zone=38 +ellps=clrk80 +towgs84=-242.2,-144.9,370.3,0,0,0,0 +units=m +no_defs '],
    [7007, '+proj=utm +zone=39 +ellps=clrk80 +towgs84=-242.2,-144.9,370.3,0,0,0,0 +units=m +no_defs '],
    [7034, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7035, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7036, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7037, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7038, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7039, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7040, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7041, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7042, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7057, '+proj=lcc +lat_1=43.2 +lat_0=43.2 +lon_0=-95.25 +k_0=1.000052 +x_0=3505207.01041402 +y_0=2926085.8521717 +ellps=GRS80 +units=us-ft +no_defs'],
    [7058, '+proj=lcc +lat_1=43.1666666666666 +lat_0=43.1666666666666 +lon_0=-92.75 +k_0=1.000043 +x_0=3810007.62001524 +y_0=2987045.97409194 +ellps=GRS80 +units=us-ft +no_defs'],
    [7059, '+proj=tmerc +lat_0=40.25 +lon_0=-91.2 +k=1.000035 +x_0=4114808.22961645 +y_0=2529845.05969012 +ellps=GRS80 +units=us-ft +no_defs'],
    [7060, '+proj=lcc +lat_1=42.5333333333333 +lat_0=42.5333333333333 +lon_0=-94.8333333333333 +k_0=1.000045 +x_0=4419608.83921767 +y_0=2621285.24257048 +ellps=GRS80 +units=us-ft +no_defs'],
    [7061, '+proj=lcc +lat_1=42.65 +lat_0=42.65 +lon_0=-92.25 +k_0=1.000032 +x_0=4724409.44881889 +y_0=2712725.42545085 +ellps=GRS80 +units=us-ft +no_defs'],
    [7062, '+proj=tmerc +lat_0=40.25 +lon_0=-95.7333333333333 +k=1.000039 +x_0=5029210.05842011 +y_0=2011684.02336804 +ellps=GRS80 +units=us-ft +no_defs'],
    [7063, '+proj=tmerc +lat_0=40.25 +lon_0=-94.6333333333333 +k=1.000045 +x_0=5334010.66802133 +y_0=2072644.14528829 +ellps=GRS80 +units=us-ft +no_defs'],
    [7064, '+proj=tmerc +lat_0=40.25 +lon_0=-93.7166666666666 +k=1.000033 +x_0=5638811.27762255 +y_0=2133604.26720853 +ellps=GRS80 +units=us-ft +no_defs'],
    [7065, '+proj=tmerc +lat_0=40.25 +lon_0=-92.8166666666666 +k=1.000027 +x_0=5943611.88722377 +y_0=2194564.38912877 +ellps=GRS80 +units=us-ft +no_defs'],
    [7066, '+proj=lcc +lat_1=41.8333333333333 +lat_0=41.8333333333333 +lon_0=-91.6666666666666 +k_0=1.00002 +x_0=6248412.49682499 +y_0=2438404.87680975 +ellps=GRS80 +units=us-ft +no_defs'],
    [7067, '+proj=tmerc +lat_0=40.25 +lon_0=-90.5333333333333 +k=1.000027 +x_0=6553213.10642621 +y_0=2316484.63296926 +ellps=GRS80 +units=us-ft +no_defs'],
    [7068, '+proj=lcc +lat_1=40.9166666666666 +lat_0=40.9166666666666 +lon_0=-93.75 +k_0=1.000037 +x_0=6858013.71602743 +y_0=1889763.77952755 +ellps=GRS80 +units=us-ft +no_defs'],
    [7069, '+proj=tmerc +lat_0=40.25 +lon_0=-91.9166666666666 +k=1.00002 +x_0=7162814.32562865 +y_0=1950723.9014478 +ellps=GRS80 +units=us-ft +no_defs'],
    [7070, '+proj=tmerc +lat_0=40.25 +lon_0=-91.25 +k=1.000018 +x_0=7467614.93522987 +y_0=1889763.77952755 +ellps=GRS80 +units=us-ft +no_defs'],
    [7071, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7072, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7073, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7074, '+proj=utm +zone=37 +south +ellps=GRS80 +units=m +no_defs'],
    [7075, '+proj=utm +zone=38 +south +ellps=GRS80 +units=m +no_defs'],
    [7076, '+proj=utm +zone=39 +south +ellps=GRS80 +units=m +no_defs'],
    [7077, '+proj=utm +zone=40 +south +ellps=GRS80 +units=m +no_defs'],
    [7078, '+proj=utm +zone=41 +south +ellps=GRS80 +units=m +no_defs'],
    [7079, '+proj=utm +zone=42 +south +ellps=GRS80 +units=m +no_defs'],
    [7080, '+proj=utm +zone=43 +south +ellps=GRS80 +units=m +no_defs'],
    [7081, '+proj=utm +zone=44 +south +ellps=GRS80 +units=m +no_defs'],
    [7084, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7085, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7086, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7087, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7088, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7109, '+proj=tmerc +lat_0=48.5 +lon_0=-112.5 +k=1.00016 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [7110, '+proj=tmerc +lat_0=48 +lon_0=-112.5 +k=1.00019 +x_0=100000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [7111, '+proj=lcc +lat_1=48.5 +lat_0=48.5 +lon_0=-111 +k_0=1.000145 +x_0=150000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [7112, '+proj=lcc +lat_1=48.5 +lat_0=48.5 +lon_0=-108.5 +k_0=1.00012 +x_0=200000 +y_0=150000 +ellps=GRS80 +units=m +no_defs'],
    [7113, '+proj=lcc +lat_1=48.3333333333333 +lat_0=48.3333333333333 +lon_0=-105.5 +k_0=1.00012 +x_0=200000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [7114, '+proj=lcc +lat_1=48.3333333333333 +lat_0=48.3333333333333 +lon_0=-105.5 +k_0=1.00009 +x_0=100000 +y_0=50000 +ellps=GRS80 +units=m +no_defs'],
    [7115, '+proj=tmerc +lat_0=44.75 +lon_0=-107.75 +k=1.000148 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [7116, '+proj=lcc +lat_1=46.25 +lat_0=46.25 +lon_0=-111.25 +k_0=1.000185 +x_0=100000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [7117, '+proj=lcc +lat_1=45.7833333333333 +lat_0=45.7833333333333 +lon_0=-108.416666666666 +k_0=1.0001515 +x_0=200000 +y_0=50000 +ellps=GRS80 +units=m +no_defs'],
    [7118, '+proj=tmerc +lat_0=42.6666666666666 +lon_0=-108.333333333333 +k=1.00024 +x_0=100000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [7119, '+proj=tmerc +lat_0=48.5 +lon_0=-112.5 +k=1.00016 +x_0=150000.00001464 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [7120, '+proj=tmerc +lat_0=48 +lon_0=-112.5 +k=1.00019 +x_0=99999.9999996 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [7121, '+proj=lcc +lat_1=48.5 +lat_0=48.5 +lon_0=-111 +k_0=1.000145 +x_0=150000.00001464 +y_0=199999.9999992 +ellps=GRS80 +units=ft +no_defs'],
    [7122, '+proj=lcc +lat_1=48.5 +lat_0=48.5 +lon_0=-108.5 +k_0=1.00012 +x_0=199999.9999992 +y_0=150000.00001464 +ellps=GRS80 +units=ft +no_defs'],
    [7123, '+proj=lcc +lat_1=48.3333333333333 +lat_0=48.3333333333333 +lon_0=-105.5 +k_0=1.00012 +x_0=199999.9999992 +y_0=99999.9999996 +ellps=GRS80 +units=ft +no_defs'],
    [7124, '+proj=lcc +lat_1=48.3333333333333 +lat_0=48.3333333333333 +lon_0=-105.5 +k_0=1.00009 +x_0=99999.9999996 +y_0=49999.99971024 +ellps=GRS80 +units=ft +no_defs'],
    [7125, '+proj=tmerc +lat_0=44.75 +lon_0=-107.75 +k=1.000148 +x_0=199999.9999992 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [7126, '+proj=lcc +lat_1=46.25 +lat_0=46.25 +lon_0=-111.25 +k_0=1.000185 +x_0=99999.9999996 +y_0=99999.9999996 +ellps=GRS80 +units=ft +no_defs'],
    [7127, '+proj=lcc +lat_1=45.7833333333333 +lat_0=45.7833333333333 +lon_0=-108.416666666666 +k_0=1.0001515 +x_0=199999.9999992 +y_0=50000.00001504 +ellps=GRS80 +units=ft +no_defs'],
    [7128, '+proj=tmerc +lat_0=42.6666666666666 +lon_0=-108.333333333333 +k=1.00024 +x_0=99999.9999898399 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [7131, '+proj=tmerc +lat_0=37.75 +lon_0=-122.45 +k=1.000007 +x_0=48000 +y_0=24000 +ellps=GRS80 +units=m +no_defs'],
    [7132, '+proj=tmerc +lat_0=37.75 +lon_0=-122.45 +k=1.000007 +x_0=48000 +y_0=24000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7133, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7134, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7135, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7136, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7137, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7138, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7139, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7142, '+proj=tmerc +lat_0=31.73409694444445 +lon_0=35.21208055555556 +k=1 +x_0=170251.555 +y_0=126867.909 +a=6378300.789 +b=6356566.435 +towgs84=-275.7224,94.7824,340.8944,-8.001,-4.42,-11.821,1 +units=m +no_defs '],
    [7257, '+proj=tmerc +lat_0=40.55 +lon_0=-84.95 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7258, '+proj=tmerc +lat_0=40.55 +lon_0=-84.95 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7259, '+proj=tmerc +lat_0=40.9 +lon_0=-85.05 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7260, '+proj=tmerc +lat_0=40.9 +lon_0=-85.05 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7261, '+proj=tmerc +lat_0=39 +lon_0=-85.8499999999999 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7262, '+proj=tmerc +lat_0=39 +lon_0=-85.8499999999999 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7263, '+proj=tmerc +lat_0=40.45 +lon_0=-87.3 +k=1.000029 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7264, '+proj=tmerc +lat_0=40.45 +lon_0=-87.3 +k=1.000029 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7265, '+proj=tmerc +lat_0=40.05 +lon_0=-85.4 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7266, '+proj=tmerc +lat_0=40.05 +lon_0=-85.4 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7267, '+proj=tmerc +lat_0=39.6 +lon_0=-86.5 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7268, '+proj=tmerc +lat_0=39.6 +lon_0=-86.5 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7269, '+proj=tmerc +lat_0=39 +lon_0=-86.3 +k=1.00003 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7270, '+proj=tmerc +lat_0=39 +lon_0=-86.3 +k=1.00003 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7271, '+proj=tmerc +lat_0=40.4 +lon_0=-86.65 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7272, '+proj=tmerc +lat_0=40.4 +lon_0=-86.65 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7273, '+proj=tmerc +lat_0=40.55 +lon_0=-86.4 +k=1.000028 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7274, '+proj=tmerc +lat_0=40.55 +lon_0=-86.4 +k=1.000028 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7275, '+proj=tmerc +lat_0=38.15 +lon_0=-85.5999999999999 +k=1.000021 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7276, '+proj=tmerc +lat_0=38.15 +lon_0=-85.5999999999999 +k=1.000021 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7277, '+proj=tmerc +lat_0=39.15 +lon_0=-87.15 +k=1.000024 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7278, '+proj=tmerc +lat_0=39.15 +lon_0=-87.15 +k=1.000024 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7279, '+proj=tmerc +lat_0=40.15 +lon_0=-86.5999999999999 +k=1.000032 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7280, '+proj=tmerc +lat_0=40.15 +lon_0=-86.5999999999999 +k=1.000032 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7281, '+proj=tmerc +lat_0=38.1 +lon_0=-86.5 +k=1.000025 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7282, '+proj=tmerc +lat_0=38.1 +lon_0=-86.5 +k=1.000025 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7283, '+proj=tmerc +lat_0=38.45 +lon_0=-87.0999999999999 +k=1.000018 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7284, '+proj=tmerc +lat_0=38.45 +lon_0=-87.0999999999999 +k=1.000018 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7285, '+proj=tmerc +lat_0=38.65 +lon_0=-84.9 +k=1.000029 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7286, '+proj=tmerc +lat_0=38.65 +lon_0=-84.9 +k=1.000029 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7287, '+proj=tmerc +lat_0=39.1 +lon_0=-85.65 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7288, '+proj=tmerc +lat_0=39.1 +lon_0=-85.65 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7289, '+proj=tmerc +lat_0=41.25 +lon_0=-84.95 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7290, '+proj=tmerc +lat_0=41.25 +lon_0=-84.95 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7291, '+proj=tmerc +lat_0=38.2 +lon_0=-86.95 +k=1.00002 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7292, '+proj=tmerc +lat_0=38.2 +lon_0=-86.95 +k=1.00002 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7293, '+proj=tmerc +lat_0=40.65 +lon_0=-85.8499999999999 +k=1.000033 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7294, '+proj=tmerc +lat_0=40.65 +lon_0=-85.8499999999999 +k=1.000033 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7295, '+proj=tmerc +lat_0=39.25 +lon_0=-85.05 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7296, '+proj=tmerc +lat_0=39.25 +lon_0=-85.05 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7297, '+proj=tmerc +lat_0=39.95 +lon_0=-87.3 +k=1.000025 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7298, '+proj=tmerc +lat_0=39.95 +lon_0=-87.3 +k=1.000025 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7299, '+proj=tmerc +lat_0=40.9 +lon_0=-86.3 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7300, '+proj=tmerc +lat_0=40.9 +lon_0=-86.3 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7301, '+proj=tmerc +lat_0=38.15 +lon_0=-87.65 +k=1.000013 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7302, '+proj=tmerc +lat_0=38.15 +lon_0=-87.65 +k=1.000013 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7303, '+proj=tmerc +lat_0=40.35 +lon_0=-85.7 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7304, '+proj=tmerc +lat_0=40.35 +lon_0=-85.7 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7305, '+proj=tmerc +lat_0=39.9 +lon_0=-86 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7306, '+proj=tmerc +lat_0=39.9 +lon_0=-86 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7307, '+proj=tmerc +lat_0=39.65 +lon_0=-85.8 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7308, '+proj=tmerc +lat_0=39.65 +lon_0=-85.8 +k=1.000036 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7309, '+proj=tmerc +lat_0=37.95 +lon_0=-86.15 +k=1.000027 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7310, '+proj=tmerc +lat_0=37.95 +lon_0=-86.15 +k=1.000027 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7311, '+proj=tmerc +lat_0=39.75 +lon_0=-85.45 +k=1.000043 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7312, '+proj=tmerc +lat_0=39.75 +lon_0=-85.45 +k=1.000043 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7313, '+proj=tmerc +lat_0=40.35 +lon_0=-86.15 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7314, '+proj=tmerc +lat_0=40.35 +lon_0=-86.15 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7315, '+proj=tmerc +lat_0=40.65 +lon_0=-85.5 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7316, '+proj=tmerc +lat_0=40.65 +lon_0=-85.5 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7317, '+proj=tmerc +lat_0=38.7 +lon_0=-85.95 +k=1.000022 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7318, '+proj=tmerc +lat_0=38.7 +lon_0=-85.95 +k=1.000022 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7319, '+proj=tmerc +lat_0=40.7 +lon_0=-87.0999999999999 +k=1.000027 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7320, '+proj=tmerc +lat_0=40.7 +lon_0=-87.0999999999999 +k=1.000027 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7321, '+proj=tmerc +lat_0=40.3 +lon_0=-85 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7322, '+proj=tmerc +lat_0=40.3 +lon_0=-85 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7323, '+proj=tmerc +lat_0=38.55 +lon_0=-85.3499999999999 +k=1.000028 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7324, '+proj=tmerc +lat_0=38.55 +lon_0=-85.3499999999999 +k=1.000028 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7325, '+proj=tmerc +lat_0=38.8 +lon_0=-85.8 +k=1.000025 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7326, '+proj=tmerc +lat_0=38.8 +lon_0=-85.8 +k=1.000025 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7327, '+proj=tmerc +lat_0=39.3 +lon_0=-86.15 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7328, '+proj=tmerc +lat_0=39.3 +lon_0=-86.15 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7329, '+proj=tmerc +lat_0=38.4 +lon_0=-87.45 +k=1.000015 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7330, '+proj=tmerc +lat_0=38.4 +lon_0=-87.45 +k=1.000015 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7331, '+proj=tmerc +lat_0=41.25 +lon_0=-85.45 +k=1.000037 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7332, '+proj=tmerc +lat_0=41.25 +lon_0=-85.45 +k=1.000037 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7333, '+proj=tmerc +lat_0=40.7 +lon_0=-87.4 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7334, '+proj=tmerc +lat_0=40.7 +lon_0=-87.4 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7335, '+proj=tmerc +lat_0=40.9 +lon_0=-86.75 +k=1.000027 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7336, '+proj=tmerc +lat_0=40.9 +lon_0=-86.75 +k=1.000027 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7337, '+proj=tmerc +lat_0=38.95 +lon_0=-86.5 +k=1.000028 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7338, '+proj=tmerc +lat_0=38.95 +lon_0=-86.5 +k=1.000028 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7339, '+proj=tmerc +lat_0=39.45 +lon_0=-86.95 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7340, '+proj=tmerc +lat_0=39.45 +lon_0=-86.95 +k=1.000031 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7341, '+proj=tmerc +lat_0=39.15 +lon_0=-86.9 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7342, '+proj=tmerc +lat_0=39.15 +lon_0=-86.9 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7343, '+proj=tmerc +lat_0=39.6 +lon_0=-87.3499999999999 +k=1.000022 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7344, '+proj=tmerc +lat_0=39.6 +lon_0=-87.3499999999999 +k=1.000022 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7345, '+proj=tmerc +lat_0=37.8 +lon_0=-86.7 +k=1.00002 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7346, '+proj=tmerc +lat_0=37.8 +lon_0=-86.7 +k=1.00002 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7347, '+proj=tmerc +lat_0=37.85 +lon_0=-87.3 +k=1.000015 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7348, '+proj=tmerc +lat_0=37.85 +lon_0=-87.3 +k=1.000015 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7349, '+proj=tmerc +lat_0=37.75 +lon_0=-87.95 +k=1.000013 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7350, '+proj=tmerc +lat_0=37.75 +lon_0=-87.95 +k=1.000013 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7351, '+proj=tmerc +lat_0=39.7 +lon_0=-85.05 +k=1.000044 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7352, '+proj=tmerc +lat_0=39.7 +lon_0=-85.05 +k=1.000044 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7353, '+proj=tmerc +lat_0=38.9 +lon_0=-85.3 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7354, '+proj=tmerc +lat_0=38.9 +lon_0=-85.3 +k=1.000038 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7355, '+proj=tmerc +lat_0=39.3 +lon_0=-85.9 +k=1.00003 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7356, '+proj=tmerc +lat_0=39.3 +lon_0=-85.9 +k=1.00003 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7357, '+proj=tmerc +lat_0=37.75 +lon_0=-87.05 +k=1.000014 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7358, '+proj=tmerc +lat_0=37.75 +lon_0=-87.05 +k=1.000014 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7359, '+proj=tmerc +lat_0=41.5 +lon_0=-85 +k=1.000041 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7360, '+proj=tmerc +lat_0=41.5 +lon_0=-85 +k=1.000041 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7361, '+proj=tmerc +lat_0=38.9 +lon_0=-87.5 +k=1.000017 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7362, '+proj=tmerc +lat_0=38.9 +lon_0=-87.5 +k=1.000017 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7363, '+proj=tmerc +lat_0=40.2 +lon_0=-86.9 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7364, '+proj=tmerc +lat_0=40.2 +lon_0=-86.9 +k=1.000026 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7365, '+proj=tmerc +lat_0=37.8 +lon_0=-87.55 +k=1.000015 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7366, '+proj=tmerc +lat_0=37.8 +lon_0=-87.55 +k=1.000015 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7367, '+proj=tmerc +lat_0=39.25 +lon_0=-87.45 +k=1.00002 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7368, '+proj=tmerc +lat_0=39.25 +lon_0=-87.45 +k=1.00002 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7369, '+proj=tmerc +lat_0=40.55 +lon_0=-85.25 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=m +no_defs'],
    [7370, '+proj=tmerc +lat_0=40.55 +lon_0=-85.25 +k=1.000034 +x_0=240000 +y_0=36000 +ellps=GRS80 +units=us-ft +no_defs'],
    [7371, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7372, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7373, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [7374, '+proj=utm +zone=39 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7375, '+proj=utm +zone=40 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7376, '+proj=utm +zone=41 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7400, '+proj=longlat +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +vunits=m +no_defs '],
    [7401, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7402, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7403, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7404, '+proj=longlat +ellps=bessel +towgs84=414.1,41.3,603.1,-0.855,2.141,-7.023,0 +vunits=m +no_defs '],
    [7405, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +datum=OSGB36 +units=m +vunits=m +no_defs'],
    [7406, '+proj=longlat +datum=NAD27 +vunits=us-ft +no_defs'],
    [7407, '+proj=lcc +lat_1=34.65 +lat_2=36.1833333333333 +lat_0=34 +lon_0=-101.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +vunits=us-ft +no_defs'],
    [7408, '+proj=longlat +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857,0.350733,-1.87035,4.0812 +vunits=m +no_defs '],
    [7409, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [7410, '+proj=longlat +ellps=clrk80 +towgs84=-180.624,-225.516,173.919,-0.81,-1.898,8.336,16.71006 +vunits=m +no_defs '],
    [7411, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7412, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7413, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7414, '+proj=longlat +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +vunits=m +no_defs '],
    [7415, '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857,0.350733,-1.87035,4.0812 +units=m +vunits=m +no_defs '],
    [7416, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7417, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7418, '+proj=tmerc +lat_0=0 +lon_0=9.5 +k=0.99995 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7419, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.99995 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7420, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7421, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7422, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +vunits=m +no_defs '],
    [7423, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [7528, '+proj=tmerc +lat_0=43.3666666666666 +lon_0=-90 +k=1.0000365285 +x_0=147218.6942 +y_0=0.0037 +ellps=GRS80 +units=m +no_defs'],
    [7529, '+proj=tmerc +lat_0=45.7061111111111 +lon_0=-90.6222222222222 +k=1.0000495683 +x_0=172821.9461 +y_0=0.0017 +ellps=GRS80 +units=m +no_defs'],
    [7530, '+proj=tmerc +lat_0=45.1333333333333 +lon_0=-91.8499999999999 +k=1.0000486665 +x_0=93150 +y_0=0.0029 +ellps=GRS80 +units=m +no_defs'],
    [7531, '+proj=lcc +lat_1=46.6696483772222 +lat_0=46.6696483772222 +lon_0=-91.1527777777777 +k_0=1.0000331195 +x_0=228600.4575 +y_0=148551.4837 +ellps=GRS80 +units=m +no_defs'],
    [7532, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31600 +y_0=4600 +ellps=GRS80 +units=m +no_defs'],
    [7533, '+proj=tmerc +lat_0=43.4813888888888 +lon_0=-91.7972222222222 +k=1.0000382778 +x_0=175260.3502 +y_0=0.0048 +ellps=GRS80 +units=m +no_defs'],
    [7534, '+proj=lcc +lat_1=45.8987148658333 +lat_0=45.8987148658333 +lon_0=-92.4577777777777 +k_0=1.0000383841 +x_0=64008.1276 +y_0=59445.9043 +ellps=GRS80 +units=m +no_defs'],
    [7535, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8893 +y_0=0.0049 +ellps=GRS80 +units=m +no_defs'],
    [7536, '+proj=lcc +lat_1=44.9778568986111 +lat_0=44.9778568986111 +lon_0=-91.2944444444444 +k_0=1.0000391127 +x_0=60045.72 +y_0=44091.4346 +ellps=GRS80 +units=m +no_defs'],
    [7537, '+proj=tmerc +lat_0=43.6 +lon_0=-90.7083333333333 +k=1.0000463003 +x_0=199949.1989 +y_0=0.0086 +ellps=GRS80 +units=m +no_defs'],
    [7538, '+proj=lcc +lat_1=43.4625466458333 +lat_0=43.4625466458333 +lon_0=-89.3944444444444 +k_0=1.00003498 +x_0=169164.3381 +y_0=111569.6134 +ellps=GRS80 +units=m +no_defs'],
    [7539, '+proj=lcc +lat_1=43.200055605 +lat_0=43.200055605 +lon_0=-90.9388888888889 +k_0=1.0000349151 +x_0=113690.6274 +y_0=53703.1201 +ellps=GRS80 +units=m +no_defs'],
    [7540, '+proj=lcc +lat_1=43.0695160375 +lat_0=43.0695160375 +lon_0=-89.4222222222222 +k_0=1.0000384786 +x_0=247193.2944 +y_0=146591.9896 +ellps=GRS80 +units=m +no_defs'],
    [7541, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=1.0000346418 +x_0=263347.7263 +y_0=0.0076 +ellps=GRS80 +units=m +no_defs'],
    [7542, '+proj=tmerc +lat_0=44.4 +lon_0=-87.2722222222222 +k=1.0000187521 +x_0=158801.1176 +y_0=0.0023 +ellps=GRS80 +units=m +no_defs'],
    [7543, '+proj=tmerc +lat_0=45.8833333333333 +lon_0=-91.9166666666666 +k=1.0000385418 +x_0=59131.3183 +y_0=0.0041 +ellps=GRS80 +units=m +no_defs'],
    [7544, '+proj=tmerc +lat_0=44.4083333333333 +lon_0=-91.8944444444444 +k=1.0000410324 +x_0=51816.104 +y_0=0.003 +ellps=GRS80 +units=m +no_defs'],
    [7545, '+proj=lcc +lat_1=44.8722811263888 +lat_0=44.8722811263888 +lon_0=-91.2888888888888 +k_0=1.000035079 +x_0=120091.4402 +y_0=91687.9238999999 +ellps=GRS80 +units=m +no_defs'],
    [7546, '+proj=tmerc +lat_0=45.4388888888888 +lon_0=-88.1416666666666 +k=1.0000552095 +x_0=133502.6683 +y_0=0.0063 +ellps=GRS80 +units=m +no_defs'],
    [7547, '+proj=tmerc +lat_0=44.0055555555555 +lon_0=-88.6333333333333 +k=1.0000673004 +x_0=275844.5533 +y_0=0.0157 +ellps=GRS80 +units=m +no_defs'],
    [7548, '+proj=tmerc +lat_0=41.4111111111111 +lon_0=-90.8 +k=1.0000349452 +x_0=242316.4841 +y_0=0.01 +ellps=GRS80 +units=m +no_defs'],
    [7549, '+proj=lcc +lat_1=42.6375622769444 +lat_0=42.6375622769444 +lon_0=-89.8388888888888 +k_0=1.0000390487 +x_0=170078.7403 +y_0=45830.2947 +ellps=GRS80 +units=m +no_defs'],
    [7550, '+proj=lcc +lat_1=43.8070001177777 +lat_0=43.8070001177777 +lon_0=-89.2416666666666 +k_0=1.0000344057 +x_0=150876.3018 +y_0=79170.7795 +ellps=GRS80 +units=m +no_defs'],
    [7551, '+proj=tmerc +lat_0=42.5388888888888 +lon_0=-90.1611111111111 +k=1.0000394961 +x_0=113081.0261 +y_0=0.0045 +ellps=GRS80 +units=m +no_defs'],
    [7552, '+proj=tmerc +lat_0=45.4333333333333 +lon_0=-90.2555555555555 +k=1.0000677153 +x_0=220980.4419 +y_0=0.0085 +ellps=GRS80 +units=m +no_defs'],
    [7553, '+proj=tmerc +lat_0=44.2533351277777 +lon_0=-90.8442965194444 +k=1.0000353 +x_0=27000 +y_0=25000 +ellps=GRS80 +units=m +no_defs'],
    [7554, '+proj=tmerc +lat_0=42.2166666666666 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.3728 +y_0=0.0009 +ellps=GRS80 +units=m +no_defs'],
    [7555, '+proj=tmerc +lat_0=43.2666666666666 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.7614 +y_0=0.0012 +ellps=GRS80 +units=m +no_defs'],
    [7556, '+proj=tmerc +lat_0=43.4511111111111 +lon_0=-91.3166666666666 +k=1.0000319985 +x_0=130454.6598 +y_0=0.0033 +ellps=GRS80 +units=m +no_defs'],
    [7557, '+proj=lcc +lat_1=45.1542371052777 +lat_0=45.1542371052777 +lon_0=-89.0333333333333 +k_0=1.0000627024 +x_0=198425.197 +y_0=105279.7829 +ellps=GRS80 +units=m +no_defs'],
    [7558, '+proj=tmerc +lat_0=44.8444444444444 +lon_0=-89.7333333333333 +k=1.0000599003 +x_0=116129.0323 +y_0=0.0058 +ellps=GRS80 +units=m +no_defs'],
    [7559, '+proj=lcc +lat_1=44.9009044236111 +lat_0=44.9009044236111 +lon_0=-89.77 +k_0=1.000053289 +x_0=74676.1493 +y_0=55049.2669 +ellps=GRS80 +units=m +no_defs'],
    [7560, '+proj=tmerc +lat_0=44.6916666666666 +lon_0=-87.7111111111111 +k=1.0000234982 +x_0=238658.8794 +y_0=0.0032 +ellps=GRS80 +units=m +no_defs'],
    [7561, '+proj=tmerc +lat_0=44.7166666666666 +lon_0=-88.4166666666666 +k=1.0000362499 +x_0=105461.0121 +y_0=0.0029 +ellps=GRS80 +units=m +no_defs'],
    [7562, '+proj=lcc +lat_1=44.0000739286111 +lat_0=44.0000739286111 +lon_0=-90.6416666666666 +k_0=1.0000434122 +x_0=204521.209 +y_0=121923.9861 +ellps=GRS80 +units=m +no_defs'],
    [7563, '+proj=tmerc +lat_0=44.3972222222222 +lon_0=-87.9083333333333 +k=1.0000236869 +x_0=182880.3676 +y_0=0.0033 +ellps=GRS80 +units=m +no_defs'],
    [7564, '+proj=lcc +lat_1=45.7042237702777 +lat_0=45.7042237702777 +lon_0=-89.5444444444444 +k_0=1.0000686968 +x_0=70104.1401 +y_0=57588.0346 +ellps=GRS80 +units=m +no_defs'],
    [7565, '+proj=lcc +lat_1=44.6361488719444 +lat_0=44.6361488719444 +lon_0=-92.2277777777777 +k_0=1.0000362977 +x_0=167640.3354 +y_0=86033.0876 +ellps=GRS80 +units=m +no_defs'],
    [7566, '+proj=tmerc +lat_0=44.6611111111111 +lon_0=-92.6333333333333 +k=1.0000433849 +x_0=141732.2823 +y_0=0.0059 +ellps=GRS80 +units=m +no_defs'],
    [7567, '+proj=lcc +lat_1=44.4168239752777 +lat_0=44.4168239752777 +lon_0=-89.5 +k_0=1.000039936 +x_0=56388.1128 +y_0=50022.1874 +ellps=GRS80 +units=m +no_defs'],
    [7568, '+proj=tmerc +lat_0=44.5555555555555 +lon_0=-90.4888888888888 +k=1.0000649554 +x_0=227990.8546 +y_0=0.0109 +ellps=GRS80 +units=m +no_defs'],
    [7569, '+proj=lcc +lat_1=43.3223129275 +lat_0=43.3223129275 +lon_0=-90.4305555555555 +k_0=1.0000375653 +x_0=202387.6048 +y_0=134255.4253 +ellps=GRS80 +units=m +no_defs'],
    [7570, '+proj=tmerc +lat_0=41.9444444444444 +lon_0=-89.0722222222222 +k=1.0000337311 +x_0=146304.2926 +y_0=0.0068 +ellps=GRS80 +units=m +no_defs'],
    [7571, '+proj=tmerc +lat_0=43.9194444444444 +lon_0=-91.0666666666666 +k=1.0000495976 +x_0=250546.1013 +y_0=0.0234 +ellps=GRS80 +units=m +no_defs'],
    [7572, '+proj=tmerc +lat_0=42.8194444444444 +lon_0=-89.9 +k=1.0000373868 +x_0=185623.5716 +y_0=0.0051 +ellps=GRS80 +units=m +no_defs'],
    [7573, '+proj=lcc +lat_1=45.9000991313888 +lat_0=45.9000991313888 +lon_0=-91.1166666666666 +k_0=1.0000573461 +x_0=216713.2336 +y_0=120734.1631 +ellps=GRS80 +units=m +no_defs'],
    [7574, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-88.6055555555555 +k=1.000032144 +x_0=262433.3253 +y_0=0.00959999999999999 +ellps=GRS80 +units=m +no_defs'],
    [7575, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-92.6333333333333 +k=1.0000381803 +x_0=165506.7302 +y_0=0.0103 +ellps=GRS80 +units=m +no_defs'],
    [7576, '+proj=lcc +lat_1=45.1778220858333 +lat_0=45.1778220858333 +lon_0=-90.4833333333333 +k_0=1.0000597566 +x_0=187147.5744 +y_0=107746.7522 +ellps=GRS80 +units=m +no_defs'],
    [7577, '+proj=tmerc +lat_0=43.1611111111111 +lon_0=-91.3666666666666 +k=1.0000361538 +x_0=256946.9138 +y_0=0.0041 +ellps=GRS80 +units=m +no_defs'],
    [7578, '+proj=lcc +lat_1=43.5750329397222 +lat_0=43.5750329397222 +lon_0=-90.7833333333333 +k_0=1.0000408158 +x_0=222504.4451 +y_0=47532.0602 +ellps=GRS80 +units=m +no_defs'],
    [7579, '+proj=lcc +lat_1=46.0778440905555 +lat_0=46.0778440905555 +lon_0=-89.4888888888888 +k_0=1.0000730142 +x_0=134417.0689 +y_0=50337.1092 +ellps=GRS80 +units=m +no_defs'],
    [7580, '+proj=lcc +lat_1=42.6694620969444 +lat_0=42.6694620969444 +lon_0=-88.5416666666666 +k_0=1.0000367192 +x_0=232562.8651 +y_0=111088.2224 +ellps=GRS80 +units=m +no_defs'],
    [7581, '+proj=lcc +lat_1=45.9612198333333 +lat_0=45.9612198333333 +lon_0=-91.7833333333333 +k_0=1.0000475376 +x_0=234086.8682 +y_0=188358.6058 +ellps=GRS80 +units=m +no_defs'],
    [7582, '+proj=tmerc +lat_0=42.9180555555555 +lon_0=-88.0638888888888 +k=1.00003738 +x_0=120091.4415 +y_0=0.003 +ellps=GRS80 +units=m +no_defs'],
    [7583, '+proj=tmerc +lat_0=42.5694444444444 +lon_0=-88.2249999999999 +k=1.0000346179 +x_0=208788.418 +y_0=0.0034 +ellps=GRS80 +units=m +no_defs'],
    [7584, '+proj=tmerc +lat_0=43.4202777777777 +lon_0=-88.8166666666666 +k=1.0000333645 +x_0=185013.9709 +y_0=0.007 +ellps=GRS80 +units=m +no_defs'],
    [7585, '+proj=lcc +lat_1=44.1139440458333 +lat_0=44.1139440458333 +lon_0=-89.2416666666666 +k_0=1.0000392096 +x_0=120091.4402 +y_0=45069.7587 +ellps=GRS80 +units=m +no_defs'],
    [7586, '+proj=lcc +lat_1=44.3625954694444 +lat_0=44.3625954694444 +lon_0=-90 +k_0=1.0000421209 +x_0=208483.6173 +y_0=134589.754 +ellps=GRS80 +units=m +no_defs'],
    [7587, '+proj=tmerc +lat_0=43.3666666666666 +lon_0=-90 +k=1.0000365285 +x_0=147218.694132588 +y_0=0.00365760731521463 +ellps=GRS80 +units=us-ft +no_defs'],
    [7588, '+proj=tmerc +lat_0=45.7061111111111 +lon_0=-90.6222222222222 +k=1.0000495683 +x_0=172821.945948692 +y_0=0.00182880365760731 +ellps=GRS80 +units=us-ft +no_defs'],
    [7589, '+proj=tmerc +lat_0=45.1333333333333 +lon_0=-91.8499999999999 +k=1.0000486665 +x_0=93150 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [7590, '+proj=lcc +lat_1=46.6696483772222 +lat_0=46.6696483772222 +lon_0=-91.1527777777777 +k_0=1.0000331195 +x_0=228600.457505715 +y_0=148551.483566167 +ellps=GRS80 +units=us-ft +no_defs'],
    [7591, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31599.9998983997 +y_0=4599.99989839979 +ellps=GRS80 +units=us-ft +no_defs'],
    [7592, '+proj=tmerc +lat_0=43.4813888888888 +lon_0=-91.7972222222222 +k=1.0000382778 +x_0=175260.3502159 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [7593, '+proj=lcc +lat_1=45.8987148658333 +lat_0=45.8987148658333 +lon_0=-92.4577777777777 +k_0=1.0000383841 +x_0=64008.1277114554 +y_0=59445.9041910083 +ellps=GRS80 +units=us-ft +no_defs'],
    [7594, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.889204978 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [7595, '+proj=lcc +lat_1=44.9778568986111 +lat_0=44.9778568986111 +lon_0=-91.2944444444444 +k_0=1.0000391127 +x_0=60045.7200914401 +y_0=44091.4344932689 +ellps=GRS80 +units=us-ft +no_defs'],
    [7596, '+proj=tmerc +lat_0=43.6 +lon_0=-90.7083333333333 +k=1.0000463003 +x_0=199949.198983998 +y_0=0.00853441706883413 +ellps=GRS80 +units=us-ft +no_defs'],
    [7597, '+proj=lcc +lat_1=43.4625466458333 +lat_0=43.4625466458333 +lon_0=-89.3944444444444 +k_0=1.00003498 +x_0=169164.338023876 +y_0=111569.613512827 +ellps=GRS80 +units=us-ft +no_defs'],
    [7598, '+proj=lcc +lat_1=43.200055605 +lat_0=43.200055605 +lon_0=-90.9388888888889 +k_0=1.0000349151 +x_0=113690.627381254 +y_0=53703.1202438404 +ellps=GRS80 +units=us-ft +no_defs'],
    [7599, '+proj=lcc +lat_1=43.0695160375 +lat_0=43.0695160375 +lon_0=-89.4222222222222 +k_0=1.0000384786 +x_0=247193.294386588 +y_0=146591.989636779 +ellps=GRS80 +units=us-ft +no_defs'],
    [7600, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=1.0000346418 +x_0=263347.726390652 +y_0=0.00762001524003048 +ellps=GRS80 +units=us-ft +no_defs'],
    [7601, '+proj=tmerc +lat_0=44.4 +lon_0=-87.2722222222222 +k=1.0000187521 +x_0=158801.117602235 +y_0=0.00243840487680975 +ellps=GRS80 +units=us-ft +no_defs'],
    [7602, '+proj=tmerc +lat_0=45.8833333333333 +lon_0=-91.9166666666666 +k=1.0000385418 +x_0=59131.3182626365 +y_0=0.00396240792481584 +ellps=GRS80 +units=us-ft +no_defs'],
    [7603, '+proj=tmerc +lat_0=44.4083333333333 +lon_0=-91.8944444444444 +k=1.0000410324 +x_0=51816.1039370078 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [7604, '+proj=lcc +lat_1=44.8722811263888 +lat_0=44.8722811263888 +lon_0=-91.2888888888888 +k_0=1.000035079 +x_0=120091.44018288 +y_0=91687.9239014478 +ellps=GRS80 +units=us-ft +no_defs'],
    [7605, '+proj=tmerc +lat_0=45.4388888888888 +lon_0=-88.1416666666666 +k=1.0000552095 +x_0=133502.668224536 +y_0=0.0064008128016256 +ellps=GRS80 +units=us-ft +no_defs'],
    [7606, '+proj=tmerc +lat_0=44.0055555555555 +lon_0=-88.6333333333333 +k=1.0000673004 +x_0=275844.553213106 +y_0=0.0158496316992634 +ellps=GRS80 +units=us-ft +no_defs'],
    [7607, '+proj=tmerc +lat_0=41.4111111111111 +lon_0=-90.8 +k=1.0000349452 +x_0=242316.484023368 +y_0=0.0100584201168402 +ellps=GRS80 +units=us-ft +no_defs'],
    [7608, '+proj=lcc +lat_1=42.6375622769444 +lat_0=42.6375622769444 +lon_0=-89.8388888888888 +k_0=1.0000390487 +x_0=170078.74015748 +y_0=45830.2948437896 +ellps=GRS80 +units=us-ft +no_defs'],
    [7609, '+proj=lcc +lat_1=43.8070001177777 +lat_0=43.8070001177777 +lon_0=-89.2416666666666 +k_0=1.0000344057 +x_0=150876.301752603 +y_0=79170.7793751587 +ellps=GRS80 +units=us-ft +no_defs'],
    [7610, '+proj=tmerc +lat_0=42.5388888888888 +lon_0=-90.1611111111111 +k=1.0000394961 +x_0=113081.026162052 +y_0=0.00457200914401828 +ellps=GRS80 +units=us-ft +no_defs'],
    [7611, '+proj=tmerc +lat_0=45.4333333333333 +lon_0=-90.2555555555555 +k=1.0000677153 +x_0=220980.441960883 +y_0=0.00853441706883413 +ellps=GRS80 +units=us-ft +no_defs'],
    [7612, '+proj=tmerc +lat_0=44.2533351277777 +lon_0=-90.8442965194444 +k=1.0000353 +x_0=27000 +y_0=24999.9998983997 +ellps=GRS80 +units=us-ft +no_defs'],
    [7613, '+proj=tmerc +lat_0=42.2166666666666 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.372771145 +y_0=0.000914401828803657 +ellps=GRS80 +units=us-ft +no_defs'],
    [7614, '+proj=tmerc +lat_0=43.2666666666666 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.761544323 +y_0=0.00121920243840487 +ellps=GRS80 +units=us-ft +no_defs'],
    [7615, '+proj=tmerc +lat_0=43.4511111111111 +lon_0=-91.3166666666666 +k=1.0000319985 +x_0=130454.659690119 +y_0=0.00335280670561341 +ellps=GRS80 +units=us-ft +no_defs'],
    [7616, '+proj=lcc +lat_1=45.1542371052777 +lat_0=45.1542371052777 +lon_0=-89.0333333333333 +k_0=1.0000627024 +x_0=198425.196850393 +y_0=105279.782880365 +ellps=GRS80 +units=us-ft +no_defs'],
    [7617, '+proj=tmerc +lat_0=44.8444444444444 +lon_0=-89.7333333333333 +k=1.0000599003 +x_0=116129.032258064 +y_0=0.00579121158242316 +ellps=GRS80 +units=us-ft +no_defs'],
    [7618, '+proj=lcc +lat_1=44.9009044236111 +lat_0=44.9009044236111 +lon_0=-89.77 +k_0=1.000053289 +x_0=74676.1493522987 +y_0=55049.2669545339 +ellps=GRS80 +units=us-ft +no_defs'],
    [7619, '+proj=tmerc +lat_0=44.6916666666666 +lon_0=-87.7111111111111 +k=1.0000234982 +x_0=238658.879451358 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [7620, '+proj=tmerc +lat_0=44.7166666666666 +lon_0=-88.4166666666666 +k=1.0000362499 +x_0=105461.012141224 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [7621, '+proj=lcc +lat_1=44.0000739286111 +lat_0=44.0000739286111 +lon_0=-90.6416666666666 +k_0=1.0000434122 +x_0=204521.209042418 +y_0=121923.986182372 +ellps=GRS80 +units=us-ft +no_defs'],
    [7622, '+proj=tmerc +lat_0=44.3972222222222 +lon_0=-87.9083333333333 +k=1.0000236869 +x_0=182880.367589535 +y_0=0.00335280670561341 +ellps=GRS80 +units=us-ft +no_defs'],
    [7623, '+proj=lcc +lat_1=45.7042237702777 +lat_0=45.7042237702777 +lon_0=-89.5444444444444 +k_0=1.0000686968 +x_0=70104.1402082804 +y_0=57588.0347472694 +ellps=GRS80 +units=us-ft +no_defs'],
    [7624, '+proj=lcc +lat_1=44.6361488719444 +lat_0=44.6361488719444 +lon_0=-92.2277777777777 +k_0=1.0000362977 +x_0=167640.33528067 +y_0=86033.0877317754 +ellps=GRS80 +units=us-ft +no_defs'],
    [7625, '+proj=tmerc +lat_0=44.6611111111111 +lon_0=-92.6333333333333 +k=1.0000433849 +x_0=141732.282245364 +y_0=0.00579121158242316 +ellps=GRS80 +units=us-ft +no_defs'],
    [7626, '+proj=lcc +lat_1=44.4168239752777 +lat_0=44.4168239752777 +lon_0=-89.5 +k_0=1.000039936 +x_0=56388.1127762255 +y_0=50022.1874523749 +ellps=GRS80 +units=us-ft +no_defs'],
    [7627, '+proj=tmerc +lat_0=44.5555555555555 +lon_0=-90.4888888888888 +k=1.0000649554 +x_0=227990.854457708 +y_0=0.0109728219456438 +ellps=GRS80 +units=us-ft +no_defs'],
    [7628, '+proj=lcc +lat_1=43.3223129275 +lat_0=43.3223129275 +lon_0=-90.4305555555555 +k_0=1.0000375653 +x_0=202387.604775209 +y_0=134255.42545085 +ellps=GRS80 +units=us-ft +no_defs'],
    [7629, '+proj=tmerc +lat_0=41.9444444444444 +lon_0=-89.0722222222222 +k=1.0000337311 +x_0=146304.292608585 +y_0=0.00670561341122682 +ellps=GRS80 +units=us-ft +no_defs'],
    [7630, '+proj=tmerc +lat_0=43.9194444444444 +lon_0=-91.0666666666666 +k=1.0000495976 +x_0=250546.101397002 +y_0=0.0234696469392938 +ellps=GRS80 +units=us-ft +no_defs'],
    [7631, '+proj=tmerc +lat_0=42.8194444444444 +lon_0=-89.9 +k=1.0000373868 +x_0=185623.571551943 +y_0=0.00518161036322072 +ellps=GRS80 +units=us-ft +no_defs'],
    [7632, '+proj=lcc +lat_1=45.9000991313888 +lat_0=45.9000991313888 +lon_0=-91.1166666666666 +k_0=1.0000573461 +x_0=216713.233731267 +y_0=120734.163169926 +ellps=GRS80 +units=us-ft +no_defs'],
    [7633, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-88.6055555555555 +k=1.000032144 +x_0=262433.32517145 +y_0=0.00944881889763779 +ellps=GRS80 +units=us-ft +no_defs'],
    [7634, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-92.6333333333333 +k=1.0000381803 +x_0=165506.73009906 +y_0=0.0103632207264414 +ellps=GRS80 +units=us-ft +no_defs'],
    [7635, '+proj=lcc +lat_1=45.1778220858333 +lat_0=45.1778220858333 +lon_0=-90.4833333333333 +k_0=1.0000597566 +x_0=187147.574295148 +y_0=107746.752146304 +ellps=GRS80 +units=us-ft +no_defs'],
    [7636, '+proj=tmerc +lat_0=43.1611111111111 +lon_0=-91.3666666666666 +k=1.0000361538 +x_0=256946.913893827 +y_0=0.00396240792481584 +ellps=GRS80 +units=us-ft +no_defs'],
    [7637, '+proj=lcc +lat_1=43.5750329397222 +lat_0=43.5750329397222 +lon_0=-90.7833333333333 +k_0=1.0000408158 +x_0=222504.44500889 +y_0=47532.0603505207 +ellps=GRS80 +units=us-ft +no_defs'],
    [7638, '+proj=lcc +lat_1=46.0778440905555 +lat_0=46.0778440905555 +lon_0=-89.4888888888888 +k_0=1.0000730142 +x_0=134417.068834137 +y_0=50337.1092710185 +ellps=GRS80 +units=us-ft +no_defs'],
    [7639, '+proj=lcc +lat_1=42.6694620969444 +lat_0=42.6694620969444 +lon_0=-88.5416666666666 +k_0=1.0000367192 +x_0=232562.86512573 +y_0=111088.222402844 +ellps=GRS80 +units=us-ft +no_defs'],
    [7640, '+proj=lcc +lat_1=45.9612198333333 +lat_0=45.9612198333333 +lon_0=-91.7833333333333 +k_0=1.0000475376 +x_0=234086.868173736 +y_0=188358.605943611 +ellps=GRS80 +units=us-ft +no_defs'],
    [7641, '+proj=tmerc +lat_0=42.9180555555555 +lon_0=-88.0638888888888 +k=1.00003738 +x_0=120091.441402082 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [7642, '+proj=tmerc +lat_0=42.5694444444444 +lon_0=-88.2249999999999 +k=1.0000346179 +x_0=208788.417881635 +y_0=0.00335280670561341 +ellps=GRS80 +units=us-ft +no_defs'],
    [7643, '+proj=tmerc +lat_0=43.4202777777777 +lon_0=-88.8166666666666 +k=1.0000333645 +x_0=185013.970942341 +y_0=0.00701041402082804 +ellps=GRS80 +units=us-ft +no_defs'],
    [7644, '+proj=lcc +lat_1=44.1139440458333 +lat_0=44.1139440458333 +lon_0=-89.2416666666666 +k_0=1.0000392096 +x_0=120091.44018288 +y_0=45069.7588011176 +ellps=GRS80 +units=us-ft +no_defs'],
    [7645, '+proj=lcc +lat_1=44.3625954694444 +lat_0=44.3625954694444 +lon_0=-90 +k_0=1.0000421209 +x_0=208483.617272034 +y_0=134589.753924307 +ellps=GRS80 +units=us-ft +no_defs'],
    [7656, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7657, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7658, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7659, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7660, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7661, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7662, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7663, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7664, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7665, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7677, '+proj=geocent +a=6378136 +b=6356751.36174571 +units=m +no_defs'],
    [7678, '+proj=longlat +a=6378136 +rf=298.257839303 +no_defs'],
    [7679, '+proj=geocent +a=6378136 +b=6356751.36174571 +units=m +no_defs'],
    [7680, '+proj=longlat +a=6378136 +rf=298.257839303 +no_defs'],
    [7681, '+proj=geocent +a=6378136.5 +b=6356751.7579556 +units=m +no_defs'],
    [7682, '+proj=longlat +ellps=GSK2011 +no_defs'],
    [7683, '+proj=longlat +a=6378136.5 +b=6356751.7579556 +no_defs'],
    [7684, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7685, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7686, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7692, '+proj=tmerc +lat_0=0 +lon_0=68.5166666666666 +k=1 +x_0=1300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [7693, '+proj=tmerc +lat_0=0 +lon_0=71.5166666666666 +k=1 +x_0=2300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [7694, '+proj=tmerc +lat_0=0 +lon_0=74.5166666666666 +k=1 +x_0=3300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [7695, '+proj=tmerc +lat_0=0 +lon_0=77.5166666666666 +k=1 +x_0=4300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [7696, '+proj=tmerc +lat_0=0 +lon_0=80.5166666666666 +k=1 +x_0=5300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [7755, '+proj=lcc +lat_1=12.472955 +lat_2=35.1728044444444 +lat_0=24 +lon_0=80 +x_0=4000000 +y_0=4000000 +datum=WGS84 +units=m +no_defs'],
    [7756, '+proj=lcc +lat_1=13.75 +lat_2=18.75 +lat_0=16.25543298 +lon_0=80.875 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7757, '+proj=lcc +lat_1=27 +lat_2=29 +lat_0=28.00157897 +lon_0=94.5 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7758, '+proj=lcc +lat_1=24.6666666666666 +lat_2=27.3333333333333 +lat_0=26.00257703 +lon_0=92.75 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7759, '+proj=lcc +lat_1=24.625 +lat_2=27.125 +lat_0=25.87725247 +lon_0=85.875 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7760, '+proj=lcc +lat_1=28.375 +lat_2=28.875 +lat_0=28.62510126 +lon_0=77 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7761, '+proj=lcc +lat_1=20.7916666666666 +lat_2=23.9583333333333 +lat_0=22.37807121 +lon_0=71.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7762, '+proj=lcc +lat_1=28.0833333333333 +lat_2=30.4166666666666 +lat_0=29.25226266 +lon_0=76 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7763, '+proj=lcc +lat_1=30.75 +lat_2=32.75 +lat_0=31.75183497 +lon_0=77.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7764, '+proj=lcc +lat_1=33.0833333333333 +lat_2=36.4166666666666 +lat_0=34.75570874 +lon_0=76.5 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7765, '+proj=lcc +lat_1=22.5416666666666 +lat_2=24.7083333333333 +lat_0=23.62652682 +lon_0=85.625 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7766, '+proj=lcc +lat_1=22 +lat_2=26 +lat_0=24.00529821 +lon_0=78.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7767, '+proj=lcc +lat_1=16.625 +lat_2=21.125 +lat_0=18.88015774 +lon_0=76.75 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7768, '+proj=lcc +lat_1=24.0833333333333 +lat_2=25.4166666666666 +lat_0=24.75060911 +lon_0=94 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7769, '+proj=lcc +lat_1=25.2083333333333 +lat_2=26.0416666666666 +lat_0=25.62524747 +lon_0=91.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7770, '+proj=lcc +lat_1=25.375 +lat_2=26.875 +lat_0=26.12581974 +lon_0=94.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7771, '+proj=lcc +lat_1=23.0416666666666 +lat_2=28.2083333333333 +lat_0=25.63452135 +lon_0=93.5 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7772, '+proj=lcc +lat_1=18.5833333333333 +lat_2=21.9166666666666 +lat_0=20.25305174 +lon_0=84.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7773, '+proj=lcc +lat_1=30 +lat_2=32 +lat_0=31.00178226 +lon_0=75.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7774, '+proj=lcc +lat_1=24.2916666666666 +lat_2=29.4583333333333 +lat_0=26.88505546 +lon_0=73.875 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7775, '+proj=lcc +lat_1=24.875 +lat_2=29.375 +lat_0=27.13270823 +lon_0=80.875 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7776, '+proj=lcc +lat_1=29 +lat_2=31 +lat_0=30.0017132 +lon_0=79.375 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7777, '+proj=tmerc +lat_0=10.25 +lon_0=93.25 +k=0.9999428 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7778, '+proj=tmerc +lat_0=21 +lon_0=82.25 +k=0.9998332 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7779, '+proj=tmerc +lat_0=15.375 +lon_0=74 +k=0.9999913 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7780, '+proj=tmerc +lat_0=15.125 +lon_0=76.375 +k=0.999801199999999 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7781, '+proj=tmerc +lat_0=10.5 +lon_0=76 +k=0.9999177 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7782, '+proj=tmerc +lat_0=10 +lon_0=73.125 +k=0.9999536 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7783, '+proj=tmerc +lat_0=23.125 +lon_0=92.75 +k=0.9999821 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7784, '+proj=tmerc +lat_0=27.625 +lon_0=88.5 +k=0.9999926 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7785, '+proj=tmerc +lat_0=10.875 +lon_0=78.375 +k=0.9997942 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7786, '+proj=tmerc +lat_0=23.75 +lon_0=91.75 +k=0.9999822 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7787, '+proj=tmerc +lat_0=24.375 +lon_0=87.875 +k=0.9998584 +x_0=1000000 +y_0=1000000 +datum=WGS84 +units=m +no_defs'],
    [7789, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7791, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7792, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7793, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7794, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9985000000000001 +x_0=7000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7795, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=3000000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7796, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7797, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7798, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7799, '+proj=utm +zone=34 +ellps=GRS80 +units=m +no_defs'],
    [7800, '+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs'],
    [7801, '+proj=lcc +lat_1=42 +lat_2=43.3333333333333 +lat_0=42.6678756833333 +lon_0=25.5 +x_0=500000 +y_0=4725824.3591 +ellps=GRS80 +units=m +no_defs'],
    [7803, '+proj=utm +zone=34 +ellps=GRS80 +units=m +no_defs'],
    [7804, '+proj=utm +zone=34 +ellps=GRS80 +units=m +no_defs'],
    [7805, '+proj=utm +zone=36 +ellps=GRS80 +units=m +no_defs'],
    [7815, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7816, '+proj=longlat +ellps=WGS84 +no_defs'],
    [7825, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=23.5 +k=1 +x_0=1300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7826, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=26.5 +k=1 +x_0=2300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7827, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=29.5 +k=1 +x_0=3300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7828, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=32.5 +k=1 +x_0=4300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7829, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=35.5 +k=1 +x_0=5300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7830, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=38.5 +k=1 +x_0=6300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7831, '+proj=tmerc +lat_0=0.08333333333333333 +lon_0=41.5 +k=1 +x_0=7300000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [7842, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7843, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7844, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7845, '+proj=lcc +lat_1=-18 +lat_2=-36 +lat_0=0 +lon_0=134 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [7846, '+proj=utm +zone=46 +south +ellps=GRS80 +units=m +no_defs'],
    [7847, '+proj=utm +zone=47 +south +ellps=GRS80 +units=m +no_defs'],
    [7848, '+proj=utm +zone=48 +south +ellps=GRS80 +units=m +no_defs'],
    [7849, '+proj=utm +zone=49 +south +ellps=GRS80 +units=m +no_defs'],
    [7850, '+proj=utm +zone=50 +south +ellps=GRS80 +units=m +no_defs'],
    [7851, '+proj=utm +zone=51 +south +ellps=GRS80 +units=m +no_defs'],
    [7852, '+proj=utm +zone=52 +south +ellps=GRS80 +units=m +no_defs'],
    [7853, '+proj=utm +zone=53 +south +ellps=GRS80 +units=m +no_defs'],
    [7854, '+proj=utm +zone=54 +south +ellps=GRS80 +units=m +no_defs'],
    [7855, '+proj=utm +zone=55 +south +ellps=GRS80 +units=m +no_defs'],
    [7856, '+proj=utm +zone=56 +south +ellps=GRS80 +units=m +no_defs'],
    [7857, '+proj=utm +zone=57 +south +ellps=GRS80 +units=m +no_defs'],
    [7858, '+proj=utm +zone=58 +south +ellps=GRS80 +units=m +no_defs'],
    [7859, '+proj=utm +zone=59 +south +ellps=GRS80 +units=m +no_defs'],
    [7877, '+proj=tmerc +lat_0=-15.96666666666667 +lon_0=-5.716666666666667 +k=1 +x_0=300000 +y_0=2000000 +ellps=intl +towgs84=-320,550,-494,0,0,0,0 +units=m +no_defs '],
    [7878, '+proj=utm +zone=30 +south +ellps=intl +towgs84=-320,550,-494,0,0,0,0 +units=m +no_defs '],
    [7879, '+proj=geocent +ellps=WGS84 +units=m +no_defs'],
    [7880, '+proj=longlat +ellps=WGS84 +towgs84=-0.077,0.079,0.086,0,0,0,0 +no_defs'],
    [7881, '+proj=longlat +ellps=WGS84 +towgs84=-0.077,0.079,0.086,0,0,0,0 +no_defs '],
    [7882, '+proj=tmerc +lat_0=-15.96666666666667 +lon_0=-5.716666666666667 +k=1 +x_0=299483.737 +y_0=2000527.879 +ellps=WGS84 +towgs84=-0.077,0.079,0.086,0,0,0,0 +units=m +no_defs '],
    [7883, '+proj=utm +zone=30 +south +ellps=WGS84 +towgs84=-0.077,0.079,0.086,0,0,0,0 +units=m +no_defs '],
    [7884, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7885, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [7886, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs '],
    [7887, '+proj=utm +zone=30 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [7899, '+proj=lcc +lat_1=-36 +lat_2=-38 +lat_0=-37 +lon_0=145 +x_0=2500000 +y_0=2500000 +ellps=GRS80 +units=m +no_defs'],
    [7900, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7901, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7902, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7903, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7904, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7905, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7906, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7907, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7908, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7909, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7910, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7911, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7912, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7914, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7915, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7916, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7917, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7918, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7919, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7920, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7921, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7922, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7923, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7924, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7925, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7926, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7927, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7928, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7929, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7930, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [7931, '+proj=longlat +ellps=GRS80 +no_defs'],
    [7954, '+proj=utm +zone=30 +south +ellps=intl +towgs84=-320,550,-494,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7955, '+proj=utm +zone=30 +south +ellps=WGS84 +towgs84=-0.077,0.079,0.086,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7956, '+proj=utm +zone=30 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs '],
    [7991, '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [7992, '+proj=utm +zone=33 +south +ellps=intl +towgs84=-254.1,-5.36,-100.29,0,0,0,0 +units=m +no_defs '],
    [8013, '+proj=tmerc +lat_0=0 +lon_0=117.883333333333 +k=1.0000044 +x_0=50000 +y_0=4100000 +ellps=GRS80 +units=m +no_defs'],
    [8014, '+proj=tmerc +lat_0=0 +lon_0=115.25 +k=1.0000022 +x_0=60000 +y_0=2700000 +ellps=GRS80 +units=m +no_defs'],
    [8015, '+proj=tmerc +lat_0=0 +lon_0=122.333333333333 +k=1.00000298 +x_0=50000 +y_0=2300000 +ellps=GRS80 +units=m +no_defs'],
    [8016, '+proj=tmerc +lat_0=0 +lon_0=115.433333333333 +k=0.99999592 +x_0=50000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [8017, '+proj=tmerc +lat_0=0 +lon_0=113.666666666666 +k=0.99999796 +x_0=50000 +y_0=3050000 +ellps=GRS80 +units=m +no_defs'],
    [8018, '+proj=tmerc +lat_0=0 +lon_0=105.625 +k=1.00002514 +x_0=50000 +y_0=1400000 +ellps=GRS80 +units=m +no_defs'],
    [8019, '+proj=tmerc +lat_0=0 +lon_0=96.875 +k=0.99999387 +x_0=50000 +y_0=1600000 +ellps=GRS80 +units=m +no_defs'],
    [8020, '+proj=tmerc +lat_0=0 +lon_0=115.933333333333 +k=1.000019 +x_0=40000 +y_0=4100000 +ellps=GRS80 +units=m +no_defs'],
    [8021, '+proj=tmerc +lat_0=0 +lon_0=121.883333333333 +k=1.0000055 +x_0=50000 +y_0=4050000 +ellps=GRS80 +units=m +no_defs'],
    [8022, '+proj=tmerc +lat_0=0 +lon_0=114.066666666666 +k=1.00000236 +x_0=50000 +y_0=2750000 +ellps=GRS80 +units=m +no_defs'],
    [8023, '+proj=tmerc +lat_0=0 +lon_0=114.583333333333 +k=1.00000628 +x_0=50000 +y_0=3450000 +ellps=GRS80 +units=m +no_defs'],
    [8024, '+proj=tmerc +lat_0=0 +lon_0=121.5 +k=1.00004949 +x_0=60000 +y_0=3800000 +ellps=GRS80 +units=m +no_defs'],
    [8025, '+proj=tmerc +lat_0=0 +lon_0=114.983333333333 +k=1.00000314 +x_0=50000 +y_0=3650000 +ellps=GRS80 +units=m +no_defs'],
    [8026, '+proj=tmerc +lat_0=0 +lon_0=114.315277777777 +k=1.000014 +x_0=55000 +y_0=3700000 +ellps=GRS80 +units=m +no_defs'],
    [8027, '+proj=tmerc +lat_0=0 +lon_0=116.933333333333 +k=0.9999989 +x_0=50000 +y_0=2550000 +ellps=GRS80 +units=m +no_defs'],
    [8028, '+proj=tmerc +lat_0=0 +lon_0=128.75 +k=1.0000165 +x_0=50000 +y_0=2100000 +ellps=GRS80 +units=m +no_defs'],
    [8029, '+proj=tmerc +lat_0=0 +lon_0=115.366666666666 +k=1.00000157 +x_0=50000 +y_0=3750000 +ellps=GRS80 +units=m +no_defs'],
    [8030, '+proj=tmerc +lat_0=0 +lon_0=115.166666666666 +k=1.0000055 +x_0=50000 +y_0=4050000 +ellps=GRS80 +units=m +no_defs'],
    [8031, '+proj=tmerc +lat_0=0 +lon_0=115.816666666666 +k=0.99999906 +x_0=50000 +y_0=3900000 +ellps=GRS80 +units=m +no_defs'],
    [8032, '+proj=tmerc +lat_0=0 +lon_0=118.6 +k=1.00000135 +x_0=50000 +y_0=2500000 +ellps=GRS80 +units=m +no_defs'],
    [8035, '+proj=utm +zone=20 +datum=WGS84 +units=us-ft +no_defs'],
    [8036, '+proj=utm +zone=21 +datum=WGS84 +units=us-ft +no_defs'],
    [8042, '+proj=longlat +a=6376045 +b=6355477.11290322 +pm=ferro +no_defs'],
    [8043, '+proj=longlat +a=6376045 +b=6355477.11290322 +pm=ferro +no_defs'],
    [8044, '+proj=cass +lat_0=48.0384638888888 +lon_0=31.8041805555555 +x_0=0 +y_0=0 +a=6376045 +b=6355477.11290322 +pm=ferro +units=m +no_defs'],
    [8045, '+proj=cass +lat_0=48.2087611111111 +lon_0=34.0409222222222 +x_0=0 +y_0=0 +a=6376045 +b=6355477.11290322 +pm=ferro +units=m +no_defs'],
    [8058, '+proj=lcc +lat_1=-30.75 +lat_2=-35.75 +lat_0=-33.25 +lon_0=147 +x_0=9300000 +y_0=4500000 +ellps=GRS80 +units=m +no_defs'],
    [8059, '+proj=lcc +lat_1=-28 +lat_2=-36 +lat_0=-32 +lon_0=135 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [8065, '+proj=omerc +lat_0=32.25 +lonc=-111.4 +alpha=45 +k=1.00011 +x_0=48768 +y_0=243840 +gamma=45 +ellps=GRS80 +units=ft +no_defs'],
    [8066, '+proj=tmerc +lat_0=31.25 +lon_0=-112.166666666666 +k=1.00009 +x_0=548640 +y_0=304800 +ellps=GRS80 +units=ft +no_defs'],
    [8067, '+proj=tmerc +lat_0=31.5 +lon_0=-113.166666666666 +k=1.000055 +x_0=182880 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8068, '+proj=lcc +lat_1=30.5 +lat_0=30.5 +lon_0=-110.75 +k_0=0.9998 +x_0=9144 +y_0=-188976 +ellps=GRS80 +units=ft +no_defs'],
    [8082, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=24500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8083, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=25500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8084, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8085, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8086, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8088, '+proj=lcc +lat_1=64.25 +lat_2=65.75 +lat_0=65 +lon_0=-19 +x_0=2700000 +y_0=300000 +ellps=GRS80 +units=m +no_defs'],
    [8090, '+proj=tmerc +lat_0=45.43888888888888 +lon_0=-88.14166666666668 +k=1.0000552095 +x_0=133502.6683 +y_0=0.0063 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8091, '+proj=tmerc +lat_0=45.43888888888888 +lon_0=-88.14166666666668 +k=1.0000552095 +x_0=133502.6682245364 +y_0=0.006400812801625603 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8092, '+proj=lcc +lat_1=44.87228112638889 +lat_0=44.87228112638889 +lon_0=-91.28888888888889 +k_0=1.000035079 +x_0=120091.4402 +y_0=91687.92389999999 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8093, '+proj=lcc +lat_1=44.87228112638889 +lat_0=44.87228112638889 +lon_0=-91.28888888888889 +k_0=1.000035079 +x_0=120091.4401828804 +y_0=91687.92390144781 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8095, '+proj=lcc +lat_1=44.36259546944444 +lat_0=44.36259546944444 +lon_0=-90 +k_0=1.0000421209 +x_0=208483.6173 +y_0=134589.754 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8096, '+proj=lcc +lat_1=44.36259546944444 +lat_0=44.36259546944444 +lon_0=-90 +k_0=1.0000421209 +x_0=208483.6172720346 +y_0=134589.7539243078 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8097, '+proj=lcc +lat_1=44.11394404583334 +lat_0=44.11394404583334 +lon_0=-89.24166666666667 +k_0=1.0000392096 +x_0=120091.4402 +y_0=45069.7587 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8098, '+proj=lcc +lat_1=44.11394404583334 +lat_0=44.11394404583334 +lon_0=-89.24166666666667 +k_0=1.0000392096 +x_0=120091.4401828804 +y_0=45069.7588011176 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8099, '+proj=tmerc +lat_0=43.42027777777778 +lon_0=-88.81666666666666 +k=1.0000333645 +x_0=185013.9709 +y_0=0.007 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8100, '+proj=tmerc +lat_0=43.42027777777778 +lon_0=-88.81666666666666 +k=1.0000333645 +x_0=185013.9709423419 +y_0=0.007010414020828041 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8101, '+proj=tmerc +lat_0=42.56944444444445 +lon_0=-88.22499999999999 +k=1.0000346179 +x_0=208788.418 +y_0=0.0034 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8102, '+proj=tmerc +lat_0=42.56944444444445 +lon_0=-88.22499999999999 +k=1.0000346179 +x_0=208788.4178816358 +y_0=0.003352806705613411 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8103, '+proj=tmerc +lat_0=42.91805555555555 +lon_0=-88.06388888888888 +k=1.00003738 +x_0=120091.4415 +y_0=0.003 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8104, '+proj=tmerc +lat_0=42.91805555555555 +lon_0=-88.06388888888888 +k=1.00003738 +x_0=120091.4414020828 +y_0=0.003048006096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8105, '+proj=lcc +lat_1=45.96121983333334 +lat_0=45.96121983333334 +lon_0=-91.78333333333333 +k_0=1.0000475376 +x_0=234086.8682 +y_0=188358.6058 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8106, '+proj=lcc +lat_1=45.96121983333334 +lat_0=45.96121983333334 +lon_0=-91.78333333333333 +k_0=1.0000475376 +x_0=234086.8681737363 +y_0=188358.6059436119 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8107, '+proj=lcc +lat_1=42.66946209694444 +lat_0=42.66946209694444 +lon_0=-88.54166666666667 +k_0=1.0000367192 +x_0=232562.8651 +y_0=111088.2224 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8108, '+proj=lcc +lat_1=42.66946209694444 +lat_0=42.66946209694444 +lon_0=-88.54166666666667 +k_0=1.0000367192 +x_0=232562.8651257302 +y_0=111088.2224028448 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8109, '+proj=lcc +lat_1=46.07784409055556 +lat_0=46.07784409055556 +lon_0=-89.48888888888889 +k_0=1.0000730142 +x_0=134417.0689 +y_0=50337.1092 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8110, '+proj=lcc +lat_1=46.07784409055556 +lat_0=46.07784409055556 +lon_0=-89.48888888888889 +k_0=1.0000730142 +x_0=134417.0688341377 +y_0=50337.10927101854 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8111, '+proj=lcc +lat_1=43.57503293972223 +lat_0=43.57503293972223 +lon_0=-90.78333333333333 +k_0=1.0000408158 +x_0=222504.4451 +y_0=47532.0602 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8112, '+proj=lcc +lat_1=43.57503293972223 +lat_0=43.57503293972223 +lon_0=-90.78333333333333 +k_0=1.0000408158 +x_0=222504.44500889 +y_0=47532.0603505207 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8113, '+proj=tmerc +lat_0=43.16111111111111 +lon_0=-91.36666666666666 +k=1.0000361538 +x_0=256946.9138 +y_0=0.0041 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8114, '+proj=tmerc +lat_0=43.16111111111111 +lon_0=-91.36666666666666 +k=1.0000361538 +x_0=256946.9138938278 +y_0=0.003962407924815849 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8115, '+proj=lcc +lat_1=45.17782208583333 +lat_0=45.17782208583333 +lon_0=-90.48333333333333 +k_0=1.0000597566 +x_0=187147.5744 +y_0=107746.7522 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8116, '+proj=lcc +lat_1=45.17782208583333 +lat_0=45.17782208583333 +lon_0=-90.48333333333333 +k_0=1.0000597566 +x_0=187147.5742951486 +y_0=107746.7521463043 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8117, '+proj=tmerc +lat_0=44.03611111111111 +lon_0=-92.63333333333334 +k=1.0000381803 +x_0=165506.7302 +y_0=0.0103 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8118, '+proj=tmerc +lat_0=44.03611111111111 +lon_0=-92.63333333333334 +k=1.0000381803 +x_0=165506.7300990602 +y_0=0.01036322072644145 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8119, '+proj=tmerc +lat_0=44.03611111111111 +lon_0=-88.60555555555555 +k=1.000032144 +x_0=262433.3253 +y_0=0.009599999999999999 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8120, '+proj=tmerc +lat_0=44.03611111111111 +lon_0=-88.60555555555555 +k=1.000032144 +x_0=262433.3251714504 +y_0=0.009448818897637795 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8121, '+proj=lcc +lat_1=45.90009913138888 +lat_0=45.90009913138888 +lon_0=-91.11666666666666 +k_0=1.0000573461 +x_0=216713.2336 +y_0=120734.1631 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8122, '+proj=lcc +lat_1=45.90009913138888 +lat_0=45.90009913138888 +lon_0=-91.11666666666666 +k_0=1.0000573461 +x_0=216713.2337312675 +y_0=120734.1631699263 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8123, '+proj=tmerc +lat_0=42.81944444444445 +lon_0=-89.90000000000001 +k=1.0000373868 +x_0=185623.5716 +y_0=0.0051 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8124, '+proj=tmerc +lat_0=42.81944444444445 +lon_0=-89.90000000000001 +k=1.0000373868 +x_0=185623.5715519431 +y_0=0.005181610363220727 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8125, '+proj=tmerc +lat_0=43.91944444444444 +lon_0=-91.06666666666666 +k=1.0000495976 +x_0=250546.1013 +y_0=0.0234 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8126, '+proj=tmerc +lat_0=43.91944444444444 +lon_0=-91.06666666666666 +k=1.0000495976 +x_0=250546.1013970028 +y_0=0.02346964693929388 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8127, '+proj=tmerc +lat_0=41.94444444444444 +lon_0=-89.07222222222222 +k=1.0000337311 +x_0=146304.2926 +y_0=0.0068 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8128, '+proj=tmerc +lat_0=41.94444444444444 +lon_0=-89.07222222222222 +k=1.0000337311 +x_0=146304.2926085852 +y_0=0.006705613411226822 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8129, '+proj=lcc +lat_1=43.3223129275 +lat_0=43.3223129275 +lon_0=-90.43055555555556 +k_0=1.0000375653 +x_0=202387.6048 +y_0=134255.4253 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8130, '+proj=lcc +lat_1=43.3223129275 +lat_0=43.3223129275 +lon_0=-90.43055555555556 +k_0=1.0000375653 +x_0=202387.6047752095 +y_0=134255.4254508509 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8131, '+proj=tmerc +lat_0=44.55555555555555 +lon_0=-90.48888888888889 +k=1.0000649554 +x_0=227990.8546 +y_0=0.0109 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8132, '+proj=tmerc +lat_0=44.55555555555555 +lon_0=-90.48888888888889 +k=1.0000649554 +x_0=227990.8544577089 +y_0=0.01097282194564389 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8133, '+proj=lcc +lat_1=44.41682397527777 +lat_0=44.41682397527777 +lon_0=-89.5 +k_0=1.000039936 +x_0=56388.1128 +y_0=50022.1874 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8134, '+proj=lcc +lat_1=44.41682397527777 +lat_0=44.41682397527777 +lon_0=-89.5 +k_0=1.000039936 +x_0=56388.11277622555 +y_0=50022.1874523749 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8135, '+proj=tmerc +lat_0=44.66111111111111 +lon_0=-92.63333333333334 +k=1.0000433849 +x_0=141732.2823 +y_0=0.0059 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8136, '+proj=tmerc +lat_0=44.66111111111111 +lon_0=-92.63333333333334 +k=1.0000433849 +x_0=141732.2822453645 +y_0=0.005791211582423164 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8137, '+proj=lcc +lat_1=44.63614887194444 +lat_0=44.63614887194444 +lon_0=-92.22777777777777 +k_0=1.0000362977 +x_0=167640.3354 +y_0=86033.0876 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8138, '+proj=lcc +lat_1=44.63614887194444 +lat_0=44.63614887194444 +lon_0=-92.22777777777777 +k_0=1.0000362977 +x_0=167640.3352806706 +y_0=86033.08773177546 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8139, '+proj=lcc +lat_1=45.70422377027778 +lat_0=45.70422377027778 +lon_0=-89.54444444444444 +k_0=1.0000686968 +x_0=70104.1401 +y_0=57588.0346 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8140, '+proj=lcc +lat_1=45.70422377027778 +lat_0=45.70422377027778 +lon_0=-89.54444444444444 +k_0=1.0000686968 +x_0=70104.14020828041 +y_0=57588.03474726949 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8141, '+proj=tmerc +lat_0=44.39722222222222 +lon_0=-87.90833333333335 +k=1.0000236869 +x_0=182880.3676 +y_0=0.0033 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8142, '+proj=tmerc +lat_0=44.39722222222222 +lon_0=-87.90833333333335 +k=1.0000236869 +x_0=182880.3675895352 +y_0=0.003352806705613411 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8143, '+proj=lcc +lat_1=44.00007392861111 +lat_0=44.00007392861111 +lon_0=-90.64166666666668 +k_0=1.0000434122 +x_0=204521.209 +y_0=121923.9861 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8144, '+proj=lcc +lat_1=44.00007392861111 +lat_0=44.00007392861111 +lon_0=-90.64166666666668 +k_0=1.0000434122 +x_0=204521.2090424181 +y_0=121923.9861823724 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8145, '+proj=tmerc +lat_0=44.71666666666667 +lon_0=-88.41666666666667 +k=1.0000362499 +x_0=105461.0121 +y_0=0.0029 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8146, '+proj=tmerc +lat_0=44.71666666666667 +lon_0=-88.41666666666667 +k=1.0000362499 +x_0=105461.0121412243 +y_0=0.003048006096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8147, '+proj=tmerc +lat_0=44.69166666666666 +lon_0=-87.71111111111111 +k=1.0000234982 +x_0=238658.8794 +y_0=0.0032 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8148, '+proj=tmerc +lat_0=44.69166666666666 +lon_0=-87.71111111111111 +k=1.0000234982 +x_0=238658.8794513589 +y_0=0.003048006096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8149, '+proj=lcc +lat_1=44.90090442361111 +lat_0=44.90090442361111 +lon_0=-89.77 +k_0=1.000053289 +x_0=74676.1493 +y_0=55049.2669 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8150, '+proj=lcc +lat_1=44.90090442361111 +lat_0=44.90090442361111 +lon_0=-89.77 +k_0=1.000053289 +x_0=74676.1493522987 +y_0=55049.26695453391 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8151, '+proj=tmerc +lat_0=44.84444444444445 +lon_0=-89.73333333333333 +k=1.0000599003 +x_0=116129.0323 +y_0=0.0058 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8152, '+proj=tmerc +lat_0=44.84444444444445 +lon_0=-89.73333333333333 +k=1.0000599003 +x_0=116129.0322580645 +y_0=0.005791211582423164 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8153, '+proj=lcc +lat_1=45.15423710527778 +lat_0=45.15423710527778 +lon_0=-89.03333333333333 +k_0=1.0000627024 +x_0=198425.197 +y_0=105279.7829 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8154, '+proj=lcc +lat_1=45.15423710527778 +lat_0=45.15423710527778 +lon_0=-89.03333333333333 +k_0=1.0000627024 +x_0=198425.1968503937 +y_0=105279.7828803657 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8155, '+proj=tmerc +lat_0=43.45111111111111 +lon_0=-91.31666666666666 +k=1.0000319985 +x_0=130454.6598 +y_0=0.0033 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8156, '+proj=tmerc +lat_0=43.45111111111111 +lon_0=-91.31666666666666 +k=1.0000319985 +x_0=130454.6596901194 +y_0=0.003352806705613411 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8157, '+proj=tmerc +lat_0=43.26666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.7614 +y_0=0.0012 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8158, '+proj=tmerc +lat_0=43.26666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.76154432308 +y_0=0.001219202438404877 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8159, '+proj=tmerc +lat_0=42.21666666666667 +lon_0=-87.89444444444445 +k=1.0000260649 +x_0=185928.3728 +y_0=0.0009 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8160, '+proj=tmerc +lat_0=42.21666666666667 +lon_0=-87.89444444444445 +k=1.0000260649 +x_0=185928.3727711455 +y_0=0.0009144018288036576 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8161, '+proj=tmerc +lat_0=44.25333512777778 +lon_0=-90.84429651944444 +k=1.0000353 +x_0=27000 +y_0=25000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8162, '+proj=tmerc +lat_0=44.25333512777778 +lon_0=-90.84429651944444 +k=1.0000353 +x_0=27000 +y_0=24999.99989839979 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8163, '+proj=tmerc +lat_0=45.43333333333333 +lon_0=-90.25555555555556 +k=1.0000677153 +x_0=220980.4419 +y_0=0.008500000000000001 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8164, '+proj=tmerc +lat_0=45.43333333333333 +lon_0=-90.25555555555556 +k=1.0000677153 +x_0=220980.4419608839 +y_0=0.008534417068834137 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8165, '+proj=tmerc +lat_0=42.53888888888888 +lon_0=-90.16111111111111 +k=1.0000394961 +x_0=113081.0261 +y_0=0.0045 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8166, '+proj=tmerc +lat_0=42.53888888888888 +lon_0=-90.16111111111111 +k=1.0000394961 +x_0=113081.0261620523 +y_0=0.004572009144018288 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8167, '+proj=lcc +lat_1=43.80700011777778 +lat_0=43.80700011777778 +lon_0=-89.24166666666667 +k_0=1.0000344057 +x_0=150876.3018 +y_0=79170.7795 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8168, '+proj=lcc +lat_1=43.80700011777778 +lat_0=43.80700011777778 +lon_0=-89.24166666666667 +k_0=1.0000344057 +x_0=150876.3017526035 +y_0=79170.77937515875 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8169, '+proj=lcc +lat_1=42.63756227694444 +lat_0=42.63756227694444 +lon_0=-89.83888888888889 +k_0=1.0000390487 +x_0=170078.7403 +y_0=45830.2947 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8170, '+proj=lcc +lat_1=42.63756227694444 +lat_0=42.63756227694444 +lon_0=-89.83888888888889 +k_0=1.0000390487 +x_0=170078.7401574803 +y_0=45830.29484378968 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8171, '+proj=tmerc +lat_0=41.41111111111111 +lon_0=-90.8 +k=1.0000349452 +x_0=242316.4841 +y_0=0.01 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8172, '+proj=tmerc +lat_0=41.41111111111111 +lon_0=-90.8 +k=1.0000349452 +x_0=242316.484023368 +y_0=0.01005842011684023 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8173, '+proj=tmerc +lat_0=44.00555555555555 +lon_0=-88.63333333333334 +k=1.0000673004 +x_0=275844.5533 +y_0=0.0157 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8177, '+proj=tmerc +lat_0=44.00555555555555 +lon_0=-88.63333333333334 +k=1.0000673004 +x_0=275844.5532131065 +y_0=0.0158496316992634 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8179, '+proj=tmerc +lat_0=44.40833333333333 +lon_0=-91.89444444444445 +k=1.0000410324 +x_0=51816.104 +y_0=0.003 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8180, '+proj=tmerc +lat_0=44.40833333333333 +lon_0=-91.89444444444445 +k=1.0000410324 +x_0=51816.10393700787 +y_0=0.003048006096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8181, '+proj=tmerc +lat_0=45.88333333333333 +lon_0=-91.91666666666667 +k=1.0000385418 +x_0=59131.3183 +y_0=0.0041 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8182, '+proj=tmerc +lat_0=45.88333333333333 +lon_0=-91.91666666666667 +k=1.0000385418 +x_0=59131.31826263652 +y_0=0.003962407924815849 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8184, '+proj=tmerc +lat_0=44.4 +lon_0=-87.27222222222223 +k=1.0000187521 +x_0=158801.1176 +y_0=0.0023 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8185, '+proj=tmerc +lat_0=44.4 +lon_0=-87.27222222222223 +k=1.0000187521 +x_0=158801.1176022352 +y_0=0.002438404876809754 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8187, '+proj=tmerc +lat_0=41.47222222222222 +lon_0=-88.77500000000001 +k=1.0000346418 +x_0=263347.7263 +y_0=0.0076 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8189, '+proj=tmerc +lat_0=41.47222222222222 +lon_0=-88.77500000000001 +k=1.0000346418 +x_0=263347.7263906528 +y_0=0.00762001524003048 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8191, '+proj=lcc +lat_1=43.0695160375 +lat_0=43.0695160375 +lon_0=-89.42222222222223 +k_0=1.0000384786 +x_0=247193.2944 +y_0=146591.9896 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8193, '+proj=lcc +lat_1=43.0695160375 +lat_0=43.0695160375 +lon_0=-89.42222222222223 +k_0=1.0000384786 +x_0=247193.2943865888 +y_0=146591.9896367793 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8196, '+proj=lcc +lat_1=43.200055605 +lat_0=43.200055605 +lon_0=-90.9388888888889 +k_0=1.0000349151 +x_0=113690.6274 +y_0=53703.1201 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8197, '+proj=lcc +lat_1=43.200055605 +lat_0=43.200055605 +lon_0=-90.9388888888889 +k_0=1.0000349151 +x_0=113690.6273812548 +y_0=53703.12024384048 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8198, '+proj=lcc +lat_1=43.46254664583333 +lat_0=43.46254664583333 +lon_0=-89.39444444444445 +k_0=1.00003498 +x_0=169164.3381 +y_0=111569.6134 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8200, '+proj=lcc +lat_1=43.46254664583333 +lat_0=43.46254664583333 +lon_0=-89.39444444444445 +k_0=1.00003498 +x_0=169164.338023876 +y_0=111569.613512827 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8201, '+proj=tmerc +lat_0=43.6 +lon_0=-90.70833333333334 +k=1.0000463003 +x_0=199949.1989 +y_0=0.0086 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8202, '+proj=tmerc +lat_0=43.6 +lon_0=-90.70833333333334 +k=1.0000463003 +x_0=199949.198983998 +y_0=0.008534417068834137 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8203, '+proj=lcc +lat_1=44.97785689861112 +lat_0=44.97785689861112 +lon_0=-91.29444444444444 +k_0=1.0000391127 +x_0=60045.72 +y_0=44091.4346 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8204, '+proj=lcc +lat_1=44.97785689861112 +lat_0=44.97785689861112 +lon_0=-91.29444444444444 +k_0=1.0000391127 +x_0=60045.72009144018 +y_0=44091.43449326898 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8205, '+proj=tmerc +lat_0=42.71944444444445 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8893 +y_0=0.0049 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8206, '+proj=tmerc +lat_0=42.71944444444445 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8892049784 +y_0=0.004876809753619507 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8207, '+proj=lcc +lat_1=45.89871486583333 +lat_0=45.89871486583333 +lon_0=-92.45777777777778 +k_0=1.0000383841 +x_0=64008.1276 +y_0=59445.9043 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8208, '+proj=lcc +lat_1=45.89871486583333 +lat_0=45.89871486583333 +lon_0=-92.45777777777778 +k_0=1.0000383841 +x_0=64008.12771145543 +y_0=59445.90419100838 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8209, '+proj=tmerc +lat_0=43.48138888888889 +lon_0=-91.79722222222222 +k=1.0000382778 +x_0=175260.3502 +y_0=0.0048 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8210, '+proj=tmerc +lat_0=43.48138888888889 +lon_0=-91.79722222222222 +k=1.0000382778 +x_0=175260.3502159004 +y_0=0.004876809753619507 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8212, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31600 +y_0=4600 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8213, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31599.99989839979 +y_0=4599.999898399797 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8214, '+proj=lcc +lat_1=46.66964837722222 +lat_0=46.66964837722222 +lon_0=-91.15277777777779 +k_0=1.0000331195 +x_0=228600.4575 +y_0=148551.4837 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8216, '+proj=lcc +lat_1=46.66964837722222 +lat_0=46.66964837722222 +lon_0=-91.15277777777779 +k_0=1.0000331195 +x_0=228600.4575057151 +y_0=148551.4835661671 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8218, '+proj=tmerc +lat_0=45.13333333333333 +lon_0=-91.84999999999999 +k=1.0000486665 +x_0=93150 +y_0=0.0029 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8220, '+proj=tmerc +lat_0=45.13333333333333 +lon_0=-91.84999999999999 +k=1.0000486665 +x_0=93150 +y_0=0.003048006096012192 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8222, '+proj=tmerc +lat_0=45.70611111111111 +lon_0=-90.62222222222222 +k=1.0000495683 +x_0=172821.9461 +y_0=0.0017 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8224, '+proj=tmerc +lat_0=45.70611111111111 +lon_0=-90.62222222222222 +k=1.0000495683 +x_0=172821.945948692 +y_0=0.001828803657607315 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8225, '+proj=tmerc +lat_0=43.36666666666667 +lon_0=-90 +k=1.0000365285 +x_0=147218.6942 +y_0=0.0037 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [8226, '+proj=tmerc +lat_0=43.36666666666667 +lon_0=-90 +k=1.0000365285 +x_0=147218.6941325883 +y_0=0.00365760731521463 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [8227, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8230, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8231, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8232, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8233, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8235, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8237, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8238, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8239, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8240, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8242, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8244, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8246, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8247, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8248, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8249, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8250, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8251, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8252, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8253, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8254, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8255, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8311, '+proj=tmerc +lat_0=43.5 +lon_0=-117.666666666666 +k=1.00014 +x_0=90000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8312, '+proj=tmerc +lat_0=43.5 +lon_0=-117.666666666666 +k=1.00014 +x_0=90000.00001488 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8313, '+proj=tmerc +lat_0=43.5 +lon_0=-119 +k=1.00022 +x_0=20000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8314, '+proj=tmerc +lat_0=43.5 +lon_0=-119 +k=1.00022 +x_0=19999.99999992 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8315, '+proj=lcc +lat_1=45.5833333333333 +lat_0=45.5833333333333 +lon_0=-123.416666666666 +k_0=1.000045 +x_0=30000 +y_0=20000 +ellps=GRS80 +units=m +no_defs'],
    [8316, '+proj=lcc +lat_1=45.5833333333333 +lat_0=45.5833333333333 +lon_0=-123.416666666666 +k_0=1.000045 +x_0=30000.00001512 +y_0=19999.99999992 +ellps=GRS80 +units=ft +no_defs'],
    [8317, '+proj=tmerc +lat_0=44.25 +lon_0=-119.633333333333 +k=1.00012 +x_0=20000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8318, '+proj=tmerc +lat_0=44.25 +lon_0=-119.633333333333 +k=1.00012 +x_0=19999.99999992 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8319, '+proj=tmerc +lat_0=41.75 +lon_0=-118.416666666666 +k=1.00019 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8320, '+proj=tmerc +lat_0=41.75 +lon_0=-118.416666666666 +k=1.00019 +x_0=79999.99999968 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8321, '+proj=lcc +lat_1=45.25 +lat_0=45.25 +lon_0=-117.25 +k_0=1.000085 +x_0=40000 +y_0=70000 +ellps=GRS80 +units=m +no_defs'],
    [8322, '+proj=lcc +lat_1=45.25 +lat_0=45.25 +lon_0=-117.25 +k_0=1.000085 +x_0=39999.99999984 +y_0=70000.0000149599 +ellps=GRS80 +units=ft +no_defs'],
    [8323, '+proj=lcc +lat_1=42 +lat_0=42 +lon_0=-122.25 +k_0=1.00004 +x_0=60000 +y_0=-60000 +ellps=GRS80 +units=m +no_defs'],
    [8324, '+proj=lcc +lat_1=42 +lat_0=42 +lon_0=-122.25 +k_0=1.00004 +x_0=59999.99999976 +y_0=-59999.99999976 +ellps=GRS80 +units=ft +no_defs'],
    [8325, '+proj=lcc +lat_1=47 +lat_0=47 +lon_0=-120.25 +k_0=0.99927 +x_0=30000 +y_0=290000 +ellps=GRS80 +units=m +no_defs'],
    [8326, '+proj=lcc +lat_1=47 +lat_0=47 +lon_0=-120.25 +k_0=0.99927 +x_0=30000.00001512 +y_0=290000.00001408 +ellps=GRS80 +units=ft +no_defs'],
    [8327, '+proj=lcc +lat_1=46.1666666666666 +lat_0=46.1666666666666 +lon_0=-120.5 +k_0=1 +x_0=100000 +y_0=140000 +ellps=GRS80 +units=m +no_defs'],
    [8328, '+proj=lcc +lat_1=46.1666666666666 +lat_0=46.1666666666666 +lon_0=-120.5 +k_0=1 +x_0=99999.9999996 +y_0=139999.99999944 +ellps=GRS80 +units=ft +no_defs'],
    [8329, '+proj=lcc +lat_1=43.5 +lat_0=43.5 +lon_0=-120.5 +k_0=1.00006 +x_0=40000 +y_0=-80000 +ellps=GRS80 +units=m +no_defs'],
    [8330, '+proj=lcc +lat_1=43.5 +lat_0=43.5 +lon_0=-120.5 +k_0=1.00006 +x_0=39999.99999984 +y_0=-79999.99999968 +ellps=GRS80 +units=ft +no_defs'],
    [8331, '+proj=tmerc +lat_0=41.75 +lon_0=-117.583333333333 +k=1.00018 +x_0=70000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8332, '+proj=tmerc +lat_0=41.75 +lon_0=-117.583333333333 +k=1.00018 +x_0=70000.0000149599 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8333, '+proj=lcc +lat_1=46.1666666666666 +lat_0=46.1666666666666 +lon_0=-119 +k_0=1.000025 +x_0=50000 +y_0=130000 +ellps=GRS80 +units=m +no_defs'],
    [8334, '+proj=lcc +lat_1=46.1666666666666 +lat_0=46.1666666666666 +lon_0=-119 +k_0=1.000025 +x_0=50000.00001504 +y_0=130000.00001472 +ellps=GRS80 +units=ft +no_defs'],
    [8335, '+proj=lcc +lat_1=44 +lat_0=44 +lon_0=-118 +k_0=1.00017 +x_0=60000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8336, '+proj=lcc +lat_1=44 +lat_0=44 +lon_0=-118 +k_0=1.00017 +x_0=59999.99999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8337, '+proj=tmerc +lat_0=41.75 +lon_0=-120.333333333333 +k=1.000215 +x_0=70000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8338, '+proj=tmerc +lat_0=41.75 +lon_0=-120.333333333333 +k=1.000215 +x_0=70000.0000149599 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8339, '+proj=lcc +lat_1=42.5 +lat_0=42.5 +lon_0=-122.583333333333 +k_0=1.00015 +x_0=10000 +y_0=60000 +ellps=GRS80 +units=m +no_defs'],
    [8340, '+proj=lcc +lat_1=42.5 +lat_0=42.5 +lon_0=-122.583333333333 +k_0=1.00015 +x_0=10000.0000152 +y_0=59999.99999976 +ellps=GRS80 +units=ft +no_defs'],
    [8341, '+proj=lcc +lat_1=45.25 +lat_0=45.25 +lon_0=-119 +k_0=1.00014 +x_0=30000 +y_0=90000 +ellps=GRS80 +units=m +no_defs'],
    [8342, '+proj=lcc +lat_1=45.25 +lat_0=45.25 +lon_0=-119 +k_0=1.00014 +x_0=30000.00001512 +y_0=90000.00001488 +ellps=GRS80 +units=ft +no_defs'],
    [8343, '+proj=tmerc +lat_0=45.25 +lon_0=-117.5 +k=1.000195 +x_0=60000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8344, '+proj=tmerc +lat_0=45.25 +lon_0=-117.5 +k=1.000195 +x_0=59999.99999976 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8345, '+proj=lcc +lat_1=42.5 +lat_0=42.5 +lon_0=-120 +k_0=1.000245 +x_0=40000 +y_0=60000 +ellps=GRS80 +units=m +no_defs'],
    [8346, '+proj=lcc +lat_1=42.5 +lat_0=42.5 +lon_0=-120 +k_0=1.000245 +x_0=39999.99999984 +y_0=59999.99999976 +ellps=GRS80 +units=ft +no_defs'],
    [8347, '+proj=tmerc +lat_0=43 +lon_0=-122 +k=1.000223 +x_0=20000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8348, '+proj=tmerc +lat_0=43 +lon_0=-122 +k=1.000223 +x_0=19999.99999992 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [8349, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [8350, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs '],
    [8351, '+proj=longlat +ellps=bessel +towgs84=485.021,169.465,483.839,7.786342,4.397554,4.102655,0 +no_defs'],
    [8352, '+proj=krovak +axis=swu +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=485.021,169.465,483.839,7.786342,4.397554,4.102655,0 +units=m +no_defs'],
    [8353, '+proj=krovak +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=485.021,169.465,483.839,7.786342,4.397554,4.102655,0 +units=m +no_defs'],
    [8360, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +vunits=m +no_defs'],
    [8370, '+proj=lcc +lat_0=50.797815 +lon_0=4.35921583333333 +lat_1=49.8333333333333 +lat_2=51.1666666666667 +x_0=649328 +y_0=665262 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8379, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.0001 +x_0=100000 +y_0=200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8380, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.0001 +x_0=99999.9999898399 +y_0=200000.00001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs'],
    [8381, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.000135 +x_0=300000 +y_0=400000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8382, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.000135 +x_0=300000 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs'],
    [8383, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.0001 +x_0=100000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [8384, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.0001 +x_0=99999.9999898399 +y_0=200000.00001016 +ellps=GRS80 +units=us-ft +no_defs'],
    [8385, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.000135 +x_0=300000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [8387, '+proj=tmerc +lat_0=36.25 +lon_0=-114.966666666667 +k=1.000135 +x_0=300000 +y_0=399999.99998984 +ellps=GRS80 +units=us-ft +no_defs'],
    [8391, '+proj=tmerc +lat_0=0 +lon_0=141 +k=0.999929 +x_0=300000 +y_0=2000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8395, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8397, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8399, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8401, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8403, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8425, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8426, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8427, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8428, '+proj=longlat +ellps=intl +no_defs'],
    [8429, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8430, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8431, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8433, '+proj=tmerc +lat_0=22.2123972222222 +lon_0=113.536469444444 +k=1 +x_0=20000 +y_0=20000 +ellps=intl +units=m +no_defs'],
    [8441, '+proj=labrd +lat_0=-18.9 +lon_0=46.4372291666667 +azi=18.9 +k=0.9995 +x_0=400000 +y_0=800000 +ellps=intl +towgs84=-198.383,-240.517,-107.909,0,0,0,0 +units=m +no_defs'],
    [8449, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8455, '+proj=utm +zone=53 +south +ellps=GRS80 +units=m +no_defs'],
    [8456, '+proj=utm +zone=54 +south +ellps=GRS80 +units=m +no_defs'],
    [8518, '+proj=tmerc +lat_0=37.5 +lon_0=-101.6 +k=1.000156 +x_0=457200.914401829 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8519, '+proj=tmerc +lat_0=37.5 +lon_0=-100.95 +k=1.000134 +x_0=762001.524003047 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8520, '+proj=tmerc +lat_0=37.5 +lon_0=-100.35 +k=1.000116 +x_0=1066802.13360427 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8521, '+proj=tmerc +lat_0=37.5 +lon_0=-99.45 +k=1.000082 +x_0=1371602.74320549 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8522, '+proj=tmerc +lat_0=37.5 +lon_0=-98.6666666666667 +k=1.000078 +x_0=1676403.3528067 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8523, '+proj=tmerc +lat_0=37.5 +lon_0=-98.15 +k=1.000068 +x_0=1981203.96240792 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8524, '+proj=tmerc +lat_0=37.5 +lon_0=-97.3333333333333 +k=1.000049 +x_0=2286004.57200914 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8525, '+proj=lcc +lat_1=39.1666666666667 +lat_0=39.1666666666667 +lon_0=-96.5 +k_0=1.000044 +x_0=2590805.18161036 +y_0=182880.365760731 +ellps=GRS80 +units=us-ft +no_defs'],
    [8526, '+proj=lcc +lat_1=38.5 +lat_0=38.5 +lon_0=-96.5 +k_0=1.00005 +x_0=2895605.79121158 +y_0=91440.1828803657 +ellps=GRS80 +units=us-ft +no_defs'],
    [8527, '+proj=lcc +lat_1=39.6333333333333 +lat_0=39.6333333333333 +lon_0=-95.75 +k_0=1.00004 +x_0=3200406.4008128 +y_0=213360.426720853 +ellps=GRS80 +units=us-ft +no_defs'],
    [8528, '+proj=lcc +lat_1=39.1 +lat_0=39.1 +lon_0=-95.25 +k_0=1.000033 +x_0=3505207.01041402 +y_0=182880.365760731 +ellps=GRS80 +units=us-ft +no_defs'],
    [8529, '+proj=tmerc +lat_0=36.75 +lon_0=-101.416666666667 +k=1.00014 +x_0=3810007.62001524 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8531, '+proj=tmerc +lat_0=36.75 +lon_0=-100.4 +k=1.000109 +x_0=4114808.22961646 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8533, '+proj=tmerc +lat_0=36.75 +lon_0=-99.6666666666667 +k=1.000097 +x_0=4419608.83921768 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8534, '+proj=tmerc +lat_0=36.75 +lon_0=-99.2 +k=1.000087 +x_0=4724409.4488189 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8535, '+proj=tmerc +lat_0=36.75 +lon_0=-98.55 +k=1.000069 +x_0=5029210.05842011 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8536, '+proj=lcc +lat_1=37.7666666666667 +lat_0=37.7666666666667 +lon_0=-97.5 +k_0=1.000059 +x_0=5334010.66802133 +y_0=121920.243840488 +ellps=GRS80 +units=us-ft +no_defs'],
    [8538, '+proj=lcc +lat_1=37.1833333333333 +lat_0=37.1833333333333 +lon_0=-97.5 +k_0=1.000055 +x_0=5638811.27762255 +y_0=60960.1219202438 +ellps=GRS80 +units=us-ft +no_defs'],
    [8539, '+proj=tmerc +lat_0=36.75 +lon_0=-95.9666666666667 +k=1.000034 +x_0=5943611.88722377 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8540, '+proj=tmerc +lat_0=36.75 +lon_0=-95.0833333333333 +k=1.000031 +x_0=6248412.49682499 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [8541, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8542, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8543, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8544, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8545, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8677, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=5500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs'],
    [8678, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs'],
    [8679, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=8500000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +units=m +no_defs'],
    [8682, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8683, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8684, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8685, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8686, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=0 +ellps=bessel +towgs84=476.08,125.947,417.81,4.610862,2.388137,-11.942335,9.896638 +units=m +no_defs'],
    [8687, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8692, '+proj=utm +zone=54 +ellps=GRS80 +units=m +no_defs'],
    [8693, '+proj=utm +zone=55 +ellps=GRS80 +units=m +no_defs'],
    [8700, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8701, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8702, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8703, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=47.0833333333333 +lat_2=45.4833333333333 +x_0=7999999.999968 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8704, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=45.7 +lat_2=44.1833333333333 +x_0=5999999.999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8705, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=43.6666666666667 +lat_2=42.1 +x_0=3999999.999984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8706, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=49 +lat_2=45 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8707, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=48.7333333333333 +lat_2=47.4333333333333 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8708, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=47.4833333333333 +lat_2=46.1833333333333 +x_0=599999.9999976 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8709, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=46 +lat_2=44.3333333333333 +x_0=2500000.0001424 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8710, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=44 +lat_2=42.3333333333333 +x_0=1500000.0001464 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8711, '+proj=lcc +lat_0=31.8333333333333 +lon_0=-81 +lat_1=34.8333333333333 +lat_2=32.5 +x_0=609600 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=ft +vunits=ft +no_defs'],
    [8712, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=36.2333333333333 +lat_2=34.9333333333333 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8713, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=34.7666666666667 +lat_2=33.3 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8714, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=41.6666666666667 +lat_2=40 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8715, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=39.8333333333333 +lat_2=38.3333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8716, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=38.4333333333333 +lat_2=37.0666666666667 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8717, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=37.25 +lat_2=36 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8718, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=35.4666666666667 +lat_2=34.0333333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8719, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=33.8833333333333 +lat_2=32.7833333333333 +x_0=2000000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8720, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=40.7833333333333 +lat_2=39.7166666666667 +x_0=914401.828803657 +y_0=304800.609601219 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8721, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=39.75 +lat_2=38.45 +x_0=914401.828803657 +y_0=304800.609601219 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8722, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=38.4333333333333 +lat_2=37.2333333333333 +x_0=914401.828803657 +y_0=304800.609601219 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8723, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.8666666666667 +lat_2=41.2 +x_0=304800.609601219 +y_0=152400.30480061 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8724, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8725, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=30.75 +lat_2=29.5833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8726, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8727, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8728, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8729, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=699999.999898399 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8730, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8731, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8732, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333 +x_0=800000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8733, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8734, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941177 +x_0=699999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8735, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966667 +x_0=99999.9998983997 +y_0=249999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8736, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=900000 +y_0=249999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8737, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=43.2666666666667 +lat_2=42.0666666666667 +x_0=1500000 +y_0=999999.999989839 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8738, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=41.7833333333333 +lat_2=40.6166666666667 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8739, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=39.7833333333333 +lat_2=38.7166666666667 +x_0=399999.99998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8740, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=38.5666666666667 +lat_2=37.2666666666667 +x_0=399999.99998984 +y_0=399999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8741, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8742, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.9333333333333 +lat_2=36.7333333333333 +x_0=500000.0001016 +y_0=500000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8743, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=32.6666666666667 +lat_2=31.1666666666667 +x_0=999999.999989839 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8744, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=30.7 +lat_2=29.3 +x_0=999999.999989839 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8745, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8746, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8747, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=39.45 +lat_2=38.3 +x_0=399999.9998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8748, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=42.6833333333333 +lat_2=41.7166666666667 +x_0=200000.0001016 +y_0=750000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8749, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.4833333333333 +lat_2=41.2833333333333 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8750, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=48.6333333333333 +lat_2=47.0333333333333 +x_0=800000.00001016 +y_0=99999.9999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8751, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=47.05 +lat_2=45.6166666666667 +x_0=800000.00001016 +y_0=99999.9999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8752, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=45.2166666666667 +lat_2=43.7833333333333 +x_0=800000.00001016 +y_0=99999.9999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8753, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8754, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=699999.999898399 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8755, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=43 +lat_2=40 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8756, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000.00001016 +y_0=8000000.00001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8757, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000.00001016 +y_0=6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8758, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000.00001016 +y_0=3999999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8759, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966667 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8760, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8761, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8762, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8763, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916667 +x_0=830000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8764, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8765, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=249999.9998984 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8766, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8767, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=41.0333333333333 +lat_2=40.6666666666667 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8768, '+proj=lcc +lat_0=33.75 +lon_0=-79 +lat_1=36.1666666666667 +lat_2=34.3333333333333 +x_0=609601.219202438 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8769, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=41.7 +lat_2=40.4333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8770, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=40.0333333333333 +lat_2=38.7333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8771, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=36.7666666666667 +lat_2=35.5666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8772, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=35.2333333333333 +lat_2=33.9333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8773, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=41.95 +lat_2=40.8833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8774, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=40.9666666666667 +lat_2=39.9333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8775, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=99999.9999898399 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8776, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=45.6833333333333 +lat_2=44.4166666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8777, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=44.4 +lat_2=42.8333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8778, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8779, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=36.1833333333333 +lat_2=34.65 +x_0=200000.0001016 +y_0=999999.999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8780, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=33.9666666666667 +lat_2=32.1333333333333 +x_0=600000 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8781, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=31.8833333333333 +lat_2=30.1166666666667 +x_0=699999.999898399 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8782, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=30.2833333333333 +lat_2=28.3833333333333 +x_0=600000 +y_0=3999999.9998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8783, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=27.8333333333333 +lat_2=26.1666666666667 +x_0=300000 +y_0=5000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8784, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=41.7833333333333 +lat_2=40.7166666666667 +x_0=500000.00001016 +y_0=999999.999989839 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8785, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=40.65 +lat_2=39.0166666666667 +x_0=500000.00001016 +y_0=2000000.00001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8786, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=38.35 +lat_2=37.2166666666667 +x_0=500000.00001016 +y_0=3000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8787, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8788, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=39.2 +lat_2=38.0333333333333 +x_0=3500000.0001016 +y_0=2000000.0001016 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8789, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=37.9666666666667 +lat_2=36.7666666666667 +x_0=3500000.0001016 +y_0=999999.999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8790, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=48.7333333333333 +lat_2=47.5 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8791, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=47.3333333333333 +lat_2=45.8333333333333 +x_0=500000.0001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8792, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=40.25 +lat_2=39 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8793, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=38.8833333333333 +lat_2=37.4833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8794, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=46.7666666666667 +lat_2=45.5666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8795, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=45.5 +lat_2=44.25 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8796, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=44.0666666666667 +lat_2=42.7333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8797, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8798, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=399999.99998984 +y_0=99999.9999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8799, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8800, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000.00001016 +y_0=99999.9999898399 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +vunits=us-ft +no_defs'],
    [8801, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8802, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8803, '+proj=omerc +no_uoff +lat_0=57 +lonc=-133.666666666667 +alpha=323.130102361111 +gamma=323.130102361111 +k=0.9999 +x_0=5000000 +y_0=-5000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8804, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8805, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8806, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8807, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8808, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8809, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8810, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8811, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +vunits=m +no_defs'],
    [8812, '+proj=lcc +lat_0=51 +lon_0=-176 +lat_1=53.8333333333333 +lat_2=51.8333333333333 +x_0=1000000 +y_0=0 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8813, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333 +x_0=250000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8814, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8815, '+proj=tmerc +lat_0=36.1666666666667 +lon_0=-94.5 +k=0.999941177 +x_0=850000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +vunits=m +no_defs'],
    [8816, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8817, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8818, '+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs'],
    [8826, '+proj=tmerc +lat_0=42 +lon_0=-114 +k=0.9996 +x_0=2500000 +y_0=1200000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8836, '+proj=utm +zone=36 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8837, '+proj=utm +zone=37 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8838, '+proj=utm +zone=38 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8839, '+proj=utm +zone=39 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8840, '+proj=utm +zone=40 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs'],
    [8857, '+proj=eqearth +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [8858, '+proj=eqearth +lon_0=-90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [8859, '+proj=eqearth +lon_0=150 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [8860, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8898, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8899, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8900, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8901, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8902, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8903, '+proj=utm +zone=1 +south +ellps=GRS80 +units=m +no_defs'],
    [8905, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [8906, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8907, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8908, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [8909, '+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs'],
    [8910, '+proj=utm +zone=17 +ellps=GRS80 +units=m +no_defs'],
    [8912, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +vunits=m +no_defs'],
    [8988, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8989, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8990, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8991, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8992, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8993, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8994, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8995, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8996, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8997, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8998, '+proj=longlat +ellps=GRS80 +no_defs'],
    [8999, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9000, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9001, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [9002, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9003, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9004, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [9005, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9006, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9007, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [9008, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9009, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9010, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [9011, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9012, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9013, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9014, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9015, '+proj=geocent +ellps=GRS80 +units=m +no_defs'],
    [9016, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9017, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9018, '+proj=longlat +ellps=GRS80 +no_defs'],
    [9019, '+proj=longlat +ellps=GRS80 +no_defs'],
    [20002, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=2500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [20003, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [20004, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20005, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20006, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20007, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20008, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20009, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20010, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=10500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20011, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=11500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20012, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=12500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20013, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20014, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=14500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20015, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=15500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20016, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=16500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20017, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=17500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20018, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20019, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20020, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=20500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20021, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=21500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20022, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=22500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20023, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=23500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20024, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=24500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20025, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=25500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20026, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=26500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20027, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=27500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20028, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=28500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20029, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=29500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20030, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=30500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20031, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=31500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20032, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=32500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20062, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [20063, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [20064, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20065, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20066, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20067, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20068, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20069, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20070, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20071, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20072, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20073, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20074, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20075, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20076, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20077, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20078, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20079, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20080, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20081, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20082, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20083, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20084, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20085, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20086, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20087, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20088, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20089, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20090, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20091, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20092, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=24.47,-130.89,-81.56,0,0,0.13,-0.22 +units=m +no_defs '],
    [20135, '+proj=utm +zone=35 +ellps=clrk80 +towgs84=-166,-15,204,0,0,0,0 +units=m +no_defs '],
    [20136, '+proj=utm +zone=36 +ellps=clrk80 +towgs84=-166,-15,204,0,0,0,0 +units=m +no_defs '],
    [20137, '+proj=utm +zone=37 +ellps=clrk80 +towgs84=-166,-15,204,0,0,0,0 +units=m +no_defs '],
    [20138, '+proj=utm +zone=38 +ellps=clrk80 +towgs84=-166,-15,204,0,0,0,0 +units=m +no_defs '],
    [20248, '+proj=utm +zone=48 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20249, '+proj=utm +zone=49 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20250, '+proj=utm +zone=50 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20251, '+proj=utm +zone=51 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20252, '+proj=utm +zone=52 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20253, '+proj=utm +zone=53 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20254, '+proj=utm +zone=54 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20255, '+proj=utm +zone=55 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20256, '+proj=utm +zone=56 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20257, '+proj=utm +zone=57 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20258, '+proj=utm +zone=58 +south +ellps=aust_SA +towgs84=-117.808,-51.536,137.784,0.303,0.446,0.234,-0.29 +units=m +no_defs '],
    [20348, '+proj=utm +zone=48 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20349, '+proj=utm +zone=49 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20350, '+proj=utm +zone=50 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20351, '+proj=utm +zone=51 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20352, '+proj=utm +zone=52 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20353, '+proj=utm +zone=53 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20354, '+proj=utm +zone=54 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20355, '+proj=utm +zone=55 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20356, '+proj=utm +zone=56 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20357, '+proj=utm +zone=57 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20358, '+proj=utm +zone=58 +south +ellps=aust_SA +towgs84=-134,-48,149,0,0,0,0 +units=m +no_defs '],
    [20436, '+proj=utm +zone=36 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [20437, '+proj=utm +zone=37 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [20438, '+proj=utm +zone=38 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [20439, '+proj=utm +zone=39 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [20440, '+proj=utm +zone=40 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [20499, '+proj=utm +zone=39 +ellps=intl +towgs84=-143,-236,7,0,0,0,0 +units=m +no_defs '],
    [20538, '+proj=utm +zone=38 +ellps=krass +towgs84=-43,-163,45,0,0,0,0 +units=m +no_defs '],
    [20539, '+proj=utm +zone=39 +ellps=krass +towgs84=-43,-163,45,0,0,0,0 +units=m +no_defs '],
    [20790, '+proj=tmerc +lat_0=39.66666666666666 +lon_0=1 +k=1 +x_0=200000 +y_0=300000 +ellps=intl +towgs84=-304.046,-60.576,103.64,0,0,0,0 +pm=lisbon +units=m +no_defs '],
    [20791, '+proj=tmerc +lat_0=39.66666666666666 +lon_0=1 +k=1 +x_0=0 +y_0=0 +ellps=intl +towgs84=-304.046,-60.576,103.64,0,0,0,0 +pm=lisbon +units=m +no_defs '],
    [20822, '+proj=utm +zone=22 +south +ellps=intl +towgs84=-151.99,287.04,-147.45,0,0,0,0 +units=m +no_defs '],
    [20823, '+proj=utm +zone=23 +south +ellps=intl +towgs84=-151.99,287.04,-147.45,0,0,0,0 +units=m +no_defs '],
    [20824, '+proj=utm +zone=24 +south +ellps=intl +towgs84=-151.99,287.04,-147.45,0,0,0,0 +units=m +no_defs '],
    [20934, '+proj=utm +zone=34 +south +a=6378249.145 +b=6356514.966398753 +towgs84=-143,-90,-294,0,0,0,0 +units=m +no_defs '],
    [20935, '+proj=utm +zone=35 +south +a=6378249.145 +b=6356514.966398753 +towgs84=-143,-90,-294,0,0,0,0 +units=m +no_defs '],
    [20936, '+proj=utm +zone=36 +south +a=6378249.145 +b=6356514.966398753 +towgs84=-143,-90,-294,0,0,0,0 +units=m +no_defs '],
    [21035, '+proj=utm +zone=35 +south +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +units=m +no_defs '],
    [21036, '+proj=utm +zone=36 +south +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +units=m +no_defs '],
    [21037, '+proj=utm +zone=37 +south +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +units=m +no_defs '],
    [21095, '+proj=utm +zone=35 +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +units=m +no_defs '],
    [21096, '+proj=utm +zone=36 +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +units=m +no_defs '],
    [21097, '+proj=utm +zone=37 +ellps=clrk80 +towgs84=-160,-6,-302,0,0,0,0 +units=m +no_defs '],
    [21100, '+proj=merc +lon_0=110 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +pm=jakarta +units=m +no_defs '],
    [21148, '+proj=utm +zone=48 +south +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +units=m +no_defs '],
    [21149, '+proj=utm +zone=49 +south +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +units=m +no_defs '],
    [21150, '+proj=utm +zone=50 +south +ellps=bessel +towgs84=-377,681,-50,0,0,0,0 +units=m +no_defs '],
    [21291, '+proj=tmerc +lat_0=0 +lon_0=-62 +k=0.9995000000000001 +x_0=400000 +y_0=0 +ellps=clrk80 +towgs84=31.95,300.99,419.19,0,0,0,0 +units=m +no_defs '],
    [21292, '+proj=tmerc +lat_0=13.17638888888889 +lon_0=-59.55972222222222 +k=0.9999986 +x_0=30000 +y_0=75000 +ellps=clrk80 +towgs84=31.95,300.99,419.19,0,0,0,0 +units=m +no_defs '],
    [21413, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21414, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=14500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21415, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=15500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21416, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=16500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21417, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=17500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21418, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21419, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21420, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=20500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21421, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=21500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21422, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=22500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21423, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=23500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21453, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21454, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21455, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21456, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21457, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21458, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21459, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21460, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21461, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21462, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21463, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21473, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21474, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21475, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21476, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21477, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21478, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21479, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21480, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21481, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21482, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21483, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=15.8,-154.4,-82.3,0,0,0,0 +units=m +no_defs '],
    [21500, '+proj=lcc +lat_1=49.8333333333333 +lat_2=51.1666666666666 +lat_0=90 +lon_0=0 +x_0=150000 +y_0=5400000 +ellps=intl +pm=brussels +units=m +no_defs'],
    [21780, '+proj=somerc +lat_0=46.95240555555556 +lon_0=0 +k_0=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +pm=bern +units=m +no_defs '],
    [21781, '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=600000 +y_0=200000 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs '],
    [21782, '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=674.374,15.056,405.346,0,0,0,0 +units=m +no_defs '],
    [21817, '+proj=utm +zone=17 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21818, '+proj=utm +zone=18 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21891, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-77.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21892, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-74.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21893, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-71.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21894, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-68.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21896, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-77.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21897, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-74.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21898, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-71.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [21899, '+proj=tmerc +lat_0=4.599047222222222 +lon_0=-68.08091666666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +towgs84=307,304,-318,0,0,0,0 +units=m +no_defs '],
    [22032, '+proj=utm +zone=32 +south +ellps=clrk80 +towgs84=-50.9,-347.6,-231,0,0,0,0 +units=m +no_defs '],
    [22033, '+proj=utm +zone=33 +south +ellps=clrk80 +towgs84=-50.9,-347.6,-231,0,0,0,0 +units=m +no_defs '],
    [22091, '+proj=tmerc +lat_0=0 +lon_0=11.5 +k=0.9996 +x_0=500000 +y_0=10000000 +ellps=clrk80 +towgs84=-50.9,-347.6,-231,0,0,0,0 +units=m +no_defs '],
    [22092, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9996 +x_0=500000 +y_0=10000000 +ellps=clrk80 +towgs84=-50.9,-347.6,-231,0,0,0,0 +units=m +no_defs '],
    [22171, '+proj=tmerc +lat_0=-90 +lon_0=-72 +k=1 +x_0=1500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22172, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22173, '+proj=tmerc +lat_0=-90 +lon_0=-66 +k=1 +x_0=3500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22174, '+proj=tmerc +lat_0=-90 +lon_0=-63 +k=1 +x_0=4500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22175, '+proj=tmerc +lat_0=-90 +lon_0=-60 +k=1 +x_0=5500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22176, '+proj=tmerc +lat_0=-90 +lon_0=-57 +k=1 +x_0=6500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22177, '+proj=tmerc +lat_0=-90 +lon_0=-54 +k=1 +x_0=7500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22181, '+proj=tmerc +lat_0=-90 +lon_0=-72 +k=1 +x_0=1500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22182, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22183, '+proj=tmerc +lat_0=-90 +lon_0=-66 +k=1 +x_0=3500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22184, '+proj=tmerc +lat_0=-90 +lon_0=-63 +k=1 +x_0=4500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22185, '+proj=tmerc +lat_0=-90 +lon_0=-60 +k=1 +x_0=5500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22186, '+proj=tmerc +lat_0=-90 +lon_0=-57 +k=1 +x_0=6500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22187, '+proj=tmerc +lat_0=-90 +lon_0=-54 +k=1 +x_0=7500000 +y_0=0 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [22191, '+proj=tmerc +lat_0=-90 +lon_0=-72 +k=1 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22192, '+proj=tmerc +lat_0=-90 +lon_0=-69 +k=1 +x_0=2500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22193, '+proj=tmerc +lat_0=-90 +lon_0=-66 +k=1 +x_0=3500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22194, '+proj=tmerc +lat_0=-90 +lon_0=-63 +k=1 +x_0=4500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22195, '+proj=tmerc +lat_0=-90 +lon_0=-60 +k=1 +x_0=5500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22196, '+proj=tmerc +lat_0=-90 +lon_0=-57 +k=1 +x_0=6500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22197, '+proj=tmerc +lat_0=-90 +lon_0=-54 +k=1 +x_0=7500000 +y_0=0 +ellps=intl +towgs84=-148,136,90,0,0,0,0 +units=m +no_defs '],
    [22234, '+proj=utm +zone=34 +south +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22235, '+proj=utm +zone=35 +south +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22236, '+proj=utm +zone=36 +south +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22275, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22277, '+proj=tmerc +lat_0=0 +lon_0=17 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22279, '+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22281, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22283, '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22285, '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22287, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22289, '+proj=tmerc +lat_0=0 +lon_0=29 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22291, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22293, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=0 +y_0=0 +axis=wsu +a=6378249.145 +b=6356514.966398753 +towgs84=-136,-108,-292,0,0,0,0 +units=m +no_defs '],
    [22300, ''],
    [22332, '+proj=utm +zone=32 +datum=carthage +units=m +no_defs'],
    [22391, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=9.9 +k_0=0.999625544 +x_0=500000 +y_0=300000 +datum=carthage +units=m +no_defs'],
    [22392, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=9.9 +k_0=0.999625769 +x_0=500000 +y_0=300000 +datum=carthage +units=m +no_defs'],
    [22521, '+proj=utm +zone=21 +south +ellps=intl +towgs84=-205.57,168.77,-4.12,0,0,0,0 +units=m +no_defs '],
    [22522, '+proj=utm +zone=22 +south +ellps=intl +towgs84=-205.57,168.77,-4.12,0,0,0,0 +units=m +no_defs '],
    [22523, '+proj=utm +zone=23 +south +ellps=intl +towgs84=-205.57,168.77,-4.12,0,0,0,0 +units=m +no_defs '],
    [22524, '+proj=utm +zone=24 +south +ellps=intl +towgs84=-205.57,168.77,-4.12,0,0,0,0 +units=m +no_defs '],
    [22525, '+proj=utm +zone=25 +south +ellps=intl +towgs84=-205.57,168.77,-4.12,0,0,0,0 +units=m +no_defs '],
    [22700, '+proj=lcc +lat_1=34.65 +lat_0=34.65 +lon_0=37.35 +k_0=0.9996256 +x_0=300000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=-190.421,8.532,238.69,0,0,0,0 +units=m +no_defs '],
    [22770, '+proj=lcc +lat_1=34.65 +lat_0=34.65 +lon_0=37.35 +k_0=0.9996256 +x_0=300000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=-190.421,8.532,238.69,0,0,0,0 +units=m +no_defs '],
    [22780, '+proj=sterea +lat_0=34.2 +lon_0=39.15 +k=0.9995341 +x_0=0 +y_0=0 +a=6378249.2 +b=6356515 +towgs84=-190.421,8.532,238.69,0,0,0,0 +units=m +no_defs '],
    [22832, '+proj=utm +zone=32 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [22991, '+proj=tmerc +lat_0=30 +lon_0=35 +k=1 +x_0=300000 +y_0=1100000 +ellps=helmert +towgs84=-130,110,-13,0,0,0,0 +units=m +no_defs '],
    [22992, '+proj=tmerc +lat_0=30 +lon_0=31 +k=1 +x_0=615000 +y_0=810000 +ellps=helmert +towgs84=-130,110,-13,0,0,0,0 +units=m +no_defs '],
    [22993, '+proj=tmerc +lat_0=30 +lon_0=27 +k=1 +x_0=700000 +y_0=200000 +ellps=helmert +towgs84=-130,110,-13,0,0,0,0 +units=m +no_defs '],
    [22994, '+proj=tmerc +lat_0=30 +lon_0=27 +k=1 +x_0=700000 +y_0=1200000 +ellps=helmert +towgs84=-130,110,-13,0,0,0,0 +units=m +no_defs '],
    [23028, '+proj=utm +zone=28 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23029, '+proj=utm +zone=29 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23030, '+proj=utm +zone=30 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23031, '+proj=utm +zone=31 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23032, '+proj=utm +zone=32 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23033, '+proj=utm +zone=33 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23034, '+proj=utm +zone=34 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23035, '+proj=utm +zone=35 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23036, '+proj=utm +zone=36 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23037, '+proj=utm +zone=37 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23038, '+proj=utm +zone=38 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23090, '+proj=tmerc +lat_0=0 +lon_0=0 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23095, '+proj=tmerc +lat_0=0 +lon_0=5 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-87,-98,-121,0,0,0,0 +units=m +no_defs '],
    [23239, '+proj=utm +zone=39 +ellps=clrk80 +towgs84=-333.102,-11.02,230.69,0,0,0.554,0.219 +units=m +no_defs '],
    [23240, '+proj=utm +zone=40 +ellps=clrk80 +towgs84=-333.102,-11.02,230.69,0,0,0.554,0.219 +units=m +no_defs '],
    [23433, '+proj=utm +zone=33 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [23700, '+proj=somerc +lat_0=47.14439372222222 +lon_0=19.04857177777778 +k_0=0.99993 +x_0=650000 +y_0=200000 +ellps=GRS67 +towgs84=52.17,-71.82,-14.9,0,0,0,0 +units=m +no_defs '],
    [23830, '+proj=tmerc +lat_0=0 +lon_0=94.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23831, '+proj=tmerc +lat_0=0 +lon_0=97.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23832, '+proj=tmerc +lat_0=0 +lon_0=100.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23833, '+proj=tmerc +lat_0=0 +lon_0=103.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23834, '+proj=tmerc +lat_0=0 +lon_0=106.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23835, '+proj=tmerc +lat_0=0 +lon_0=109.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23836, '+proj=tmerc +lat_0=0 +lon_0=112.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23837, '+proj=tmerc +lat_0=0 +lon_0=115.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23838, '+proj=tmerc +lat_0=0 +lon_0=118.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23839, '+proj=tmerc +lat_0=0 +lon_0=121.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23840, '+proj=tmerc +lat_0=0 +lon_0=124.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23841, '+proj=tmerc +lat_0=0 +lon_0=127.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23842, '+proj=tmerc +lat_0=0 +lon_0=130.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23843, '+proj=tmerc +lat_0=0 +lon_0=133.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23844, '+proj=tmerc +lat_0=0 +lon_0=136.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23845, '+proj=tmerc +lat_0=0 +lon_0=139.5 +k=0.9999 +x_0=200000 +y_0=1500000 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23846, '+proj=utm +zone=46 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23847, '+proj=utm +zone=47 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23848, '+proj=utm +zone=48 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23849, '+proj=utm +zone=49 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23850, '+proj=utm +zone=50 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23851, '+proj=utm +zone=51 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23852, '+proj=utm +zone=52 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23853, '+proj=utm +zone=53 +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23866, '+proj=utm +zone=46 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23867, '+proj=utm +zone=47 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23868, '+proj=utm +zone=48 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23869, '+proj=utm +zone=49 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23870, '+proj=utm +zone=50 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23871, '+proj=utm +zone=51 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23872, '+proj=utm +zone=52 +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23877, '+proj=utm +zone=47 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23878, '+proj=utm +zone=48 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23879, '+proj=utm +zone=49 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23880, '+proj=utm +zone=50 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23881, '+proj=utm +zone=51 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23882, '+proj=utm +zone=52 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23883, '+proj=utm +zone=53 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23884, '+proj=utm +zone=54 +south +ellps=WGS84 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [23886, '+proj=utm +zone=46 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23887, '+proj=utm +zone=47 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23888, '+proj=utm +zone=48 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23889, '+proj=utm +zone=49 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23890, '+proj=utm +zone=50 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23891, '+proj=utm +zone=51 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23892, '+proj=utm +zone=52 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23893, '+proj=utm +zone=53 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23894, '+proj=utm +zone=54 +south +a=6378160 +b=6356774.50408554 +towgs84=-24,-15,5,0,0,0,0 +units=m +no_defs '],
    [23946, '+proj=utm +zone=46 +a=6377276.345 +b=6356075.41314024 +towgs84=217,823,299,0,0,0,0 +units=m +no_defs '],
    [23947, '+proj=utm +zone=47 +a=6377276.345 +b=6356075.41314024 +towgs84=217,823,299,0,0,0,0 +units=m +no_defs '],
    [23948, '+proj=utm +zone=48 +a=6377276.345 +b=6356075.41314024 +towgs84=217,823,299,0,0,0,0 +units=m +no_defs '],
    [24047, '+proj=utm +zone=47 +a=6377276.345 +b=6356075.41314024 +towgs84=210,814,289,0,0,0,0 +units=m +no_defs '],
    [24048, '+proj=utm +zone=48 +a=6377276.345 +b=6356075.41314024 +towgs84=210,814,289,0,0,0,0 +units=m +no_defs '],
    [24100, '+proj=lcc +lat_1=18 +lat_0=18 +lon_0=-77 +k_0=1 +x_0=167638.49597 +y_0=121918.90616 +a=6378249.14480801 +b=6356514.96620413 +to_meter=0.3047972654 +no_defs'],
    [24200, '+proj=lcc +lat_1=18 +lat_0=18 +lon_0=-77 +k_0=1 +x_0=250000 +y_0=150000 +ellps=clrk66 +towgs84=70,207,389.5,0,0,0,0 +units=m +no_defs '],
    [24305, '+proj=utm +zone=45 +a=6377276.345 +b=6356075.41314024 +towgs84=214,804,268,0,0,0,0 +units=m +no_defs '],
    [24306, '+proj=utm +zone=46 +a=6377276.345 +b=6356075.41314024 +towgs84=214,804,268,0,0,0,0 +units=m +no_defs '],
    [24311, '+proj=utm +zone=41 +a=6377301.243 +b=6356100.230165384 +towgs84=283,682,231,0,0,0,0 +units=m +no_defs '],
    [24312, '+proj=utm +zone=42 +a=6377301.243 +b=6356100.230165384 +towgs84=283,682,231,0,0,0,0 +units=m +no_defs '],
    [24313, '+proj=utm +zone=43 +a=6377301.243 +b=6356100.230165384 +towgs84=283,682,231,0,0,0,0 +units=m +no_defs '],
    [24342, '+proj=utm +zone=42 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24343, '+proj=utm +zone=43 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24344, '+proj=utm +zone=44 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24345, '+proj=utm +zone=45 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24346, '+proj=utm +zone=46 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24347, '+proj=utm +zone=47 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24370, '+proj=lcc +lat_1=39.5 +lat_0=39.5 +lon_0=68 +k_0=0.99846154 +x_0=2153865.73916853 +y_0=2368292.1946281 +a=6377299.36559538 +b=6356098.35900515 +to_meter=0.91439853074444 +no_defs'],
    [24371, '+proj=lcc +lat_1=32.5 +lat_0=32.5 +lon_0=68 +k_0=0.99878641 +x_0=2743195.59223332 +y_0=914398.53074444 +a=6377299.36559538 +b=6356098.35900515 +to_meter=0.91439853074444 +no_defs'],
    [24372, '+proj=lcc +lat_1=26 +lat_0=26 +lon_0=74 +k_0=0.99878641 +x_0=2743195.59223332 +y_0=914398.53074444 +a=6377299.36559538 +b=6356098.35900515 +to_meter=0.91439853074444 +no_defs'],
    [24373, '+proj=lcc +lat_1=19 +lat_0=19 +lon_0=80 +k_0=0.99878641 +x_0=2743195.59223332 +y_0=914398.53074444 +a=6377299.36559538 +b=6356098.35900515 +to_meter=0.91439853074444 +no_defs'],
    [24374, '+proj=lcc +lat_1=12 +lat_0=12 +lon_0=80 +k_0=0.99878641 +x_0=2743195.59223332 +y_0=914398.53074444 +a=6377299.36559538 +b=6356098.35900515 +to_meter=0.91439853074444 +no_defs'],
    [24375, '+proj=lcc +lat_1=26 +lat_0=26 +lon_0=90 +k_0=0.99878641 +x_0=2743185.69 +y_0=914395.23 +a=6377276.345 +b=6356075.41314024 +towgs84=214,804,268,0,0,0,0 +units=m +no_defs '],
    [24376, '+proj=lcc +lat_1=32.5 +lat_0=32.5 +lon_0=68 +k_0=0.99878641 +x_0=2743196.4 +y_0=914398.8 +a=6377301.243 +b=6356100.230165384 +towgs84=283,682,231,0,0,0,0 +units=m +no_defs '],
    [24377, '+proj=lcc +lat_1=26 +lat_0=26 +lon_0=74 +k_0=0.99878641 +x_0=2743196.4 +y_0=914398.8 +a=6377301.243 +b=6356100.230165384 +towgs84=283,682,231,0,0,0,0 +units=m +no_defs '],
    [24378, '+proj=lcc +lat_1=32.5 +lat_0=32.5 +lon_0=68 +k_0=0.99878641 +x_0=2743195.5 +y_0=914398.5 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24379, '+proj=lcc +lat_1=26 +lat_0=26 +lon_0=74 +k_0=0.99878641 +x_0=2743195.5 +y_0=914398.5 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24380, '+proj=lcc +lat_1=26 +lat_0=26 +lon_0=90 +k_0=0.99878641 +x_0=2743195.5 +y_0=914398.5 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24381, '+proj=lcc +lat_1=19 +lat_0=19 +lon_0=80 +k_0=0.99878641 +x_0=2743195.5 +y_0=914398.5 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24382, '+proj=lcc +lat_1=26 +lat_0=26 +lon_0=90 +k_0=0.99878641 +x_0=2743195.59223332 +y_0=914398.53074444 +a=6377299.36559538 +b=6356098.35900515 +to_meter=0.91439853074444 +no_defs'],
    [24383, '+proj=lcc +lat_1=12 +lat_0=12 +lon_0=80 +k_0=0.99878641 +x_0=2743195.5 +y_0=914398.5 +a=6377299.151 +b=6356098.145120132 +towgs84=295,736,257,0,0,0,0 +units=m +no_defs '],
    [24500, '+proj=cass +lat_0=1.287646666666667 +lon_0=103.8530022222222 +x_0=30000 +y_0=30000 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [24547, '+proj=utm +zone=47 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [24548, '+proj=utm +zone=48 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +units=m +no_defs '],
    [24571, '+proj=omerc +lat_0=4 +lonc=102.25 +alpha=323.0257905 +k=0.99984 +x_0=804671.2997750348 +y_0=0 +no_uoff +gamma=323.1301023611111 +a=6377304.063 +b=6356103.038993155 +towgs84=-11,851,5,0,0,0,0 +to_meter=20.11678249437587 +no_defs '],
    [24600, '+proj=lcc +lat_1=32.5 +lat_0=32.5 +lon_0=45 +k_0=0.9987864078000001 +x_0=1500000 +y_0=1166200 +ellps=clrk80 +towgs84=-294.7,-200.1,525.5,0,0,0,0 +units=m +no_defs '],
    [24718, '+proj=utm +zone=18 +ellps=intl +towgs84=-273.5,110.6,-357.9,0,0,0,0 +units=m +no_defs '],
    [24719, '+proj=utm +zone=19 +ellps=intl +towgs84=-273.5,110.6,-357.9,0,0,0,0 +units=m +no_defs '],
    [24720, '+proj=utm +zone=20 +ellps=intl +towgs84=-273.5,110.6,-357.9,0,0,0,0 +units=m +no_defs '],
    [24721, '+proj=utm +zone=21 +ellps=intl +units=m +no_defs'],
    [24817, '+proj=utm +zone=17 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24818, '+proj=utm +zone=18 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24819, '+proj=utm +zone=19 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24820, '+proj=utm +zone=20 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24821, '+proj=utm +zone=21 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24877, '+proj=utm +zone=17 +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24878, '+proj=utm +zone=18 +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24879, '+proj=utm +zone=19 +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24880, '+proj=utm +zone=20 +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24881, '+proj=utm +zone=21 +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24882, '+proj=utm +zone=22 +south +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24891, '+proj=tmerc +lat_0=-6 +lon_0=-80.5 +k=0.99983008 +x_0=222000 +y_0=1426834.743 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24892, '+proj=tmerc +lat_0=-9.5 +lon_0=-76 +k=0.99932994 +x_0=720000 +y_0=1039979.159 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [24893, '+proj=tmerc +lat_0=-9.5 +lon_0=-70.5 +k=0.99952992 +x_0=1324000 +y_0=1040084.558 +ellps=intl +towgs84=-288,175,-376,0,0,0,0 +units=m +no_defs '],
    [25000, '+proj=tmerc +lat_0=4.666666666666667 +lon_0=-1 +k=0.99975 +x_0=274319.51 +y_0=0 +ellps=clrk80 +towgs84=-130,29,364,0,0,0,0 +units=m +no_defs '],
    [25231, '+proj=utm +zone=31 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [25391, '+proj=tmerc +lat_0=0 +lon_0=117 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs '],
    [25392, '+proj=tmerc +lat_0=0 +lon_0=119 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs '],
    [25393, '+proj=tmerc +lat_0=0 +lon_0=121 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs '],
    [25394, '+proj=tmerc +lat_0=0 +lon_0=123 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs '],
    [25395, '+proj=tmerc +lat_0=0 +lon_0=125 +k=0.99995 +x_0=500000 +y_0=0 +ellps=clrk66 +towgs84=-133,-77,-51,0,0,0,0 +units=m +no_defs '],
    [25700, '+proj=merc +lon_0=110 +k=0.997 +x_0=3900000 +y_0=900000 +ellps=bessel +towgs84=-587.8,519.75,145.76,0,0,0,0 +pm=jakarta +units=m +no_defs '],
    [25828, '+proj=utm +zone=28 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25829, '+proj=utm +zone=29 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25830, '+proj=utm +zone=30 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25831, '+proj=utm +zone=31 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25832, '+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25833, '+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25834, '+proj=utm +zone=34 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25835, '+proj=utm +zone=35 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25836, '+proj=utm +zone=36 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25837, '+proj=utm +zone=37 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25838, '+proj=utm +zone=38 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25884, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [25932, '+proj=utm +zone=32 +south +ellps=intl +towgs84=-254.1,-5.36,-100.29,0,0,0,0 +units=m +no_defs '],
    [26191, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs '],
    [26192, '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.9996155960000001 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs '],
    [26193, '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.9996 +x_0=1200000 +y_0=400000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs '],
    [26194, '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.999616304 +x_0=1200000 +y_0=400000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs '],
    [26195, '+proj=lcc +lat_1=22.5 +lat_0=22.5 +lon_0=-5.4 +k_0=0.999616437 +x_0=1500000 +y_0=400000 +a=6378249.2 +b=6356515 +towgs84=31,146,47,0,0,0,0 +units=m +no_defs '],
    [26237, '+proj=utm +zone=37 +ellps=bessel +towgs84=639,405,60,0,0,0,0 +units=m +no_defs '],
    [26331, '+proj=utm +zone=31 +ellps=clrk80 +towgs84=-92,-93,122,0,0,0,0 +units=m +no_defs '],
    [26332, '+proj=utm +zone=32 +ellps=clrk80 +towgs84=-92,-93,122,0,0,0,0 +units=m +no_defs '],
    [26391, '+proj=tmerc +lat_0=4 +lon_0=4.5 +k=0.99975 +x_0=230738.26 +y_0=0 +ellps=clrk80 +towgs84=-92,-93,122,0,0,0,0 +units=m +no_defs '],
    [26392, '+proj=tmerc +lat_0=4 +lon_0=8.5 +k=0.99975 +x_0=670553.98 +y_0=0 +ellps=clrk80 +towgs84=-92,-93,122,0,0,0,0 +units=m +no_defs '],
    [26393, '+proj=tmerc +lat_0=4 +lon_0=12.5 +k=0.99975 +x_0=1110369.7 +y_0=0 +ellps=clrk80 +towgs84=-92,-93,122,0,0,0,0 +units=m +no_defs '],
    [26432, '+proj=utm +zone=32 +south +ellps=intl +towgs84=-252.95,-4.11,-96.38,0,0,0,0 +units=m +no_defs '],
    [26591, '+proj=tmerc +lat_0=0 +lon_0=-3.45233333333333 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +pm=rome +units=m +no_defs '],
    [26592, '+proj=tmerc +lat_0=0 +lon_0=2.54766666666666 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +towgs84=-104.1,-49.1,-9.9,0.971,-2.917,0.714,-11.68 +pm=rome +units=m +no_defs '],
    [26632, '+proj=utm +zone=32 +a=6378249.2 +b=6356515 +towgs84=-74,-130,42,0,0,0,0 +units=m +no_defs '],
    [26692, '+proj=utm +zone=32 +south +a=6378249.2 +b=6356515 +towgs84=-74,-130,42,0,0,0,0 +units=m +no_defs '],
    [26701, '+proj=utm +zone=1 +datum=NAD27 +units=m +no_defs'],
    [26702, '+proj=utm +zone=2 +datum=NAD27 +units=m +no_defs'],
    [26703, '+proj=utm +zone=3 +datum=NAD27 +units=m +no_defs'],
    [26704, '+proj=utm +zone=4 +datum=NAD27 +units=m +no_defs'],
    [26705, '+proj=utm +zone=5 +datum=NAD27 +units=m +no_defs'],
    [26706, '+proj=utm +zone=6 +datum=NAD27 +units=m +no_defs'],
    [26707, '+proj=utm +zone=7 +datum=NAD27 +units=m +no_defs'],
    [26708, '+proj=utm +zone=8 +datum=NAD27 +units=m +no_defs'],
    [26709, '+proj=utm +zone=9 +datum=NAD27 +units=m +no_defs'],
    [26710, '+proj=utm +zone=10 +datum=NAD27 +units=m +no_defs'],
    [26711, '+proj=utm +zone=11 +datum=NAD27 +units=m +no_defs'],
    [26712, '+proj=utm +zone=12 +datum=NAD27 +units=m +no_defs'],
    [26713, '+proj=utm +zone=13 +datum=NAD27 +units=m +no_defs'],
    [26714, '+proj=utm +zone=14 +datum=NAD27 +units=m +no_defs'],
    [26715, '+proj=utm +zone=15 +datum=NAD27 +units=m +no_defs'],
    [26716, '+proj=utm +zone=16 +datum=NAD27 +units=m +no_defs'],
    [26717, '+proj=utm +zone=17 +datum=NAD27 +units=m +no_defs'],
    [26718, '+proj=utm +zone=18 +datum=NAD27 +units=m +no_defs'],
    [26719, '+proj=utm +zone=19 +datum=NAD27 +units=m +no_defs'],
    [26720, '+proj=utm +zone=20 +datum=NAD27 +units=m +no_defs'],
    [26721, '+proj=utm +zone=21 +datum=NAD27 +units=m +no_defs'],
    [26722, '+proj=utm +zone=22 +datum=NAD27 +units=m +no_defs'],
    [26729, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26730, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26731, '+proj=omerc +lat_0=57 +lonc=-133.666666666666 +alpha=323.130102361111 +k=0.9999 +x_0=5000000.001016 +y_0=-5000000.001016 +no_uoff +gamma=323.130102361111 +datum=NAD27 +units=us-ft +no_defs'],
    [26732, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26733, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26734, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26735, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26736, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26737, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=213360.426720853 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26738, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26739, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=182880.365760731 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26740, '+proj=lcc +lat_1=53.8333333333333 +lat_2=51.8333333333333 +lat_0=51 +lon_0=-176 +x_0=914401.828803657 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26741, '+proj=lcc +lat_1=41.6666666666666 +lat_2=40 +lat_0=39.3333333333333 +lon_0=-122 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26742, '+proj=lcc +lat_1=39.8333333333333 +lat_2=38.3333333333333 +lat_0=37.6666666666666 +lon_0=-122 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26743, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.0666666666666 +lat_0=36.5 +lon_0=-120.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26744, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.3333333333333 +lon_0=-119 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26745, '+proj=lcc +lat_1=35.4666666666666 +lat_2=34.0333333333333 +lat_0=33.5 +lon_0=-118 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26746, '+proj=lcc +lat_1=33.8833333333333 +lat_2=32.7833333333333 +lat_0=32.1666666666666 +lon_0=-116.25 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26747, '+proj=lcc +lat_1=34.4166666666666 +lat_2=33.8666666666666 +lat_0=34.1333333333333 +lon_0=-118.333333333333 +x_0=1276106.4505969 +y_0=127079.524511049 +datum=NAD27 +units=us-ft +no_defs'],
    [26748, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666666 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26749, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666666 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26750, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26751, '+proj=lcc +lat_1=36.2333333333333 +lat_2=34.9333333333333 +lat_0=34.3333333333333 +lon_0=-92 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26752, '+proj=lcc +lat_1=34.7666666666666 +lat_2=33.3 +lat_0=32.6666666666666 +lon_0=-92 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26753, '+proj=lcc +lat_1=39.7166666666666 +lat_2=40.7833333333333 +lat_0=39.3333333333333 +lon_0=-105.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26754, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.8333333333333 +lon_0=-105.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26755, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.2333333333333 +lat_0=36.6666666666666 +lon_0=-105.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26756, '+proj=lcc +lat_1=41.8666666666666 +lat_2=41.2 +lat_0=40.8333333333333 +lon_0=-72.75 +x_0=182880.365760731 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26757, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666666 +k=0.999995 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26758, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26759, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26760, '+proj=lcc +lat_1=30.75 +lat_2=29.5833333333333 +lat_0=29 +lon_0=-84.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26761, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966666666667 +x_0=152400.30480061 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26762, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966666666667 +x_0=152400.30480061 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26763, '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=152400.30480061 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26764, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=152400.30480061 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26765, '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=152400.30480061 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26766, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666666 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26767, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666666 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26768, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-112.166666666666 +k=0.999947367999999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26769, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-114 +k=0.999947367999999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26770, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26771, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-88.3333333333333 +k=0.999974999999999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26772, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-90.1666666666666 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26773, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666666 +k=0.999966667 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26774, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26775, '+proj=lcc +lat_1=43.2666666666666 +lat_2=42.0666666666666 +lat_0=41.5 +lon_0=-93.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26776, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.6166666666666 +lat_0=40 +lon_0=-93.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26777, '+proj=lcc +lat_1=39.7833333333333 +lat_2=38.7166666666666 +lat_0=38.3333333333333 +lon_0=-98 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26778, '+proj=lcc +lat_1=38.5666666666666 +lat_2=37.2666666666666 +lat_0=36.6666666666666 +lon_0=-98.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26779, '+proj=lcc +lat_1=37.9666666666666 +lat_2=38.9666666666666 +lat_0=37.5 +lon_0=-84.25 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26780, '+proj=lcc +lat_1=36.7333333333333 +lat_2=37.9333333333333 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26781, '+proj=lcc +lat_1=31.1666666666666 +lat_2=32.6666666666666 +lat_0=30.6666666666666 +lon_0=-92.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26782, '+proj=lcc +lat_1=29.3 +lat_2=30.7 +lat_0=28.6666666666666 +lon_0=-91.3333333333333 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26783, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-68.5 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26784, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666666 +k=0.999966667 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26785, '+proj=lcc +lat_1=38.3 +lat_2=39.45 +lat_0=37.8333333333333 +lon_0=-77 +x_0=243840.487680975 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26786, '+proj=lcc +lat_1=41.7166666666666 +lat_2=42.6833333333333 +lat_0=41 +lon_0=-71.5 +x_0=182880.365760731 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26787, '+proj=lcc +lat_1=41.2833333333333 +lat_2=41.4833333333333 +lat_0=41 +lon_0=-70.5 +x_0=60960.1219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26788, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26789, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3333333333333 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26790, '+proj=lcc +lat_0=41.5 +lon_0=-84.3333333333333 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26791, '+proj=lcc +lat_1=47.0333333333333 +lat_2=48.6333333333333 +lat_0=46.5 +lon_0=-93.0999999999999 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26792, '+proj=lcc +lat_1=45.6166666666666 +lat_2=47.05 +lat_0=45 +lon_0=-94.25 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26793, '+proj=lcc +lat_1=43.7833333333333 +lat_2=45.2166666666666 +lat_0=43 +lon_0=-94 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26794, '+proj=tmerc +lat_0=29.6666666666666 +lon_0=-88.8333333333333 +k=0.99996 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26795, '+proj=tmerc +lat_0=30.5 +lon_0=-90.3333333333333 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26796, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26797, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26798, '+proj=tmerc +lat_0=36.1666666666666 +lon_0=-94.5 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [26799, '+proj=lcc +lat_1=34.4166666666666 +lat_2=33.8666666666666 +lat_0=34.1333333333333 +lon_0=-118.333333333333 +x_0=1276106.4505969 +y_0=1268253.00685801 +datum=NAD27 +units=us-ft +no_defs'],
    [26801, '+proj=tmerc +lat_0=41.5 +lon_0=-83.6666666666666 +k=0.999942857 +x_0=152400.304800609 +y_0=0 +a=6378450.04754889 +b=6356826.62148844 +units=us-ft +no_defs'],
    [26802, '+proj=tmerc +lat_0=41.5 +lon_0=-85.75 +k=0.999909091 +x_0=152400.304800609 +y_0=0 +a=6378450.04754889 +b=6356826.62148844 +units=us-ft +no_defs'],
    [26803, '+proj=tmerc +lat_0=41.5 +lon_0=-88.75 +k=0.999909091 +x_0=152400.304800609 +y_0=0 +a=6378450.04754889 +b=6356826.62148844 +units=us-ft +no_defs'],
    [26811, '+proj=lcc +lat_1=45.4833333333333 +lat_2=47.0833333333333 +lat_0=44.7833333333333 +lon_0=-87 +x_0=609601.219202438 +y_0=0 +a=6378450.04754889 +b=6356826.62148844 +units=us-ft +no_defs'],
    [26812, '+proj=lcc +lat_1=44.1833333333333 +lat_2=45.7 +lat_0=43.3166666666666 +lon_0=-84.3333333333333 +x_0=609601.219202438 +y_0=0 +a=6378450.04754889 +b=6356826.62148844 +units=us-ft +no_defs'],
    [26813, '+proj=lcc +lat_1=42.1 +lat_2=43.6666666666666 +lat_0=41.5 +lon_0=-84.3333333333333 +x_0=609601.219202438 +y_0=0 +a=6378450.04754889 +b=6356826.62148844 +units=us-ft +no_defs'],
    [26814, '+proj=tmerc +lat_0=43.6666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26815, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666666 +k=0.999966667 +x_0=900000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26819, '+proj=lcc +lat_1=48.6333333333333 +lat_2=47.0333333333333 +lat_0=46.5 +lon_0=-93.0999999999999 +x_0=800000.00001016 +y_0=99999.99998984 +datum=NAD83 +units=m +no_defs'],
    [26820, '+proj=lcc +lat_1=47.05 +lat_2=45.6166666666666 +lat_0=45 +lon_0=-94.25 +x_0=800000.00001016 +y_0=99999.99998984 +datum=NAD83 +units=m +no_defs'],
    [26821, '+proj=lcc +lat_1=45.2166666666666 +lat_2=43.7833333333333 +lat_0=43 +lon_0=-94 +x_0=800000.00001016 +y_0=99999.99998984 +datum=NAD83 +units=m +no_defs'],
    [26822, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.8333333333333 +lon_0=-100 +x_0=500000.00001016 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26823, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=1968500 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26824, '+proj=lcc +lat_1=38.8833333333333 +lat_2=37.4833333333333 +lat_0=37 +lon_0=-81 +x_0=1968500 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26825, '+proj=tmerc +lat_0=43.66666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26826, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.16666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26830, '+proj=lcc +lat_1=48.63333333333333 +lat_2=47.03333333333333 +lat_0=46.5 +lon_0=-93.09999999999999 +x_0=800000.0000101601 +y_0=99999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26831, '+proj=lcc +lat_1=47.05 +lat_2=45.61666666666667 +lat_0=45 +lon_0=-94.25 +x_0=800000.0000101601 +y_0=99999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26832, '+proj=lcc +lat_1=45.21666666666667 +lat_2=43.78333333333333 +lat_0=43 +lon_0=-94 +x_0=800000.0000101601 +y_0=99999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26833, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000.0000101601 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26834, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=1968500 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26835, '+proj=lcc +lat_1=38.88333333333333 +lat_2=37.48333333333333 +lat_0=37 +lon_0=-81 +x_0=1968500 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26836, '+proj=tmerc +lat_0=43.66666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26837, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.16666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26841, '+proj=lcc +lat_1=48.63333333333333 +lat_2=47.03333333333333 +lat_0=46.5 +lon_0=-93.09999999999999 +x_0=800000.0000101601 +y_0=99999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26842, '+proj=lcc +lat_1=47.05 +lat_2=45.61666666666667 +lat_0=45 +lon_0=-94.25 +x_0=800000.0000101601 +y_0=99999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26843, '+proj=lcc +lat_1=45.21666666666667 +lat_2=43.78333333333333 +lat_0=43 +lon_0=-94 +x_0=800000.0000101601 +y_0=99999.99998984 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26844, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000.0000101601 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26845, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=1968500 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26846, '+proj=lcc +lat_1=38.88333333333333 +lat_2=37.48333333333333 +lat_0=37 +lon_0=-81 +x_0=1968500 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26847, '+proj=tmerc +lat_0=43.6666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [26848, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666666 +k=0.999966667 +x_0=900000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [26849, '+proj=lcc +lat_1=48.6333333333333 +lat_2=47.0333333333333 +lat_0=46.5 +lon_0=-93.0999999999999 +x_0=800000.000010159 +y_0=99999.9999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [26850, '+proj=lcc +lat_1=47.05 +lat_2=45.6166666666666 +lat_0=45 +lon_0=-94.25 +x_0=800000.000010159 +y_0=99999.9999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [26851, '+proj=lcc +lat_1=45.2166666666666 +lat_2=43.7833333333333 +lat_0=43 +lon_0=-94 +x_0=800000.000010159 +y_0=99999.9999898399 +datum=NAD83 +units=us-ft +no_defs'],
    [26852, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.8333333333333 +lon_0=-100 +x_0=500000.00001016 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [26853, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [26854, '+proj=lcc +lat_1=38.8833333333333 +lat_2=37.4833333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [26855, '+proj=tmerc +lat_0=43.66666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26856, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.16666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26857, '+proj=lcc +lat_1=48.63333333333333 +lat_2=47.03333333333333 +lat_0=46.5 +lon_0=-93.09999999999999 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26858, '+proj=lcc +lat_1=47.05 +lat_2=45.61666666666667 +lat_0=45 +lon_0=-94.25 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26859, '+proj=lcc +lat_1=45.21666666666667 +lat_2=43.78333333333333 +lat_0=43 +lon_0=-94 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26860, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26861, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26862, '+proj=lcc +lat_1=38.88333333333333 +lat_2=37.48333333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26863, '+proj=tmerc +lat_0=43.66666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000.0000000001 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26864, '+proj=tmerc +lat_0=42.83333333333334 +lon_0=-70.16666666666667 +k=0.999966667 +x_0=900000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26865, '+proj=lcc +lat_1=48.63333333333333 +lat_2=47.03333333333333 +lat_0=46.5 +lon_0=-93.09999999999999 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26866, '+proj=lcc +lat_1=47.05 +lat_2=45.61666666666667 +lat_0=45 +lon_0=-94.25 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26867, '+proj=lcc +lat_1=45.21666666666667 +lat_2=43.78333333333333 +lat_0=43 +lon_0=-94 +x_0=800000.0000101599 +y_0=99999.99998983997 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26868, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.83333333333334 +lon_0=-100 +x_0=500000.00001016 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26869, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26870, '+proj=lcc +lat_1=38.88333333333333 +lat_2=37.48333333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=us-ft +no_defs '],
    [26891, '+proj=tmerc +lat_0=0 +lon_0=-82.5 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26892, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26893, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26894, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26895, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26896, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26897, '+proj=tmerc +lat_0=0 +lon_0=-96 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26898, '+proj=tmerc +lat_0=0 +lon_0=-53 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26899, '+proj=tmerc +lat_0=0 +lon_0=-56 +k=0.9999 +x_0=304800 +y_0=0 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [26901, '+proj=utm +zone=1 +datum=NAD83 +units=m +no_defs'],
    [26902, '+proj=utm +zone=2 +datum=NAD83 +units=m +no_defs'],
    [26903, '+proj=utm +zone=3 +datum=NAD83 +units=m +no_defs'],
    [26904, '+proj=utm +zone=4 +datum=NAD83 +units=m +no_defs'],
    [26905, '+proj=utm +zone=5 +datum=NAD83 +units=m +no_defs'],
    [26906, '+proj=utm +zone=6 +datum=NAD83 +units=m +no_defs'],
    [26907, '+proj=utm +zone=7 +datum=NAD83 +units=m +no_defs'],
    [26908, '+proj=utm +zone=8 +datum=NAD83 +units=m +no_defs'],
    [26909, '+proj=utm +zone=9 +datum=NAD83 +units=m +no_defs'],
    [26910, '+proj=utm +zone=10 +datum=NAD83 +units=m +no_defs'],
    [26911, '+proj=utm +zone=11 +datum=NAD83 +units=m +no_defs'],
    [26912, '+proj=utm +zone=12 +datum=NAD83 +units=m +no_defs'],
    [26913, '+proj=utm +zone=13 +datum=NAD83 +units=m +no_defs'],
    [26914, '+proj=utm +zone=14 +datum=NAD83 +units=m +no_defs'],
    [26915, '+proj=utm +zone=15 +datum=NAD83 +units=m +no_defs'],
    [26916, '+proj=utm +zone=16 +datum=NAD83 +units=m +no_defs'],
    [26917, '+proj=utm +zone=17 +datum=NAD83 +units=m +no_defs'],
    [26918, '+proj=utm +zone=18 +datum=NAD83 +units=m +no_defs'],
    [26919, '+proj=utm +zone=19 +datum=NAD83 +units=m +no_defs'],
    [26920, '+proj=utm +zone=20 +datum=NAD83 +units=m +no_defs'],
    [26921, '+proj=utm +zone=21 +datum=NAD83 +units=m +no_defs'],
    [26922, '+proj=utm +zone=22 +datum=NAD83 +units=m +no_defs'],
    [26923, '+proj=utm +zone=23 +datum=NAD83 +units=m +no_defs'],
    [26929, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26930, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26931, '+proj=omerc +lat_0=57 +lonc=-133.666666666666 +alpha=323.130102361111 +k=0.9999 +x_0=5000000 +y_0=-5000000 +no_uoff +gamma=323.130102361111 +datum=NAD83 +units=m +no_defs'],
    [26932, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26933, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26934, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26935, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26936, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26937, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26938, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26939, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26940, '+proj=lcc +lat_1=53.8333333333333 +lat_2=51.8333333333333 +lat_0=51 +lon_0=-176 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26941, '+proj=lcc +lat_1=41.6666666666666 +lat_2=40 +lat_0=39.3333333333333 +lon_0=-122 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26942, '+proj=lcc +lat_1=39.8333333333333 +lat_2=38.3333333333333 +lat_0=37.6666666666666 +lon_0=-122 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26943, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.0666666666666 +lat_0=36.5 +lon_0=-120.5 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26944, '+proj=lcc +lat_1=37.25 +lat_2=36 +lat_0=35.3333333333333 +lon_0=-119 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26945, '+proj=lcc +lat_1=35.4666666666666 +lat_2=34.0333333333333 +lat_0=33.5 +lon_0=-118 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26946, '+proj=lcc +lat_1=33.8833333333333 +lat_2=32.7833333333333 +lat_0=32.1666666666666 +lon_0=-116.25 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26948, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666666 +k=0.9999 +x_0=213360 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26949, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666666 +k=0.9999 +x_0=213360 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26950, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333 +x_0=213360 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26951, '+proj=lcc +lat_1=36.2333333333333 +lat_2=34.9333333333333 +lat_0=34.3333333333333 +lon_0=-92 +x_0=400000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26952, '+proj=lcc +lat_1=34.7666666666666 +lat_2=33.3 +lat_0=32.6666666666666 +lon_0=-92 +x_0=400000 +y_0=400000 +datum=NAD83 +units=m +no_defs'],
    [26953, '+proj=lcc +lat_1=40.7833333333333 +lat_2=39.7166666666666 +lat_0=39.3333333333333 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +datum=NAD83 +units=m +no_defs'],
    [26954, '+proj=lcc +lat_1=39.75 +lat_2=38.45 +lat_0=37.8333333333333 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +datum=NAD83 +units=m +no_defs'],
    [26955, '+proj=lcc +lat_1=38.4333333333333 +lat_2=37.2333333333333 +lat_0=36.6666666666666 +lon_0=-105.5 +x_0=914401.8289 +y_0=304800.6096 +datum=NAD83 +units=m +no_defs'],
    [26956, '+proj=lcc +lat_1=41.8666666666666 +lat_2=41.2 +lat_0=40.8333333333333 +lon_0=-72.75 +x_0=304800.6096 +y_0=152400.3048 +datum=NAD83 +units=m +no_defs'],
    [26957, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666666 +k=0.999995 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26958, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26959, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941177 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26960, '+proj=lcc +lat_1=30.75 +lat_2=29.5833333333333 +lat_0=29 +lon_0=-84.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26961, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966667 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26962, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666666 +k=0.999966667 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26963, '+proj=tmerc +lat_0=21.1666666666666 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26964, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26965, '+proj=tmerc +lat_0=21.6666666666666 +lon_0=-160.166666666666 +k=1 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26966, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666666 +k=0.9999 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26967, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666666 +k=0.9999 +x_0=700000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26968, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-112.166666666666 +k=0.999947367999999 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26969, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-114 +k=0.999947367999999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26970, '+proj=tmerc +lat_0=41.6666666666666 +lon_0=-115.75 +k=0.999933333 +x_0=800000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26971, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-88.3333333333333 +k=0.999974999999999 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26972, '+proj=tmerc +lat_0=36.6666666666666 +lon_0=-90.1666666666666 +k=0.999941177 +x_0=700000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26973, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666666 +k=0.999966667 +x_0=100000 +y_0=250000 +datum=NAD83 +units=m +no_defs'],
    [26974, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966667 +x_0=900000 +y_0=250000 +datum=NAD83 +units=m +no_defs'],
    [26975, '+proj=lcc +lat_1=43.2666666666666 +lat_2=42.0666666666666 +lat_0=41.5 +lon_0=-93.5 +x_0=1500000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [26976, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.6166666666666 +lat_0=40 +lon_0=-93.5 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26977, '+proj=lcc +lat_1=39.7833333333333 +lat_2=38.7166666666666 +lat_0=38.3333333333333 +lon_0=-98 +x_0=400000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26978, '+proj=lcc +lat_1=38.5666666666666 +lat_2=37.2666666666666 +lat_0=36.6666666666666 +lon_0=-98.5 +x_0=400000 +y_0=400000 +datum=NAD83 +units=m +no_defs'],
    [26979, '+proj=lcc +lat_1=37.9666666666666 +lat_2=37.9666666666666 +lat_0=37.5 +lon_0=-84.25 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26980, '+proj=lcc +lat_1=37.9333333333333 +lat_2=36.7333333333333 +lat_0=36.3333333333333 +lon_0=-85.75 +x_0=500000 +y_0=500000 +datum=NAD83 +units=m +no_defs'],
    [26981, '+proj=lcc +lat_1=32.6666666666666 +lat_2=31.1666666666666 +lat_0=30.5 +lon_0=-92.5 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26982, '+proj=lcc +lat_1=30.7 +lat_2=29.3 +lat_0=28.5 +lon_0=-91.3333333333333 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26983, '+proj=tmerc +lat_0=43.6666666666666 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26984, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666666 +k=0.999966667 +x_0=900000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26985, '+proj=lcc +lat_1=39.45 +lat_2=38.3 +lat_0=37.6666666666666 +lon_0=-77 +x_0=400000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26986, '+proj=lcc +lat_1=42.6833333333333 +lat_2=41.7166666666666 +lat_0=41 +lon_0=-71.5 +x_0=200000 +y_0=750000 +datum=NAD83 +units=m +no_defs'],
    [26987, '+proj=lcc +lat_1=41.4833333333333 +lat_2=41.2833333333333 +lat_0=41 +lon_0=-70.5 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26988, '+proj=lcc +lat_1=47.0833333333333 +lat_2=45.4833333333333 +lat_0=44.7833333333333 +lon_0=-87 +x_0=8000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26989, '+proj=lcc +lat_1=45.7 +lat_2=44.1833333333333 +lat_0=43.3166666666666 +lon_0=-84.3666666666666 +x_0=6000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26990, '+proj=lcc +lat_1=43.6666666666666 +lat_2=42.1 +lat_0=41.5 +lon_0=-84.3666666666666 +x_0=4000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26991, '+proj=lcc +lat_1=48.6333333333333 +lat_2=47.0333333333333 +lat_0=46.5 +lon_0=-93.0999999999999 +x_0=800000 +y_0=100000 +datum=NAD83 +units=m +no_defs'],
    [26992, '+proj=lcc +lat_1=47.05 +lat_2=45.6166666666666 +lat_0=45 +lon_0=-94.25 +x_0=800000 +y_0=100000 +datum=NAD83 +units=m +no_defs'],
    [26993, '+proj=lcc +lat_1=45.2166666666666 +lat_2=43.7833333333333 +lat_0=43 +lon_0=-94 +x_0=800000 +y_0=100000 +datum=NAD83 +units=m +no_defs'],
    [26994, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26995, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=700000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26996, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333 +x_0=250000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26997, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [26998, '+proj=tmerc +lat_0=36.1666666666666 +lon_0=-94.5 +k=0.999941177 +x_0=850000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [27037, '+proj=utm +zone=37 +ellps=clrk80 +towgs84=-249,-156,381,0,0,0,0 +units=m +no_defs '],
    [27038, '+proj=utm +zone=38 +ellps=clrk80 +towgs84=-249,-156,381,0,0,0,0 +units=m +no_defs '],
    [27039, '+proj=utm +zone=39 +ellps=clrk80 +towgs84=-249,-156,381,0,0,0,0 +units=m +no_defs '],
    [27040, '+proj=utm +zone=40 +ellps=clrk80 +towgs84=-249,-156,381,0,0,0,0 +units=m +no_defs '],
    [27120, '+proj=utm +zone=20 +ellps=intl +towgs84=-10,375,165,0,0,0,0 +units=m +no_defs '],
    [27200, '+proj=nzmg +lat_0=-41 +lon_0=173 +x_0=2510000 +y_0=6023150 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27205, '+proj=tmerc +lat_0=-36.87986527777778 +lon_0=174.7643393611111 +k=0.9999 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27206, '+proj=tmerc +lat_0=-37.76124980555556 +lon_0=176.46619725 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27207, '+proj=tmerc +lat_0=-38.62470277777778 +lon_0=177.8856362777778 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27208, '+proj=tmerc +lat_0=-39.65092930555556 +lon_0=176.6736805277778 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27209, '+proj=tmerc +lat_0=-39.13575830555556 +lon_0=174.22801175 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27210, '+proj=tmerc +lat_0=-39.51247038888889 +lon_0=175.6400368055556 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27211, '+proj=tmerc +lat_0=-40.24194713888889 +lon_0=175.4880996111111 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27212, '+proj=tmerc +lat_0=-40.92553263888889 +lon_0=175.6473496666667 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27213, '+proj=tmerc +lat_0=-41.3013196388888 +lon_0=174.776623111111 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +no_defs'],
    [27214, '+proj=tmerc +lat_0=-40.71475905555556 +lon_0=172.6720465 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27215, '+proj=tmerc +lat_0=-41.27454472222222 +lon_0=173.2993168055555 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27216, '+proj=tmerc +lat_0=-41.28991152777778 +lon_0=172.1090281944444 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27217, '+proj=tmerc +lat_0=-41.81080286111111 +lon_0=171.5812600555556 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27218, '+proj=tmerc +lat_0=-42.33369427777778 +lon_0=171.5497713055556 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27219, '+proj=tmerc +lat_0=-42.68911658333333 +lon_0=173.0101333888889 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27220, '+proj=tmerc +lat_0=-41.54448666666666 +lon_0=173.8020741111111 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27221, '+proj=tmerc +lat_0=-42.88632236111111 +lon_0=170.9799935 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27222, '+proj=tmerc +lat_0=-43.11012813888889 +lon_0=170.2609258333333 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27223, '+proj=tmerc +lat_0=-43.97780288888889 +lon_0=168.606267 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27224, '+proj=tmerc +lat_0=-43.59063758333333 +lon_0=172.7271935833333 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27225, '+proj=tmerc +lat_0=-43.74871155555556 +lon_0=171.3607484722222 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27226, '+proj=tmerc +lat_0=-44.40222036111111 +lon_0=171.0572508333333 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27227, '+proj=tmerc +lat_0=-44.73526797222222 +lon_0=169.4677550833333 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27228, '+proj=tmerc +lat_0=-45.13290258333333 +lon_0=168.3986411944444 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27229, '+proj=tmerc +lat_0=-45.56372616666666 +lon_0=167.7388617777778 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27230, '+proj=tmerc +lat_0=-45.81619661111111 +lon_0=170.6285951666667 +k=1 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27231, '+proj=tmerc +lat_0=-45.86151336111111 +lon_0=170.2825891111111 +k=0.99996 +x_0=300000 +y_0=700000 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27232, '+proj=tmerc +lat_0=-46.60000961111111 +lon_0=168.342872 +k=1 +x_0=300002.66 +y_0=699999.58 +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27258, '+proj=utm +zone=58 +south +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27259, '+proj=utm +zone=59 +south +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27260, '+proj=utm +zone=60 +south +datum=nzgd49 +units=m +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27291, '+proj=tmerc +lat_0=-39 +lon_0=175.5 +k=1 +x_0=274319.5243848086 +y_0=365759.3658464114 +datum=nzgd49 +to_meter=0.9143984146160287 +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27292, '+proj=tmerc +lat_0=-44 +lon_0=171.5 +k=1 +x_0=457199.2073080143 +y_0=457199.2073080143 +datum=nzgd49 +to_meter=0.9143984146160287 +towgs84=59.47,-5.04,187.44,0.47,-0.1,1.024,-4.5993 +nadgrids=nzgd2kgrid0005.gsb +no_defs '],
    [27391, '+proj=tmerc +lat_0=58 +lon_0=-4.666666666666667 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27392, '+proj=tmerc +lat_0=58 +lon_0=-2.333333333333333 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27393, '+proj=tmerc +lat_0=58 +lon_0=0 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27394, '+proj=tmerc +lat_0=58 +lon_0=2.5 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27395, '+proj=tmerc +lat_0=58 +lon_0=6.166666666666667 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27396, '+proj=tmerc +lat_0=58 +lon_0=10.16666666666667 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27397, '+proj=tmerc +lat_0=58 +lon_0=14.16666666666667 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27398, '+proj=tmerc +lat_0=58 +lon_0=18.33333333333333 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +b=6356173.508712696 +towgs84=278.3,93,474.5,7.889,0.05,-6.61,6.21 +pm=oslo +units=m +no_defs '],
    [27429, '+proj=utm +zone=29 +ellps=intl +towgs84=-223.237,110.193,36.649,0,0,0,0 +units=m +no_defs '],
    [27492, '+proj=tmerc +lat_0=39.66666666666666 +lon_0=-8.131906111111112 +k=1 +x_0=180.598 +y_0=-86.98999999999999 +ellps=intl +towgs84=-223.237,110.193,36.649,0,0,0,0 +units=m +no_defs '],
    [27493, '+proj=tmerc +lat_0=39.66666666666666 +lon_0=-8.131906111111112 +k=1 +x_0=180.598 +y_0=-86.98999999999999 +ellps=intl +towgs84=-223.237,110.193,36.649,0,0,0,0 +units=m +no_defs '],
    [27500, '+proj=lcc +lat_1=49.5 +lat_0=49.5 +lon_0=5.39999999999999 +k_0=0.99950908 +x_0=500000 +y_0=300000 +a=6376523 +b=6355862.93325557 +pm=2.33720833333333 +units=m +no_defs'],
    [27561, '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27562, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27563, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27564, '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27571, '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=1200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27572, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27573, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27574, '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=4185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27581, '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=1200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27582, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27583, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27584, '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=4185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27591, '+proj=lcc +lat_1=49.50000000000001 +lat_0=49.50000000000001 +lon_0=0 +k_0=0.999877341 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27592, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=0 +k_0=0.99987742 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27593, '+proj=lcc +lat_1=44.10000000000001 +lat_0=44.10000000000001 +lon_0=0 +k_0=0.999877499 +x_0=600000 +y_0=200000 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27594, '+proj=lcc +lat_1=42.16500000000001 +lat_0=42.16500000000001 +lon_0=0 +k_0=0.99994471 +x_0=234.358 +y_0=185861.369 +a=6378249.2 +b=6356515 +towgs84=-168,-60,320,0,0,0,0 +pm=paris +units=m +no_defs '],
    [27700, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +datum=OSGB36 +units=m +no_defs'],
    [28191, '+proj=cass +lat_0=31.73409694444445 +lon_0=35.21208055555556 +x_0=170251.555 +y_0=126867.909 +a=6378300.789 +b=6356566.435 +towgs84=-275.7224,94.7824,340.8944,-8.001,-4.42,-11.821,1 +units=m +no_defs '],
    [28192, '+proj=tmerc +lat_0=31.73409694444445 +lon_0=35.21208055555556 +k=1 +x_0=170251.555 +y_0=1126867.909 +a=6378300.789 +b=6356566.435 +towgs84=-275.7224,94.7824,340.8944,-8.001,-4.42,-11.821,1 +units=m +no_defs '],
    [28193, '+proj=cass +lat_0=31.73409694444445 +lon_0=35.21208055555556 +x_0=170251.555 +y_0=1126867.909 +a=6378300.789 +b=6356566.435 +towgs84=-275.7224,94.7824,340.8944,-8.001,-4.42,-11.821,1 +units=m +no_defs '],
    [28232, '+proj=utm +zone=32 +south +a=6378249.2 +b=6356515 +towgs84=-148,51,-291,0,0,0,0 +units=m +no_defs '],
    [28348, '+proj=utm +zone=48 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28349, '+proj=utm +zone=49 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28350, '+proj=utm +zone=50 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28351, '+proj=utm +zone=51 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28352, '+proj=utm +zone=52 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28353, '+proj=utm +zone=53 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28354, '+proj=utm +zone=54 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28355, '+proj=utm +zone=55 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28356, '+proj=utm +zone=56 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28357, '+proj=utm +zone=57 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28358, '+proj=utm +zone=58 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [28402, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=2500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28403, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=3500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28404, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28405, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28406, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28407, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28408, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28409, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=9500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28410, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=10500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28411, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=11500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28412, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=12500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28413, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=13500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28414, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=14500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28415, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=15500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28416, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=16500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28417, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=17500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28418, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=18500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28419, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=19500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28420, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=20500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28421, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=21500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28422, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=22500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28423, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=23500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28424, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=24500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28425, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=25500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28426, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=26500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28427, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=27500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28428, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=28500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28429, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=29500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28430, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=30500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28431, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=31500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28432, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=32500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28462, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28463, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28464, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28465, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28466, '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28467, '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28468, '+proj=tmerc +lat_0=0 +lon_0=45 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28469, '+proj=tmerc +lat_0=0 +lon_0=51 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28470, '+proj=tmerc +lat_0=0 +lon_0=57 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28471, '+proj=tmerc +lat_0=0 +lon_0=63 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28472, '+proj=tmerc +lat_0=0 +lon_0=69 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28473, '+proj=tmerc +lat_0=0 +lon_0=75 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28474, '+proj=tmerc +lat_0=0 +lon_0=81 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28475, '+proj=tmerc +lat_0=0 +lon_0=87 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28476, '+proj=tmerc +lat_0=0 +lon_0=93 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28477, '+proj=tmerc +lat_0=0 +lon_0=99 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28478, '+proj=tmerc +lat_0=0 +lon_0=105 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28479, '+proj=tmerc +lat_0=0 +lon_0=111 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28480, '+proj=tmerc +lat_0=0 +lon_0=117 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28481, '+proj=tmerc +lat_0=0 +lon_0=123 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28482, '+proj=tmerc +lat_0=0 +lon_0=129 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28483, '+proj=tmerc +lat_0=0 +lon_0=135 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28484, '+proj=tmerc +lat_0=0 +lon_0=141 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28485, '+proj=tmerc +lat_0=0 +lon_0=147 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28486, '+proj=tmerc +lat_0=0 +lon_0=153 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28487, '+proj=tmerc +lat_0=0 +lon_0=159 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28488, '+proj=tmerc +lat_0=0 +lon_0=165 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28489, '+proj=tmerc +lat_0=0 +lon_0=171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28490, '+proj=tmerc +lat_0=0 +lon_0=177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28491, '+proj=tmerc +lat_0=0 +lon_0=-177 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28492, '+proj=tmerc +lat_0=0 +lon_0=-171 +k=1 +x_0=500000 +y_0=0 +ellps=krass +towgs84=23.92,-141.27,-80.9,0,0.35,0.82,-0.12 +units=m +no_defs '],
    [28600, '+proj=tmerc +lat_0=24.45 +lon_0=51.21666666666667 +k=0.99999 +x_0=200000 +y_0=300000 +ellps=intl +towgs84=-128.16,-282.42,21.93,0,0,0,0 +units=m +no_defs '],
    [28991, '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=0 +y_0=0 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857,0.350733,-1.87035,4.0812 +units=m +no_defs '],
    [28992, '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857,0.350733,-1.87035,4.0812 +units=m +no_defs '],
    [29100, '+proj=poly +lat_0=0 +lon_0=-54 +x_0=5000000 +y_0=10000000 +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29101, '+proj=poly +lat_0=0 +lon_0=-54 +x_0=5000000 +y_0=10000000 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29118, '+proj=utm +zone=18 +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29119, '+proj=utm +zone=19 +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29120, '+proj=utm +zone=20 +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29121, '+proj=utm +zone=21 +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29122, '+proj=utm +zone=22 +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29168, '+proj=utm +zone=18 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29169, '+proj=utm +zone=19 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29170, '+proj=utm +zone=20 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29171, '+proj=utm +zone=21 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29172, '+proj=utm +zone=22 +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29177, '+proj=utm +zone=17 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29178, '+proj=utm +zone=18 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29179, '+proj=utm +zone=19 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29180, '+proj=utm +zone=20 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29181, '+proj=utm +zone=21 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29182, '+proj=utm +zone=22 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29183, '+proj=utm +zone=23 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29184, '+proj=utm +zone=24 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29185, '+proj=utm +zone=25 +south +ellps=GRS67 +towgs84=-57,1,-41,0,0,0,0 +units=m +no_defs '],
    [29187, '+proj=utm +zone=17 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29188, '+proj=utm +zone=18 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29189, '+proj=utm +zone=19 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29190, '+proj=utm +zone=20 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29191, '+proj=utm +zone=21 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29192, '+proj=utm +zone=22 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29193, '+proj=utm +zone=23 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29194, '+proj=utm +zone=24 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29195, '+proj=utm +zone=25 +south +ellps=aust_SA +towgs84=-66.87,4.37,-38.52,0,0,0,0 +units=m +no_defs '],
    [29220, '+proj=utm +zone=20 +south +ellps=intl +towgs84=-355,21,72,0,0,0,0 +units=m +no_defs '],
    [29221, '+proj=utm +zone=21 +south +ellps=intl +towgs84=-355,21,72,0,0,0,0 +units=m +no_defs '],
    [29333, '+proj=utm +zone=33 +south +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +units=m +no_defs '],
    [29371, '+proj=tmerc +lat_0=-22 +lon_0=11 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29373, '+proj=tmerc +lat_0=-22 +lon_0=13 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29375, '+proj=tmerc +lat_0=-22 +lon_0=15 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29377, '+proj=tmerc +lat_0=-22 +lon_0=17 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29379, '+proj=tmerc +lat_0=-22 +lon_0=19 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29381, '+proj=tmerc +lat_0=-22 +lon_0=21 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29383, '+proj=tmerc +lat_0=-22 +lon_0=23 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29385, '+proj=tmerc +lat_0=-22 +lon_0=25 +k=1 +x_0=0 +y_0=0 +axis=wsu +ellps=bess_nam +towgs84=616,97,-251,0,0,0,0 +to_meter=1.0000135965 +no_defs '],
    [29635, '+proj=utm +zone=35 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [29636, '+proj=utm +zone=36 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [29700, '+proj=omerc +lat_0=-18.9 +lonc=44.10000000000001 +alpha=18.9 +k=0.9995000000000001 +x_0=400000 +y_0=800000 +gamma=18.9 +ellps=intl +towgs84=-189,-242,-91,0,0,0,0 +pm=paris +units=m +no_defs '],
    [29701, ''],
    [29702, '+proj=omerc +lat_0=-18.9 +lonc=44.10000000000001 +alpha=18.9 +k=0.9995000000000001 +x_0=400000 +y_0=800000 +gamma=18.9 +ellps=intl +towgs84=-189,-242,-91,0,0,0,0 +pm=paris +units=m +no_defs '],
    [29738, '+proj=utm +zone=38 +south +ellps=intl +towgs84=-189,-242,-91,0,0,0,0 +units=m +no_defs '],
    [29739, '+proj=utm +zone=39 +south +ellps=intl +towgs84=-189,-242,-91,0,0,0,0 +units=m +no_defs '],
    [29849, '+proj=utm +zone=49 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +units=m +no_defs '],
    [29850, '+proj=utm +zone=50 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +units=m +no_defs '],
    [29871, '+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31582047222222 +k=0.99984 +x_0=590476.8714630401 +y_0=442857.653094361 +gamma=53.13010236111111 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +to_meter=20.11676512155263 +no_defs '],
    [29872, '+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31582047222222 +k=0.99984 +x_0=590476.8727431979 +y_0=442857.6545573985 +gamma=53.13010236111111 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +to_meter=0.3047994715386762 +no_defs '],
    [29873, '+proj=omerc +lat_0=4 +lonc=115 +alpha=53.31582047222222 +k=0.99984 +x_0=590476.87 +y_0=442857.65 +gamma=53.13010236111111 +ellps=evrstSS +towgs84=-679,669,-48,0,0,0,0 +units=m +no_defs '],
    [29900, '+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1.000035 +x_0=200000 +y_0=250000 +datum=IRE65 +units=m +no_defs'],
    [29901, '+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1 +x_0=200000 +y_0=250000 +ellps=airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +units=m +no_defs '],
    [29902, '+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1.000035 +x_0=200000 +y_0=250000 +datum=IRE65 +units=m +no_defs'],
    [29903, '+proj=tmerc +lat_0=53.5 +lon_0=-8 +k=1.000035 +x_0=200000 +y_0=250000 +ellps=mod_airy +towgs84=482.5,-130.6,564.6,-1.042,-0.214,-0.631,8.15 +units=m +no_defs '],
    [30161, '+proj=tmerc +lat_0=33 +lon_0=129.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30162, '+proj=tmerc +lat_0=33 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30163, '+proj=tmerc +lat_0=36 +lon_0=132.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30164, '+proj=tmerc +lat_0=33 +lon_0=133.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30165, '+proj=tmerc +lat_0=36 +lon_0=134.3333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30166, '+proj=tmerc +lat_0=36 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30167, '+proj=tmerc +lat_0=36 +lon_0=137.1666666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30168, '+proj=tmerc +lat_0=36 +lon_0=138.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30169, '+proj=tmerc +lat_0=36 +lon_0=139.8333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30170, '+proj=tmerc +lat_0=40 +lon_0=140.8333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30171, '+proj=tmerc +lat_0=44 +lon_0=140.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30172, '+proj=tmerc +lat_0=44 +lon_0=142.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30173, '+proj=tmerc +lat_0=44 +lon_0=144.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30174, '+proj=tmerc +lat_0=26 +lon_0=142 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30175, '+proj=tmerc +lat_0=26 +lon_0=127.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30176, '+proj=tmerc +lat_0=26 +lon_0=124 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30177, '+proj=tmerc +lat_0=26 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30178, '+proj=tmerc +lat_0=20 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30179, '+proj=tmerc +lat_0=26 +lon_0=154 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +towgs84=-146.414,507.337,680.507,0,0,0,0 +units=m +no_defs '],
    [30200, '+proj=cass +lat_0=10.44166666666667 +lon_0=-61.33333333333334 +x_0=86501.46392051999 +y_0=65379.0134283 +a=6378293.645208759 +b=6356617.987679838 +towgs84=-61.702,284.488,472.052,0,0,0,0 +to_meter=0.201166195164 +no_defs '],
    [30339, '+proj=utm +zone=39 +ellps=helmert +units=m +no_defs'],
    [30340, '+proj=utm +zone=40 +ellps=helmert +units=m +no_defs'],
    [30491, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=2.7 +k_0=0.999625544 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=-73,-247,227,0,0,0,0 +units=m +no_defs '],
    [30492, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=2.7 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +towgs84=-73,-247,227,0,0,0,0 +units=m +no_defs '],
    [30493, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=2.7 +k_0=0.999625544 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [30494, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=2.7 +k_0=0.999625769 +x_0=500000 +y_0=300000 +a=6378249.2 +b=6356515 +units=m +no_defs'],
    [30591, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=2.7 +k_0=0.999625544 +x_0=500135 +y_0=300090 +a=6378249.145 +rf=293.465 +units=m +no_defs'],
    [30592, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=2.7 +k_0=0.999625769 +x_0=500135 +y_0=300090 +a=6378249.145 +rf=293.465 +units=m +no_defs'],
    [30729, '+proj=utm +zone=29 +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +units=m +no_defs '],
    [30730, '+proj=utm +zone=30 +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +units=m +no_defs '],
    [30731, '+proj=utm +zone=31 +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +units=m +no_defs '],
    [30732, '+proj=utm +zone=32 +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +units=m +no_defs '],
    [30791, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=2.7 +k_0=0.999625544 +x_0=500135 +y_0=300090 +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +units=m +no_defs '],
    [30792, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=2.7 +k_0=0.999625769 +x_0=500135 +y_0=300090 +ellps=clrk80 +towgs84=-209.3622,-87.8162,404.6198,0.0046,3.4784,0.5805,-1.4547 +units=m +no_defs '],
    [30800, '+proj=tmerc +lat_0=0 +lon_0=15.8082777777777 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [31028, '+proj=utm +zone=28 +a=6378249.2 +b=6356515 +towgs84=-30,190,89,0,0,0,0 +units=m +no_defs '],
    [31121, '+proj=utm +zone=21 +ellps=intl +towgs84=-265,120,-358,0,0,0,0 +units=m +no_defs '],
    [31154, '+proj=tmerc +lat_0=0 +lon_0=-54 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-265,120,-358,0,0,0,0 +units=m +no_defs '],
    [31170, '+proj=tmerc +lat_0=0 +lon_0=-55.68333333333333 +k=0.9996 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-265,120,-358,0,0,0,0 +units=m +no_defs '],
    [31171, '+proj=tmerc +lat_0=0 +lon_0=-55.68333333333333 +k=0.9999 +x_0=500000 +y_0=0 +ellps=intl +towgs84=-265,120,-358,0,0,0,0 +units=m +no_defs '],
    [31251, '+proj=tmerc +lat_0=0 +lon_0=28 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +pm=ferro +units=m +no_defs '],
    [31252, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +pm=ferro +units=m +no_defs '],
    [31253, '+proj=tmerc +lat_0=0 +lon_0=34 +k=1 +x_0=0 +y_0=-5000000 +ellps=bessel +towgs84=577.326,90.129,463.919,5.137,1.474,5.297,2.4232 +pm=ferro +units=m +no_defs '],
    [31254, '+proj=tmerc +lat_0=0 +lon_0=10.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [31255, '+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [31256, '+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=0 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [31257, '+proj=tmerc +lat_0=0 +lon_0=10.3333333333333 +k=1 +x_0=150000 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [31258, '+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=450000 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [31259, '+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=750000 +y_0=-5000000 +datum=hermannskogel +units=m +no_defs'],
    [31265, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31266, '+proj=tmerc +lat_0=0 +lon_0=18 +k=1 +x_0=6500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31267, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31268, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31275, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=5500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31276, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=6500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31277, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=7500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31278, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=7500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31279, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=8500000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31281, '+proj=tmerc +lat_0=0 +lon_0=28 +k=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31282, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31283, '+proj=tmerc +lat_0=0 +lon_0=34 +k=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31284, '+proj=tmerc +lat_0=0 +lon_0=10.3333333333333 +k=1 +x_0=150000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31285, '+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=450000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31286, '+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=750000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31287, '+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.3333333333333 +x_0=400000 +y_0=400000 +datum=hermannskogel +units=m +no_defs'],
    [31288, '+proj=tmerc +lat_0=0 +lon_0=28 +k=1 +x_0=150000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31289, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=450000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31290, '+proj=tmerc +lat_0=0 +lon_0=34 +k=1 +x_0=750000 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31291, '+proj=tmerc +lat_0=0 +lon_0=28 +k=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31292, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31293, '+proj=tmerc +lat_0=0 +lon_0=34 +k=1 +x_0=0 +y_0=0 +ellps=bessel +towgs84=682,-203,480,0,0,0,0 +pm=ferro +units=m +no_defs '],
    [31294, '+proj=tmerc +lat_0=0 +lon_0=10.3333333333333 +k=1 +x_0=150000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31295, '+proj=tmerc +lat_0=0 +lon_0=13.3333333333333 +k=1 +x_0=450000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31296, '+proj=tmerc +lat_0=0 +lon_0=16.3333333333333 +k=1 +x_0=750000 +y_0=0 +datum=hermannskogel +units=m +no_defs'],
    [31297, '+proj=lcc +lat_1=49 +lat_2=46 +lat_0=47.5 +lon_0=13.3333333333333 +x_0=400000 +y_0=400000 +datum=hermannskogel +units=m +no_defs'],
    [31300, '+proj=lcc +lat_1=49.83333333333334 +lat_2=51.16666666666666 +lat_0=90 +lon_0=4.356939722222222 +x_0=150000.01256 +y_0=5400088.4378 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs '],
    [31370, '+proj=lcc +lat_1=51.16666723333333 +lat_2=49.8333339 +lat_0=90 +lon_0=4.367486666666666 +x_0=150000.013 +y_0=5400088.438 +ellps=intl +towgs84=-106.8686,52.2978,-103.7239,0.3366,-0.457,1.8422,-1.2747 +units=m +no_defs '],
    [31461, '+proj=tmerc +lat_0=0 +lon_0=3 +k=1 +x_0=1500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31462, '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31463, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31464, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31465, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31466, '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31467, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31468, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31469, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +datum=potsdam +units=m +no_defs'],
    [31491, '+proj=tmerc +lat_0=0 +lon_0=3 +k=1 +x_0=1500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [31492, '+proj=tmerc +lat_0=0 +lon_0=6 +k=1 +x_0=2500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [31493, '+proj=tmerc +lat_0=0 +lon_0=9 +k=1 +x_0=3500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [31494, '+proj=tmerc +lat_0=0 +lon_0=12 +k=1 +x_0=4500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [31495, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=5500000 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [31528, '+proj=utm +zone=28 +a=6378249.2 +b=6356515 +towgs84=-23,259,-9,0,0,0,0 +units=m +no_defs '],
    [31529, '+proj=utm +zone=29 +a=6378249.2 +b=6356515 +towgs84=-23,259,-9,0,0,0,0 +units=m +no_defs '],
    [31600, '+proj=sterea +lat_0=45.9 +lon_0=25.39246588888889 +k=0.9996667 +x_0=500000 +y_0=500000 +ellps=intl +towgs84=103.25,-100.4,-307.19,0,0,0,0 +units=m +no_defs '],
    [31700, '+proj=sterea +lat_0=46 +lon_0=25 +k=0.99975 +x_0=500000 +y_0=500000 +ellps=krass +towgs84=28,-121,-77,0,0,0,0 +units=m +no_defs '],
    [31838, '+proj=utm +zone=38 +ellps=WGS84 +towgs84=-3.2,-5.7,2.8,0,0,0,0 +units=m +no_defs '],
    [31839, '+proj=utm +zone=39 +ellps=WGS84 +towgs84=-3.2,-5.7,2.8,0,0,0,0 +units=m +no_defs '],
    [31900, '+proj=tmerc +lat_0=0 +lon_0=48 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-20.8,11.3,2.4,0,0,0,0 +units=m +no_defs '],
    [31901, '+proj=tmerc +lat_0=0 +lon_0=48 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +towgs84=-20.8,11.3,2.4,0,0,0,0 +units=m +no_defs '],
    [31917, '+proj=utm +zone=17 +ellps=GRS80 +units=m +no_defs'],
    [31918, '+proj=utm +zone=18 +ellps=GRS80 +units=m +no_defs'],
    [31919, '+proj=utm +zone=19 +ellps=GRS80 +units=m +no_defs'],
    [31920, '+proj=utm +zone=20 +ellps=GRS80 +units=m +no_defs'],
    [31921, '+proj=utm +zone=21 +ellps=GRS80 +units=m +no_defs'],
    [31922, '+proj=utm +zone=22 +ellps=GRS80 +units=m +no_defs'],
    [31965, '+proj=utm +zone=11 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31966, '+proj=utm +zone=12 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31967, '+proj=utm +zone=13 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31968, '+proj=utm +zone=14 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31969, '+proj=utm +zone=15 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31970, '+proj=utm +zone=16 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31971, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31972, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31973, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31974, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31975, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31976, '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31977, '+proj=utm +zone=17 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31978, '+proj=utm +zone=18 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31979, '+proj=utm +zone=19 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31980, '+proj=utm +zone=20 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31981, '+proj=utm +zone=21 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31982, '+proj=utm +zone=22 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31983, '+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31984, '+proj=utm +zone=24 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31985, '+proj=utm +zone=25 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31986, '+proj=utm +zone=17 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31987, '+proj=utm +zone=18 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31988, '+proj=utm +zone=19 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31989, '+proj=utm +zone=20 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31990, '+proj=utm +zone=21 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31991, '+proj=utm +zone=22 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31992, '+proj=utm +zone=17 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31993, '+proj=utm +zone=18 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31994, '+proj=utm +zone=19 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31995, '+proj=utm +zone=20 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31996, '+proj=utm +zone=21 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31997, '+proj=utm +zone=22 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31998, '+proj=utm +zone=23 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [31999, '+proj=utm +zone=24 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [32000, '+proj=utm +zone=25 +south +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs '],
    [32001, '+proj=lcc +lat_1=48.7166666666666 +lat_2=47.85 +lat_0=47 +lon_0=-109.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32002, '+proj=lcc +lat_1=47.8833333333333 +lat_2=46.45 +lat_0=45.8333333333333 +lon_0=-109.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32003, '+proj=lcc +lat_1=46.4 +lat_2=44.8666666666666 +lat_0=44 +lon_0=-109.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32005, '+proj=lcc +lat_1=41.85 +lat_2=42.8166666666666 +lat_0=41.3333333333333 +lon_0=-100 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32006, '+proj=lcc +lat_1=40.2833333333333 +lat_2=41.7166666666666 +lat_0=39.6666666666666 +lon_0=-99.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32007, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32008, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666666 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32009, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32010, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666666 +k=0.999966667 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32011, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.6666666666666 +k=0.999974999999999 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32012, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909091 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32013, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32014, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916667 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32015, '+proj=tmerc +lat_0=40 +lon_0=-74.3333333333333 +k=0.999966667 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32016, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32017, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32018, '+proj=lcc +lat_1=41.0333333333333 +lat_2=40.6666666666666 +lat_0=40.5 +lon_0=-74 +x_0=304800.609601219 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32019, '+proj=lcc +lat_1=34.3333333333333 +lat_2=36.1666666666666 +lat_0=33.75 +lon_0=-79 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32020, '+proj=lcc +lat_1=47.4333333333333 +lat_2=48.7333333333333 +lat_0=47 +lon_0=-100.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32021, '+proj=lcc +lat_1=46.1833333333333 +lat_2=47.4833333333333 +lat_0=45.6666666666666 +lon_0=-100.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32022, '+proj=lcc +lat_1=40.4333333333333 +lat_2=41.7 +lat_0=39.6666666666666 +lon_0=-82.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32023, '+proj=lcc +lat_1=38.7333333333333 +lat_2=40.0333333333333 +lat_0=38 +lon_0=-82.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32024, '+proj=lcc +lat_1=35.5666666666666 +lat_2=36.7666666666666 +lat_0=35 +lon_0=-98 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32025, '+proj=lcc +lat_1=33.9333333333333 +lat_2=35.2333333333333 +lat_0=33.3333333333333 +lon_0=-98 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32026, '+proj=lcc +lat_1=44.3333333333333 +lat_2=46 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32027, '+proj=lcc +lat_1=42.3333333333333 +lat_2=44 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32028, '+proj=lcc +lat_1=40.8833333333333 +lat_2=41.95 +lat_0=40.1666666666666 +lon_0=-77.75 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32029, '+proj=lcc +lat_1=39.9333333333333 +lat_2=40.8 +lat_0=39.3333333333333 +lon_0=-77.75 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32030, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.9999938 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32031, '+proj=lcc +lat_1=33.7666666666666 +lat_2=34.9666666666666 +lat_0=33 +lon_0=-81 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32033, '+proj=lcc +lat_1=32.3333333333333 +lat_2=33.6666666666666 +lat_0=31.8333333333333 +lon_0=-81 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32034, '+proj=lcc +lat_1=44.4166666666666 +lat_2=45.6833333333333 +lat_0=43.8333333333333 +lon_0=-100 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32035, '+proj=lcc +lat_1=42.8333333333333 +lat_2=44.4 +lat_0=42.3333333333333 +lon_0=-100.333333333333 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32036, '+proj=lcc +lat_1=35.25 +lat_2=36.4166666666666 +lat_0=34.6666666666666 +lon_0=-86 +x_0=30480.0609601219 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32037, '+proj=lcc +lat_1=34.65 +lat_2=36.1833333333333 +lat_0=34 +lon_0=-101.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32038, '+proj=lcc +lat_1=32.1333333333333 +lat_2=33.9666666666666 +lat_0=31.6666666666666 +lon_0=-97.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32039, '+proj=lcc +lat_1=30.1166666666666 +lat_2=31.8833333333333 +lat_0=29.6666666666666 +lon_0=-100.333333333333 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32040, '+proj=lcc +lat_1=28.3833333333333 +lat_2=30.2833333333333 +lat_0=27.8333333333333 +lon_0=-99 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32041, '+proj=lcc +lat_1=26.1666666666666 +lat_2=27.8333333333333 +lat_0=25.6666666666666 +lon_0=-98.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32042, '+proj=lcc +lat_1=40.7166666666666 +lat_2=41.7833333333333 +lat_0=40.3333333333333 +lon_0=-111.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32043, '+proj=lcc +lat_1=39.0166666666666 +lat_2=40.65 +lat_0=38.3333333333333 +lon_0=-111.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32044, '+proj=lcc +lat_1=37.2166666666666 +lat_2=38.35 +lat_0=36.6666666666666 +lon_0=-111.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32045, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32046, '+proj=lcc +lat_1=38.0333333333333 +lat_2=39.2 +lat_0=37.6666666666666 +lon_0=-78.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32047, '+proj=lcc +lat_1=36.7666666666666 +lat_2=37.9666666666666 +lat_0=36.3333333333333 +lon_0=-78.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32048, '+proj=lcc +lat_1=47.5 +lat_2=48.7333333333333 +lat_0=47 +lon_0=-120.833333333333 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32049, '+proj=lcc +lat_1=45.8333333333333 +lat_2=47.3333333333333 +lat_0=45.3333333333333 +lon_0=-120.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32050, '+proj=lcc +lat_1=39 +lat_2=40.25 +lat_0=38.5 +lon_0=-79.5 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32051, '+proj=lcc +lat_1=37.4833333333333 +lat_2=38.8833333333333 +lat_0=37 +lon_0=-81 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32052, '+proj=lcc +lat_1=45.5666666666666 +lat_2=46.7666666666666 +lat_0=45.1666666666666 +lon_0=-90 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32053, '+proj=lcc +lat_1=44.25 +lat_2=45.5 +lat_0=43.8333333333333 +lon_0=-90 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32054, '+proj=lcc +lat_1=42.7333333333333 +lat_2=44.0666666666666 +lat_0=42 +lon_0=-90 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32055, '+proj=tmerc +lat_0=40.6666666666666 +lon_0=-105.166666666666 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32056, '+proj=tmerc +lat_0=40.6666666666666 +lon_0=-107.333333333333 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32057, '+proj=tmerc +lat_0=40.6666666666666 +lon_0=-108.75 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32058, '+proj=tmerc +lat_0=40.6666666666666 +lon_0=-110.083333333333 +k=0.999941177 +x_0=152400.304800609 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32059, '+proj=lcc +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +lat_1=18.0333333333333 +lat_2=18.4333333333333 +x_0=152400.30480061 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32060, '+proj=lcc +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +lat_1=18.0333333333333 +lat_2=18.4333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +datum=NAD27 +units=us-ft +no_defs'],
    [32061, '+proj=lcc +lat_1=16.8166666666666 +lat_0=16.8166666666666 +lon_0=-90.3333333333333 +k_0=0.99992226 +x_0=500000 +y_0=292209.579 +datum=NAD27 +units=m +no_defs'],
    [32062, '+proj=lcc +lat_1=14.9 +lat_0=14.9 +lon_0=-90.3333333333333 +k_0=0.99989906 +x_0=500000 +y_0=325992.681 +datum=NAD27 +units=m +no_defs'],
    [32064, '+proj=tmerc +lat_0=0 +lon_0=-99 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32065, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32066, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32067, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32074, '+proj=tmerc +lat_0=0 +lon_0=-99 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32075, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32076, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32077, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32081, '+proj=tmerc +lat_0=0 +lon_0=-53 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32082, '+proj=tmerc +lat_0=0 +lon_0=-56 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32083, '+proj=tmerc +lat_0=0 +lon_0=-58.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32084, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32085, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32086, '+proj=tmerc +lat_0=0 +lon_0=-67.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32098, '+proj=lcc +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [32099, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.6666666666666 +lon_0=-91.3333333333333 +x_0=609601.219202438 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [32100, '+proj=lcc +lat_1=49 +lat_2=45 +lat_0=44.25 +lon_0=-109.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32104, '+proj=lcc +lat_1=43 +lat_2=40 +lat_0=39.8333333333333 +lon_0=-100 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32107, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +datum=NAD83 +units=m +no_defs'],
    [32108, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666666 +k=0.9999 +x_0=500000 +y_0=6000000 +datum=NAD83 +units=m +no_defs'],
    [32109, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +datum=NAD83 +units=m +no_defs'],
    [32110, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666666 +k=0.999966667 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32111, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32112, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909091 +x_0=165000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32113, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32114, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916667 +x_0=830000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32115, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32116, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32117, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32118, '+proj=lcc +lat_1=41.0333333333333 +lat_2=40.6666666666666 +lat_0=40.1666666666666 +lon_0=-74 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32119, '+proj=lcc +lat_1=36.1666666666666 +lat_2=34.3333333333333 +lat_0=33.75 +lon_0=-79 +x_0=609601.22 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32120, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.4333333333333 +lat_0=47 +lon_0=-100.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32121, '+proj=lcc +lat_1=47.4833333333333 +lat_2=46.1833333333333 +lat_0=45.6666666666666 +lon_0=-100.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32122, '+proj=lcc +lat_1=41.7 +lat_2=40.4333333333333 +lat_0=39.6666666666666 +lon_0=-82.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32123, '+proj=lcc +lat_1=40.0333333333333 +lat_2=38.7333333333333 +lat_0=38 +lon_0=-82.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32124, '+proj=lcc +lat_1=36.7666666666666 +lat_2=35.5666666666666 +lat_0=35 +lon_0=-98 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32125, '+proj=lcc +lat_1=35.2333333333333 +lat_2=33.9333333333333 +lat_0=33.3333333333333 +lon_0=-98 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32126, '+proj=lcc +lat_1=46 +lat_2=44.3333333333333 +lat_0=43.6666666666666 +lon_0=-120.5 +x_0=2500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32127, '+proj=lcc +lat_1=44 +lat_2=42.3333333333333 +lat_0=41.6666666666666 +lon_0=-120.5 +x_0=1500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32128, '+proj=lcc +lat_1=41.95 +lat_2=40.8833333333333 +lat_0=40.1666666666666 +lon_0=-77.75 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32129, '+proj=lcc +lat_1=40.9666666666666 +lat_2=39.9333333333333 +lat_0=39.3333333333333 +lon_0=-77.75 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32130, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32133, '+proj=lcc +lat_1=34.8333333333333 +lat_2=32.5 +lat_0=31.8333333333333 +lon_0=-81 +x_0=609600 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32134, '+proj=lcc +lat_1=45.6833333333333 +lat_2=44.4166666666666 +lat_0=43.8333333333333 +lon_0=-100 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32135, '+proj=lcc +lat_1=44.4 +lat_2=42.8333333333333 +lat_0=42.3333333333333 +lon_0=-100.333333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32136, '+proj=lcc +lat_1=36.4166666666666 +lat_2=35.25 +lat_0=34.3333333333333 +lon_0=-86 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32137, '+proj=lcc +lat_1=36.1833333333333 +lat_2=34.65 +lat_0=34 +lon_0=-101.5 +x_0=200000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [32138, '+proj=lcc +lat_1=33.9666666666666 +lat_2=32.1333333333333 +lat_0=31.6666666666666 +lon_0=-98.5 +x_0=600000 +y_0=2000000 +datum=NAD83 +units=m +no_defs'],
    [32139, '+proj=lcc +lat_1=31.8833333333333 +lat_2=30.1166666666666 +lat_0=29.6666666666666 +lon_0=-100.333333333333 +x_0=700000 +y_0=3000000 +datum=NAD83 +units=m +no_defs'],
    [32140, '+proj=lcc +lat_1=30.2833333333333 +lat_2=28.3833333333333 +lat_0=27.8333333333333 +lon_0=-99 +x_0=600000 +y_0=4000000 +datum=NAD83 +units=m +no_defs'],
    [32141, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.6666666666666 +lon_0=-98.5 +x_0=300000 +y_0=5000000 +datum=NAD83 +units=m +no_defs'],
    [32142, '+proj=lcc +lat_1=41.7833333333333 +lat_2=40.7166666666666 +lat_0=40.3333333333333 +lon_0=-111.5 +x_0=500000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [32143, '+proj=lcc +lat_1=40.65 +lat_2=39.0166666666666 +lat_0=38.3333333333333 +lon_0=-111.5 +x_0=500000 +y_0=2000000 +datum=NAD83 +units=m +no_defs'],
    [32144, '+proj=lcc +lat_1=38.35 +lat_2=37.2166666666666 +lat_0=36.6666666666666 +lon_0=-111.5 +x_0=500000 +y_0=3000000 +datum=NAD83 +units=m +no_defs'],
    [32145, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964286 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32146, '+proj=lcc +lat_1=39.2 +lat_2=38.0333333333333 +lat_0=37.6666666666666 +lon_0=-78.5 +x_0=3500000 +y_0=2000000 +datum=NAD83 +units=m +no_defs'],
    [32147, '+proj=lcc +lat_1=37.9666666666666 +lat_2=36.7666666666666 +lat_0=36.3333333333333 +lon_0=-78.5 +x_0=3500000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [32148, '+proj=lcc +lat_1=48.7333333333333 +lat_2=47.5 +lat_0=47 +lon_0=-120.833333333333 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32149, '+proj=lcc +lat_1=47.3333333333333 +lat_2=45.8333333333333 +lat_0=45.3333333333333 +lon_0=-120.5 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32150, '+proj=lcc +lat_1=40.25 +lat_2=39 +lat_0=38.5 +lon_0=-79.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32151, '+proj=lcc +lat_1=38.8833333333333 +lat_2=37.4833333333333 +lat_0=37 +lon_0=-81 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32152, '+proj=lcc +lat_1=46.7666666666666 +lat_2=45.5666666666666 +lat_0=45.1666666666666 +lon_0=-90 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32153, '+proj=lcc +lat_1=45.5 +lat_2=44.25 +lat_0=43.8333333333333 +lon_0=-90 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32154, '+proj=lcc +lat_1=44.0666666666666 +lat_2=42.7333333333333 +lat_0=42 +lon_0=-90 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32155, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666666 +k=0.9999375 +x_0=200000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32156, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +datum=NAD83 +units=m +no_defs'],
    [32157, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32158, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +datum=NAD83 +units=m +no_defs'],
    [32161, '+proj=lcc +lat_1=18.4333333333333 +lat_2=18.0333333333333 +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +x_0=200000 +y_0=200000 +datum=NAD83 +units=m +no_defs'],
    [32164, '+proj=tmerc +lat_0=0 +lon_0=-99 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [32165, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [32166, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [32167, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [32180, '+proj=tmerc +lat_0=0 +lon_0=-55.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32181, '+proj=tmerc +lat_0=0 +lon_0=-53 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32182, '+proj=tmerc +lat_0=0 +lon_0=-56 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32183, '+proj=tmerc +lat_0=0 +lon_0=-58.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32184, '+proj=tmerc +lat_0=0 +lon_0=-61.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32185, '+proj=tmerc +lat_0=0 +lon_0=-64.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32186, '+proj=tmerc +lat_0=0 +lon_0=-67.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32187, '+proj=tmerc +lat_0=0 +lon_0=-70.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32188, '+proj=tmerc +lat_0=0 +lon_0=-73.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32189, '+proj=tmerc +lat_0=0 +lon_0=-76.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32190, '+proj=tmerc +lat_0=0 +lon_0=-79.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32191, '+proj=tmerc +lat_0=0 +lon_0=-82.5 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32192, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32193, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32194, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32195, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32196, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32197, '+proj=tmerc +lat_0=0 +lon_0=-96 +k=0.9999 +x_0=304800 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32198, '+proj=lcc +lat_1=60 +lat_2=46 +lat_0=44 +lon_0=-68.5 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32199, '+proj=lcc +lat_1=27.8333333333333 +lat_2=26.1666666666666 +lat_0=25.5 +lon_0=-91.3333333333333 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [32201, '+proj=utm +zone=1 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32202, '+proj=utm +zone=2 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32203, '+proj=utm +zone=3 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32204, '+proj=utm +zone=4 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32205, '+proj=utm +zone=5 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32206, '+proj=utm +zone=6 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32207, '+proj=utm +zone=7 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32208, '+proj=utm +zone=8 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32209, '+proj=utm +zone=9 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32210, '+proj=utm +zone=10 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32211, '+proj=utm +zone=11 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32212, '+proj=utm +zone=12 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32213, '+proj=utm +zone=13 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32214, '+proj=utm +zone=14 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32215, '+proj=utm +zone=15 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32216, '+proj=utm +zone=16 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32217, '+proj=utm +zone=17 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32218, '+proj=utm +zone=18 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32219, '+proj=utm +zone=19 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32220, '+proj=utm +zone=20 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32221, '+proj=utm +zone=21 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32222, '+proj=utm +zone=22 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32223, '+proj=utm +zone=23 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32224, '+proj=utm +zone=24 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32225, '+proj=utm +zone=25 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32226, '+proj=utm +zone=26 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32227, '+proj=utm +zone=27 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32228, '+proj=utm +zone=28 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32229, '+proj=utm +zone=29 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32230, '+proj=utm +zone=30 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32231, '+proj=utm +zone=31 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32232, '+proj=utm +zone=32 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32233, '+proj=utm +zone=33 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32234, '+proj=utm +zone=34 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32235, '+proj=utm +zone=35 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32236, '+proj=utm +zone=36 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32237, '+proj=utm +zone=37 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32238, '+proj=utm +zone=38 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32239, '+proj=utm +zone=39 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32240, '+proj=utm +zone=40 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32241, '+proj=utm +zone=41 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32242, '+proj=utm +zone=42 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32243, '+proj=utm +zone=43 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32244, '+proj=utm +zone=44 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32245, '+proj=utm +zone=45 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32246, '+proj=utm +zone=46 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32247, '+proj=utm +zone=47 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32248, '+proj=utm +zone=48 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32249, '+proj=utm +zone=49 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32250, '+proj=utm +zone=50 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32251, '+proj=utm +zone=51 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32252, '+proj=utm +zone=52 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32253, '+proj=utm +zone=53 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32254, '+proj=utm +zone=54 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32255, '+proj=utm +zone=55 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32256, '+proj=utm +zone=56 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32257, '+proj=utm +zone=57 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32258, '+proj=utm +zone=58 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32259, '+proj=utm +zone=59 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32260, '+proj=utm +zone=60 +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32301, '+proj=utm +zone=1 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32302, '+proj=utm +zone=2 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32303, '+proj=utm +zone=3 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32304, '+proj=utm +zone=4 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32305, '+proj=utm +zone=5 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32306, '+proj=utm +zone=6 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32307, '+proj=utm +zone=7 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32308, '+proj=utm +zone=8 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32309, '+proj=utm +zone=9 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32310, '+proj=utm +zone=10 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32311, '+proj=utm +zone=11 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32312, '+proj=utm +zone=12 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32313, '+proj=utm +zone=13 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32314, '+proj=utm +zone=14 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32315, '+proj=utm +zone=15 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32316, '+proj=utm +zone=16 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32317, '+proj=utm +zone=17 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32318, '+proj=utm +zone=18 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32319, '+proj=utm +zone=19 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32320, '+proj=utm +zone=20 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32321, '+proj=utm +zone=21 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32322, '+proj=utm +zone=22 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32323, '+proj=utm +zone=23 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32324, '+proj=utm +zone=24 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32325, '+proj=utm +zone=25 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32326, '+proj=utm +zone=26 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32327, '+proj=utm +zone=27 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32328, '+proj=utm +zone=28 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32329, '+proj=utm +zone=29 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32330, '+proj=utm +zone=30 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32331, '+proj=utm +zone=31 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32332, '+proj=utm +zone=32 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32333, '+proj=utm +zone=33 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32334, '+proj=utm +zone=34 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32335, '+proj=utm +zone=35 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32336, '+proj=utm +zone=36 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32337, '+proj=utm +zone=37 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32338, '+proj=utm +zone=38 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32339, '+proj=utm +zone=39 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32340, '+proj=utm +zone=40 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32341, '+proj=utm +zone=41 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32342, '+proj=utm +zone=42 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32343, '+proj=utm +zone=43 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32344, '+proj=utm +zone=44 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32345, '+proj=utm +zone=45 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32346, '+proj=utm +zone=46 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32347, '+proj=utm +zone=47 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32348, '+proj=utm +zone=48 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32349, '+proj=utm +zone=49 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32350, '+proj=utm +zone=50 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32351, '+proj=utm +zone=51 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32352, '+proj=utm +zone=52 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32353, '+proj=utm +zone=53 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32354, '+proj=utm +zone=54 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32355, '+proj=utm +zone=55 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32356, '+proj=utm +zone=56 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32357, '+proj=utm +zone=57 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32358, '+proj=utm +zone=58 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32359, '+proj=utm +zone=59 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32360, '+proj=utm +zone=60 +south +ellps=WGS72 +towgs84=0,0,4.5,0,0,0.554,0.2263 +units=m +no_defs '],
    [32401, '+proj=utm +zone=1 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32402, '+proj=utm +zone=2 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32403, '+proj=utm +zone=3 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32404, '+proj=utm +zone=4 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32405, '+proj=utm +zone=5 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32406, '+proj=utm +zone=6 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32407, '+proj=utm +zone=7 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32408, '+proj=utm +zone=8 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32409, '+proj=utm +zone=9 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32410, '+proj=utm +zone=10 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32411, '+proj=utm +zone=11 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32412, '+proj=utm +zone=12 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32413, '+proj=utm +zone=13 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32414, '+proj=utm +zone=14 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32415, '+proj=utm +zone=15 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32416, '+proj=utm +zone=16 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32417, '+proj=utm +zone=17 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32418, '+proj=utm +zone=18 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32419, '+proj=utm +zone=19 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32420, '+proj=utm +zone=20 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32421, '+proj=utm +zone=21 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32422, '+proj=utm +zone=22 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32423, '+proj=utm +zone=23 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32424, '+proj=utm +zone=24 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32425, '+proj=utm +zone=25 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32426, '+proj=utm +zone=26 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32427, '+proj=utm +zone=27 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32428, '+proj=utm +zone=28 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32429, '+proj=utm +zone=29 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32430, '+proj=utm +zone=30 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32431, '+proj=utm +zone=31 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32432, '+proj=utm +zone=32 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32433, '+proj=utm +zone=33 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32434, '+proj=utm +zone=34 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32435, '+proj=utm +zone=35 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32436, '+proj=utm +zone=36 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32437, '+proj=utm +zone=37 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32438, '+proj=utm +zone=38 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32439, '+proj=utm +zone=39 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32440, '+proj=utm +zone=40 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32441, '+proj=utm +zone=41 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32442, '+proj=utm +zone=42 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32443, '+proj=utm +zone=43 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32444, '+proj=utm +zone=44 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32445, '+proj=utm +zone=45 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32446, '+proj=utm +zone=46 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32447, '+proj=utm +zone=47 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32448, '+proj=utm +zone=48 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32449, '+proj=utm +zone=49 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32450, '+proj=utm +zone=50 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32451, '+proj=utm +zone=51 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32452, '+proj=utm +zone=52 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32453, '+proj=utm +zone=53 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32454, '+proj=utm +zone=54 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32455, '+proj=utm +zone=55 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32456, '+proj=utm +zone=56 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32457, '+proj=utm +zone=57 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32458, '+proj=utm +zone=58 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32459, '+proj=utm +zone=59 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32460, '+proj=utm +zone=60 +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32501, '+proj=utm +zone=1 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32502, '+proj=utm +zone=2 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32503, '+proj=utm +zone=3 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32504, '+proj=utm +zone=4 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32505, '+proj=utm +zone=5 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32506, '+proj=utm +zone=6 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32507, '+proj=utm +zone=7 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32508, '+proj=utm +zone=8 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32509, '+proj=utm +zone=9 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32510, '+proj=utm +zone=10 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32511, '+proj=utm +zone=11 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32512, '+proj=utm +zone=12 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32513, '+proj=utm +zone=13 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32514, '+proj=utm +zone=14 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32515, '+proj=utm +zone=15 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32516, '+proj=utm +zone=16 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32517, '+proj=utm +zone=17 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32518, '+proj=utm +zone=18 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32519, '+proj=utm +zone=19 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32520, '+proj=utm +zone=20 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32521, '+proj=utm +zone=21 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32522, '+proj=utm +zone=22 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32523, '+proj=utm +zone=23 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32524, '+proj=utm +zone=24 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32525, '+proj=utm +zone=25 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32526, '+proj=utm +zone=26 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32527, '+proj=utm +zone=27 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32528, '+proj=utm +zone=28 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32529, '+proj=utm +zone=29 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32530, '+proj=utm +zone=30 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32531, '+proj=utm +zone=31 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32532, '+proj=utm +zone=32 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32533, '+proj=utm +zone=33 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32534, '+proj=utm +zone=34 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32535, '+proj=utm +zone=35 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32536, '+proj=utm +zone=36 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32537, '+proj=utm +zone=37 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32538, '+proj=utm +zone=38 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32539, '+proj=utm +zone=39 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32540, '+proj=utm +zone=40 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32541, '+proj=utm +zone=41 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32542, '+proj=utm +zone=42 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32543, '+proj=utm +zone=43 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32544, '+proj=utm +zone=44 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32545, '+proj=utm +zone=45 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32546, '+proj=utm +zone=46 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32547, '+proj=utm +zone=47 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32548, '+proj=utm +zone=48 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32549, '+proj=utm +zone=49 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32550, '+proj=utm +zone=50 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32551, '+proj=utm +zone=51 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32552, '+proj=utm +zone=52 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32553, '+proj=utm +zone=53 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32554, '+proj=utm +zone=54 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32555, '+proj=utm +zone=55 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32556, '+proj=utm +zone=56 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32557, '+proj=utm +zone=57 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32558, '+proj=utm +zone=58 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32559, '+proj=utm +zone=59 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32560, '+proj=utm +zone=60 +south +ellps=WGS72 +towgs84=0,0,1.9,0,0,0.814,-0.38 +units=m +no_defs '],
    [32601, '+proj=utm +zone=1 +datum=WGS84 +units=m +no_defs'],
    [32602, '+proj=utm +zone=2 +datum=WGS84 +units=m +no_defs'],
    [32603, '+proj=utm +zone=3 +datum=WGS84 +units=m +no_defs'],
    [32604, '+proj=utm +zone=4 +datum=WGS84 +units=m +no_defs'],
    [32605, '+proj=utm +zone=5 +datum=WGS84 +units=m +no_defs'],
    [32606, '+proj=utm +zone=6 +datum=WGS84 +units=m +no_defs'],
    [32607, '+proj=utm +zone=7 +datum=WGS84 +units=m +no_defs'],
    [32608, '+proj=utm +zone=8 +datum=WGS84 +units=m +no_defs'],
    [32609, '+proj=utm +zone=9 +datum=WGS84 +units=m +no_defs'],
    [32610, '+proj=utm +zone=10 +datum=WGS84 +units=m +no_defs'],
    [32611, '+proj=utm +zone=11 +datum=WGS84 +units=m +no_defs'],
    [32612, '+proj=utm +zone=12 +datum=WGS84 +units=m +no_defs'],
    [32613, '+proj=utm +zone=13 +datum=WGS84 +units=m +no_defs'],
    [32614, '+proj=utm +zone=14 +datum=WGS84 +units=m +no_defs'],
    [32615, '+proj=utm +zone=15 +datum=WGS84 +units=m +no_defs'],
    [32616, '+proj=utm +zone=16 +datum=WGS84 +units=m +no_defs'],
    [32617, '+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs'],
    [32618, '+proj=utm +zone=18 +datum=WGS84 +units=m +no_defs'],
    [32619, '+proj=utm +zone=19 +datum=WGS84 +units=m +no_defs'],
    [32620, '+proj=utm +zone=20 +datum=WGS84 +units=m +no_defs'],
    [32621, '+proj=utm +zone=21 +datum=WGS84 +units=m +no_defs'],
    [32622, '+proj=utm +zone=22 +datum=WGS84 +units=m +no_defs'],
    [32623, '+proj=utm +zone=23 +datum=WGS84 +units=m +no_defs'],
    [32624, '+proj=utm +zone=24 +datum=WGS84 +units=m +no_defs'],
    [32625, '+proj=utm +zone=25 +datum=WGS84 +units=m +no_defs'],
    [32626, '+proj=utm +zone=26 +datum=WGS84 +units=m +no_defs'],
    [32627, '+proj=utm +zone=27 +datum=WGS84 +units=m +no_defs'],
    [32628, '+proj=utm +zone=28 +datum=WGS84 +units=m +no_defs'],
    [32629, '+proj=utm +zone=29 +datum=WGS84 +units=m +no_defs'],
    [32630, '+proj=utm +zone=30 +datum=WGS84 +units=m +no_defs'],
    [32631, '+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs'],
    [32632, '+proj=utm +zone=32 +datum=WGS84 +units=m +no_defs'],
    [32633, '+proj=utm +zone=33 +datum=WGS84 +units=m +no_defs'],
    [32634, '+proj=utm +zone=34 +datum=WGS84 +units=m +no_defs'],
    [32635, '+proj=utm +zone=35 +datum=WGS84 +units=m +no_defs'],
    [32636, '+proj=utm +zone=36 +datum=WGS84 +units=m +no_defs'],
    [32637, '+proj=utm +zone=37 +datum=WGS84 +units=m +no_defs'],
    [32638, '+proj=utm +zone=38 +datum=WGS84 +units=m +no_defs'],
    [32639, '+proj=utm +zone=39 +datum=WGS84 +units=m +no_defs'],
    [32640, '+proj=utm +zone=40 +datum=WGS84 +units=m +no_defs'],
    [32641, '+proj=utm +zone=41 +datum=WGS84 +units=m +no_defs'],
    [32642, '+proj=utm +zone=42 +datum=WGS84 +units=m +no_defs'],
    [32643, '+proj=utm +zone=43 +datum=WGS84 +units=m +no_defs'],
    [32644, '+proj=utm +zone=44 +datum=WGS84 +units=m +no_defs'],
    [32645, '+proj=utm +zone=45 +datum=WGS84 +units=m +no_defs'],
    [32646, '+proj=utm +zone=46 +datum=WGS84 +units=m +no_defs'],
    [32647, '+proj=utm +zone=47 +datum=WGS84 +units=m +no_defs'],
    [32648, '+proj=utm +zone=48 +datum=WGS84 +units=m +no_defs'],
    [32649, '+proj=utm +zone=49 +datum=WGS84 +units=m +no_defs'],
    [32650, '+proj=utm +zone=50 +datum=WGS84 +units=m +no_defs'],
    [32651, '+proj=utm +zone=51 +datum=WGS84 +units=m +no_defs'],
    [32652, '+proj=utm +zone=52 +datum=WGS84 +units=m +no_defs'],
    [32653, '+proj=utm +zone=53 +datum=WGS84 +units=m +no_defs'],
    [32654, '+proj=utm +zone=54 +datum=WGS84 +units=m +no_defs'],
    [32655, '+proj=utm +zone=55 +datum=WGS84 +units=m +no_defs'],
    [32656, '+proj=utm +zone=56 +datum=WGS84 +units=m +no_defs'],
    [32657, '+proj=utm +zone=57 +datum=WGS84 +units=m +no_defs'],
    [32658, '+proj=utm +zone=58 +datum=WGS84 +units=m +no_defs'],
    [32659, '+proj=utm +zone=59 +datum=WGS84 +units=m +no_defs'],
    [32660, '+proj=utm +zone=60 +datum=WGS84 +units=m +no_defs'],
    [32661, '+proj=stere +lat_0=90 +lat_ts=90 +lon_0=0 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [32662, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [32663, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [32664, '+proj=tmerc +lat_0=0 +lon_0=-99 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=WGS84 +units=us-ft +no_defs'],
    [32665, '+proj=tmerc +lat_0=0 +lon_0=-93 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=WGS84 +units=us-ft +no_defs'],
    [32666, '+proj=tmerc +lat_0=0 +lon_0=-87 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=WGS84 +units=us-ft +no_defs'],
    [32667, '+proj=tmerc +lat_0=0 +lon_0=-81 +k=0.9996 +x_0=500000.001016002 +y_0=0 +datum=WGS84 +units=us-ft +no_defs'],
    [32701, '+proj=utm +zone=1 +south +datum=WGS84 +units=m +no_defs'],
    [32702, '+proj=utm +zone=2 +south +datum=WGS84 +units=m +no_defs'],
    [32703, '+proj=utm +zone=3 +south +datum=WGS84 +units=m +no_defs'],
    [32704, '+proj=utm +zone=4 +south +datum=WGS84 +units=m +no_defs'],
    [32705, '+proj=utm +zone=5 +south +datum=WGS84 +units=m +no_defs'],
    [32706, '+proj=utm +zone=6 +south +datum=WGS84 +units=m +no_defs'],
    [32707, '+proj=utm +zone=7 +south +datum=WGS84 +units=m +no_defs'],
    [32708, '+proj=utm +zone=8 +south +datum=WGS84 +units=m +no_defs'],
    [32709, '+proj=utm +zone=9 +south +datum=WGS84 +units=m +no_defs'],
    [32710, '+proj=utm +zone=10 +south +datum=WGS84 +units=m +no_defs'],
    [32711, '+proj=utm +zone=11 +south +datum=WGS84 +units=m +no_defs'],
    [32712, '+proj=utm +zone=12 +south +datum=WGS84 +units=m +no_defs'],
    [32713, '+proj=utm +zone=13 +south +datum=WGS84 +units=m +no_defs'],
    [32714, '+proj=utm +zone=14 +south +datum=WGS84 +units=m +no_defs'],
    [32715, '+proj=utm +zone=15 +south +datum=WGS84 +units=m +no_defs'],
    [32716, '+proj=utm +zone=16 +south +datum=WGS84 +units=m +no_defs'],
    [32717, '+proj=utm +zone=17 +south +datum=WGS84 +units=m +no_defs'],
    [32718, '+proj=utm +zone=18 +south +datum=WGS84 +units=m +no_defs'],
    [32719, '+proj=utm +zone=19 +south +datum=WGS84 +units=m +no_defs'],
    [32720, '+proj=utm +zone=20 +south +datum=WGS84 +units=m +no_defs'],
    [32721, '+proj=utm +zone=21 +south +datum=WGS84 +units=m +no_defs'],
    [32722, '+proj=utm +zone=22 +south +datum=WGS84 +units=m +no_defs'],
    [32723, '+proj=utm +zone=23 +south +datum=WGS84 +units=m +no_defs'],
    [32724, '+proj=utm +zone=24 +south +datum=WGS84 +units=m +no_defs'],
    [32725, '+proj=utm +zone=25 +south +datum=WGS84 +units=m +no_defs'],
    [32726, '+proj=utm +zone=26 +south +datum=WGS84 +units=m +no_defs'],
    [32727, '+proj=utm +zone=27 +south +datum=WGS84 +units=m +no_defs'],
    [32728, '+proj=utm +zone=28 +south +datum=WGS84 +units=m +no_defs'],
    [32729, '+proj=utm +zone=29 +south +datum=WGS84 +units=m +no_defs'],
    [32730, '+proj=utm +zone=30 +south +datum=WGS84 +units=m +no_defs'],
    [32731, '+proj=utm +zone=31 +south +datum=WGS84 +units=m +no_defs'],
    [32732, '+proj=utm +zone=32 +south +datum=WGS84 +units=m +no_defs'],
    [32733, '+proj=utm +zone=33 +south +datum=WGS84 +units=m +no_defs'],
    [32734, '+proj=utm +zone=34 +south +datum=WGS84 +units=m +no_defs'],
    [32735, '+proj=utm +zone=35 +south +datum=WGS84 +units=m +no_defs'],
    [32736, '+proj=utm +zone=36 +south +datum=WGS84 +units=m +no_defs'],
    [32737, '+proj=utm +zone=37 +south +datum=WGS84 +units=m +no_defs'],
    [32738, '+proj=utm +zone=38 +south +datum=WGS84 +units=m +no_defs'],
    [32739, '+proj=utm +zone=39 +south +datum=WGS84 +units=m +no_defs'],
    [32740, '+proj=utm +zone=40 +south +datum=WGS84 +units=m +no_defs'],
    [32741, '+proj=utm +zone=41 +south +datum=WGS84 +units=m +no_defs'],
    [32742, '+proj=utm +zone=42 +south +datum=WGS84 +units=m +no_defs'],
    [32743, '+proj=utm +zone=43 +south +datum=WGS84 +units=m +no_defs'],
    [32744, '+proj=utm +zone=44 +south +datum=WGS84 +units=m +no_defs'],
    [32745, '+proj=utm +zone=45 +south +datum=WGS84 +units=m +no_defs'],
    [32746, '+proj=utm +zone=46 +south +datum=WGS84 +units=m +no_defs'],
    [32747, '+proj=utm +zone=47 +south +datum=WGS84 +units=m +no_defs'],
    [32748, '+proj=utm +zone=48 +south +datum=WGS84 +units=m +no_defs'],
    [32749, '+proj=utm +zone=49 +south +datum=WGS84 +units=m +no_defs'],
    [32750, '+proj=utm +zone=50 +south +datum=WGS84 +units=m +no_defs'],
    [32751, '+proj=utm +zone=51 +south +datum=WGS84 +units=m +no_defs'],
    [32752, '+proj=utm +zone=52 +south +datum=WGS84 +units=m +no_defs'],
    [32753, '+proj=utm +zone=53 +south +datum=WGS84 +units=m +no_defs'],
    [32754, '+proj=utm +zone=54 +south +datum=WGS84 +units=m +no_defs'],
    [32755, '+proj=utm +zone=55 +south +datum=WGS84 +units=m +no_defs'],
    [32756, '+proj=utm +zone=56 +south +datum=WGS84 +units=m +no_defs'],
    [32757, '+proj=utm +zone=57 +south +datum=WGS84 +units=m +no_defs'],
    [32758, '+proj=utm +zone=58 +south +datum=WGS84 +units=m +no_defs'],
    [32759, '+proj=utm +zone=59 +south +datum=WGS84 +units=m +no_defs'],
    [32760, '+proj=utm +zone=60 +south +datum=WGS84 +units=m +no_defs'],
    [32761, '+proj=stere +lat_0=-90 +lat_ts=-90 +lon_0=0 +k=0.994 +x_0=2000000 +y_0=2000000 +datum=WGS84 +units=m +no_defs'],
    [32766, '+proj=tmerc +lat_0=0 +lon_0=36 +k=0.9996 +x_0=500000 +y_0=10000000 +datum=WGS84 +units=m +no_defs'],
    [37001, '+proj=longlat +ellps=NWL9D +no_defs'],
    [37002, '+proj=longlat +ellps=fschr60 +no_defs'],
    [37003, '+proj=longlat +ellps=fschr68 +no_defs'],
    [37004, '+proj=longlat +ellps=fschr60m +no_defs'],
    [37005, '+proj=longlat +ellps=hough +no_defs'],
    [37006, '+proj=longlat +ellps=evrst69 +no_defs'],
    [37007, '+proj=longlat +ellps=walbeck +no_defs'],
    [37008, '+proj=longlat +ellps=sphere +no_defs'],
    [37201, '+proj=longlat +ellps=intl +no_defs'],
    [37202, '+proj=longlat +ellps=evrst30 +no_defs'],
    [37203, '+proj=longlat +a=6377301.243 +rf=300.8017255 +no_defs'],
    [37204, '+proj=longlat +ellps=intl +no_defs'],
    [37205, '+proj=longlat +ellps=intl +no_defs'],
    [37206, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [37207, '+proj=longlat +ellps=fschr60m +no_defs'],
    [37208, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [37211, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [37212, '+proj=longlat +ellps=intl +no_defs'],
    [37213, '+proj=longlat +ellps=intl +no_defs'],
    [37214, '+proj=longlat +ellps=intl +no_defs'],
    [37215, '+proj=longlat +ellps=intl +no_defs'],
    [37216, '+proj=longlat +ellps=intl +no_defs'],
    [37217, '+proj=longlat +ellps=intl +no_defs'],
    [37218, '+proj=longlat +ellps=intl +no_defs'],
    [37219, '+proj=longlat +ellps=intl +no_defs'],
    [37220, '+proj=longlat +ellps=clrk66 +no_defs'],
    [37221, '+proj=longlat +ellps=intl +no_defs'],
    [37222, '+proj=longlat +ellps=intl +no_defs'],
    [37223, '+proj=longlat +ellps=clrk80ign +no_defs'],
    [37224, '+proj=longlat +ellps=intl +no_defs'],
    [37225, '+proj=longlat +ellps=clrk80ign +no_defs'],
    [37226, '+proj=longlat +ellps=intl +no_defs'],
    [37227, '+proj=longlat +ellps=intl +no_defs'],
    [37228, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [37229, '+proj=longlat +ellps=hough +no_defs'],
    [37230, '+proj=longlat +ellps=intl +no_defs'],
    [37231, '+proj=longlat +ellps=aust_SA +no_defs'],
    [37232, '+proj=longlat +ellps=intl +no_defs'],
    [37233, '+proj=longlat +ellps=intl +no_defs'],
    [37234, '+proj=longlat +ellps=intl +no_defs'],
    [37235, '+proj=longlat +ellps=intl +no_defs'],
    [37237, '+proj=longlat +ellps=intl +no_defs'],
    [37238, '+proj=longlat +ellps=intl +no_defs'],
    [37239, '+proj=longlat +ellps=clrk66 +no_defs'],
    [37240, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [37241, '+proj=longlat +ellps=intl +no_defs'],
    [37242, '+proj=longlat +ellps=intl +no_defs'],
    [37243, '+proj=longlat +ellps=clrk66 +no_defs'],
    [37245, '+proj=longlat +ellps=intl +no_defs'],
    [37246, '+proj=longlat +ellps=intl +no_defs'],
    [37247, '+proj=longlat +ellps=intl +no_defs'],
    [37249, '+proj=longlat +ellps=intl +no_defs'],
    [37250, '+proj=longlat +ellps=intl +no_defs'],
    [37251, '+proj=longlat +ellps=intl +no_defs'],
    [37252, '+proj=longlat +ellps=clrk66 +no_defs'],
    [37253, '+proj=longlat +ellps=intl +no_defs'],
    [37254, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [37255, '+proj=longlat +ellps=bessel +no_defs'],
    [37257, '+proj=longlat +ellps=krass +no_defs'],
    [37259, '+proj=longlat +ellps=intl +no_defs'],
    [37260, '+proj=longlat +ellps=clrk66 +no_defs'],
    [53001, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53002, '+proj=eqc +lat_ts=60 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53003, '+proj=mill +R_A +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53004, '+proj=merc +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53008, '+proj=sinu +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53009, '+proj=moll +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53010, '+proj=eck6 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53011, '+proj=eck5 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53012, '+proj=eck4 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53013, '+proj=eck3 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53014, '+proj=eck2 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53015, '+proj=eck1 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53016, '+proj=gall +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53018, '+proj=wink1 +lon_0=0 +lat_ts=50.4597762521898 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53019, '+proj=wink2 +lon_0=0 +lat_1=50.4597762521898 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53021, '+proj=poly +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53022, '+proj=qua_aut +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53023, '+proj=loxim +lat_1=40 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53024, '+proj=bonne +lat_1=60 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53025, '+proj=omerc +lat_0=40 +lat_1=0 +lon_1=0 +lat_2=60 +lon_2=60 +k=1 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53026, '+proj=stere +lat_0=0 +lon_0=0 +k=1 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53027, '+proj=eqdc +lat_0=0 +lon_0=0 +lat_1=60 +lat_2=60 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53028, '+proj=cass +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53029, '+proj=vandg +R_A +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53030, '+proj=robin +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53031, '+proj=tpeqd +lat_1=0 +lon_1=0 +lat_2=60 +lon_2=60 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53032, '+proj=aeqd +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53034, '+proj=cea +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53035, '+proj=eqearth +lon_0=0 +x_0=0 +y_0=0 +R=6371008.7714 +units=m +no_defs'],
    [53036, '+proj=eqearth +lon_0=-90 +x_0=0 +y_0=0 +R=6371008.7714 +units=m +no_defs'],
    [53037, '+proj=eqearth +lon_0=150 +x_0=0 +y_0=0 +R=6371008.7714 +units=m +no_defs'],
    [53042, '+proj=wintri +lon_0=0 +lat_1=50.467 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53043, '+proj=aitoff +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53046, '+proj=crast +lon_0=0 +x_0=0 +y_0=0 +R=6371000 +units=m +no_defs'],
    [53074, '+proj=wag4 +lon_0=0 +x_0=0 +y_0=0 +R=6371007 +units=m +no_defs'],
    [53075, '+proj=wag5 +lon_0=0 +x_0=0 +y_0=0 +R=6371008.7714 +units=m +no_defs'],
    [53076, '+proj=wag7 +lon_0=0 +x_0=0 +y_0=0 +R=6371007 +units=m +no_defs'],
    [54001, '+proj=eqc +lat_ts=0 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54002, '+proj=eqc +lat_ts=60 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54003, '+proj=mill +R_A +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54004, '+proj=merc +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54008, '+proj=sinu +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54009, '+proj=moll +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54010, '+proj=ECK6 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54011, '+proj=ECK5 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54012, '+proj=ECK4 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54013, '+proj=ECK3 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54014, '+proj=ECK2 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54015, '+proj=ECK1 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54016, '+proj=gall +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54018, '+proj=wink1 +lon_0=0 +lat_ts=50.4597762521898 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54019, '+proj=wink2 +lon_0=0 +lat_1=50.4597762521898 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54021, '+proj=poly +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54022, '+proj=qua_aut +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54023, '+proj=loxim +lat_1=40 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54024, '+proj=bonne +lat_1=60 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54025, '+proj=omerc +lat_0=40 +lat_1=0 +lon_1=0 +lat_2=60 +lon_2=60 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54026, '+proj=stere +lat_0=0 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54027, '+proj=eqdc +lat_0=0 +lon_0=0 +lat_1=60 +lat_2=60 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54028, '+proj=cass +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54029, '+proj=vandg +R_A +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54030, '+proj=robin +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54031, '+proj=tpeqd +lat_1=0 +lon_1=0 +lat_2=60 +lon_2=60 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54032, '+proj=aeqd +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54034, '+proj=cea +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54035, '+proj=eqearth +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54036, '+proj=eqearth +lon_0=-90 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54037, '+proj=eqearth +lon_0=150 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54042, '+proj=wintri +lon_0=0 +lat_1=50.467 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54043, '+proj=aitoff +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54046, '+proj=crast +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54052, '+proj=goode +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54053, '+proj=goode +lon_0=-160 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54074, '+proj=WAG4 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54075, '+proj=WAG5 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [54076, '+proj=WAG7 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [65061, '+proj=poly +lat_0=13.4724663527778 +lon_0=144.748750705556 +x_0=50000 +y_0=50000 +datum=NAD27 +units=us-ft +no_defs'],
    [65062, '+proj=lcc +lat_1=-14.2666666666667 +lat_0=-14.2666666666667 +lon_0=-170 +k_0=1 +x_0=152400.30480061 +y_0=95169.3116586233 +ellps=clrk66 +units=us-ft +no_defs'],
    [65161, '+proj=poly +lat_0=13.4724663527778 +lon_0=144.748750705556 +x_0=50000 +y_0=50000 +datum=NAD83 +units=m +no_defs'],
    [65163, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [102001, '+proj=aea +lat_0=40 +lon_0=-96 +lat_1=50 +lat_2=70 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102002, '+proj=lcc +lat_0=40 +lon_0=-96 +lat_1=50 +lat_2=70 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102003, '+proj=aea +lat_0=37.5 +lon_0=-96 +lat_1=29.5 +lat_2=45.5 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102004, '+proj=lcc +lat_0=39 +lon_0=-96 +lat_1=33 +lat_2=45 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102005, '+proj=eqdc +lat_0=39 +lon_0=-96 +lat_1=33 +lat_2=45 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102006, '+proj=aea +lat_0=50 +lon_0=-154 +lat_1=55 +lat_2=65 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102007, '+proj=aea +lat_0=13 +lon_0=-157 +lat_1=8 +lat_2=18 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102008, '+proj=aea +lat_0=40 +lon_0=-96 +lat_1=20 +lat_2=60 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102009, '+proj=lcc +lat_0=40 +lon_0=-96 +lat_1=20 +lat_2=60 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102010, '+proj=eqdc +lat_0=40 +lon_0=-96 +lat_1=20 +lat_2=60 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102011, '+proj=sinu +lon_0=15 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102012, '+proj=lcc +lat_0=0 +lon_0=105 +lat_1=30 +lat_2=62 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102013, '+proj=aea +lat_0=30 +lon_0=10 +lat_1=43 +lat_2=62 +x_0=0 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102014, '+proj=lcc +lat_0=30 +lon_0=10 +lat_1=43 +lat_2=62 +x_0=0 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102015, '+proj=lcc +lat_0=-32 +lon_0=-60 +lat_1=-5 +lat_2=-42 +x_0=0 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [102016, '+proj=aeqd +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102017, '+proj=laea +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102018, '+proj=stere +lat_0=90 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102019, '+proj=aeqd +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102020, '+proj=laea +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102021, '+proj=stere +lat_0=-90 +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102022, '+proj=aea +lat_0=0 +lon_0=25 +lat_1=20 +lat_2=-23 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102023, '+proj=eqdc +lat_0=0 +lon_0=25 +lat_1=20 +lat_2=-23 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102024, '+proj=lcc +lat_0=0 +lon_0=25 +lat_1=20 +lat_2=-23 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102025, '+proj=aea +lat_0=30 +lon_0=95 +lat_1=15 +lat_2=65 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102026, '+proj=eqdc +lat_0=30 +lon_0=95 +lat_1=15 +lat_2=65 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102027, '+proj=lcc +lat_0=30 +lon_0=95 +lat_1=15 +lat_2=65 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102028, '+proj=aea +lat_0=-15 +lon_0=125 +lat_1=7 +lat_2=-32 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102029, '+proj=eqdc +lat_0=-15 +lon_0=125 +lat_1=7 +lat_2=-32 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102030, '+proj=lcc +lat_0=-15 +lon_0=125 +lat_1=7 +lat_2=-32 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102031, '+proj=eqdc +lat_0=30 +lon_0=10 +lat_1=43 +lat_2=62 +x_0=0 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102032, '+proj=eqdc +lat_0=-32 +lon_0=-60 +lat_1=-5 +lat_2=-42 +x_0=0 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [102033, '+proj=aea +lat_0=-32 +lon_0=-60 +lat_1=-5 +lat_2=-42 +x_0=0 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [102034, '+proj=gnom +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102035, '+proj=ortho +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102036, '+proj=gnom +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102037, '+proj=ortho +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102038, '+proj=ortho +lat_0=42.5333333333 +lon_0=-72.5333333334 +x_0=0 +y_0=0 +ellps=sphere +units=m +no_defs'],
    [102039, '+proj=aea +lat_0=23 +lon_0=-96 +lat_1=29.5 +lat_2=45.5 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102040, '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=bessel +units=m +no_defs'],
    [102041, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000.070104141 +y_0=0.0731521463042926 +ellps=GRS80 +units=us-ft +no_defs'],
    [102042, '+proj=aea +lat_0=35 +lon_0=-82.25 +lat_1=38.25 +lat_2=47.25 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102043, '+proj=utm +zone=20 +ellps=GRS80 +units=m +no_defs'],
    [102044, '+proj=utm +zone=20 +ellps=GRS80 +units=m +no_defs'],
    [102045, '+proj=utm +zone=20 +ellps=GRS80 +units=m +no_defs'],
    [102046, '+proj=utm +zone=59 +ellps=GRS80 +units=m +no_defs'],
    [102047, '+proj=utm +zone=60 +ellps=GRS80 +units=m +no_defs'],
    [102048, '+proj=utm +zone=1 +ellps=GRS80 +units=m +no_defs'],
    [102049, '+proj=utm +zone=2 +ellps=GRS80 +units=m +no_defs'],
    [102050, '+proj=utm +zone=3 +ellps=GRS80 +units=m +no_defs'],
    [102051, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [102052, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [102053, '+proj=utm +zone=6 +ellps=GRS80 +units=m +no_defs'],
    [102054, '+proj=utm +zone=7 +ellps=GRS80 +units=m +no_defs'],
    [102055, '+proj=utm +zone=8 +ellps=GRS80 +units=m +no_defs'],
    [102056, '+proj=utm +zone=9 +ellps=GRS80 +units=m +no_defs'],
    [102057, '+proj=utm +zone=10 +ellps=GRS80 +units=m +no_defs'],
    [102058, '+proj=utm +zone=11 +ellps=GRS80 +units=m +no_defs'],
    [102059, '+proj=utm +zone=12 +ellps=GRS80 +units=m +no_defs'],
    [102060, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=bessel +units=m +no_defs'],
    [102061, '+proj=omerc +no_uoff +lat_0=4 +lonc=102.25 +alpha=-36.974209437118 +gamma=-36.869897645844 +k=0.99984 +x_0=804670.24 +y_0=0 +ellps=evrst69 +units=m +no_defs'],
    [102062, '+proj=omerc +no_uoff +lat_0=4 +lonc=102.25 +alpha=-36.974209437118 +gamma=-36.869897645844 +k=0.99984 +x_0=804671.299775 +y_0=0 +ellps=evrst48 +units=m +no_defs'],
    [102063, '+proj=tmerc +lat_0=7.00048027777778 +lon_0=80.7717111111111 +k=1 +x_0=160933.56048 +y_0=160933.56048 +ellps=evrst30 +units=m +no_defs'],
    [102064, '+proj=tmerc +lat_0=7.00048027777778 +lon_0=80.7717111111111 +k=1 +x_0=160933.56048 +y_0=160933.56048 +ellps=evrst30 +units=ind-yd +no_defs'],
    [102065, '+proj=krovak +axis=swu +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [102066, '+proj=krovak +lat_0=49.5 +lon_0=42.5 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +pm=ferro +units=m +no_defs'],
    [102067, '+proj=krovak +lat_0=49.5 +lon_0=24.8333333333333 +alpha=30.2881397527778 +k=0.9999 +x_0=0 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [102068, '+proj=stere +lat_0=90 +lat_ts=60 +lon_0=-32 +x_0=400000 +y_0=5500000 +R=6370000 +to_meter=50000 +no_defs'],
    [102069, '+proj=stere +lat_0=90 +lat_ts=60 +lon_0=-32 +x_0=450000 +y_0=5550000 +R=6370000 +to_meter=150000 +no_defs'],
    [102070, '+proj=tmerc +lat_0=49.5 +lon_0=-2.41666666666667 +k=0.999997 +x_0=47000 +y_0=50000 +datum=WGS84 +units=m +no_defs'],
    [102071, '+proj=tmerc +lat_0=0 +lon_0=149.009294833333 +k=1.000086 +x_0=200000 +y_0=4510193.4939 +ellps=aust_SA +units=m +no_defs'],
    [102072, '+proj=tmerc +lat_0=0 +lon_0=141 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102073, '+proj=tmerc +lat_0=0 +lon_0=143 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102074, '+proj=tmerc +lat_0=0 +lon_0=145 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102075, '+proj=tmerc +lat_0=0 +lon_0=147 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102076, '+proj=tmerc +lat_0=0 +lon_0=149 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102077, '+proj=tmerc +lat_0=0 +lon_0=151 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102078, '+proj=tmerc +lat_0=0 +lon_0=153 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102079, '+proj=tmerc +lat_0=0 +lon_0=155 +k=0.99994 +x_0=300000 +y_0=5000000 +ellps=aust_SA +units=m +no_defs'],
    [102080, '+proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [102081, '+proj=tmerc +lat_0=38 +lon_0=125 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'],
    [102082, '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'],
    [102083, '+proj=tmerc +lat_0=38 +lon_0=129 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'],
    [102084, '+proj=tmerc +lat_0=38 +lon_0=131 +k=1 +x_0=200000 +y_0=600000 +ellps=GRS80 +units=m +no_defs'],
    [102085, '+proj=tmerc +lat_0=38 +lon_0=125.002890277778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [102086, '+proj=tmerc +lat_0=38 +lon_0=127.002890277778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [102087, '+proj=tmerc +lat_0=38 +lon_0=127.002890277778 +k=1 +x_0=200000 +y_0=550000 +ellps=bessel +units=m +no_defs'],
    [102088, '+proj=tmerc +lat_0=38 +lon_0=129.002890277778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [102089, '+proj=tmerc +lat_0=38 +lon_0=131.002890277778 +k=1 +x_0=200000 +y_0=500000 +ellps=bessel +units=m +no_defs'],
    [102090, '+proj=tmerc +lat_0=32 +lon_0=-64.75 +k=1 +x_0=550000 +y_0=100000 +ellps=WGS84 +units=m +no_defs'],
    [102091, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102092, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102093, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=2520000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102094, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=1500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102095, '+proj=lcc +lat_1=18 +lat_0=18 +lon_0=-77 +k_0=1 +x_0=750000 +y_0=650000 +ellps=WGS84 +units=m +no_defs'],
    [102096, '+proj=aeqd +lat_0=7.35122211111111 +lon_0=134.450444861111 +x_0=50000 +y_0=150000 +ellps=clrk66 +units=m +no_defs'],
    [102097, '+proj=utm +zone=26 +ellps=GRS80 +units=m +no_defs'],
    [102098, '+proj=utm +zone=27 +ellps=GRS80 +units=m +no_defs'],
    [102099, '+proj=utm +zone=39 +ellps=GRS80 +units=m +no_defs'],
    [102100, '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs'],
    [102101, '+proj=tmerc +lat_0=58 +lon_0=6.05625 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102102, '+proj=tmerc +lat_0=58 +lon_0=8.38958333333333 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102103, '+proj=tmerc +lat_0=58 +lon_0=10.7229166666667 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102104, '+proj=tmerc +lat_0=58 +lon_0=13.2229166666667 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102105, '+proj=tmerc +lat_0=58 +lon_0=16.8895833333333 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102106, '+proj=tmerc +lat_0=58 +lon_0=20.8895833333333 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102107, '+proj=tmerc +lat_0=58 +lon_0=24.8895833333333 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102108, '+proj=tmerc +lat_0=58 +lon_0=29.05625 +k=1 +x_0=0 +y_0=0 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102109, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9999 +x_0=500000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [102110, '+proj=lcc +lat_0=46.5 +lon_0=3 +lat_1=44 +lat_2=49 +x_0=700000 +y_0=6600000 +ellps=GRS80 +units=m +no_defs'],
    [102111, '+proj=tmerc +lat_0=-44 +lon_0=-176.5 +k=1 +x_0=350000 +y_0=650000 +ellps=intl +units=m +no_defs'],
    [102112, '+proj=tmerc +lat_0=-44 +lon_0=-176.5 +k=1 +x_0=400000 +y_0=800000 +ellps=GRS80 +units=m +no_defs'],
    [102113, '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +wktext +no_defs'],
    [102114, '+proj=utm +zone=4 +ellps=clrk66 +units=m +no_defs'],
    [102115, '+proj=utm +zone=5 +ellps=clrk66 +units=m +no_defs'],
    [102116, '+proj=utm +zone=2 +south +ellps=clrk66 +units=m +no_defs'],
    [102117, '+proj=aea +lat_0=50 +lon_0=-154 +lat_1=55 +lat_2=65 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102118, '+proj=aea +lat_0=23 +lon_0=-83.5 +lat_1=29.5 +lat_2=45.5 +x_0=0 +y_0=0 +datum=NAD27 +units=us-ft +no_defs'],
    [102119, '+proj=lcc +lat_0=31.1666666666667 +lon_0=-100 +lat_1=27.4166666666667 +lat_2=34.9166666666667 +x_0=914400 +y_0=914400 +datum=NAD27 +units=ft +no_defs'],
    [102120, '+proj=omerc +no_uoff +lat_0=45.3091666666667 +lonc=-86 +alpha=337.25556 +gamma=337.25556 +k=0.9996 +x_0=2546731.49596139 +y_0=-4354009.81600203 +datum=NAD27 +units=us-ft +no_defs'],
    [102121, '+proj=omerc +no_uoff +lat_0=45.3091666666667 +lonc=-86 +alpha=337.25556 +gamma=337.25556 +k=0.9996 +x_0=2546731.49596139 +y_0=-4354009.81600203 +datum=NAD83 +units=us-ft +no_defs'],
    [102122, '+proj=omerc +no_uoff +lat_0=45.3091666666667 +lonc=-86 +alpha=337.25556 +gamma=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +datum=NAD27 +units=m +no_defs'],
    [102123, '+proj=omerc +no_uoff +lat_0=45.3091666666667 +lonc=-86 +alpha=337.25556 +gamma=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +datum=NAD83 +units=m +no_defs'],
    [102124, '+proj=utm +zone=1 +datum=NAD27 +units=m +no_defs'],
    [102125, '+proj=utm +zone=2 +datum=NAD27 +units=m +no_defs'],
    [102126, '+proj=utm +zone=59 +datum=NAD27 +units=m +no_defs'],
    [102127, '+proj=utm +zone=60 +datum=NAD27 +units=m +no_defs'],
    [102128, '+proj=utm +zone=1 +datum=NAD83 +units=m +no_defs'],
    [102129, '+proj=utm +zone=2 +datum=NAD83 +units=m +no_defs'],
    [102130, '+proj=utm +zone=59 +datum=NAD83 +units=m +no_defs'],
    [102131, '+proj=utm +zone=60 +datum=NAD83 +units=m +no_defs'],
    [102132, '+proj=utm +zone=32 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102133, '+proj=utm +zone=33 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102134, '+proj=utm +zone=34 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102135, '+proj=utm +zone=35 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102136, '+proj=tmerc +lat_0=58 +lon_0=10.7229166666667 +k=1 +x_0=19999.32 +y_0=-202977.79 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102137, '+proj=tmerc +lat_0=58 +lon_0=6.05625 +k=1 +x_0=100000 +y_0=-200000 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102138, '+proj=tmerc +lat_0=58 +lon_0=10.7229166666667 +k=1 +x_0=0 +y_0=-212979.18 +a=6377492.018 +rf=299.1528128 +units=m +no_defs'],
    [102139, '+proj=utm +zone=35 +ellps=GRS80 +units=m +no_defs'],
    [102140, '+proj=tmerc +lat_0=22.3121333333333 +lon_0=114.178555555556 +k=1 +x_0=836694.05 +y_0=819069.8 +ellps=intl +units=m +no_defs'],
    [102141, '+proj=utm +zone=49 +ellps=intl +units=m +no_defs'],
    [102142, '+proj=utm +zone=50 +ellps=intl +units=m +no_defs'],
    [102143, '+proj=utm +zone=39 +ellps=intl +units=m +no_defs'],
    [102144, '+proj=utm +zone=28 +ellps=clrk80ign +units=m +no_defs'],
    [102145, '+proj=utm +zone=51 +ellps=GRS80 +units=m +no_defs'],
    [102146, '+proj=utm +zone=52 +ellps=GRS80 +units=m +no_defs'],
    [102147, '+proj=utm +zone=53 +ellps=GRS80 +units=m +no_defs'],
    [102148, '+proj=utm +zone=54 +ellps=GRS80 +units=m +no_defs'],
    [102149, '+proj=utm +zone=55 +ellps=GRS80 +units=m +no_defs'],
    [102150, '+proj=utm +zone=56 +ellps=GRS80 +units=m +no_defs'],
    [102151, '+proj=utm +zone=51 +ellps=bessel +units=m +no_defs'],
    [102152, '+proj=utm +zone=52 +ellps=bessel +units=m +no_defs'],
    [102153, '+proj=utm +zone=53 +ellps=bessel +units=m +no_defs'],
    [102154, '+proj=utm +zone=54 +ellps=bessel +units=m +no_defs'],
    [102155, '+proj=utm +zone=55 +ellps=bessel +units=m +no_defs'],
    [102156, '+proj=utm +zone=56 +ellps=bessel +units=m +no_defs'],
    [102157, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.9999 +x_0=7500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102158, '+proj=tmerc +lat_0=0 +lon_0=37 +k=0.9998 +x_0=500000 +y_0=-3000000 +ellps=intl +units=m +no_defs'],
    [102159, '+proj=tmerc +lat_0=22.2123972222222 +lon_0=113.536469444444 +k=1 +x_0=20000 +y_0=20000 +ellps=intl +units=m +no_defs'],
    [102160, '+proj=tmerc +lat_0=39.6666666666667 +lon_0=-8.13190611111111 +k=1 +x_0=200180.598 +y_0=299913.01 +ellps=intl +units=m +no_defs'],
    [102161, '+proj=tmerc +lat_0=39.6666666666667 +lon_0=-8.13190611111111 +k=1 +x_0=180.598 +y_0=-86.99 +ellps=intl +units=m +no_defs'],
    [102162, '+proj=utm +zone=26 +ellps=intl +units=m +no_defs'],
    [102163, '+proj=bonne +lat_1=39.6666666666667 +lon_0=-8.13190611111111 +x_0=0 +y_0=0 +ellps=bessel +units=m +no_defs'],
    [102164, '+proj=tmerc +lat_0=39.6666666666667 +lon_0=-8.13190611111111 +k=1 +x_0=200000 +y_0=300000 +ellps=intl +units=m +no_defs'],
    [102165, '+proj=tmerc +lat_0=39.6666666666667 +lon_0=-8.13190611111111 +k=1 +x_0=0 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102166, '+proj=utm +zone=25 +ellps=intl +units=m +no_defs'],
    [102167, '+proj=utm +zone=28 +ellps=intl +units=m +no_defs'],
    [102168, '+proj=utm +zone=26 +ellps=intl +units=m +no_defs'],
    [102169, '+proj=utm +zone=28 +ellps=intl +units=m +no_defs'],
    [102170, '+proj=lcc +lat_0=-37 +lon_0=145 +lat_1=-36 +lat_2=-38 +x_0=2500000 +y_0=4500000 +ellps=aust_SA +units=m +no_defs'],
    [102171, '+proj=lcc +lat_0=-37 +lon_0=145 +lat_1=-36 +lat_2=-38 +x_0=2500000 +y_0=2500000 +ellps=GRS80 +units=m +no_defs'],
    [102172, '+proj=lcc +lat_0=-32 +lon_0=135 +lat_1=-28 +lat_2=-36 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [102173, '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=GRS80 +units=m +no_defs'],
    [102174, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102175, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102176, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102177, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102178, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=500000 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102179, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102180, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102181, '+proj=tmerc +lat_0=0 +lon_0=-114 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102182, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102183, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD27 +units=m +no_defs'],
    [102184, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102185, '+proj=tmerc +lat_0=0 +lon_0=-115 +k=0.9992 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102186, '+proj=tmerc +lat_0=0 +lon_0=-111 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102187, '+proj=tmerc +lat_0=0 +lon_0=-114 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102188, '+proj=tmerc +lat_0=0 +lon_0=-117 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102189, '+proj=tmerc +lat_0=0 +lon_0=-120 +k=0.9999 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102190, '+proj=aea +lat_0=45 +lon_0=-126 +lat_1=50 +lat_2=58.5 +x_0=1000000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102191, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=-5.4 +k_0=0.999625769 +x_0=500000 +y_0=300000 +ellps=clrk80ign +units=m +no_defs'],
    [102192, '+proj=lcc +lat_1=29.7 +lat_0=29.7 +lon_0=-5.4 +k_0=0.999615596 +x_0=500000 +y_0=300000 +ellps=clrk80ign +units=m +no_defs'],
    [102193, '+proj=lcc +lat_1=26.1 +lat_0=26.1 +lon_0=-5.4 +k_0=0.9996 +x_0=1200000 +y_0=400000 +ellps=clrk80ign +units=m +no_defs'],
    [102194, '+proj=tmerc +lat_0=0 +lon_0=19 +k=0.9993 +x_0=500000 +y_0=-5300000 +ellps=WGS84 +units=m +no_defs'],
    [102195, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.999923 +x_0=5500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102196, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.999923 +x_0=6500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102197, '+proj=tmerc +lat_0=0 +lon_0=21 +k=0.999923 +x_0=7500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102198, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.999923 +x_0=8500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102199, '+proj=lcc +lat_0=50.797815 +lon_0=4.35921583333333 +lat_1=49.8333333333333 +lat_2=51.1666666666667 +x_0=649328 +y_0=665262 +ellps=GRS80 +units=m +no_defs'],
    [102200, '+proj=utm +zone=2 +south +ellps=GRS80 +units=m +no_defs'],
    [102201, '+proj=tmerc +lat_0=13.5 +lon_0=144.75 +k=1 +x_0=100000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [102202, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [102203, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [102204, '+proj=lcc +lat_0=24 +lon_0=45 +lat_1=21 +lat_2=27 +x_0=1000000 +y_0=3000000 +ellps=intl +units=m +no_defs'],
    [102205, '+proj=utm +zone=11 +ellps=GRS80 +units=m +no_defs'],
    [102206, '+proj=utm +zone=12 +ellps=GRS80 +units=m +no_defs'],
    [102207, '+proj=utm +zone=13 +ellps=GRS80 +units=m +no_defs'],
    [102208, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102209, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102210, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102211, '+proj=utm +zone=18 +ellps=GRS80 +units=m +no_defs'],
    [102212, '+proj=lcc +lat_0=41 +lon_0=-107.5 +lat_1=41 +lat_2=45 +x_0=500000 +y_0=200000 +datum=NAD83 +units=m +no_defs'],
    [102213, '+proj=utm +zone=58 +datum=NAD83 +units=m +no_defs'],
    [102214, '+proj=lcc +lat_0=15.8333333333333 +lon_0=-24 +lat_1=15 +lat_2=16.6666666666667 +x_0=161587.83 +y_0=128511.202 +datum=WGS84 +units=m +no_defs'],
    [102215, '+proj=lcc +lat_0=0 +lon_0=-95 +lat_1=49 +lat_2=77 +x_0=0 +y_0=-8000000 +datum=WGS84 +units=m +no_defs'],
    [102216, '+proj=tmerc +lat_0=0 +lon_0=115.816666666667 +k=0.99999906 +x_0=50000 +y_0=3800000 +ellps=GRS80 +units=m +no_defs'],
    [102217, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=519999.999999999 +y_0=-4479999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [102218, '+proj=aea +lat_0=34 +lon_0=-120 +lat_1=43 +lat_2=48 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102219, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=519999.999999999 +y_0=-4479999.99999999 +datum=NAD83 +units=us-ft +no_defs'],
    [102220, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=519999.999999999 +y_0=-4479999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [102221, '+proj=lcc +lat_0=10.4666666666667 +lon_0=-84.3333333333333 +lat_1=9.93333333333333 +lat_2=11 +x_0=500000 +y_0=271820.522 +ellps=clrk66 +units=m +no_defs'],
    [102222, '+proj=lcc +lat_0=9 +lon_0=-83.6666666666667 +lat_1=8.46666666666667 +lat_2=9.53333333333333 +x_0=500000 +y_0=327987.436 +ellps=clrk66 +units=m +no_defs'],
    [102223, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9996 +x_0=500000 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102224, '+proj=utm +zone=46 +ellps=GRS80 +units=m +no_defs'],
    [102225, '+proj=utm +zone=47 +ellps=GRS80 +units=m +no_defs'],
    [102226, '+proj=utm +zone=48 +ellps=GRS80 +units=m +no_defs'],
    [102227, '+proj=utm +zone=49 +ellps=GRS80 +units=m +no_defs'],
    [102228, '+proj=utm +zone=50 +ellps=GRS80 +units=m +no_defs'],
    [102229, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102230, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102231, '+proj=tmerc +lat_0=4.59904722222222 +lon_0=-80.0809166666667 +k=1 +x_0=1000000 +y_0=1000000 +ellps=intl +units=m +no_defs'],
    [102232, '+proj=tmerc +lat_0=4.68333333333333 +lon_0=-74.15 +k=1.00039978753252 +x_0=92334.879 +y_0=109320.965 +ellps=intl +units=m +no_defs'],
    [102233, '+proj=tmerc +lat_0=4.68048611111111 +lon_0=-74.1465916666667 +k=1.00039980326544 +x_0=92334.879 +y_0=109320.965 +ellps=GRS80 +units=m +no_defs'],
    [102234, '+proj=utm +zone=14 +ellps=GRS80 +units=m +no_defs'],
    [102235, '+proj=utm +zone=15 +ellps=GRS80 +units=m +no_defs'],
    [102236, '+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs'],
    [102237, '+proj=aeqd +lat_0=6.96507569444444 +lon_0=158.209299222222 +x_0=80122.82 +y_0=80747.24 +ellps=clrk66 +units=m +no_defs'],
    [102238, '+proj=aeqd +lat_0=15.1675572222222 +lon_0=145.711286944444 +x_0=50000 +y_0=50000 +ellps=clrk66 +units=m +no_defs'],
    [102239, '+proj=aeqd +lat_0=13.4724663527778 +lon_0=144.748750705556 +x_0=50000 +y_0=50000 +ellps=clrk66 +units=m +no_defs'],
    [102240, '+proj=tmerc +lat_0=13.5 +lon_0=144.75 +k=1 +x_0=100000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [102241, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=40 +lat_2=41.6666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102242, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=38.3333333333333 +lat_2=39.8333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102243, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=37.0666666666667 +lat_2=38.4333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102244, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=36 +lat_2=37.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102245, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102246, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=32.7833333333333 +lat_2=33.8833333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102247, '+proj=aea +lat_0=50 +lon_0=-154 +lat_1=55 +lat_2=65 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102248, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102249, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102250, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333333333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102251, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=34.9333333333333 +lat_2=36.2333333333333 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102252, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=33.3 +lat_2=34.7666666666667 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [102253, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=39.7166666666667 +lat_2=40.7833333333333 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [102254, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=38.45 +lat_2=39.75 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [102255, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=37.2333333333333 +lat_2=38.4333333333333 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [102256, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.2 +lat_2=41.8666666666667 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +units=m +no_defs'],
    [102257, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102258, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102259, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102260, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=29.5833333333333 +lat_2=30.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102261, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102262, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102263, '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102264, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102265, '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102266, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102267, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102268, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368421053 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102269, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368421053 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102270, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333333333 +x_0=800000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102271, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102272, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941176470588 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102273, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [102274, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966666666667 +x_0=900000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [102275, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=42.0666666666667 +lat_2=43.2666666666667 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [102276, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=40.6166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102277, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=38.7166666666667 +lat_2=39.7833333333333 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102278, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=37.2666666666667 +lat_2=38.5666666666667 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [102279, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102280, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=36.7333333333333 +lat_2=37.9333333333333 +x_0=500000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102281, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=31.1666666666667 +lat_2=32.6666666666667 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102282, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=29.3 +lat_2=30.7 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102283, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102284, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966666666667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102285, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=38.3 +lat_2=39.45 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102286, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=41.7166666666667 +lat_2=42.6833333333333 +x_0=200000 +y_0=750000 +ellps=GRS80 +units=m +no_defs'],
    [102287, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.2833333333333 +lat_2=41.4833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102288, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=8000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102289, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=6000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102290, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=4000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102291, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [102292, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [102293, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [102294, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102295, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102296, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333333333 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102297, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102298, '+proj=tmerc +lat_0=36.1666666666667 +lon_0=-94.5 +k=0.999941176470588 +x_0=850000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102300, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102304, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=40 +lat_2=43 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102305, '+proj=tmerc +lat_0=0 +lon_0=-84 +k=0.9999 +x_0=500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102306, '+proj=tmerc +lat_0=0 +lon_0=84 +k=0.9999 +x_0=500000 +y_0=0 +ellps=evrst30 +units=m +no_defs'],
    [102307, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +ellps=GRS80 +units=m +no_defs'],
    [102308, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +units=m +no_defs'],
    [102309, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [102310, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966666666667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102311, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102312, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909090909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102313, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102314, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916666666667 +x_0=830000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102315, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102316, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102317, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102318, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=40.6666666666667 +lat_2=41.0333333333333 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102319, '+proj=tmerc +lat_0=0 +lon_0=33 +k=0.99995 +x_0=200000 +y_0=-3500000 +ellps=WGS84 +units=m +no_defs'],
    [102320, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=47.4333333333333 +lat_2=48.7333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102321, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=46.1833333333333 +lat_2=47.4833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102322, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=40.4333333333333 +lat_2=41.7 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102323, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=38.7333333333333 +lat_2=40.0333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102324, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=35.5666666666667 +lat_2=36.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102325, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=33.9333333333333 +lat_2=35.2333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102326, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=44.3333333333333 +lat_2=46 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102327, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=42.3333333333333 +lat_2=44 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102328, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102329, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=32500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102330, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102331, '+proj=utm +zone=25 +ellps=GRS80 +units=m +no_defs'],
    [102332, '+proj=utm +zone=26 +ellps=GRS80 +units=m +no_defs'],
    [102333, '+proj=utm +zone=28 +ellps=GRS80 +units=m +no_defs'],
    [102334, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=44.4166666666667 +lat_2=45.6833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102335, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=42.8333333333333 +lat_2=44.4 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102336, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=35.25 +lat_2=36.4166666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102337, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=34.65 +lat_2=36.1833333333333 +x_0=200000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [102338, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=32.1333333333333 +lat_2=33.9666666666667 +x_0=600000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [102339, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=30.1166666666667 +lat_2=31.8833333333333 +x_0=700000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [102340, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=28.3833333333333 +lat_2=30.2833333333333 +x_0=600000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [102341, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=26.1666666666667 +lat_2=27.8333333333333 +x_0=300000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [102342, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [102343, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [102344, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [102345, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964285714286 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102346, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=38.0333333333333 +lat_2=39.2 +x_0=3500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [102347, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=36.7666666666667 +lat_2=37.9666666666667 +x_0=3500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [102348, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102349, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=45.8333333333333 +lat_2=47.3333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102350, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=39 +lat_2=40.25 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102351, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=37.4833333333333 +lat_2=38.8833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102352, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=45.5666666666667 +lat_2=46.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102353, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=44.25 +lat_2=45.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102354, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=42.7333333333333 +lat_2=44.0666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102355, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102356, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [102357, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102358, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [102359, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=3500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102360, '+proj=tmerc +lat_0=0 +lon_0=15 +k=0.9996 +x_0=33500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102361, '+proj=lcc +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +lat_1=18.0333333333333 +lat_2=18.4333333333333 +x_0=200000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [102362, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.9996 +x_0=32500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102363, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [102364, '+proj=utm +zone=59 +ellps=GRS80 +units=m +no_defs'],
    [102365, '+proj=utm +zone=60 +ellps=GRS80 +units=m +no_defs'],
    [102366, '+proj=omerc +no_uoff +lat_0=57 +lonc=-133.666666666667 +alpha=-36.8698976458333 +gamma=-36.8698976458333 +k=0.9999 +x_0=5000000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [102367, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102368, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102369, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102370, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102371, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102372, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102373, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102374, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102375, '+proj=lcc +lat_0=51 +lon_0=-176 +lat_1=51.8333333333333 +lat_2=53.8333333333333 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102376, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=44.3333333333333 +lat_2=46 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102377, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=42.3333333333333 +lat_2=44 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102378, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=44.3333333333333 +lat_2=46 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102379, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=42.3333333333333 +lat_2=44 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102380, '+proj=lcc +lat_0=41.75 +lon_0=-120.5 +lat_1=43 +lat_2=45.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102381, '+proj=lcc +lat_0=41.75 +lon_0=-120.5 +lat_1=43 +lat_2=45.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102382, '+proj=utm +zone=13 +ellps=GRS80 +units=m +no_defs'],
    [102383, '+proj=utm +zone=14 +ellps=GRS80 +units=m +no_defs'],
    [102384, '+proj=utm +zone=15 +ellps=GRS80 +units=m +no_defs'],
    [102385, '+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs'],
    [102386, '+proj=utm +zone=17 +ellps=GRS80 +units=m +no_defs'],
    [102387, '+proj=utm +zone=18 +ellps=GRS80 +units=m +no_defs'],
    [102388, '+proj=utm +zone=19 +ellps=GRS80 +units=m +no_defs'],
    [102389, '+proj=omerc +no_uoff +lat_0=46.9916361111111 +lonc=-96.8888638888889 +alpha=2.63389226 +gamma=0 +k=1.000038773618 +x_0=874578.083820168 +y_0=-5077268.7778918 +datum=NAD83 +units=us-ft +no_defs'],
    [102390, '+proj=omerc +no_uoff +lat_0=46.9916361111111 +lonc=-96.8888638888889 +alpha=2.63389226 +gamma=0 +k=1.000038773618 +x_0=874578.083820168 +y_0=-5077268.7778918 +ellps=GRS80 +units=us-ft +no_defs'],
    [102391, '+proj=omerc +no_uoff +lat_0=46.9916361111111 +lonc=-96.8888638888889 +alpha=2.63389226 +gamma=0 +k=1.000038773618 +x_0=874578.083820168 +y_0=-5077268.7778918 +ellps=GRS80 +units=us-ft +no_defs'],
    [102392, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102393, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102394, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102395, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102396, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102397, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102398, '+proj=lcc +lat_0=51 +lon_0=-176 +lat_1=51.8333333333333 +lat_2=53.8333333333333 +x_0=999999.999999998 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102399, '+proj=aea +lat_0=42.5 +lon_0=-106 +lat_1=43.5 +lat_2=48 +x_0=0 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102400, '+proj=tmerc +lat_0=51.1666666666667 +lon_0=-0.158333333333333 +k=0.9999999 +x_0=78250 +y_0=-2800 +ellps=WGS84 +units=m +no_defs'],
    [102401, '+proj=utm +zone=1 +ellps=GRS80 +units=m +no_defs'],
    [102402, '+proj=utm +zone=2 +ellps=GRS80 +units=m +no_defs'],
    [102403, '+proj=utm +zone=3 +ellps=GRS80 +units=m +no_defs'],
    [102404, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [102405, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [102406, '+proj=utm +zone=6 +ellps=GRS80 +units=m +no_defs'],
    [102407, '+proj=utm +zone=7 +ellps=GRS80 +units=m +no_defs'],
    [102408, '+proj=utm +zone=8 +ellps=GRS80 +units=m +no_defs'],
    [102409, '+proj=utm +zone=9 +ellps=GRS80 +units=m +no_defs'],
    [102410, '+proj=utm +zone=10 +ellps=GRS80 +units=m +no_defs'],
    [102411, '+proj=utm +zone=11 +ellps=GRS80 +units=m +no_defs'],
    [102412, '+proj=utm +zone=12 +ellps=GRS80 +units=m +no_defs'],
    [102413, '+proj=utm +zone=13 +ellps=GRS80 +units=m +no_defs'],
    [102414, '+proj=utm +zone=14 +ellps=GRS80 +units=m +no_defs'],
    [102415, '+proj=utm +zone=15 +ellps=GRS80 +units=m +no_defs'],
    [102416, '+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs'],
    [102417, '+proj=utm +zone=17 +ellps=GRS80 +units=m +no_defs'],
    [102418, '+proj=utm +zone=18 +ellps=GRS80 +units=m +no_defs'],
    [102419, '+proj=utm +zone=19 +ellps=GRS80 +units=m +no_defs'],
    [102420, '+proj=lcc +lat_0=65 +lon_0=-19 +lat_1=64.25 +lat_2=65.75 +x_0=1700000 +y_0=300000 +ellps=GRS80 +units=m +no_defs'],
    [102421, '+proj=eqc +lat_ts=22.94791772 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102422, '+proj=eqc +lat_ts=41.12682127 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102423, '+proj=eqc +lat_ts=52.28859923 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102424, '+proj=eqc +lat_ts=60.32378942 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102425, '+proj=eqc +lat_ts=66.09421768 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102426, '+proj=eqc +lat_ts=70.10896259 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102427, '+proj=eqc +lat_ts=74.13230145 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102428, '+proj=eqc +lat_ts=78.1728375 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102429, '+proj=aeqd +lat_0=90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102430, '+proj=eqc +lat_ts=-22.94791772 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102431, '+proj=eqc +lat_ts=-41.12682127 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102432, '+proj=eqc +lat_ts=-52.28859923 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102433, '+proj=eqc +lat_ts=-60.32378942 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102434, '+proj=eqc +lat_ts=-66.09421768 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102435, '+proj=eqc +lat_ts=-70.10896259 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102436, '+proj=eqc +lat_ts=-74.13230145 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102437, '+proj=eqc +lat_ts=-78.1728375 +lat_0=0 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102438, '+proj=aeqd +lat_0=-90 +lon_0=0 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102439, '+proj=lcc +lat_0=-27 +lon_0=132 +lat_1=-18 +lat_2=-36 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs'],
    [102440, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102441, '+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [102442, '+proj=tmerc +lat_0=0 +lon_0=119 +k=0.9999 +x_0=250000 +y_0=0 +ellps=aust_SA +units=m +no_defs'],
    [102443, '+proj=tmerc +lat_0=0 +lon_0=121 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102444, '+proj=tmerc +lat_0=0 +lon_0=119 +k=0.9999 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102445, '+proj=omerc +no_uoff +lat_0=57 +lonc=-133.666666666667 +alpha=-36.8698976458333 +gamma=-36.8698976458333 +k=0.9999 +x_0=5000000.00000001 +y_0=-5000000.00000001 +ellps=GRS80 +units=us-ft +no_defs'],
    [102446, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102447, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102448, '+proj=tmerc +lat_0=22.2123972222222 +lon_0=113.536469444444 +k=1 +x_0=20000 +y_0=20000 +ellps=intl +units=m +no_defs'],
    [102449, '+proj=utm +zone=55 +ellps=GRS80 +units=m +no_defs'],
    [102450, '+proj=tmerc +lat_0=58 +lon_0=0 +k=1 +x_0=19999.32 +y_0=-202977.79 +a=6377492.018 +rf=299.1528128 +pm=oslo +units=m +no_defs'],
    [102451, '+proj=tmerc +lat_0=58 +lon_0=-4.66666666666667 +k=1 +x_0=100000 +y_0=-200000 +a=6377492.018 +rf=299.1528128 +pm=oslo +units=m +no_defs'],
    [102452, '+proj=tmerc +lat_0=58 +lon_0=0 +k=1 +x_0=0 +y_0=-212979.18 +a=6377492.018 +rf=299.1528128 +pm=oslo +units=m +no_defs'],
    [102453, '+proj=utm +zone=50 +ellps=clrk66 +units=m +no_defs'],
    [102454, '+proj=utm +zone=51 +ellps=clrk66 +units=m +no_defs'],
    [102455, '+proj=utm +zone=52 +ellps=clrk66 +units=m +no_defs'],
    [102456, '+proj=utm +zone=50 +ellps=clrk66 +units=m +no_defs'],
    [102457, '+proj=utm +zone=51 +ellps=clrk66 +units=m +no_defs'],
    [102458, '+proj=utm +zone=52 +ellps=clrk66 +units=m +no_defs'],
    [102459, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=1.00011328 +x_0=800143.9664 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102461, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102462, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102463, '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102464, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102465, '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102466, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [102467, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [102468, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [102469, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +ellps=GRS80 +units=m +no_defs'],
    [102470, '+proj=tmerc +lat_0=0 +lon_0=15 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102471, '+proj=tmerc +lat_0=0 +lon_0=17 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102472, '+proj=tmerc +lat_0=0 +lon_0=19 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102473, '+proj=tmerc +lat_0=0 +lon_0=21 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102474, '+proj=tmerc +lat_0=0 +lon_0=23 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102475, '+proj=tmerc +lat_0=0 +lon_0=25 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102476, '+proj=tmerc +lat_0=0 +lon_0=27 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102477, '+proj=tmerc +lat_0=0 +lon_0=29 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102478, '+proj=tmerc +lat_0=0 +lon_0=31 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102479, '+proj=tmerc +lat_0=0 +lon_0=33 +k=-1 +x_0=0 +y_0=0 +a=6378249.145 +rf=293.4663077 +units=m +no_defs'],
    [102480, '+proj=tmerc +lat_0=0 +lon_0=15 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102481, '+proj=tmerc +lat_0=0 +lon_0=17 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102482, '+proj=tmerc +lat_0=0 +lon_0=19 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102483, '+proj=tmerc +lat_0=0 +lon_0=21 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102484, '+proj=tmerc +lat_0=0 +lon_0=23 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102485, '+proj=tmerc +lat_0=0 +lon_0=25 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102486, '+proj=tmerc +lat_0=0 +lon_0=27 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102487, '+proj=tmerc +lat_0=0 +lon_0=29 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102488, '+proj=tmerc +lat_0=0 +lon_0=31 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102489, '+proj=tmerc +lat_0=0 +lon_0=33 +k=-1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102490, '+proj=omerc +no_uoff +lat_0=4 +lonc=115 +alpha=53.31580995 +gamma=53.130102354156 +k=0.99984 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102491, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=2.7 +k_0=0.999625544 +x_0=500000 +y_0=300000 +ellps=clrk80ign +units=m +no_defs'],
    [102492, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=2.7 +k_0=0.999625769 +x_0=500000 +y_0=300000 +ellps=clrk80ign +units=m +no_defs'],
    [102493, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [102494, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [102495, '+proj=tmerc +lat_0=13.5 +lon_0=144.75 +k=1 +x_0=100000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [102496, '+proj=utm +zone=2 +south +ellps=GRS80 +units=m +no_defs'],
    [102497, '+proj=geos +lon_0=-75 +h=35786023 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102498, '+proj=geos +lon_0=-75 +h=35786023 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102499, '+proj=lcc +lat_0=90 +lon_0=4.36748666666667 +lat_1=49.8333339 +lat_2=51.1666672333333 +x_0=150000.01256 +y_0=5400088.4378 +ellps=intl +units=m +no_defs'],
    [102500, '+proj=tmerc +lat_0=44.5 +lon_0=-117.833333333333 +k=1.00016 +x_0=40000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102501, '+proj=lcc +lat_1=43.6666666666667 +lat_0=43.6666666666667 +lon_0=-119.75 +k_0=1.0002 +x_0=120000 +y_0=60000 +ellps=GRS80 +units=ft +no_defs'],
    [102502, '+proj=tmerc +lat_0=41.75 +lon_0=-121.75 +k=1.0002 +x_0=80000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102503, '+proj=lcc +lat_1=44.6666666666667 +lat_0=44.6666666666667 +lon_0=-121.25 +k_0=1.00012 +x_0=80000 +y_0=130000 +ellps=GRS80 +units=ft +no_defs'],
    [102504, '+proj=tmerc +lat_0=42.5 +lon_0=-123.333333333333 +k=1.00007 +x_0=40000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102505, '+proj=lcc +lat_1=45.6666666666667 +lat_0=45.6666666666667 +lon_0=-120.5 +k_0=1.000008 +x_0=150000 +y_0=30000 +ellps=GRS80 +units=ft +no_defs'],
    [102506, '+proj=omerc +no_uoff +lat_0=45.9166666666667 +lonc=-123 +alpha=-65 +gamma=-65 +k=1 +x_0=7000000.00000001 +y_0=-3000000 +ellps=GRS80 +units=ft +no_defs'],
    [102507, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-123.333333333333 +k=1.000023 +x_0=50000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102508, '+proj=tmerc +lat_0=44.5 +lon_0=-121 +k=1.00011 +x_0=80000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102509, '+proj=tmerc +lat_0=43.75 +lon_0=-123.166666666667 +k=1.000015 +x_0=50000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102510, '+proj=tmerc +lat_0=41.75 +lon_0=-123.333333333333 +k=1.000043 +x_0=50000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102511, '+proj=tmerc +lat_0=45 +lon_0=-122.333333333333 +k=1.00005 +x_0=10000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102512, '+proj=tmerc +lat_0=45 +lon_0=-118 +k=1.00013 +x_0=40000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102513, '+proj=tmerc +lat_0=43.25 +lon_0=-117 +k=1.0001 +x_0=80000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102514, '+proj=omerc +no_uoff +lat_0=44.75 +lonc=-124.05 +alpha=5 +gamma=5 +k=1 +x_0=-300000 +y_0=-4600000.00000001 +ellps=GRS80 +units=ft +no_defs'],
    [102515, '+proj=tmerc +lat_0=45.0833333333333 +lon_0=-118.333333333333 +k=1.000175 +x_0=30000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102516, '+proj=tmerc +lat_0=45.25 +lon_0=-119.166666666667 +k=1.000045 +x_0=60000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102517, '+proj=lcc +lat_1=45.5 +lat_0=45.5 +lon_0=-122.75 +k_0=1.000002 +x_0=100000 +y_0=50000 +ellps=GRS80 +units=ft +no_defs'],
    [102518, '+proj=tmerc +lat_0=44.3333333333333 +lon_0=-123.083333333333 +k=1.00001 +x_0=50000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102519, '+proj=tmerc +lat_0=44.0833333333333 +lon_0=-122.5 +k=1.000155 +x_0=0 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102520, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102521, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102522, '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102523, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102524, '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102525, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102526, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102527, '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102528, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102529, '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102530, '+proj=tmerc +lat_0=44.5 +lon_0=-117.833333333333 +k=1.00016 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102531, '+proj=lcc +lat_1=43.6666666666667 +lat_0=43.6666666666667 +lon_0=-119.75 +k_0=1.0002 +x_0=120000 +y_0=60000 +ellps=GRS80 +units=m +no_defs'],
    [102532, '+proj=tmerc +lat_0=41.75 +lon_0=-121.75 +k=1.0002 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102533, '+proj=lcc +lat_1=44.6666666666667 +lat_0=44.6666666666667 +lon_0=-121.25 +k_0=1.00012 +x_0=80000 +y_0=130000 +ellps=GRS80 +units=m +no_defs'],
    [102534, '+proj=tmerc +lat_0=42.5 +lon_0=-123.333333333333 +k=1.00007 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102535, '+proj=lcc +lat_1=45.6666666666667 +lat_0=45.6666666666667 +lon_0=-120.5 +k_0=1.000008 +x_0=150000 +y_0=30000 +ellps=GRS80 +units=m +no_defs'],
    [102536, '+proj=omerc +no_uoff +lat_0=45.9166666666667 +lonc=-123 +alpha=-65 +gamma=-65 +k=1 +x_0=7000000 +y_0=-3000000 +ellps=GRS80 +units=m +no_defs'],
    [102537, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-123.333333333333 +k=1.000023 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102538, '+proj=tmerc +lat_0=44.5 +lon_0=-121 +k=1.00011 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102539, '+proj=tmerc +lat_0=43.75 +lon_0=-123.166666666667 +k=1.000015 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102540, '+proj=tmerc +lat_0=41.75 +lon_0=-123.333333333333 +k=1.000043 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102541, '+proj=tmerc +lat_0=45 +lon_0=-122.333333333333 +k=1.00005 +x_0=10000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102542, '+proj=tmerc +lat_0=45 +lon_0=-118 +k=1.00013 +x_0=40000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102543, '+proj=tmerc +lat_0=43.25 +lon_0=-117 +k=1.0001 +x_0=80000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102544, '+proj=omerc +no_uoff +lat_0=44.75 +lonc=-124.05 +alpha=5 +gamma=5 +k=1 +x_0=-300000 +y_0=-4600000 +ellps=GRS80 +units=m +no_defs'],
    [102545, '+proj=tmerc +lat_0=45.0833333333333 +lon_0=-118.333333333333 +k=1.000175 +x_0=30000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102546, '+proj=tmerc +lat_0=45.25 +lon_0=-119.166666666667 +k=1.000045 +x_0=60000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102547, '+proj=lcc +lat_1=45.5 +lat_0=45.5 +lon_0=-122.75 +k_0=1.000002 +x_0=100000 +y_0=50000 +ellps=GRS80 +units=m +no_defs'],
    [102548, '+proj=tmerc +lat_0=44.3333333333333 +lon_0=-123.083333333333 +k=1.00001 +x_0=50000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102549, '+proj=tmerc +lat_0=44.0833333333333 +lon_0=-122.5 +k=1.000155 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102550, '+proj=tmerc +lat_0=0 +lon_0=27 +k=0.9996 +x_0=9500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102551, '+proj=tmerc +lat_0=0 +lon_0=30 +k=0.9996 +x_0=10500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102552, '+proj=tmerc +lat_0=0 +lon_0=33 +k=0.9996 +x_0=11500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102553, '+proj=tmerc +lat_0=0 +lon_0=36 +k=0.9996 +x_0=12500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102554, '+proj=tmerc +lat_0=0 +lon_0=39 +k=0.9996 +x_0=13500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102555, '+proj=tmerc +lat_0=0 +lon_0=42 +k=0.9996 +x_0=14500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102556, '+proj=tmerc +lat_0=0 +lon_0=45 +k=0.9996 +x_0=15500000 +y_0=0 +ellps=intl +units=m +no_defs'],
    [102557, '+proj=tmerc +lat_0=0 +lon_0=68.5166666666667 +k=1 +x_0=1300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [102558, '+proj=tmerc +lat_0=0 +lon_0=71.5166666666667 +k=1 +x_0=2300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [102559, '+proj=tmerc +lat_0=0 +lon_0=74.5166666666667 +k=1 +x_0=3300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [102560, '+proj=tmerc +lat_0=0 +lon_0=77.5166666666667 +k=1 +x_0=4300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [102561, '+proj=tmerc +lat_0=0 +lon_0=80.5166666666667 +k=1 +x_0=5300000 +y_0=14743.5 +ellps=GRS80 +units=m +no_defs'],
    [102562, '+proj=tmerc +lat_0=0 +lon_0=19 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102563, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102564, '+proj=tmerc +lat_0=0 +lon_0=23 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102565, '+proj=tmerc +lat_0=0 +lon_0=25 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102566, '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102567, '+proj=tmerc +lat_0=0 +lon_0=29 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102568, '+proj=tmerc +lat_0=0 +lon_0=31 +k=1 +x_0=0 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [102569, '+proj=tmerc +lat_0=0 +lon_0=132 +k=1 +x_0=500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [102581, '+proj=lcc +lat_1=49.5 +lat_0=49.5 +lon_0=2.33722916666667 +k_0=0.999877341 +x_0=600000 +y_0=1200000 +ellps=clrk80ign +units=m +no_defs'],
    [102582, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=2.33722916666667 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +ellps=clrk80ign +units=m +no_defs'],
    [102583, '+proj=lcc +lat_1=44.1 +lat_0=44.1 +lon_0=2.33722916666667 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +ellps=clrk80ign +units=m +no_defs'],
    [102584, '+proj=lcc +lat_1=42.165 +lat_0=42.165 +lon_0=2.33722916666667 +k_0=0.99994471 +x_0=234.358 +y_0=4185861.369 +ellps=clrk80ign +units=m +no_defs'],
    [102585, '+proj=lcc +lat_1=49.5 +lat_0=49.5 +lon_0=2.33722916666667 +k_0=0.999877341 +x_0=600000 +y_0=1200000 +ellps=clrk80ign +units=m +no_defs'],
    [102586, '+proj=lcc +lat_1=46.8 +lat_0=46.8 +lon_0=2.33722916666667 +k_0=0.99987742 +x_0=600000 +y_0=2200000 +ellps=clrk80ign +units=m +no_defs'],
    [102587, '+proj=lcc +lat_1=44.1 +lat_0=44.1 +lon_0=2.33722916666667 +k_0=0.999877499 +x_0=600000 +y_0=3200000 +ellps=clrk80ign +units=m +no_defs'],
    [102588, '+proj=lcc +lat_1=42.165 +lat_0=42.165 +lon_0=2.33722916666667 +k_0=0.99994471 +x_0=234.358 +y_0=4185861.369 +ellps=clrk80ign +units=m +no_defs'],
    [102589, '+proj=aea +lat_0=25 +lon_0=-86 +lat_1=31 +lat_2=41 +x_0=0 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102590, '+proj=labrd +lat_0=-18.9 +lon_0=46.4372291666667 +azi=18.9 +k=0.9995 +x_0=400000 +y_0=800000 +ellps=intl +units=m +no_defs'],
    [102591, '+proj=lcc +lat_1=36 +lat_0=36 +lon_0=2.7 +k_0=0.999625544 +x_0=500135 +y_0=300090 +a=6378249.145 +rf=293.465 +units=m +no_defs'],
    [102592, '+proj=lcc +lat_1=33.3 +lat_0=33.3 +lon_0=2.7 +k_0=0.999625769 +x_0=500135 +y_0=300090 +a=6378249.145 +rf=293.465 +units=m +no_defs'],
    [102593, '+proj=utm +zone=51 +ellps=GRS80 +units=m +no_defs'],
    [102594, '+proj=utm +zone=52 +ellps=GRS80 +units=m +no_defs'],
    [102595, '+proj=utm +zone=53 +ellps=GRS80 +units=m +no_defs'],
    [102596, '+proj=utm +zone=54 +ellps=GRS80 +units=m +no_defs'],
    [102597, '+proj=utm +zone=55 +ellps=GRS80 +units=m +no_defs'],
    [102598, '+proj=utm +zone=56 +ellps=GRS80 +units=m +no_defs'],
    [102599, '+proj=aea +lat_0=0 +lon_0=-120 +lat_1=34 +lat_2=40.5 +x_0=0 +y_0=-1219202.43840488 +datum=WGS84 +units=us-ft +no_defs'],
    [102600, '+proj=aea +lat_0=0 +lon_0=-120 +lat_1=34 +lat_2=40.5 +x_0=0 +y_0=-1219202.43840488 +datum=NAD83 +units=us-ft +no_defs'],
    [102601, '+proj=aea +lat_0=18 +lon_0=-100 +lat_1=27.5 +lat_2=35 +x_0=1500000 +y_0=6000000 +datum=NAD83 +units=m +no_defs'],
    [102602, '+proj=lcc +lat_0=18 +lon_0=-100 +lat_1=27.5 +lat_2=35 +x_0=1500000 +y_0=5000000 +datum=NAD83 +units=m +no_defs'],
    [102603, '+proj=lcc +lat_0=31.1666666666667 +lon_0=-100 +lat_1=27.4166666666667 +lat_2=34.9166666666667 +x_0=1000000 +y_0=1000000 +datum=NAD83 +units=m +no_defs'],
    [102604, '+proj=lcc +lat_0=0 +lon_0=-83.5 +lat_1=31.4166666666667 +lat_2=34.2833333333333 +x_0=0 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102605, '+proj=tmerc +lat_0=42 +lon_0=-114 +k=0.9996 +x_0=2500000 +y_0=1200000 +datum=NAD83 +units=m +no_defs'],
    [102606, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102607, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102608, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +datum=NAD83 +units=m +no_defs'],
    [102609, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +datum=NAD83 +units=m +no_defs'],
    [102610, '+proj=tmerc +lat_0=33 +lon_0=129.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102611, '+proj=tmerc +lat_0=33 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102612, '+proj=tmerc +lat_0=36 +lon_0=132.166666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102613, '+proj=tmerc +lat_0=33 +lon_0=133.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102614, '+proj=tmerc +lat_0=36 +lon_0=134.333333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102615, '+proj=tmerc +lat_0=36 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102616, '+proj=tmerc +lat_0=36 +lon_0=137.166666666667 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102617, '+proj=tmerc +lat_0=36 +lon_0=138.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102618, '+proj=tmerc +lat_0=36 +lon_0=139.833333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102619, '+proj=tmerc +lat_0=40 +lon_0=140.833333333333 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102620, '+proj=tmerc +lat_0=44 +lon_0=140.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102621, '+proj=tmerc +lat_0=44 +lon_0=142.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102622, '+proj=tmerc +lat_0=44 +lon_0=144.25 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102623, '+proj=tmerc +lat_0=26 +lon_0=142 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102624, '+proj=tmerc +lat_0=26 +lon_0=127.5 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102625, '+proj=tmerc +lat_0=26 +lon_0=124 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102626, '+proj=tmerc +lat_0=26 +lon_0=131 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102627, '+proj=tmerc +lat_0=20 +lon_0=136 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102628, '+proj=tmerc +lat_0=26 +lon_0=154 +k=0.9999 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102629, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102630, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102631, '+proj=omerc +no_uoff +lat_0=57 +lonc=-133.666666666667 +alpha=-36.8698976458333 +gamma=-36.8698976458333 +k=0.9999 +x_0=5000000.00000001 +y_0=-5000000.00000001 +datum=NAD83 +units=us-ft +no_defs'],
    [102632, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102633, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102634, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102635, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102636, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102637, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102638, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102639, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102640, '+proj=lcc +lat_0=51 +lon_0=-176 +lat_1=51.8333333333333 +lat_2=53.8333333333333 +x_0=999999.999999998 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102641, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=40 +lat_2=41.6666666666667 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102642, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=38.3333333333333 +lat_2=39.8333333333333 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102643, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=37.0666666666667 +lat_2=38.4333333333333 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102644, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=36 +lat_2=37.25 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102645, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102646, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=32.7833333333333 +lat_2=33.8833333333333 +x_0=2000000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102647, '+proj=lcc +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +lat_1=18.0333333333333 +lat_2=18.4333333333333 +x_0=200000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [102648, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102649, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102650, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333333333 +x_0=213360 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102651, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=34.9333333333333 +lat_2=36.2333333333333 +x_0=399999.999999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102652, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=33.3 +lat_2=34.7666666666667 +x_0=399999.999999999 +y_0=399999.999999999 +datum=NAD83 +units=us-ft +no_defs'],
    [102653, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=39.7166666666667 +lat_2=40.7833333333333 +x_0=914401.828899998 +y_0=304800.6096 +datum=NAD83 +units=us-ft +no_defs'],
    [102654, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=38.45 +lat_2=39.75 +x_0=914401.828899998 +y_0=304800.6096 +datum=NAD83 +units=us-ft +no_defs'],
    [102655, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=37.2333333333333 +lat_2=38.4333333333333 +x_0=914401.828899998 +y_0=304800.6096 +datum=NAD83 +units=us-ft +no_defs'],
    [102656, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.2 +lat_2=41.8666666666667 +x_0=304800.6096 +y_0=152400.3048 +datum=NAD83 +units=us-ft +no_defs'],
    [102657, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102658, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941176470588 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102659, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941176470588 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102660, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=29.5833333333333 +lat_2=30.75 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102661, '+proj=tmerc +lat_0=18.8333333333333 +lon_0=-155.5 +k=0.999966666666667 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102662, '+proj=tmerc +lat_0=20.3333333333333 +lon_0=-156.666666666667 +k=0.999966666666667 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102663, '+proj=tmerc +lat_0=21.1666666666667 +lon_0=-158 +k=0.99999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102664, '+proj=tmerc +lat_0=21.8333333333333 +lon_0=-159.5 +k=0.99999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102665, '+proj=tmerc +lat_0=21.6666666666667 +lon_0=-160.166666666667 +k=1 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102666, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102667, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=699999.999999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102668, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368421053 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102669, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368421053 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102670, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333333333 +x_0=800000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102671, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102672, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941176470588 +x_0=699999.999999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102673, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +datum=NAD83 +units=us-ft +no_defs'],
    [102674, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966666666667 +x_0=900000 +y_0=250000 +datum=NAD83 +units=us-ft +no_defs'],
    [102675, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=42.0666666666667 +lat_2=43.2666666666667 +x_0=1500000 +y_0=999999.999999998 +datum=NAD83 +units=us-ft +no_defs'],
    [102676, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=40.6166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102677, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=38.7166666666667 +lat_2=39.7833333333333 +x_0=399999.999999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102678, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=37.2666666666667 +lat_2=38.5666666666667 +x_0=399999.999999999 +y_0=399999.999999999 +datum=NAD83 +units=us-ft +no_defs'],
    [102679, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102680, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=36.7333333333333 +lat_2=37.9333333333333 +x_0=500000 +y_0=500000 +datum=NAD83 +units=us-ft +no_defs'],
    [102681, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=31.1666666666667 +lat_2=32.6666666666667 +x_0=999999.999999998 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102682, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=29.3 +lat_2=30.7 +x_0=999999.999999998 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102683, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102684, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966666666667 +x_0=900000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102685, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=38.3 +lat_2=39.45 +x_0=399999.999999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102686, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=41.7166666666667 +lat_2=42.6833333333333 +x_0=200000 +y_0=750000 +datum=NAD83 +units=us-ft +no_defs'],
    [102687, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.2833333333333 +lat_2=41.4833333333333 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102688, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=8000000.00000001 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102689, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=6000000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102690, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=3999999.99999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102691, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +datum=NAD83 +units=us-ft +no_defs'],
    [102692, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +datum=NAD83 +units=us-ft +no_defs'],
    [102693, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +datum=NAD83 +units=us-ft +no_defs'],
    [102694, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102695, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=699999.999999999 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102696, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333333333 +x_0=250000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102697, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333333333 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102698, '+proj=tmerc +lat_0=36.1666666666667 +lon_0=-94.5 +k=0.999941176470588 +x_0=849999.999999998 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102699, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=124358.648717297 +y_0=-81076.9621539243 +datum=NAD27 +units=us-ft +no_defs'],
    [102700, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102701, '+proj=utm +zone=4 +ellps=GRS80 +units=m +no_defs'],
    [102702, '+proj=utm +zone=5 +ellps=GRS80 +units=m +no_defs'],
    [102703, '+proj=utm +zone=2 +south +ellps=GRS80 +units=m +no_defs'],
    [102704, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=40 +lat_2=43 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102705, '+proj=tmerc +lat_0=40.25 +lon_0=-96.6880555555556 +k=1.000054615 +x_0=50000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102706, '+proj=tmerc +lat_0=31.7340969444444 +lon_0=35.2120805555556 +k=1 +x_0=170251.555 +y_0=126867.909 +a=6378300.789 +b=6356566.435 +units=m +no_defs'],
    [102707, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000.00000001 +datum=NAD83 +units=us-ft +no_defs'],
    [102708, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +datum=NAD83 +units=us-ft +no_defs'],
    [102709, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=3999999.99999999 +datum=NAD83 +units=us-ft +no_defs'],
    [102710, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966666666667 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102711, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102712, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909090909091 +x_0=165000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102713, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102714, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916666666667 +x_0=830000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102715, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102716, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102717, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102718, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=40.6666666666667 +lat_2=41.0333333333333 +x_0=300000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102719, '+proj=lcc +lat_0=33.75 +lon_0=-79 +lat_1=34.3333333333333 +lat_2=36.1666666666667 +x_0=609601.220000001 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102720, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=47.4333333333333 +lat_2=48.7333333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102721, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=46.1833333333333 +lat_2=47.4833333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102722, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=40.4333333333333 +lat_2=41.7 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102723, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=38.7333333333333 +lat_2=40.0333333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102724, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=35.5666666666667 +lat_2=36.7666666666667 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102725, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=33.9333333333333 +lat_2=35.2333333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102726, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=44.3333333333333 +lat_2=46 +x_0=2500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102727, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=42.3333333333333 +lat_2=44 +x_0=1500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102728, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=40.8833333333333 +lat_2=41.95 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102729, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=39.9333333333333 +lat_2=40.9666666666667 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102730, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102733, '+proj=lcc +lat_0=31.8333333333333 +lon_0=-81 +lat_1=32.5 +lat_2=34.8333333333333 +x_0=609600 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102734, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=44.4166666666667 +lat_2=45.6833333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102735, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=42.8333333333333 +lat_2=44.4 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102736, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=35.25 +lat_2=36.4166666666667 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102737, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=34.65 +lat_2=36.1833333333333 +x_0=200000 +y_0=999999.999999998 +datum=NAD83 +units=us-ft +no_defs'],
    [102738, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=32.1333333333333 +lat_2=33.9666666666667 +x_0=600000 +y_0=2000000 +datum=NAD83 +units=us-ft +no_defs'],
    [102739, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=30.1166666666667 +lat_2=31.8833333333333 +x_0=699999.999999999 +y_0=3000000 +datum=NAD83 +units=us-ft +no_defs'],
    [102740, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=28.3833333333333 +lat_2=30.2833333333333 +x_0=600000 +y_0=3999999.99999999 +datum=NAD83 +units=us-ft +no_defs'],
    [102741, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=26.1666666666667 +lat_2=27.8333333333333 +x_0=300000 +y_0=5000000.00000001 +datum=NAD83 +units=us-ft +no_defs'],
    [102742, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=999999.999999998 +datum=NAD83 +units=us-ft +no_defs'],
    [102743, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +datum=NAD83 +units=us-ft +no_defs'],
    [102744, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +datum=NAD83 +units=us-ft +no_defs'],
    [102745, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964285714286 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102746, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=38.0333333333333 +lat_2=39.2 +x_0=3500000.00000001 +y_0=2000000 +datum=NAD83 +units=us-ft +no_defs'],
    [102747, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=36.7666666666667 +lat_2=37.9666666666667 +x_0=3500000.00000001 +y_0=999999.999999998 +datum=NAD83 +units=us-ft +no_defs'],
    [102748, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102749, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=45.8333333333333 +lat_2=47.3333333333333 +x_0=500000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102750, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=39 +lat_2=40.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102751, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=37.4833333333333 +lat_2=38.8833333333333 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102752, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=45.5666666666667 +lat_2=46.7666666666667 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102753, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=44.25 +lat_2=45.5 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102754, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=42.7333333333333 +lat_2=44.0666666666667 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102755, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102756, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=399999.999999999 +y_0=100000 +datum=NAD83 +units=us-ft +no_defs'],
    [102757, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +datum=NAD83 +units=us-ft +no_defs'],
    [102758, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +datum=NAD83 +units=us-ft +no_defs'],
    [102761, '+proj=lcc +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +lat_1=18.0333333333333 +lat_2=18.4333333333333 +x_0=200000 +y_0=200000 +datum=NAD83 +units=us-ft +no_defs'],
    [102762, '+proj=lcc +lat_0=-9 +lon_0=26 +lat_1=-6.5 +lat_2=-11.5 +x_0=500000 +y_0=500000 +ellps=clrk66 +units=m +no_defs'],
    [102763, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=999999.999999998 +datum=NAD83 +units=us-ft +no_defs'],
    [102764, '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [102765, '+proj=tmerc +lat_0=0 +lon_0=24 +k=1 +x_0=8500000 +y_0=0 +ellps=krass +units=m +no_defs'],
    [102766, '+proj=poly +lat_0=13.4724663527778 +lon_0=144.748750705556 +x_0=50000 +y_0=50000 +datum=NAD83 +units=us-ft +no_defs'],
    [102799, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.99926 +x_0=261910.5587 +y_0=70975.76209 +ellps=airy +units=m +no_defs'],
    [102800, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999314 +x_0=252927.2844 +y_0=70979.59363 +ellps=airy +units=m +no_defs'],
    [102801, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999365 +x_0=243942.3084 +y_0=70983.21269 +ellps=airy +units=m +no_defs'],
    [102802, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.99939 +x_0=243948.4072 +y_0=70984.98734 +ellps=airy +units=m +no_defs'],
    [102803, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999415 +x_0=234956.1813 +y_0=70986.76115 +ellps=airy +units=m +no_defs'],
    [102804, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999465 +x_0=225969.1556 +y_0=70990.30995 +ellps=airy +units=m +no_defs'],
    [102805, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.99949 +x_0=225974.8051 +y_0=70992.08478 +ellps=airy +units=m +no_defs'],
    [102806, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999516 +x_0=215981.5338 +y_0=70993.93011 +ellps=airy +units=m +no_defs'],
    [102807, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999541 +x_0=215986.9336 +y_0=70995.70502 +ellps=airy +units=m +no_defs'],
    [102808, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999564 +x_0=205992.2754 +y_0=70997.33764 +ellps=airy +units=m +no_defs'],
    [102809, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999589 +x_0=205997.4254 +y_0=70999.11264 +ellps=airy +units=m +no_defs'],
    [102810, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999613 +x_0=196002.254 +y_0=71000.81651 +ellps=airy +units=m +no_defs'],
    [102811, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999638 +x_0=196007.1543 +y_0=71002.5916 +ellps=airy +units=m +no_defs'],
    [102812, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999662 +x_0=185011.1931 +y_0=71004.29572 +ellps=airy +units=m +no_defs'],
    [102813, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999687 +x_0=185015.8185 +y_0=71006.07089 +ellps=airy +units=m +no_defs'],
    [102814, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999713 +x_0=173019.2914 +y_0=71007.91729 +ellps=airy +units=m +no_defs'],
    [102815, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999738 +x_0=173023.6171 +y_0=71009.69256 +ellps=airy +units=m +no_defs'],
    [102816, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999767 +x_0=159026.3186 +y_0=71011.75231 +ellps=airy +units=m +no_defs'],
    [102817, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999792 +x_0=159030.2944 +y_0=71013.52767 +ellps=airy +units=m +no_defs'],
    [102818, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999817 +x_0=159034.2704 +y_0=71015.30312 +ellps=airy +units=m +no_defs'],
    [102819, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999817 +x_0=144031.0383 +y_0=71015.30362 +ellps=airy +units=m +no_defs'],
    [102820, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999842 +x_0=144034.6392 +y_0=71017.07907 +ellps=airy +units=m +no_defs'],
    [102821, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999867 +x_0=144038.2403 +y_0=71018.8546 +ellps=airy +units=m +no_defs'],
    [102822, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999865 +x_0=128033.7365 +y_0=71018.71321 +ellps=airy +units=m +no_defs'],
    [102823, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.99989 +x_0=128036.9375 +y_0=71020.48874 +ellps=airy +units=m +no_defs'],
    [102824, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999914 +x_0=111034.6979 +y_0=71022.19417 +ellps=airy +units=m +no_defs'],
    [102825, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999939 +x_0=111037.4739 +y_0=71023.96979 +ellps=airy +units=m +no_defs'],
    [102826, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999967 +x_0=88032.17537 +y_0=71025.95967 +ellps=airy +units=m +no_defs'],
    [102827, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999992 +x_0=88034.37626 +y_0=71027.73539 +ellps=airy +units=m +no_defs'],
    [102828, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000012 +x_0=54022.17583 +y_0=71029.15712 +ellps=airy +units=m +no_defs'],
    [102829, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000037 +x_0=54023.52644 +y_0=71030.93291 +ellps=airy +units=m +no_defs'],
    [102830, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999981 +x_0=-24009.11135 +y_0=71026.9544 +ellps=airy +units=m +no_defs'],
    [102831, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000006 +x_0=-24009.7116 +y_0=71028.73014 +ellps=airy +units=m +no_defs'],
    [102832, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999928 +x_0=-58018.94296 +y_0=71023.18879 +ellps=airy +units=m +no_defs'],
    [102833, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999953 +x_0=-58020.39349 +y_0=71024.96444 +ellps=airy +units=m +no_defs'],
    [102834, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999874 +x_0=-88023.98625 +y_0=71019.35254 +ellps=airy +units=m +no_defs'],
    [102835, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999899 +x_0=-88026.18693 +y_0=71021.12809 +ellps=airy +units=m +no_defs'],
    [102836, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999826 +x_0=-105023.5775 +y_0=71015.94289 +ellps=airy +units=m +no_defs'],
    [102837, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999851 +x_0=-105026.2032 +y_0=71017.71836 +ellps=airy +units=m +no_defs'],
    [102838, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999771 +x_0=-122020.6823 +y_0=71012.0364 +ellps=airy +units=m +no_defs'],
    [102839, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999796 +x_0=-122023.7329 +y_0=71013.81177 +ellps=airy +units=m +no_defs'],
    [102840, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999708 +x_0=-139014.8049 +y_0=71007.56222 +ellps=airy +units=m +no_defs'],
    [102841, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999733 +x_0=-139018.2804 +y_0=71009.33748 +ellps=airy +units=m +no_defs'],
    [102842, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999656 +x_0=-156008.5024 +y_0=71003.86967 +ellps=airy +units=m +no_defs'],
    [102843, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999681 +x_0=-156012.4027 +y_0=71005.64484 +ellps=airy +units=m +no_defs'],
    [102844, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999613 +x_0=-165001.8975 +y_0=71000.81651 +ellps=airy +units=m +no_defs'],
    [102845, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999638 +x_0=-165006.0227 +y_0=71002.5916 +ellps=airy +units=m +no_defs'],
    [102846, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999565 +x_0=-175993.5763 +y_0=70997.40864 +ellps=airy +units=m +no_defs'],
    [102847, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.99959 +x_0=-175997.9763 +y_0=70999.18364 +ellps=airy +units=m +no_defs'],
    [102848, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999517 +x_0=-185984.2846 +y_0=70994.00109 +ellps=airy +units=m +no_defs'],
    [102849, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999542 +x_0=-185988.9343 +y_0=70995.77601 +ellps=airy +units=m +no_defs'],
    [102850, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999467 +x_0=-195973.6419 +y_0=70990.45191 +ellps=airy +units=m +no_defs'],
    [102851, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999492 +x_0=-195978.5414 +y_0=70992.22674 +ellps=airy +units=m +no_defs'],
    [102852, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999416 +x_0=-205961.7946 +y_0=70986.83212 +ellps=airy +units=m +no_defs'],
    [102853, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999441 +x_0=-205966.9438 +y_0=70988.60686 +ellps=airy +units=m +no_defs'],
    [102854, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999366 +x_0=-214949.3801 +y_0=70983.28366 +ellps=airy +units=m +no_defs'],
    [102855, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999314 +x_0=-223935.6193 +y_0=70979.59363 +ellps=airy +units=m +no_defs'],
    [102856, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999967 +x_0=88032.17537 +y_0=111040.5848 +ellps=airy +units=m +no_defs'],
    [102857, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999992 +x_0=88034.37626 +y_0=111043.361 +ellps=airy +units=m +no_defs'],
    [102858, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000017 +x_0=88036.57726 +y_0=111046.1372 +ellps=airy +units=m +no_defs'],
    [102859, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000012 +x_0=54022.17583 +y_0=111045.5837 +ellps=airy +units=m +no_defs'],
    [102860, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000037 +x_0=54023.52644 +y_0=111048.3599 +ellps=airy +units=m +no_defs'],
    [102861, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000062 +x_0=54024.87711 +y_0=111051.1363 +ellps=airy +units=m +no_defs'],
    [102862, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000087 +x_0=54026.22785 +y_0=111053.9128 +ellps=airy +units=m +no_defs'],
    [102863, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999981 +x_0=-24009.11135 +y_0=111042.14 +ellps=airy +units=m +no_defs'],
    [102864, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000006 +x_0=-24009.7116 +y_0=111044.9161 +ellps=airy +units=m +no_defs'],
    [102865, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999928 +x_0=-58018.94296 +y_0=111036.2529 +ellps=airy +units=m +no_defs'],
    [102866, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999953 +x_0=-58020.39349 +y_0=111039.0289 +ellps=airy +units=m +no_defs'],
    [102867, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999874 +x_0=-88023.98625 +y_0=111030.2554 +ellps=airy +units=m +no_defs'],
    [102868, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999826 +x_0=-105023.5775 +y_0=111024.9248 +ellps=airy +units=m +no_defs'],
    [102869, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999771 +x_0=-122020.6823 +y_0=111018.8175 +ellps=airy +units=m +no_defs'],
    [102870, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999709 +x_0=-139014.9439 +y_0=111011.9337 +ellps=airy +units=m +no_defs'],
    [102871, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999656 +x_0=-156008.5024 +y_0=111006.0498 +ellps=airy +units=m +no_defs'],
    [102872, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999614 +x_0=-165002.0625 +y_0=111001.3875 +ellps=airy +units=m +no_defs'],
    [102873, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999565 +x_0=-175993.5763 +y_0=110995.9487 +ellps=airy +units=m +no_defs'],
    [102874, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999517 +x_0=-185984.2846 +y_0=110990.6214 +ellps=airy +units=m +no_defs'],
    [102875, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999467 +x_0=-195973.6419 +y_0=110985.0727 +ellps=airy +units=m +no_defs'],
    [102876, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999416 +x_0=-205961.7946 +y_0=110979.4136 +ellps=airy +units=m +no_defs'],
    [102877, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999367 +x_0=-214949.595 +y_0=110973.9769 +ellps=airy +units=m +no_defs'],
    [102878, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999315 +x_0=-223935.8432 +y_0=110968.208 +ellps=airy +units=m +no_defs'],
    [102879, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999261 +x_0=-232920.6915 +y_0=110962.2179 +ellps=airy +units=m +no_defs'],
    [102880, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999206 +x_0=-241904.3281 +y_0=110956.1174 +ellps=airy +units=m +no_defs'],
    [102881, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999866 +x_0=128033.8646 +y_0=126033.3354 +ellps=airy +units=m +no_defs'],
    [102882, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999914 +x_0=111034.6979 +y_0=126039.3868 +ellps=airy +units=m +no_defs'],
    [102883, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999939 +x_0=111037.4739 +y_0=126042.5379 +ellps=airy +units=m +no_defs'],
    [102884, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999964 +x_0=111040.25 +y_0=126045.6892 +ellps=airy +units=m +no_defs'],
    [102885, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999989 +x_0=111043.0263 +y_0=126048.8406 +ellps=airy +units=m +no_defs'],
    [102886, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999967 +x_0=88032.17537 +y_0=126046.0693 +ellps=airy +units=m +no_defs'],
    [102887, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999992 +x_0=88034.37626 +y_0=126049.2206 +ellps=airy +units=m +no_defs'],
    [102888, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000017 +x_0=88036.57726 +y_0=126052.372 +ellps=airy +units=m +no_defs'],
    [102889, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000042 +x_0=88038.77836 +y_0=126055.5236 +ellps=airy +units=m +no_defs'],
    [102890, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000067 +x_0=88040.97958 +y_0=126058.6753 +ellps=airy +units=m +no_defs'],
    [102891, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000012 +x_0=54022.17583 +y_0=126051.7436 +ellps=airy +units=m +no_defs'],
    [102892, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000037 +x_0=54023.52644 +y_0=126054.895 +ellps=airy +units=m +no_defs'],
    [102893, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000062 +x_0=54024.87711 +y_0=126058.0466 +ellps=airy +units=m +no_defs'],
    [102894, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000087 +x_0=54026.22785 +y_0=126061.1983 +ellps=airy +units=m +no_defs'],
    [102895, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999981 +x_0=-24009.11135 +y_0=126047.8346 +ellps=airy +units=m +no_defs'],
    [102896, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=1.000006 +x_0=-24009.7116 +y_0=126050.9859 +ellps=airy +units=m +no_defs'],
    [102897, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999928 +x_0=-58018.94296 +y_0=126041.1519 +ellps=airy +units=m +no_defs'],
    [102898, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999953 +x_0=-58020.39349 +y_0=126044.3031 +ellps=airy +units=m +no_defs'],
    [102899, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999874 +x_0=-88023.98625 +y_0=126034.3439 +ellps=airy +units=m +no_defs'],
    [102900, '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.999899 +x_0=-88026.18693 +y_0=126037.4949 +ellps=airy +units=m +no_defs'],
    [102962, '+proj=aea +lat_0=0 +lon_0=-120 +lat_1=34 +lat_2=40.5 +x_0=0 +y_0=-4000000 +ellps=GRS80 +units=m +no_defs'],
    [102963, '+proj=tmerc +lat_0=32.5 +lon_0=-89.75 +k=0.9998335 +x_0=500000 +y_0=1300000 +ellps=GRS80 +units=m +no_defs'],
    [102965, '+proj=aea +lat_0=23 +lon_0=-96 +lat_1=29.5 +lat_2=45.5 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102966, '+proj=aea +lat_0=50 +lon_0=-154 +lat_1=55 +lat_2=65 +x_0=0 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102967, '+proj=aea +lat_0=24 +lon_0=-84 +lat_1=24 +lat_2=31.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102968, '+proj=omerc +no_uoff +lat_0=45.3091666666667 +lonc=-86 +alpha=337.25556 +gamma=337.25556 +k=0.9996 +x_0=2546731.496 +y_0=-4354009.816 +ellps=GRS80 +units=m +no_defs'],
    [102969, '+proj=lcc +lat_0=41.75 +lon_0=-120.5 +lat_1=43 +lat_2=45.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102970, '+proj=lcc +lat_0=41.75 +lon_0=-120.5 +lat_1=43 +lat_2=45.5 +x_0=400000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102971, '+proj=aea +lat_0=18 +lon_0=-100 +lat_1=27.5 +lat_2=35 +x_0=1500000 +y_0=6000000 +ellps=GRS80 +units=m +no_defs'],
    [102972, '+proj=lcc +lat_0=18 +lon_0=-100 +lat_1=27.5 +lat_2=35 +x_0=1500000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [102973, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=520000 +y_0=-4480000 +ellps=GRS80 +units=m +no_defs'],
    [102974, '+proj=tmerc +lat_0=0 +lon_0=-90 +k=0.9996 +x_0=519999.999999999 +y_0=-4479999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [102975, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102976, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102977, '+proj=omerc +no_uoff +lat_0=57 +lonc=-133.666666666667 +alpha=-36.8698976458333 +gamma=-36.8698976458333 +k=0.9999 +x_0=5000000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [102978, '+proj=tmerc +lat_0=54 +lon_0=-142 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102979, '+proj=tmerc +lat_0=54 +lon_0=-146 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102980, '+proj=tmerc +lat_0=54 +lon_0=-150 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102981, '+proj=tmerc +lat_0=54 +lon_0=-154 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102982, '+proj=tmerc +lat_0=54 +lon_0=-158 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102983, '+proj=tmerc +lat_0=54 +lon_0=-162 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102984, '+proj=tmerc +lat_0=54 +lon_0=-166 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102985, '+proj=tmerc +lat_0=54 +lon_0=-170 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102986, '+proj=lcc +lat_0=51 +lon_0=-176 +lat_1=51.8333333333333 +lat_2=53.8333333333333 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102987, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102988, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102989, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333333333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102990, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102991, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102992, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333333333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [102993, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=34.9333333333333 +lat_2=36.2333333333333 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [102994, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=33.3 +lat_2=34.7666666666667 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [102995, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=34.9333333333333 +lat_2=36.2333333333333 +x_0=399999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [102996, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=33.3 +lat_2=34.7666666666667 +x_0=399999.999999999 +y_0=399999.999999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [102997, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=40 +lat_2=41.6666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102998, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=38.3333333333333 +lat_2=39.8333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [102999, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=37.0666666666667 +lat_2=38.4333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103000, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=36 +lat_2=37.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103001, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103002, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=32.7833333333333 +lat_2=33.8833333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103003, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=40 +lat_2=41.6666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103004, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=38.3333333333333 +lat_2=39.8333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103005, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=37.0666666666667 +lat_2=38.4333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103006, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=36 +lat_2=37.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103007, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103008, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=32.7833333333333 +lat_2=33.8833333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103009, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=39.7166666666667 +lat_2=40.7833333333333 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [103010, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=38.45 +lat_2=39.75 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [103011, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=37.2333333333333 +lat_2=38.4333333333333 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [103012, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=39.7166666666667 +lat_2=40.7833333333333 +x_0=914401.828899998 +y_0=304800.6096 +ellps=GRS80 +units=us-ft +no_defs'],
    [103013, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=38.45 +lat_2=39.75 +x_0=914401.828899998 +y_0=304800.6096 +ellps=GRS80 +units=us-ft +no_defs'],
    [103014, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=37.2333333333333 +lat_2=38.4333333333333 +x_0=914401.828899998 +y_0=304800.6096 +ellps=GRS80 +units=us-ft +no_defs'],
    [103015, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.2 +lat_2=41.8666666666667 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +units=m +no_defs'],
    [103016, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.2 +lat_2=41.8666666666667 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +units=us-ft +no_defs'],
    [103017, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103018, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103019, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103020, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103021, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=29.5833333333333 +lat_2=30.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103022, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103023, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103024, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=29.5833333333333 +lat_2=30.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103025, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103026, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103027, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103028, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=699999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103029, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368421053 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103030, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368421053 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103031, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333333333 +x_0=800000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103032, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368421053 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103033, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368421053 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103034, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333333333 +x_0=800000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103035, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103036, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941176470588 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103037, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103038, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941176470588 +x_0=699999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103039, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [103040, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966666666667 +x_0=900000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [103041, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103042, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966666666667 +x_0=900000 +y_0=250000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103043, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=42.0666666666667 +lat_2=43.2666666666667 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103044, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=40.6166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103045, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=42.0666666666667 +lat_2=43.2666666666667 +x_0=1500000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103046, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=40.6166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103047, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=38.7166666666667 +lat_2=39.7833333333333 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103048, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=37.2666666666667 +lat_2=38.5666666666667 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [103049, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=38.7166666666667 +lat_2=39.7833333333333 +x_0=399999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103050, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=37.2666666666667 +lat_2=38.5666666666667 +x_0=399999.999999999 +y_0=399999.999999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103051, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103052, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103053, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103054, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103055, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=36.7333333333333 +lat_2=37.9333333333333 +x_0=500000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103056, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=36.7333333333333 +lat_2=37.9333333333333 +x_0=500000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103057, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=31.1666666666667 +lat_2=32.6666666666667 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103058, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=29.3 +lat_2=30.7 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103059, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=31.1666666666667 +lat_2=32.6666666666667 +x_0=999999.999999998 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103060, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=29.3 +lat_2=30.7 +x_0=999999.999999998 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103061, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103062, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966666666667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103063, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103064, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966666666667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103065, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103066, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103067, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103068, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=38.3 +lat_2=39.45 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103069, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=38.3 +lat_2=39.45 +x_0=399999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103070, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=41.7166666666667 +lat_2=42.6833333333333 +x_0=200000 +y_0=750000 +ellps=GRS80 +units=m +no_defs'],
    [103071, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.2833333333333 +lat_2=41.4833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103072, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=41.7166666666667 +lat_2=42.6833333333333 +x_0=200000 +y_0=750000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103073, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.2833333333333 +lat_2=41.4833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103074, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=8000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103075, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=6000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103076, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=4000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103077, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=8000000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103078, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=5999999.99999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103079, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=4000000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103080, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103081, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103082, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103083, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103084, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103085, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103086, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103087, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103088, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103089, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=699999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103090, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333333333 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103091, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103092, '+proj=tmerc +lat_0=36.1666666666667 +lon_0=-94.5 +k=0.999941176470588 +x_0=850000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103093, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103094, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=599999.999999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103095, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=40 +lat_2=43 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103096, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=40 +lat_2=43 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103097, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +ellps=GRS80 +units=m +no_defs'],
    [103098, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +units=m +no_defs'],
    [103099, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [103100, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000.00000001 +ellps=GRS80 +units=us-ft +no_defs'],
    [103101, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103102, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=3999999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103103, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966666666667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103104, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966666666667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103105, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103106, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103107, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909090909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103108, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103109, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916666666667 +x_0=830000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103110, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909090909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103111, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103112, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916666666667 +x_0=830000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103113, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103114, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103115, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103116, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=40.6666666666667 +lat_2=41.0333333333333 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103117, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103118, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103119, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103120, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=40.6666666666667 +lat_2=41.0333333333333 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103121, '+proj=lcc +lat_0=33.75 +lon_0=-79 +lat_1=34.3333333333333 +lat_2=36.1666666666667 +x_0=609601.219202438 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103122, '+proj=lcc +lat_0=33.75 +lon_0=-79 +lat_1=34.3333333333333 +lat_2=36.1666666666667 +x_0=609601.219202438 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103123, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=47.4333333333333 +lat_2=48.7333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103124, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=46.1833333333333 +lat_2=47.4833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103125, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=47.4333333333333 +lat_2=48.7333333333333 +x_0=599999.999999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103126, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=46.1833333333333 +lat_2=47.4833333333333 +x_0=599999.999999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103127, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=40.4333333333333 +lat_2=41.7 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103128, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=38.7333333333333 +lat_2=40.0333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103129, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=40.4333333333333 +lat_2=41.7 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103130, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=38.7333333333333 +lat_2=40.0333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103131, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=35.5666666666667 +lat_2=36.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103132, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=33.9333333333333 +lat_2=35.2333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103133, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=35.5666666666667 +lat_2=36.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103134, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=33.9333333333333 +lat_2=35.2333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103135, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=44.3333333333333 +lat_2=46 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103136, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=42.3333333333333 +lat_2=44 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103137, '+proj=lcc +lat_0=43.6666666666667 +lon_0=-120.5 +lat_1=44.3333333333333 +lat_2=46 +x_0=2500000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103138, '+proj=lcc +lat_0=41.6666666666667 +lon_0=-120.5 +lat_1=42.3333333333333 +lat_2=44 +x_0=1500000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103139, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=40.8833333333333 +lat_2=41.95 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103140, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=40.8833333333333 +lat_2=41.95 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103141, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=39.9333333333333 +lat_2=40.9666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103142, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=39.9333333333333 +lat_2=40.9666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103143, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103144, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103145, '+proj=lcc +lat_0=31.8333333333333 +lon_0=-81 +lat_1=32.5 +lat_2=34.8333333333333 +x_0=609600 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103146, '+proj=lcc +lat_0=31.8333333333333 +lon_0=-81 +lat_1=32.5 +lat_2=34.8333333333333 +x_0=609600 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103147, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=44.4166666666667 +lat_2=45.6833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103148, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=42.8333333333333 +lat_2=44.4 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103149, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=44.4166666666667 +lat_2=45.6833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103150, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=42.8333333333333 +lat_2=44.4 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103151, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=35.25 +lat_2=36.4166666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103152, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=35.25 +lat_2=36.4166666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103153, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=34.65 +lat_2=36.1833333333333 +x_0=200000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103154, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=32.1333333333333 +lat_2=33.9666666666667 +x_0=600000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [103155, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=30.1166666666667 +lat_2=31.8833333333333 +x_0=700000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [103156, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=28.3833333333333 +lat_2=30.2833333333333 +x_0=600000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [103157, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=26.1666666666667 +lat_2=27.8333333333333 +x_0=300000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [103158, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=34.65 +lat_2=36.1833333333333 +x_0=200000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103159, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=32.1333333333333 +lat_2=33.9666666666667 +x_0=600000 +y_0=2000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103160, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=30.1166666666667 +lat_2=31.8833333333333 +x_0=699999.999999999 +y_0=3000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103161, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=28.3833333333333 +lat_2=30.2833333333333 +x_0=600000 +y_0=3999999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103162, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=26.1666666666667 +lat_2=27.8333333333333 +x_0=300000 +y_0=5000000.00000001 +ellps=GRS80 +units=us-ft +no_defs'],
    [103163, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103164, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [103165, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [103166, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=1000000 +ellps=GRS80 +units=ft +no_defs'],
    [103167, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=ft +no_defs'],
    [103168, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=ft +no_defs'],
    [103169, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103170, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103171, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103172, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964285714286 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103173, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964285714286 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103174, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=38.0333333333333 +lat_2=39.2 +x_0=3500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [103175, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=36.7666666666667 +lat_2=37.9666666666667 +x_0=3500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103176, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=38.0333333333333 +lat_2=39.2 +x_0=3500000.00000001 +y_0=2000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103177, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=36.7666666666667 +lat_2=37.9666666666667 +x_0=3500000.00000001 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103178, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103179, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=45.8333333333333 +lat_2=47.3333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103180, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103181, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=45.8333333333333 +lat_2=47.3333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103182, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=39 +lat_2=40.25 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103183, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=37.4833333333333 +lat_2=38.8833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103184, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=39 +lat_2=40.25 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103185, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=37.4833333333333 +lat_2=38.8833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103186, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=45.5666666666667 +lat_2=46.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103187, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=44.25 +lat_2=45.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103188, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=42.7333333333333 +lat_2=44.0666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103189, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=45.5666666666667 +lat_2=46.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103190, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=44.25 +lat_2=45.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103191, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=42.7333333333333 +lat_2=44.0666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103192, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103193, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103194, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103195, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103196, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103197, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=399999.999999999 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103198, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103199, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103200, '+proj=lcc +lat_0=17.8333333333333 +lon_0=-66.4333333333333 +lat_1=18.0333333333333 +lat_2=18.4333333333333 +x_0=200000 +y_0=200000 +ellps=GRS80 +units=m +no_defs'],
    [103201, '+proj=tmerc +lat_0=0 +lon_0=12 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103202, '+proj=tmerc +lat_0=0 +lon_0=14 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103203, '+proj=tmerc +lat_0=0 +lon_0=16 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103204, '+proj=tmerc +lat_0=0 +lon_0=18 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103205, '+proj=tmerc +lat_0=0 +lon_0=20 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103206, '+proj=tmerc +lat_0=0 +lon_0=22 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103207, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103208, '+proj=tmerc +lat_0=0 +lon_0=26 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103209, '+proj=tmerc +lat_0=0 +lon_0=28 +k=0.9999 +x_0=500000 +y_0=10000000 +ellps=GRS80 +units=m +no_defs'],
    [103210, '+proj=utm +zone=33 +south +ellps=GRS80 +units=m +no_defs'],
    [103211, '+proj=utm +zone=34 +south +ellps=GRS80 +units=m +no_defs'],
    [103212, '+proj=utm +zone=35 +south +ellps=GRS80 +units=m +no_defs'],
    [103213, '+proj=utm +zone=23 +south +ellps=intl +units=m +no_defs'],
    [103214, '+proj=utm +zone=27 +ellps=GRS80 +units=m +no_defs'],
    [103215, '+proj=utm +zone=28 +ellps=GRS80 +units=m +no_defs'],
    [103216, '+proj=tmerc +lat_0=0 +lon_0=9 +k=0.99998 +x_0=200000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [103217, '+proj=tmerc +lat_0=0 +lon_0=10 +k=0.99998 +x_0=400000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [103218, '+proj=tmerc +lat_0=0 +lon_0=11.75 +k=0.99998 +x_0=600000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [103219, '+proj=tmerc +lat_0=0 +lon_0=15 +k=1 +x_0=800000 +y_0=-5000000 +ellps=GRS80 +units=m +no_defs'],
    [103220, '+proj=tmerc +lat_0=30.5 +lon_0=-85.8333333333333 +k=0.99996 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103221, '+proj=tmerc +lat_0=30 +lon_0=-87.5 +k=0.999933333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103222, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103223, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103224, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333333333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103225, '+proj=tmerc +lat_0=31 +lon_0=-110.166666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103226, '+proj=tmerc +lat_0=31 +lon_0=-111.916666666667 +k=0.9999 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103227, '+proj=tmerc +lat_0=31 +lon_0=-113.75 +k=0.999933333333333 +x_0=213360 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103228, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=34.9333333333333 +lat_2=36.2333333333333 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103229, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=33.3 +lat_2=34.7666666666667 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [103230, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-92 +lat_1=34.9333333333333 +lat_2=36.2333333333333 +x_0=399999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103231, '+proj=lcc +lat_0=32.6666666666667 +lon_0=-92 +lat_1=33.3 +lat_2=34.7666666666667 +x_0=399999.999999999 +y_0=399999.999999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103232, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=40 +lat_2=41.6666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103233, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=38.3333333333333 +lat_2=39.8333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103234, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=37.0666666666667 +lat_2=38.4333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103235, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=36 +lat_2=37.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103236, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103237, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=32.7833333333333 +lat_2=33.8833333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103238, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-122 +lat_1=40 +lat_2=41.6666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103239, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-122 +lat_1=38.3333333333333 +lat_2=39.8333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103240, '+proj=lcc +lat_0=36.5 +lon_0=-120.5 +lat_1=37.0666666666667 +lat_2=38.4333333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103241, '+proj=lcc +lat_0=35.3333333333333 +lon_0=-119 +lat_1=36 +lat_2=37.25 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103242, '+proj=lcc +lat_0=33.5 +lon_0=-118 +lat_1=34.0333333333333 +lat_2=35.4666666666667 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103243, '+proj=lcc +lat_0=32.1666666666667 +lon_0=-116.25 +lat_1=32.7833333333333 +lat_2=33.8833333333333 +x_0=2000000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103244, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=39.7166666666667 +lat_2=40.7833333333333 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [103245, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=38.45 +lat_2=39.75 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [103246, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=37.2333333333333 +lat_2=38.4333333333333 +x_0=914401.8289 +y_0=304800.6096 +ellps=GRS80 +units=m +no_defs'],
    [103247, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-105.5 +lat_1=39.7166666666667 +lat_2=40.7833333333333 +x_0=914401.828899998 +y_0=304800.6096 +ellps=GRS80 +units=us-ft +no_defs'],
    [103248, '+proj=lcc +lat_0=37.8333333333333 +lon_0=-105.5 +lat_1=38.45 +lat_2=39.75 +x_0=914401.828899998 +y_0=304800.6096 +ellps=GRS80 +units=us-ft +no_defs'],
    [103249, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-105.5 +lat_1=37.2333333333333 +lat_2=38.4333333333333 +x_0=914401.828899998 +y_0=304800.6096 +ellps=GRS80 +units=us-ft +no_defs'],
    [103250, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.2 +lat_2=41.8666666666667 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +units=m +no_defs'],
    [103251, '+proj=lcc +lat_0=40.8333333333333 +lon_0=-72.75 +lat_1=41.2 +lat_2=41.8666666666667 +x_0=304800.6096 +y_0=152400.3048 +ellps=GRS80 +units=us-ft +no_defs'],
    [103252, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103253, '+proj=tmerc +lat_0=38 +lon_0=-75.4166666666667 +k=0.999995 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103254, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103255, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103256, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=29.5833333333333 +lat_2=30.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103257, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103258, '+proj=tmerc +lat_0=24.3333333333333 +lon_0=-82 +k=0.999941176470588 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103259, '+proj=lcc +lat_0=29 +lon_0=-84.5 +lat_1=29.5833333333333 +lat_2=30.75 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103260, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103261, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103262, '+proj=tmerc +lat_0=30 +lon_0=-82.1666666666667 +k=0.9999 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103263, '+proj=tmerc +lat_0=30 +lon_0=-84.1666666666667 +k=0.9999 +x_0=699999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103264, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368421053 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103265, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368421053 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103266, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333333333 +x_0=800000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103267, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-112.166666666667 +k=0.999947368421053 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103268, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-114 +k=0.999947368421053 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103269, '+proj=tmerc +lat_0=41.6666666666667 +lon_0=-115.75 +k=0.999933333333333 +x_0=800000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103270, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103271, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941176470588 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103272, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-88.3333333333333 +k=0.999975 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103273, '+proj=tmerc +lat_0=36.6666666666667 +lon_0=-90.1666666666667 +k=0.999941176470588 +x_0=699999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103274, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [103275, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966666666667 +x_0=900000 +y_0=250000 +ellps=GRS80 +units=m +no_defs'],
    [103276, '+proj=tmerc +lat_0=37.5 +lon_0=-85.6666666666667 +k=0.999966666666667 +x_0=100000 +y_0=250000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103277, '+proj=tmerc +lat_0=37.5 +lon_0=-87.0833333333333 +k=0.999966666666667 +x_0=900000 +y_0=250000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103278, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=42.0666666666667 +lat_2=43.2666666666667 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103279, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=40.6166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103280, '+proj=lcc +lat_0=41.5 +lon_0=-93.5 +lat_1=42.0666666666667 +lat_2=43.2666666666667 +x_0=1500000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103281, '+proj=lcc +lat_0=40 +lon_0=-93.5 +lat_1=40.6166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103282, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=38.7166666666667 +lat_2=39.7833333333333 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103283, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=37.2666666666667 +lat_2=38.5666666666667 +x_0=400000 +y_0=400000 +ellps=GRS80 +units=m +no_defs'],
    [103284, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-98 +lat_1=38.7166666666667 +lat_2=39.7833333333333 +x_0=399999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103285, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-98.5 +lat_1=37.2666666666667 +lat_2=38.5666666666667 +x_0=399999.999999999 +y_0=399999.999999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103286, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103287, '+proj=lcc +lat_0=37.5 +lon_0=-84.25 +lat_1=37.9666666666667 +lat_2=38.9666666666667 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103288, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103289, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=37.0833333333333 +lat_2=38.6666666666667 +x_0=1500000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103290, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=36.7333333333333 +lat_2=37.9333333333333 +x_0=500000 +y_0=500000 +ellps=GRS80 +units=m +no_defs'],
    [103291, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-85.75 +lat_1=36.7333333333333 +lat_2=37.9333333333333 +x_0=500000 +y_0=500000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103292, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=31.1666666666667 +lat_2=32.6666666666667 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103293, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=29.3 +lat_2=30.7 +x_0=1000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103294, '+proj=lcc +lat_0=30.5 +lon_0=-92.5 +lat_1=31.1666666666667 +lat_2=32.6666666666667 +x_0=999999.999999998 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103295, '+proj=lcc +lat_0=28.5 +lon_0=-91.3333333333333 +lat_1=29.3 +lat_2=30.7 +x_0=999999.999999998 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103296, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103297, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966666666667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103298, '+proj=tmerc +lat_0=43.6666666666667 +lon_0=-68.5 +k=0.9999 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103299, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.1666666666667 +k=0.999966666666667 +x_0=900000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103300, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=1.0000365285 +x_0=147218.6942 +y_0=0.0037 +ellps=GRS80 +units=m +no_defs'],
    [103301, '+proj=tmerc +lat_0=45.7061111111111 +lon_0=-90.6222222222222 +k=1.0000495683 +x_0=172821.9461 +y_0=0.0017 +ellps=GRS80 +units=m +no_defs'],
    [103302, '+proj=tmerc +lat_0=45.1333333333333 +lon_0=-91.85 +k=1.0000486665 +x_0=93150 +y_0=0.0029 +ellps=GRS80 +units=m +no_defs'],
    [103303, '+proj=lcc +lat_1=46.6696483772222 +lat_0=46.6696483772222 +lon_0=-91.1527777777778 +k_0=1.0000331195 +x_0=228600.4575 +y_0=148551.4837 +ellps=GRS80 +units=m +no_defs'],
    [103304, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31600 +y_0=4600 +ellps=GRS80 +units=m +no_defs'],
    [103305, '+proj=tmerc +lat_0=43.4813888888889 +lon_0=-91.7972222222222 +k=1.0000382778 +x_0=175260.3502 +y_0=0.0048 +ellps=GRS80 +units=m +no_defs'],
    [103306, '+proj=lcc +lat_1=45.8987148658333 +lat_0=45.8987148658333 +lon_0=-92.4577777777778 +k_0=1.0000383841 +x_0=64008.1276 +y_0=59445.9043 +ellps=GRS80 +units=m +no_defs'],
    [103307, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8893 +y_0=0.0049 +ellps=GRS80 +units=m +no_defs'],
    [103308, '+proj=lcc +lat_1=44.9778568986111 +lat_0=44.9778568986111 +lon_0=-91.2944444444444 +k_0=1.0000391127 +x_0=60045.72 +y_0=44091.4346 +ellps=GRS80 +units=m +no_defs'],
    [103309, '+proj=tmerc +lat_0=43.6 +lon_0=-90.7083333333333 +k=1.0000463003 +x_0=199949.1989 +y_0=0.0086 +ellps=GRS80 +units=m +no_defs'],
    [103310, '+proj=lcc +lat_1=43.4625466458333 +lat_0=43.4625466458333 +lon_0=-89.3944444444444 +k_0=1.00003498 +x_0=169164.3381 +y_0=111569.6134 +ellps=GRS80 +units=m +no_defs'],
    [103311, '+proj=lcc +lat_1=43.200055605 +lat_0=43.200055605 +lon_0=-90.9388888888889 +k_0=1.0000349151 +x_0=113690.6274 +y_0=53703.1201 +ellps=GRS80 +units=m +no_defs'],
    [103312, '+proj=lcc +lat_1=43.0695160375 +lat_0=43.0695160375 +lon_0=-89.4222222222222 +k_0=1.0000384786 +x_0=247193.2944 +y_0=146591.9896 +ellps=GRS80 +units=m +no_defs'],
    [103313, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=1.0000346418 +x_0=263347.7263 +y_0=0.0076 +ellps=GRS80 +units=m +no_defs'],
    [103314, '+proj=tmerc +lat_0=44.4 +lon_0=-87.2722222222222 +k=1.0000187521 +x_0=158801.1176 +y_0=0.0023 +ellps=GRS80 +units=m +no_defs'],
    [103315, '+proj=tmerc +lat_0=45.8833333333333 +lon_0=-91.9166666666667 +k=1.0000385418 +x_0=59131.3183 +y_0=0.0041 +ellps=GRS80 +units=m +no_defs'],
    [103316, '+proj=tmerc +lat_0=44.4083333333333 +lon_0=-91.8944444444444 +k=1.0000410324 +x_0=51816.104 +y_0=0.003 +ellps=GRS80 +units=m +no_defs'],
    [103317, '+proj=lcc +lat_1=44.8722811263889 +lat_0=44.8722811263889 +lon_0=-91.2888888888889 +k_0=1.000035079 +x_0=120091.4402 +y_0=91687.9239 +ellps=GRS80 +units=m +no_defs'],
    [103318, '+proj=tmerc +lat_0=45.4388888888889 +lon_0=-88.1416666666667 +k=1.0000552095 +x_0=133502.6683 +y_0=0.0063 +ellps=GRS80 +units=m +no_defs'],
    [103319, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8893 +y_0=0.0049 +ellps=GRS80 +units=m +no_defs'],
    [103320, '+proj=tmerc +lat_0=44.0055555555556 +lon_0=-88.6333333333333 +k=1.0000673004 +x_0=275844.5533 +y_0=0.0157 +ellps=GRS80 +units=m +no_defs'],
    [103321, '+proj=tmerc +lat_0=41.4111111111111 +lon_0=-90.8 +k=1.0000349452 +x_0=242316.4841 +y_0=0.01 +ellps=GRS80 +units=m +no_defs'],
    [103322, '+proj=lcc +lat_1=42.6375622769444 +lat_0=42.6375622769444 +lon_0=-89.8388888888889 +k_0=1.0000390487 +x_0=170078.7403 +y_0=45830.2947 +ellps=GRS80 +units=m +no_defs'],
    [103323, '+proj=lcc +lat_1=43.8070001177778 +lat_0=43.8070001177778 +lon_0=-89.2416666666667 +k_0=1.0000344057 +x_0=150876.3018 +y_0=79170.7795 +ellps=GRS80 +units=m +no_defs'],
    [103324, '+proj=tmerc +lat_0=42.5388888888889 +lon_0=-90.1611111111111 +k=1.0000394961 +x_0=113081.0261 +y_0=0.0045 +ellps=GRS80 +units=m +no_defs'],
    [103325, '+proj=tmerc +lat_0=45.4333333333333 +lon_0=-90.2555555555556 +k=1.0000677153 +x_0=220980.4419 +y_0=0.0085 +ellps=GRS80 +units=m +no_defs'],
    [103326, '+proj=tmerc +lat_0=44.2533351277778 +lon_0=-90.8442965194444 +k=1.0000353 +x_0=27000 +y_0=25000 +ellps=GRS80 +units=m +no_defs'],
    [103327, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=1.0000346418 +x_0=263347.7263 +y_0=0.0076 +ellps=GRS80 +units=m +no_defs'],
    [103328, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=1.0000365285 +x_0=147218.6942 +y_0=0.0037 +ellps=GRS80 +units=m +no_defs'],
    [103329, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.3728 +y_0=0.0009 +ellps=GRS80 +units=m +no_defs'],
    [103330, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.7614 +y_0=0.0012 +ellps=GRS80 +units=m +no_defs'],
    [103331, '+proj=tmerc +lat_0=43.4511111111111 +lon_0=-91.3166666666667 +k=1.0000319985 +x_0=130454.6598 +y_0=0.0033 +ellps=GRS80 +units=m +no_defs'],
    [103332, '+proj=lcc +lat_1=42.6375622769444 +lat_0=42.6375622769444 +lon_0=-89.8388888888889 +k_0=1.0000390487 +x_0=170078.7403 +y_0=45830.2947 +ellps=GRS80 +units=m +no_defs'],
    [103333, '+proj=lcc +lat_1=45.1542371052778 +lat_0=45.1542371052778 +lon_0=-89.0333333333333 +k_0=1.0000627024 +x_0=198425.197 +y_0=105279.7829 +ellps=GRS80 +units=m +no_defs'],
    [103334, '+proj=tmerc +lat_0=44.8444444444444 +lon_0=-89.7333333333333 +k=1.0000599003 +x_0=116129.0323 +y_0=0.0058 +ellps=GRS80 +units=m +no_defs'],
    [103335, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.7614 +y_0=0.0012 +ellps=GRS80 +units=m +no_defs'],
    [103336, '+proj=lcc +lat_1=44.9009044236111 +lat_0=44.9009044236111 +lon_0=-89.77 +k_0=1.000053289 +x_0=74676.1493 +y_0=55049.2669 +ellps=GRS80 +units=m +no_defs'],
    [103337, '+proj=tmerc +lat_0=44.6916666666667 +lon_0=-87.7111111111111 +k=1.0000234982 +x_0=238658.8794 +y_0=0.0032 +ellps=GRS80 +units=m +no_defs'],
    [103338, '+proj=lcc +lat_1=43.8070001177778 +lat_0=43.8070001177778 +lon_0=-89.2416666666667 +k_0=1.0000344057 +x_0=150876.3018 +y_0=79170.7795 +ellps=GRS80 +units=m +no_defs'],
    [103339, '+proj=tmerc +lat_0=44.7166666666667 +lon_0=-88.4166666666667 +k=1.0000362499 +x_0=105461.0121 +y_0=0.0029 +ellps=GRS80 +units=m +no_defs'],
    [103340, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.3728 +y_0=0.0009 +ellps=GRS80 +units=m +no_defs'],
    [103341, '+proj=lcc +lat_1=44.0000739286111 +lat_0=44.0000739286111 +lon_0=-90.6416666666667 +k_0=1.0000434122 +x_0=204521.209 +y_0=121923.9861 +ellps=GRS80 +units=m +no_defs'],
    [103342, '+proj=tmerc +lat_0=44.3972222222222 +lon_0=-87.9083333333333 +k=1.0000236869 +x_0=182880.3676 +y_0=0.0033 +ellps=GRS80 +units=m +no_defs'],
    [103343, '+proj=lcc +lat_1=45.7042237702778 +lat_0=45.7042237702778 +lon_0=-89.5444444444444 +k_0=1.0000686968 +x_0=70104.1401 +y_0=57588.0346 +ellps=GRS80 +units=m +no_defs'],
    [103344, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8893 +y_0=0.0049 +ellps=GRS80 +units=m +no_defs'],
    [103345, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.3728 +y_0=0.0009 +ellps=GRS80 +units=m +no_defs'],
    [103346, '+proj=lcc +lat_1=44.6361488719444 +lat_0=44.6361488719444 +lon_0=-92.2277777777778 +k_0=1.0000362977 +x_0=167640.3354 +y_0=86033.0876 +ellps=GRS80 +units=m +no_defs'],
    [103347, '+proj=lcc +lat_1=44.6361488719444 +lat_0=44.6361488719444 +lon_0=-92.2277777777778 +k_0=1.0000362977 +x_0=167640.3354 +y_0=86033.0876 +ellps=GRS80 +units=m +no_defs'],
    [103348, '+proj=tmerc +lat_0=44.6611111111111 +lon_0=-92.6333333333333 +k=1.0000433849 +x_0=141732.2823 +y_0=0.0059 +ellps=GRS80 +units=m +no_defs'],
    [103349, '+proj=lcc +lat_1=44.4168239752778 +lat_0=44.4168239752778 +lon_0=-89.5 +k_0=1.000039936 +x_0=56388.1128 +y_0=50022.1874 +ellps=GRS80 +units=m +no_defs'],
    [103350, '+proj=tmerc +lat_0=44.5555555555556 +lon_0=-90.4888888888889 +k=1.0000649554 +x_0=227990.8546 +y_0=0.0109 +ellps=GRS80 +units=m +no_defs'],
    [103351, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.3728 +y_0=0.0009 +ellps=GRS80 +units=m +no_defs'],
    [103352, '+proj=lcc +lat_1=43.3223129275 +lat_0=43.3223129275 +lon_0=-90.4305555555556 +k_0=1.0000375653 +x_0=202387.6048 +y_0=134255.4253 +ellps=GRS80 +units=m +no_defs'],
    [103353, '+proj=tmerc +lat_0=41.9444444444444 +lon_0=-89.0722222222222 +k=1.0000337311 +x_0=146304.2926 +y_0=0.0068 +ellps=GRS80 +units=m +no_defs'],
    [103354, '+proj=tmerc +lat_0=43.9194444444444 +lon_0=-91.0666666666667 +k=1.0000495976 +x_0=250546.1013 +y_0=0.0234 +ellps=GRS80 +units=m +no_defs'],
    [103355, '+proj=tmerc +lat_0=42.8194444444444 +lon_0=-89.9 +k=1.0000373868 +x_0=185623.5716 +y_0=0.0051 +ellps=GRS80 +units=m +no_defs'],
    [103356, '+proj=lcc +lat_1=45.9000991313889 +lat_0=45.9000991313889 +lon_0=-91.1166666666667 +k_0=1.0000573461 +x_0=216713.2336 +y_0=120734.1631 +ellps=GRS80 +units=m +no_defs'],
    [103357, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-88.6055555555556 +k=1.000032144 +x_0=262433.3253 +y_0=0.0096 +ellps=GRS80 +units=m +no_defs'],
    [103358, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.7614 +y_0=0.0012 +ellps=GRS80 +units=m +no_defs'],
    [103359, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-92.6333333333333 +k=1.0000381803 +x_0=165506.7302 +y_0=0.0103 +ellps=GRS80 +units=m +no_defs'],
    [103360, '+proj=lcc +lat_1=45.1778220858333 +lat_0=45.1778220858333 +lon_0=-90.4833333333333 +k_0=1.0000597566 +x_0=187147.5744 +y_0=107746.7522 +ellps=GRS80 +units=m +no_defs'],
    [103361, '+proj=tmerc +lat_0=43.1611111111111 +lon_0=-91.3666666666667 +k=1.0000361538 +x_0=256946.9138 +y_0=0.0041 +ellps=GRS80 +units=m +no_defs'],
    [103362, '+proj=lcc +lat_1=43.5750329397222 +lat_0=43.5750329397222 +lon_0=-90.7833333333333 +k_0=1.0000408158 +x_0=222504.4451 +y_0=47532.0602 +ellps=GRS80 +units=m +no_defs'],
    [103363, '+proj=lcc +lat_1=46.0778440905556 +lat_0=46.0778440905556 +lon_0=-89.4888888888889 +k_0=1.0000730142 +x_0=134417.0689 +y_0=50337.1092 +ellps=GRS80 +units=m +no_defs'],
    [103364, '+proj=lcc +lat_1=42.6694620969444 +lat_0=42.6694620969444 +lon_0=-88.5416666666667 +k_0=1.0000367192 +x_0=232562.8651 +y_0=111088.2224 +ellps=GRS80 +units=m +no_defs'],
    [103365, '+proj=lcc +lat_1=45.9612198333333 +lat_0=45.9612198333333 +lon_0=-91.7833333333333 +k_0=1.0000475376 +x_0=234086.8682 +y_0=188358.6058 +ellps=GRS80 +units=m +no_defs'],
    [103366, '+proj=tmerc +lat_0=42.9180555555555 +lon_0=-88.0638888888889 +k=1.00003738 +x_0=120091.4415 +y_0=0.003 +ellps=GRS80 +units=m +no_defs'],
    [103367, '+proj=tmerc +lat_0=42.5694444444444 +lon_0=-88.225 +k=1.0000346179 +x_0=208788.418 +y_0=0.0034 +ellps=GRS80 +units=m +no_defs'],
    [103368, '+proj=tmerc +lat_0=43.4202777777778 +lon_0=-88.8166666666667 +k=1.0000333645 +x_0=185013.9709 +y_0=0.007 +ellps=GRS80 +units=m +no_defs'],
    [103369, '+proj=lcc +lat_1=44.1139440458333 +lat_0=44.1139440458333 +lon_0=-89.2416666666667 +k_0=1.0000392096 +x_0=120091.4402 +y_0=45069.7587 +ellps=GRS80 +units=m +no_defs'],
    [103370, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.8893 +y_0=0.0049 +ellps=GRS80 +units=m +no_defs'],
    [103371, '+proj=lcc +lat_1=44.3625954694444 +lat_0=44.3625954694444 +lon_0=-90 +k_0=1.0000421209 +x_0=208483.6173 +y_0=134589.754 +ellps=GRS80 +units=m +no_defs'],
    [103372, '+proj=tmerc +lat_0=43.8333333333333 +lon_0=-67.875 +k=0.99998 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103373, '+proj=tmerc +lat_0=43.5 +lon_0=-69.125 +k=0.99998 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103374, '+proj=tmerc +lat_0=42.8333333333333 +lon_0=-70.375 +k=0.99998 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103375, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=38.3 +lat_2=39.45 +x_0=400000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103376, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-77 +lat_1=38.3 +lat_2=39.45 +x_0=399999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103377, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=41.7166666666667 +lat_2=42.6833333333333 +x_0=200000 +y_0=750000 +ellps=GRS80 +units=m +no_defs'],
    [103378, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.2833333333333 +lat_2=41.4833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103379, '+proj=lcc +lat_0=41 +lon_0=-71.5 +lat_1=41.7166666666667 +lat_2=42.6833333333333 +x_0=200000 +y_0=750000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103380, '+proj=lcc +lat_0=41 +lon_0=-70.5 +lat_1=41.2833333333333 +lat_2=41.4833333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103381, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=8000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103382, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=6000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103383, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=4000000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103384, '+proj=lcc +lat_0=44.7833333333333 +lon_0=-87 +lat_1=45.4833333333333 +lat_2=47.0833333333333 +x_0=8000000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103385, '+proj=lcc +lat_0=43.3166666666667 +lon_0=-84.3666666666667 +lat_1=44.1833333333333 +lat_2=45.7 +x_0=5999999.99999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103386, '+proj=lcc +lat_0=41.5 +lon_0=-84.3666666666667 +lat_1=42.1 +lat_2=43.6666666666667 +x_0=4000000 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103387, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103388, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103389, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103390, '+proj=lcc +lat_0=46.5 +lon_0=-93.1 +lat_1=47.0333333333333 +lat_2=48.6333333333333 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103391, '+proj=lcc +lat_0=45 +lon_0=-94.25 +lat_1=45.6166666666667 +lat_2=47.05 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103392, '+proj=lcc +lat_0=43 +lon_0=-94 +lat_1=43.7833333333333 +lat_2=45.2166666666667 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103393, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103394, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=700000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103395, '+proj=tmerc +lat_0=29.5 +lon_0=-88.8333333333333 +k=0.99995 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103396, '+proj=tmerc +lat_0=29.5 +lon_0=-90.3333333333333 +k=0.99995 +x_0=699999.999999999 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103397, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-90.5 +k=0.999933333333333 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103398, '+proj=tmerc +lat_0=35.8333333333333 +lon_0=-92.5 +k=0.999933333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103399, '+proj=tmerc +lat_0=36.1666666666667 +lon_0=-94.5 +k=0.999941176470588 +x_0=850000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103400, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=1.0000365285 +x_0=147218.694132588 +y_0=0.00365760731521463 +ellps=GRS80 +units=us-ft +no_defs'],
    [103401, '+proj=tmerc +lat_0=45.7061111111111 +lon_0=-90.6222222222222 +k=1.0000495683 +x_0=172821.945948692 +y_0=0.00182880365760731 +ellps=GRS80 +units=us-ft +no_defs'],
    [103402, '+proj=tmerc +lat_0=45.1333333333333 +lon_0=-91.85 +k=1.0000486665 +x_0=93150 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [103403, '+proj=lcc +lat_1=46.6696483772222 +lat_0=46.6696483772222 +lon_0=-91.1527777777778 +k_0=1.0000331195 +x_0=228600.457505715 +y_0=148551.483566167 +ellps=GRS80 +units=us-ft +no_defs'],
    [103404, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31599.9998983998 +y_0=4599.99989839979 +ellps=GRS80 +units=us-ft +no_defs'],
    [103405, '+proj=tmerc +lat_0=43.4813888888889 +lon_0=-91.7972222222222 +k=1.0000382778 +x_0=175260.3502159 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [103406, '+proj=lcc +lat_1=45.8987148658333 +lat_0=45.8987148658333 +lon_0=-92.4577777777778 +k_0=1.0000383841 +x_0=64008.1277114554 +y_0=59445.9041910083 +ellps=GRS80 +units=us-ft +no_defs'],
    [103407, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.889204978 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [103408, '+proj=lcc +lat_1=44.9778568986111 +lat_0=44.9778568986111 +lon_0=-91.2944444444444 +k_0=1.0000391127 +x_0=60045.7200914401 +y_0=44091.434493269 +ellps=GRS80 +units=us-ft +no_defs'],
    [103409, '+proj=tmerc +lat_0=43.6 +lon_0=-90.7083333333333 +k=1.0000463003 +x_0=199949.198983998 +y_0=0.00853441706883413 +ellps=GRS80 +units=us-ft +no_defs'],
    [103410, '+proj=lcc +lat_1=43.4625466458333 +lat_0=43.4625466458333 +lon_0=-89.3944444444444 +k_0=1.00003498 +x_0=169164.338023876 +y_0=111569.613512827 +ellps=GRS80 +units=us-ft +no_defs'],
    [103411, '+proj=lcc +lat_1=43.200055605 +lat_0=43.200055605 +lon_0=-90.9388888888889 +k_0=1.0000349151 +x_0=113690.627381255 +y_0=53703.1202438405 +ellps=GRS80 +units=us-ft +no_defs'],
    [103412, '+proj=lcc +lat_1=43.0695160375 +lat_0=43.0695160375 +lon_0=-89.4222222222222 +k_0=1.0000384786 +x_0=247193.294386589 +y_0=146591.989636779 +ellps=GRS80 +units=us-ft +no_defs'],
    [103413, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=1.0000346418 +x_0=263347.726390653 +y_0=0.00762001524003048 +ellps=GRS80 +units=us-ft +no_defs'],
    [103414, '+proj=tmerc +lat_0=44.4 +lon_0=-87.2722222222222 +k=1.0000187521 +x_0=158801.117602235 +y_0=0.00243840487680975 +ellps=GRS80 +units=us-ft +no_defs'],
    [103415, '+proj=tmerc +lat_0=45.8833333333333 +lon_0=-91.9166666666667 +k=1.0000385418 +x_0=59131.3182626365 +y_0=0.00396240792481585 +ellps=GRS80 +units=us-ft +no_defs'],
    [103416, '+proj=tmerc +lat_0=44.4083333333333 +lon_0=-91.8944444444444 +k=1.0000410324 +x_0=51816.1039370078 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [103417, '+proj=lcc +lat_1=44.8722811263889 +lat_0=44.8722811263889 +lon_0=-91.2888888888889 +k_0=1.000035079 +x_0=120091.44018288 +y_0=91687.9239014477 +ellps=GRS80 +units=us-ft +no_defs'],
    [103418, '+proj=tmerc +lat_0=45.4388888888889 +lon_0=-88.1416666666667 +k=1.0000552095 +x_0=133502.668224536 +y_0=0.0064008128016256 +ellps=GRS80 +units=us-ft +no_defs'],
    [103419, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.889204978 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [103420, '+proj=tmerc +lat_0=44.0055555555556 +lon_0=-88.6333333333333 +k=1.0000673004 +x_0=275844.553213106 +y_0=0.0158496316992634 +ellps=GRS80 +units=us-ft +no_defs'],
    [103421, '+proj=tmerc +lat_0=41.4111111111111 +lon_0=-90.8 +k=1.0000349452 +x_0=242316.484023368 +y_0=0.0100584201168402 +ellps=GRS80 +units=us-ft +no_defs'],
    [103422, '+proj=lcc +lat_1=42.6375622769444 +lat_0=42.6375622769444 +lon_0=-89.8388888888889 +k_0=1.0000390487 +x_0=170078.74015748 +y_0=45830.2948437897 +ellps=GRS80 +units=us-ft +no_defs'],
    [103423, '+proj=lcc +lat_1=43.8070001177778 +lat_0=43.8070001177778 +lon_0=-89.2416666666667 +k_0=1.0000344057 +x_0=150876.301752603 +y_0=79170.7793751587 +ellps=GRS80 +units=us-ft +no_defs'],
    [103424, '+proj=tmerc +lat_0=42.5388888888889 +lon_0=-90.1611111111111 +k=1.0000394961 +x_0=113081.026162052 +y_0=0.00457200914401829 +ellps=GRS80 +units=us-ft +no_defs'],
    [103425, '+proj=tmerc +lat_0=45.4333333333333 +lon_0=-90.2555555555556 +k=1.0000677153 +x_0=220980.441960884 +y_0=0.00853441706883413 +ellps=GRS80 +units=us-ft +no_defs'],
    [103426, '+proj=tmerc +lat_0=44.2533351277778 +lon_0=-90.8442965194444 +k=1.0000353 +x_0=27000 +y_0=24999.9998983998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103427, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=1.0000346418 +x_0=263347.726390653 +y_0=0.00762001524003048 +ellps=GRS80 +units=us-ft +no_defs'],
    [103428, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=1.0000365285 +x_0=147218.694132588 +y_0=0.00365760731521463 +ellps=GRS80 +units=us-ft +no_defs'],
    [103429, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.372771145 +y_0=0.000914401828803657 +ellps=GRS80 +units=us-ft +no_defs'],
    [103430, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.761544323 +y_0=0.00121920243840488 +ellps=GRS80 +units=us-ft +no_defs'],
    [103431, '+proj=tmerc +lat_0=43.4511111111111 +lon_0=-91.3166666666667 +k=1.0000319985 +x_0=130454.659690119 +y_0=0.00335280670561341 +ellps=GRS80 +units=us-ft +no_defs'],
    [103432, '+proj=lcc +lat_1=42.6375622769444 +lat_0=42.6375622769444 +lon_0=-89.8388888888889 +k_0=1.0000390487 +x_0=170078.74015748 +y_0=45830.2948437897 +ellps=GRS80 +units=us-ft +no_defs'],
    [103433, '+proj=lcc +lat_1=45.1542371052778 +lat_0=45.1542371052778 +lon_0=-89.0333333333333 +k_0=1.0000627024 +x_0=198425.196850394 +y_0=105279.782880366 +ellps=GRS80 +units=us-ft +no_defs'],
    [103434, '+proj=tmerc +lat_0=44.8444444444444 +lon_0=-89.7333333333333 +k=1.0000599003 +x_0=116129.032258064 +y_0=0.00579121158242316 +ellps=GRS80 +units=us-ft +no_defs'],
    [103435, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.761544323 +y_0=0.00121920243840488 +ellps=GRS80 +units=us-ft +no_defs'],
    [103436, '+proj=lcc +lat_1=44.9009044236111 +lat_0=44.9009044236111 +lon_0=-89.77 +k_0=1.000053289 +x_0=74676.1493522987 +y_0=55049.2669545339 +ellps=GRS80 +units=us-ft +no_defs'],
    [103437, '+proj=tmerc +lat_0=44.6916666666667 +lon_0=-87.7111111111111 +k=1.0000234982 +x_0=238658.879451359 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [103438, '+proj=lcc +lat_1=43.8070001177778 +lat_0=43.8070001177778 +lon_0=-89.2416666666667 +k_0=1.0000344057 +x_0=150876.301752603 +y_0=79170.7793751587 +ellps=GRS80 +units=us-ft +no_defs'],
    [103439, '+proj=tmerc +lat_0=44.7166666666667 +lon_0=-88.4166666666667 +k=1.0000362499 +x_0=105461.012141224 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [103440, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.372771145 +y_0=0.000914401828803657 +ellps=GRS80 +units=us-ft +no_defs'],
    [103441, '+proj=lcc +lat_1=44.0000739286111 +lat_0=44.0000739286111 +lon_0=-90.6416666666667 +k_0=1.0000434122 +x_0=204521.209042418 +y_0=121923.986182372 +ellps=GRS80 +units=us-ft +no_defs'],
    [103442, '+proj=tmerc +lat_0=44.3972222222222 +lon_0=-87.9083333333333 +k=1.0000236869 +x_0=182880.367589535 +y_0=0.00335280670561341 +ellps=GRS80 +units=us-ft +no_defs'],
    [103443, '+proj=lcc +lat_1=45.7042237702778 +lat_0=45.7042237702778 +lon_0=-89.5444444444444 +k_0=1.0000686968 +x_0=70104.1402082804 +y_0=57588.0347472695 +ellps=GRS80 +units=us-ft +no_defs'],
    [103444, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.889204978 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [103445, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.372771145 +y_0=0.000914401828803657 +ellps=GRS80 +units=us-ft +no_defs'],
    [103446, '+proj=lcc +lat_1=44.6361488719444 +lat_0=44.6361488719444 +lon_0=-92.2277777777778 +k_0=1.0000362977 +x_0=167640.33528067 +y_0=86033.0877317754 +ellps=GRS80 +units=us-ft +no_defs'],
    [103447, '+proj=lcc +lat_1=44.6361488719444 +lat_0=44.6361488719444 +lon_0=-92.2277777777778 +k_0=1.0000362977 +x_0=167640.33528067 +y_0=86033.0877317754 +ellps=GRS80 +units=us-ft +no_defs'],
    [103448, '+proj=tmerc +lat_0=44.6611111111111 +lon_0=-92.6333333333333 +k=1.0000433849 +x_0=141732.282245364 +y_0=0.00579121158242316 +ellps=GRS80 +units=us-ft +no_defs'],
    [103449, '+proj=lcc +lat_1=44.4168239752778 +lat_0=44.4168239752778 +lon_0=-89.5 +k_0=1.000039936 +x_0=56388.1127762255 +y_0=50022.1874523749 +ellps=GRS80 +units=us-ft +no_defs'],
    [103450, '+proj=tmerc +lat_0=44.5555555555556 +lon_0=-90.4888888888889 +k=1.0000649554 +x_0=227990.854457709 +y_0=0.0109728219456439 +ellps=GRS80 +units=us-ft +no_defs'],
    [103451, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=1.0000260649 +x_0=185928.372771145 +y_0=0.000914401828803657 +ellps=GRS80 +units=us-ft +no_defs'],
    [103452, '+proj=lcc +lat_1=43.3223129275 +lat_0=43.3223129275 +lon_0=-90.4305555555556 +k_0=1.0000375653 +x_0=202387.604775209 +y_0=134255.425450851 +ellps=GRS80 +units=us-ft +no_defs'],
    [103453, '+proj=tmerc +lat_0=41.9444444444444 +lon_0=-89.0722222222222 +k=1.0000337311 +x_0=146304.292608585 +y_0=0.00670561341122682 +ellps=GRS80 +units=us-ft +no_defs'],
    [103454, '+proj=tmerc +lat_0=43.9194444444444 +lon_0=-91.0666666666667 +k=1.0000495976 +x_0=250546.101397003 +y_0=0.0234696469392939 +ellps=GRS80 +units=us-ft +no_defs'],
    [103455, '+proj=tmerc +lat_0=42.8194444444444 +lon_0=-89.9 +k=1.0000373868 +x_0=185623.571551943 +y_0=0.00518161036322072 +ellps=GRS80 +units=us-ft +no_defs'],
    [103456, '+proj=lcc +lat_1=45.9000991313889 +lat_0=45.9000991313889 +lon_0=-91.1166666666667 +k_0=1.0000573461 +x_0=216713.233731267 +y_0=120734.163169926 +ellps=GRS80 +units=us-ft +no_defs'],
    [103457, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-88.6055555555556 +k=1.000032144 +x_0=262433.32517145 +y_0=0.00944881889763779 +ellps=GRS80 +units=us-ft +no_defs'],
    [103458, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1.0000233704 +x_0=79857.761544323 +y_0=0.00121920243840488 +ellps=GRS80 +units=us-ft +no_defs'],
    [103459, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-92.6333333333333 +k=1.0000381803 +x_0=165506.73009906 +y_0=0.0103632207264414 +ellps=GRS80 +units=us-ft +no_defs'],
    [103460, '+proj=lcc +lat_1=45.1778220858333 +lat_0=45.1778220858333 +lon_0=-90.4833333333333 +k_0=1.0000597566 +x_0=187147.574295148 +y_0=107746.752146304 +ellps=GRS80 +units=us-ft +no_defs'],
    [103461, '+proj=tmerc +lat_0=43.1611111111111 +lon_0=-91.3666666666667 +k=1.0000361538 +x_0=256946.913893828 +y_0=0.00396240792481585 +ellps=GRS80 +units=us-ft +no_defs'],
    [103462, '+proj=lcc +lat_1=43.5750329397222 +lat_0=43.5750329397222 +lon_0=-90.7833333333333 +k_0=1.0000408158 +x_0=222504.44500889 +y_0=47532.0603505207 +ellps=GRS80 +units=us-ft +no_defs'],
    [103463, '+proj=lcc +lat_1=46.0778440905556 +lat_0=46.0778440905556 +lon_0=-89.4888888888889 +k_0=1.0000730142 +x_0=134417.068834138 +y_0=50337.1092710185 +ellps=GRS80 +units=us-ft +no_defs'],
    [103464, '+proj=lcc +lat_1=42.6694620969444 +lat_0=42.6694620969444 +lon_0=-88.5416666666667 +k_0=1.0000367192 +x_0=232562.86512573 +y_0=111088.222402845 +ellps=GRS80 +units=us-ft +no_defs'],
    [103465, '+proj=lcc +lat_1=45.9612198333333 +lat_0=45.9612198333333 +lon_0=-91.7833333333333 +k_0=1.0000475376 +x_0=234086.868173736 +y_0=188358.605943612 +ellps=GRS80 +units=us-ft +no_defs'],
    [103466, '+proj=tmerc +lat_0=42.9180555555555 +lon_0=-88.0638888888889 +k=1.00003738 +x_0=120091.441402083 +y_0=0.00304800609601219 +ellps=GRS80 +units=us-ft +no_defs'],
    [103467, '+proj=tmerc +lat_0=42.5694444444444 +lon_0=-88.225 +k=1.0000346179 +x_0=208788.417881636 +y_0=0.00335280670561341 +ellps=GRS80 +units=us-ft +no_defs'],
    [103468, '+proj=tmerc +lat_0=43.4202777777778 +lon_0=-88.8166666666667 +k=1.0000333645 +x_0=185013.970942342 +y_0=0.00701041402082804 +ellps=GRS80 +units=us-ft +no_defs'],
    [103469, '+proj=lcc +lat_1=44.1139440458333 +lat_0=44.1139440458333 +lon_0=-89.2416666666667 +k_0=1.0000392096 +x_0=120091.44018288 +y_0=45069.7588011176 +ellps=GRS80 +units=us-ft +no_defs'],
    [103470, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=1.0000286569 +x_0=244754.889204978 +y_0=0.0048768097536195 +ellps=GRS80 +units=us-ft +no_defs'],
    [103471, '+proj=lcc +lat_1=44.3625954694444 +lat_0=44.3625954694444 +lon_0=-90 +k_0=1.0000421209 +x_0=208483.617272034 +y_0=134589.753924308 +ellps=GRS80 +units=us-ft +no_defs'],
    [103472, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103473, '+proj=lcc +lat_0=44.25 +lon_0=-109.5 +lat_1=45 +lat_2=49 +x_0=599999.999999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103474, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=40 +lat_2=43 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103475, '+proj=lcc +lat_0=39.8333333333333 +lon_0=-100 +lat_1=40 +lat_2=43 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103476, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000 +ellps=GRS80 +units=m +no_defs'],
    [103477, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +units=m +no_defs'],
    [103478, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [103479, '+proj=tmerc +lat_0=34.75 +lon_0=-115.583333333333 +k=0.9999 +x_0=200000 +y_0=8000000.00000001 +ellps=GRS80 +units=us-ft +no_defs'],
    [103480, '+proj=tmerc +lat_0=34.75 +lon_0=-116.666666666667 +k=0.9999 +x_0=500000 +y_0=6000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103481, '+proj=tmerc +lat_0=34.75 +lon_0=-118.583333333333 +k=0.9999 +x_0=800000 +y_0=3999999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103482, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966666666667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103483, '+proj=tmerc +lat_0=42.5 +lon_0=-71.6666666666667 +k=0.999966666666667 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103484, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103485, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103486, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909090909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103487, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103488, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916666666667 +x_0=830000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103489, '+proj=tmerc +lat_0=31 +lon_0=-104.333333333333 +k=0.999909090909091 +x_0=165000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103490, '+proj=tmerc +lat_0=31 +lon_0=-106.25 +k=0.9999 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103491, '+proj=tmerc +lat_0=31 +lon_0=-107.833333333333 +k=0.999916666666667 +x_0=830000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103492, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103493, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103494, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103495, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=40.6666666666667 +lat_2=41.0333333333333 +x_0=300000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103496, '+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103497, '+proj=tmerc +lat_0=40 +lon_0=-76.5833333333333 +k=0.9999375 +x_0=250000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103498, '+proj=tmerc +lat_0=40 +lon_0=-78.5833333333333 +k=0.9999375 +x_0=350000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103499, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-74 +lat_1=40.6666666666667 +lat_2=41.0333333333333 +x_0=300000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103500, '+proj=lcc +lat_0=33.75 +lon_0=-79 +lat_1=34.3333333333333 +lat_2=36.1666666666667 +x_0=609601.219202438 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103501, '+proj=lcc +lat_0=33.75 +lon_0=-79 +lat_1=34.3333333333333 +lat_2=36.1666666666667 +x_0=609601.219202438 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103502, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=47.4333333333333 +lat_2=48.7333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103503, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=46.1833333333333 +lat_2=47.4833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103504, '+proj=lcc +lat_0=47 +lon_0=-100.5 +lat_1=47.4333333333333 +lat_2=48.7333333333333 +x_0=599999.999999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103505, '+proj=lcc +lat_0=45.6666666666667 +lon_0=-100.5 +lat_1=46.1833333333333 +lat_2=47.4833333333333 +x_0=599999.999999999 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103506, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=40.4333333333333 +lat_2=41.7 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103507, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=38.7333333333333 +lat_2=40.0333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103508, '+proj=lcc +lat_0=39.6666666666667 +lon_0=-82.5 +lat_1=40.4333333333333 +lat_2=41.7 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103509, '+proj=lcc +lat_0=38 +lon_0=-82.5 +lat_1=38.7333333333333 +lat_2=40.0333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103510, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=35.5666666666667 +lat_2=36.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103511, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=33.9333333333333 +lat_2=35.2333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103512, '+proj=lcc +lat_0=35 +lon_0=-98 +lat_1=35.5666666666667 +lat_2=36.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103513, '+proj=lcc +lat_0=33.3333333333333 +lon_0=-98 +lat_1=33.9333333333333 +lat_2=35.2333333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103514, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=40.8833333333333 +lat_2=41.95 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103515, '+proj=lcc +lat_0=40.1666666666667 +lon_0=-77.75 +lat_1=40.8833333333333 +lat_2=41.95 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103516, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=39.9333333333333 +lat_2=40.9666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103517, '+proj=lcc +lat_0=39.3333333333333 +lon_0=-77.75 +lat_1=39.9333333333333 +lat_2=40.9666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103518, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103519, '+proj=tmerc +lat_0=41.0833333333333 +lon_0=-71.5 +k=0.99999375 +x_0=100000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103520, '+proj=lcc +lat_0=31.8333333333333 +lon_0=-81 +lat_1=32.5 +lat_2=34.8333333333333 +x_0=609600 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103521, '+proj=lcc +lat_0=31.8333333333333 +lon_0=-81 +lat_1=32.5 +lat_2=34.8333333333333 +x_0=609600 +y_0=0 +ellps=GRS80 +units=ft +no_defs'],
    [103522, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=44.4166666666667 +lat_2=45.6833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103523, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=42.8333333333333 +lat_2=44.4 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103524, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-100 +lat_1=44.4166666666667 +lat_2=45.6833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103525, '+proj=lcc +lat_0=42.3333333333333 +lon_0=-100.333333333333 +lat_1=42.8333333333333 +lat_2=44.4 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103526, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=35.25 +lat_2=36.4166666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103527, '+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=35.25 +lat_2=36.4166666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103528, '+proj=utm +zone=28 +ellps=WGS84 +units=m +no_defs'],
    [103529, '+proj=utm +zone=29 +ellps=WGS84 +units=m +no_defs'],
    [103530, '+proj=utm +zone=30 +ellps=WGS84 +units=m +no_defs'],
    [103531, '+proj=utm +zone=31 +ellps=WGS84 +units=m +no_defs'],
    [103532, '+proj=utm +zone=32 +ellps=WGS84 +units=m +no_defs'],
    [103533, '+proj=utm +zone=33 +ellps=WGS84 +units=m +no_defs'],
    [103534, '+proj=utm +zone=34 +ellps=WGS84 +units=m +no_defs'],
    [103535, '+proj=utm +zone=35 +ellps=WGS84 +units=m +no_defs'],
    [103536, '+proj=utm +zone=36 +ellps=WGS84 +units=m +no_defs'],
    [103537, '+proj=utm +zone=37 +ellps=WGS84 +units=m +no_defs'],
    [103538, '+proj=utm +zone=38 +ellps=WGS84 +units=m +no_defs'],
    [103539, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=34.65 +lat_2=36.1833333333333 +x_0=200000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103540, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=32.1333333333333 +lat_2=33.9666666666667 +x_0=600000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [103541, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=30.1166666666667 +lat_2=31.8833333333333 +x_0=700000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [103542, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=28.3833333333333 +lat_2=30.2833333333333 +x_0=600000 +y_0=4000000 +ellps=GRS80 +units=m +no_defs'],
    [103543, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=26.1666666666667 +lat_2=27.8333333333333 +x_0=300000 +y_0=5000000 +ellps=GRS80 +units=m +no_defs'],
    [103544, '+proj=lcc +lat_0=34 +lon_0=-101.5 +lat_1=34.65 +lat_2=36.1833333333333 +x_0=200000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103545, '+proj=lcc +lat_0=31.6666666666667 +lon_0=-98.5 +lat_1=32.1333333333333 +lat_2=33.9666666666667 +x_0=600000 +y_0=2000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103546, '+proj=lcc +lat_0=29.6666666666667 +lon_0=-100.333333333333 +lat_1=30.1166666666667 +lat_2=31.8833333333333 +x_0=699999.999999999 +y_0=3000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103547, '+proj=lcc +lat_0=27.8333333333333 +lon_0=-99 +lat_1=28.3833333333333 +lat_2=30.2833333333333 +x_0=600000 +y_0=3999999.99999999 +ellps=GRS80 +units=us-ft +no_defs'],
    [103548, '+proj=lcc +lat_0=25.6666666666667 +lon_0=-98.5 +lat_1=26.1666666666667 +lat_2=27.8333333333333 +x_0=300000 +y_0=5000000.00000001 +ellps=GRS80 +units=us-ft +no_defs'],
    [103549, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103550, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [103551, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=m +no_defs'],
    [103552, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=1000000 +ellps=GRS80 +units=ft +no_defs'],
    [103553, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=ft +no_defs'],
    [103554, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=ft +no_defs'],
    [103555, '+proj=lcc +lat_0=40.3333333333333 +lon_0=-111.5 +lat_1=40.7166666666667 +lat_2=41.7833333333333 +x_0=500000 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103556, '+proj=lcc +lat_0=38.3333333333333 +lon_0=-111.5 +lat_1=39.0166666666667 +lat_2=40.65 +x_0=500000 +y_0=2000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103557, '+proj=lcc +lat_0=36.6666666666667 +lon_0=-111.5 +lat_1=37.2166666666667 +lat_2=38.35 +x_0=500000 +y_0=3000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103558, '+proj=tmerc +lat_0=42.5 +lon_0=-72.5 +k=0.999964285714286 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103559, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=38.0333333333333 +lat_2=39.2 +x_0=3500000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs'],
    [103560, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=36.7666666666667 +lat_2=37.9666666666667 +x_0=3500000 +y_0=1000000 +ellps=GRS80 +units=m +no_defs'],
    [103561, '+proj=lcc +lat_0=37.6666666666667 +lon_0=-78.5 +lat_1=38.0333333333333 +lat_2=39.2 +x_0=3500000.00000001 +y_0=2000000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103562, '+proj=lcc +lat_0=36.3333333333333 +lon_0=-78.5 +lat_1=36.7666666666667 +lat_2=37.9666666666667 +x_0=3500000.00000001 +y_0=999999.999999998 +ellps=GRS80 +units=us-ft +no_defs'],
    [103563, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103564, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=45.8333333333333 +lat_2=47.3333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103565, '+proj=lcc +lat_0=47 +lon_0=-120.833333333333 +lat_1=47.5 +lat_2=48.7333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103566, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-120.5 +lat_1=45.8333333333333 +lat_2=47.3333333333333 +x_0=500000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103567, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=39 +lat_2=40.25 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103568, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=37.4833333333333 +lat_2=38.8833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103569, '+proj=lcc +lat_0=38.5 +lon_0=-79.5 +lat_1=39 +lat_2=40.25 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103570, '+proj=lcc +lat_0=37 +lon_0=-81 +lat_1=37.4833333333333 +lat_2=38.8833333333333 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103571, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=45.5666666666667 +lat_2=46.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103572, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=44.25 +lat_2=45.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103573, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=42.7333333333333 +lat_2=44.0666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103574, '+proj=lcc +lat_0=45.1666666666667 +lon_0=-90 +lat_1=45.5666666666667 +lat_2=46.7666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103575, '+proj=lcc +lat_0=43.8333333333333 +lon_0=-90 +lat_1=44.25 +lat_2=45.5 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103576, '+proj=lcc +lat_0=42 +lon_0=-90 +lat_1=42.7333333333333 +lat_2=44.0666666666667 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103577, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103578, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=400000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103579, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=m +no_defs'],
    [103580, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=m +no_defs'],
    [103581, '+proj=tmerc +lat_0=40.5 +lon_0=-105.166666666667 +k=0.9999375 +x_0=200000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103582, '+proj=tmerc +lat_0=40.5 +lon_0=-107.333333333333 +k=0.9999375 +x_0=399999.999999999 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103583, '+proj=tmerc +lat_0=40.5 +lon_0=-108.75 +k=0.9999375 +x_0=600000 +y_0=0 +ellps=GRS80 +units=us-ft +no_defs'],
    [103584, '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=0 +ellps=WGS84 +units=m +no_defs'],
    [103585, '+proj=tmerc +lat_0=40.5 +lon_0=-110.083333333333 +k=0.9999375 +x_0=800000 +y_0=100000 +ellps=GRS80 +units=us-ft +no_defs'],
    [103600, '+proj=tmerc +lat_0=46.1541666666667 +lon_0=-93.4325 +k=1.000059152669 +x_0=152409.319685395 +y_0=30481.863937079 +ellps=GRS80 +units=m +no_defs'],
    [103601, '+proj=tmerc +lat_0=46.63 +lon_0=-96.7 +k=1.000045317862 +x_0=152407.211256591 +y_0=30481.4422513183 +ellps=GRS80 +units=m +no_defs'],
    [103602, '+proj=tmerc +lat_0=47.1516666666667 +lon_0=-95.3758333333333 +k=1.000072505661 +x_0=152411.354685446 +y_0=30482.2709370892 +ellps=GRS80 +units=m +no_defs'],
    [103603, '+proj=tmerc +lat_0=46.8036111111111 +lon_0=-94.9205555555556 +k=1.000071553661 +x_0=152411.209600356 +y_0=30482.2419200711 +ellps=GRS80 +units=m +no_defs'],
    [103604, '+proj=tmerc +lat_0=47.0666666666667 +lon_0=-91.4091666666667 +k=1.000075844621 +x_0=152411.863543968 +y_0=30482.3727087935 +ellps=GRS80 +units=m +no_defs'],
    [103605, '+proj=tmerc +lat_0=45.5588888888889 +lon_0=-93.6205555555556 +k=1.000054146138 +x_0=152408.556688545 +y_0=30481.7113377089 +ellps=GRS80 +units=m +no_defs'],
    [103606, '+proj=tmerc +lat_0=44.7458333333333 +lon_0=-92.8333333333333 +k=1.000039836799 +x_0=152406.37594092 +y_0=30481.2751881839 +ellps=GRS80 +units=m +no_defs'],
    [103607, '+proj=tmerc +lat_0=46.0216666666667 +lon_0=-96.5244444444444 +k=1.000048901066 +x_0=152407.757337973 +y_0=30481.5514675946 +ellps=GRS80 +units=m +no_defs'],
    [103608, '+proj=lcc +lat_0=45.0352777777778 +lon_0=-93.2666666666667 +lat_1=45.0666666666667 +lat_2=45.3666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378418.941 +rf=298.257222100883 +units=m +no_defs'],
    [103609, '+proj=lcc +lat_0=46.7177777777778 +lon_0=-95.6833333333333 +lat_1=46.7833333333333 +lat_2=47.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378586.581 +rf=298.257222100883 +units=m +no_defs'],
    [103610, '+proj=lcc +lat_0=48.02 +lon_0=-95.0166666666667 +lat_1=48.1166666666667 +lat_2=48.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378505.809 +rf=298.257222100883 +units=m +no_defs'],
    [103611, '+proj=lcc +lat_0=47.4125 +lon_0=-94.85 +lat_1=47.5 +lat_2=47.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378544.823 +rf=298.257222100883 +units=m +no_defs'],
    [103612, '+proj=lcc +lat_0=45.5591666666667 +lon_0=-94.05 +lat_1=45.5833333333333 +lat_2=45.7833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378490.569 +rf=298.257222100883 +units=m +no_defs'],
    [103613, '+proj=lcc +lat_0=45.1522222222222 +lon_0=-96.05 +lat_1=45.2166666666667 +lat_2=45.5333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378470.757 +rf=298.257222100883 +units=m +no_defs'],
    [103614, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.2666666666667 +lat_1=43.9333333333333 +lat_2=44.3666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378403.701 +rf=298.257222100883 +units=m +no_defs'],
    [103615, '+proj=lcc +lat_0=44.1080555555556 +lon_0=-94.7333333333333 +lat_1=44.1666666666667 +lat_2=44.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=m +no_defs'],
    [103616, '+proj=lcc +lat_0=46.4172222222222 +lon_0=-92.6833333333333 +lat_1=46.4666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378454.907 +rf=298.257222100883 +units=m +no_defs'],
    [103617, '+proj=lcc +lat_0=44.6397222222222 +lon_0=-93.7666666666667 +lat_1=44.6833333333333 +lat_2=44.9 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378400.653 +rf=298.257222100883 +units=m +no_defs'],
    [103618, '+proj=lcc +lat_0=46.8036111111111 +lon_0=-94.2166666666667 +lat_1=46.9166666666667 +lat_2=47.3166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378567.378 +rf=298.257222100883 +units=m +no_defs'],
    [103619, '+proj=lcc +lat_0=46.1563888888889 +lon_0=-94.4666666666667 +lat_1=46.2666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378546.957 +rf=298.257222100883 +units=m +no_defs'],
    [103620, '+proj=lcc +lat_0=44.7527777777778 +lon_0=-95.85 +lat_1=44.8333333333333 +lat_2=45.2 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378476.853 +rf=298.257222100883 +units=m +no_defs'],
    [103621, '+proj=lcc +lat_0=45.2963888888889 +lon_0=-93.0833333333333 +lat_1=45.3333333333333 +lat_2=45.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378411.321 +rf=298.257222100883 +units=m +no_defs'],
    [103622, '+proj=lcc +lat_0=47.8833333333333 +lon_0=-90.25 +lat_1=47.9333333333333 +lat_2=48.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378647.541 +rf=298.257222100883 +units=m +no_defs'],
    [103623, '+proj=lcc +lat_0=47.4388888888889 +lon_0=-90.25 +lat_1=47.55 +lat_2=47.8166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378647.541 +rf=298.257222100883 +units=m +no_defs'],
    [103624, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.9166666666667 +lat_1=43.9 +lat_2=44.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378514.953 +rf=298.257222100883 +units=m +no_defs'],
    [103625, '+proj=lcc +lat_0=46.1563888888889 +lon_0=-94.4666666666667 +lat_1=46.2666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378546.957 +rf=298.257222100883 +units=m +no_defs'],
    [103626, '+proj=lcc +lat_0=44.4719444444445 +lon_0=-93.3166666666667 +lat_1=44.5166666666667 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378421.989 +rf=298.257222100883 +units=m +no_defs'],
    [103627, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=m +no_defs'],
    [103628, '+proj=lcc +lat_0=45.7588888888889 +lon_0=-96.05 +lat_1=45.8 +lat_2=46.05 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378518.001 +rf=298.257222100883 +units=m +no_defs'],
    [103629, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=m +no_defs'],
    [103630, '+proj=lcc +lat_0=43.5 +lon_0=-92.0833333333333 +lat_1=43.55 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378464.661 +rf=298.257222100883 +units=m +no_defs'],
    [103631, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=m +no_defs'],
    [103632, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-93.1333333333333 +lat_1=44.3 +lat_2=44.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=m +no_defs'],
    [103633, '+proj=lcc +lat_0=45.7588888888889 +lon_0=-96.05 +lat_1=45.8 +lat_2=46.05 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378518.001 +rf=298.257222100883 +units=m +no_defs'],
    [103634, '+proj=lcc +lat_0=44.7911111111111 +lon_0=-93.3833333333333 +lat_1=44.8833333333333 +lat_2=45.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378418.941 +rf=298.257222100883 +units=m +no_defs'],
    [103635, '+proj=lcc +lat_0=43.5 +lon_0=-91.4666666666667 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378436.619 +rf=298.257222100883 +units=m +no_defs'],
    [103636, '+proj=lcc +lat_0=45.2963888888889 +lon_0=-93.0833333333333 +lat_1=45.3333333333333 +lat_2=45.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378411.321 +rf=298.257222100883 +units=m +no_defs'],
    [103637, '+proj=lcc +lat_0=47.5 +lon_0=-93.7333333333333 +lat_1=47.5666666666667 +lat_2=47.8166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378574.389 +rf=298.257222100883 +units=m +no_defs'],
    [103638, '+proj=lcc +lat_0=47.0263888888889 +lon_0=-93.7333333333333 +lat_1=47.0833333333333 +lat_2=47.4166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378574.389 +rf=298.257222100883 +units=m +no_defs'],
    [103639, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=m +no_defs'],
    [103640, '+proj=lcc +lat_0=45.73 +lon_0=-92.9 +lat_1=45.8166666666667 +lat_2=46.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378472.281 +rf=298.257222100883 +units=m +no_defs'],
    [103641, '+proj=lcc +lat_0=44.8913888888889 +lon_0=-94.75 +lat_1=44.9666666666667 +lat_2=45.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378498.189 +rf=298.257222100883 +units=m +no_defs'],
    [103642, '+proj=lcc +lat_0=48.5438888888889 +lon_0=-96.15 +lat_1=48.6 +lat_2=48.9333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378449.421 +rf=298.257222100883 +units=m +no_defs'],
    [103643, '+proj=lcc +lat_0=47.8458333333333 +lon_0=-93.75 +lat_1=48 +lat_2=48.6166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378525.621 +rf=298.257222100883 +units=m +no_defs'],
    [103644, '+proj=lcc +lat_0=44.7527777777778 +lon_0=-95.85 +lat_1=44.8333333333333 +lat_2=45.2 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378476.853 +rf=298.257222100883 +units=m +no_defs'],
    [103645, '+proj=lcc +lat_0=49.15 +lon_0=-94.9833333333333 +lat_1=49.1833333333333 +lat_2=49.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378466.185 +rf=298.257222100883 +units=m +no_defs'],
    [103646, '+proj=lcc +lat_0=48.3661111111111 +lon_0=-94.8833333333333 +lat_1=48.45 +lat_2=48.8833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378496.665 +rf=298.257222100883 +units=m +no_defs'],
    [103647, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-93.1333333333333 +lat_1=44.3 +lat_2=44.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=m +no_defs'],
    [103648, '+proj=lcc +lat_0=44.1966666666667 +lon_0=-96.2666666666667 +lat_1=44.2833333333333 +lat_2=44.6166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378643.579 +rf=298.257222100883 +units=m +no_defs'],
    [103649, '+proj=lcc +lat_0=44.1955555555556 +lon_0=-95.85 +lat_1=44.25 +lat_2=44.5833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378559.758 +rf=298.257222100883 +units=m +no_defs'],
    [103650, '+proj=lcc +lat_0=44.4561111111111 +lon_0=-94.6333333333333 +lat_1=44.5333333333333 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378414.369 +rf=298.257222100883 +units=m +no_defs'],
    [103651, '+proj=lcc +lat_0=47.1516666666667 +lon_0=-95.8166666666667 +lat_1=47.2 +lat_2=47.45 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378586.581 +rf=298.257222100883 +units=m +no_defs'],
    [103652, '+proj=lcc +lat_0=48.1730555555555 +lon_0=-96.3833333333333 +lat_1=48.2333333333333 +lat_2=48.4833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378441.801 +rf=298.257222100883 +units=m +no_defs'],
    [103653, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=m +no_defs'],
    [103654, '+proj=lcc +lat_0=44.8913888888889 +lon_0=-94.75 +lat_1=44.9666666666667 +lat_2=45.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378498.189 +rf=298.257222100883 +units=m +no_defs'],
    [103655, '+proj=lcc +lat_0=45.7738888888889 +lon_0=-94.2 +lat_1=45.85 +lat_2=46.2666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=m +no_defs'],
    [103656, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=m +no_defs'],
    [103657, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-95.7666666666667 +lat_1=43.9166666666667 +lat_2=44.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378617.061 +rf=298.257222100883 +units=m +no_defs'],
    [103658, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.2666666666667 +lat_1=43.9333333333333 +lat_2=44.3666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378403.701 +rf=298.257222100883 +units=m +no_defs'],
    [103659, '+proj=lcc +lat_0=43.5 +lon_0=-95.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378624.681 +rf=298.257222100883 +units=m +no_defs'],
    [103660, '+proj=lcc +lat_0=47.1505555555556 +lon_0=-96.45 +lat_1=47.2 +lat_2=47.45 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378468.623 +rf=298.257222100883 +units=m +no_defs'],
    [103661, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=m +no_defs'],
    [103662, '+proj=lcc +lat_0=46.1063888888889 +lon_0=-95.7166666666667 +lat_1=46.1833333333333 +lat_2=46.65 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378525.621 +rf=298.257222100883 +units=m +no_defs'],
    [103663, '+proj=lcc +lat_0=47.4988888888889 +lon_0=-96.3666666666667 +lat_1=47.6 +lat_2=48.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378445.763 +rf=298.257222100883 +units=m +no_defs'],
    [103664, '+proj=lcc +lat_0=45.73 +lon_0=-92.9 +lat_1=45.8166666666667 +lat_2=46.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378472.281 +rf=298.257222100883 +units=m +no_defs'],
    [103665, '+proj=lcc +lat_0=43.8491666666667 +lon_0=-96.25 +lat_1=43.8833333333333 +lat_2=44.15 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378670.401 +rf=298.257222100883 +units=m +no_defs'],
    [103666, '+proj=lcc +lat_0=47.4988888888889 +lon_0=-96.3666666666667 +lat_1=47.6 +lat_2=48.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378445.763 +rf=298.257222100883 +units=m +no_defs'],
    [103667, '+proj=lcc +lat_0=45.2827777777778 +lon_0=-95.15 +lat_1=45.35 +lat_2=45.7 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=m +no_defs'],
    [103668, '+proj=lcc +lat_0=44.7911111111111 +lon_0=-93.3833333333333 +lat_1=44.8833333333333 +lat_2=45.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378418.941 +rf=298.257222100883 +units=m +no_defs'],
    [103669, '+proj=lcc +lat_0=47.4988888888889 +lon_0=-96.3666666666667 +lat_1=47.6 +lat_2=48.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378445.763 +rf=298.257222100883 +units=m +no_defs'],
    [103670, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-95.2333333333333 +lat_1=44.2666666666667 +lat_2=44.5666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378438.753 +rf=298.257222100883 +units=m +no_defs'],
    [103671, '+proj=lcc +lat_0=44.4561111111111 +lon_0=-94.6333333333333 +lat_1=44.5333333333333 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378414.369 +rf=298.257222100883 +units=m +no_defs'],
    [103672, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-93.1333333333333 +lat_1=44.3 +lat_2=44.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=m +no_defs'],
    [103673, '+proj=lcc +lat_0=43.5 +lon_0=-95.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378624.681 +rf=298.257222100883 +units=m +no_defs'],
    [103674, '+proj=lcc +lat_0=48.5438888888889 +lon_0=-96.15 +lat_1=48.6 +lat_2=48.9333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378449.421 +rf=298.257222100883 +units=m +no_defs'],
    [103675, '+proj=lcc +lat_0=47.8333333333333 +lon_0=-92.45 +lat_1=47.9833333333333 +lat_2=48.5333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378543.909 +rf=298.257222100883 +units=m +no_defs'],
    [103676, '+proj=lcc +lat_0=47.25 +lon_0=-92.45 +lat_1=47.3333333333333 +lat_2=47.75 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378605.783 +rf=298.257222100883 +units=m +no_defs'],
    [103677, '+proj=lcc +lat_0=46.65 +lon_0=-92.45 +lat_1=46.7833333333333 +lat_2=47.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378540.861 +rf=298.257222100883 +units=m +no_defs'],
    [103678, '+proj=lcc +lat_0=44.4719444444445 +lon_0=-93.3166666666667 +lat_1=44.5166666666667 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378421.989 +rf=298.257222100883 +units=m +no_defs'],
    [103679, '+proj=lcc +lat_0=44.9775 +lon_0=-93.8833333333333 +lat_1=45.0333333333333 +lat_2=45.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378443.325 +rf=298.257222100883 +units=m +no_defs'],
    [103680, '+proj=lcc +lat_0=44.4561111111111 +lon_0=-94.6333333333333 +lat_1=44.5333333333333 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378414.369 +rf=298.257222100883 +units=m +no_defs'],
    [103681, '+proj=lcc +lat_0=45.2827777777778 +lon_0=-95.15 +lat_1=45.35 +lat_2=45.7 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=m +no_defs'],
    [103682, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=m +no_defs'],
    [103683, '+proj=lcc +lat_0=45.2827777777778 +lon_0=-95.15 +lat_1=45.35 +lat_2=45.7 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=m +no_defs'],
    [103684, '+proj=lcc +lat_0=45.1522222222222 +lon_0=-96.05 +lat_1=45.2166666666667 +lat_2=45.5333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378470.757 +rf=298.257222100883 +units=m +no_defs'],
    [103685, '+proj=lcc +lat_0=45.7733333333333 +lon_0=-94.9 +lat_1=45.8666666666667 +lat_2=46.2833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378548.481 +rf=298.257222100883 +units=m +no_defs'],
    [103686, '+proj=lcc +lat_0=45.5855555555556 +lon_0=-96.55 +lat_1=45.6333333333333 +lat_2=45.9666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378463.746 +rf=298.257222100883 +units=m +no_defs'],
    [103687, '+proj=lcc +lat_0=44.1069444444444 +lon_0=-92.2666666666667 +lat_1=44.15 +lat_2=44.4166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378426.561 +rf=298.257222100883 +units=m +no_defs'],
    [103688, '+proj=lcc +lat_0=46.1563888888889 +lon_0=-94.4666666666667 +lat_1=46.2666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378546.957 +rf=298.257222100883 +units=m +no_defs'],
    [103689, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=m +no_defs'],
    [103690, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.9166666666667 +lat_1=43.9 +lat_2=44.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378514.953 +rf=298.257222100883 +units=m +no_defs'],
    [103691, '+proj=lcc +lat_0=43.8472222222222 +lon_0=-91.6166666666667 +lat_1=43.9 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378453.688 +rf=298.257222100883 +units=m +no_defs'],
    [103692, '+proj=lcc +lat_0=44.9775 +lon_0=-93.8833333333333 +lat_1=45.0333333333333 +lat_2=45.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378443.325 +rf=298.257222100883 +units=m +no_defs'],
    [103693, '+proj=lcc +lat_0=44.5416666666667 +lon_0=-95.9 +lat_1=44.6666666666667 +lat_2=44.95 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378530.193 +rf=298.257222100883 +units=m +no_defs'],
    [103694, '+proj=tmerc +lat_0=46.6166666666667 +lon_0=-92.45 +k=0.99998529 +x_0=1450000 +y_0=1000000 +a=6378523 +rf=298.2752724 +units=m +no_defs'],
    [103695, '+proj=tmerc +lat_0=46.6166666666667 +lon_0=-92.45 +k=0.99998529 +x_0=1449999.9999999 +y_0=999999.999999898 +a=6378523 +rf=298.2752724 +units=us-ft +no_defs'],
    [103700, '+proj=tmerc +lat_0=46.1541666666667 +lon_0=-93.4325 +k=1.000059152669 +x_0=152409.319685395 +y_0=30481.863937079 +ellps=GRS80 +units=us-ft +no_defs'],
    [103701, '+proj=tmerc +lat_0=46.63 +lon_0=-96.7 +k=1.000045317862 +x_0=152407.211256591 +y_0=30481.4422513182 +ellps=GRS80 +units=us-ft +no_defs'],
    [103702, '+proj=tmerc +lat_0=47.1516666666667 +lon_0=-95.3758333333333 +k=1.000072505661 +x_0=152411.354685446 +y_0=30482.2709370891 +ellps=GRS80 +units=us-ft +no_defs'],
    [103703, '+proj=tmerc +lat_0=46.8036111111111 +lon_0=-94.9205555555556 +k=1.000071553661 +x_0=152411.209600356 +y_0=30482.2419200711 +ellps=GRS80 +units=us-ft +no_defs'],
    [103704, '+proj=tmerc +lat_0=47.0666666666667 +lon_0=-91.4091666666667 +k=1.000075844621 +x_0=152411.863543967 +y_0=30482.3727087935 +ellps=GRS80 +units=us-ft +no_defs'],
    [103705, '+proj=tmerc +lat_0=45.5588888888889 +lon_0=-93.6205555555556 +k=1.000054146138 +x_0=152408.556688544 +y_0=30481.7113377089 +ellps=GRS80 +units=us-ft +no_defs'],
    [103706, '+proj=tmerc +lat_0=44.7458333333333 +lon_0=-92.8333333333333 +k=1.000039836799 +x_0=152406.375940919 +y_0=30481.2751881839 +ellps=GRS80 +units=us-ft +no_defs'],
    [103707, '+proj=tmerc +lat_0=46.0216666666667 +lon_0=-96.5244444444444 +k=1.000048901066 +x_0=152407.757337973 +y_0=30481.5514675946 +ellps=GRS80 +units=us-ft +no_defs'],
    [103708, '+proj=lcc +lat_0=45.0352777777778 +lon_0=-93.2666666666667 +lat_1=45.0666666666667 +lat_2=45.3666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378418.941 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103709, '+proj=lcc +lat_0=46.7177777777778 +lon_0=-95.6833333333333 +lat_1=46.7833333333333 +lat_2=47.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378586.581 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103710, '+proj=lcc +lat_0=48.02 +lon_0=-95.0166666666667 +lat_1=48.1166666666667 +lat_2=48.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378505.809 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103711, '+proj=lcc +lat_0=47.4125 +lon_0=-94.85 +lat_1=47.5 +lat_2=47.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378544.823 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103712, '+proj=lcc +lat_0=45.5591666666667 +lon_0=-94.05 +lat_1=45.5833333333333 +lat_2=45.7833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378490.569 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103713, '+proj=lcc +lat_0=45.1522222222222 +lon_0=-96.05 +lat_1=45.2166666666667 +lat_2=45.5333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378470.757 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103714, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.2666666666667 +lat_1=43.9333333333333 +lat_2=44.3666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378403.701 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103715, '+proj=lcc +lat_0=44.1080555555556 +lon_0=-94.7333333333333 +lat_1=44.1666666666667 +lat_2=44.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103716, '+proj=lcc +lat_0=46.4172222222222 +lon_0=-92.6833333333333 +lat_1=46.4666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378454.907 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103717, '+proj=lcc +lat_0=44.6397222222222 +lon_0=-93.7666666666667 +lat_1=44.6833333333333 +lat_2=44.9 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378400.653 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103718, '+proj=lcc +lat_0=46.8036111111111 +lon_0=-94.2166666666667 +lat_1=46.9166666666667 +lat_2=47.3166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378567.378 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103719, '+proj=lcc +lat_0=46.1563888888889 +lon_0=-94.4666666666667 +lat_1=46.2666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378546.957 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103720, '+proj=lcc +lat_0=44.7527777777778 +lon_0=-95.85 +lat_1=44.8333333333333 +lat_2=45.2 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378476.853 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103721, '+proj=lcc +lat_0=45.2963888888889 +lon_0=-93.0833333333333 +lat_1=45.3333333333333 +lat_2=45.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378411.321 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103722, '+proj=lcc +lat_0=47.8833333333333 +lon_0=-90.25 +lat_1=47.9333333333333 +lat_2=48.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378647.541 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103723, '+proj=lcc +lat_0=47.4388888888889 +lon_0=-90.25 +lat_1=47.55 +lat_2=47.8166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378647.541 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103724, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.9166666666667 +lat_1=43.9 +lat_2=44.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378514.953 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103725, '+proj=lcc +lat_0=46.1563888888889 +lon_0=-94.4666666666667 +lat_1=46.2666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378546.957 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103726, '+proj=lcc +lat_0=44.4719444444445 +lon_0=-93.3166666666667 +lat_1=44.5166666666667 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378421.989 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103727, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103728, '+proj=lcc +lat_0=45.7588888888889 +lon_0=-96.05 +lat_1=45.8 +lat_2=46.05 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378518.001 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103729, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103730, '+proj=lcc +lat_0=43.5 +lon_0=-92.0833333333333 +lat_1=43.55 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378464.661 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103731, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103732, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-93.1333333333333 +lat_1=44.3 +lat_2=44.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103733, '+proj=lcc +lat_0=45.7588888888889 +lon_0=-96.05 +lat_1=45.8 +lat_2=46.05 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378518.001 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103734, '+proj=lcc +lat_0=44.7911111111111 +lon_0=-93.3833333333333 +lat_1=44.8833333333333 +lat_2=45.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378418.941 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103735, '+proj=lcc +lat_0=43.5 +lon_0=-91.4666666666667 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378436.619 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103736, '+proj=lcc +lat_0=45.2963888888889 +lon_0=-93.0833333333333 +lat_1=45.3333333333333 +lat_2=45.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378411.321 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103737, '+proj=lcc +lat_0=47.5 +lon_0=-93.7333333333333 +lat_1=47.5666666666667 +lat_2=47.8166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378574.389 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103738, '+proj=lcc +lat_0=47.0263888888889 +lon_0=-93.7333333333333 +lat_1=47.0833333333333 +lat_2=47.4166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378574.389 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103739, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103740, '+proj=lcc +lat_0=45.73 +lon_0=-92.9 +lat_1=45.8166666666667 +lat_2=46.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378472.281 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103741, '+proj=lcc +lat_0=44.8913888888889 +lon_0=-94.75 +lat_1=44.9666666666667 +lat_2=45.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378498.189 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103742, '+proj=lcc +lat_0=48.5438888888889 +lon_0=-96.15 +lat_1=48.6 +lat_2=48.9333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378449.421 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103743, '+proj=lcc +lat_0=47.8458333333333 +lon_0=-93.75 +lat_1=48 +lat_2=48.6166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378525.621 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103744, '+proj=lcc +lat_0=44.7527777777778 +lon_0=-95.85 +lat_1=44.8333333333333 +lat_2=45.2 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378476.853 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103745, '+proj=lcc +lat_0=49.15 +lon_0=-94.9833333333333 +lat_1=49.1833333333333 +lat_2=49.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378466.185 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103746, '+proj=lcc +lat_0=48.3661111111111 +lon_0=-94.8833333333333 +lat_1=48.45 +lat_2=48.8833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378496.665 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103747, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-93.1333333333333 +lat_1=44.3 +lat_2=44.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103748, '+proj=lcc +lat_0=44.1966666666667 +lon_0=-96.2666666666667 +lat_1=44.2833333333333 +lat_2=44.6166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378643.579 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103749, '+proj=lcc +lat_0=44.1955555555556 +lon_0=-95.85 +lat_1=44.25 +lat_2=44.5833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378559.758 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103750, '+proj=lcc +lat_0=44.4561111111111 +lon_0=-94.6333333333333 +lat_1=44.5333333333333 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378414.369 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103751, '+proj=lcc +lat_0=47.1516666666667 +lon_0=-95.8166666666667 +lat_1=47.2 +lat_2=47.45 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378586.581 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103752, '+proj=lcc +lat_0=48.1730555555555 +lon_0=-96.3833333333333 +lat_1=48.2333333333333 +lat_2=48.4833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378441.801 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103753, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103754, '+proj=lcc +lat_0=44.8913888888889 +lon_0=-94.75 +lat_1=44.9666666666667 +lat_2=45.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378498.189 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103755, '+proj=lcc +lat_0=45.7738888888889 +lon_0=-94.2 +lat_1=45.85 +lat_2=46.2666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103756, '+proj=lcc +lat_0=43.5 +lon_0=-93.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378521.049 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103757, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-95.7666666666667 +lat_1=43.9166666666667 +lat_2=44.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378617.061 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103758, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.2666666666667 +lat_1=43.9333333333333 +lat_2=44.3666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378403.701 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103759, '+proj=lcc +lat_0=43.5 +lon_0=-95.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378624.681 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103760, '+proj=lcc +lat_0=47.1505555555556 +lon_0=-96.45 +lat_1=47.2 +lat_2=47.45 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378468.623 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103761, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103762, '+proj=lcc +lat_0=46.1063888888889 +lon_0=-95.7166666666667 +lat_1=46.1833333333333 +lat_2=46.65 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378525.621 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103763, '+proj=lcc +lat_0=47.4988888888889 +lon_0=-96.3666666666667 +lat_1=47.6 +lat_2=48.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378445.763 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103764, '+proj=lcc +lat_0=45.73 +lon_0=-92.9 +lat_1=45.8166666666667 +lat_2=46.3333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378472.281 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103765, '+proj=lcc +lat_0=43.8491666666667 +lon_0=-96.25 +lat_1=43.8833333333333 +lat_2=44.15 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378670.401 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103766, '+proj=lcc +lat_0=47.4988888888889 +lon_0=-96.3666666666667 +lat_1=47.6 +lat_2=48.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378445.763 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103767, '+proj=lcc +lat_0=45.2827777777778 +lon_0=-95.15 +lat_1=45.35 +lat_2=45.7 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103768, '+proj=lcc +lat_0=44.7911111111111 +lon_0=-93.3833333333333 +lat_1=44.8833333333333 +lat_2=45.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378418.941 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103769, '+proj=lcc +lat_0=47.4988888888889 +lon_0=-96.3666666666667 +lat_1=47.6 +lat_2=48.0833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378445.763 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103770, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-95.2333333333333 +lat_1=44.2666666666667 +lat_2=44.5666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378438.753 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103771, '+proj=lcc +lat_0=44.4561111111111 +lon_0=-94.6333333333333 +lat_1=44.5333333333333 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378414.369 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103772, '+proj=lcc +lat_0=44.1947222222222 +lon_0=-93.1333333333333 +lat_1=44.3 +lat_2=44.6666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378434.181 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103773, '+proj=lcc +lat_0=43.5 +lon_0=-95.95 +lat_1=43.5666666666667 +lat_2=43.8 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378624.681 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103774, '+proj=lcc +lat_0=48.5438888888889 +lon_0=-96.15 +lat_1=48.6 +lat_2=48.9333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378449.421 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103775, '+proj=lcc +lat_0=47.8333333333333 +lon_0=-92.45 +lat_1=47.9833333333333 +lat_2=48.5333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378543.909 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103776, '+proj=lcc +lat_0=47.25 +lon_0=-92.45 +lat_1=47.3333333333333 +lat_2=47.75 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378605.783 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103777, '+proj=lcc +lat_0=46.65 +lon_0=-92.45 +lat_1=46.7833333333333 +lat_2=47.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378540.861 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103778, '+proj=lcc +lat_0=44.4719444444445 +lon_0=-93.3166666666667 +lat_1=44.5166666666667 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378421.989 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103779, '+proj=lcc +lat_0=44.9775 +lon_0=-93.8833333333333 +lat_1=45.0333333333333 +lat_2=45.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378443.325 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103780, '+proj=lcc +lat_0=44.4561111111111 +lon_0=-94.6333333333333 +lat_1=44.5333333333333 +lat_2=44.9166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378414.369 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103781, '+proj=lcc +lat_0=45.2827777777778 +lon_0=-95.15 +lat_1=45.35 +lat_2=45.7 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103782, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103783, '+proj=lcc +lat_0=45.2827777777778 +lon_0=-95.15 +lat_1=45.35 +lat_2=45.7 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378502.761 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103784, '+proj=lcc +lat_0=45.1522222222222 +lon_0=-96.05 +lat_1=45.2166666666667 +lat_2=45.5333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378470.757 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103785, '+proj=lcc +lat_0=45.7733333333333 +lon_0=-94.9 +lat_1=45.8666666666667 +lat_2=46.2833333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378548.481 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103786, '+proj=lcc +lat_0=45.5855555555556 +lon_0=-96.55 +lat_1=45.6333333333333 +lat_2=45.9666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378463.746 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103787, '+proj=lcc +lat_0=44.1069444444444 +lon_0=-92.2666666666667 +lat_1=44.15 +lat_2=44.4166666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378426.561 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103788, '+proj=lcc +lat_0=46.1563888888889 +lon_0=-94.4666666666667 +lat_1=46.2666666666667 +lat_2=46.7333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378546.957 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103789, '+proj=lcc +lat_0=43.8338888888889 +lon_0=-92.9166666666667 +lat_1=43.8833333333333 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378481.425 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103790, '+proj=lcc +lat_0=43.8480555555556 +lon_0=-94.9166666666667 +lat_1=43.9 +lat_2=44.1666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378514.953 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103791, '+proj=lcc +lat_0=43.8472222222222 +lon_0=-91.6166666666667 +lat_1=43.9 +lat_2=44.1333333333333 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378453.688 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103792, '+proj=lcc +lat_0=44.9775 +lon_0=-93.8833333333333 +lat_1=45.0333333333333 +lat_2=45.4666666666667 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378443.325 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103793, '+proj=lcc +lat_0=44.5416666666667 +lon_0=-95.9 +lat_1=44.6666666666667 +lat_2=44.95 +x_0=152400.30480061 +y_0=30480.0609601219 +a=6378530.193 +rf=298.257222100883 +units=us-ft +no_defs'],
    [103794, '+proj=utm +zone=11 +ellps=GRS80 +units=m +no_defs'],
    [103795, '+proj=utm +zone=12 +ellps=GRS80 +units=m +no_defs'],
    [103796, '+proj=utm +zone=13 +ellps=GRS80 +units=m +no_defs'],
    [103797, '+proj=utm +zone=14 +ellps=GRS80 +units=m +no_defs'],
    [103798, '+proj=utm +zone=15 +ellps=GRS80 +units=m +no_defs'],
    [103799, '+proj=utm +zone=16 +ellps=GRS80 +units=m +no_defs'],
    [103800, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=0.999999 +x_0=147218.694437389 +y_0=0 +a=6378376.271 +rf=298.268410995005 +units=m +no_defs'],
    [103801, '+proj=tmerc +lat_0=45.7061111111111 +lon_0=-90.6222222222222 +k=0.999997 +x_0=172821.945643891 +y_0=0 +a=6378471.92 +rf=298.272883775229 +units=m +no_defs'],
    [103802, '+proj=tmerc +lat_0=45.1333333333333 +lon_0=-91.85 +k=0.999996 +x_0=93150 +y_0=0 +a=6378472.931 +rf=298.272931052052 +units=m +no_defs'],
    [103803, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31599.99998984 +y_0=4599.98983997968 +ellps=GRS80 +units=m +no_defs'],
    [103804, '+proj=tmerc +lat_0=43.4813888888889 +lon_0=-91.7972222222222 +k=1 +x_0=175260.350520701 +y_0=0 +a=6378380.991 +rf=298.268631713702 +units=m +no_defs'],
    [103805, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=m +no_defs'],
    [103806, '+proj=tmerc +lat_0=43.6 +lon_0=-90.7083333333333 +k=0.999994 +x_0=199949.1998984 +y_0=0 +a=6378470.401 +rf=298.272812743089 +units=m +no_defs'],
    [103807, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=0.999997 +x_0=263347.726695453 +y_0=0 +a=6378376.811 +rf=298.268436246721 +units=m +no_defs'],
    [103808, '+proj=tmerc +lat_0=44.4 +lon_0=-87.2722222222222 +k=0.999991 +x_0=158801.117602235 +y_0=0 +a=6378313.92 +rf=298.26549531037 +units=m +no_defs'],
    [103809, '+proj=tmerc +lat_0=45.8833333333333 +lon_0=-91.9166666666667 +k=0.999995 +x_0=59131.3182626365 +y_0=0 +a=6378414.93 +rf=298.270218784012 +units=m +no_defs'],
    [103810, '+proj=tmerc +lat_0=44.4083333333333 +lon_0=-91.8944444444444 +k=0.999998 +x_0=51816.1036322073 +y_0=0 +a=6378413.021 +rf=298.270129514522 +units=m +no_defs'],
    [103811, '+proj=tmerc +lat_0=45.4388888888889 +lon_0=-88.1416666666667 +k=0.999993 +x_0=133502.667005334 +y_0=0 +a=6378530.851 +rf=298.275639532334 +units=m +no_defs'],
    [103812, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=m +no_defs'],
    [103813, '+proj=tmerc +lat_0=44.0055555555556 +lon_0=-88.6333333333333 +k=0.999996 +x_0=275844.551689103 +y_0=0 +a=6378591.521 +rf=298.278476609315 +units=m +no_defs'],
    [103814, '+proj=tmerc +lat_0=41.4111111111111 +lon_0=-90.8 +k=0.999997 +x_0=242316.484632969 +y_0=0 +a=6378378.881 +rf=298.268533044963 +units=m +no_defs'],
    [103815, '+proj=tmerc +lat_0=42.5388888888889 +lon_0=-90.1611111111111 +k=0.999997 +x_0=113081.026162052 +y_0=0 +a=6378408.041 +rf=298.269896637591 +units=m +no_defs'],
    [103816, '+proj=tmerc +lat_0=45.4333333333333 +lon_0=-90.2555555555556 +k=0.999996 +x_0=220980.441960884 +y_0=0 +a=6378655.071 +rf=298.281448362111 +units=m +no_defs'],
    [103817, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=0.999997 +x_0=263347.726695453 +y_0=0 +a=6378376.811 +rf=298.268436246721 +units=m +no_defs'],
    [103818, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=0.999999 +x_0=147218.694437389 +y_0=0 +a=6378376.271 +rf=298.268410995005 +units=m +no_defs'],
    [103819, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=m +no_defs'],
    [103820, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1 +x_0=79857.7597155194 +y_0=0 +a=6378285.86 +rf=298.264183156421 +units=m +no_defs'],
    [103821, '+proj=tmerc +lat_0=43.4511111111111 +lon_0=-91.3166666666667 +k=0.999994 +x_0=130454.660909322 +y_0=0 +a=6378379.301 +rf=298.268552685186 +units=m +no_defs'],
    [103822, '+proj=tmerc +lat_0=44.8444444444444 +lon_0=-89.7333333333333 +k=0.999998 +x_0=116129.032258065 +y_0=0 +a=6378531.821 +rf=298.275684891897 +units=m +no_defs'],
    [103823, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1 +x_0=79857.7597155194 +y_0=0 +a=6378285.86 +rf=298.264183156421 +units=m +no_defs'],
    [103824, '+proj=tmerc +lat_0=44.6916666666667 +lon_0=-87.7111111111111 +k=0.999986 +x_0=238658.877317755 +y_0=0 +a=6378376.041 +rf=298.268400239645 +units=m +no_defs'],
    [103825, '+proj=tmerc +lat_0=44.7166666666667 +lon_0=-88.4166666666667 +k=0.999994 +x_0=105461.010922022 +y_0=0 +a=6378406.601 +rf=298.269829299684 +units=m +no_defs'],
    [103826, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=m +no_defs'],
    [103827, '+proj=tmerc +lat_0=44.3972222222222 +lon_0=-87.9083333333333 +k=0.999991 +x_0=182880.365760732 +y_0=0 +a=6378345.42 +rf=298.266968327098 +units=m +no_defs'],
    [103828, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=m +no_defs'],
    [103829, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=m +no_defs'],
    [103830, '+proj=tmerc +lat_0=44.6611111111111 +lon_0=-92.6333333333333 +k=1 +x_0=141732.283464567 +y_0=0 +a=6378413.671 +rf=298.270159910105 +units=m +no_defs'],
    [103831, '+proj=tmerc +lat_0=44.5555555555556 +lon_0=-90.4888888888889 +k=0.999998 +x_0=227990.855981712 +y_0=0 +a=6378563.891 +rf=298.277184563214 +units=m +no_defs'],
    [103832, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=m +no_defs'],
    [103833, '+proj=tmerc +lat_0=41.9444444444444 +lon_0=-89.0722222222222 +k=0.999996 +x_0=146304.292608585 +y_0=0 +a=6378377.671 +rf=298.268476462415 +units=m +no_defs'],
    [103834, '+proj=tmerc +lat_0=43.9194444444444 +lon_0=-91.0666666666667 +k=0.999997 +x_0=250546.101092202 +y_0=0 +a=6378472.751 +rf=298.272922634813 +units=m +no_defs'],
    [103835, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-92.6333333333333 +k=0.999995 +x_0=165506.731013462 +y_0=0 +a=6378412.511 +rf=298.270105665679 +units=m +no_defs'],
    [103836, '+proj=tmerc +lat_0=42.8194444444444 +lon_0=-89.9 +k=0.999995 +x_0=185623.571247143 +y_0=0 +a=6378407.281 +rf=298.26986109814 +units=m +no_defs'],
    [103837, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-88.6055555555556 +k=0.99999 +x_0=262433.32486665 +y_0=0 +a=6378406.051 +rf=298.269803580344 +units=m +no_defs'],
    [103838, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1 +x_0=79857.7597155194 +y_0=0 +a=6378285.86 +rf=298.264183156421 +units=m +no_defs'],
    [103839, '+proj=tmerc +lat_0=43.1611111111111 +lon_0=-91.3666666666667 +k=0.999998 +x_0=256946.913893828 +y_0=0 +a=6378380.091 +rf=298.26858962751 +units=m +no_defs'],
    [103840, '+proj=tmerc +lat_0=42.9180555555555 +lon_0=-88.0638888888889 +k=0.999995 +x_0=120091.44018288 +y_0=0 +a=6378407.141 +rf=298.269854551399 +units=m +no_defs'],
    [103841, '+proj=tmerc +lat_0=42.5694444444444 +lon_0=-88.225 +k=0.999997 +x_0=208788.417576835 +y_0=0 +a=6378376.871 +rf=298.268439052467 +units=m +no_defs'],
    [103842, '+proj=tmerc +lat_0=43.4202777777778 +lon_0=-88.8166666666667 +k=0.999996 +x_0=185013.97002794 +y_0=0 +a=6378375.251 +rf=298.268363297321 +units=m +no_defs'],
    [103843, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=m +no_defs'],
    [103844, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-91.1527777777778 +lat_1=46.4138888888889 +lat_2=46.925 +x_0=228600.457200914 +y_0=0 +a=6378411.351 +rf=298.270051421254 +units=m +no_defs'],
    [103845, '+proj=lcc +lat_0=45.3638888888889 +lon_0=-92.4577777777778 +lat_1=45.7138888888889 +lat_2=46.0833333333333 +x_0=64008.128016256 +y_0=0 +a=6378414.96 +rf=298.270220186885 +units=m +no_defs'],
    [103846, '+proj=lcc +lat_0=44.5811111111111 +lon_0=-91.2944444444444 +lat_1=44.8138888888889 +lat_2=45.1416666666667 +x_0=60045.7200914402 +y_0=0 +a=6378412.542 +rf=298.270107115315 +units=m +no_defs'],
    [103847, '+proj=lcc +lat_0=42.4583333333333 +lon_0=-89.3944444444444 +lat_1=43.3333333333333 +lat_2=43.5916666666667 +x_0=169164.338328677 +y_0=0 +a=6378376.331 +rf=298.268413800752 +units=m +no_defs'],
    [103848, '+proj=lcc +lat_0=42.7166666666667 +lon_0=-90.9388888888889 +lat_1=43.0583333333333 +lat_2=43.3416666666667 +x_0=113690.627381255 +y_0=0 +a=6378379.031 +rf=298.268540059328 +units=m +no_defs'],
    [103849, '+proj=lcc +lat_0=41.75 +lon_0=-89.4222222222222 +lat_1=42.9083333333333 +lat_2=43.2305555555555 +x_0=247193.294386589 +y_0=0 +a=6378407.621 +rf=298.269876997368 +units=m +no_defs'],
    [103850, '+proj=lcc +lat_0=44.0472222222222 +lon_0=-91.2888888888889 +lat_1=44.7305555555555 +lat_2=45.0138888888889 +x_0=120091.44018288 +y_0=0 +a=6378380.381 +rf=298.268603188617 +units=m +no_defs'],
    [103851, '+proj=lcc +lat_0=42.225 +lon_0=-89.8388888888889 +lat_1=42.4861111111111 +lat_2=42.7888888888889 +x_0=170078.74015748 +y_0=0 +a=6378408.481 +rf=298.269917213063 +units=m +no_defs'],
    [103852, '+proj=lcc +lat_0=43.0944444444444 +lon_0=-89.2416666666667 +lat_1=43.6666666666667 +lat_2=43.9472222222222 +x_0=150876.301752603 +y_0=0 +a=6378375.601 +rf=298.268379664173 +units=m +no_defs'],
    [103853, '+proj=lcc +lat_0=43.7944444444444 +lon_0=-90.7388888888889 +lat_1=44.1638888888889 +lat_2=44.4194444444444 +x_0=125882.651765303 +y_0=0 +a=6378409.151 +rf=298.269948543895 +units=m +no_defs'],
    [103854, '+proj=lcc +lat_0=42.225 +lon_0=-89.8388888888889 +lat_1=42.4861111111111 +lat_2=42.7888888888889 +x_0=170078.74015748 +y_0=0 +a=6378408.481 +rf=298.269917213063 +units=m +no_defs'],
    [103855, '+proj=lcc +lat_0=44.2069444444445 +lon_0=-89.0333333333333 +lat_1=45 +lat_2=45.3083333333333 +x_0=198425.196850394 +y_0=0 +a=6378560.121 +rf=298.277008268831 +units=m +no_defs'],
    [103856, '+proj=lcc +lat_0=44.4055555555556 +lon_0=-89.77 +lat_1=44.7452777777778 +lat_2=45.0563888888889 +x_0=74676.1493522987 +y_0=0 +a=6378500.6 +rf=298.274224921888 +units=m +no_defs'],
    [103857, '+proj=lcc +lat_0=43.0944444444444 +lon_0=-89.2416666666667 +lat_1=43.6666666666667 +lat_2=43.9472222222222 +x_0=150876.301752603 +y_0=0 +a=6378375.601 +rf=298.268379664173 +units=m +no_defs'],
    [103858, '+proj=lcc +lat_0=42.9027777777778 +lon_0=-90.6416666666667 +lat_1=43.8388888888889 +lat_2=44.1611111111111 +x_0=204521.209042418 +y_0=0 +a=6378438.991 +rf=298.27134393498 +units=m +no_defs'],
    [103859, '+proj=lcc +lat_0=45.1861111111111 +lon_0=-89.5444444444444 +lat_1=45.5666666666667 +lat_2=45.8416666666667 +x_0=70104.1402082804 +y_0=0 +a=6378593.86 +rf=298.278585986653 +units=m +no_defs'],
    [103860, '+proj=lcc +lat_0=43.8619444444444 +lon_0=-92.2277777777778 +lat_1=44.5222222222222 +lat_2=44.75 +x_0=167640.335280671 +y_0=0 +a=6378381.271 +rf=298.268644807185 +units=m +no_defs'],
    [103861, '+proj=lcc +lat_0=43.8619444444444 +lon_0=-92.2277777777778 +lat_1=44.5222222222222 +lat_2=44.75 +x_0=167640.335280671 +y_0=0 +a=6378381.271 +rf=298.268644807185 +units=m +no_defs'],
    [103862, '+proj=lcc +lat_0=43.9666666666667 +lon_0=-89.5 +lat_1=44.1833333333333 +lat_2=44.65 +x_0=56388.1127762256 +y_0=0 +a=6378344.377 +rf=298.266919538913 +units=m +no_defs'],
    [103863, '+proj=lcc +lat_0=42.1138888888889 +lon_0=-90.4305555555556 +lat_1=43.1416666666667 +lat_2=43.5027777777778 +x_0=202387.60477521 +y_0=0 +a=6378408.091 +rf=298.269898975713 +units=m +no_defs'],
    [103864, '+proj=lcc +lat_0=44.8138888888889 +lon_0=-91.1166666666667 +lat_1=45.7194444444444 +lat_2=46.0805555555556 +x_0=216713.233426467 +y_0=0 +a=6378534.451 +rf=298.275807877103 +units=m +no_defs'],
    [103865, '+proj=lcc +lat_0=44.2083333333333 +lon_0=-90.4833333333333 +lat_1=45.0555555555556 +lat_2=45.3 +x_0=187147.574295149 +y_0=0 +a=6378532.921 +rf=298.275736330576 +units=m +no_defs'],
    [103866, '+proj=lcc +lat_0=43.1472222222222 +lon_0=-90.7833333333333 +lat_1=43.4666666666667 +lat_2=43.6833333333333 +x_0=222504.44500889 +y_0=0 +a=6378408.941 +rf=298.269938723784 +units=m +no_defs'],
    [103867, '+proj=lcc +lat_0=45.625 +lon_0=-89.4888888888889 +lat_1=45.9305555555556 +lat_2=46.225 +x_0=134417.068834138 +y_0=0 +a=6378624.171 +rf=298.280003402845 +units=m +no_defs'],
    [103868, '+proj=lcc +lat_0=41.6694444444444 +lon_0=-88.5416666666667 +lat_1=42.5888888888889 +lat_2=42.75 +x_0=232562.86512573 +y_0=0 +a=6378377.411 +rf=298.268464304182 +units=m +no_defs'],
    [103869, '+proj=lcc +lat_0=44.2666666666667 +lon_0=-91.7833333333333 +lat_1=45.7722222222222 +lat_2=46.15 +x_0=234086.868173736 +y_0=0 +a=6378474.591 +rf=298.273008677695 +units=m +no_defs'],
    [103870, '+proj=lcc +lat_0=43.7083333333333 +lon_0=-89.2416666666667 +lat_1=43.975 +lat_2=44.2527777777778 +x_0=120091.44018288 +y_0=0 +a=6378405.971 +rf=298.269799839349 +units=m +no_defs'],
    [103871, '+proj=lcc +lat_0=43.1513888888889 +lon_0=-90 +lat_1=44.1805555555556 +lat_2=44.5444444444444 +x_0=208483.616967234 +y_0=0 +a=6378437.651 +rf=298.271281273316 +units=m +no_defs'],
    [103900, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=0.999999 +x_0=147218.694437389 +y_0=0 +a=6378376.271 +rf=298.268410995005 +units=us-ft +no_defs'],
    [103901, '+proj=tmerc +lat_0=45.7061111111111 +lon_0=-90.6222222222222 +k=0.999997 +x_0=172821.945643891 +y_0=0 +a=6378471.92 +rf=298.272883775229 +units=us-ft +no_defs'],
    [103902, '+proj=tmerc +lat_0=45.1333333333333 +lon_0=-91.85 +k=0.999996 +x_0=93150 +y_0=0 +a=6378472.931 +rf=298.272931052052 +units=us-ft +no_defs'],
    [103903, '+proj=tmerc +lat_0=43 +lon_0=-88 +k=1.00002 +x_0=31599.99998984 +y_0=4599.98983997968 +ellps=GRS80 +units=us-ft +no_defs'],
    [103904, '+proj=tmerc +lat_0=43.4813888888889 +lon_0=-91.7972222222222 +k=1 +x_0=175260.350520701 +y_0=0 +a=6378380.991 +rf=298.268631713702 +units=us-ft +no_defs'],
    [103905, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=us-ft +no_defs'],
    [103906, '+proj=tmerc +lat_0=43.6 +lon_0=-90.7083333333333 +k=0.999994 +x_0=199949.1998984 +y_0=0 +a=6378470.401 +rf=298.272812743089 +units=us-ft +no_defs'],
    [103907, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=0.999997 +x_0=263347.726695453 +y_0=0 +a=6378376.811 +rf=298.268436246721 +units=us-ft +no_defs'],
    [103908, '+proj=tmerc +lat_0=44.4 +lon_0=-87.2722222222222 +k=0.999991 +x_0=158801.117602235 +y_0=0 +a=6378313.92 +rf=298.26549531037 +units=us-ft +no_defs'],
    [103909, '+proj=tmerc +lat_0=45.8833333333333 +lon_0=-91.9166666666667 +k=0.999995 +x_0=59131.3182626365 +y_0=0 +a=6378414.93 +rf=298.270218784012 +units=us-ft +no_defs'],
    [103910, '+proj=tmerc +lat_0=44.4083333333333 +lon_0=-91.8944444444444 +k=0.999998 +x_0=51816.1036322072 +y_0=0 +a=6378413.021 +rf=298.270129514522 +units=us-ft +no_defs'],
    [103911, '+proj=tmerc +lat_0=45.4388888888889 +lon_0=-88.1416666666667 +k=0.999993 +x_0=133502.667005334 +y_0=0 +a=6378530.851 +rf=298.275639532334 +units=us-ft +no_defs'],
    [103912, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=us-ft +no_defs'],
    [103913, '+proj=tmerc +lat_0=44.0055555555556 +lon_0=-88.6333333333333 +k=0.999996 +x_0=275844.551689103 +y_0=0 +a=6378591.521 +rf=298.278476609315 +units=us-ft +no_defs'],
    [103914, '+proj=tmerc +lat_0=41.4111111111111 +lon_0=-90.8 +k=0.999997 +x_0=242316.484632969 +y_0=0 +a=6378378.881 +rf=298.268533044963 +units=us-ft +no_defs'],
    [103915, '+proj=tmerc +lat_0=42.5388888888889 +lon_0=-90.1611111111111 +k=0.999997 +x_0=113081.026162052 +y_0=0 +a=6378408.041 +rf=298.269896637591 +units=us-ft +no_defs'],
    [103916, '+proj=tmerc +lat_0=45.4333333333333 +lon_0=-90.2555555555556 +k=0.999996 +x_0=220980.441960884 +y_0=0 +a=6378655.071 +rf=298.281448362111 +units=us-ft +no_defs'],
    [103917, '+proj=tmerc +lat_0=41.4722222222222 +lon_0=-88.775 +k=0.999997 +x_0=263347.726695453 +y_0=0 +a=6378376.811 +rf=298.268436246721 +units=us-ft +no_defs'],
    [103918, '+proj=tmerc +lat_0=43.3666666666667 +lon_0=-90 +k=0.999999 +x_0=147218.694437389 +y_0=0 +a=6378376.271 +rf=298.268410995005 +units=us-ft +no_defs'],
    [103919, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=us-ft +no_defs'],
    [103920, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1 +x_0=79857.7597155194 +y_0=0 +a=6378285.86 +rf=298.264183156421 +units=us-ft +no_defs'],
    [103921, '+proj=tmerc +lat_0=43.4511111111111 +lon_0=-91.3166666666667 +k=0.999994 +x_0=130454.660909322 +y_0=0 +a=6378379.301 +rf=298.268552685186 +units=us-ft +no_defs'],
    [103922, '+proj=tmerc +lat_0=44.8444444444444 +lon_0=-89.7333333333333 +k=0.999998 +x_0=116129.032258064 +y_0=0 +a=6378531.821 +rf=298.275684891897 +units=us-ft +no_defs'],
    [103923, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1 +x_0=79857.7597155194 +y_0=0 +a=6378285.86 +rf=298.264183156421 +units=us-ft +no_defs'],
    [103924, '+proj=tmerc +lat_0=44.6916666666667 +lon_0=-87.7111111111111 +k=0.999986 +x_0=238658.877317754 +y_0=0 +a=6378376.041 +rf=298.268400239645 +units=us-ft +no_defs'],
    [103925, '+proj=tmerc +lat_0=44.7166666666667 +lon_0=-88.4166666666667 +k=0.999994 +x_0=105461.010922022 +y_0=0 +a=6378406.601 +rf=298.269829299684 +units=us-ft +no_defs'],
    [103926, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=us-ft +no_defs'],
    [103927, '+proj=tmerc +lat_0=44.3972222222222 +lon_0=-87.9083333333333 +k=0.999991 +x_0=182880.365760731 +y_0=0 +a=6378345.42 +rf=298.266968327098 +units=us-ft +no_defs'],
    [103928, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=us-ft +no_defs'],
    [103929, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=us-ft +no_defs'],
    [103930, '+proj=tmerc +lat_0=44.6611111111111 +lon_0=-92.6333333333333 +k=1 +x_0=141732.283464567 +y_0=0 +a=6378413.671 +rf=298.270159910105 +units=us-ft +no_defs'],
    [103931, '+proj=tmerc +lat_0=44.5555555555556 +lon_0=-90.4888888888889 +k=0.999998 +x_0=227990.855981712 +y_0=0 +a=6378563.891 +rf=298.277184563214 +units=us-ft +no_defs'],
    [103932, '+proj=tmerc +lat_0=42.2166666666667 +lon_0=-87.8944444444444 +k=0.999998 +x_0=185928.371856744 +y_0=0 +a=6378315.7 +rf=298.265578547505 +units=us-ft +no_defs'],
    [103933, '+proj=tmerc +lat_0=41.9444444444444 +lon_0=-89.0722222222222 +k=0.999996 +x_0=146304.292608585 +y_0=0 +a=6378377.671 +rf=298.268476462415 +units=us-ft +no_defs'],
    [103934, '+proj=tmerc +lat_0=43.9194444444444 +lon_0=-91.0666666666667 +k=0.999997 +x_0=250546.101092202 +y_0=0 +a=6378472.751 +rf=298.272922634813 +units=us-ft +no_defs'],
    [103935, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-92.6333333333333 +k=0.999995 +x_0=165506.731013462 +y_0=0 +a=6378412.511 +rf=298.270105665679 +units=us-ft +no_defs'],
    [103936, '+proj=tmerc +lat_0=42.8194444444444 +lon_0=-89.9 +k=0.999995 +x_0=185623.571247142 +y_0=0 +a=6378407.281 +rf=298.26986109814 +units=us-ft +no_defs'],
    [103937, '+proj=tmerc +lat_0=44.0361111111111 +lon_0=-88.6055555555556 +k=0.99999 +x_0=262433.32486665 +y_0=0 +a=6378406.051 +rf=298.269803580344 +units=us-ft +no_defs'],
    [103938, '+proj=tmerc +lat_0=43.2666666666667 +lon_0=-87.55 +k=1 +x_0=79857.7597155194 +y_0=0 +a=6378285.86 +rf=298.264183156421 +units=us-ft +no_defs'],
    [103939, '+proj=tmerc +lat_0=43.1611111111111 +lon_0=-91.3666666666667 +k=0.999998 +x_0=256946.913893828 +y_0=0 +a=6378380.091 +rf=298.26858962751 +units=us-ft +no_defs'],
    [103940, '+proj=tmerc +lat_0=42.9180555555555 +lon_0=-88.0638888888889 +k=0.999995 +x_0=120091.44018288 +y_0=0 +a=6378407.141 +rf=298.269854551399 +units=us-ft +no_defs'],
    [103941, '+proj=tmerc +lat_0=42.5694444444444 +lon_0=-88.225 +k=0.999997 +x_0=208788.417576835 +y_0=0 +a=6378376.871 +rf=298.268439052467 +units=us-ft +no_defs'],
    [103942, '+proj=tmerc +lat_0=43.4202777777778 +lon_0=-88.8166666666667 +k=0.999996 +x_0=185013.97002794 +y_0=0 +a=6378375.251 +rf=298.268363297321 +units=us-ft +no_defs'],
    [103943, '+proj=tmerc +lat_0=42.7194444444444 +lon_0=-88.5 +k=0.999996 +x_0=244754.889509779 +y_0=0 +a=6378345.09 +rf=298.266952895494 +units=us-ft +no_defs'],
    [103944, '+proj=lcc +lat_0=45.3333333333333 +lon_0=-91.1527777777778 +lat_1=46.4138888888889 +lat_2=46.925 +x_0=228600.457200914 +y_0=0 +a=6378411.351 +rf=298.270051421254 +units=us-ft +no_defs'],
    [103945, '+proj=lcc +lat_0=45.3638888888889 +lon_0=-92.4577777777778 +lat_1=45.7138888888889 +lat_2=46.0833333333333 +x_0=64008.128016256 +y_0=0 +a=6378414.96 +rf=298.270220186885 +units=us-ft +no_defs'],
    [103946, '+proj=lcc +lat_0=44.5811111111111 +lon_0=-91.2944444444444 +lat_1=44.8138888888889 +lat_2=45.1416666666667 +x_0=60045.7200914401 +y_0=0 +a=6378412.542 +rf=298.270107115315 +units=us-ft +no_defs'],
    [103947, '+proj=lcc +lat_0=42.4583333333333 +lon_0=-89.3944444444444 +lat_1=43.3333333333333 +lat_2=43.5916666666667 +x_0=169164.338328677 +y_0=0 +a=6378376.331 +rf=298.268413800752 +units=us-ft +no_defs'],
    [103948, '+proj=lcc +lat_0=42.7166666666667 +lon_0=-90.9388888888889 +lat_1=43.0583333333333 +lat_2=43.3416666666667 +x_0=113690.627381255 +y_0=0 +a=6378379.031 +rf=298.268540059328 +units=us-ft +no_defs'],
    [103949, '+proj=lcc +lat_0=41.75 +lon_0=-89.4222222222222 +lat_1=42.9083333333333 +lat_2=43.2305555555555 +x_0=247193.294386589 +y_0=0 +a=6378407.621 +rf=298.269876997368 +units=us-ft +no_defs'],
    [103950, '+proj=lcc +lat_0=44.0472222222222 +lon_0=-91.2888888888889 +lat_1=44.7305555555555 +lat_2=45.0138888888889 +x_0=120091.44018288 +y_0=0 +a=6378380.381 +rf=298.268603188617 +units=us-ft +no_defs'],
    [103951, '+proj=lcc +lat_0=42.225 +lon_0=-89.8388888888889 +lat_1=42.4861111111111 +lat_2=42.7888888888889 +x_0=170078.74015748 +y_0=0 +a=6378408.481 +rf=298.269917213063 +units=us-ft +no_defs'],
    [103952, '+proj=lcc +lat_0=43.0944444444444 +lon_0=-89.2416666666667 +lat_1=43.6666666666667 +lat_2=43.9472222222222 +x_0=150876.301752603 +y_0=0 +a=6378375.601 +rf=298.268379664173 +units=us-ft +no_defs'],
    [103953, '+proj=lcc +lat_0=43.7944444444444 +lon_0=-90.7388888888889 +lat_1=44.1638888888889 +lat_2=44.4194444444444 +x_0=125882.651765303 +y_0=0 +a=6378409.151 +rf=298.269948543895 +units=us-ft +no_defs'],
    [103954, '+proj=lcc +lat_0=42.225 +lon_0=-89.8388888888889 +lat_1=42.4861111111111 +lat_2=42.7888888888889 +x_0=170078.74015748 +y_0=0 +a=6378408.481 +rf=298.269917213063 +units=us-ft +no_defs'],
    [103955, '+proj=lcc +lat_0=44.2069444444445 +lon_0=-89.0333333333333 +lat_1=45 +lat_2=45.3083333333333 +x_0=198425.196850394 +y_0=0 +a=6378560.121 +rf=298.277008268831 +units=us-ft +no_defs'],
    [103956, '+proj=lcc +lat_0=44.4055555555556 +lon_0=-89.77 +lat_1=44.7452777777778 +lat_2=45.0563888888889 +x_0=74676.1493522987 +y_0=0 +a=6378500.6 +rf=298.274224921888 +units=us-ft +no_defs'],
    [103957, '+proj=lcc +lat_0=43.0944444444444 +lon_0=-89.2416666666667 +lat_1=43.6666666666667 +lat_2=43.9472222222222 +x_0=150876.301752603 +y_0=0 +a=6378375.601 +rf=298.268379664173 +units=us-ft +no_defs'],
    [103958, '+proj=lcc +lat_0=42.9027777777778 +lon_0=-90.6416666666667 +lat_1=43.8388888888889 +lat_2=44.1611111111111 +x_0=204521.209042418 +y_0=0 +a=6378438.991 +rf=298.27134393498 +units=us-ft +no_defs'],
    [103959, '+proj=lcc +lat_0=45.1861111111111 +lon_0=-89.5444444444444 +lat_1=45.5666666666667 +lat_2=45.8416666666667 +x_0=70104.1402082804 +y_0=0 +a=6378593.86 +rf=298.278585986653 +units=us-ft +no_defs'],
    [103960, '+proj=lcc +lat_0=43.8619444444444 +lon_0=-92.2277777777778 +lat_1=44.5222222222222 +lat_2=44.75 +x_0=167640.33528067 +y_0=0 +a=6378381.271 +rf=298.268644807185 +units=us-ft +no_defs'],
    [103961, '+proj=lcc +lat_0=43.8619444444444 +lon_0=-92.2277777777778 +lat_1=44.5222222222222 +lat_2=44.75 +x_0=167640.33528067 +y_0=0 +a=6378381.271 +rf=298.268644807185 +units=us-ft +no_defs'],
    [103962, '+proj=lcc +lat_0=43.9666666666667 +lon_0=-89.5 +lat_1=44.1833333333333 +lat_2=44.65 +x_0=56388.1127762255 +y_0=0 +a=6378344.377 +rf=298.266919538913 +units=us-ft +no_defs'],
    [103963, '+proj=lcc +lat_0=42.1138888888889 +lon_0=-90.4305555555556 +lat_1=43.1416666666667 +lat_2=43.5027777777778 +x_0=202387.604775209 +y_0=0 +a=6378408.091 +rf=298.269898975713 +units=us-ft +no_defs'],
    [103964, '+proj=lcc +lat_0=44.8138888888889 +lon_0=-91.1166666666667 +lat_1=45.7194444444444 +lat_2=46.0805555555556 +x_0=216713.233426467 +y_0=0 +a=6378534.451 +rf=298.275807877103 +units=us-ft +no_defs'],
    [103965, '+proj=lcc +lat_0=44.2083333333333 +lon_0=-90.4833333333333 +lat_1=45.0555555555556 +lat_2=45.3 +x_0=187147.574295148 +y_0=0 +a=6378532.921 +rf=298.275736330576 +units=us-ft +no_defs'],
    [103966, '+proj=lcc +lat_0=43.1472222222222 +lon_0=-90.7833333333333 +lat_1=43.4666666666667 +lat_2=43.6833333333333 +x_0=222504.44500889 +y_0=0 +a=6378408.941 +rf=298.269938723784 +units=us-ft +no_defs'],
    [103967, '+proj=lcc +lat_0=45.625 +lon_0=-89.4888888888889 +lat_1=45.9305555555556 +lat_2=46.225 +x_0=134417.068834138 +y_0=0 +a=6378624.171 +rf=298.280003402845 +units=us-ft +no_defs'],
    [103968, '+proj=lcc +lat_0=41.6694444444444 +lon_0=-88.5416666666667 +lat_1=42.5888888888889 +lat_2=42.75 +x_0=232562.86512573 +y_0=0 +a=6378377.411 +rf=298.268464304182 +units=us-ft +no_defs'],
    [103969, '+proj=lcc +lat_0=44.2666666666667 +lon_0=-91.7833333333333 +lat_1=45.7722222222222 +lat_2=46.15 +x_0=234086.868173736 +y_0=0 +a=6378474.591 +rf=298.273008677695 +units=us-ft +no_defs'],
    [103970, '+proj=lcc +lat_0=43.7083333333333 +lon_0=-89.2416666666667 +lat_1=43.975 +lat_2=44.2527777777778 +x_0=120091.44018288 +y_0=0 +a=6378405.971 +rf=298.269799839349 +units=us-ft +no_defs'],
    [103971, '+proj=lcc +lat_0=43.1513888888889 +lon_0=-90 +lat_1=44.1805555555556 +lat_2=44.5444444444444 +x_0=208483.616967234 +y_0=0 +a=6378437.651 +rf=298.271281273316 +units=us-ft +no_defs'],
    [104000, '+proj=longlat +datum=NAD27 +no_defs'],
    [104009, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104010, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104011, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104012, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104013, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104014, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104015, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104016, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104017, '+proj=longlat +a=6378136 +rf=298.257839303 +no_defs'],
    [104018, '+proj=longlat +a=6378136 +rf=298.257839303 +no_defs'],
    [104019, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104020, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104021, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104022, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104023, '+proj=longlat +ellps=aust_SA +no_defs'],
    [104024, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104025, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [104026, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [104047, '+proj=longlat +R=6371008.7714 +no_defs'],
    [104050, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104100, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104101, '+proj=longlat +ellps=bessel +no_defs'],
    [104102, '+proj=longlat +ellps=bessel +no_defs'],
    [104103, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [104104, '+proj=longlat +ellps=intl +no_defs'],
    [104105, '+proj=longlat +ellps=bessel +no_defs'],
    [104106, '+proj=longlat +ellps=intl +no_defs'],
    [104107, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104108, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104109, '+proj=longlat +ellps=clrk66 +no_defs'],
    [104110, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104111, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104112, '+proj=longlat +ellps=clrk66 +no_defs'],
    [104113, '+proj=longlat +ellps=clrk66 +no_defs'],
    [104114, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104115, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104116, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104117, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104118, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104119, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104120, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104121, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104122, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104123, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104124, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104125, '+proj=longlat +ellps=intl +no_defs'],
    [104126, '+proj=longlat +ellps=intl +no_defs'],
    [104127, '+proj=longlat +ellps=intl +no_defs'],
    [104128, '+proj=longlat +R=6370000 +no_defs'],
    [104129, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104130, '+proj=longlat +ellps=intl +no_defs'],
    [104131, '+proj=longlat +ellps=bessel +no_defs'],
    [104132, '+proj=longlat +ellps=clrk66 +no_defs'],
    [104133, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104134, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104135, '+proj=longlat +ellps=krass +no_defs'],
    [104136, '+proj=longlat +ellps=aust_SA +no_defs'],
    [104137, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104138, '+proj=longlat +ellps=intl +no_defs'],
    [104139, '+proj=longlat +ellps=clrk80ign +no_defs'],
    [104140, '+proj=longlat +ellps=clrk80ign +no_defs'],
    [104141, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104142, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104143, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104144, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104145, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104179, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104180, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104181, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104182, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104183, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104184, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104185, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104186, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104199, '+proj=longlat +R=6378137 +no_defs'],
    [104223, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104248, '+proj=longlat +ellps=intl +no_defs'],
    [104256, '+proj=longlat +ellps=evrst30 +no_defs'],
    [104257, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104258, '+proj=longlat +ellps=WGS84 +no_defs'],
    [104259, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104260, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104261, '+proj=longlat +ellps=clrk80ign +no_defs'],
    [104286, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104287, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104304, '+proj=longlat +ellps=clrk80ign +no_defs'],
    [104305, '+proj=longlat +a=6378249.145 +rf=293.465 +no_defs'],
    [104700, '+proj=longlat +a=6378418.941 +rf=298.257222100883 +no_defs'],
    [104701, '+proj=longlat +a=6378586.581 +rf=298.257222100883 +no_defs'],
    [104702, '+proj=longlat +a=6378505.809 +rf=298.257222100883 +no_defs'],
    [104703, '+proj=longlat +a=6378544.823 +rf=298.257222100883 +no_defs'],
    [104704, '+proj=longlat +a=6378490.569 +rf=298.257222100883 +no_defs'],
    [104705, '+proj=longlat +a=6378470.757 +rf=298.257222100883 +no_defs'],
    [104706, '+proj=longlat +a=6378403.701 +rf=298.257222100883 +no_defs'],
    [104707, '+proj=longlat +a=6378434.181 +rf=298.257222100883 +no_defs'],
    [104708, '+proj=longlat +a=6378454.907 +rf=298.257222100883 +no_defs'],
    [104709, '+proj=longlat +a=6378400.653 +rf=298.257222100883 +no_defs'],
    [104710, '+proj=longlat +a=6378567.378 +rf=298.257222100883 +no_defs'],
    [104711, '+proj=longlat +a=6378546.957 +rf=298.257222100883 +no_defs'],
    [104712, '+proj=longlat +a=6378476.853 +rf=298.257222100883 +no_defs'],
    [104713, '+proj=longlat +a=6378411.321 +rf=298.257222100883 +no_defs'],
    [104714, '+proj=longlat +a=6378647.541 +rf=298.257222100883 +no_defs'],
    [104715, '+proj=longlat +a=6378647.541 +rf=298.257222100883 +no_defs'],
    [104716, '+proj=longlat +a=6378514.953 +rf=298.257222100883 +no_defs'],
    [104717, '+proj=longlat +a=6378546.957 +rf=298.257222100883 +no_defs'],
    [104718, '+proj=longlat +a=6378421.989 +rf=298.257222100883 +no_defs'],
    [104719, '+proj=longlat +a=6378481.425 +rf=298.257222100883 +no_defs'],
    [104720, '+proj=longlat +a=6378518.001 +rf=298.257222100883 +no_defs'],
    [104721, '+proj=longlat +a=6378521.049 +rf=298.257222100883 +no_defs'],
    [104722, '+proj=longlat +a=6378464.661 +rf=298.257222100883 +no_defs'],
    [104723, '+proj=longlat +a=6378521.049 +rf=298.257222100883 +no_defs'],
    [104724, '+proj=longlat +a=6378434.181 +rf=298.257222100883 +no_defs'],
    [104725, '+proj=longlat +a=6378518.001 +rf=298.257222100883 +no_defs'],
    [104726, '+proj=longlat +a=6378418.941 +rf=298.257222100883 +no_defs'],
    [104727, '+proj=longlat +a=6378436.619 +rf=298.257222100883 +no_defs'],
    [104728, '+proj=longlat +a=6378411.321 +rf=298.257222100883 +no_defs'],
    [104729, '+proj=longlat +a=6378574.389 +rf=298.257222100883 +no_defs'],
    [104730, '+proj=longlat +a=6378574.389 +rf=298.257222100883 +no_defs'],
    [104731, '+proj=longlat +a=6378521.049 +rf=298.257222100883 +no_defs'],
    [104732, '+proj=longlat +a=6378472.281 +rf=298.257222100883 +no_defs'],
    [104733, '+proj=longlat +a=6378498.189 +rf=298.257222100883 +no_defs'],
    [104734, '+proj=longlat +a=6378449.421 +rf=298.257222100883 +no_defs'],
    [104735, '+proj=longlat +a=6378525.621 +rf=298.257222100883 +no_defs'],
    [104736, '+proj=longlat +a=6378476.853 +rf=298.257222100883 +no_defs'],
    [104737, '+proj=longlat +a=6378466.185 +rf=298.257222100883 +no_defs'],
    [104738, '+proj=longlat +a=6378496.665 +rf=298.257222100883 +no_defs'],
    [104739, '+proj=longlat +a=6378434.181 +rf=298.257222100883 +no_defs'],
    [104740, '+proj=longlat +a=6378643.579 +rf=298.257222100883 +no_defs'],
    [104741, '+proj=longlat +a=6378559.758 +rf=298.257222100883 +no_defs'],
    [104742, '+proj=longlat +a=6378414.369 +rf=298.257222100883 +no_defs'],
    [104743, '+proj=longlat +a=6378586.581 +rf=298.257222100883 +no_defs'],
    [104744, '+proj=longlat +a=6378441.801 +rf=298.257222100883 +no_defs'],
    [104745, '+proj=longlat +a=6378521.049 +rf=298.257222100883 +no_defs'],
    [104746, '+proj=longlat +a=6378498.189 +rf=298.257222100883 +no_defs'],
    [104747, '+proj=longlat +a=6378502.761 +rf=298.257222100883 +no_defs'],
    [104748, '+proj=longlat +a=6378521.049 +rf=298.257222100883 +no_defs'],
    [104749, '+proj=longlat +a=6378617.061 +rf=298.257222100883 +no_defs'],
    [104750, '+proj=longlat +a=6378403.701 +rf=298.257222100883 +no_defs'],
    [104751, '+proj=longlat +a=6378624.681 +rf=298.257222100883 +no_defs'],
    [104752, '+proj=longlat +a=6378468.623 +rf=298.257222100883 +no_defs'],
    [104753, '+proj=longlat +a=6378481.425 +rf=298.257222100883 +no_defs'],
    [104754, '+proj=longlat +a=6378525.621 +rf=298.257222100883 +no_defs'],
    [104755, '+proj=longlat +a=6378445.763 +rf=298.257222100883 +no_defs'],
    [104756, '+proj=longlat +a=6378472.281 +rf=298.257222100883 +no_defs'],
    [104757, '+proj=longlat +a=6378670.401 +rf=298.257222100883 +no_defs'],
    [104758, '+proj=longlat +a=6378445.763 +rf=298.257222100883 +no_defs'],
    [104759, '+proj=longlat +a=6378502.761 +rf=298.257222100883 +no_defs'],
    [104760, '+proj=longlat +a=6378418.941 +rf=298.257222100883 +no_defs'],
    [104761, '+proj=longlat +a=6378445.763 +rf=298.257222100883 +no_defs'],
    [104762, '+proj=longlat +a=6378438.753 +rf=298.257222100883 +no_defs'],
    [104763, '+proj=longlat +a=6378414.369 +rf=298.257222100883 +no_defs'],
    [104764, '+proj=longlat +a=6378434.181 +rf=298.257222100883 +no_defs'],
    [104765, '+proj=longlat +a=6378624.681 +rf=298.257222100883 +no_defs'],
    [104766, '+proj=longlat +a=6378449.421 +rf=298.257222100883 +no_defs'],
    [104767, '+proj=longlat +a=6378543.909 +rf=298.257222100883 +no_defs'],
    [104768, '+proj=longlat +a=6378605.783 +rf=298.257222100883 +no_defs'],
    [104769, '+proj=longlat +a=6378540.861 +rf=298.257222100883 +no_defs'],
    [104770, '+proj=longlat +a=6378421.989 +rf=298.257222100883 +no_defs'],
    [104771, '+proj=longlat +a=6378443.325 +rf=298.257222100883 +no_defs'],
    [104772, '+proj=longlat +a=6378414.369 +rf=298.257222100883 +no_defs'],
    [104773, '+proj=longlat +a=6378502.761 +rf=298.257222100883 +no_defs'],
    [104774, '+proj=longlat +a=6378481.425 +rf=298.257222100883 +no_defs'],
    [104775, '+proj=longlat +a=6378502.761 +rf=298.257222100883 +no_defs'],
    [104776, '+proj=longlat +a=6378470.757 +rf=298.257222100883 +no_defs'],
    [104777, '+proj=longlat +a=6378548.481 +rf=298.257222100883 +no_defs'],
    [104778, '+proj=longlat +a=6378463.746 +rf=298.257222100883 +no_defs'],
    [104779, '+proj=longlat +a=6378426.561 +rf=298.257222100883 +no_defs'],
    [104780, '+proj=longlat +a=6378546.957 +rf=298.257222100883 +no_defs'],
    [104781, '+proj=longlat +a=6378481.425 +rf=298.257222100883 +no_defs'],
    [104782, '+proj=longlat +a=6378514.953 +rf=298.257222100883 +no_defs'],
    [104783, '+proj=longlat +a=6378453.688 +rf=298.257222100883 +no_defs'],
    [104784, '+proj=longlat +a=6378443.325 +rf=298.257222100883 +no_defs'],
    [104785, '+proj=longlat +a=6378530.193 +rf=298.257222100883 +no_defs'],
    [104786, '+proj=longlat +a=6378523 +rf=298.2752724 +no_defs'],
    [104800, '+proj=longlat +a=6378376.271 +rf=298.268410995005 +no_defs'],
    [104801, '+proj=longlat +a=6378471.92 +rf=298.272883775229 +no_defs'],
    [104802, '+proj=longlat +a=6378472.931 +rf=298.272931052052 +no_defs'],
    [104803, '+proj=longlat +a=6378411.351 +rf=298.270051421254 +no_defs'],
    [104804, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104805, '+proj=longlat +a=6378380.991 +rf=298.268631713702 +no_defs'],
    [104806, '+proj=longlat +a=6378414.96 +rf=298.270220186885 +no_defs'],
    [104807, '+proj=longlat +a=6378345.09 +rf=298.266952895494 +no_defs'],
    [104808, '+proj=longlat +a=6378412.542 +rf=298.270107115315 +no_defs'],
    [104809, '+proj=longlat +a=6378470.401 +rf=298.272812743089 +no_defs'],
    [104810, '+proj=longlat +a=6378376.331 +rf=298.268413800752 +no_defs'],
    [104811, '+proj=longlat +a=6378379.031 +rf=298.268540059328 +no_defs'],
    [104812, '+proj=longlat +a=6378407.621 +rf=298.269876997368 +no_defs'],
    [104813, '+proj=longlat +a=6378376.811 +rf=298.268436246721 +no_defs'],
    [104814, '+proj=longlat +a=6378313.92 +rf=298.26549531037 +no_defs'],
    [104815, '+proj=longlat +a=6378414.93 +rf=298.270218784012 +no_defs'],
    [104816, '+proj=longlat +a=6378413.021 +rf=298.270129514522 +no_defs'],
    [104817, '+proj=longlat +a=6378380.381 +rf=298.268603188617 +no_defs'],
    [104818, '+proj=longlat +a=6378530.851 +rf=298.275639532334 +no_defs'],
    [104819, '+proj=longlat +a=6378345.09 +rf=298.266952895494 +no_defs'],
    [104820, '+proj=longlat +a=6378591.521 +rf=298.278476609315 +no_defs'],
    [104821, '+proj=longlat +a=6378378.881 +rf=298.268533044963 +no_defs'],
    [104822, '+proj=longlat +a=6378408.481 +rf=298.269917213063 +no_defs'],
    [104823, '+proj=longlat +a=6378375.601 +rf=298.268379664173 +no_defs'],
    [104824, '+proj=longlat +a=6378408.041 +rf=298.269896637591 +no_defs'],
    [104825, '+proj=longlat +a=6378655.071 +rf=298.281448362111 +no_defs'],
    [104826, '+proj=longlat +a=6378409.151 +rf=298.269948543895 +no_defs'],
    [104827, '+proj=longlat +a=6378376.811 +rf=298.268436246721 +no_defs'],
    [104828, '+proj=longlat +a=6378376.271 +rf=298.268410995005 +no_defs'],
    [104829, '+proj=longlat +a=6378315.7 +rf=298.265578547505 +no_defs'],
    [104830, '+proj=longlat +a=6378285.86 +rf=298.264183156421 +no_defs'],
    [104831, '+proj=longlat +a=6378379.301 +rf=298.268552685186 +no_defs'],
    [104832, '+proj=longlat +a=6378408.481 +rf=298.269917213063 +no_defs'],
    [104833, '+proj=longlat +a=6378560.121 +rf=298.277008268831 +no_defs'],
    [104834, '+proj=longlat +a=6378531.821 +rf=298.275684891897 +no_defs'],
    [104835, '+proj=longlat +a=6378285.86 +rf=298.264183156421 +no_defs'],
    [104836, '+proj=longlat +a=6378500.6 +rf=298.274224921888 +no_defs'],
    [104837, '+proj=longlat +a=6378376.041 +rf=298.268400239645 +no_defs'],
    [104838, '+proj=longlat +a=6378375.601 +rf=298.268379664173 +no_defs'],
    [104839, '+proj=longlat +a=6378406.601 +rf=298.269829299684 +no_defs'],
    [104840, '+proj=longlat +a=6378315.7 +rf=298.265578547505 +no_defs'],
    [104841, '+proj=longlat +a=6378438.991 +rf=298.27134393498 +no_defs'],
    [104842, '+proj=longlat +a=6378345.42 +rf=298.266968327098 +no_defs'],
    [104843, '+proj=longlat +a=6378593.86 +rf=298.278585986653 +no_defs'],
    [104844, '+proj=longlat +a=6378345.09 +rf=298.266952895494 +no_defs'],
    [104845, '+proj=longlat +a=6378315.7 +rf=298.265578547505 +no_defs'],
    [104846, '+proj=longlat +a=6378381.271 +rf=298.268644807185 +no_defs'],
    [104847, '+proj=longlat +a=6378381.271 +rf=298.268644807185 +no_defs'],
    [104848, '+proj=longlat +a=6378413.671 +rf=298.270159910105 +no_defs'],
    [104849, '+proj=longlat +a=6378344.377 +rf=298.266919538913 +no_defs'],
    [104850, '+proj=longlat +a=6378563.891 +rf=298.277184563214 +no_defs'],
    [104851, '+proj=longlat +a=6378315.7 +rf=298.265578547505 +no_defs'],
    [104852, '+proj=longlat +a=6378408.091 +rf=298.269898975713 +no_defs'],
    [104853, '+proj=longlat +a=6378377.671 +rf=298.268476462415 +no_defs'],
    [104854, '+proj=longlat +a=6378472.751 +rf=298.272922634813 +no_defs'],
    [104855, '+proj=longlat +a=6378412.511 +rf=298.270105665679 +no_defs'],
    [104856, '+proj=longlat +a=6378407.281 +rf=298.26986109814 +no_defs'],
    [104857, '+proj=longlat +a=6378534.451 +rf=298.275807877103 +no_defs'],
    [104858, '+proj=longlat +a=6378406.051 +rf=298.269803580344 +no_defs'],
    [104859, '+proj=longlat +a=6378285.86 +rf=298.264183156421 +no_defs'],
    [104860, '+proj=longlat +a=6378532.921 +rf=298.275736330576 +no_defs'],
    [104861, '+proj=longlat +a=6378380.091 +rf=298.26858962751 +no_defs'],
    [104862, '+proj=longlat +a=6378408.941 +rf=298.269938723784 +no_defs'],
    [104863, '+proj=longlat +a=6378624.171 +rf=298.280003402845 +no_defs'],
    [104864, '+proj=longlat +a=6378377.411 +rf=298.268464304182 +no_defs'],
    [104865, '+proj=longlat +a=6378474.591 +rf=298.273008677695 +no_defs'],
    [104866, '+proj=longlat +a=6378407.141 +rf=298.269854551399 +no_defs'],
    [104867, '+proj=longlat +a=6378376.871 +rf=298.268439052467 +no_defs'],
    [104868, '+proj=longlat +a=6378375.251 +rf=298.268363297321 +no_defs'],
    [104869, '+proj=longlat +a=6378405.971 +rf=298.269799839349 +no_defs'],
    [104870, '+proj=longlat +a=6378345.09 +rf=298.266952895494 +no_defs'],
    [104871, '+proj=longlat +a=6378437.651 +rf=298.271281273316 +no_defs'],
    [104896, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104900, '+proj=longlat +R=2439700 +no_defs'],
    [104901, '+proj=longlat +R=6051000 +no_defs'],
    [104902, '+proj=longlat +R=6051800 +no_defs'],
    [104903, '+proj=longlat +R=1737400 +no_defs'],
    [104904, '+proj=longlat +a=3393400 +rf=192.043010752688 +no_defs'],
    [104905, '+proj=longlat +a=3396190 +rf=169.894447223612 +no_defs'],
    [104906, '+proj=longlat +R=6200 +no_defs'],
    [104907, '+proj=longlat +R=11100 +no_defs'],
    [104908, '+proj=longlat +a=71492000 +rf=15.4144027598103 +no_defs'],
    [104909, '+proj=longlat +R=8200 +no_defs'],
    [104910, '+proj=longlat +R=83500 +no_defs'],
    [104911, '+proj=longlat +R=10000 +no_defs'],
    [104912, '+proj=longlat +R=2409300 +no_defs'],
    [104913, '+proj=longlat +R=15000 +no_defs'],
    [104914, '+proj=longlat +R=40000 +no_defs'],
    [104915, '+proj=longlat +R=1562090 +no_defs'],
    [104916, '+proj=longlat +R=2632345 +no_defs'],
    [104917, '+proj=longlat +R=85000 +no_defs'],
    [104918, '+proj=longlat +R=1821460 +no_defs'],
    [104919, '+proj=longlat +R=5000 +no_defs'],
    [104920, '+proj=longlat +R=12000 +no_defs'],
    [104921, '+proj=longlat +a=30000 +rf=3 +no_defs'],
    [104922, '+proj=longlat +R=18000 +no_defs'],
    [104923, '+proj=longlat +R=14000 +no_defs'],
    [104924, '+proj=longlat +R=49300 +no_defs'],
    [104925, '+proj=longlat +a=60268000 +rf=10.2079945799458 +no_defs'],
    [104926, '+proj=longlat +R=16000 +no_defs'],
    [104927, '+proj=longlat +R=9500 +no_defs'],
    [104928, '+proj=longlat +R=560000 +no_defs'],
    [104929, '+proj=longlat +R=249400 +no_defs'],
    [104930, '+proj=longlat +R=59500 +no_defs'],
    [104931, '+proj=longlat +R=16000 +no_defs'],
    [104932, '+proj=longlat +R=133000 +no_defs'],
    [104933, '+proj=longlat +R=718000 +no_defs'],
    [104934, '+proj=longlat +R=88800 +no_defs'],
    [104935, '+proj=longlat +R=198630 +no_defs'],
    [104936, '+proj=longlat +R=10000 +no_defs'],
    [104937, '+proj=longlat +R=41900 +no_defs'],
    [104938, '+proj=longlat +R=110000 +no_defs'],
    [104939, '+proj=longlat +R=50100 +no_defs'],
    [104940, '+proj=longlat +R=764000 +no_defs'],
    [104941, '+proj=longlat +R=11000 +no_defs'],
    [104942, '+proj=longlat +R=529800 +no_defs'],
    [104943, '+proj=longlat +R=2575000 +no_defs'],
    [104944, '+proj=longlat +a=25559000 +rf=43.6160409556314 +no_defs'],
    [104945, '+proj=longlat +R=578900 +no_defs'],
    [104946, '+proj=longlat +R=33000 +no_defs'],
    [104947, '+proj=longlat +R=21000 +no_defs'],
    [104948, '+proj=longlat +R=13000 +no_defs'],
    [104949, '+proj=longlat +R=31000 +no_defs'],
    [104950, '+proj=longlat +R=27000 +no_defs'],
    [104951, '+proj=longlat +R=42000 +no_defs'],
    [104952, '+proj=longlat +R=235800 +no_defs'],
    [104953, '+proj=longlat +R=761400 +no_defs'],
    [104954, '+proj=longlat +R=15000 +no_defs'],
    [104955, '+proj=longlat +R=54000 +no_defs'],
    [104956, '+proj=longlat +R=77000 +no_defs'],
    [104957, '+proj=longlat +R=27000 +no_defs'],
    [104958, '+proj=longlat +R=788900 +no_defs'],
    [104959, '+proj=longlat +R=584700 +no_defs'],
    [104960, '+proj=longlat +a=24764000 +rf=58.5437352245863 +no_defs'],
    [104961, '+proj=longlat +R=74000 +no_defs'],
    [104962, '+proj=longlat +R=79000 +no_defs'],
    [104963, '+proj=longlat +a=104000 +rf=6.93333333333333 +no_defs'],
    [104964, '+proj=longlat +R=29000 +no_defs'],
    [104965, '+proj=longlat +R=170000 +no_defs'],
    [104966, '+proj=longlat +R=208000 +no_defs'],
    [104967, '+proj=longlat +R=40000 +no_defs'],
    [104968, '+proj=longlat +R=1352600 +no_defs'],
    [104969, '+proj=longlat +R=1195000 +no_defs'],
    [104970, '+proj=longlat +R=593000 +no_defs'],
    [104971, '+proj=longlat +R=3396190 +no_defs'],
    [104972, '+proj=longlat +R=470000 +no_defs'],
    [104973, '+proj=longlat +R=255000 +no_defs'],
    [104974, '+proj=longlat +R=2439400 +no_defs'],
    [104975, '+proj=longlat +R=695700000 +no_defs'],
    [104990, '+proj=longlat +ellps=bessel +no_defs'],
    [104991, '+proj=longlat +ellps=GRS80 +no_defs'],
    [104992, '+proj=longlat +ellps=bessel +no_defs'],
    [900913, '+proj=merc +a=6378137 +b=6378137 +lat_ts=0 +lon_0=0 +x_0=0 +y_0=0 +k=1 +units=m +nadgrids=@null +no_defs']
]
)
