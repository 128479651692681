import { NavigationExtras } from "@angular/router";

export class NavigationResponse{
    path:string;
    extras: NavigationExtras;

    constructor(path:string, extras?:NavigationExtras){
        this.path = path;
        this.extras = extras;
    }
}