<!-- Both Link and router options are almost identical. Sadly this is the only way I could make generic buttons work while not causing accessibility selection duplicates-->
<!-- being a button inside a routerlink or hyperlink causes the button to be tabbed twice. -->
<ng-container *ngIf="redirectToLink() else DEFAULT">
    <a [href]="getHeaderButtonUrl()" *ngIf="isHeaderButtonAnImage() && !displayName()" target="_blank">
        <img [src]="getHeaderButtonImageUrl()" class="header_image"
            [ngStyle]="getHeaderButtonStyle()" [alt]="getHeaderButtonAltText()">
    </a>

    <a mat-button *ngIf="isHeaderButtonAnImage() && displayName()" class="header_icon_button" [ngStyle]="getHeaderButtonStyle()"
    [href]="getHeaderButtonUrl()">
        <img [src]="getHeaderButtonImageUrl()" class="header_image" [alt]="getHeaderButtonAltText()">
        {{getHeaderButtonName() | translate}}
    </a>

    <a mat-icon-button *ngIf="isHeaderButtonAnIcon() && !displayName()" class="header_icon_button" [ngStyle]="getHeaderButtonStyle()"
        [href]="getHeaderButtonUrl()">
        <mat-icon>{{getHeaderButtonIcon()}}</mat-icon>
    </a>

    <a mat-button *ngIf="isHeaderButtonAnIcon() && displayName()" class="header_icon_button" [ngStyle]="getHeaderButtonStyle()"
    [href]="getHeaderButtonUrl()">
        <mat-icon>{{getHeaderButtonIcon()}}</mat-icon>
        {{getHeaderButtonName() | translate}}
    </a>

    <a mat-button *ngIf="isHeaderButtonAName()" class="header_button" [ngStyle]="getHeaderButtonStyle()" target="_blank"
        [href]="getHeaderButtonUrl()">
        {{getHeaderButtonName() | translate}}
    </a>
</ng-container>

<!-- Both Link and router options are almost identical. Sadly this is the only way I could make generic buttons work while not causing accessibility selection duplicates-->
<!-- being a button inside a routerlink or hyperlink causes the button to be tabbed twice. -->
<ng-template #DEFAULT>
    <a [routerLink]="getHeaderButtonUrl()" *ngIf="isHeaderButtonAnImage() && !displayName()">
        <img [src]="getHeaderButtonImageUrl()" class="header_image"
            [ngStyle]="getHeaderButtonStyle()" [alt]="getHeaderButtonAltText()">
    </a>

    <a mat-button *ngIf="isHeaderButtonAnImage() && displayName()" class="header_icon_button" [ngStyle]="getHeaderButtonStyle()"
    [routerLinkActive]="getRouterLinkActive()" [routerLink]="getHeaderButtonUrl()">
        <img [src]="getHeaderButtonImageUrl()" class="header_image" [alt]="getHeaderButtonAltText()">
        {{getHeaderButtonName() | translate}}
    </a>

    <a mat-icon-button *ngIf="isHeaderButtonAnIcon() && !displayName()" class="header_icon_button" [ngStyle]="getHeaderButtonStyle()"
    [routerLinkActive]="getRouterLinkActive()" [routerLink]="getHeaderButtonUrl()">
        <mat-icon>{{getHeaderButtonIcon()}}</mat-icon>
    </a>

    <a mat-button *ngIf="isHeaderButtonAnIcon() && displayName()" class="header_icon_button" [ngStyle]="getHeaderButtonStyle()"
    [routerLinkActive]="getRouterLinkActive()" [routerLink]="getHeaderButtonUrl()">
        <mat-icon>{{getHeaderButtonIcon()}}</mat-icon>
        {{getHeaderButtonName() | translate}}
    </a>

    <a mat-button *ngIf="isHeaderButtonAName()" class="header_button" [ngStyle]="getHeaderButtonStyle()"
    [routerLinkActive]="getRouterLinkActive()" [routerLink]="getHeaderButtonUrl()">
        {{getHeaderButtonName() | translate}}
    </a>
</ng-template>
