import { NavigationExtras } from "@angular/router";

export class BreadcrumbModel{
    paths: string[];
    extras?: NavigationExtras;

    constructor(paths:string[], extras?: NavigationExtras){
        this.paths = paths;
        this.extras = extras;
    }
}