import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { DatasetModel } from "@shared/models/dataset.model";
import { DatastoreSearchModel } from "@shared/models/datastore-search.model";
import { ExtrasModel } from "@shared/models/extras.model";
import { GroupModel } from "@shared/models/group.model";
import { OrganizationModel } from "@shared/models/organization.model";
import { PackageSearchModel } from "@shared/models/package-search.model";
import { CkanResponseModel } from "@shared/models/scheming-package.model";
import { firstValueFrom, forkJoin, Observable, Subject } from "rxjs";
import { DynamicInjectorService } from "./dynamic.injector.service";
import { KeycloakUserService } from "./keycloak-user.service";

@Injectable()
export class ApiService {

    private apiCallsLoading:number = 0; //used to see if any calls are still loading. Usefull for a progress bar or something similar
    public static readonly TOKEN_HEADER : string = "recaptcha_token";
    currentPackageSearchParams: string;

    constructor(
        private http: HttpClient,
        private keycloakUserService: KeycloakUserService,
        private dynamicInjectorService: DynamicInjectorService
        ){

    }

    private getCkanUrl():string{
        return this.dynamicInjectorService.getEnvironment().apiUrl;
    }

    /**
     * Keeps track of on going API calls. Good for progress bars or similar applications.
     * @returns if there are still api calls in progress
     */
    public isLoading():boolean{
        return this.apiCallsLoading > 0;
    }

    /**
     * Makes a get request
     * @param url provide the url the GET request must call
     * @param params (optional) provide parameters to add to the call
     * @returns A promise based on the type provided
     */
    private getRequest<T>(url:string, params?:HttpParams):Promise<T> {
        return new Promise((resolve,reject) => {
            this.apiCallsLoading++;
            firstValueFrom<T>(this.http.get<T>(url, {
                params: params
            })).then(response => {
                this.apiCallsLoading--;
                resolve(response);
            })
            .catch(error => reject(error));
        });
    }

    /**
     * Make a post request
     * @param url provide the url the POST request must call
     * @param body provide the body the POST request has
     * @param params (optional) provide parameters to add to the call
     * @returns A promise based on the type provided
     */
    public postRequest<T>(url:string, body:any, headers?:HttpHeaders, params?: HttpParams):Promise<T>{
        return new Promise((resolve,reject) => {
            this.apiCallsLoading++;
            firstValueFrom<T>(this.http.post<T>(url, body, {
                headers:headers,
                params:params
            })).then(response => {
                this.apiCallsLoading--;
                resolve(response);
            }).catch( error => reject(error));
        })
    }

    /**
     * Makes a get request that returns a string
     * @param url provide a url for your request
     * @param params (optional) provide parameters to the request
     * @returns a string of text based on the call
     */
    public getTextRequest(url:string, params?:HttpParams):Promise<string>{
        return new Promise((resolve,reject) => {
            this.apiCallsLoading++;
            firstValueFrom<string>(this.http.get(url, {
                responseType: 'text',
                params: params
            })).then(response => {
                this.apiCallsLoading--;
                resolve(response);
            })
            .catch(error => reject(error))
        });
    }

        /**
     * Makes a get request that returns a arraybuffer
     * @param url provide a url for your request
     * @param params (optional) provide parameters to the request
     * @returns a string of text based on the call
     */
         public getArrayBufferRequest(url:string, params?:HttpParams):Promise<ArrayBuffer>{
            return new Promise((resolve,reject) => {
                this.apiCallsLoading++;
                firstValueFrom<ArrayBuffer>(this.http.get(url, {
                    responseType: 'arraybuffer',
                    params: params
                })).then(response => {
                    this.apiCallsLoading--;
                    resolve(response);
                })
                .catch(error => reject(error));
            });
        }

    private postTextRequest(url:string, body:any, params?: HttpParams):Promise<string>{
        return new Promise((resolve,reject) => {
            this.apiCallsLoading++;
            firstValueFrom<string>(this.http.post(url, body, {
                params:params,
                responseType: 'text'
            })).then(response => {
                this.apiCallsLoading--;
                resolve(response);
            })
            .catch(error => reject(error));
        })
    }

    /**
     * Make a CKAN specific request and determine the outcome
     * @param url provide a CKAN url
     * @param params (optional) provide parameters to the request
     * @returns the result within the CkanResponse model
     */
    private getCkanResponseModelRequest<T>(url:string, params?:HttpParams):Promise<T>{
        return new Promise<T>((resolve, reject) => {
            this.getRequest<CkanResponseModel<T>>(url,params).then(response => resolve(response.result)).catch(error => reject(error));
        })
    }

    private getGroupList():Promise<string[]>{
        return this.getCkanResponseModelRequest<string[]>(`${this.getCkanUrl()}action/group_list`);
    }

    public getThemes(): Observable<GroupModel[]> {
        const groupSubject: Subject<GroupModel[]> = new Subject<GroupModel[]>();
        this.getGroupList().then(response => {
            const groupsList: Promise<GroupModel>[] = [];
            const groupNames: string[] = response;
            for (const groupName of groupNames) {
                groupsList.push(this.getGroupDetails(groupName))
            }
            forkJoin(groupsList).subscribe(responseModels => groupSubject.next(responseModels));
        })
        return groupSubject.asObservable();
    }

    public getGroupDetails(groupName: string):Promise<GroupModel>{
        return this.getCkanResponseModelRequest<GroupModel>(this.getCkanUrl() + 'action/group_show?id=' + groupName);
    }

    public getGroupPackageCount(groupName: string):Promise<PackageSearchModel> {
        const groupFilterString : string = this.dynamicInjectorService.getOrganizationConfig().oneCkanClient ? 'groups' : 'theme';
        return this.getCkanResponseModelRequest<PackageSearchModel>(`${this.getCkanUrl()}action/package_search?fq=owner_org:${this.dynamicInjectorService.getEnvironment().organizationId}+${groupFilterString}:${groupName}+private:false&include_private=true`);
    }

    public postFeedback(userData: object, params?:HttpParams): Promise<string> {
        return this.postTextRequest(this.dynamicInjectorService.getEnvironment().supportApi, userData, params)
    }

    public getOrganizations():Promise<string[]>{
        return this.getCkanResponseModelRequest<string[]>(`${this.getCkanUrl()}action/organization_list`);
    }

    /**
     * Retreive organization information
     * @param organizationId provide the organization id of the organization you want to collect
     * @returns a organization model
     */
    public getOrganizationByName(organizationId: string):Promise<OrganizationModel> {
        return this.getCkanResponseModelRequest<OrganizationModel>(`${this.getCkanUrl()}action/organization_show?id=${organizationId}`);
    }

    public addRating<T>(datasetId: string, rating: number, ratings_count: number): Promise<CkanResponseModel<T>> {
        return this.postRequest<CkanResponseModel<T>>(`${this.getCkanUrl()}action/rating_package_create`, { package: datasetId, rating: rating, ratings_count: ratings_count });
    }

    public editRating<T>(datasetId: string, rating: number): Promise<CkanResponseModel<T>> {
        return this.postRequest<CkanResponseModel<T>>(`${this.getCkanUrl()}action/rating_package_create`, { package: datasetId, rating: rating });
    }

    /**
     * @param datasetsId provide the id of the dataset rating you want to receive
     * @returns A limited DatasetModel with rating information
     * @todo provide a better model to fit this limited DatasetModel
     */
    public getRating(datasetsId: string): Promise<DatasetModel> {
        return this.getCkanResponseModelRequest<DatasetModel>(`${this.getCkanUrl()}action/rating_package_get?package_id=${datasetsId}`);
    }

    public getDatasetDetails(datasetId: string, include_tracking: boolean = true): Promise<DatasetModel> {
          const params:HttpParams = new HttpParams()
            .set('id', datasetId)
            .set('include_tracking', include_tracking.toString());
          return this.getCkanResponseModelRequest<DatasetModel>(`${this.getCkanUrl()}action/package_show`, params);
    }

    /**
     * @todo the model ExtrasModel name does not seem fitting.
     */
    public getSchemePackageDetails(datasetId: string):Promise<ExtrasModel> {
        return this.getCkanResponseModelRequest<ExtrasModel>(this.getCkanUrl() + 'action/scheming_package_show?type=dataset&id=' + datasetId);
    }

    /**
     * @todo needs a return type, no model is in place yet.
     */
    public getSchemeDataset(): Promise<any> {
        return this.getCkanResponseModelRequest<any>(this.getCkanUrl() + 'action/scheming_dataset_schema_show?type=dataset');
    }

    public getDatastoreSearch(params?:HttpParams):Promise<DatastoreSearchModel>{
        return this.getCkanResponseModelRequest<DatastoreSearchModel>(this.getCkanUrl() + 'action/datastore_search', params);
    }

    public getResourceShow(resourceId: string)  {
        return this.getCkanResponseModelRequest(this.getCkanUrl()+ 'action/resource_show?id=' + resourceId);
    }

    public getLastEditedDatasets(): Promise<any> {
        return this.getCkanResponseModelRequest<any>(this.getCkanUrl() + 'action/recently_changed_packages_activity_list');
    }

    public getMostSeen(): Promise<any> {
        return this.getCkanResponseModelRequest<any>(this.getCkanUrl() + 'action/package_search?q=&sort=views_total+desc&include_private=true');
    }

    public getPackageSearch(params?: HttpParams):Promise<PackageSearchModel>{
        let includePrivate=""
        if (this.keycloakUserService.isAuthorized()){
            includePrivate = "&include_private=true"
        }
        this.currentPackageSearchParams = params.get('q')
        return this.getCkanResponseModelRequest<PackageSearchModel>(this.getCkanUrl() + `action/package_search?fl=id&fl=notes&fl=title${includePrivate}`,params);
    }

    public getSystemIntroText(params?: HttpParams): Promise<string>{
        return this.getCkanResponseModelRequest<string>(this.getCkanUrl() + 'action/config_option_show?key=ckanext.portal.intro_text',params);
    }

    /**
 * Retreive organization information
 * @param organizationId provide the organization id of the organization you want to collect
 * @returns a organization model
 */
    public getWFSFeatures(url: string):Promise<JSON> {
        return new Promise<JSON>((resolve) => {
            this.getRequest<JSON>(url)
            .then(response => resolve(response))
            .catch(error => {
                console.warn(error)
                resolve(null)
            });
        })
    }

    /**
     * Retrieve latested stored package search params
     * @returns
     */
    public getCurrentPackageSearchParams(): string{
        return this.currentPackageSearchParams
    }

}
