import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject, startWith, of, Observable, takeUntil, map } from 'rxjs';
import { FormControl } from '@angular/forms';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { NavigationService } from '@shared/services/navigation.service';
import { MatomoTracker } from 'ngx-matomo';
import { DatasetsService } from '@shared/services/datasets.service';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { PackageSearchModel } from '@shared/models/package-search.model';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent implements OnInit, OnDestroy {

  datasetSearchInput: FormControl;
  filteredDatasets: Observable<any[]>;
  private ngUnsubscribe = new Subject<void>();
  private totalNoOfDatasets: number = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private datasetsService: DatasetsService,
    private dynamicInjectorService: DynamicInjectorService,
    private matomoTracker: MatomoTracker,
    private navigationService: NavigationService,
  ) { 
    this.datasetSearchInput = new FormControl();
  }


  ngOnInit() {
    this.datasetSearchInput.valueChanges.pipe(
      startWith(''),
      takeUntil(this.ngUnsubscribe)
   ).subscribe(
      (new_value) => {
        if (new_value) {
          this.filterDatasets(new_value);
        } else {
          this.filteredDatasets = undefined;
        }
      }
    );
    this.datasetsService.getTotalNumberOfDatasets().then((result: number) => {
      this.totalNoOfDatasets = result;
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  
  filterDatasets(title: string) {
    this.datasetsService.getDatasets(5, 0, title).then((data: PackageSearchModel) => {
      // if the value changed during the request, don't care about this output
      if (title === this.datasetSearchInput.value) {
        this.filteredDatasets = of(data.results);
      }
    });
  }

  public getSearchBarStyle():{[key: string]: any}{
    return this.dynamicInjectorService.getOrganizationConfig().header.searchbarStyle;
  }

  public getDatasetDetail(id: string): void {
    this.navigationService.toDatasetDetail(id);
  }

  public getDatasetCounterStyle(): {[key: string]: any}{
    return this.dynamicInjectorService.getOrganizationConfig().header.datasetCounter.style;
  }

  public getTotalNumberOfDatasets(): number {
    return this.totalNoOfDatasets
  }


  public isDatasetCounterEnabled(): boolean {
    
    return this.dynamicInjectorService.getOrganizationConfig().header.datasetCounter.enabled;
  }

  displayDatasetSearchResults() {
    // if user deletes all search terms and hits enter or clicks on search icon, display all datasets
    if (!this.datasetSearchInput.value){
      this.navigationService.toData();
    }
    else {
      this.navigationService.toData({
        // put searchInput value into parentheses, otherwise the query fails to return results when more than one strings are provided
        queryParams: { autocomplete_field: `(${this.datasetSearchInput.value})`},
        queryParamsHandling: 'merge'
      }, this.getParamKey(), this.getParamValue())

      let packageSearchResponse = this.datasetsService.getPackageSearchResponse()

      // matomo hook after search results are returned
      this.matomoTracker.setDocumentTitle('Dataset search');
      this.matomoTracker.setCustomUrl(this.getCustomMatomoURL(packageSearchResponse.keyword, packageSearchResponse.count))
      this.matomoTracker.trackPageView();
    }
  }

  private getParamKey():string{
    const queryKey:string = this.activatedRoute.snapshot.queryParamMap.get('key');
    const key:string = this.activatedRoute.snapshot.paramMap.get('key');
    if(!_.isNil(key)){
      return key;
    } else if (!_.isNil(queryKey)){
      return queryKey;
    } else {
      return undefined
    }
  }

  private getParamValue():string{
    const queryValue:string = this.activatedRoute.snapshot.queryParamMap.get('value');
    const value:string = this.activatedRoute.snapshot.paramMap.get('value');
    if(!_.isNil(value)){
      return value;
    } else if (!_.isNil(queryValue)){
      return queryValue;
    } else {
      return undefined
    }
  }

  /**
  Returns a custom url which should be used when tracking keyword search in Matomo. Based on the documentation in: https://matomo.org/faq/reports/tracking-site-search-keywords/
**/
  getCustomMatomoURL(searchKeyword: string, searchCount: number){
    return `${window.location.href.split('?')[0]}?q=${searchKeyword}&search_count=${searchCount}`
  }

}
