import { AuthGuardService } from '@shared/services/auth-guard-service.service';
import { CustomComponent } from './custom-components/custom-component/custom.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

//Customer components
import { CasesComponent } from '../organizations/dataplatform/custom-pages/cases/cases.component';
import { ContactComponent } from '../organizations/dataplatform/custom-pages/contact/contact.component';
import { DataVisualizationComponent } from '../organizations/dataplatform/custom-pages/data-visualization/data-visualization.component';
import { FaqComponent } from '../organizations/dataplatform/custom-pages/info/faq/faq.component';
import { FodaComponent } from '../organizations/friesland/custom-pages/foda/foda.component';
import { ForMunicipalitiesComponent } from '../organizations/friesland/custom-pages/for-municipalities/for-municipalities.component';
import { GebuiksvoorwaardenComponent } from '../organizations/dataplatform/custom-pages/info/gebuiksvoorwaarden/gebuiksvoorwaarden.component';
import { GISViewerComponent } from '../organizations/exposome/custom-pages/gisviewer/gisviewer.component';
import { LabComponent } from '../organizations/dataplatform/custom-pages/lab/lab.component';
import { Map2dComponent } from '../organizations/sandbox/custom-pages/map2d/map2d.component';
import { Map3dComponent } from '../organizations/sandbox/custom-pages/map3d/map3d.component';
import { OrganizationComponentMalmo } from '../organizations/malmo/custom-pages/organization/organization.component';
import { OrganizationProvincieNoordHollandComponent } from '../organizations/provincie-noord-holland/custom-pages/organization/organization.component';
import { OrganizationsComponent } from '../organizations/dataplatform/custom-pages/organizations/organizations.component';
import { PrivacyComponent } from '../organizations/dataplatform/custom-pages/info/privacy/privacy.component';
import { RegionalHistoricalCenterComponent } from '../organizations/stichtsevecht/custom-pages/regional-historical-center/regional-historical-center.component';
import { ShoppingCartComponent } from '../organizations/health-ri/subpaths/shoppingCart/shoppingCart.component';
import { VoorbeeldenDenhaagComponent } from '../organizations/denhaag/custom-pages/voorbeelden/voorbeelden.component';
import { KlimaatMonitorComponent } from '../organizations/greeninnovationhub/custom-pages/klimaatmonitor/klimaatmonitor.component';


const routes: Routes = [
  { path: '', component: CustomComponent },
  {
    path: 'foda', component: FodaComponent,
    canActivate: [AuthGuardService], data: { customers: ['Friesland'] }
  },
  {
    path: 'voor-gemeenten', component: ForMunicipalitiesComponent,
    canActivate: [AuthGuardService], data: { customers: ['Friesland'] }
  },
  {
    path: 'lab', component: LabComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform'] }
  },
  {
    path: 'cases', component: CasesComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform'] }
  },
  {
    path: 'contact', component: ContactComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform'] }
  },
  {
    path: 'privacy', component: PrivacyComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform', 'Sandbox'] }
  },
  {
    path: 'faq', component: FaqComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform', 'Sandbox'] }
  },
  {
    path: 'terms-of-use', component: GebuiksvoorwaardenComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform', 'Sandbox'] }
  },
    {
    path: 'organizations', component: OrganizationsComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform'] }
  },
  {
    path: 'data-visualization', component: DataVisualizationComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform'] }
  },
  {
    path: 'data', component: OrganizationsComponent,
    canActivate: [AuthGuardService], data: { customers: ['Dataplatform'] }
  },
  {
    path: 'voorbeeldenDenhaag', component: VoorbeeldenDenhaagComponent,
    canActivate: [AuthGuardService], data: { customers: ['Denhaag'] }
  },
  {
    path: 'gisViewer', component: GISViewerComponent,
    canActivate: [AuthGuardService], 
    data: { customers: ['Exposome'] }
  },
  {
    path: 'klimaatmonitor', component: KlimaatMonitorComponent,
    canActivate: [AuthGuardService], 
    data: { customers: ['greeninnovationhub'] }
  },
  {
    path: 'map2d', component: Map2dComponent,
    canActivate: [AuthGuardService], 
    data: { customers: ['Sandbox'] }
  },
  {
    path: 'map3d', component: Map3dComponent,
    canActivate: [AuthGuardService], 
    data: { customers: ['Sandbox'] }
  },
  {
    path: 'regional-historical-center', component: RegionalHistoricalCenterComponent,
    canActivate: [AuthGuardService], data: { customers: ['Stichtsevecht'] }
  },
  {
    path: 'organizations/Malmo', component: OrganizationComponentMalmo,
    canActivate: [AuthGuardService], data: { customers: ['Malmo'] }
  },
  {
    path: 'organizations/Provincie-Noord-Holland', component: OrganizationProvincieNoordHollandComponent,
    canActivate: [AuthGuardService], data: { customers: ['Provincie-Noord-Holland'] }
  },
  {
    path: 'shoppingCart', component: ShoppingCartComponent,
    canActivate: [AuthGuardService], data: { customers: ['Health-RI'] }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class CustomRoutingModule {

}
