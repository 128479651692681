<!-- Accessibility Title -->
<h1 class="accessibility-hidden-title">Themes</h1>

<div fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap.xs="20px" class="py2">
    <mat-spinner *ngIf="showLoading"></mat-spinner>
</div>
<div *ngIf="!showLoading" fxLayout fxLayout.xs="column" fxLayoutAlign="center" fxLayoutGap.xs="20px" class="py2">

    <div [ngSwitch]="customerName" class="ieCompat">
        <div *ngSwitchCase="'Zaanstad'" fxLayoutAlign="center center">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" fxLayout.xs="column"
                class="container flex-wrap mat-elevation-z3">
                <app-group-card *ngFor="let group of groups; let i = index" fxFlex="25" [index]="i" [group]="group">
                </app-group-card>
            </div>
        </div>
        <div *ngSwitchCase="'Malmo'" fxLayoutAlign="center center">
            <div fxFlex="100" fxFlex.sm="100" fxFlex.xs="100" class="container flex-wrap mat-elevation-z3" autosize>
                <app-group-card *ngFor="let group of groups; let i = index" fxFlex="25" [fxFlex.md]=50 [fxFlex.sm]=100
                    [fxFlex.xs]=100 [index]="i" [group]="group" style="font-size: 11px; padding: 10px;" tabindex="0"
                    role="button" autosize></app-group-card>
            </div>
        </div>
        <div *ngSwitchDefault fxLayoutAlign="center center">
            <div fxFlex="60" fxFlex.sm="90" fxFlex.xs="90" class="container flex-wrap mat-elevation-z3" autosize>
                <app-group-card *ngFor="let group of groups; let i = index" fxFlex="25" [fxFlex.md]=50 [fxFlex.sm]=100
                    [fxFlex.xs]=100 [index]="i" [group]="group" autosize></app-group-card>
            </div>

        </div>
    </div>
</div>
<br>