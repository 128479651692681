import { Component, OnInit } from '@angular/core';
import { ConfigInterface } from '@shared/interfaces/config.component.interface';
import { ID } from '../id';
import { DynamicInjectorService } from '@shared/services/dynamic.injector.service';
import { ApiService } from "@shared/services/api.service";
import { MarkdownService } from 'ngx-markdown';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface caseTile {
  cols: number;
  rows: number;
  title: string;
  description: string;
}

@Component({
  selector: 'app-greeninnovationhub-home-text',
  templateUrl: './greeninnovationhub-home-text.component.html',
  styleUrls: ['./greeninnovationhub-home-text-component.scss']
})
export class GreenInnovationHubHomeTextComponent implements OnInit, ConfigInterface {
  partners: any[];
  showCaseDialog: boolean = false;
  caseTitle: string;
  caseDescription: string;
  public totalNoOfDatasets: number = 0;
  constructor(
    private http: HttpClient,
    private dynamicInjectorService: DynamicInjectorService,
    private apiService: ApiService,
    private markdownService: MarkdownService
  ) { }


  openCaseDialog(caseObj) {
    this.caseTitle = caseObj.title;
    this.caseDescription = caseObj.description;
    this.showCaseDialog = true;
  }

  closeCaseDialog() {
    this.showCaseDialog = false;
  }

  getPortal(): string {
    return ID.id;
  }

  ngOnInit() {
    this.loadPartners().subscribe(data => {
      this.partners = data;
    });
  }

  public setBackgroundUrl(string): string {
    return `url(${string})`
  }

  loadPartners(): Observable<any[]> {
    return this.http.get<any[]>('portal-config/organizations/greeninnovationhub/assets/data/partners.json');
  }
}
