<mat-panel-description>
  <table mat-table [dataSource]="dataSource" class="app-background">

    <ng-container matColumnDef="value_label" >
      <th mat-header-cell *matHeaderCellDef>{{ "DATA.FIELD" | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <div>{{ getFieldLabel(element, language) }}</div>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef>{{ "DATA.VALUE" | translate }}</th>
      <td mat-cell *matCellDef="let element" [ngSwitch]="element.field_label.en">
        <div [ngSwitch]="getValueType(element)">
          <div *ngSwitchCase="'text'">
            {{ element.value }}
          </div>
          <div *ngSwitchCase="'url'">
            <ng-container *ngIf="validateUrl(element.value) else INVALID_URL">
              <a [href]="element.value" target="_blank">{{ getValueLabel(element, language) }}</a>
            </ng-container>
            <ng-template #INVALID_URL>
              {{ element.value }}
            </ng-template>
          </div>
          <div *ngSwitchCase="'date'">
            {{ element.value | localPipe: "dd MMMM yyyy"}}
          </div>
          <div *ngSwitchCase="'email'">
            <ng-container *ngIf="validateEmail(element.value) else INVALID_EMAIL">
              <a href="mailto:{{ element.value }}">{{ element.value }}</a>
            </ng-container>
            <ng-template #INVALID_EMAIL>
              {{ element.value }}
            </ng-template>
          </div>
          <div *ngSwitchDefault>
            {{ getValueLabel(element, language) }}
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

  </table>
</mat-panel-description>
