<mat-toolbar class="footer">
    <mat-toolbar-row class="footer-row">
        <div class="bounds" fxFlex="100" fxLayoutAlign="center" fxLayoutAlign.xs="start">
            <div fxFlex="61">
                <div fxFlex="66" fxLayoutAlign="start" style="white-space:normal">
                    <p class="mat-body european-union-horizon" style="margin-top:auto; margin-bottom:auto;">{{'FOOTER.SCOREWATER_EU_FUNDING' | translate}}</p>
                </div>
                <div fxFlex="33" fxLayoutAlign="start">
                    <img [src]="getEuropeanImage()" class="european-union-horizon-image">
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>