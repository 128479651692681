import { TruncatePipesModule } from 'angular-truncate-pipes';
import { GroupCardComponent } from './themes/group-card/group-card.component';
import { MetadataTableComponent } from './../datasets/dataset-details/dataset-information/metadata-table/metadata-table.component';
import { NgModule } from '@angular/core';
import { MaterialComponentsModule } from './material-components.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MarkdownModule } from 'ngx-markdown';
import { DatasetRatingComponent } from './dataset-rating/dataset-rating.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MapService } from './components/map-preview/map.service';
import { MapPreviewComponent } from './components/map-preview/map-preview.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ThemesComponent } from './themes/themes.component';
import { SafePipeUrl, SafePipeHtml } from './pipes/safePipe';
import { DataplatformPreviewGroupsComponent } from './themes/custom-theme/dataplatform-preview-groups/dataplatform-preview-groups.component';
import { LocalPipe } from './pipes/localPipe';
import { PaginatorI18n } from './paginatorI18n';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ScrollToTopComponent } from 'app/scroll-to-top/scroll-to-top.component';
import { MatomoModule } from 'ngx-matomo';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {OverlayContainer} from "@angular/cdk/overlay";
import {FullscreenOverlayContainer} from "@angular/cdk/overlay";
import { NgxPaginationModule } from 'ngx-pagination';
import { KeycloakUserService } from './services/keycloak-user.service';
import { ApiService } from './services/api.service';


@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule, MatInputModule, MatNativeDateModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    NgxPaginationModule,
    HttpClientModule,
    RouterModule,
    MatDatepickerModule,
    MatMenuModule,
    TranslateModule.forChild(),
    MarkdownModule.forChild(),
  ],
  exports: [
    CommonModule,
    MaterialComponentsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    HttpClientModule,
    TranslateModule,
    MetadataTableComponent,
    MarkdownModule,
    DatasetRatingComponent,
    TruncatePipe,
    MatMenuModule,
    MapPreviewComponent,
    SearchBarComponent,
    ThemesComponent,
    DataplatformPreviewGroupsComponent,
    SafePipeUrl,
    LocalPipe,
    SafePipeHtml,
    TruncatePipesModule,
    ScrollToTopComponent,
    MatomoModule,
  ],
  declarations: [MetadataTableComponent,
    DatasetRatingComponent,
    TruncatePipe,
    MapPreviewComponent,
    SearchBarComponent,
    ThemesComponent,
    DataplatformPreviewGroupsComponent,
    GroupCardComponent,
    SafePipeUrl,
    LocalPipe,
    SafePipeHtml,
    ScrollToTopComponent
  ],
  providers: [
    MapService,
    KeycloakUserService,
    ApiService,
    {
      provide: MatPaginatorIntl, deps: [TranslateService],
      useFactory: (translateService: TranslateService) => new PaginatorI18n(translateService)
    },
    {provide: OverlayContainer, useClass: FullscreenOverlayContainer}
  ]
})
export class SharedModule {
}
