import { SettingsConfig } from "@shared/models/settings.config.model";

export const settingsObject: SettingsConfig = {
    version: '2.10.0',
    supportUrl: {
        tst:"https://tst-mail.dataplatform.nl/external-email",
        acc:"https://acc-mail.dataplatform.nl/external-email",
        prd:"https://mail.dataplatform.nl/external-email"
    },
    matomoUrl: {
        tst:"https://tst-matomo.dataplatform.nl/",
        acc:"https://acc-matomo.dataplatform.nl/",
        prd:"https://matomo.dataplatform.nl/"
    },
    languages: new Map<string, string>([
        ["nl", "Nederlands"], 
        ["en","English"], 
        ["sv","Svenska"]
    ]),
    urlFilters: new Set<string>([
        'dataplatform', 
        'dataplattform'
    ]),
    uniqueCustomerReferences: new Map<string,string>([
        ['www.dataplatform.nl','dataplatform'],
        ['data.rotterdam.nl','rotterdam'],
        
        // Health-ri multiple alias 
        ['accesshealthdata.nl','health-ri'],
        ['catalog.accesshealthdata.nl','health-ri'],
        ['catalog.healthdata.nl','health-ri'],
        ['healthdata.nl','health-ri'],

        // Catalog portal under catalog CKAN dataplatform endpoint
        ['https://tst-catalogus-lelystad.dataplatform.nl/portal', 'catalogus-lelystad'],
        ['https://acc-catalogus-lelystad.dataplatform.nl/portal', 'catalogus-lelystad'],
        ['https://catalogus-lelystad.dataplatform.nl/portal', 'catalogus-lelystad']

        // //use on debugging with Web Server for Chrome
        // ['127.0.0.1', 'rotterdam'], 
        // ['10.8.26.26', 'malmo']
    ])
}
