import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n extends MatPaginatorIntl {

    constructor(private readonly translate: TranslateService) {
        super();
        this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
        this.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE');
        this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE');
        this.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE');
        this.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE');

        translate.onLangChange.subscribe(data => {
          this.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE');
          this.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE');
          this.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE');
          this.firstPageLabel = this.translate.instant('PAGINATOR.FIRST_PAGE');
          this.lastPageLabel = this.translate.instant('PAGINATOR.LAST_PAGE');
          this.changes.next();
        });
    }

    getRangeLabel = function (page, pageSize, length) {
        const of = this.translate ? this.translate.instant('PAGINATOR.RANGE_LABEL') : 'of';
        if (length === 0 || pageSize === 0) {
          return '0 ' + of + ' ' + length;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        // If the start index exceeds the list length, do not try and fix the end index to the end.
        const endIndex = startIndex < length ?
          Math.min(startIndex + pageSize, length) :
          startIndex + pageSize;
        return startIndex + 1 + ' - ' + endIndex + ' ' + of + ' ' + length;
      };
}
